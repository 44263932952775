import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import "react-confirm-alert/src/react-confirm-alert.css";
import "./../../assets/css/usuarios.css";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import axios from "axios";
import play from "../../assets/img/usuarios/play.svg";
import pause from "../../assets/img/usuarios/pause.svg";
import audio_salusin from "../../assets/img/usuarios/audio_salusin.svg";
// estilos

export default class ReproducirAudio extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      audio: props.url ? props.url : null,
      paused: false,
      porcentajeCarga: 0,
      duracion: 0,
      tiempoActual: 0,
      audioMotivoInicio: props.inicio | false,
      audioMotivoTermino: props.termino | false,
    };
  }

  componentDidMount() {
    if (this.state.audio) {
      let id = this.makeid(5);
      this.decargarAudio(this.state.audio, id);
    }
  }

  decargarAudio = (url, registro) => {
    let urlCortada = url.replace("http://200.111.252.138/grabaciones/", "");
    axios
      .get(global.config.modulos.parametros + "descargar_audio/", {
        headers: authHeader(),
        params: { url: urlCortada, registro: registro },
      })
      .then((res) => {
        this.setState({ audio: new Audio(res.data) });
      });
  };

  makeid = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  reproducirAudio = () => {
    let audio = this.state.audio;
    let inicio = this.state.audioMotivoInicio;
    let termino = this.state.audioMotivoTermino;

    if (!audio.paused) {
      audio.pause();
      this.setState({ paused: false });
      return;
    } else if (audio.currentTime) {
      audio.play();
      this.setState({ paused: true });
      return;
    }
    this.setState({ duracion: Math.trunc(audio.duration) });

    if (inicio) {
      audio.currentTime = inicio;
    }
    audio.play();
    let duracion = audio.duration;
    let calculoAumento = audio.duration;
    this.setState({ paused: true });

    let x = 0;
    let current = 0;
    if (inicio) {
      let tiempoTermino = termino;
      x = inicio + 1;
      current = inicio;
      duracion = tiempoTermino;
      calculoAumento = tiempoTermino - inicio;
      calculoAumento = calculoAumento - 1;
      this.setState({ duracion: calculoAumento });
    }

    let aumento = 100 / calculoAumento;
    let porcent = 0;
    let tiempoInicio = 0;

    let listen = audio.addEventListener("timeupdate", () => {
      current = Math.trunc(audio.currentTime);

      if (current >= x) {
        x++;
        tiempoInicio++;
        porcent = porcent + aumento;
        this.setState({ porcentajeCarga: porcent, tiempoActual: tiempoInicio });
      }
      if (current >= Math.trunc(duracion)) {
        audio.pause();
        this.setState({ paused: false });
        audio.currentTime = 0;
        this.setState({ porcentajeCarga: 0, tiempoActual: 0 });
      }
    });
  };
  handleProgressClick = (event) => {
    let audio = this.state.audio;
    let inicio = this.state.audioMotivoInicio;
    let termino = this.state.audioMotivoTermino;

    const barraProgreso = event.currentTarget;
    const barraProgresoWidth = barraProgreso.offsetWidth;
    const clickX = event.nativeEvent.offsetX;

    const clickPercentage = clickX / barraProgresoWidth;

    const newTime = inicio + clickPercentage * (termino - inicio);
    audio.currentTime = newTime;

    const relativeTime = newTime >= inicio ? newTime - inicio : 0;

    this.setState({
      tiempoActual: Math.trunc(relativeTime),
      porcentajeCarga: clickPercentage * 100,
    });

    audio.addEventListener("timeupdate", () => {
      const currentTime = audio.currentTime;
      const relativeTime = currentTime >= inicio ? currentTime - inicio : 0;
      const porcentajeCarga = ((currentTime - inicio) / (termino - inicio)) * 100;

      this.setState({
        tiempoActual: Math.trunc(relativeTime),
        porcentajeCarga: porcentajeCarga,
      });

      if (currentTime >= termino) {
        audio.pause();
        audio.currentTime = inicio;
        this.setState({ paused: true, porcentajeCarga: 0, tiempoActual: 0 });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <GridContainer className="agendar-container">
          <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
            <img className="marca-agua"></img>
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2>Escuchar audio</h2>
                <p className="bordeAudio" style={{ marginTop: 20 }}>
                  <GridContainer className="agendar-container">
                    <GridItem xs={3}>
                      <div className="bordeAudio" style={{ padding: 3 }}>
                        <img src={audio_salusin}></img>
                      </div>
                    </GridItem>
                    <GridItem
                      xs={9}
                      style={{
                        display: "block",
                        alignContent: "center",
                        paddingLef: "0px !important",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <p style={{ textAlign: "center" }}>
                            <a style={{ cursor: "pointer" }} onClick={(e) => this.reproducirAudio()}>
                              {!this.state.paused ? (
                                <img
                                  style={{
                                    width: 60,
                                    paddingRight: 10,
                                  }}
                                  src={play}
                                ></img>
                              ) : (
                                <img
                                  style={{
                                    width: 60,
                                    paddingRight: 10,
                                  }}
                                  src={pause}
                                ></img>
                              )}
                            </a>
                          </p>
                        </div>
                        <div
                          style={{
                            width: "75%",
                            height: "5px",
                            backgroundColor: "rgb(167 159 159)",
                            borderRadius: "5px",
                            position: "relative",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleProgressClick(e);
                          }}
                        >
                          <div
                            style={{
                              width: this.state.porcentajeCarga + "%",
                              height: "5px",
                              backgroundColor: "#3e3c3c",
                              borderRadius: "5px",
                            }}
                          ></div>
                          <div>
                            <label style={{ float: "left" }}>{this.state.tiempoActual}s</label>
                            <label style={{ float: "right" }}>{this.state.duracion}s</label>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </p>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}
