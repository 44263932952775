import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class DerivarCaso extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      cesfam: null,
      gegId: props.gegId,
      urgencia: props.urgencia,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  derivarCaso = () => {
    if (this.state.cesfam === null) {
      return NotificationManager.warning("Seleccione a dónde desea derivar");
    }
    let cesfam = this.state.cesfam;

    if (cesfam === "noCovid") {
      axios
        .patch(
          global.config.modulos.gestion,
          {
            geg_id: this.state.gegId,
            geg_estado: 1,
            geg_ubicacion: 1,
            geg_tipo: 1,
            geg_marcar_derivado: this.state.urgencia,
            geg_tomado: null,
            geg_usp_deriva: this.state.uspId,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          NotificationManager.success("Caso derivado con éxito");
          this.generarHistorial("Se deriva a no covid", this.state.gegId, this.state.uspId);
          this.salir();
        });
    } else if (cesfam === "covid") {
      axios
        .patch(
          global.config.modulos.gestion,
          {
            geg_id: this.state.gegId,
            geg_estado: 1,
            geg_ubicacion: 1,
            geg_tipo: 2,
            geg_marcar_derivado: this.state.urgencia,
            geg_tomado: null,
            geg_usp_deriva: this.state.uspId,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          NotificationManager.success("Caso derivado con éxito");
          this.generarHistorial("Se deriva a covid", this.state.gegId, this.state.uspId);
          this.salir();
        });
    } else {
      axios
        .patch(
          global.config.modulos.gestion,
          {
            geg_id: this.state.gegId,
            geg_estado: 1,
            geg_ubicacion: 1,
            geg_cesfam: cesfam,
            geg_marcar_derivado: this.state.urgencia,
            geg_tomado: null,
            geg_usp_deriva: this.state.uspId,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          NotificationManager.success("Caso derivado con éxito");
          this.generarHistorial("Se deriva a cesfam ", this.state.gegId, this.state.uspId);
          this.salir();
        });
    }
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.salir();
      });
  };

  render() {
    const listaCesfams = [
      { id: 5, nombre: "Lo Amor" },
      { id: 6, nombre: "Cerro Navia" },
      { id: 7, nombre: "Albertz" },
      { id: 8, nombre: "Steeger" },
      { id: 9, nombre: "Los lagos" },
      { id: 10, nombre: "Schwarzenberg" },
      { id: "noCovid", nombre: "Gestión NO Covid 19" },
    ];
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Derivar caso</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Derivar a</InputLabel>
                    <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="cesfam" name="cesfam" value={this.state.cesfam} onChange={this.cambiarValores} label="Derivar a">
                      {listaCesfams.map((item, index) => {
                        return store.getState().cesfam != item.id ? (
                          <MenuItem key={index} value={item.id}>
                            {item.nombre}
                          </MenuItem>
                        ) : null;
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button style={{ marginLeft: 20 }} color="primary" onClick={this.derivarCaso} className="fondoVerde">
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
