import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import moment from "moment";
import "moment/locale/es";

import { store } from "../../../store/index";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Grid, List, ListItem } from "@material-ui/core";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

// core components
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialTable from "material-table";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import chileanRut from "chilean-rut";
//css
import crearTotem from "assets/css/crearTotem.module.css";

export default class DatosTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      open: true,
    };

    this.salir = () => {
      this.setState({
        open: false,
      });
      props.ocultar();
    };
  }
  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  componentDidMount() {}

  validaciones() {
    var rut = document.getElementById("rut").value;

    if (!rut) {
      this.mostrarAlerta("Escribe el rut.");
      return false;
    } else if (!chileanRut.validate(rut)) {
      this.mostrarAlerta("Escribe el rut válido.");
      return false;
    }

    return true;
  }

  buscar_usuario = () => {
    this.setState({ datos: false, usu_id: 0 });

    if (this.validaciones()) {
      var rut = document.getElementById("rut").value;

      var data = {
        usu_rut: rut,
      };

      axios.post(global.config.modulos.admin_totem + "tickets_por_rut/", data, { headers: authHeader() }).then((res) => {
        var data = res.data;
        var estado = data.estado;
        if (estado) {
          this.setState({ tickets: data.tickets });
        } else {
          this.mostrarAlerta("Paciente no tiene registros de tickets.");
        }

        this.setState({ datos: true });
      });
    }
  };

  tickets_por_usuario = (usu_id) => {
    axios.get(global.config.modulos.admin_totem + "tickets_por_usuario/?usuario=" + usu_id, { headers: authHeader() }).then((res) => {
      var data = res.data;
      var estado = data.estado;
      if (estado) {
        this.setState({ tickets: data.tickets });
      } else {
        this.mostrarAlerta("Paciente no tiene registros de tickets.");
      }
    });
  };

  render() {
    return (
      <>
        <h1 style={{ color: "#666666" }}>Tickets por paciente</h1>
        <br></br>
        <br></br>
        <Grid item xs={12} sm={12}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Grid item xs={2} sm={2}>
              <InputLabel className={crearTotem.textosTitulos}>RUT</InputLabel>
              <input type="text" id={"rut"} className={crearTotem.input} />
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              style={{
                display: "flex",
                marginLeft: "3%",
                alignItems: "flex-end",
              }}
            >
              <button
                id={"btnBuscar"}
                className={crearTotem.btnAgregarAcceso}
                style={{ width: "6vw" }}
                onClick={() => {
                  this.buscar_usuario();
                }}
              >
                {"Buscar"}
              </button>
            </Grid>
          </div>
        </Grid>

        {this.state.datos ? (
          <GridContainer style={{ width: "100%", marginTop: "10px", justifyContent: "space-between" }}>
            <GridItem xs={12} sm={12} md={12}>
              <div className={crearTotem.divBordeTablaAccesos} style={{ fontSize: "1rem !important" }}>
                <Grid item xs={12} sm={12}>
                  <MaterialTable
                    title=""
                    columns={[
                      {
                        title: "Día de obtención del ticket",
                        field: "dia_tick",
                      },
                      {
                        title: "Hora de obtención del ticket",
                        field: "hora_tick",
                      },
                      { title: "Módulo", field: "tit_nombre_opcion" },
                      { title: "Número de atención", field: "tit_codigo" },
                      {
                        title: "Atendido",
                        field: "tit_atendido",
                        render: (rowData) => <React.Fragment>{rowData.tit_atendido == 1 ? "SÍ" : "NO"}</React.Fragment>,
                      },
                      {
                        title: "Hora atendido",
                        field: "hora_tick_ate",
                        render: (rowData) => <React.Fragment>{rowData.hora_tick_ate ? rowData.hora_tick_ate + " hrs." : "--"}</React.Fragment>,
                      },
                      {
                        title: "Tiempo de espera",
                        field: "espera_ti",
                        render: (rowData) => <React.Fragment>{rowData.espera_ti ? rowData.espera_ti + " hrs." : "--"}</React.Fragment>,
                      },
                      {
                        title: "Duración de atención",
                        field: "tie_atencion",
                      },
                    ]}
                    data={this.state.tickets}
                    options={{
                      actionsColumnIndex: -1,
                      search: true,
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No tiene historial de atenciones",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                </Grid>
              </div>
            </GridItem>
          </GridContainer>
        ) : null}
      </>
    );
  }
}
