import "./config";
import React from "react";

import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
require("dotenv").config();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
        <NotificationContainer />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
