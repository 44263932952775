import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";
import axios from "axios";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";

import { Input } from "@material-ui/core";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import { DialogContentText } from "@material-ui/core";

import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class EditarFechaRegistro extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    var tratamiento = "0";
    if (props.tipo == 4 || props.tipo == 5) {
      tratamiento = "";
    }

    this.state = {
      loi_id: props.registro,
      tipo: props.tipo,
      escala: "0",
      patologia: props.patologia,
      tratamiento: tratamiento,

      tratamientos: [],
      tratamiento_secundarios: [],
      escalas_trat: [],

      mostrar_select: 0,
      trat_libre: 0,
      tratamiento_libre: "",

      dato: props.dato,
      fecha_registro: date,

      cesfam: store.getState().cesfam,
      usp_id: store.getState().usp,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  obtenerTratamientoSec = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamiento_secundarios: res.data.datos,
          });
        }
      });
  };

  obtenerEscalasTratamiento = () => {
    if (this.state.tratamiento == "63") {
      this.setState({ trat_libre: 1, tratamiento_libre: this.state.patologia });
    } else {
      this.setState({ trat_libre: 0 });
    }

    axios
      .get(global.config.modulos.listaEspera + "obtener_escalas_evaluacion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          especialidad: this.state.tratamiento,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            escalas_trat: res.data.datos,
            mostrar_select: 1,
          });
        } else {
          this.setState({
            mostrar_select: 0,
          });
        }
      });
  };

  componentDidMount() {
    this.obtenerTratamientos();
    this.obtenerTratamientoSec();
  }

  modificarTratamiento = () => {
    if (this.state.tratamiento == "0" || this.state.tratamiento === null) {
      NotificationManager.error("Debe ingresar un registro", "Atención", 3000);
      return;
    }

    if (this.state.info_obs_fija != null) {
      this.AlertaFijo();
      return;
    }

    var data = { loi_id: this.state.loi_id, usp_id: this.state.usp_id };

    if (this.state.tipo == 1) {
      data["loi_fecha_diagnostico"] = this.state.tratamiento;
    } else if (this.state.tipo == 2) {
      data["loi_tratamiento"] = this.state.tratamiento;
    } else if (this.state.tipo == 3) {
      data["loi_tratamiento_secundario"] = this.state.tratamiento;
    } else if (this.state.tipo == 4) {
      data["loi_fecharegistro"] = this.state.tratamiento;
      data["loi_fecha_invalida"] = 0;
    } else if (this.state.tipo == 5) {
      if (chileanRut.validate(this.state.tratamiento) === false) {
        NotificationManager.error("Rut incorrecto", "Atención", 3000);
        return;
      }

      data["loi_rut"] = this.state.tratamiento;
      data["loi_rut_valido"] = 0;
    }

    if (this.state.mostrar_select == 1) {
      if (this.state.escala == "0") {
        NotificationManager.error("Debe seleccionar una escala de evaluación", "Atención", 3000);
        return;
      } else {
        data["loi_escala_ev"] = this.state.escala;
      }
    } else {
      data["loi_escala_ev"] = null;
    }

    if (this.state.trat_libre == 1) {
      if (this.state.tratamiento_libre == "" || this.state.tratamiento_libre === null) {
        NotificationManager.error("Debe ingresar la patología", "Atención", 3000);
        return;
      } else {
        data["loi_tratamiento_libre"] = this.state.tratamiento_libre;
      }
    }

    axios.patch(global.config.modulos.listaEspera + "modificar_lista/", { data: data }, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Modificado con éxito ", "Atención", 3000);
        this.salir();
      } else {
        NotificationManager.error(res.data.mensaje, "Atención", 3000);
        return;
      }
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento, escala: "0" }, () => {
      this.obtenerEscalasTratamiento();
    });

    if (tratamiento == "63") {
      this.setState({ trat_libre: 1 });
    } else {
      this.setState({ trat_libre: 0 });
    }
  };

  tratamiento_sec = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });

    this.obtenerInfoTTo(tratamiento);
  };

  obtenerInfoTTo = (tratamiento) => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          tratamiento: tratamiento,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            info_obs_fija: res.data.datos[0]["lre_id_obs_fija"],
            mensaje_obs_fija: res.data.datos[0]["lre_mensaje_alerta"],
          });
        } else {
          this.setState({
            info_obs_fija: null,
          });
        }
      });
  };

  AlertaFijo = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{
              backgroundColor: "white",
              width: "100%",
              padding: "40px",
              borderRadius: "20px",
            }}
          >
            <h1
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              ¡Atención!
            </h1>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p>{this.state.mensaje_obs_fija}</p>
                <p>Se ingresara los datos ingresados al listado de otros casos</p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button
                    onClick={() => {
                      onClose();
                      this.otrosCasos();
                    }}
                    style={{ color: "#fff" }}
                    className="fondoNaranjo"
                  >
                    Aceptar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        );
      },
    });
  };

  otrosCasos = () => {
    var data = { loi_id: this.state.loi_id, usp_id: this.state.usp_id };
    data["loi_estado"] = 2;
    data["loi_motivo_eliminacion"] = 4;

    axios.patch(global.config.modulos.listaEspera + "modificar_lista/", { data: data }, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        this.salir();
      } else {
        NotificationManager.error(res.data.mensaje, "Atención", 3000);
        return;
      }
    });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalAgregarProfesional">
          <DialogContent
            style={{
              width: "500px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "20px" }}>{this.state.tipo == 1 ? <h2>Modificar Fecha de diagnóstico:</h2> : this.state.tipo == 2 ? <h2>Modificar diagnóstico </h2> : this.state.tipo == 3 ? <h2>Modificar resultado exploración de oídos</h2> : this.state.tipo == 4 ? <h2>Modificar Fecha de derivación</h2> : <h2>Modificar rut</h2>}</div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.tipo == 1 ? (
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl>
                      <Input type="date" name="tratamiento" value={this.state.tratamiento} onChange={this.cambiarValores} />
                    </FormControl>
                  </div>
                ) : this.state.tipo == 2 ? (
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
                      <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
                        <MenuItem value={"0"}>Seleccione Tratamiento</MenuItem>
                        {this.state.tratamientos.map((tratamiento, i) => {
                          return (
                            <MenuItem key={i} value={tratamiento.lt_id}>
                              {tratamiento.lt_nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <br></br>
                    <br></br>
                    <br></br>
                    {this.state.mostrar_select == 1 && (
                      <div>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Escala de evaluación</InputLabel>
                          <Select name="escala" value={this.state.escala} onChange={this.cambiarValores}>
                            <MenuItem value={"0"}>Seleccione escala de evaluación</MenuItem>
                            {this.state.escalas_trat.map((escala, i) => {
                              return (
                                <MenuItem key={i} value={escala.ev_id}>
                                  {escala.ev_nombre}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                    {this.state.trat_libre == 1 ? (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Indique patología</InputLabel>
                        <Input type="text" name="tratamiento_libre" value={this.state.tratamiento_libre} onChange={this.cambiarValores} />
                      </FormControl>
                    ) : null}
                  </div>
                ) : this.state.tipo == 3 ? (
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">Resultado examen</InputLabel>
                      <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento_sec}>
                        <MenuItem value={"0"}>Seleccione resultado de examen</MenuItem>
                        {this.state.tratamiento_secundarios.map((tt_sec, i) => {
                          return (
                            <MenuItem key={i} value={tt_sec.lre_id}>
                              {tt_sec.lre_nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                ) : this.state.tipo == 4 ? (
                  <div style={{ marginBottom: "5px" }}>
                    <h4>Razón fecha incorrecta: </h4>
                    <br></br>
                    <div>
                      <FormControl>
                        <Input type="date" name="tratamiento" value={this.state.tratamiento} onChange={this.cambiarValores} />
                      </FormControl>
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: "5px" }}>
                    <h4>Razón rut incorrecto: </h4>
                    <div>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Modifique el rut mal ingresado</InputLabel>
                        <Input type="text" name="tratamiento" value={this.state.tratamiento} onChange={this.cambiarValores} />
                      </FormControl>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: 10, backgroundColor: "#00ac77", color: "#fff" }} onClick={() => this.modificarTratamiento()}>
                    Modificar
                  </Button>
                  <Button onClick={this.salir} style={{ color: "#fff" }} className="fondoNaranjo">
                    Cancelar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
