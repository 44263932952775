import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "./../../../assets/css/graficos.css";

export default function GraficoTeleconsulta(props) {
  // This code will only run one time
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    var responsive = am5themes_Responsive.newEmpty(root);

    responsive.addRule({
      name: "AxisRendererY",
      relevant: am5themes_Responsive.widthL,
      settings: {
        inside: true,
      },
    });

    root.setThemes([am5themes_Animated.new(root), responsive]);

    root.locale = am5locales_es_ES;

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        maxTooltipDistance: 10,
        pinchZoomX: true,
      })
    );

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: props.llamadas,
      dataFields: {
        vls_fecha_creacion_sala: "Inicio de Llamada",
        vls_fecha_termino_llamada: "Termino de Llamada",
        usu_nombre: "Nombre paciente",
        usu_apellido: "Apellido paciente",
        usu_rut: "Rut paciente",
        pro_nombre: "Nombre profesional",
        pro_apellido: "Apellido profesional",
        especialidad: "Especialidad",
        duracion: "Duración de llamada",
      },
      dataFieldsOrder: ["Inicio de Llamada", "Término de Llamada", "Duración de llamada", "Nombre paciente", "Apellido paciente", "Rut paciente", "Nombre profesional", "Apellido profesional", "Especialidad"],
      pngOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      pdfOptions: {
        addURL: false,
      },
      jsonOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      title: "Hora y Cantidad TCS",
      filePrefix: props.filename,
    });

    exporting.events.on("pdfdocready", function (event) {
      event.doc.content.unshift({
        text: props.filename,
        margin: [0, 30],
        style: {
          fontSize: 25,
          bold: true,
        },
      });
    });

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: "second",
          count: 1,
        },
        groupData: true,
        groupCount: 50,
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    chart.get("colors").set("colors", [am5.color("#97caef"), am5.color("#73aad1"), am5.color("#3f78a1"), am5.color("#6d350f"), am5.color("#582300")]);

    // Create series
    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Todos los Canales",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value_total",
        valueXField: "date",
        legendValueText: "{valueY}",
        valueYGrouped: "sum",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}\nCantidad: {valueY}\nHora: {valueX}",
        }),
      })
    );

    var modal = am5.Modal.new(root, {
      content: "No se encontraron datos para estos filtros",
    });

    series.events.on("datavalidated", function (ev) {
      var series = ev.target;
      if (ev.target.data.length < 1) {
        modal.open();
      } else {
        modal.close();
      }
    });

    var series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Portal Web",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value_web",
        valueXField: "date",
        legendValueText: "{valueY}",
        valueYGrouped: "sum",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}\nCantidad: {valueY}\nHora: {valueX}",
        }),
      })
    );

    var series3 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "App Movil",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value_app",
        valueXField: "date",
        legendValueText: "{valueY}",
        valueYGrouped: "sum",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}\nCantidad: {valueY}\nHora: {valueX}",
        }),
      })
    );

    root.dateFormatter.setAll({
      dateFormat: "HH:mm:ss",
      dateFields: ["valueX"],
    });

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series2.strokes.template.setAll({
      strokeWidth: 3,
    });

    series3.strokes.template.setAll({
      strokeWidth: 3,
    });

    //console.log(props);
    series.data.processor = am5.DataProcessor.new(root, {
      numericFields: ["value"],
      dateFields: ["date"],
      dateFormat: "yyyy-MM-ddHH:mm:ss",
    });

    series2.data.processor = am5.DataProcessor.new(root, {
      numericFields: ["value"],
      dateFields: ["date"],
      dateFormat: "yyyy-MM-ddHH:mm:ss",
    });

    series3.data.processor = am5.DataProcessor.new(root, {
      numericFields: ["value"],
      dateFields: ["date"],
      dateFormat: "yyyy-MM-ddHH:mm:ss",
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
        }),
      });
    });

    series2.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series2.get("fill"),
        }),
      });
    });

    series3.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series3.get("fill"),
        }),
      });
    });

    series.data.setAll(props.data);
    series.appear();
    series2.data.setAll(props.data);
    series2.appear();
    series3.data.setAll(props.data);
    series3.appear();

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        width: 200,
        paddingLeft: 30,
        height: am5.percent(100),
      })
    );

    legend.itemContainers.template.events.on("pointerover", function (e) {
      let itemContainer = e.target;

      let series = itemContainer.dataItem.dataContext;

      chart.series.each(function (chartSeries) {
        if (chartSeries !== series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000),
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3,
          });
        }
      });
    });

    legend.itemContainers.template.events.on("pointerout", function (e) {
      let itemContainer = e.target;
      let series = itemContainer.dataItem.dataContext;

      chart.series.each(function (chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 2,
          stroke: chartSeries.get("fill"),
        });
      });
    });

    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right",
    });

    legend.data.setAll(chart.series.values);

    chart.appear(600, 100);

    return () => {
      root.dispose();
    };
  }, []);

  return <div id="chartdiv" class="armchResponsiveLine"></div>;
}
