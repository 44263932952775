import React, { useState, useEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons";
import { connect } from "react-redux";
import { store } from "./../../store/index";
import { handleError } from "./../../components/Helpers/helpers.js";

import Menu_oferta_demanda from "../../assets/img/imagenesMenu/reporteria.svg";
import Menu_demanda from "../../assets/img/imagenesMenu/gestion de demanda.svg";
import Menu_pacientes from "../../assets/img/imagenesMenu/gestion de paciente.svg";
import Menu_profesional from "../../assets/img/imagenesMenu/gestion profesional.svg";
import Menu_horas from "../../assets/img/imagenesMenu/fce externa.svg";
import Menu_apoyo_social from "../../assets/img/imagenesMenu/plan externo.svg";
import Menu_horarios from "../../assets/img/imagenesMenu/gestion totem.svg";
import Menu_pendiente from "../../assets/img/imagenesMenu/pendientes.svg";
import Menu_solicitudes from "../../assets/img/imagenesMenu/solicitudes.svg";
import Menu_funcionarios from "../../assets/img/imagenesMenu/gestion_funcionarios.svg";
import Menu_gestion_domiciliaria from "../../assets/img/imagenesMenu/gestion_entrega_domiciliaria.svg";

import { useHistory, useLocation } from "react-router-dom";
import { socketSesion, socketNotificacion } from "../../service/socket";
import { NotificationManager } from "react-notifications";
import { cambioCesfam } from "./../../store/actions/actions";
import { logoutUser } from "./../../store/actions/actions";
import SidebarStile from "../../assets/css/sidebar.module.css";

import { confirmAlert } from "react-confirm-alert"; // Import

import Submenu from "./Submenu";

import "assets/css/navbar.css";
import "assets/css/sidebar.css";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Navbar = ({ logoutUser, cambioCesfam }) => {
  const history = useHistory();
  const [estadoSubmenu, setEstadoSubmenu] = useState(false);
  const [datosSubmenu, setDatosSubmenu] = useState([]);
  const [espacioTop, setEspacioTop] = useState(0);
  const [usp, setUsp] = React.useState(store.getState().usp);
  const [herramientas, setHerramientas] = React.useState(store.getState().herramientas);

  const [cesfam, setCesfam] = React.useState(store.getState().cesfam);
  const [nombreusp, setNombreusp] = React.useState(store.getState().nombreusp);
  const [tipousp, setTipousp] = React.useState(store.getState().tipo);
  const [opcionesSelect, setOpcionesSelect] = React.useState("");
  const [comuna, setComuna] = React.useState(store.getState().cliente);
  const [imgCentro, setImgCentro] = React.useState(1);
  const [uspCargo, setUspCargo] = useState("");
  const [uspNombre, setUspNombre] = useState("");
  const [notificaciones, setNotificaciones] = useState([]);
  const [notificacionesSinLeer, setNotificacionesSinLeer] = useState(0);

  const [actSide, setActSide] = useState(SidebarStile.aSide);
  const [actSide1, setActSide1] = useState(SidebarStile.aSide1);
  const [actSide2, setActSide2] = useState(SidebarStile.aSide2);
  const [actSide3, setActSide3] = useState(SidebarStile.aSide3);
  const [actSide4, setActSide4] = useState(SidebarStile.aSide4);
  const [actSide5, setActSide5] = useState(SidebarStile.aSide5);
  const [actSide6, setActSide6] = useState(SidebarStile.aSide6);
  const [actSide7, setActSide7] = useState(SidebarStile.aSide7);
  const [actSide9, setActSide9] = useState(SidebarStile.aSide9);
  const [actSide10, setActSide10] = useState(SidebarStile.aSide10);
  const [actSide11, setActSide11] = useState(SidebarStile.aSide10);
  const [contadorGprofesional, setContadorGprofesional] = useState(0);
  const [contadorPlanExterno, setContadorPlanExterno] = useState(0);
  const [contadorReporteria, setContadorReporteria] = useState(0);
  const [contadorGpaciente, setContadorGpaciente] = useState(0);
  const [contadorPendiente, setContadorPendiente] = useState(0);
  const [contadorGdemanda, setContadorGdemanda] = useState(0);
  const [contadorFceExterna, setContadorFce] = useState(0);
  const [contadorGtotem, setContadorGtotem] = useState(0);
  const [contadorSolicitudes, setcontadorSolicitudes] = useState(0);
  const [contadorFuncionarios, setContadorFuncionarios] = useState(0);
  const [contadorGestionDom, setContadorGestionDom] = useState(0);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);
  const [menu3, setMenu3] = useState(false);
  const [menu4, setMenu4] = useState(false);
  const [menu5, setMenu5] = useState(false);
  const [menu6, setMenu6] = useState(false);
  const [menu7, setMenu7] = useState(false);
  const [menu8, setMenu8] = useState(false);
  const [menu9, setMenu9] = useState(false);
  const [menu10, setMenu10] = useState(false);
  const [menu11, setMenu11] = useState(false);

  useEffect(() => {
    cargarCesfams(usp);
    datosCesfam();
    traeNotificaciones();
    contadorHerramientas();

    socketSesion.emit("sessionAlive", { usuario_panel: usp });

    socketSesion.on("stayAlive", () => {
      socketSesion.emit("sessionAlive", { usuario_panel: usp });
    });

    socketSesion.on("mensajeUsp" + usp, (data) => {
      NotificationManager.info(data.mensaje);
    });

    socketSesion.on("cerrarSesion" + usp, () => {
      logoutUser();
    });
    socketSesion.on("mensajeUsp" + usp, (data) => {
      NotificationManager.info(data.mensaje);
    });

    socketNotificacion.on("notificacion", (data) => {
      if (data.uspId == usp) {
        traeNotificaciones();
      }
    });
  }, []);

  const datosCesfam = () => {
    axios
      .get(global.config.modulos.cesfams, { headers: authHeader(), params: { cesid: cesfam } })
      .then((res) => {
        setImgCentro(res.data.ces_tipo);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const cargarCesfams = (usp) => {
    axios
      .get(global.config.modulos.usuariospanel + `por_id/` + usp, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data) {
          const cesfamsVisibles = res.data["usp_cesfams_visibles"].split(",");
          setUspNombre(res.data["usp_nombre"] + " " + res.data["usp_apellido_p"]);
          setUspCargo(res.data["rol"]);

          axios
            .get(global.config.modulos.cesfams, { headers: authHeader() })
            .then((res) => {
              var opciones = "";

              if (cesfamsVisibles.length <= 1) {
                return false;
              }
              res.data.datos.map((reg) => {
                if (cesfamsVisibles.includes(reg.ces_id.toString())) {
                  if (reg.ces_id == cesfam) {
                    opciones += "<option style='text-align: center' selected value='" + reg.ces_id + ";" + reg.ces_comuna_id + "'>" + reg.ces_nombre + "</option>";
                  } else {
                    opciones += "<option value='" + reg.ces_id + ";" + reg.ces_comuna_id + "'>" + reg.ces_nombre + "</option>";
                  }
                }
              });
              setOpcionesSelect(opciones);
            })
            .catch((err) => {
              handleError(err);
            });
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    if (window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      if (window.location.pathname === "/graficos_generales") {
        const allElementsUp = document.querySelectorAll(".upper-area");
        allElementsUp.forEach((element) => {
          element.style.zIndex = "0";
        });
      } else {
        const allElementsUp = document.querySelectorAll(".upper-area");
        allElementsUp.forEach((element) => {
          element.style.zIndex = "45";
        });
      }

      let chat = document.getElementById("divPrincipal");
      chat.classList.add("divPrincipalChico");

      const allElements = document.querySelectorAll(".liMenu");
      allElements.forEach((element) => {
        element.classList.remove("liMenu");
      });
    }
  }, []);

  const traeNotificaciones = () => {
    axios
      .get(global.config.modulos.notificaciones + "por_usuario/", {
        headers: authHeader(),
        params: { usp: usp },
      })
      .then((res) => {
        if (res.data.estado) {
          setNotificaciones(res.data.datos);
          setNotificacionesSinLeer(res.data.datos.length);
        } else {
          setNotificaciones([]);
          setNotificacionesSinLeer(0);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const campioPath = (event) => {
    var path = event.target.value;

    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass();
    }

    if (path === "/graficos_generales" || path === "/nsp" || path === "/ranking") {
      if (path === "/graficos_generales") {
        const allElementsUp = document.querySelectorAll(".upper-area");
        allElementsUp.forEach((element) => {
          element.style.zIndex = "0";
        });
      } else {
        const allElementsUp = document.querySelectorAll(".upper-area");
        allElementsUp.forEach((element) => {
          element.style.zIndex = "45";
        });
      }

      let chat = document.getElementById("divPrincipal");
      chat.classList.add("divPrincipalChico");

      const allElements = document.querySelectorAll(".liMenu");
      allElements.forEach((element) => {
        element.classList.remove("liMenu");
      });

      history.push(path);
    } else {
      //1
      let chat = document.getElementById("divPrincipal");
      chat.classList.remove("divPrincipalChico");

      const allElementsUp = document.querySelectorAll(".upper-area");
      allElementsUp.forEach((element) => {
        element.style.zIndex = "45";
      });

      history.push(path);
    }
  };

  const clickSideMenu1 = () => {
    setMenu1(true);

    escalera();
  };

  const escalera = () => {
    setTimeout(() => {
      if (window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
        const allElements = document.querySelectorAll(".liMenu");
        allElements.forEach((element) => {
          element.classList.add("liMenuChico");
        });

        const allElements2 = document.querySelectorAll(".liMenu2");
        allElements2.forEach((element) => {
          element.classList.add("liMenu2Chico");
        });
      }

      const allElements = document.querySelectorAll(".botonesSideBar");
      var x = 1;
      var y = 2;
      allElements.forEach((element) => {
        setTimeout(() => {
          element.style.display = "block";
          setTimeout(() => {
            element.classList.add("botonesSideBarMost");
          }, x + "0");
        }, y + "0");
        x = x + 4;
        y = y + 6;
      });
    }, "20");
  };

  const mouseSideMenu1 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(1);

      let chat = document.getElementById("side-gestion-paciente");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu2 = () => {
    setMenu2(true);

    escalera();
  };

  const mouseSideMenu2 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(2);

      let chat = document.getElementById("side-gestion-profesional");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu3 = () => {
    setMenu3(true);

    escalera();
  };

  const mouseSideMenu3 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(3);

      let chat = document.getElementById("side-gestion-demanda");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu4 = () => {
    setMenu4(true);

    escalera();
  };

  const mouseSideMenu4 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(4);

      let chat = document.getElementById("side-gestion-externa");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu5 = () => {
    setMenu5(true);

    escalera();
  };

  const mouseSideMenu5 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(5);

      let chat = document.getElementById("side-gestion-plan-externo");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu6 = () => {
    setMenu6(true);

    escalera();
  };

  const mouseSideMenu6 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(6);

      let chat = document.getElementById("side-gestion-metricas");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu7 = () => {
    setMenu7(true);

    escalera();
  };

  const mouseSideMenu7 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(7);

      let chat = document.getElementById("side-gestion-totem");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu8 = () => {
    setMenu8(true);

    escalera();
  };

  const mouseSideMenu8 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches || window.location.pathname === "/graficos_generales" || window.location.pathname === "/nsp" || window.location.pathname === "/ranking") {
      removerClass(8);

      let chat = document.getElementById("side-gestion-pendientes");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu9 = () => {
    setMenu9(true);

    escalera();
  };

  const mouseSideMenu9 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches) {
      removerClass(9);

      let chat = document.getElementById("side-gestion-solicitudes");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu10 = () => {
    setMenu10(true);

    escalera();
  };

  const mouseSideMenu10 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches) {
      removerClass(10);

      let chat = document.getElementById("side-gestion-funcionarios");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const clickSideMenu11 = () => {
    setMenu11(true);

    escalera();
  };

  const mouseSideMenu11 = () => {
    var x = window.matchMedia("(max-width: 1450px)");
    if (x.matches) {
      removerClass(11);

      let chat = document.getElementById("side-gestion-domiciliaria");
      chat.classList.add("sideBarVisualizar");
    }
  };

  const handleLeaveAside = () => {
    setActSide(SidebarStile.aSide);

    let chat = document.getElementById("side-gestion-paciente");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside1 = () => {
    setActSide1(SidebarStile.aSide1);

    let chat = document.getElementById("side-gestion-profesional");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside2 = () => {
    setActSide2(SidebarStile.aSide2);

    let chat = document.getElementById("side-gestion-demanda");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside3 = () => {
    setActSide3(SidebarStile.aSide3);

    let chat = document.getElementById("side-gestion-externa");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside4 = () => {
    setActSide4(SidebarStile.aSide4);

    let chat = document.getElementById("side-gestion-plan-externo");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside5 = () => {
    setActSide5(SidebarStile.aSide5);

    let chat = document.getElementById("side-gestion-metricas");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside6 = () => {
    setActSide6(SidebarStile.aSide6);

    let chat = document.getElementById("side-gestion-totem");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside7 = () => {
    setActSide7(SidebarStile.aSide7);

    let chat = document.getElementById("side-gestion-pendientes");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside9 = () => {
    setActSide9(SidebarStile.aSide9);

    let chat = document.getElementById("side-gestion-solicitudes");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside10 = () => {
    setActSide10(SidebarStile.aSide10);

    let chat = document.getElementById("side-gestion-funcionarios");
    chat.classList.remove("sideBarVisualizar");
  };

  const handleLeaveAside11 = () => {
    setActSide11(SidebarStile.aSide11);

    let chat = document.getElementById("side-gestion-domiciliaria");
    chat.classList.remove("sideBarVisualizar");
  };

  const removerClass = (tipo) => {
    const allElements = document.querySelectorAll("*");

    allElements.forEach((element) => {
      element.classList.remove("sideBarVisualizar");
    });

    if (tipo != 1) {
      handleLeaveMenu1();
    }
    if (tipo != 2) {
      handleLeaveMenu2();
    }
    if (tipo != 3) {
      handleLeaveMenu3();
    }
    if (tipo != 4) {
      handleLeaveMenu4();
    }
    if (tipo != 5) {
      handleLeaveMenu5();
    }
    if (tipo != 6) {
      handleLeaveMenu6();
    }
    if (tipo != 7) {
      handleLeaveMenu7();
    }
    if (tipo != 8) {
      handleLeaveMenu8();
    }
    if (tipo != 9) {
      handleLeaveMenu9();
    }
    if (tipo != 10) {
      handleLeaveMenu10();
    }
    if (tipo != 11) {
      handleLeaveMenu11();
    }
  };

  const handleLeaveMenu1 = () => {
    setMenu1(false);
  };

  const handleLeaveMenu2 = () => {
    setMenu2(false);
  };

  const handleLeaveMenu3 = () => {
    setMenu3(false);
  };

  const handleLeaveMenu4 = () => {
    setMenu4(false);
  };

  const handleLeaveMenu5 = () => {
    setMenu5(false);
  };

  const handleLeaveMenu6 = () => {
    setMenu6(false);
  };

  const handleLeaveMenu7 = () => {
    setMenu7(false);
  };

  const handleLeaveMenu8 = () => {
    setMenu8(false);
  };

  const handleLeaveMenu9 = () => {
    setMenu9(false);
  };

  const handleLeaveMenu10 = () => {
    setMenu10(false);
  };

  const handleLeaveMenu11 = () => {
    setMenu11(false);
  };

  const contadorHerramientas = () => {
    let gestionpaciente = 1;
    let gestion_profesional = 0;
    let gestion_demanda = 0;
    let fce_externa = 0;
    let plan_externo = 0;
    let reporteria = 0;
    let gestion_totem = 0;
    let pendiente = 0;
    let solicitudes = 0;
    let funcionarios = 0;
    let gestion_domiciliaria = 0;

    SidebarData.map((item) => {
      if (item.grupo === "gestion_paciente" && herramientas.includes(item.sigla) && item.estado === "1") {
        gestionpaciente = gestionpaciente + 1;
      }
      if (item.grupo === "gestion_profesional" && herramientas.includes(item.sigla) && item.estado === "1") {
        gestion_profesional = gestion_profesional + 1;
      }
      if (item.grupo === "gestion_demanda" && herramientas.includes(item.sigla) && item.estado === "1") {
        gestion_demanda = gestion_demanda + 1;
      }
      if (item.grupo === "fce_externa" && herramientas.includes(item.sigla) && item.estado === "1") {
        fce_externa = fce_externa + 1;
      }
      if (item.grupo === "plan_externo" && herramientas.includes(item.sigla) && item.estado === "1") {
        plan_externo = plan_externo + 1;
      }
      if (item.grupo === "reporteria" && herramientas.includes(item.sigla) && item.estado === "1") {
        reporteria = reporteria + 1;
      }
      if (item.grupo === "gestor_totem" && herramientas.includes(item.sigla) && item.estado === "1") {
        gestion_totem = gestion_totem + 1;
      }
      if (item.grupo === "pendiente" && herramientas.includes(item.sigla) && item.estado === "1") {
        pendiente = pendiente + 1;
      }
      if (item.grupo === "solicitudes" && herramientas.includes(item.sigla) && item.estado === "1") {
        solicitudes = solicitudes + 1;
      }
      if (item.grupo === "gestion_funcionarios" && herramientas.includes(item.sigla) && item.estado === "1") {
        funcionarios = funcionarios + 1;
      }
      if (item.grupo === "gestion_domiciliaria" && herramientas.includes(item.sigla) && item.estado === "1") {
        gestion_domiciliaria = gestion_domiciliaria + 1;
      }
    });

    setContadorGpaciente(gestionpaciente);
    setContadorGprofesional(gestion_profesional);
    setContadorGdemanda(gestion_demanda);
    setContadorFce(fce_externa);
    setContadorPlanExterno(plan_externo);
    setContadorReporteria(reporteria);
    setContadorGtotem(gestion_totem);
    setContadorPendiente(pendiente);
    setcontadorSolicitudes(solicitudes);
    setContadorFuncionarios(funcionarios);
    setContadorGestionDom(gestion_domiciliaria);
  };

  let contador = 0;

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        {estadoSubmenu ? <Submenu submenuData={datosSubmenu} espacioTop={espacioTop} /> : null}

        <div id="divPrincipal" className={SidebarStile.divPrincipal}>
          <ul style={{ maxHeight: "90%" }}>
            {contadorGpaciente !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-paciente"
                    className={SidebarStile.actSide}
                    onClick={() => {
                      clickSideMenu1();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu1();
                    }}
                  >
                    <img src={Menu_pacientes} className={SidebarStile.selectSideImg}></img>
                    <button onChange={campioPath} className={SidebarStile.selectSideGrande}>
                      Gestion del Paciente
                    </button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 200),
              menu1 && (
                <ClickAwayListener onClickAway={handleLeaveMenu1}>
                  <li id="li-gestion-paciente" className={[SidebarStile.Menu1, SidebarStile.g1].join(" ")}>
                    <a id="a-gestion-paciente" className={"liMenu"}>
                      <button value="/buscarRut" onClick={campioPath} className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")} style={{ transition: "right " + contador + "ms" }}>
                        Pacientes
                      </button>
                      {SidebarData.map((item) => {
                        if (item.grupo === "gestion_paciente" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorGprofesional !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside1}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-profesional"
                    className={actSide1}
                    onClick={() => {
                      clickSideMenu2();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu2();
                    }}
                  >
                    <img src={Menu_profesional} className={SidebarStile.selectSideImg}></img>
                    <button className={SidebarStile.selectSideGrande1} onClick={() => {}}>
                      Gestion del Profesional
                    </button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu2 && (
                <ClickAwayListener onClickAway={handleLeaveMenu2}>
                  <li id="li-gestion-profesional" className={[SidebarStile.Menu1, SidebarStile.g2].join(" ")}>
                    <a id="a-gestion-profesional" className={"liMenu"}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "gestion_profesional" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorGdemanda !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside2}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-demanda"
                    className={actSide2}
                    onClick={() => {
                      clickSideMenu3();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu3();
                    }}
                  >
                    <img src={Menu_demanda} className={SidebarStile.selectSideImg}></img>
                    <button className={SidebarStile.selectSideGrande1}>Gestion de la Demanda</button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu3 && (
                <ClickAwayListener onClickAway={handleLeaveMenu3}>
                  <li id="li-gestion-demanda" className={[SidebarStile.Menu1, SidebarStile.g3].join(" ")}>
                    <a id="a-gestion-demanda" className={"liMenu"}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "gestion_demanda" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorFceExterna !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside3}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-externa"
                    className={actSide3}
                    onClick={() => {
                      clickSideMenu4();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu4();
                    }}
                  >
                    <img src={Menu_horas} className={SidebarStile.selectSideImg}></img>
                    <button onChange={campioPath} className={SidebarStile.selectSide}>
                      Fce Externa
                    </button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu4 && (
                <ClickAwayListener onClickAway={handleLeaveMenu4}>
                  <li id="li-gestion-externa" className={[SidebarStile.Menu1, SidebarStile.g4].join(" ")}>
                    <a id="a-gestion-externa" className={"liMenu"}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "fce_externa" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorPlanExterno !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside4}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-plan-externo"
                    className={actSide4}
                    onClick={() => {
                      clickSideMenu5();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu5();
                    }}
                  >
                    <img src={Menu_apoyo_social} className={SidebarStile.selectSideImg}></img>
                    <button className={SidebarStile.selectSide}>Plan Externo</button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu5 && (
                <ClickAwayListener onClickAway={handleLeaveMenu5}>
                  <li id="li-gestion-plan-externo" className={[SidebarStile.Menu1, SidebarStile.g5].join(" ")}>
                    <a id="a-gestion-plan-externo" className={"liMenu"}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "plan_externo" && herramientas.includes(item.sigla) && item.estado === "1" && comuna == 1) {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        } else {
                          if (item.grupo === "plan_externo" && herramientas.includes(item.sigla) && item.estado === "1" && item.sigla !== "V12") {
                            contador = contador + 200;
                            return (
                              <button
                                value={item.path}
                                onClick={campioPath}
                                className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                                style={{
                                  transition: "right " + contador + "ms",
                                }}
                              >
                                {item.title}
                              </button>
                            );
                          }
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorReporteria !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside5}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-metricas"
                    className={actSide5}
                    onClick={() => {
                      clickSideMenu6();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu6();
                    }}
                  >
                    <img src={Menu_oferta_demanda} className={SidebarStile.selectSideImg}></img>
                    <button className={SidebarStile.selectSide}>Reportería</button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {menu6 && (
              <ClickAwayListener onClickAway={handleLeaveMenu6}>
                <li id="li-gestion-metricas" className={[SidebarStile.Menu1, SidebarStile.g6].join(" ")}>
                  <a id="a-gestion-metricas" className={"liMenu"}>
                    {SidebarData.map((item) => {
                      if (item.grupo === "reporteria" && herramientas.includes(item.sigla) && item.estado === "1") {
                        contador = contador + 200;
                        return (
                          <button value={item.path} onClick={campioPath} className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")} style={{ transition: "right " + contador + "ms" }}>
                            {item.title}
                          </button>
                        );
                      }
                    })}
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {contadorGtotem !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside6}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-totem"
                    className={actSide6}
                    onClick={() => {
                      clickSideMenu7();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu7();
                    }}
                  >
                    <img src={Menu_horarios} className={SidebarStile.selectSideImg}></img>
                    <button onChange={campioPath} className={SidebarStile.selectSideGrande}>
                      Gestor de Tótem
                    </button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu7 && (
                <ClickAwayListener onClickAway={handleLeaveMenu7}>
                  <li id="li-gestion-totem" className={[SidebarStile.Menu1, SidebarStile.g7].join(" ")}>
                    <a id="a-gestion-totem" className={"liMenu"}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "gestor_totem" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorPendiente !== 0 && tipousp === 1 && (
              <ClickAwayListener onClickAway={handleLeaveAside7}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-pendientes"
                    className={actSide7}
                    onClick={() => {
                      clickSideMenu8();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu8();
                    }}
                  >
                    <img src={Menu_pendiente} className={SidebarStile.selectSideImg}></img>
                    <button onChange={campioPath} className={SidebarStile.selectSide}>
                      Pendientes
                    </button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu8 && (
                <ClickAwayListener onClickAway={handleLeaveMenu8}>
                  <li id="li-gestion-pendientes" className={SidebarStile.Menu1}>
                    <a id="a-gestion-pendientes" className={"liMenu"}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "pendiente" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorSolicitudes !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside9}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-solicitudes"
                    className={actSide9}
                    onClick={() => {
                      clickSideMenu9();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu9();
                    }}
                  >
                    <img src={Menu_solicitudes} className={SidebarStile.selectSideImg}></img>
                    <button className={SidebarStile.selectSideGrande1}>Solicitudes</button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu9 && (
                <ClickAwayListener onClickAway={handleLeaveMenu9}>
                  <li id="li-gestion-solicitudes" className={[SidebarStile.Menu1, SidebarStile.g1].join(" ")}>
                    <a id="a-gestion-solicitudes" className={SidebarStile.liMenu}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "solicitudes" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorFuncionarios !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside10}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-funcionarios"
                    className={actSide10}
                    onClick={() => {
                      clickSideMenu10();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu10();
                    }}
                  >
                    <img src={Menu_funcionarios} className={SidebarStile.selectSideImg}></img>
                    <button onChange={campioPath} className={SidebarStile.selectSideGrande}>
                      Gestor de accesos
                    </button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu10 && (
                <ClickAwayListener onClickAway={handleLeaveMenu10}>
                  <li id="li-gestion-funcionarios" className={SidebarStile.Menu1}>
                    <a id="a-gestion-funcionarios" className={SidebarStile.liMenu}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "gestion_funcionarios" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }

            {contadorGestionDom !== 0 && (
              <ClickAwayListener onClickAway={handleLeaveAside11}>
                <li style={{ cursor: "pointer" }} className={SidebarStile.liside}>
                  <a
                    id="side-gestion-domiciliaria"
                    className={actSide11}
                    onClick={() => {
                      clickSideMenu11();
                    }}
                    onMouseOver={() => {
                      mouseSideMenu11();
                    }}
                  >
                    <img src={Menu_gestion_domiciliaria} className={SidebarStile.selectSideImg}></img>
                    <button onChange={campioPath} className={SidebarStile.selectSideGrande}>
                      Gestion de entrega domiciliaria
                    </button>
                  </a>
                </li>
              </ClickAwayListener>
            )}

            {
              ((contador = 0),
              menu11 && (
                <ClickAwayListener onClickAway={handleLeaveMenu11}>
                  <li id="li-gestion-domiciliaria" className={SidebarStile.Menu1}>
                    <a id="a-gestion-domiciliaria" className={SidebarStile.liMenu}>
                      {SidebarData.map((item) => {
                        if (item.grupo === "gestion_domiciliaria" && herramientas.includes(item.sigla) && item.estado === "1") {
                          contador = contador + 200;
                          return (
                            <button
                              value={item.path}
                              onClick={campioPath}
                              className={(SidebarStile.sideBar_liMenu_button, "botonesSideBar")}
                              style={{
                                transition: "right " + contador + "ms",
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        }
                      })}
                    </a>
                  </li>
                </ClickAwayListener>
              ))
            }
          </ul>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default connect(null, { logoutUser, cambioCesfam })(Navbar);
