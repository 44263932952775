import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import TableCell from "@material-ui/core/TableCell";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import chileanRut from "chilean-rut";
import TextField from "@material-ui/core/TextField";
import { debounce } from "lodash";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AgregarDifusion from "../Difusiones/AgregarDifusion";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default class AsistidaDetalle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfamId: store.getState().cesfam,

      open: true,

      ingresos: [{ id: 0, atencionSelect: null, rut: "", fechaSelect: null }],

      atencionOpciones: [],

      /* cesfams:[],
        cesfamSelect: null, */

      /* sectores:[],
        sectorSelect: null, */

      maximoIngresos: false,

      cesfam: false,
      sector: false,

      errorValid: false,
    };
  }

  componentDidMount = async () => {
    this.cargarData();
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  obtenerListaSectores = async (cesId) => {
    let apiUrl = global.config.modulos.metas + "lista_sectores_metas/?cesfam=" + cesId;
    var res = await axios(apiUrl, { headers: authHeader() });
    let sec = [];
    let val = 0;
    res.data.sectores.map((item) => {
      sec.push({
        label: item.sec_nombre,
        val: val,
        id: item.sec_id,
        name: "sectorSelect",
      });
      val++;
    });
    return sec;
  };

  obtenerListaCesfams = async () => {
    let apiUrl = global.config.modulos.metas + "lista_cesfams_metas/?cesfam=" + this.state.cesfamId;
    var res = await axios(apiUrl, { headers: authHeader() });
    let ces = [];
    let val = 0;
    this.setState({ comunaSelect: res.data.comuna });
    res.data.cesfams.map((item) => {
      ces.push({
        label: item.ces_nombre,
        val: val,
        id: item.ces_id,
        name: "cesfamSelect",
      });
      val++;
    });
    return ces;
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }
    const soloNumeros = ["cantidad"];
    if (soloNumeros.includes(name)) {
      value = value.replace(/[^0-9]/g, "");
    }

    this.setState({ [name]: value });
  };

  cambiarRut = (ingreso, index, e) => {
    let { name, value } = e.target;
    let newIngresos = this.state.ingresos;
    newIngresos[index].rut = value;
    this.setState({ ingresos: newIngresos });
  };

  cambiarSeleccion(ingreso, index, event, value) {
    const name = value.name;
    let newIngresos = this.state.ingresos;
    newIngresos[index][name] = value;
    this.setState({ ingresos: newIngresos });
  }

  cambioFecha = (ingreso, index, e) => {
    let newIngresos = this.state.ingresos;
    newIngresos[index].fechaSelect = e;
    this.setState({ ingresos: newIngresos });
  };

  validaciones() {
    let formErrors = {};
    let validado = true;

    const { ingresos } = this.state;

    if (ingresos.length < 1) {
      validado = false;
      formErrors["ingresosErr"] = "Debe haber al menos un ingreso";
    } else {
      ingresos.map((ing, i) => {
        if (!ing.atencionSelect) {
          validado = false;
          formErrors[`atencionErr${ing.value}`] = "Debe seleccionar una atención";
        }
        if (!ing.rut) {
          validado = false;
          formErrors[`rutErr${ing.value}`] = "Debe ingresar un rut";
        }

        if (!chileanRut.validate(ing.rut)) {
          validado = false;
          formErrors[`rutErr${ing.value}`] = "Debe ingresar un rut válido";
        }

        if (!ing.fechaSelect) {
          validado = false;
          formErrors[`fechaErr${ing.value}`] = "Debe ingresar una fecha";
        }
      });
    }

    this.setState({ errorValid: formErrors });
    return validado;
  }

  cargarData = async () => {
    const met_id = this.props.met.met_id;
    const ces = this.props.met.met_cesfam ? this.props.met.met_cesfam : this.state.cesfamId;
    let apiUrl = global.config.modulos.metas + "meta_por_id/" + met_id + "?cesfam=" + ces;
    axios.get(apiUrl, { headers: authHeader() }).then(async (res) => {
      let atencionOpciones = [];
      let at_val = 0;
      res.data.atencion.map((item) => {
        atencionOpciones.push({
          label: item.atencion,
          val: at_val,
          id: item.id,
          name: "atencionSelect",
        });
        at_val++;
      });

      this.setState({ atencionOpciones: atencionOpciones });
    });
  };

  guardarAsisDetalle() {
    if (this.validaciones()) {
      let ingresos = [];

      this.state.ingresos.map((ing, i) => {
        ingresos.push({
          mad_rut: ing.rut,
          mad_tipo_atencion: ing.atencionSelect.id,
          mad_fecha: moment(ing.fechaSelect).format(),
          mad_usp: this.state.uspId,
        });
      });
      var data = {
        ingresos: ingresos,
      };
      let apiUrl = global.config.modulos.metas + "agregar_asistida_detalle/" + this.props.met.met_id;
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        NotificationManager.info("Asistida Detalle creada correctamente");
        this.salir();
      });
    }
  }

  render() {
    const { errorValid } = this.state;
    const cargarArchivo = (e) => {
      this.setState({
        archivo: e,
      });
    };

    const agregarIngreso = () => {
      if (!this.state.maximoIngresos) {
        this.setState({ ingresos: [...this.state.ingresos, { id: this.state.ingresos.length, atencionSelect: null, rut: "", fechaSelect: null }] }, () => {
          if (this.state.ingresos.length === 9) {
            this.setState({ maximoIngresos: true });
          }
        });
      }
    };

    const quitarOpcion = (o) => {
      this.setState({ ingresos: this.state.ingresos.filter((item) => item != o) }, () => {
        if (this.state.ingresos.length < 9) {
          this.setState({ maximoIngresos: false });
        }
      });
    };

    return (
      <>
        <Dialog open={this.state.open} onClose={this.salir} className="modificarUsuario">
          <DialogContent>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>Asistida Manual Detalle</h1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 10 }}>
                  <TableCell style={{ textAlign: "center" }}>
                    <h3>Nombre Meta:</h3>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "normal" }}> {this.props.met.met_nombre}</h3>
                  </TableCell>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              {errorValid["ingresosErr"] ? (
                <>
                  <GridItem xs={12} style={{ marginTop: 15 }}>
                    <GridContainer className="">
                      <GridItem xs={12} style={{ marginTop: 20, marginLeft: 10 }}>
                        <div style={{ color: "red", paddingBottom: 10 }}>{errorValid["ingresosErr"]}</div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ) : null}
              {this.state.ingresos.map(
                (item, index) => (
                  <GridItem xs={12} style={{ marginTop: 10 }}>
                    <GridContainer className="">
                      <GridItem xs={2} style={{ marginLeft: 10 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                          <KeyboardDatePicker
                            style={{ marginTop: 10 }}
                            disableToolbar
                            invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                            disableFuture="true"
                            views={["year", "month", "date"]}
                            value={this.state.ingresos[index].fechaSelect}
                            initialFocusedDate={this.state.ingresos[index].fechaSelect}
                            format="yyyy-MM-dd"
                            id="date-picker-inline"
                            label="Fecha"
                            name="fecha"
                            onChange={this.cambioFecha.bind(this, item, index)}
                            KeyboardButtonProps={{
                              "aria-label": "cambiar fecha",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {errorValid[`fechaErr${item.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`fechaErr${item.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={3} style={{ marginTop: 10 }}>
                        <InputLabel>R.U.T.</InputLabel>
                        <input type="rut" maxLength={10} name="rut" value={this.state.ingresos[index].rut} onChange={this.cambiarRut.bind(this, item, index)} placeholder="" className="inputText" />
                        {errorValid[`rutErr${item.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`rutErr${item.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={3} style={{ marginTop: 10, marginLeft: 10 }}>
                        <Autocomplete name="atencion" disableClearable id="periodoAtencion" options={this.state.atencionOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this, item, index)} value={this.state.ingresos[index].atencionSelect} renderInput={(params) => <TextField {...params} label="Tipo de Atencion" />}></Autocomplete>
                        {errorValid[`atencionErr${item.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`atencionErr${item.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={2} style={{ marginLeft: 15, marginTop: 20 }}>
                        <Button style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={quitarOpcion.bind(this, item)}>
                          Eliminar
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                ),
                this
              )}
              <GridContainer className="">
                <GridItem xs={12} style={{ marginLeft: 10, marginTop: 30, display: "flex", justifyContent: "flex-start" }}>
                  <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} className="botonGeneral" onClick={agregarIngreso}>
                    Agregar más
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} className="fondoVerde" onClick={this.guardarAsisDetalle.bind(this)}>
                  Confirmar
                </Button>
                <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.salir}>
                  Cancelar
                </Button>
              </GridItem>
            </GridItem>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
