import React, { act, Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GridContainer from "components/grid/GridContainer";
import { Grid } from "@material-ui/core";
import GridItem from "components/grid/GridItem";
import Stylos from "assets/css/graf.module.css";
import { Height } from "@material-ui/icons";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Grafico1 from "components/Graficos/CallCenter/Grafico";
import Grafico2 from "components/Graficos/CallCenter/Grafico2";
import Grafico3 from "components/Graficos/CallCenter/Grafico3";
import Grafico4 from "components/Graficos/CallCenter/Grafico4";
import { Button } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Select } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import DatePicker from "react-multi-date-picker";
import Ima from "assets/img/graficoCall/ima.png";

export default class GraficosCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      items: [],
      botonActivo: "",
      graficosSalida: false,
      graficosEntrada: false,
      tipoFiltro: "",
      fec_act: null,
      fec_ini: null,
      fec_ter: null,
      fecha_actual: "",
      fecha_inicio: "",
      fecha_termino: "",
      fecha_semana: "",
      fecha_mes: "",
      fecha_año: "",
      mostrarFiltro: false,
      activeIndex: undefined,
      comuna: null,
    };
  }

  handleButtonClick = (boton) => {
    this.setState({ botonActivo: boton });
  };

  handleItemClick = (index) => {
    this.setState(index === this.state.activeIndex ? null : { activeIndex: index });
  };

  nextSlide = () => {
    this.setState(
      (prevState) => ({
        currentIndex: (prevState.currentIndex + 1) % this.state.items.length,
      }),
      () => {
        this.setState((prevState) => ({
          activeIndex: prevState.activeIndex === 0 ? this.state.items.length - 1 : prevState.activeIndex - 1,
        }));
      }
    );
  };

  handleChange = (event) => {
    this.setState(
      {
        tipoFiltro: event.target.value,
        mostrarFiltro: false,
        fec_act: null,
        fec_ini: null,
        fec_ter: null,
        fecha_actual: "",
        fecha_inicio: "",
        fecha_termino: "",
        fecha_semana: "",
        fecha_mes: "",
        fecha_año: "",
      },
      () => {
        this.setState({
          mostrarFiltro: true,
        });
      }
    );
  };

  prevSlide = () => {
    this.setState(
      (prevState) => ({
        currentIndex: prevState.currentIndex === 0 ? this.state.items.length - 1 : prevState.currentIndex - 1,
      }),
      () => {
        this.setState((prevState) => ({
          activeIndex: (prevState.activeIndex + 1) % this.state.items.length,
        }));
      }
    );
  };

  formatearNumero = (numero) => {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  traerDatos = () => {
    axios.get(global.config.modulos.grafico_demanda + "tiempo_llamadas/", { headers: authHeader() }).then((response) => {
      if (!response.data.estado) {
        NotificationManager.error(response.data.mensaje, "ERROR", 0);
      } else {
        this.setState({ items: response.data.datos });
      }
    });
  };
  actualizarAño = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: "",
          fecha_termino: "",
          fec_ini: "",
          fec_ter: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState({
            fecha_año: fecha["year"],
          });
        }
      );
    }
  };

  actualizarFechaInicial = (fecha) => {
    if (fecha) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: "",
          fecha_termino: "",
          fec_ini: "",
          fec_ter: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState({
            fecha_actual: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
            fec_act: fecha,
          });
        }
      );
    }
  };

  actualizarSemana = (e) => {
    if (e) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: null,
          fecha_termino: null,
          fec_ini: null,
          fec_ter: null,
          fecha_semana: null,
          fecha_mes: null,
          fecha_año: null,
        },
        () => {
          this.setState({
            fecha_semana: e.target.value,
          });
        }
      );
    }
  };

  actualizarMes = (e) => {
    if (e) {
      this.setState(
        {
          fecha_actual: null,
          fec_act: null,
          fecha_inicio: "",
          fecha_termino: "",
          fec_ini: "",
          fec_ter: "",
          fecha_semana: "",
          fecha_mes: "",
          fecha_año: "",
        },
        () => {
          this.setState({
            fecha_mes: e.target.value,
          });
        }
      );
    }
  };

  buscarDatos = () => {
    if (this.state.botonActivo === "salida")
      this.setState({ graficosSalida: false, graficosEntrada: false }, () => {
        this.setState({ graficosSalida: true });
      });
    else if (this.state.botonActivo === "entrada") {
      this.setState({ graficosEntrada: false, graficosSalida: false }, () => {
        this.setState({ graficosEntrada: true });
      });
    } else {
      NotificationManager.error("Seleccione un tipo de llamada");
    }
  };

  componentDidMount() {
    this.traerDatos();
  }

  getVisibleItems = () => {
    const { currentIndex, items } = this.state;
    const visibleItems = [...items.slice(currentIndex, currentIndex + 9), ...items.slice(0, Math.max(0, currentIndex + 9 - items.length))];
    return visibleItems;
  };

  render() {
    const visibleItems = this.getVisibleItems();

    return (
      <div className="middle-area">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={Stylos.imgIzquierda} onClick={this.prevSlide}></div>

              <div
                style={{
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    transition: "transform 0.5s ease",
                  }}
                >
                  {visibleItems.map((itemClass, index) => (
                    <div
                      key={index}
                      className={`${Stylos[itemClass.stylo]} ${this.state.activeIndex !== undefined && this.state.activeIndex !== index ? Stylos.transparente : ""}`}
                      onClick={() => {
                        this.handleItemClick(index);
                        this.setState({ comuna: itemClass.id });
                      }}
                    >
                      <div className={Stylos.divRegiones}>
                        <label className={Stylos.labelCallNumeros}>{this.formatearNumero(itemClass.tiempo_legible_fijo)}</label>
                        <label className={Stylos.labelCallLetras}>fijos</label>
                        <label className={Stylos.labelCallNumeros}>{this.formatearNumero(itemClass.tiempo_legible_movil)}</label>
                        <label className={Stylos.labelCallLetras}>móvil</label>
                        <label className={Stylos.labelCallNumeros}>{this.formatearNumero(itemClass.tiempo_legible_fijo + itemClass.tiempo_legible_movil)}</label>
                        <label className={Stylos.labelCallLetras}>totales</label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={Stylos.imgDerecha} onClick={this.nextSlide}></div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                paddingLeft: "2.5vw",
                paddingTop: "3vh",
                marginBottom: "2vh",
              }}
            >
              <Button
                className={`${Stylos.btnNaranjosCall} ${this.state.botonActivo === "salida" ? Stylos.active : ""}`}
                onClick={() => {
                  this.handleButtonClick("salida");
                }}
              >
                Salida <br /> llamadas
              </Button>
              <Button
                className={`${Stylos.btnNaranjosCall} ${this.state.botonActivo === "entrada" ? Stylos.active : ""}`}
                onClick={() => {
                  this.handleButtonClick("entrada");
                }}
              >
                Entrada <br /> llamadas
              </Button>
            </div>
          </GridItem>
          <GridItem style={{ marginBottom: "1%" }}>
            <div style={{ display: "flex" }}>
              <div>
                <span style={{ marginLeft: "4.3vh", fontSize: "0.8rem" }}>Por año, mes, semana o día</span>
                <div
                  style={{
                    display: "flex",
                    zIndex: "2",
                    alignItems: "center",
                    height: "3vh",
                  }}
                >
                  <FormControl
                    style={{
                      width: "10vw",
                      marginLeft: "2.4vw",
                      fontSize: "0.8rem",
                    }}
                  >
                    <InputLabel
                      id="Filtro-label"
                      style={{
                        marginLeft: "1vw",
                      }}
                    ></InputLabel>

                    <Select
                      style={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        color: "rgb(128, 128, 128)",
                        width: "10vw",
                        paddingLeft: "0.5vw",
                        fontSize: "0.7rem",
                        marginTop: "0px !important",
                      }}
                      labelId="Filtro-label"
                      id="Filtro-select"
                      disableUnderline
                      value={this.state.tipoFiltro}
                      onChange={this.handleChange}
                    >
                      <MenuItem
                        value={0}
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Seleccione el Filtro
                      </MenuItem>
                      <MenuItem
                        value={4}
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Por Año
                      </MenuItem>
                      <MenuItem
                        value={3}
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Por Mes
                      </MenuItem>
                      <MenuItem
                        value={2}
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Por Semana
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {this.state.tipoFiltro == 2 && (
                <div className={Stylos.divSemanaCall} style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      marginLeft: "0.5vw",
                      fontSize: "0.8rem",
                      marginTop: "0.3vh",
                    }}
                  >
                    Semana
                  </span>
                  <div style={{ display: "flex" }}>
                    <input onChange={(e) => this.actualizarSemana(e)} type="week" id="weekInput" name="weekInput" className={Stylos.inputTypeWeek} min={this.state.minDate} />
                    <img
                      src={Ima}
                      style={{
                        width: "0.8rem",
                        zIndex: "-5",
                        position: "absolute",
                        marginLeft: "9.2vw",
                        marginTop: "1.8vh",
                      }}
                    />
                  </div>
                </div>
              )}

              {this.state.tipoFiltro == 3 && (
                <div className={Stylos.divMesCall} style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      marginLeft: "0.5vw",
                      fontSize: "0.8rem",
                      marginTop: "0.3vh",
                    }}
                  >
                    Mes
                  </span>
                  <div style={{ display: "flex" }}>
                    <input onChange={(e) => this.actualizarMes(e)} type="month" id="weekInput" name="mes" min={this.state.primeraCargaMes} className={Stylos.inputTypeWeek} />
                    <img
                      src={Ima}
                      style={{
                        width: "0.8rem",
                        zIndex: "-5",
                        position: "absolute",
                        marginLeft: "9.2vw",
                        marginTop: "1.8vh",
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.tipoFiltro == 4 && (
                <div>
                  <span style={{ marginLeft: "0.5vw", fontSize: "0.8rem" }}>Año</span>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid black",
                      borderRadius: "4px",
                      color: "rgb(128, 128, 128)",
                      width: "10vw",
                      paddingLeft: "0.5vw",
                      fontSize: "0.7rem",
                      height: "27px",
                      paddingTop: "0.2vh",
                      marginLeft: "1vh",
                      marginTop: "0.9vh",
                    }}
                  >
                    <DatePicker
                      onChange={this.actualizarAño}
                      placeholder="---- Año"
                      style={{
                        background: "transparent",
                        border: "none",
                        width: "100%",
                      }}
                      onlyYearPicker
                      name="año"
                      minDate={this.state.primeraCargaAño}
                    />
                    <img
                      src={Ima}
                      style={{
                        width: "0.8rem",
                        zIndex: "-5",
                        position: "absolute",
                        marginLeft: "8.4vw",
                        marginTop: "0.5vh",
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.tipoFiltro ? (
                <div>
                  <span style={{ marginLeft: "0.5vw", fontSize: "0.8rem" }}></span>
                  <div>
                    <Button
                      className={Stylos.btnNaranjosCall}
                      style={{
                        marginTop: "0.6vh",
                        marginLeft: "1vw",
                        width: "1vw",
                        height: "34px",
                      }}
                      onClick={() => this.buscarDatos()}
                    >
                      Buscar
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ overflow: "scroll", height: "22rem" }}>
            {this.state.graficosSalida ? <Grafico1 tipoFiltro={this.state.tipoFiltro} comuna={this.state.comuna} fecha_semana={this.state.fecha_semana} fecha_mes={this.state.fecha_mes} fecha_año={this.state.fecha_año} /> : null}
            {this.state.graficosSalida ? <Grafico3 tipoFiltro={this.state.tipoFiltro} comuna={this.state.comuna} fecha_semana={this.state.fecha_semana} fecha_mes={this.state.fecha_mes} fecha_año={this.state.fecha_año} /> : null}
            {this.state.graficosEntrada ? <Grafico2 tipoFiltro={this.state.tipoFiltro} comuna={this.state.comuna} fecha_semana={this.state.fecha_semana} fecha_mes={this.state.fecha_mes} fecha_año={this.state.fecha_año} /> : null}
            {this.state.graficosEntrada ? <Grafico4 tipoFiltro={this.state.tipoFiltro} comuna={this.state.comuna} fecha_semana={this.state.fecha_semana} fecha_mes={this.state.fecha_mes} fecha_año={this.state.fecha_año} /> : null}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
