import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import "moment/locale/es";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import iconoAgendar from "../../assets/img/usuarios/icono_agendar.svg";

import { store } from "./../../store/index";
import { handleError } from "./../../components/Helpers/helpers.js";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import MaterialTable from "material-table";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class AgendarHora extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalRepeticiones: 0,
      reservador: props.reservador | null,

      especialidad: props.especialidad,
      latencia: props.latencia,
      usuId: props.usuario,

      canal: 5,
      sigla: "",
      esp_id: "",
      esp_nombre: "",
      uspId: store.getState().usp,

      horarios: [],
      ciclo: null,
      cesfam: store.getState().cesfam,
      familiar: props.familiar || false,

      filtros: true,
      quitar_reservas_nsp: null,
      quitar_prioridad: null,
      quitar_usuario_bloqueado: null,
      quitar_hora_emparejada: null,
      quitar_sector: null,

      contacto: null,
      otroTelefono: "",
      telefono: null,
      telefonos: [],

      teleconsulta: false,
      existeContactoTelefono: false,
      existeContactoTelefonoOtro: false,

      open: true,
    };
  }

  componentDidMount() {
    this.canalesTeleconsulta();
    this.fetchBuscarEspecialidades();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  fetchBuscarEspecialidades = async () => {
    axios
      .get(global.config.modulos.especialidades, {
        headers: authHeader(),
        params: {
          esp_id: this.state.especialidad,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.estado) {
          this.setState(
            {
              esp_id: data.datos.esp_id,
              esp_nombre: data.datos.esp_nombre,
              sigla: data.datos.esp_sigla,
            },
            () => {
              this.traerContacto();
            }
          );
        } else {
          NotificationManager.error(res.data.mensaje);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  buscarHorarios = async () => {
    this.buscarHoras(this.state.cesfam, 0);
  };

  buscarHoras = (cesfam, estado) => {
    let sigla = this.state.sigla;
    let canal = this.state.canal;

    axios
      .get(global.config.modulos.reservas + "horariosDisponibles/", {
        headers: authHeader(),
        params: {
          idUsuario: this.state.usuId,
          sigla: sigla,
          canal: canal,
          cesfam: cesfam,
          nHoras: 20,
          diffHoras: 0,

          horas_en_pausa: estado,
          hora_pendiente: 1,
          habilitar_prioridades: 1,
          quitar_reservas_nsp: 1,
          quitar_usuario_bloqueado: 1,
          deshabilitar_hora_en_gestion: true,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          res.data.horas.map((hor) => {
            hor["hor_atencion"] = hor["hor_atencion"].replace("T", " ");
          });
          this.setState({
            horarios: res.data.horas,
            ciclo: res.data.ciclo,
          });
        } else {
          if (estado == 0) {
            this.buscarHoras(this.state.cesfam, 1);
          } else {
            this.setState({
              horarios: [],
              quitar_hora_emparejada: null,
            });
            NotificationManager.error(res.data.mensaje);
          }
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  reservarHora = (horario, cesfam) => {
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "Segur@ que desea reservar esta hora?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
            onClick: () => null,
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: () => {
              let telefono = this.state.telefono;
              if (telefono == "otro") {
                this.agregarTelefono(this.state.otroTelefono, this.state.usuId, this.state.uspId);
                telefono = this.state.otroTelefono;
              }

              axios
                .post(
                  global.config.modulos.agendamientos + "agendar_horario/",
                  {
                    horario: horario,
                    cesfam: cesfam,
                    usp_id: this.state.uspId,
                    canal: this.state.canal,
                    usu_id: this.state.usuId,
                    ciclo: this.state.ciclo,
                    rut_usuario: "",
                    grupo_familiar: this.state.reservador,
                    tipo_contacto: this.state.contacto,
                    telefono_contacto: telefono,
                  },
                  { headers: authHeader() }
                )
                .then((res) => {
                  if (res.data.estado) {
                    this.finalizarCaso();
                    NotificationManager.success(res.data.mensaje);
                  } else {
                    NotificationManager.error(res.data.mensaje);
                    this.salir();
                  }
                })
                .catch((err) => {
                  handleError(err);
                });
            },
          },
        ],
      });
    }
  };

  finalizarCaso() {
    var data = {
      lbc_estado: 2,
      lbc_latencia: this.state.latencia,
      lbc_usuario_panel: this.state.uspId,
    };
    let apiUrl = global.config.modulos.bloqueos + "tomar_caso/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.salir();
    });
  }

  validaciones() {
    const { teleconsulta, contacto, telefono, otroTelefono } = this.state;
    let formIsValid = true;

    if (teleconsulta && contacto == "3" && telefono == 0) {
      formIsValid = false;
      NotificationManager.error("Selecciona un teléfono.");
    } else if (teleconsulta && telefono == "otro" && !otroTelefono) {
      formIsValid = false;
      NotificationManager.error("Escribe el teléfono de contacto.");
    } else if (teleconsulta && telefono == "otro" && otroTelefono.length < 9) {
      formIsValid = false;
      NotificationManager.error("Verifica el largo del teléfono de contacto, deben ser 9 dígitos.");
    }

    return formIsValid;
  }

  traerContacto = () => {
    if (this.state.esp_nombre.includes("Teleconsulta")) {
      this.setState({
        teleconsulta: true,
        contacto: 1,
        existeContactoTelefono: false,
        existeContactoTelefonoOtro: false,
        telefono: null,
        telefonos: [],
        otroTelefono: "",
      });
    } else {
      this.setState({
        teleconsulta: false,
        contacto: null,
        telefono: null,
        telefonos: [],
        otroTelefono: "",
      });
    }
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    if (name == "contacto") {
      if (value == 3) {
        this.setState({ existeContactoTelefono: true });
        this.traerTelefonos();
      } else {
        this.setState({
          existeContactoTelefono: false,
          telefono: null,
          telefonos: [],
          otroTelefono: "",
          existeContactoTelefonoOtro: false,
        });
      }
    }

    if (name == "telefono") {
      if (value == "otro") {
        this.setState({ existeContactoTelefonoOtro: true });
      } else {
        this.setState({ existeContactoTelefonoOtro: false });
      }
    }

    this.setState({ [name]: value });
  };

  traerTelefonos = () => {
    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario/" + this.state.usuId;
    axios(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        telefonos: res.data.datos,
        telefono: 0,
      });
    });
  };

  agregarTelefono = (telefono, usuId, uspId) => {
    axios
      .post(
        global.config.modulos.telefonos + "agregar_telefono/",
        {
          telefono: telefono,
          usu_id: usuId,
          tipo: 2,
          canal: 5,
          usp_id: uspId,
        },
        { headers: authHeader() }
      )
      .then((res) => {});
  };

  canalesTeleconsulta = async () => {
    axios
      .get(global.config.modulos.videollamada + "canales_teleconsulta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        this.setState({
          canalesTeleconsulta: data,
        });
      })
      .catch((err) => {});
  };

  render() {
    const { filtros } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Reservar hora</h2>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer className="agendar-container">
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer className="agendar-container">
                        <GridItem id="modalAgendarEspecialidades" xs={12} style={{ marginTop: 30, float: "left" }}></GridItem>
                        <GridItem id="modalAgendarEspecialidades" xs={5} sm={5} md={5} style={{ marginTop: 30 }}>
                          <InputLabel id="label-habilitado">Tipos de atención</InputLabel>
                          <Select style={{ with: 300 }} labelId="label-habilitado" id="espPrimaria" name="espPrimaria" value={this.state.especialidad} fullWidth disabled={true}>
                            <MenuItem value={this.state.esp_id} key={0}>
                              {this.state.esp_nombre}
                            </MenuItem>
                          </Select>
                        </GridItem>
                        <GridItem id="modalAgendarBuscar" xs={2} sm={2} md={2} style={{ marginTop: 40 }}>
                          <Button onClick={this.buscarHorarios} className="botonDialog fondoNaranjo">
                            Buscar
                          </Button>
                        </GridItem>
                      </GridContainer>

                      {this.state.teleconsulta ? (
                        <>
                          <br></br>
                          <InputLabel>Contacto</InputLabel>
                          <Select style={{ width: 200 }} labelId="label-habilitado" id="contacto" name="contacto" value={this.state.contacto} onChange={this.cambiarValores}>
                            {this.state.canalesTeleconsulta.map((res, index) => {
                              return (
                                <MenuItem value={res.cta_id} key={index}>
                                  {res.cta_canal}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <br></br>
                          <br></br>
                          {this.state.existeContactoTelefono ? (
                            <>
                              <InputLabel>Teléfono</InputLabel>
                              <Select style={{ width: 200 }} labelId="label-habilitado" id="telefono" name="telefono" value={this.state.telefono} onChange={this.cambiarValores}>
                                <MenuItem value={0}>Seleccione</MenuItem>

                                {this.state.telefonos.map((res, index) => {
                                  return (
                                    <MenuItem value={res.tel_telefono} key={index}>
                                      {res.tel_telefono}
                                    </MenuItem>
                                  );
                                })}
                                <MenuItem value={"otro"}>Otro</MenuItem>
                              </Select>
                              <br></br>
                              <br></br>
                              {this.state.existeContactoTelefonoOtro ? (
                                <>
                                  <InputLabel>Otro</InputLabel>
                                  <TextField
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                                    }}
                                    id="standard-basic"
                                    label=""
                                    value={this.state.otroTelefono}
                                    style={{ width: 200 }}
                                    type="number"
                                    name="otroTelefono"
                                    onChange={this.cambiarValores}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                                    }}
                                  />
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : null}

                      <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ minHeight: 200, marginTop: 40 }}>
                        {this.state.horarios.length > 0 ? (
                          <MaterialTable
                            title=""
                            columns={[
                              {
                                title: "Hora",
                                field: "hor_atencion",
                                defaultSort: "asc",
                                render: (rowData) => (
                                  <React.Fragment>
                                    <p>{rowData.hor_atencion}</p>
                                  </React.Fragment>
                                ),
                              },
                              { title: "Especialidad", field: "esp_nombre" },
                              {
                                title: "Profesional",
                                field: "pro_nombre",
                                field: "pro_apellido",
                                render: (rowData) => (
                                  <React.Fragment>
                                    <p>{rowData.pro_nombre + " " + rowData.pro_apellido}</p>
                                  </React.Fragment>
                                ),
                              },
                              { title: "Cesfam", field: "ces_nombre" },
                            ]}
                            actions={[
                              {
                                icon: () => <img src={iconoAgendar} style={{ width: 40 }} />,
                                tooltip: this.state.familiar ? "Reservar esta hora para el familiar" : "Reservar esta hora para el paciente",
                                onClick: (event, rowData) => this.reservarHora(rowData.hor_id, rowData.hor_cesfam),
                              },
                            ]}
                            data={this.state.horarios}
                            options={{
                              actionsColumnIndex: -1,
                              search: false,
                              sorting: true,
                              pageSize: this.state.horarios.length,
                              pageSizeOptions: [{ value: this.state.horarios.length, label: "All" }],
                            }}
                            localization={{
                              header: {
                                actions: "Acciones",
                              },
                              pagination: {
                                labelDisplayedRows: "{from}-{to} de {count}",
                                labelRowsSelect: "filas",
                                firstTooltip: "primera",
                                previousTooltip: "anterior",
                                nextTooltip: "siguiente",
                                lastTooltip: "última",
                              },
                              toolbar: {
                                nRowsSelected: "{0} fila(s) seleccionadas",
                                searchTooltip: "Buscar",
                                searchPlaceholder: "Buscar",
                              },
                              body: {
                                emptyDataSourceMessage: "No hay comentarios registrados",
                                filterRow: {
                                  filterTooltip: "Buscar",
                                },
                              },
                            }}
                          />
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
                        <Button onClick={this.salir} className="botonDialogCancel fondoRojo">
                          Cancelar
                        </Button>
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
