import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import chileanRut from "chilean-rut";

// core components

import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import Paginacion from "./../../components/Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import Buttons from "react-multi-date-picker/components/button";
import PrioritariosDetalle from "../../components/GestionPrioritarios/PrioritariosDetalle";
import PrioritariosReserva from "../../components/GestionPrioritarios/PrioritariosReservas";
import PrioritariosNsp from "../../components/GestionPrioritarios/PrioritariosNsp";
import PrioritariosRechazos from "../../components/GestionPrioritarios/PrioritariosRechazos";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "react-multi-date-picker/components/button";
import AgregarPrioridad from "../../components/GestionPrioritarios/AgregarPrioritarios";
import VistaUsuarios from "../../components/GestionPrioritarios/VistaUsuarios";
import { Input } from "@material-ui/core";

import signo from "./../../assets/img/signo.svg";

class GestionPrioritarios extends Component {
  constructor() {
    super();

    this.state = {
      datoPrioritarios: [],
      herramientas: store.getState().herramientas,
      cesfam: store.getState().cesfam,
      pagina: 0,
      filas: 10,
      total: 0,
      modalPrioritariosDetalle: false,
      modalPrioritariosReserva: false,
      modalPrioritariosNsp: false,
      modalPrioritariosRechazos: false,
      modalAgregarPrioridad: false,
      modalUsuario: false,
      datosEsp: [],
      lap_id: null,
      dato_esp: null,
      datosEsp: [],
      dato_atn: null,
      datoAtencion: [],
      datoSector: [],
      dato_sec: null,
      tipoUsuario: 0,
      lap_usuario: null,
      lap_especialidad: null,
      texto_filtro: null,
      estadoEspecialidad: true,
      estadoAtencion: true,
      ordenarPrioridad: null,
      orden: null,
    };
    console.log(store.getState().cesfam);
  }

  componentDidMount() {
    this.obtenerPrioritarios(this.state.pagina, this.state.filas);
    this.obtenerEspecialidad();
    this.obtenerSector();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Gestión nivel 2 <span title='Permite ver los pacientes que actualmente estan en lista de espera.'><img src=" + signo + "></img></span>";
  };

  handleFiltradorCampo = (campo) => {
    var campo = campo;
    if (campo === this.state.ordenarPrioridad) {
      campo = campo + "asc";
    } else if (this.state.ordenarPrioridad === campo + "asc") {
      campo = null;
    }

    this.setState(
      {
        ordenarPrioridad: campo,
      },
      () => {
        this.obtenerPrioritarios(this.state.pagina, this.state.filas);
      }
    );

    console.log(this.state.ordenarPrioridad);
  };

  obtenerPrioritarios = async (pagina, filas) => {
    axios
      .get(global.config.modulos.prioritarios + "ver_prioritarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          pagina: pagina,
          filas: filas,
          especialidad: this.state.dato_esp,
          textofiltro: this.state.texto_filtro,
          atencion: this.state.dato_atn,
          sector: this.state.dato_sec,
          ordenarPrioridad: this.state.ordenarPrioridad,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datoPrioritarios: res.data.datos,
            total: res.data.total,
            pagina: res.data.pagina,
          });
        } else {
          this.setState({
            datoPrioritarios: [],
            total: 0,
            pagina: 0,
          });
        }
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.obtenerPrioritarios(pagina, this.state.filas);
  };

  aplicarFiltros = () => {
    this.obtenerPrioritarios(this.state.pagina, this.state.filas);
  };
  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.obtenerPrioritarios(this.state.pagina, filas);
  };

  mostrarPrioridadDetalle = (lap_id) => {
    this.setState({
      modalPrioritariosDetalle: true,
      lap_id: lap_id,
    });
  };

  ocultarPrioridadDetalle = () => {
    this.setState({
      modalPrioritariosDetalle: false,
    });
  };

  mostrarAgregarPrioridad = () => {
    this.setState({
      modalAgregarPrioridad: true,
    });
  };

  ocultarAgregarPrioridad = () => {
    this.setState({
      modalAgregarPrioridad: false,
    });
    this.obtenerPrioritarios(this.state.pagina, this.state.filas);
  };

  mostrarPrioridadReserva = (lap_usuario, lap_especialidad) => {
    this.setState({
      modalPrioritariosReserva: true,
      lap_usuario: lap_usuario,
      lap_especialidad: lap_especialidad,
    });
  };

  ocultarPrioridadReserva = () => {
    this.setState({
      modalPrioritariosReserva: false,
    });
  };

  mostrarPrioridadNsp = (lap_usuario) => {
    this.setState({
      modalPrioritariosNsp: true,
      lap_usuario: lap_usuario,
    });
  };

  ocultarPrioridadNsp = () => {
    this.setState({
      modalPrioritariosNsp: false,
    });
  };

  mostrarPrioridadRechazos = (lap_usuario, lap_especialidad) => {
    this.setState({
      modalPrioritariosRechazos: true,
      lap_usuario: lap_usuario,
      lap_especialidad: lap_especialidad,
    });
  };

  ocultarPrioridadRechazos = () => {
    this.setState({
      modalPrioritariosRechazos: false,
    });
  };

  mostrarModalUsuario = (lap_usuario) => {
    this.setState({
      modalUsuario: true,
      lap_usuario: lap_usuario,
    });
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  obtenerEspecialidad = () => {
    axios
      .get(global.config.modulos.prioritarios + "especialidades", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datosEsp: res.data.datos,
          });
        }
      });
  };

  seleccionaresp = (e) => {
    const dato_esp = e.target.value;
    this.setState({ dato_esp: dato_esp, dato_atn: null, estadoEspecialidad: false }, () => {
      this.obtenerAtencion();
    });
  };

  obtenerAtencion = () => {
    axios
      .get(global.config.modulos.prioritarios + "especialidades/atencion", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          sigla: this.state.dato_esp,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datoAtencion: res.data.datos,
          });
        }
      });
  };

  seleccionarAtencion = (e) => {
    const dato_atn = e.target.value;
    this.setState({ dato_atn: dato_atn, estadoAtencion: false });
  };

  obtenerSector = () => {
    axios
      .get(global.config.modulos.prioritarios + "sectores", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datoSector: res.data.datos,
          });
        }
      });
  };

  seleccionarSector = (e) => {
    const dato_sec = e.target.value;
    this.setState({ dato_sec: dato_sec });
  };

  filtroTexto = (e) => {
    const texto_filtro = e.target.value;
    this.setState({ texto_filtro: texto_filtro });
  };

  render() {
    return (
      <div className="middle-area">
        <div style={{ display: "flex", marginBottom: "3%", marginTop: "2%" }}>
          <FormControl style={{ width: "15%" }}>
            <InputLabel id="demo-simple-select-label">Especialidad</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.dato_esp} label="Tipo" onChange={this.seleccionaresp}>
              <MenuItem value={null}>Seleccionar todo</MenuItem>
              {this.state.datosEsp.map((res, index) => (
                <MenuItem key={index} value={res.esp_sigla}>
                  {res.esp_nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl disabled={this.state.estadoEspecialidad} style={{ width: "15%", marginLeft: "2%" }}>
            <InputLabel id="demo-simple-select-label">Tipo Atencion</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.dato_atn} label="Tipo" onChange={this.seleccionarAtencion}>
              <MenuItem value={null}>Seleccionar todo</MenuItem>
              {this.state.datoAtencion.map((res, index) => (
                <MenuItem key={index} value={res.esp_id}>
                  {res.esp_nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl disabled={this.state.estadoAtencion} style={{ width: "15%", marginLeft: "2%" }}>
            <InputLabel id="demo-simple-select-label">Sector</InputLabel>

            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.dato_sec} label="Tipo" onChange={this.seleccionarSector}>
              <MenuItem value={null}>Seleccionar todo</MenuItem>
              {this.state.datoSector.map((res, index) => (
                <MenuItem key={index} value={res.sec_id}>
                  {res.sec_nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Input
            type="text"
            name="texto_filtro"
            value={this.state.texto_filtro}
            onChange={this.filtroTexto}
            placeholder="Nombre o Rut"
            className={"inputText"}
            style={{
              padding: 6,
              border: "1px solid black",
              borderRadius: 5,
              marginTop: "1%",
              float: "left",
              marginLeft: "2%",
              height: "35px",
              width: "20%",
            }}
          />
          <Buttons
            onClick={this.aplicarFiltros}
            className={"btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginLeft: "2%",
              paddingLeft: "20px",
              height: "35px",
              width: "70px",
              marginTop: "1%",
              fontSize: "14px",
              float: "left",
              border: "0",
              textAlign: "center",
            }}
          >
            Filtro
          </Buttons>

          <Buttons
            onClick={this.mostrarAgregarPrioridad}
            className={"btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginLeft: "20%",
              paddingLeft: "10px",
              height: "2%",
              width: "140px",
              marginTop: "1%",
              fontSize: "14px",
              border: "0",
              textAlign: "center",
              paddingRight: "10px",
              paddingTop: "7px",
            }}
          >
            Agregar paciente prioritario
          </Buttons>
        </div>
        <div className="middle-content" style={{ height: "70%", width: "100%" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        <a style={{ cursor: "pointer" }} onClick={() => this.handleFiltradorCampo("fecha")}>
                          Fecha registro {this.state.orden}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a style={{ cursor: "pointer" }} onClick={() => this.handleFiltradorCampo("rut")}>
                          RUT paciente {this.state.orden}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a style={{ cursor: "pointer" }} onClick={() => this.handleFiltradorCampo("nombre")}>
                          Nombre paciente {this.state.orden}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a style={{ cursor: "pointer" }} onClick={() => this.handleFiltradorCampo("especialidad")}>
                          Tipo de atención {this.state.orden}
                        </a>
                      </TableCell>

                      {this.state.herramientas.includes("H34") ? (
                        <React.Fragment>
                          <TableCell align="center">Reservas </TableCell>

                          <TableCell align="center">NSP</TableCell>

                          <TableCell align="center">Rechazos</TableCell>

                          <TableCell align="center">Detalle</TableCell>
                        </React.Fragment>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.datoPrioritarios.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {row.lap_reservo == 0 ? (
                            <p
                              style={{
                                backgroundColor: "#c12b2b",
                                color: "white",
                                paddingBottom: "5px",
                                paddingTop: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              {moment(row.lap_fecha_registro).format("DD-MM-YYYY HH:MM")}
                            </p>
                          ) : (
                            <p>{moment(row.lap_fecha_registro).format("DD-MM-YYYY HH:MM")}</p>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <a
                            onClick={() => this.mostrarModalUsuario(row.lap_usuario)}
                            style={{
                              cursor: "pointer",
                              padding: "10px 15px 10px 15px",
                            }}
                          >
                            {chileanRut.format(row.rut_usuario, true)}
                          </a>
                        </TableCell>
                        <TableCell align="center">{row.nombre_usuario}</TableCell>
                        <TableCell align="center">{row.nombre_especialidad}</TableCell>
                        {this.state.herramientas.includes("H34") ? (
                          <React.Fragment>
                            <TableCell align="center">
                              {/* Esto se activa cuando es administrador ya que es vista interna*/}
                              {row.lap_reservas != 0 ? (
                                <a
                                  onClick={() => this.mostrarPrioridadReserva(row.lap_usuario, row.lap_especialidad)}
                                  style={{
                                    cursor: "pointer",
                                    padding: "10px 15px 10px 15px",
                                  }}
                                >
                                  {row.lap_reservas}
                                </a>
                              ) : (
                                row.lap_reservas
                              )}
                            </TableCell>

                            <TableCell align="center">
                              {row.lap_nsp != 0 ? (
                                <a
                                  onClick={() => this.mostrarPrioridadNsp(row.lap_usuario)}
                                  style={{
                                    cursor: "pointer",
                                    padding: "10px 15px 10px 15px",
                                  }}
                                >
                                  {row.lap_nsp}
                                </a>
                              ) : (
                                row.lap_nsp
                              )}{" "}
                            </TableCell>

                            <TableCell align="center">
                              {row.lap_rechazo != 0 ? (
                                <a
                                  onClick={() => this.mostrarPrioridadRechazos(row.lap_usuario, row.lap_especialidad)}
                                  style={{
                                    cursor: "pointer",
                                    padding: "10px 15px 10px 15px",
                                  }}
                                >
                                  {row.lap_rechazo}
                                </a>
                              ) : (
                                row.lap_rechazo
                              )}{" "}
                            </TableCell>

                            <TableCell align="center">
                              <Tooltip title="Revisar detalles " aria-label="Revisar detalles">
                                <img className="mediumIcon" src={ver_mas} onClick={() => this.mostrarPrioridadDetalle(row.lap_id)} />
                              </Tooltip>
                            </TableCell>
                          </React.Fragment>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
              </TableContainer>

              {this.state.modalPrioritariosDetalle ? <PrioritariosDetalle ocultar={this.ocultarPrioridadDetalle} lap_id={this.state.lap_id}></PrioritariosDetalle> : null}
              {this.state.modalPrioritariosReserva ? <PrioritariosReserva ocultar={this.ocultarPrioridadReserva} usuario={this.state.lap_usuario} especialidad={this.state.lap_especialidad}></PrioritariosReserva> : null}
              {this.state.modalPrioritariosNsp ? <PrioritariosNsp ocultar={this.ocultarPrioridadNsp} usuario={this.state.lap_usuario}></PrioritariosNsp> : null}
              {this.state.modalPrioritariosRechazos ? <PrioritariosRechazos ocultar={this.ocultarPrioridadRechazos} usuario={this.state.lap_usuario} especialidad={this.state.lap_especialidad}></PrioritariosRechazos> : null}
              {this.state.modalAgregarPrioridad ? <AgregarPrioridad ocultar={this.ocultarAgregarPrioridad}></AgregarPrioridad> : null}
              {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.lap_usuario}></VistaUsuarios> : null}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(GestionPrioritarios);
