import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import { store } from "store/index";

import GridContainer from "components/grid/GridContainer.js";
import axios from "axios";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import moment from "moment";

//JS
import ModalSolicitud from "../Dialog/ModalSolicitud";

// IMG
import imgObservacion from "assets/img/solicitud_trabajo/iconos/motivo.svg";

//CSS
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

export default class Rechazados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      abrirModal: false,
      dataSolicitud: null,
      tituloModalSolicitud: "",
      vistaModal: 0,
      seleccion: 1,
    };
  }

  componentDidMount() {
    this.solicitudes("1");
    this.colorTabla();
  }

  solicitudes = (estado) => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "notificaciones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
          estado: estado,
        },
      })
      .then((res) => {
        this.setState({
          solicitudes: res.data.datos,
        });
      });
  };

  colorTabla = () => {
    const tableHeaders = document.querySelectorAll(".MuiTableCell-head");
    tableHeaders.forEach((header) => {
      header.style.setProperty("border-bottom", "4px solid #eaa029", "important");
    });
  };

  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  irTicket = (cod) => {
    this.props.history.push({
      pathname: "/solicitud_trabajo",
      params: { cod },
    });
  };

  abrirModalMotivo = (data, titulo, vista) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo, vistaModal: vista });
  };

  render() {
    const { seleccion } = this.state;
    return (
      <>
        <GridContainer style={{ width: "90%" }}>
          <Grid item xs={12} sm={12}>
            <div className={SolicitudTrabajo.divBorderTableSolicitudCesfamRechazado} style={{ paddingLeft: "20px" }}>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "Fecha de ingreso",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.fecha_solicitud.split(" ")[0]}</p>
                        <p>{rowData.fecha_solicitud.split(" ")[1]} hrs</p>
                      </React.Fragment>
                    ),
                    field: "fecha_solicitud",
                  },
                  {
                    title: "N° de ticket ",
                    field: "sol_codigo",
                  },
                  {
                    title: "Nombre",
                    field: "sol_autoriza",
                  },
                  {
                    title: "CESFAM",
                    field: "ces_nombre",
                  },
                  {
                    title: "Tipo de solicitud",
                    field: "tipo",
                  },
                  {
                    title: "Motivo de solicitud",
                    render: (rowData) => (
                      <React.Fragment>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img src={imgObservacion} style={{ width: "30px", cursor: "pointer" }} onClick={() => this.abrirModalMotivo(rowData, "Motivo", 1)} />
                        </div>
                      </React.Fragment>
                    ),
                    field: "sol_motivo",
                  },
                  {
                    title: "Motivo de rechazo",
                    render: (rowData) => (
                      <React.Fragment>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img src={imgObservacion} style={{ width: "30px", cursor: "pointer" }} onClick={() => this.abrirModalMotivo(rowData, "Motivo de rechazo", 17)} />
                        </div>
                      </React.Fragment>
                    ),
                    field: "sol_motivo",
                  },
                ]}
                data={this.state.solicitudes}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  toolbar: false,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: <span style={{ fontSize: "1.5rem" }}>No tiene tickets pendientes</span>,

                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </div>

            {this.state.abrirModal && <ModalSolicitud data={this.state.dataSolicitud} titulo={this.state.tituloModalSolicitud} salir={this.cerrarModal} vista={this.state.vistaModal} solicitudes={this.solicitudes} />}
          </Grid>
        </GridContainer>
      </>
    );
  }
}
