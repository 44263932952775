import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "store/index";
import "moment/locale/es";
import { connect } from "react-redux";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import MaterialTable from "material-table";

import modificar from "assets/img/admin/modificar.svg";
import CrearTotem from "./admin/CrearTotem";

import { totem } from "store/actions/actions";
import { Style } from "@material-ui/icons";

//IMAGENES
import ImgSeleccionTotem from "assets/img/admin_totem/nuevos_logo/seleccionar_totem.svg";
import ImgCrearTotem from "assets/img/admin_totem/nuevos_logo/crear_totem.svg";

//CSS
import crearTotem from "assets/css/crearTotem.module.css";

class ListaTotems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totems: [],

      herramientas: store.getState().herramientas,
      cesfam: store.getState().cesfam,
      vista: 1,
    };
  }

  componentDidMount() {
    this.fetchTotems();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Administrar Totems <span title='Totems creados'></span>";
  };

  fetchTotems = async () => {
    let apiUrl = global.config.modulos.admin_totem + "por_cesfam/?cesfam=" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var data = res.data;
        this.setState({
          totems: data.datos,
        });
      });
  };

  modificarTotem = (tot_id, tot_nombre) => {
    this.props.dispatch(totem(tot_id, tot_nombre));
    this.props.history.push("/administrar_totem");
  };

  cambioVista = (valor) => {
    this.setState({ vista: valor }, () => {});
    axios
      .post(global.config.modulos.admin_totem + "crear_ticket_muestra/", {
        headers: authHeader(),
      })
      .then((res) => {});
    if (valor == 1) {
      document.getElementById("SeleccionTotem").style.width = "6.5%";
      document.getElementById("CrearTotem").style.width = "6%";
    } else if (valor == 2) {
      document.getElementById("CrearTotem").style.width = "6.5%";
      document.getElementById("SeleccionTotem").style.width = "6%";
    }
  };

  render() {
    return (
      <div className="middle-area" style={{ height: "80%" }}>
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <img src={ImgSeleccionTotem} style={{ width: "6.5%" }} className={crearTotem.tam_icon_totem} onClick={() => this.cambioVista(1)} id="SeleccionTotem" />
              {this.state.herramientas.includes("H55") && <img src={ImgCrearTotem} style={{ width: "6%" }} className={crearTotem.tam_icon_totem} onClick={() => this.cambioVista(2)} id="CrearTotem" />}
              {this.state.vista == 1 ? (
                <MaterialTable
                  title=""
                  columns={[
                    { title: "Nombre", field: "tot_nombre" },
                    {
                      title: "Acciones",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>
                            <img className="botonesTablas" style={{ width: "40px" }} src={modificar} onClick={() => this.modificarTotem(rowData.tot_id, rowData.tot_nombre)} />
                          </p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                  data={this.state.totems}
                  options={{
                    actionsColumnIndex: -1,
                    search: true,
                    sorting: true,
                  }}
                  localization={{
                    header: {
                      actions: "Acciones",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "filas",
                      firstTooltip: "primera",
                      previousTooltip: "anterior",
                      nextTooltip: "siguiente",
                      lastTooltip: "última",
                    },
                    toolbar: {
                      nRowsSelected: "{0} fila(s) seleccionadas",
                      searchTooltip: "Buscar",
                      searchPlaceholder: "Buscar",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay horas anuladas próximas a vencer",
                      filterRow: {
                        filterTooltip: "Buscar",
                      },
                    },
                  }}
                />
              ) : (
                <CrearTotem></CrearTotem>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaTotems);
