import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import MaterialTable from "material-table";
import "react-confirm-alert/src/react-confirm-alert.css";
import { store } from "./../../store/index";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import icono_vista_6 from "../../assets/img/usuarios/icono_vista_6.svg";
import AgregarDifusion from "./AgregarDifusion";
// iconos final
import iconoEliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import iconoModificar from "../../assets/img/usuarios/icono_modificar.svg";
import iconoCopiar from "../../assets/img/usuarios/copiar.svg";
import iconoPause from "../../assets/img/difusion/pausar.svg";
import iconoPlay from "../../assets/img/difusion/play.svg";

import anunciarLlegada from "../../assets/img/usuarios/icono_anunciar_llegada.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import NotificationManager from "react-notifications/lib/NotificationManager";
// estilos
//import "../../assets/css/agregar.css";

export default class ListadoDifusiones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      difusiones: [],
      formErrors: {},
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      usuId: props.usuario,
      familiar: props.familiar || false,
      age_id: null,

      modalElim: false,
      modalEdit: false,
      modalClon: false,
      modalPausa: false,
      difusAccion: {},
    };

    this.traerDifusiones = this.traerDifusiones.bind(this);
    this.eliminarDifusion = this.eliminarDifusion.bind(this);
    this.editarDifusionCancel = this.editarDifusionCancel.bind(this);
    this.clonarDifusionCancel = this.clonarDifusionCancel.bind(this);
  }

  componentDidMount() {
    this.traerDifusiones(this.props.opcion);
  }

  traerDifusiones(opcion) {
    let apiUrl = global.config.modulos.difusion + "por_cesfam/" + this.state.cesfam + "?opcion=" + opcion;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ difusiones: res.data.datos });
    });
  }

  tipoDifus(tipo) {
    switch (tipo) {
      case 1:
        return "Información";
      case 2:
        return "Encuestas";
      case 3:
        return "Reserva";
      case 4:
        return "Inscripción";
    }
  }

  clonarDifusion(rowData) {
    this.setState({ modalClon: true, difusAccion: rowData });
  }

  clonarDifusionCancel(rowData) {
    this.setState({ modalClon: false }, () => {
      this.setState({ difusAccion: {} });
      this.traerDifusiones(this.props.opcion);
    });
  }

  pausarReanudarDifusionConfirm(rowData) {
    this.setState({ modalPausa: true, difusAccion: rowData });
  }

  pausarReanudarDifusionCancel(rowData) {
    this.setState({ modalPausa: false }, () => {
      this.setState({ difusAccion: {} });
    });
  }

  pausarReanudarDifusion(rowData) {
    var data = {
      dic_pausada: rowData.dic_pausada === 0 ? 1 : 0,
    };
    let apiUrl = global.config.modulos.difusion + "por_id/" + rowData.dic_id;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.traerDifusiones(this.props.opcion);
      this.pausarReanudarDifusionCancel();
      let accion = rowData.dic_pausada === 0 ? "pausada" : "reanudada";
      NotificationManager.info("Difusión " + accion + " correctamente");
    });
  }

  editarDifusionModal(rowData) {
    this.setState({ modalEdit: true, difusAccion: rowData });
  }

  editarDifusionCancel(rowData) {
    this.setState({ modalEdit: false }, () => {
      this.setState({ difusAccion: {} });
      this.traerDifusiones(this.props.opcion);
    });
  }

  eliminarDifusion(rowData) {
    var data = {
      dic_estado: 0,
    };
    let apiUrl = global.config.modulos.difusion + "por_id/" + rowData.dic_id;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.traerDifusiones(this.props.opcion);
      this.eliminarDifusionCancel();
      NotificationManager.info("Difusión eliminada correctamente");
    });
  }

  eliminarDifusionConfirm(rowData) {
    this.setState({ modalElim: true, difusAccion: rowData });
  }

  eliminarDifusionCancel(rowData) {
    this.setState({ modalElim: false }, () => {
      this.setState({ difusAccion: {} });
    });
  }

  render() {
    return (
      <>
        <div className="historialHoras">
          <Grid container spacing={2} className="fichaUsuario scroll-container">
            <Grid item xs={12}>
              <div>
                <div className="datosUsuario " style={{ border: "", marginTop: "0", marginLeft: "0" }}>
                  <TableContainer style={{ width: "100%", marginTop: "" }}>
                    <Table aria-label="simple table" className="" style={{ textAlign: "center" }}>
                      <MaterialTable
                        title=""
                        columns={[
                          {
                            title: "Nombre Difusión",
                            field: "dic_nombre",
                          },
                          {
                            title: "Veces que se repite",
                            field: "dic_repeticion",
                            render: (rowData) => (
                              <p>
                                {rowData.dic_repeticion} veces por {rowData.dic_repeticion_dias} día(s)
                              </p>
                            ),
                          },
                          {
                            title: "Fecha de Inicio",
                            field: "dic_fecha_inicio",
                            render: (rowData) => <p>{moment(rowData.dic_fecha_inicio).format("DD-MM-YYYY")}</p>,
                          },
                          {
                            title: "Fecha de término",
                            field: "dic_fecha_termino",
                            render: (rowData) => <p>{moment(rowData.dic_fecha_termino).format("DD-MM-YYYY")}</p>,
                          },
                          {
                            title: "Tipo de difusión",
                            field: "dic_tipo",
                            render: (rowData) => <p>{this.tipoDifus(rowData.dic_tipo)}</p>,
                          },
                          {
                            title: "Acciones",
                            hidden: this.props.opcion === 0 ? false : true,
                            render: (rowData) => (
                              <>
                                <Tooltip title="Clonar Difusión" aria-label="" style={{ marginRight: 2 }}>
                                  <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.clonarDifusion.bind(this, rowData)} src={iconoCopiar} />
                                </Tooltip>
                                <Tooltip title={rowData.dic_pausada === 0 ? "Pausar" : "Reanudar"} aria-label="">
                                  <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.pausarReanudarDifusionConfirm.bind(this, rowData)} src={rowData.dic_pausada === 0 ? iconoPause : iconoPlay} />
                                </Tooltip>
                                <Tooltip title="Editar" aria-label="">
                                  <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.editarDifusionModal.bind(this, rowData)} src={iconoModificar} />
                                </Tooltip>
                                <Tooltip title="Eliminar" aria-label="">
                                  <img className="mediumIcon" style={{ marginTop: 0 }} onClick={this.eliminarDifusionConfirm.bind(this, rowData)} src={iconoEliminar} />
                                </Tooltip>
                              </>
                            ),
                          },
                        ]}
                        data={this.state.difusiones}
                        options={{
                          actionsColumnIndex: -1,
                          sorting: false,
                          search: true,
                          toolbar: false,
                          actions: true,
                          exportAllData: true,
                          exportButton: true,
                        }}
                        localization={{
                          header: {
                            actions: "a",
                          },
                          toolbar: {
                            searchPlaceholder: "Buscar",
                            searchTooltip: "Buscar",
                            exportTitle: "Exportar",
                            exportAriaLabel: "Exportar",
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                          },
                          body: {
                            emptyDataSourceMessage: "No se encuentran difusiones para este cesfam",
                          },
                          pagination: {
                            labelRowsSelect: "Filas",
                            labelDisplayedRows: "{from}-{to} de {count}",
                          },
                        }}
                      ></MaterialTable>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <>
          <Dialog open={this.state.modalElim} onClose={this.eliminarDifusionCancel.bind(this)} className="modificarUsuario">
            <DialogContent>
              <GridContainer className="">
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                      <h1>¿Seguro que desea eliminar la difusión {this.state.difusAccion.dic_nombre}?</h1>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                    <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8, color: "white" }} className="fondoRojoBeneficio" onClick={this.eliminarDifusion.bind(this, this.state.difusAccion)}>
                      Eliminar
                    </Button>
                    <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.eliminarDifusionCancel.bind(this)}>
                      Atrás
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </DialogContent>
          </Dialog>

          <Dialog open={this.state.modalPausa} onClose={this.pausarReanudarDifusionCancel.bind(this)} className="modificarUsuario">
            <DialogContent>
              <GridContainer className="">
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                      <h1>
                        ¿Seguro que desea {this.state.difusAccion.dic_pausada == 0 ? <>pausar</> : <>reanudar</>} la difusión {this.state.difusAccion.dic_nombre}?
                      </h1>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                    <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8, color: "white" }} className="fondoVerde" onClick={this.pausarReanudarDifusion.bind(this, this.state.difusAccion)}>
                      {this.state.difusAccion.dic_pausada == 0 ? <>Pausar</> : <>Reanudar</>}
                    </Button>
                    <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.pausarReanudarDifusionCancel.bind(this)}>
                      Atrás
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </DialogContent>
          </Dialog>
        </>
        <>
          {this.state.modalEdit ? <AgregarDifusion ocultar={this.editarDifusionCancel} editar={true} clon={false} dif_edit={this.state.difusAccion} /> : null}
          {this.state.modalClon ? <AgregarDifusion ocultar={this.clonarDifusionCancel} editar={false} clon={true} dif_edit={this.state.difusAccion} /> : null}
        </>
      </>
    );
  }
}
