import React, { useState, useEffect, Component, useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import { store } from "../../store/index";
import GraficoNsps from "./GraficoNsps";
import Titulo from "../../components/titulo/Titulo.js";

import moment from "moment";
import "moment/locale/es";

import MaterialTable from "material-table";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
class GraficoNsp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      cesfam: store.getState().cesfam,
      Nsps: [],
      NspsLista: [],
      loading: false,
      open: false,
      tipoData: 1,

      ano: "",
      mes: "",
      sem: "",
      dia: "",
      hora: "",

      dateSelect: null,
    };
  }

  componentDidMount() {
    this.getNsps();
  }

  getNsps() {
    this.setState({ Nsps: [], NspsLista: [], loading: false, open: false });

    axios
      .get(global.config.modulos.reportes + "informacion_grafico_nsp/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          fecha: this.state.dateSelect,
          tipo: this.state.tipoData,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          var modal = false;
          var data = [];
          if (res.data.tipo == 1) {
            res.data.datos.map((res) => {
              data.push({
                texto: moment(res.hor_atencion).format("YYYY"),
                hor_atencion: moment(res.hor_atencion),
                fecha: moment(res.hor_atencion),
                cantidad: res.cantidad,
              });
            });
          } else if (res.data.tipo == 2) {
            res.data.datos.map((res) => {
              data.push({
                texto: moment(res.hor_atencion).format("MM-YYYY"),
                hor_atencion: moment(res.hor_atencion),
                fecha: moment(res.hor_atencion),
                cantidad: res.cantidad,
              });
            });
          } else if (res.data.tipo == 3) {
            res.data.datos.map((res) => {
              var fechaInicioSemana = moment().years(this.state.ano).isoWeek(res.hor_atencion).startOf("isoweek");
              var fechaTerminoSemana = moment().years(this.state.ano).isoWeek(res.hor_atencion).endOf("isoweek");

              data.push({
                texto: "Semana desde el día " + fechaInicioSemana.format("DD-MM-YYYY") + " al " + fechaTerminoSemana.format("DD-MM-YYYY"),
                hor_atencion: fechaInicioSemana,
                fecha: res.hor_atencion,
                cantidad: res.cantidad,
              });
            });
          } else if (res.data.tipo == 4) {
            res.data.datos.map((res) => {
              data.push({
                texto: moment(res.hor_atencion).format("DD-MM-YYYY"),
                hor_atencion: moment(res.hor_atencion),
                fecha: moment(res.hor_atencion).format("YYYY-MM-DD"),
                cantidad: res.cantidad,
              });
            });
          } else if (res.data.tipo == 5) {
            res.data.datos.map((res) => {
              data.push({
                texto: moment(res.hor_atencion).format("DD-MM-YYYY HH:mm:ss"),
                hor_atencion: moment(res.hor_atencion),
                fecha: moment(res.hor_atencion).format("YYYY-MM-DD HH:mm:ss"),
                cantidad: res.cantidad,
              });
            });
          } else {
            data = res.data.datos;
            modal = true;
          }

          this.setState({ Nsps: data, tipoData: res.data.tipo, loading: true, open: modal });
        } else {
          this.setState({ Nsps: [], loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getNspsLista() {
    axios
      .get(global.config.modulos.reportes + "informacion_grafico_nsp/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          fecha: this.state.dateSelect,
          tipo: this.state.tipoData,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          var dataLista = res.data.datosLista;

          this.setState({ NspsLista: dataLista, tipoData: res.data.tipo, loading: true, open: true });
        } else {
          this.setState({ Nsps: [], loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleLineCLick = (datos) => {
    var tipo = datos.tipoData;

    if (tipo == 1) {
      var yr = moment(datos.fecha).format("YYYY");

      this.setState(
        {
          dateSelect: yr,
          ano: yr,
          tipoData: tipo,
        },
        () => {
          this.getNsps();
        }
      );
    } else if (tipo == 2) {
      var mes = moment(datos.fecha).format("YYYY-MM");

      this.setState(
        {
          dateSelect: mes,
          mes: mes,
          tipoData: tipo,
        },
        () => {
          this.getNsps();
        }
      );
    } else if (tipo == 3) {
      var sem = datos.fecha;

      this.setState(
        {
          dateSelect: sem,
          sem: sem,
          tipoData: tipo,
        },
        () => {
          this.getNsps();
        }
      );
    } else if (tipo == 4) {
      var dia = datos.fecha;

      this.setState(
        {
          dateSelect: dia,
          dia: dia,
          tipoData: tipo,
        },
        () => {
          this.getNsps();
        }
      );
    } else if (tipo == 5) {
      var hora = datos.fecha;

      this.setState(
        {
          dateSelect: hora,
          hora: hora,
          tipoData: tipo,
        },
        () => {
          this.getNspsLista();
        }
      );
    }
  };

  cerrarModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <GraficoNsps key={"graficoDemanda"} data={this.state.Nsps} tipoData={this.state.tipoData} handleLineCLick={this.handleLineCLick} />
          </div>
        ) : null}

        <Dialog open={this.state.open} onClose={this.cerrarModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
          <DialogContent>
            <GridContainer className="agendar-container">
              <Titulo links={[]} linkActual="" titulo={"NSP (No se presenta) " + moment(this.state.dateSelect).format("DD-MM-YYYY HH:mm:ss")} descripcion="Podrá visualizar toda la información relacionada a los pacientes NSP" />
              <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ minHeight: 200, marginTop: 40 }}>
                {this.state.NspsLista.length > 0 ? (
                  <MaterialTable
                    title=""
                    columns={[
                      { title: "Rut paciente", field: "usu_rut" },
                      { title: "Nombre paciente", field: "nombre_usuario" },
                      { title: "Edad paciente", field: "edad" },
                      { title: "Sector", field: "nombre_sector" },
                      { title: "Especialidad", field: "especialidad" },
                      { title: "Nombre profesional", field: "nombre_profesional" },
                      {
                        title: "Horario",
                        field: "hor_atencion",
                        defaultSort: "asc",
                        render: (rowData) => (
                          <React.Fragment>
                            <p>{moment(rowData.hor_atencion).format("DD-MM-YYYY HH:mm:ss")}</p>
                          </React.Fragment>
                        ),
                      },
                    ]}
                    actions={[]}
                    data={this.state.NspsLista}
                    options={{
                      actionsColumnIndex: -1,
                      search: false,
                      sorting: true,
                      pageSize: this.state.NspsLista.length,
                      pageSizeOptions: [{ value: this.state.NspsLista.length, label: "All" }],
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No hay comentarios registrados",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                ) : null}
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default GraficoNsp;
