import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import { store } from "./../../store/index";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import AnularHora from "components/Usuarios/AnularHora";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

import icono_vista_6 from "assets/img/usuarios/icono_vista_6.svg";
import iconoEliminar from "assets/img/usuarios/icono_eliminar.svg";
import iconoAsistencia from "assets/img/usuarios/icono_asistencia.svg";
import anunciarLlegada from "assets/img/usuarios/icono_anunciar_llegada.svg";

import { confirmAlert } from "react-confirm-alert";

import Tooltip from "@material-ui/core/Tooltip";
import NotificationManager from "react-notifications/lib/NotificationManager";

import CompletarFolio from "./CompletarFolio";

// estilos
//import "../../assets/css/agregar.css";

export default class HorasFuturas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      horas: [],
      colorEstado: ["", "", "", "", "#37a5cf", "#47cf37", "red", "#ffe000", "", "#ee4646", "", "black"],
      formErrors: {},

      anularHora: false,
      age_id: null,

      usuId: props.usuario,
      familiar: props.familiar || false,

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.traerHistorialFuturo(this.state.usuId);
  }

  traerHistorialFuturo = (usuId) => {
    let apiUrl = global.config.modulos.agendamientos + "reservas_futuras/";

    axios.get(apiUrl, { headers: authHeader(), params: { idUsuario: usuId, canal: 5, cesfam: this.state.cesfam } }).then((res) => {
      if (res.data.estado) {
        res.data.datos.map((res) => {
          res["age_fecha_registro"] = res["age_fecha_registro"].replace("T", " ");
          res["hor_atencion"] = res["hor_atencion"].replace("T", " ");
        });
        var horas = res.data.datos;
        this.setState({
          horas: horas,
        });
      } else {
        this.setState({
          horas: [],
        });
      }
    });
  };

  fechaTexto = (dateString) => {
    var meses = [" ", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    var fecha = dateString.split("-");
    var ano = fecha[0];
    var mes = meses[parseInt(fecha[1])];
    var dia = fecha[2];

    var fechaCompleta = dia + " de " + mes + " del " + ano;

    return fechaCompleta;
  };

  mostrarModalAnular = (age_id) => {
    this.setState({
      anularHora: true,
      age_id: age_id,
    });
  };

  ocultarModalAnular = () => {
    this.setState({
      anularHora: false,
    });
    this.traerHistorialFuturo(this.state.usuId);
  };

  mostrarModalAnunciarLlegada = (age_id, especialidad) => {
    confirmAlert({
      title: "",
      message: "Desea confirmar la llegada de este paciente?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.traerExamenes(age_id, especialidad),
          className: "btnAceptar",
        },
      ],
    });
  };

  anunciarLlegada = (age_id) => {
    var data = {
      agenda_id: age_id,
      usuario_panel: this.state.uspId,
    };
    let apiUrl = global.config.modulos.agendamientos + "confirmar_llegada/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Llegada confirmada con éxito");
        this.traerHistorialFuturo(this.state.usuId);
      }
    });
  };

  asistencia = (ageId) => {
    confirmAlert({
      title: "",
      message: "¿El paciente ha asistido a su hora médica?",
      buttons: [
        {
          label: "No se presentó",
          className: "btnCancelar",
          onClick: () => this.cambiarEstadoAgenda(ageId, 9),
        },
        {
          label: "Sí, se presentó",
          className: "btnAceptar",
          onClick: () => this.cambiarEstadoAgenda(ageId, 7),
        },
      ],
    });
  };

  cambiarEstadoAgenda = async (ageId, estadoAgenda) => {
    const datos = {
      agenda: ageId,
      estadoHora: estadoAgenda,
      uspId: this.state.uspId,
    };
    await axios.post(global.config.modulos.agendamientos + "cambio_estado_agenda/", datos, { headers: authHeader() });
    this.traerHistorialFuturo(this.state.usuId);
  };

  traerExamenes = async (agenda, especialidad) => {
    axios
      .get(global.config.modulos.especialidades + "examenes/", {
        headers: authHeader(),
        params: {
          especialidad: especialidad,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        if (data.length > 0) {
          this.confirmarExamen(agenda);
        } else {
          this.anunciarLlegada(agenda);
        }
      })
      .catch((err) => {});
  };

  confirmarExamen = (agenda) => {
    confirmAlert({
      title: "",
      message: "¿El paciente tiene la orden para el examen?",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
          onClick: () => {
            NotificationManager.error("La orden de examen es obligatoria para realizar la reserva y posteriormente el examen, una vez que el paciente presente la orden del examen podrá reservar la hora de atención.");
          },
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () => {
            this.mostrarCompletarFolio(agenda);
          },
        },
      ],
    });
  };

  mostrarCompletarFolio = (agenda) => {
    this.setState({
      completarFolio: true,
      age_id: agenda,
    });
  };

  ocultarCompletarFolio = () => {
    this.setState({
      completarFolio: false,
    });
    this.traerHistorialFuturo(this.state.usuId);
  };

  render() {
    return (
      <div className="historialHoras" style={{ marginLeft: "-10px" }}>
        <Grid container spacing={2} className="fichaUsuario scroll-container">
          <Grid item xs={12}>
            <div>
              <img className="imagenDatoSeleccionado" src={icono_vista_6} />
              <div className="datosUsuario " style={{ border: "inherit" }}>
                <TableContainer style={{ width: "100%", float: "left", marginTop: "" }}>
                  <Table aria-label="simple table" className="bordeTablaHorasFuturas">
                    <TableHead>
                      <TableRow>
                        <TableCell className="cellInicio" align="center">
                          Fecha de registro
                        </TableCell>
                        <TableCell align="center">Especialidad</TableCell>
                        <TableCell align="center">Profesional</TableCell>
                        <TableCell align="center">Canal</TableCell>
                        <TableCell align="center">Estado</TableCell>
                        <TableCell align="center">Fecha de atención</TableCell>
                        <TableCell className="cellTermino" align="center">
                          Acciones
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.horas.length > 0 ? (
                        <React.Fragment>
                          {this.state.horas.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{row.age_fecha_registro}</TableCell>
                              <TableCell align="center">{row.esp_nombre}</TableCell>
                              <TableCell align="center">{row.nombreProfesional}</TableCell>
                              <TableCell align="center">
                                <p>
                                  {row.canal}
                                  {row.canal == "Some" ? ": " + row.medio_reserva : ""}
                                </p>
                                <p>{row.nombre_usuario_panel ? row.nombre_usuario_panel : ""}</p>

                                {row.age_grupo_familiar ? (
                                  <React.Fragment>
                                    <Tooltip title={row.familiar_que_reservo + " - " + row.rut_familiar_que_reservo} aria-label={row.familiar_que_reservo + " - " + row.rut_familiar_que_reservo}>
                                      <p
                                        style={{
                                          marginTop: 5,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {"Reservada por grupo familiar"}
                                      </p>
                                    </Tooltip>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="center">{row.estadoHorario == 1 ? row.estadoAgenda : "Bloqueada"}</TableCell>
                              <TableCell align="center">{row.hor_atencion}</TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  display: "flex",
                                  border: "none",
                                  height: "10vw",
                                  justifyContent: "center",
                                }}
                              >
                                {this.state.herramientas.includes("H59") && row.hor_atencion <= moment().add(3, "hours").format("YYYY-MM-DD HH:mm:ss").toString() ? (
                                  <Tooltip title={"Asistencia"} aria-label={"Asistencia"}>
                                    <img
                                      style={{
                                        width: 30,
                                        float: "left",
                                      }}
                                      className="imagenUsuario"
                                      src={iconoAsistencia}
                                      onClick={() => this.asistencia(row.age_id)}
                                    />
                                  </Tooltip>
                                ) : null}

                                {row.age_estado_hora != 6 && row.age_estado_hora != 11 && row.hor_atencion >= moment().format("YYYY-MM-DD HH:mm:ss").toString() ? (
                                  <Tooltip title={this.state.familiar ? "Anular la hora del familiar" : "Anular la hora del paciente"} aria-label={this.state.familiar ? "Anular la hora del familiar" : "Anular la hora del paciente"}>
                                    <img
                                      style={{
                                        width: 30,
                                        float: "left",
                                      }}
                                      className="imagenUsuario"
                                      src={iconoEliminar}
                                      onClick={() => this.mostrarModalAnular(row.age_id)}
                                    />
                                  </Tooltip>
                                ) : null}
                                {row.age_estado_hora != 6 &&
                                row.age_estado_hora != 11 &&
                                row.age_estado_hora != 10 &&
                                row.hor_atencion >= moment().format("YYYY-MM-DD HH:mm:ss").toString() &&
                                row.hor_atencion <=
                                  moment()
                                    .add(this.state.herramientas.includes("H73") ? 6 : 2, "hours")
                                    .format("YYYY-MM-DD HH:mm:ss")
                                    .toString() ? (
                                  <Tooltip title={this.state.familiar ? "Anunciar llegada del familiar" : "Anunciar llegada del paciente"} aria-label={this.state.familiar ? "Anunciar llegada del familiar" : "Anunciar llegada del paciente"}>
                                    <img
                                      style={{
                                        width: 35,
                                        float: "left",
                                        marginTop: "-3px",
                                      }}
                                      className="imagenUsuario"
                                      src={anunciarLlegada}
                                      onClick={() => this.mostrarModalAnunciarLlegada(row.age_id, row.age_especialidad)}
                                    />
                                  </Tooltip>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ) : (
                        <TableRow>
                          <TableCell align="center" style={{ height: 250 }} colSpan={6}>
                            No tiene horas futuras
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {this.state.anularHora ? <AnularHora ocultar={this.ocultarModalAnular} ageid={this.state.age_id} usuId={this.state.usuId}></AnularHora> : null}
                  {this.state.completarFolio ? <CompletarFolio ocultar={this.ocultarCompletarFolio} ageId={this.state.age_id} usuId={this.state.usuId}></CompletarFolio> : null}
                </TableContainer>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
