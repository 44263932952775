import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class AlertaHTML extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      mensaje: props.mensaje.mensaje,
      gestion: props.mensaje.gestion,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { mensaje, gestion } = this.state;
    const gestiones = ["", "NO Covid", "Covid", "Plan Contigo", "Apoyo Social"];
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="alertaHTML">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  {mensaje}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  {gestion.map((reg, index) => (
                    <p
                      key={index}
                      style={{
                        marginTop: 10,
                        borderRadius: 10,
                        border: "2px solid #eaa028",
                        padding: 15,
                      }}
                    >
                      <GridContainer className="agendar-container">
                        <GridItem xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Gestión:</label> {gestiones[reg.geg_tipo]}
                          </p>
                        </GridItem>

                        <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Fecha de ingreso:</label> {reg.geg_fecha_ingreso ? reg.geg_fecha_ingreso.replace("T", " ") : ""}
                          </p>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Cesfam:</label> {reg.cesfam}
                          </p>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Ubicación:</label> {reg.ubicacion}
                          </p>
                        </GridItem>
                        <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Tomado por:</label> {reg.tomado}
                          </p>
                          {this.state.tipo == 1 ? (
                            <p>
                              <label style={{ fontWeight: "bold" }}>Requerimiento:</label> {reg.geg_requerimiento}
                            </p>
                          ) : null}
                          {reg.geg_ubicacion == 6 ? (
                            <React.Fragment>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Fecha de finalización:</label> {reg.geg_fecha_finalizacion ? reg.geg_fecha_finalizacion.replace("T", " ") : ""}
                              </p>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Solución:</label> {reg.geg_solucion}
                              </p>
                            </React.Fragment>
                          ) : null}
                        </GridItem>
                        <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Última observación:</label> {reg.observacion}
                          </p>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ))}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <a onClick={this.salir} style={{ marginLeft: 20 }} className="btnAgregarCaso fondoNaranjo">
                    Aceptar
                  </a>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
