import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default class TablaSexo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      datas: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      edad_max: props.edad_max,
      edad_min: props.edad_min,
      stc_nombre: "",
      root: "",
      sec: "",
    };
  }

  tablas_sexo() {
    this.setState({ dates: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "horasXsexo/", {
        headers: authHeader(),
        params: {
          fecha_act: this.state.fecha_actual,
          fecha_ini: this.state.fecha_inicio,
          fecha_ter: this.state.fecha_termino,
          edad_min: this.state.edad_min,
          edad_max: this.state.edad_max,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ dates: res.data.datos }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          this.setState({ datas: [] });

          axios
            .get(global.config.modulos.grafico_demanda + "agendadasXsector/", {
              headers: authHeader(),
              params: {
                fecha_act: this.state.fecha_actual,
                fecha_ini: this.state.fecha_inicio,
                fecha_ter: this.state.fecha_termino,
                edad_min: this.state.edad_min,
                edad_max: this.state.edad_max,
              },
            })
            .then((res) => {
              if (!res.data.estado) {
                NotificationManager.info("No hay datos");
                return;
              }
              this.setState({ datas: res.data.datos }, () => {
                try {
                  this.state.root.dispose();
                } catch (e) {}

                let root = am5.Root.new("chartdiv7");

                let myTheme = am5.Theme.new(root);

                myTheme.rule("Grid", ["base"]).setAll({
                  strokeOpacity: 0.1,
                });

                root.setThemes([am5themes_Animated.new(root), myTheme]);

                let chart = root.container.children.push(
                  am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: "none",
                    wheelY: "none",
                    pinchZoomX: true,
                  })
                );

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineX.set("visible", false);

                let yRenderer = am5xy.AxisRendererY.new(root, {
                  minGridDistance: 30,
                });
                yRenderer.grid.template.set("location", 1);

                let yAxis = chart.yAxes.push(
                  am5xy.CategoryAxis.new(root, {
                    maxDeviation: 0,
                    categoryField: "category",
                    renderer: yRenderer,
                    tooltip: am5.Tooltip.new(root, {}),
                  })
                );

                let xAxis = chart.xAxes.push(
                  am5xy.ValueAxis.new(root, {
                    maxDeviation: 0,
                    min: 0,
                    renderer: am5xy.AxisRendererX.new(root, {
                      visible: true,
                      strokeOpacity: 0.1,
                    }),
                  })
                );

                let series = chart.series.push(
                  am5xy.ColumnSeries.new(root, {
                    name: "Series 1",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: "value",
                    sequencedInterpolation: true,
                    categoryYField: "category",
                    tooltip: am5.Tooltip.new(root, {
                      labelText: "{categoryY}:{valueX}",
                    }),
                  })
                );

                series.bullets.push(function () {
                  return am5.Bullet.new(root, {
                    locationX: 1,
                    sprite: am5.Label.new(root, {
                      centerY: am5.p50,
                      centerX: am5.p100,
                      fill: root.interfaceColors.get("alternativeText"),
                      text: "{value}",
                      populateText: true,
                    }),
                  });
                });

                let columnTemplate = series.columns.template;

                columnTemplate.setAll({
                  cursorOverStyle: "pointer",
                  cornerRadiusBR: 10,
                  cornerRadiusTR: 10,
                  strokeOpacity: 0,
                });
                columnTemplate.adapters.add("fill", (fill, target) => {
                  return chart.get("colors").getIndex(series.columns.indexOf(target));
                });

                columnTemplate.adapters.add("stroke", (stroke, target) => {
                  return chart.get("colors").getIndex(series.columns.indexOf(target));
                });

                let data = [
                  {
                    category: this.state.datas[2]["category"],
                    value: this.state.datas[2]["value"],
                  },
                  {
                    category: this.state.datas[0]["category"],
                    value: this.state.datas[0]["value"],
                  },
                  {
                    category: this.state.datas[1]["category"],
                    value: this.state.datas[1]["value"],
                  },
                ];

                let thiss = this;

                columnTemplate.events.on("click", function (ev) {
                  thiss.setState({ stc_nombre: ev.target.dataItem.dataContext.category }, () => {
                    thiss.props.mostrarGraficoAgendas(thiss.state.stc_nombre);
                  });
                });

                yAxis.data.setAll(data);
                series.data.setAll(data);

                series.appear(1000);
                chart.appear(500, 50);
              });
            });
        });
      });
  }

  componentDidMount() {
    this.tablas_sexo();
  }

  render() {
    return (
      <div
        id="tablas"
        style={{
          width: "50%",
          overflowY: "scroll",
          overflowX: "scroll",
          height: "50%",
          zIndex: 1,
          position: "fixed",
          left: "45%",
          textAlign: "center",
        }}
      >
        <h2 style={{ marginTop: "20px" }}>horas agendadas por Sector</h2>

        <div
          id="chartdiv7"
          style={{
            display: "flex",
            width: "90%",
            height: "50%",
            zIndex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        ></div>
      </div>
    );
  }
}
