import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { store } from "store/index";
import { Grid, List, ListItem, MenuItem, Select } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";
import MaterialTable from "material-table";

import eliminar from "assets/img/usuarios/icono_eliminar.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { confirmAlert } from "react-confirm-alert";
import HelpIcon from "@material-ui/icons/Help";
import prioritario_manual from "assets/img/admin_totem/prioritario_manual.png";
import rehacer from "assets/img/admin_totem/rehacer.png";

// CSS
import crearTotem from "assets/css/crearTotem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Apariencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img_carrucel: "",
      ruta_template: "",
      totem: store.getState().totem,
      prio: "",
      tamano_logo: "",
      nombre_logo: "",
      prev_logo: "",
      carruselActivo: "",
    };
  }

  componentDidMount() {
    this.publicidad_carrucel();
    this.configuracion_aparencia();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  configuracion_aparencia = () => {
    const apiUrl = global.config.modulos.admin_totem + "configuraciones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
          tipo: 3,
        },
      })
      .then((res) => {
        var data = res.data;
        document.getElementById("sloganM").value = data["tot_eslogan"];
        document.getElementById("tiempoSinUso").value = data["tot_tiempo_sin_uso"];
        document.getElementById("tiempoCambioImg").value = data["tot_cambio_imagenes"];

        this.setState({
          prio: data["tot_popup_prioritario"],
          tamano_logo: data["tot_tamano_logo"],
          nombre_logo: data["tot_logo_comuna"],
          carruselActivo: data["tot_carrucel_activo"],
        });
      });
  };

  publicidad_carrucel = () => {
    const apiUrl = global.config.modulos.admin_totem + "publicidad/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
          tipo: 1,
        },
      })
      .then((res) => {
        var data = res.data;
        this.setState({
          carrucel: data.publicidad,
          ruta_template: data.ruta,
        });
      });
  };

  abrirImgCarrusel = (ruta) => {
    ruta = ruta.replace("localhost", global.config.modulos.ip_socket);
    this.setState({
      img_carrucel: ruta,
      abrirImgCarrusel: true,
    });
  };

  cerrarImgCarrusel = () => {
    this.setState({
      abrirImgCarrusel: false,
    });
  };

  cargarArchivo = async (event, ubicacion) => {
    const data = new FormData();
    var arc = event[0];
    var nombreNuevo = this.state.totem + "_" + arc["name"];

    var blob = arc.slice(0, arc.size, "image/png");
    var newFile = new File([blob], nombreNuevo, { type: "image/png" });

    data.append("file", newFile);
    data.append("filename", ubicacion);
    this.subirArchivo(data);

    const apiUrl = global.config.modulos.admin_totem + "publicidad/";
    await axios.post(
      apiUrl,
      {
        totem: this.state.totem,
        nombre: nombreNuevo,
        tipo: 1,
      },
      {
        headers: authHeader(),
      }
    );

    confirmAlert({
      title: "Guardado con éxito",
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
    document.getElementById("archivo").value = "";
    this.publicidad_carrucel();
  };

  modificar_publicidad = (idPublicidad, estado, tipo) => {
    if (estado == 1) {
      estado = 0;
    } else {
      estado = 1;
    }

    var data = { idPublicidad: idPublicidad, tipo: tipo };
    data["cal_estado"] = estado;

    axios
      .patch(global.config.modulos.admin_totem + "publicidad_por_id/", data, {
        headers: authHeader(),
      })
      .then((res) => {
        this.publicidad_carrucel();
      });
  };

  modificar_configuracion = (tipo) => {
    const { totem, tamano_logo, prio, nombre_logo, carruselActivo } = this.state;
    var data = {
      totem: totem,
      tipo: tipo,
      tot_tamano_logo: tamano_logo,
      tot_popup_prioritario: prio,
      tot_logo_comuna: nombre_logo,
      tot_carrucel_activo: carruselActivo,
    };

    var slogan = document.getElementById("sloganM").value;
    var tiempoSinUso = document.getElementById("tiempoSinUso").value;
    var tiempoCambioImg = document.getElementById("tiempoCambioImg").value;

    data["tot_eslogan"] = slogan;
    data["tot_tiempo_sin_uso"] = tiempoSinUso;
    data["tot_cambio_imagenes"] = tiempoCambioImg;

    axios.patch(global.config.modulos.admin_totem + "configuraciones_por_id/", data, { headers: authHeader() }).then((res) => {
      this.mostrarAlerta("Guardado con éxito");
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cargarLogo = (event, ubicacion) => {
    const data = new FormData();
    var arc = event[0];
    var nombreNuevo = this.state.totem + "_" + arc["name"];

    var blob = arc.slice(0, arc.size, "image/png");
    var newFile = new File([blob], nombreNuevo, { type: "image/png" });

    data.append("file", newFile);
    data.append("filename", ubicacion);
    this.subirArchivo(data);

    const inputFile = document.getElementById("logoTotem");
    inputFile.value = "";

    this.setState({
      nombre_logo: "https://panelv2hs.cl/archivos/totem/logo_comuna/" + nombreNuevo,
    });
  };

  subirArchivo = async (data) => {
    this.setState({ cargando: true });
    await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_totem", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    this.setState({ cargando: false });
    return true;
  };

  abrirModal_prio = () => {
    this.setState({
      abrirPrio: true,
    });
  };

  cerrarModal_prio = () => {
    this.setState({
      abrirPrio: false,
    });
  };

  abrirModal_prev = () => {
    this.setState({
      prev_logo: true,
    });
  };

  cerrarPrev_logo = () => {
    this.setState({
      prev_logo: false,
    });
  };

  render() {
    const { ruta_template, prio, tamano_logo, carruselActivo, abrirImgCarrusel, carrucel, abrirPrio, prev_logo, nombre_logo, img_carrucel } = this.state;
    return (
      <GridContainer style={{ width: "100%", marginTop: "10px" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>Título tótem</InputLabel>
                      <input type="text" id="sloganM" className={crearTotem.input} />
                    </Grid>

                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>Tiempo máximo por sesión (segundos)</InputLabel>
                      <input type="number" id="tiempoSinUso" className={crearTotem.input} defaultValue={"30"} min="1" />
                    </Grid>

                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>Tiempo por imagen (segundos)</InputLabel>
                      <input type="number" id="tiempoCambioImg" className={crearTotem.input} defaultValue={"15"} min="1" />
                    </Grid>
                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>Carrusel</InputLabel>
                      <input className={crearTotem.inputFile} accept=".png" type="file" id="archivo" name="archivo" onChange={(e) => this.cargarArchivo(e.target.files, "publicidad/totem/" + ruta_template + "/carrucel/")} />
                    </Grid>
                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>
                        Selección Prioritario <HelpIcon onClick={this.abrirModal_prio} className={crearTotem.helpIcon} />
                      </InputLabel>
                      <Select labelId="demo-simple-select-label" id="prio" disableUnderline={true} className={crearTotem.selectores} name="prio" value={prio} onChange={this.cambiarValores}>
                        <MenuItem value={1}>Manual</MenuItem>
                        <MenuItem value={2}>Automático</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>
                        Tamaño Logo <HelpIcon className={crearTotem.helpIcon} />
                      </InputLabel>
                      <input type="number" id="tamano_logo" name="tamano_logo" className={crearTotem.input} value={tamano_logo} min="1" onChange={this.cambiarValores} />
                    </Grid>
                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>
                        Logo de totem <HelpIcon onClick={this.abrirModal_prev} className={crearTotem.helpIcon} />
                      </InputLabel>
                      <input className={crearTotem.inputFile} accept=".png" type="file" id="logoTotem" name="logoTotem" onChange={(e) => this.cargarLogo(e.target.files, "logo_comuna/")} />
                    </Grid>
                    <Grid item xs={3} sm={3} className={crearTotem.MargenGridADM}>
                      <InputLabel className={crearTotem.textoTitulos}>Carrusel activo</InputLabel>
                      <Select labelId="demo-simple-select-label" id="carruselActivo" disableUnderline={true} className={crearTotem.selectores} name="carruselActivo" value={carruselActivo} onChange={this.cambiarValores}>
                        <MenuItem value={true}>Activado</MenuItem>
                        <MenuItem value={false}>Desactivado</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <a
                    className={crearTotem.btnAgregarAcceso}
                    style={{ marginTop: 30, width: "15%" }}
                    onClick={() => {
                      this.modificar_configuracion(3);
                    }}
                  >
                    Guardar
                  </a>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={9} sm={9}>
              <div className="divBordeTablaAccesos">
                <MaterialTable
                  title=""
                  columns={[
                    {
                      title: "Imagenes del carrusel",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>
                            <label style={{ cursor: "pointer" }} onClick={() => this.abrirImgCarrusel(rowData.cal_ruta)}>
                              <img src={rowData.cal_ruta} width="10%" />
                            </label>
                          </p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: "Acciones",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>{rowData.cal_estado == 1 ? <img className="botonesTablas" style={{ width: "40px" }} src={eliminar} onClick={() => this.modificar_publicidad(rowData.cal_id, rowData.cal_estado, 1)} /> : <img className="botonesTablas" style={{ width: "40px" }} src={rehacer} onClick={() => this.modificar_publicidad(rowData.cal_id, rowData.cal_estado, 1)} />}</p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                  data={carrucel}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  localization={{
                    header: {
                      actions: "Acciones",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "filas",
                      firstTooltip: "primera",
                      previousTooltip: "anterior",
                      nextTooltip: "siguiente",
                      lastTooltip: "última",
                    },
                    toolbar: {
                      nRowsSelected: "{0} fila(s) seleccionadas",
                      searchTooltip: "Buscar",
                      searchPlaceholder: "Buscar",
                    },
                    body: {
                      emptyDataSourceMessage: "No tiene historial de atenciones",
                      filterRow: {
                        filterTooltip: "Buscar",
                      },
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </GridItem>

        {abrirImgCarrusel ? (
          <div>
            <Dialog open={abrirImgCarrusel} onClose={this.cerrarImgCarrusel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent style={{ display: "flex", justifyContent: "center" }}>
                <img src={img_carrucel} style={{ width: "45%" }} />
              </DialogContent>
            </Dialog>
          </div>
        ) : null}

        <Dialog open={abrirPrio} onClose={this.cerrarModal_prio} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Prioridad</h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3} style={{ justifyContent: "center" }}>
                <Grid item xs={8} sm={8}>
                  {prio == 0 ? <InputLabel className={crearTotem.textoTitulos}>Al seleccionar una opción podrá ver una imagen de ejemplo de como se ve el tipo de prioridad manual </InputLabel> : <></>}
                  {prio == 1 ? <img src={prioritario_manual} width="100%" /> : <></>}
                  {prio == 2 ? <InputLabel className={crearTotem.textoTitulos}>La opción automatica no se aplica el popap de prioritario </InputLabel> : <></>}
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>

        <Dialog open={prev_logo} onClose={this.cerrarPrev_logo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Previsualización logo comuna</h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3} style={{ justifyContent: "center" }}>
                <img src={nombre_logo} style={{ width: tamano_logo + "vw" }} />
              </Grid>
            </Grid>
            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>
      </GridContainer>
    );
  }
}
