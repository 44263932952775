import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioInscribirPsicologo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,
      beneficio: props.beneficio,
      fechaInicio: null,
      fechaParto: null,
      profesionales: [],
      profesional: null,
      atencionExterna: null,

      profSelecccionados: [],
      espSelecccionados: [],

      open: true,
      formErrors: {},

      nombreBeneficio: props.nombreBeneficio,
      especialidades: props.especialidades,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.traerEspecialidades();
  }

  traerProfesionalesEsp = async (espe) => {
    var res = await axios.get(global.config.modulos.profesionales + "por_especialidad/", {
      headers: authHeader(),
      params: {
        especialidad: parseInt(espe),
      },
    });
    return res.data.datos;
  };

  traerEspecialidades = async () => {
    let arrEspecialidades = this.state.especialidades.split(",");

    var arrProfe = [];
    for (var i = 0; i < arrEspecialidades.length; i++) {
      var res = await axios.get(global.config.modulos.especialidades, {
        headers: authHeader(),
        params: {
          esp_id: parseInt(arrEspecialidades[i]),
        },
      });
      var especialidad = res.data.datos;
      var data = {
        espNombre: especialidad.esp_nombre,
        espId: especialidad.esp_id,
      };
      var profesionales = await this.traerProfesionalesEsp(arrEspecialidades[i]);
      data.profesionales = profesionales;
      arrProfe.push(data);
    }

    this.setState({
      profesionales: arrProfe,
    });
  };

  inscribir = () => {
    if (this.state.profSelecccionados.length == 0) {
      return NotificationManager.warning("Selecciona un profesional");
    } else {
      if (this.state.profesionales.length > 1) {
        if (this.state.profSelecccionados.length <= 1) {
          return NotificationManager.warning("Selecciona un profesional");
        }
      }
      var tipo = 0;
      var profSelecccionados = this.state.profSelecccionados;

      for (var i = 0; i < this.state.profSelecccionados.length; i++) {
        if (profSelecccionados[i].tipo == 1) {
          tipo = 1;
        }
      }

      if (tipo == 0) {
        return NotificationManager.warning("Selecciona un profesional asignado");
      }
    }

    axios
      .post(
        global.config.modulos.beneficios + "inscribir_beneficio_psico/",
        {
          fechaInicio: null,
          fechaExtra: null,
          fechaTermino: null,
          usuario: this.state.usuId,
          beneficio: this.state.beneficio,
          usuarioPanel: this.state.uspId,
          atencionExterna: this.state.atencionExterna,
          profesionales: this.state.profSelecccionados,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Inscrito con éxito");
        } else {
          NotificationManager.error("Ha ocurrido un error al inscribir");
        }
        this.salir();
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  seleccionarProfesionales = (esp, tipo, e) => {
    let profesionales = this.state.profSelecccionados;
    let especialidades = this.state.espSelecccionados;

    var prof = e.target.value;
    if (prof != "") {
      var data = {
        profesional: parseInt(prof),
        especialidad: esp,
        tipo: tipo,
      };

      if (especialidades.includes(esp)) {
        var pos = especialidades.indexOf(esp);
        var profe = profesionales[pos];
        if (profe.tipo != tipo) {
          document.getElementById(esp + "-" + profe.tipo).value = "";
        }
        especialidades[pos] = esp;
        profesionales[pos] = data;
      } else {
        especialidades.push(esp);
        var pos = especialidades.indexOf(esp);
        profesionales[pos] = data;
      }

      this.setState(
        {
          profSelecccionados: profesionales,
          espSelecccionados: especialidades,
        },
        () => {
          console.log(this.state.profSelecccionados);
          console.log(this.state.espSelecccionados);
        }
      );
    } else {
      if (especialidades.includes(esp)) {
        var pos = especialidades.indexOf(esp);
        profesionales.splice(pos, 1);
        especialidades.splice(pos, 1);
      }

      this.setState(
        {
          profSelecccionados: profesionales,
          espSelecccionados: especialidades,
        },
        () => {
          console.log(this.state.profSelecccionados);
          console.log(this.state.espSelecccionados);
        }
      );
    }
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img alt="" class="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Inscribir beneficio {this.state.nombreBeneficio}</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <label>Selecciona el profesional asignado al paciente</label>
                </GridItem>

                {this.state.profesionales &&
                  this.state.profesionales.map((res, index) => {
                    return (
                      <GridItem xs={6} style={{ marginTop: 20 }}>
                        <GridItem xs={12} style={{ marginTop: 20 }}>
                          <label>{res.espNombre}</label>
                        </GridItem>

                        {res.espId == 991 || res.espId == 992 || res.espId == 993 ? (
                          <GridItem xs={12} style={{ marginTop: 20 }}>
                            <InputLabel>Profesional asignado</InputLabel>
                            <select id={res.espId + "-" + 1} name="profesionalOpc" onChange={this.seleccionarProfesionales.bind(this, res.espId, 1)} className={"inputText fechaBeneficio"}>
                              <option value="">Seleccione</option>
                              {res.profesionales && res.profesionales.map((profesional) => <option value={profesional.pro_id}>{profesional.nombreProfesional}</option>)}
                            </select>
                          </GridItem>
                        ) : (
                          <GridItem xs={12} style={{ marginTop: 20 }}>
                            <InputLabel>Profesional asignado</InputLabel>
                            <select id={res.espId + "-" + 1} name="profesionalAsig" onChange={this.seleccionarProfesionales.bind(this, res.espId, 1)} className={"inputText fechaBeneficio"}>
                              <option value="">Seleccione</option>
                              {res.profesionales && res.profesionales.map((profesional) => <option value={profesional.pro_id}>{profesional.nombreProfesional}</option>)}
                            </select>
                          </GridItem>
                        )}
                      </GridItem>
                    );
                  })}

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                  <Button id="modalEliminarBtnAceptar" className="botonDialog fondoVerde" onClick={this.inscribir}>
                    Inscribir
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
