export default global.config = {
  modulos: {
    REACT_APP_URL_SERVER_: process.env.REACT_APP_URL_SERVER_,

    base: process.env.REACT_APP_URL_SERVER + "api/",
    cesfams: process.env.REACT_APP_URL_SERVER + "api/cesfams/",
    //cesfams: "http://localhost:3001/api/cesfams/",

    parametros: process.env.REACT_APP_URL_SERVER + "api/parametros/",
    //parametros: "http://127.0.0.1:8001/api/parametros/",

    usuariospanel: process.env.REACT_APP_URL_SERVER + "api/usuarios_panel/",
    //usuariospanel: "http://localhost:3003/api/usuarios_panel/",

    roles: process.env.REACT_APP_URL_SERVER + "api/roles/",
    //roles: "http://localhost:8000/api/roles/",

    sectores: process.env.REACT_APP_URL_SERVER + "api/sectores/",
    //sectores: "http://localhost:3005/",

    beneficios: process.env.REACT_APP_URL_SERVER + "api/beneficios/",
    //beneficios: "http://127.0.0.1:3006/api/beneficios/",

    prematuros: process.env.REACT_APP_URL_SERVER + "api/prematuros/",
    //prematuros: "http://localhost:3006/api/prematuros/",

    prioritarios: process.env.REACT_APP_URL_SERVER + "api/prioritarios/",
    //prioritarios: "http://localhost:3014/api/prioritarios/",

    ciclos: process.env.REACT_APP_URL_SERVER + "api/ciclos/",
    //ciclos: "http://localhost:3006/api/ciclos/",

    especialidades: process.env.REACT_APP_URL_SERVER + "api/especialidades/",
    //especialidades: "http://localhost:3007/api/especialidades/",

    usuarios: process.env.REACT_APP_URL_SERVER + "api/usuarios/",
    //usuarios: "http://200.111.252.140:3009/api/usuarios/",

    telefonos: process.env.REACT_APP_URL_SERVER + "api/telefonos/",
    //telefonos: "http://localhost:3009/api/telefonos/",

    grupo_familiar: process.env.REACT_APP_URL_SERVER + "api/grupo_familiar/",
    //grupo_familiar: "http://localhost:3009/api/grupo_familiar/",

    comentarios: process.env.REACT_APP_URL_SERVER + "api/comentarios/",
    //comentarios: "http://localhost:8000/",

    login: process.env.REACT_APP_URL_SERVER + "login/",
    //login: "http://localhost:3010/",

    profesionales: process.env.REACT_APP_URL_SERVER + "api/profesionales/",
    //profesionales: "http://localhost:3011/api/profesionales/",

    agendamientos: process.env.REACT_APP_URL_SERVER + "api/agendamiento/",
    //agendamientos: "http://localhost:3012/api/agendamiento/",

    reportes: process.env.REACT_APP_URL_SERVER + "api/reportes_agendas/",
    //reportes: "http://localhost:3067/api/reportes/",

    noPercapitados: process.env.REACT_APP_URL_SERVER + "api/noPercapitados/",

    listaEspera: process.env.REACT_APP_URL_SERVER + "api/listadoOirs/",
    //listaEspera: "http://localhost:3062/api/listadoOirs/",

    notificaciones: process.env.REACT_APP_URL_SERVER + "api/notificaciones/",
    //notificaciones: "http://localhost:8000/api/notificaciones/",

    reservas: process.env.REACT_APP_URL_SERVER + "api/reservas/",
    //reservas: "http://localhost:3015/api/reservas/",

    categorizador: process.env.REACT_APP_URL_SERVER + "api/categorizador/",
    //categorizador: "http://localhost:3016/api/categorizador/",

    transformador: process.env.REACT_APP_URL_SERVER + "api/transformador/",
    //transformador: "http://localhost:3016/api/transformador/",

    demanda: process.env.REACT_APP_URL_SERVER + "api/demanda/",
    //demanda: "http://localhost:3021/api/demanda/",

    gestion: process.env.REACT_APP_URL_SERVER + "api/gestion/",
    //gestion: "http://localhost:3029/api/gestion/",

    videollamada: process.env.REACT_APP_URL_SERVER + "api/video_llamada/",
    //videollamada: "http://localhost:3032/api/video_llamada/",

    bloqueos: process.env.REACT_APP_URL_SERVER + "api/bloqueos/",
    //bloqueos: "http://localhost:3019/api/bloqueos/",

    cuestionarios: process.env.REACT_APP_URL_SERVER + "api/cuestionarios/",
    //cuestionarios: "http://localhost:3035/api/cuestionarios/",

    reservas_externas: process.env.REACT_APP_URL_SERVER + "api/reservas_externas/",
    //reservas_externas: "http://localhost:3048/api/reservas_externas/",

    chat: process.env.REACT_APP_URL_SERVER + "api/app/",

    aplicaciones: process.env.REACT_APP_URL_SERVER + "api/app/",
    //aplicaciones: "http://localhost:3034/api/app/",

    /*     reportes_agendas:
      process.env.REACT_APP_URL_SERVER + "api/reportes_agendas/",
    //reportes_agendas: "http://localhost:3055/api/reportes_agendas/", */

    repartos: process.env.REACT_APP_URL_SERVER + "api/repartos/",
    //repartos: "http://localhost:8000/api/repartos/",

    totem: process.env.REACT_APP_URL_SERVER + "api/totem/",
    //totem: "http://localhost:8000/api/totem/",

    metas: process.env.REACT_APP_URL_SERVER + "api/metas/",
    //metas: "http://localhost:3068/api/metas/",

    difusion: process.env.REACT_APP_URL_SERVER + "api/difusion/",

    videollamada_grafico: process.env.REACT_APP_URL_SERVER + "api/videollamada_graficos/",
    //videollamada_grafico: "http://localhost:3070/api/videollamada_graficos/",

    agendamiento_celery: process.env.REACT_APP_URL_SERVER + "api/agendamiento_celery/",
    //agendamiento_celery: "http://localhost:3071/api/agendamiento_celery/",

    carga_masiva_usuario: process.env.REACT_APP_URL_SERVER + "api/CargaMasivaUsuario/",
    //carga_masiva_usuario: "http://localhost:3069/api/CargaMasivaUsuario/",

    grafico_demanda: process.env.REACT_APP_URL_SERVER + "api/graficosdw/",
    //grafico_demanda: "http://10.0.1.97:8000/api/graficosdw/",

    cm_atenciones_externas: process.env.REACT_APP_URL_SERVER + "api/cm_atenciones_externas/",
    //cm_atenciones_externas: "http://localhost:3083/api/cm_atenciones_externas/",

    admin_totem: process.env.REACT_APP_URL_SERVER + "api/admin_totem/",
    //admin_totem: "http://localhost:8001/api/admin_totem/",

    solicitudTrabajo: process.env.REACT_APP_URL_SERVER + "api/solicitud_trabajo/",
    //solicitudTrabajo: "http://localhost:3091/api/solicitud_trabajo/",

    clientes: process.env.REACT_APP_URL_SERVER + "api/clientes/",
    //clientes: "http://localhost:3004/api/clientes/",

    api_transformador: process.env.REACT_APP_URL_SERVER + "api/transformador/",
    //api_transformador: "http://localhost:3076/api/transformador/",

    cargas_masivas: process.env.REACT_APP_URL_SERVER + "api/cargas_masivas/",
    //cargas_masivas: "http://localhost:3097/api/cargas_masivas/",
  },
};
