import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import moment from "moment";

/* eslint-disable no-use-before-define */

import { store } from "store/index";
import { DialogTitle, Grid, MenuItem, Select, InputBase } from "@material-ui/core";
import { socketNotificacion } from "service/socket";

import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { confirmAlert } from "react-confirm-alert";

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

//JS
import ModalAlerta from "./modalAlerta";
import Usuario from "./InformacionUsuario";
import Cargando from "../../../components/Helpers/Cargando";

export default class IngresarSolicitud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Arrtipo_solicitud: [],
      contenido: [],
      camposErr: {},

      tipo_solicitud: "",
      motivo: "",
      codigo: "",
      horaEnvio: "",
      fecha_estimada: "",
      online_o_presencial: "",

      usuariopanel: store.getState().usp,

      soli_enviada: false,
      sigue: true,
      soli_datos: false,
      cargando: false,
      modalDeAlerta: false,
    };
  }

  componentDidMount() {
    this.traerTiposSolcitudes();
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerTiposSolcitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "tipos_solicitudes/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        Arrtipo_solicitud: res.data.datos,
      });
    });
  };

  validaciones = () => {
    const { tipo_solicitud, motivo, online_o_presencial } = this.state;
    let validacion = true;
    const camposErr = {};

    if (!tipo_solicitud) {
      validacion = false;
      camposErr["Tipo_solicitud"] = "Tipo de solicitud";
    }

    if (tipo_solicitud == 5 && !online_o_presencial) {
      validacion = false;
      camposErr["Online_presencial"] = "Online o presencial";
    }

    if (!motivo) {
      validacion = false;
      camposErr["Motivo"] = "Motivo";
    }

    this.setState({ camposErr: camposErr });

    if (validacion) {
      this.enviarSolcitudes();
    } else {
      this.modalError(camposErr);
    }
  };

  modalError = (err) => {
    const contenido = [];
    contenido.push(
      <div>
        <h2 style={{ color: "black", fontSize: "3rem" }}>¡Atención!</h2>
        <br />
        <p style={{ fontSize: "1.2rem" }}>Para continuar debes ingresar los siguientes datos faltantes:</p>
        <br />
        {Object.entries(err).map(([key, value], index) => (
          <div key={index}>
            <li style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{value}</li>
          </div>
        ))}
        <br />
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <button onClick={() => this.cerrar(2)} className={SolicitudTrabajo.btnAcciones} style={{ width: "150px" }}>
            Aceptar
          </button>
        </div>
      </div>
    );
    this.setState({ modalDeAlerta: true, contenido: contenido });
  };

  modalReunionCapacitacion = (err) => {
    const contenido = [];
    contenido.push(
      <div>
        <h2 style={{ color: "black", fontSize: "3rem" }}>¡Atención!</h2>
        <br />
        <br />
        <p style={{ fontSize: "1.2rem" }}>
          Al ser una reunión o capacitación presencial la fecha de realización de esta
          <br />
          depende netamente de la disponibilidad horaria de nuestros agentes y los tiempos
          <br />
          de espera para esta podrían ser mas altos de lo normal...
        </p>
        <p style={{ fontSize: "1.2rem" }}>¿Esta segur@ que desea continuar?</p>
        <br />
        <br />
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <button onClick={() => this.cerrar(2)} className={SolicitudTrabajo.btnAcciones2} style={{ width: "150px", backgroundColor: "#c0262d" }}>
            No
          </button>
          <button onClick={() => this.guardarSolicitud()} className={SolicitudTrabajo.btnAcciones2} style={{ width: "150px", backgroundColor: "#3ab549" }}>
            Si
          </button>
        </div>
      </div>
    );
    this.setState({ modalDeAlerta: true, contenido: contenido });
  };

  guardarSolicitud = () => {
    const { tipo_solicitud, motivo, usuariopanel, online_o_presencial } = this.state;
    this.setState({ cargando: true });

    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";

    const data = {
      tipo: tipo_solicitud,
      motivo: motivo,
      usu_panel: usuariopanel,
      sol_onl_pres: online_o_presencial,
    };

    axios
      .post(apiUrl, data, { headers: authHeader() })
      .then((res) => {
        this.setState({ cargando: false });

        const datos = res.data.datos;

        const codigo = datos.sol_codigo;
        let horaEnvio = datos.sol_fec_crear_soli;

        var nuevaFecha = horaEnvio.split("T")[0];
        let updatedDate = new Date(horaEnvio);
        let diaSemana = updatedDate.getDay();

        if (diaSemana == 5) {
          updatedDate.setDate(updatedDate.getDate() + 3);
          const nuevaFecha2 = updatedDate.toISOString().split("T")[0];
          this.setState({
            fecha_estimada: nuevaFecha2,
          });
        } else if (diaSemana == 4) {
          updatedDate.setDate(updatedDate.getDate() + 4);
          const nuevaFecha2 = updatedDate.toISOString().split("T")[0];
          this.setState({
            fecha_estimada: nuevaFecha2,
          });
        } else {
          updatedDate.setDate(updatedDate.getDate() + 2);
          const nuevaFecha2 = updatedDate.toISOString().split("T")[0];
          this.setState({
            fecha_estimada: nuevaFecha2,
          });
        }

        this.setState({
          soli_enviada: true,
          modalDeAlerta: false,
          codigo: codigo,
          horaEnvio: nuevaFecha,
        });

        this.vaciarCampos();
      })
      .catch((error) => {
        this.setState({ cargando: false });
        console.log(error);
      });
  };

  enviarSolcitudes = () => {
    const { tipo_solicitud, online_o_presencial } = this.state;

    if (tipo_solicitud == 5 && online_o_presencial == 2) {
      this.modalReunionCapacitacion();
    } else {
      this.guardarSolicitud();
    }
  };

  vaciarCampos = () => {
    this.setState({
      tipo_solicitud: "",
      motivo: "",
    });
  };

  cerrar = (tipo) => {
    if (tipo == 1) {
      this.setState({
        soli_enviada: false,
      });
      this.props.cambiarVista("", 2);
    } else if (tipo == 2) {
      this.setState({
        modalDeAlerta: false,
      });
    }
  };

  siguiente = (estado, contenido) => {
    if (estado) {
      this.setState({
        sigue: false,
        soli_datos: true,
      });
    } else {
      this.setState({
        modalDeAlerta: true,
        contenido: contenido,
      });
    }
  };

  irTicket = () => {
    this.props.cambiarVista(this.state.codigo, 1);
  };

  cerrarIngresoSolicitud = () => {
    this.props.cambiarVista("", 2);
  };

  render() {
    const { soli_enviada, codigo, fecha_estimada, horaEnvio, sigue, soli_datos, tipo_solicitud, Arrtipo_solicitud, online_o_presencial, motivo, abrirInfo, modalDeAlerta, contenido, cargando } = this.state;
    return (
      <>
        <Dialog open={soli_enviada} onClose={() => this.cerrar(1)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogTitle></DialogTitle>
          <DialogContent>
            <h1 style={{ color: "#666666" }}>¡Solicitud enviada!</h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3} style={{ justifyContent: "center" }}>
                <p>
                  Tu número de ticket de trabajo es N° <b style={{ fontSize: "1.3rem" }}>{codigo}</b>, con fecha de ingreso el día <b>{horaEnvio}</b>,
                  <br />
                  tiene una fecha estimada de respuesta para el día <b>{fecha_estimada}</b>.
                  <br />
                  Para revisar el estado de tu ticket de trabajo ingresa tu número de ticket en esta página.
                </p>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "-30px",
              }}
            >
              <a
                className={SolicitudTrabajo.revisarEstado}
                onClick={() => {
                  this.irTicket();
                }}
              >
                Revisar Estado
              </a>
              <a
                className={SolicitudTrabajo.btnNaranja}
                onClick={() => {
                  this.cerrar(1);
                }}
              >
                Aceptar
              </a>
            </div>

            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>

        <Dialog open={this.props.modalIngreso} onClose={this.cerrarIngresoSolicitud} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" style={{ width: "auto !important" }} className="modalSolicitudTrabajo">
          <DialogContent style={{ width: sigue ? "48vw" : "600px" }}>
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "600",
                paddingLeft: "20px",
              }}
            >
              Ingresar solicitud
            </h1>
            <br></br>
            <br></br>
            <div>
              {sigue && <Usuario siguiente={this.siguiente} cerrar={this.cerrar}></Usuario>}
              {soli_datos && (
                <>
                  <Grid item xs={12} sm={12} className={SolicitudTrabajo.contenedorFormIngreso}>
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <InputLabel style={{ marginBottom: 5 }}>Tipo de ticket</InputLabel>
                      <Select id="tipo_solicitud" name="tipo_solicitud" className={SolicitudTrabajo.selectoresIngreso} value={tipo_solicitud} onChange={this.cambiarValores} style={{ paddingLeft: 10 }} input={<InputBase disableUnderline />}>
                        <MenuItem value={""} disabled></MenuItem>
                        {Arrtipo_solicitud.map((tip) => (
                          <MenuItem style={{ paddingLeft: 10 }} value={tip.tsl_id}>
                            {tip.tsl_nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    {tipo_solicitud == 5 && (
                      <Grid item xs={5} sm={5}>
                        <InputLabel style={{ marginBottom: 5 }}>Online o presencial</InputLabel>
                        <Select id="online_o_presencial" name="online_o_presencial" className={SolicitudTrabajo.selectoresIngreso} style={{ paddingLeft: 10 }} value={online_o_presencial} onChange={this.cambiarValores}>
                          <MenuItem value={""} disabled></MenuItem>
                          <MenuItem value={1}>Online</MenuItem>
                          <MenuItem value={2}>Presencial</MenuItem>
                        </Select>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} className={SolicitudTrabajo.contenedorFormIngreso}>
                    <Grid item xs={12} sm={12}>
                      <InputLabel style={{ marginBottom: 5 }}>Motivo</InputLabel>
                      <textarea style={{ background: "transparent", fontSize: "1rem" }} id="motivo" name="motivo" className={SolicitudTrabajo.textAreaIngresado} value={motivo} onChange={this.cambiarValores}></textarea>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <a className={SolicitudTrabajo.botonModal} onClick={this.validaciones}>
                      Enviar
                    </a>
                    <a
                      className={abrirInfo ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                      onClick={() => this.cerrar(1)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "3rem",
                        width: "180px",
                        marginTop: "5%",
                      }}
                    >
                      Cancelar
                    </a>
                  </div>
                </>
              )}
            </div>

            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>

        {modalDeAlerta && <ModalAlerta modal={modalDeAlerta} cerrarModal={this.cerrar} contenido={contenido} />}
        {cargando && <Cargando color="#fff" type="spinningBubbles" />}
      </>
    );
  }
}
