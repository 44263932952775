import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem } from "@material-ui/core";
import { store } from "store/index";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";
import MaterialTable from "material-table";

import Radio from "@material-ui/core/Radio";

import NativeSelect from "@material-ui/core/NativeSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import icono_eliminar from "assets/img/usuarios/icono_eliminar.svg";

// CSS
import crearTotem from "assets/css/crearTotem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Horarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcionesCreadas: [],
      diasSemana: [
        { dia: "Lunes", nro: 0 },
        { dia: "Martes", nro: 1 },
        { dia: "Miercoles", nro: 2 },
        { dia: "Jueves", nro: 3 },
        { dia: "Viernes", nro: 4 },
        { dia: "Sabado", nro: 5 },
        { dia: "Domingo", nro: 6 },
      ],
      diasSemanaSelect: [],
      totem: store.getState().totem,
    };
  }

  componentDidMount() {
    this.opciones_creadas();
    this.configuracion_opciones();
  }

  opciones_creadas = () => {
    const apiUrl = global.config.modulos.admin_totem + "opciones_creadas/?totem=" + this.state.totem;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;
      this.setState({
        opcionesCreadas: data.opciones,
      });
    });
  };

  configuracion_opciones = () => {
    const apiUrl = global.config.modulos.admin_totem + "horarios_opciones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
        },
      })
      .then((res) => {
        var data = res.data;
        this.setState({
          configOpciones: data.opciones,
        });
      });
  };

  validacionCrearConfigHorario = () => {
    var inicioAM = document.getElementById("inicioAM").value;
    var terminoAM = document.getElementById("terminoAM").value;
    var inicioPM = document.getElementById("inicioPM").value;
    var terminoPM = document.getElementById("terminoPM").value;

    var diasSemanaSelect = this.state.diasSemanaSelect;

    if (!inicioAM) {
      this.mostrarAlerta("Ingresa la hora de inicio AM.");
      return false;
    } else if (!terminoAM) {
      this.mostrarAlerta("Ingresa la hora de termino AM.");
      return false;
    } else if (!inicioPM) {
      this.mostrarAlerta("Ingresa la hora de inicio PM.");
      return false;
    } else if (!terminoPM) {
      this.mostrarAlerta("Ingresa la hora de termino PM.");
      return false;
    } else if (diasSemanaSelect.length == 0) {
      this.mostrarAlerta("Ingresa el o los días de funcionamiento de este horario.");
      return false;
    }

    return true;
  };

  abrirCrearHorario = () => {
    this.setState({
      abrirCrearHorario: true,
      diasSemanaSelect: [],
    });
  };

  cerrarCrearHorario = () => {
    this.setState({
      abrirCrearHorario: false,
    });
  };

  crear_configuracion_opciones = () => {
    if (this.validacionCrearConfigHorario()) {
      var selectOpc = document.getElementById("selectOpcC");
      var selectOpc = selectOpc.options[selectOpc.selectedIndex].value;

      var inicioAM = document.getElementById("inicioAM").value;
      var terminoAM = document.getElementById("terminoAM").value;
      var inicioPM = document.getElementById("inicioPM").value;
      var terminoPM = document.getElementById("terminoPM").value;

      var diasSemana = "";
      var diasSemanaSelect = this.state.diasSemanaSelect;
      var contadorDiasSemana = diasSemanaSelect.length;
      for (var i = 0; i < contadorDiasSemana; i++) {
        diasSemana += diasSemanaSelect[i] + ",";
      }

      var data = {
        cno_inicio_am: inicioAM,
        cno_termino_am: terminoAM,
        cno_total_am: 0,
        cno_inicio_pm: inicioPM,
        cno_termino_pm: terminoPM,
        cno_total_pm: 0,
        cno_opcion: selectOpc,
        cno_estado: 1,
        cno_semana: diasSemana,
      };

      axios.post(global.config.modulos.admin_totem + "crear_configuracion_horario/", data, { headers: authHeader() }).then((res) => {
        this.cerrarCrearHorario();
        this.configuracion_opciones();
        this.mostrarAlerta("Guardado con éxito");
      });
    }
  };

  abrirModificarHorario = (config) => {
    this.setState({
      abrirModificarHorario: true,
      idConfigHorario: config,
      diasSemanaSelect: [],
    });

    this.configuracion_opcion(config);
  };

  cerrarModificarHorario = () => {
    this.setState({
      abrirModificarHorario: false,
    });
  };

  configuracion_opcion = (config) => {
    const apiUrl = global.config.modulos.admin_totem + "configuracion_horario/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          config: config,
        },
      })
      .then((res) => {
        var data = res.data;

        document.getElementById("selectOpcC").value = data["cno_opcion"];
        document.getElementById("inicioAM").value = data["cno_inicio_am"];
        document.getElementById("terminoAM").value = data["cno_termino_am"];
        document.getElementById("inicioPM").value = data["cno_inicio_pm"];
        document.getElementById("terminoPM").value = data["cno_termino_pm"];

        this.setState({
          diasSemanaSelect: data["cno_semana"].slice(0, -1).split(","),
        });
      });
  };

  modificar_configuracion_opcion = () => {
    var config = this.state.idConfigHorario;

    var selectOpc = document.getElementById("selectOpcC");
    var selectOpc = selectOpc.options[selectOpc.selectedIndex].value;

    var inicioAM = document.getElementById("inicioAM").value;
    var terminoAM = document.getElementById("terminoAM").value;
    var inicioPM = document.getElementById("inicioPM").value;
    var terminoPM = document.getElementById("terminoPM").value;

    var diasSemana = "";
    var diasSemanaSelect = this.state.diasSemanaSelect;
    var contadorDiasSemana = diasSemanaSelect.length;
    for (var i = 0; i < contadorDiasSemana; i++) {
      diasSemana += diasSemanaSelect[i] + ",";
    }

    var data = {
      config: config,
      cno_inicio_am: inicioAM,
      cno_termino_am: terminoAM,
      cno_inicio_pm: inicioPM,
      cno_termino_pm: terminoPM,
      cno_opcion: selectOpc,
      cno_semana: diasSemana,
    };

    axios.patch(global.config.modulos.admin_totem + "configuracion_horario/", data, { headers: authHeader() }).then((res) => {
      this.cerrarModificarHorario();
      this.configuracion_opciones();
      this.mostrarAlerta("Modificado con éxito");
    });
  };

  eliminar_configuracion_opcion = (config) => {
    axios.delete(global.config.modulos.admin_totem + "configuracion_horario/?config=" + config, { headers: authHeader() }).then((res) => {
      this.configuracion_opciones();
      this.mostrarAlerta("Eliminado con éxito");
    });
  };

  render() {
    const elegirDiasSemana = (event) => {
      const { name, value } = event.target;

      var opciones = this.state.diasSemanaSelect;

      var check = event.target.checked;
      if (check == true) {
        opciones.push(value);
      } else {
        const index = opciones.indexOf(value);
        if (index > -1) {
          opciones.splice(index, 1);
        }
      }

      this.setState({
        diasSemanaSelect: opciones,
        [name]: check,
      });
    };

    return (
      <GridContainer
        style={{
          width: "100%",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ color: "#666666", marginLeft: "1%", fontSize: "1.8rem" }}>Horarios por opciones</h2>
        <a
          className={crearTotem.btnAgregarAcceso}
          style={{ width: "12%" }}
          onClick={() => {
            this.abrirCrearHorario();
          }}
        >
          {"Agregar horario"}
        </a>
        <GridItem xs={12} sm={12} md={12}>
          <div className={crearTotem.divBordeTablaAccesos} style={{ fontSize: "1rem !important" }}>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "Nombre del módulo",
                  field: "opc_nombre",
                },
                {
                  title: "Inicio AM",
                  field: "cno_inicio_am",
                },
                {
                  title: "Termino AM",
                  field: "cno_termino_am",
                },
                {
                  title: "Inicio PM",
                  field: "cno_inicio_pm",
                },
                {
                  title: "Termino PM",
                  field: "cno_termino_pm",
                },
                {
                  title: "Semana",
                  field: "cno_semana",
                },
                {
                  title: "Acciones",
                  render: (rowData) => (
                    <React.Fragment>
                      <div>
                        <img className="botonesTablas" style={{ marginRight: "20px", width: "30px" }} src={icono_modificar} onClick={() => this.abrirModificarHorario(rowData.cno_id)} />
                        <img className="botonesTablas" style={{ width: "30px" }} src={icono_eliminar} onClick={() => this.eliminar_configuracion_opcion(rowData.cno_id)} />
                      </div>
                    </React.Fragment>
                  ),
                },
              ]}
              data={this.state.configOpciones}
              options={{
                actionsColumnIndex: -1,
                search: true,
              }}
              localization={{
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "filas",
                  firstTooltip: "primera",
                  previousTooltip: "anterior",
                  nextTooltip: "siguiente",
                  lastTooltip: "última",
                },
                toolbar: {
                  nRowsSelected: "{0} fila(s) seleccionadas",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No tiene historial de atenciones",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </div>
        </GridItem>

        {this.state.abrirCrearHorario ? (
          <div>
            <Dialog open={this.state.abrirCrearHorario} onClose={this.cerrarCrearHorario} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent>
                <h1>Agregar horario</h1>
                <br></br>
                <br></br>

                <Grid item xs={12} sm={12}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      width: "1000px",
                    }}
                  >
                    <div style={{ width: "33%" }}>
                      <Grid item xs={10} sm={10}>
                        <InputLabel className={crearTotem.textosTitulos}>Módulos</InputLabel>
                        <NativeSelect disableUnderline={true} className={crearTotem.selectores} id={"selectOpcC"}>
                          {this.state.opcionesCreadas.map((opc, index) => (
                            <option value={opc.opc_id}>{opc.opc_nombre}</option>
                          ))}
                        </NativeSelect>
                      </Grid>
                    </div>
                    <div style={{ width: "33%", display: "flex" }}>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Inicio AM</InputLabel>
                        <input type="time" id="inicioAM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Termino AM</InputLabel>
                        <input type="time" id="terminoAM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                    </div>
                    <div style={{ width: "33%", display: "flex" }}>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Inicio PM</InputLabel>
                        <input type="time" id="inicioPM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Termino PM</InputLabel>
                        <input type="time" id="terminoPM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <Grid item xs={12} sm={12} md={12} style={{ marginTop: "3%" }}>
                  <InputLabel className={crearTotem.textosTitulos}>Días de la semana</InputLabel>
                  <Grid container spacing={2} style={{ flexWrap: "nowrap", width: "85%" }}>
                    {this.state.diasSemana.map((das, index) => (
                      <Grid item xs={12} sm={3} md={2}>
                        <FormControlLabel id={das.dia} label={das.dia} value={das.nro} control={<Checkbox className={crearTotem.checkbox} />} checked={this.state.diasSemanaSelect.includes(das.nro.toString())} onChange={elegirDiasSemana} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <a
                  className={crearTotem.btnAgregarAcceso}
                  style={{ width: "6vw", height: "40px" }}
                  onClick={() => {
                    this.crear_configuracion_opciones();
                  }}
                >
                  {"Agregar"}
                </a>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}

        {this.state.abrirModificarHorario ? (
          <div>
            <Dialog open={this.state.abrirModificarHorario} onClose={this.cerrarModificarHorario} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent>
                <h1>Modificar horario</h1>
                <br></br>
                <br></br>

                <Grid item xs={12} sm={12}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      width: "1000px",
                    }}
                  >
                    <div style={{ width: "33%" }}>
                      <Grid item xs={10} sm={10}>
                        <InputLabel className={crearTotem.textosTitulos}>Módulos</InputLabel>
                        <NativeSelect disableUnderline={true} className={crearTotem.selectores} id={"selectOpcC"}>
                          {this.state.opcionesCreadas.map((opc, index) => (
                            <option value={opc.opc_id}>{opc.opc_nombre}</option>
                          ))}
                        </NativeSelect>
                      </Grid>
                    </div>
                    <div style={{ width: "33%", display: "flex" }}>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Inicio AM</InputLabel>
                        <input type="time" id="inicioAM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Termino AM</InputLabel>
                        <input type="time" id="terminoAM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                    </div>
                    <div style={{ width: "33%", display: "flex" }}>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Inicio PM</InputLabel>
                        <input type="time" id="inicioPM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                      <Grid item xs={5} sm={5} style={{ marginRight: "15px" }}>
                        <InputLabel className={crearTotem.textosTitulos}>Termino PM</InputLabel>
                        <input type="time" id="terminoPM" style={{ fontSize: "1rem" }} className={crearTotem.input}></input>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <Grid item xs={12} sm={12} md={12} style={{ marginTop: "3%" }}>
                  <InputLabel className={crearTotem.textosTitulos}>Días de la semana</InputLabel>
                  <Grid container spacing={2} style={{ flexWrap: "nowrap", width: "85%" }}>
                    {this.state.diasSemana.map((das, index) => (
                      <Grid item xs={12} sm={3} md={2}>
                        <FormControlLabel id={das.dia} label={das.dia} value={das.nro} control={<Checkbox className={crearTotem.checkbox} />} checked={this.state.diasSemanaSelect.includes(das.nro.toString())} onChange={elegirDiasSemana} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <a
                  className={crearTotem.btnAgregarAcceso}
                  style={{ width: "6vw", height: "40px" }}
                  onClick={() => {
                    this.modificar_configuracion_opcion();
                  }}
                >
                  {"Modificar"}
                </a>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}
      </GridContainer>
    );
  }
}
