import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default class CanalGraf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      fecha_actual: props.fecha_actual,
      hora_inicio: props.hora_inicio,
      hora_termino: props.hora_termino,
      ces_id: props.ces_id,
      edad_min: props.edad_min,
      edad_max: props.edad_max,
      root: "",
      chart: "",
      dato: "",
      display: "center",
      cesfam: "",
    };
  }

  PrincipalGraf = () => {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "canalXcesfam/", {
        headers: authHeader(),
        params: {
          fecha_act: this.state.fecha_actual,
          fecha_ini: this.state.fecha_inicio,
          fecha_ter: this.state.fecha_termino,
          id_ces: this.state.ces_id,
          edad_max: this.state.edad_max,
          edad_min: this.state.edad_min,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ datos: res.data.datos, cesfam: res.data.datos[0]["cesfam"] }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv11");

          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              layout: root.verticalLayout,
            })
          );

          let series = chart.series.push(
            am5percent.PieSeries.new(root, {
              valueField: "value",
              categoryField: "canal",
              alignLabels: false,
            })
          );

          series.labels.template.set("text", "{canal}:[bold]{value}");

          series.data.setAll(this.state.datos);

          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.percent(50),
              x: am5.percent(50),
              marginTop: 15,
              marginBottom: 15,
            })
          );

          legend.data.setAll(series.dataItems);

          series.appear(1000, 100);
        });
      });
  };

  componentDidMount() {
    this.PrincipalGraf();
  }

  render() {
    return (
      <div
        id="chartdiv11"
        style={{
          width: "35%",
          height: "60%",
          zIndex: 1,
          position: "fixed",
          marginTop: "20px",
          textAlign: "center",
          left: "55%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Horas agendadas por canal de {this.state.cesfam}</h1>
      </div>
    );
  }
}
