import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import GridContainer from "components/grid/GridContainer";
import GridItem from "components/grid/GridItem";

import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";

import "assets/css/general.css";
import "assets/css/usuarios.css";

export default class ModalHorasReservadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      usuario: props.usuario,
      tipo: props.tipo,

      reservas: [],
      open: false,
      ocultar: props.ocultar,
      abrir: props.abrir,
      diasAsistidas: props.diasAsistidas,
      diasPerdidas: props.diasPerdidas,
    };
  }

  componentDidMount() {
    this.traerHistorial(this.state.usuario, this.state.tipo, this.state.diasAsistidas, this.state.diasPerdidas);
  }

  traerHistorial = (usuario, tipo, diasAsistidas, diasPerdidas) => {
    let apiUrl = global.config.modulos.agendamientos + "horas_historicas_por_tipo/";

    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          usuario: usuario,
          tipo: tipo,
          canal: 5,
          diasAsistidas: diasAsistidas,
          diasPerdidas: diasPerdidas,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          res.data.datos.map((res) => {
            res["age_fecha_registro"] = res["age_fecha_registro"].replace("T", " ");
            res["hor_atencion"] = res["hor_atencion"].replace("T", " ");
          });
          this.setState({
            reservas: res.data.datos,
            open: this.state.abrir,
          });
        } else {
          this.salir();
        }
      });
  };

  salir = () => {
    this.setState(
      {
        open: false,
      },
      () => {
        this.state.ocultar();
      }
    );
  };

  render() {
    return (
      <Dialog open={this.state.open} className="modificarUsuario">
        <GridContainer style={{ padding: 30 }} className="agendar-container">
          <GridItem xs={12} style={{ marginTop: 10 }}>
            {this.state.tipo == 7 && <h3 className="titulo">Considerar que el paciente YA TUVO las siguientes ATENCIONES en los últimos {this.state.diasAsistidas} días:</h3>}
            {this.state.tipo == 9 && (
              <h3 className="titulo">
                Por favor recuerda al paciente que debe ANULAR CON ANTICIPACIÓN las horas cuando no vaya a asistir, este paciente tiene {this.state.reservas.length} HORAS PERDIDAS en los últimos {this.state.diasPerdidas} días:
              </h3>
            )}
          </GridItem>
          <GridItem xs={12} id="tablaBloqueos" style={{ marginTop: 30 }}>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "Fecha de solicitud",
                  field: "age_fecha_registro",
                },
                { title: "Tipo de atención", field: "especialidad" },
                { title: "Profesional", field: "nombreProfesional" },
                {
                  title: "Canal",
                  render: (rowData) =>
                    rowData.age_canal == 5 ? (
                      <React.Fragment>
                        <p>{rowData.canal}</p>
                        <p>{rowData.nombre_usuario_panel}</p>

                        {rowData.age_grupo_familiar ? (
                          <React.Fragment>
                            <Tooltip title={rowData.familiar_que_reservo + " - " + rowData.rut_familiar_que_reservo} aria-label={rowData.familiar_que_reservo + " - " + rowData.rut_familiar_que_reservo}>
                              <p
                                style={{
                                  marginTop: 5,
                                  cursor: "pointer",
                                }}
                              >
                                {"Reservada por grupo familiar"}
                              </p>
                            </Tooltip>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ) : (
                      rowData.canal
                    ),
                },
                {
                  title: "Estado",
                  render: (rowData) => (rowData.estadoHorario == 0 ? <label>Bloqueada</label> : rowData.estadoAgenda),
                },

                { title: "Fecha de atención", field: "hor_atencion" },
              ]}
              data={this.state.reservas}
              options={{
                actionsColumnIndex: -1,
                search: true,
              }}
              localization={{
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "filas",
                  firstTooltip: "primera",
                  previousTooltip: "anterior",
                  nextTooltip: "siguiente",
                  lastTooltip: "última",
                },
                toolbar: {
                  nRowsSelected: "{0} fila(s) seleccionadas",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No tiene horas pasadas",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
            <Button className="fondoNaranjo" style={{ color: "white" }} onClick={() => this.salir()}>
              Cerrar
            </Button>
          </GridItem>
        </GridContainer>
      </Dialog>
    );
  }
}
