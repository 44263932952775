import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NotificationManager } from "react-notifications";
import csvCiclo from "./../../assets/img/imgCargaMasiva/desincribirCiclo.png";
import { store } from "./../../store/index";
import carga_ciclos from "../../assets/img/imgCargaMasiva/ciclos.svg";
import carga_beneficios from "../../assets/img/imgCargaMasiva/beneficios.svg";
import carga_prenaturo from "../../assets/img/imgCargaMasiva/prematuros.svg";
import carga_reservaExterba from "../../assets/img/imgCargaMasiva/reservas externas.svg";
import carga_fallecidos from "../../assets/img/imgCargaMasiva/fallecidos.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";

import signo from "./../../assets/img/signo.svg";

// CSS
import "./../../assets/css/usuarios.css";
import moduleCM from "../../assets/css/cargamasiva.module.css";

export default class EliminarCiclos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      ciclos: [],
      niveles: [],
      ciclo: null,
      nivel: null,
      datos: [],
      tipo_carga: "eliminarCiclos",
      motivo: 1,
      herramientas: store.getState().herramientas,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.traeCiclos();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva  <span title='Permite desinscribir de un ciclo a los pacientes a través de de un archivo .csv'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  traeCiclos = () => {
    axios
      .get(global.config.modulos.cargas_masivas + "ciclos/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam, estado: 2 },
      })
      .then((res) => {
        this.setState({ ciclos: res.data });
      });
  };

  cambioValorCiclo = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  cambioValor = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleOnDrop = (data) => {
    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    if (registros.length == 0) {
      NotificationManager.error("Revise el formato del csv o si contiene datos", "", 5 * 60 * 1000);
      return false;
    }
    this.setState({ datos: registros });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    document.getElementById("cargando").style.display = "block";

    axios
      .post(
        global.config.modulos.cargas_masivas + "ciclos/carga_masiva_eliminar/",
        {
          ciclos: this.state.datos,
          uspId: this.state.uspId,
          ciclo: this.state.ciclo,
          motivo: this.state.motivo,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success("los registros se están ingresando, se le notificará cuando termine", "", 5 * 60 * 1000);
        } else {
          NotificationManager.error(res.data.mensaje, "", 5 * 60 * 1000);
        }
      })
      .catch((error) => {
        document.getElementById("cargando").style.display = "none";
        NotificationManager.error("Error al ingresar los registros", "", 5 * 60 * 1000);
      });
  }

  cambiarVista = (e) => {
    const { name, value } = e.target;
    this.setState({ tipo_carga: value }, () => {
      this.props.history.push(this.state.tipo_carga);
    });
  };

  cargarCiclos = () => {
    this.props.history.push("/cargarCiclos");
  };

  cargarFallecidos = () => {
    this.props.history.push("/cargarFallecidos");
  };

  cargarCondiciones = () => {
    this.props.history.push("/cargarCondiciones");
  };

  cargarBeneficios = () => {
    this.props.history.push("/cargarBeneficios");
  };

  cargarReservasExternas = () => {
    this.props.history.push("/cargarReservasExternas");
  };

  render() {
    const { niveles, ciclos, ciclo, nivel, datos } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="cargarUsuarios">
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <div className={moduleCM.divIcon}>
                        <img className={moduleCM.imgCMciclos} src={carga_ciclos} onClick={this.cargarCiclos} />

                        <img className={moduleCM.imgCMbeneficio} src={carga_beneficios} onClick={this.cargarBeneficios} />

                        <img className={moduleCM.imgCMfallecido} src={carga_fallecidos} onClick={this.cargarFallecidos} />

                        {this.state.herramientas.includes("H44") ? <img className={moduleCM.imgCMprematuro} src={carga_prenaturo} onClick={this.cargarCondiciones} /> : null}

                        {this.state.herramientas.includes("H47") ? <img className={moduleCM.imgCMreservaExterna} src={carga_reservaExterba} onClick={this.cargarReservasExternas} /> : null}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <div className={moduleCM.divSelectIncribir}>
                        <label>Inscribir/Desinscribir</label>
                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.tipo_carga}
                          onChange={this.cambiarVista}
                          inputProps={{
                            name: "tipo_carga",
                            id: "tipo_carga",
                          }}
                        >
                          <option value="cargarCiclos">Inscribir en ciclo</option>
                          <option selected value="eliminarCiclos">
                            Desinscribir de ciclo
                          </option>
                        </NativeSelect>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <div style={{ float: "left", marginLeft: "20px" }}>
                        <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                          Ciclo
                        </InputLabel>
                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.ciclo}
                          onChange={this.cambioValorCiclo}
                          inputProps={{
                            name: "ciclo",
                            id: "ciclo",
                          }}
                        >
                          <option value="">Seleccione ciclo</option>
                          {ciclos &&
                            ciclos.map((cic, index) => (
                              <option value={cic["cic_id"]} key={index}>
                                {cic["cic_nombre"]}
                              </option>
                            ))}
                        </NativeSelect>
                      </div>

                      <div style={{ float: "left", marginLeft: 20 }}>
                        <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                          Motivo
                        </InputLabel>
                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.motivo}
                          onChange={this.cambioValor}
                          inputProps={{
                            name: "motivo",
                            id: "motivo",
                          }}
                        >
                          <option value={1}>Alta por término de tratamiento</option>
                          <option value={2}>Alta por inasistencia</option>
                          <option value={3}>Error de digitación</option>
                        </NativeSelect>
                      </div>
                    </Grid>

                    {ciclo && (
                      <Grid item xs={12} sm={12}>
                        <span style={{ marginLeft: "19px" }}>Cargar CSV</span>
                        <div className={moduleCM.csvCargaMasiva}>
                          <CSVReader
                            onDrop={this.handleOnDrop}
                            onError={this.handleOnError}
                            addRemoveButton
                            removeButtonColor="#659cef"
                            onRemoveFile={this.handleOnRemoveFile}
                            config={{
                              header: true,
                              dynamicTyping: true,
                              skipEmptyLines: true,
                              transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                            }}
                          >
                            <div
                              className={moduleCM.divSpanArchivo}
                              style={{
                                width: "21vw",
                              }}
                            >
                              <span className={moduleCM.spanArchivo}>Seleccione archivo</span>
                              <span style={{ fontSize: "0.9vw" }}> O arrastre archivo .CSV </span>
                            </div>
                          </CSVReader>

                          <LinearProgress id="cargando" style={{ display: "none" }} />
                        </div>
                      </Grid>
                    )}
                  </Grid>

                  {ciclo && datos.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={this.cargaMasiva}
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Cargar registros
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12}>
                    <img className={moduleCM.imgEjemploCiclo} src={csvCiclo} alt="csv" />
                  </Grid>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
