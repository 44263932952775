import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import chileanRut from "chilean-rut";
import { LiveKitRoom, DisplayOptions, DisplayContext } from "@livekit/react-components";

import { PropTypes } from "prop-types";
import { confirmAlert } from "react-confirm-alert"; // Import
import { connect } from "react-redux";
import { store } from "../../store/index";

import cortar from "../../assets/img/video_llamada/cortar.svg";
import recargar from "../../assets/img/video_llamada/recargar.svg";
import ausente from "../../assets/img/video_llamada/ausente.svg";

import adjuntar_archivo from "../../assets/img/video_llamada/adjuntar_archivo.svg";
import escribir_mensaje from "../../assets/img/video_llamada/escribir_mensaje.svg";
import escribir_observacion from "../../assets/img/video_llamada/escribir_observacion.svg";
import logo_v2 from "../../assets/img/video_llamada/logo_v2.svg";
import siguiente_paciente from "../../assets/img/video_llamada/siguiente_paciente.svg";
import observaciones from "../../assets/img/video_llamada/observaciones.svg";
import reservar_llamada from "../../assets/img/video_llamada/reservar_llamada.svg";
import ver_archivos from "../../assets/img/video_llamada/ver_archivos.svg";

import fondo_escribir from "../../assets/img/video_llamada/fondo_escribir.png";
import fondo_datos from "../../assets/img/video_llamada/fondo_datos.svg";
import datos_pacientes from "../../assets/img/video_llamada/datos_pacientes.svg";
import fondo_footer from "../../assets/img/video_llamada/footerVideo.svg";

import checkActivo from "../../assets/img/video_llamada/check.svg";
import cerrar_popup from "../../assets/img/video_llamada/cerrar popup.svg";
import descargar_archivo_blanco from "../../assets/img/video_llamada/descargar_archivo_blanco.svg";

import AgendarUsuario from "./ModalAgendarHoraVD";

import { socketVideollamada, socketRTC } from "../../service/socket";

import { showLoader, hideLoader } from "../../store/actions/actions";

import moment from "moment";
import "moment/locale/es";
import { Room, RoomEvent, setLogLevel, VideoPresets } from "livekit-client";

import "react-confirm-alert/src/react-confirm-alert.css";
import "@livekit/react-components/dist/index.css";
import "react-aspect-ratio/aspect-ratio.css";

var countdown;
var countdownAtr;
var roomStart = new Date().getTime();
var activeCall;

class VideoLlamadaGrupal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("token"),
      nombreSala: window.localStorage.getItem("nombreSala"),
      id_sala: window.localStorage.getItem("idSala"),
      tipo_gestion: window.localStorage.getItem("tipoGestion"),

      ageId: window.localStorage.getItem("ageId"),
      usu_id: window.localStorage.getItem("usuId"),
      proId: store.getState().id_medico,
      proPerfil: "1",
      cesfam: window.localStorage.getItem("cesfam"),
      canal: 5,

      datosUsuario: "",
      infoAcom: "",
      contenedor: "",
      tablaObservaciones: "",
      detalleObservacion: "",
      timerC: "",
      countdownC: "",
      btnActivo: false,

      audio: true,
      video: true,

      uspId: store.getState().usp,
    };
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  componentDidMount() {
    this.props.dispatch(hideLoader());
  }

  obtenerRend = async () => {
    var datos = { agenda: this.state.ageId };
    var respuesta = {};
    await axios
      .get(global.config.modulos.videollamada + "rendimiento_por_agenda/", {
        headers: authHeader(),
        params: datos,
      })
      .then(function (res) {
        respuesta = res;
      });

    return respuesta.data;
  };

  componentWillUnmount() {
    clearInterval(countdown);
    clearInterval(countdownAtr);
  }

  mensajesNoLeidos = async () => {
    var datos = { nombreSala: this.state.nombreSala, tipo: 1 };
    var res = await axios.get(global.config.modulos.videollamada + "mensaje_no_leido/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    if (data["estado"]) {
      var idMensaje = data["vlm_id"];

      const datos = { vlm_estado: 2 };
      axios
        .patch(global.config.modulos.videollamada + "mensaje/" + idMensaje, datos, { headers: authHeader() })
        .then(function (res) {})
        .catch(function (err) {
          console.log(err);
        });

      document.getElementById("MensajeEnviadoM").innerHTML = data["vlm_mensaje"];
      this.setState({ contenedorPopM: "info9" });
    }
  };

  alertaFinalizoLlamada = async () => {
    const datos2 = { sala: this.state.id_sala, accion: 2 };
    await axios
      .post(global.config.modulos.videollamada + "registrar_logs/", datos2, { headers: authHeader() })
      .then(function (res) {})
      .catch(function (err) {});

    const datos = {
      titulo: "termino_llamada_usuario",
      canal: this.state.canal,
    };
    var res = await axios.get(global.config.modulos.aplicaciones + "mensajes_por_titulo/", {
      headers: authHeader(),
      params: datos,
    });
    var data = res.data;
    confirmAlert({
      title: data["msg_mensaje"],
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  muteAudio = () => {
    document.querySelectorAll("._hRq5k._3Bgvz")[0].click();
    this.setState({ audio: !this.state.audio });
  };

  muteVideo = () => {
    document.querySelectorAll("._hRq5k._3Bgvz")[1].click();
    this.setState({ video: !this.state.video });
  };

  iniciarTRestante = async (start, rend) => {
    var hora = new Date(start);
    var limit = new Date(hora.getTime() + rend * 60000);
    countdown = setInterval(function () {
      var now = new Date().getTime();
      var remaining = limit - now;
      var r_minute = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
      var r_second = Math.floor((remaining % (1000 * 60)) / 1000);
      var tx_r_second = r_second < 10 ? "0" + r_second + " min" : r_second + " min";
      var tx_r_minute = r_minute < 10 ? "0" + r_minute : r_minute;

      var remain_timer = "<label style='color: red;margin:0px'><b>" + tx_r_minute + ":" + tx_r_second + "</b></label><br><label style='color: red;'>Restante</label>";

      if (activeCall) {
        var elapsed = (roomStart - now) * -1;
        var e_minute = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
        var e_second = Math.floor((elapsed % (1000 * 60)) / 1000);
        var tx_e_second = e_second < 10 ? "0" + e_second + " min" : e_second + " min";
        var tx_e_minute = e_minute < 10 ? "0" + e_minute : e_minute;
        var elapsed_timer = "<label style='color: white;margin:0px'><b>" + tx_e_minute + ":" + tx_e_second + "</label></b><br><label style='color: white;'>Transcurrido</label>";

        document.getElementById("call-timer").innerHTML = elapsed_timer;
      }
      if (r_minute >= 0 && r_second >= 0) {
        document.getElementById("call-countdown").innerHTML = remain_timer;
      } else {
        remain_timer = "<label style='color: red;margin:0px'><b>00:00 min</b></label><br><label style='color: red;'>Restante</label>";
        document.getElementById("call-countdown").innerHTML = remain_timer;
      }
    }, 1000);
  };

  showTimers = () => {
    axios
      .get(global.config.modulos.profesionales + "por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam, { headers: authHeader() })
      .then(function (res) {
        var data = res.data;
        var estado = data.estado;
        if (estado) {
          if (data.pro_tiempo_restante == 1) {
            document.getElementById("call-countdown").style.display = "block";
          }
          if (data.pro_tiempo_transcurrido == 1) {
            document.getElementById("call-timer").style.display = "block";
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  hideTimers = () => {
    document.getElementById("call-countdown").style.display = "none";
    document.getElementById("call-timer").style.display = "none";
  };

  cortar = () => {
    socketRTC.emit("terminar_llamada", {
      sala: this.state.nombreSala,
      tipo: 2,
    });

    const datos2 = { sala: this.state.id_sala };
    axios
      .post(global.config.modulos.videollamada + "cambio_estado_agenda_grupal/", datos2, { headers: authHeader() })
      .then((res) => {
        window.location.replace("/pacientes");
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  videoLlamada = async () => {
    this.props.dispatch(showLoader());

    socketRTC.emit("terminar_llamada", {
      sala: this.state.nombreSala,
      tipo: 2,
    });

    const datos2 = { agenda: this.state.ageId, profesional: this.state.proId, sala: this.state.id_sala, proximo: true };
    var res = await axios.post(global.config.modulos.videollamada + "cambio_estado_agenda_grupal/", datos2, { headers: authHeader() });
    var datos = res.data;
    if (datos.estado) {
      const datos2 = { agenda: datos["age_id"] };
      var res;
      if (datos["llamada_grupal"] == 1) {
        res = await axios.post(global.config.modulos.videollamada + "crear_sala_grupal/", datos2, { headers: authHeader() });
      } else {
        datos2["dispositivo"] = 1;
        datos2["tipo"] = datos["age_tipo_gestion"];
        res = await axios.post(global.config.modulos.videollamada + "crear_sala/", datos2, { headers: authHeader() });
      }
      var data = res.data;
      if (data.estado) {
        var resToken = await axios.get(global.config.modulos.videollamada + "token_sala/?uspId=" + this.state.uspId + "&sala=" + data.vls_nombre, { headers: authHeader() });
        var dataToken = resToken.data;

        window.localStorage.setItem("ageId", datos["age_id"]);
        window.localStorage.setItem("cesfam", datos["age_cesfam"]);
        window.localStorage.setItem("tipoGestion", datos["age_tipo_gestion"]);

        window.localStorage.setItem("nombreSala", data.vls_nombre);
        window.localStorage.setItem("idSala", data.vls_id);
        window.localStorage.setItem("token", dataToken.token);

        if (datos["llamada_grupal"] == 1) {
          window.location.replace("/videoLlamadaGrupal");
        } else {
          window.location.replace("/videoLlamada");
        }
      } else {
        this.props.dispatch(hideLoader());
        confirmAlert({
          title: data.mensaje,
          message: "",
          buttons: [
            {
              label: "Aceptar",
            },
          ],
        });
      }
    } else {
      this.props.dispatch(hideLoader());
      confirmAlert({
        title: datos.mensaje,
        message: "",
        buttons: [
          {
            label: "Aceptar",
          },
        ],
      });
    }
  };

  iniciarTAtraso = async (inicio) => {
    document.getElementById("infoAtrasado").style.display = "block";

    if (inicio != null) {
      var inicioAtraso = new Date(inicio).getTime();
    } else {
      var inicioAtraso = new Date().getTime();
    }
    countdownAtr = setInterval(function () {
      var now = new Date().getTime();
      var elapsed = (inicioAtraso - now) * -1;
      var e_minute = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
      var e_second = Math.floor((elapsed % (1000 * 60)) / 1000);
      var tx_e_second = e_second < 10 ? "0" + e_second + " min" : e_second + " min";
      var tx_e_minute = e_minute < 10 ? "0" + e_minute : e_minute;
      var elapsed_timer = "<label style='color: #ff000a'><b> " + tx_e_minute + ":" + tx_e_second + "</b></label>";
      document.getElementById("countdownAtraso").innerHTML = elapsed_timer;
    }, 1000);
  };

  confirmarProximaLlamada = () => {
    confirmAlert({
      title: "¿Quieres terminar esta atención y contactar al siguiente paciente?",
      message: "",
      buttons: [
        {
          label: "Sí",
          onClick: this.videoLlamada.bind(this),
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    const onConnected = async (room) => {
      room.localParticipant.setCameraEnabled(true);
      room.localParticipant.setMicrophoneEnabled(true);

      this.showTimers();
      var rend = await this.obtenerRend();
      var start = rend.hora_at;
      var rendi = rend.rendimiento;
      activeCall = true;
      this.iniciarTRestante(start, rendi);
    };

    const updateParticipantSize = (room) => {};

    const onParticipantDisconnected = (room) => {
      updateParticipantSize(room);
    };

    const displayOptions = {
      stageLayout: "grid",
      showStats: true,
    };

    return (
      <DisplayContext.Provider value={displayOptions}>
        <div className="roomContainer">
          <div className={`media-bridge ${this.state.bridge}`}>
            <article className="_2sm4earticle">
              <LiveKitRoom
                roomOptions={{
                  adaptiveStream: "adaptiveStream",
                  dynacast: "dynacast",
                  publishDefaults: {
                    simulcast: "simulcast",
                  },
                  videoCaptureDefaults: {
                    resolution: VideoPresets.h720.resolution,
                  },
                }}
                onConnected={(room) => {
                  onConnected(room);
                  room.on(RoomEvent.ParticipantConnected, () => updateParticipantSize(room));
                  room.on(RoomEvent.ParticipantDisconnected, () => onParticipantDisconnected(room));
                  updateParticipantSize(room);
                }}
                url={"https://hsdc.cl/"}
                token={this.state.token}
              ></LiveKitRoom>
            </article>

            <div className="accionesVideo">
              <svg className="mute-audio" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-10 -10 68 68" onClick={this.muteAudio}>
                <title>title</title>
                <circle className="circleAcciones" cx="24" cy="24" r="34">
                  <title>Mute audio</title>
                </circle>
                <path style={this.state.audio ? { display: "none" } : { display: "block" }} className="on" transform="scale(0.6), translate(17,18)" d="M38 22h-3.4c0 1.49-.31 2.87-.87 4.1l2.46 2.46C37.33 26.61 38 24.38 38 22zm-8.03.33c0-.11.03-.22.03-.33V10c0-3.32-2.69-6-6-6s-6 2.68-6 6v.37l11.97 11.96zM8.55 6L6 8.55l12.02 12.02v1.44c0 3.31 2.67 6 5.98 6 .45 0 .88-.06 1.3-.15l3.32 3.32c-1.43.66-3 1.03-4.62 1.03-5.52 0-10.6-4.2-10.6-10.2H10c0 6.83 5.44 12.47 12 13.44V42h4v-6.56c1.81-.27 3.53-.9 5.08-1.81L39.45 42 42 39.46 8.55 6z" fill="white"></path>
                <path style={this.state.audio ? { display: "block" } : { display: "none" }} className="off" transform="scale(0.6), translate(17,18)" d="M24 28c3.31 0 5.98-2.69 5.98-6L30 10c0-3.32-2.68-6-6-6-3.31 0-6 2.68-6 6v12c0 3.31 2.69 6 6 6zm10.6-6c0 6-5.07 10.2-10.6 10.2-5.52 0-10.6-4.2-10.6-10.2H10c0 6.83 5.44 12.47 12 13.44V42h4v-6.56c6.56-.97 12-6.61 12-13.44h-3.4z" fill="white"></path>
              </svg>

              <svg className="mute-video" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-10 -10 68 68" onClick={this.muteVideo}>
                <circle className="circleAcciones" cx="24" cy="24" r="34">
                  <title>Mute video</title>
                </circle>
                <path style={this.state.video ? { display: "none" } : { display: "block" }} className="on" transform="scale(0.6), translate(17,16)" d="M40 8H15.64l8 8H28v4.36l1.13 1.13L36 16v12.36l7.97 7.97L44 36V12c0-2.21-1.79-4-4-4zM4.55 2L2 4.55l4.01 4.01C4.81 9.24 4 10.52 4 12v24c0 2.21 1.79 4 4 4h29.45l4 4L44 41.46 4.55 2zM12 16h1.45L28 30.55V32H12V16z" fill="white"></path>
                <path style={this.state.video ? { display: "block" } : { display: "none" }} className="off" transform="scale(0.6), translate(17,16)" d="M40 8H8c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zm-4 24l-8-6.4V32H12V16h16v6.4l8-6.4v16z" fill="white"></path>
              </svg>
            </div>

            <footer
              className="footer-video"
              style={{
                backgroundImage: `url("${fondo_footer}")`,
              }}
            >
              <div style={{ textAlign: "center", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div className="contendorFooterAcciones">
                  <img className="accionesFooter" src={cortar} title="Terminar llamada " onClick={this.cortar} />
                  <div id="call-timer" className="timerGrupal"></div>
                  <div id="call-countdown" className="timerGrupal"></div>
                </div>
                <img src={logo_v2} style={{ width: "13vw", position: "absolute", left: " 50%" }} />
                <img className="accionesFooter" src={siguiente_paciente} onClick={this.confirmarProximaLlamada} />
              </div>
            </footer>
          </div>
        </div>
      </DisplayContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(VideoLlamadaGrupal);
