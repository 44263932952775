import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridItem from "../../components/grid/GridItem";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "moment/locale/es";
import axios from "axios";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import GraficoSolExternasInfluenza from "./GraficosVacunasInfluenza/GraficoSolExternasInfluenza";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "./../../assets/css/graficos.css";

export default class GraficoSolExternas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      cesfams: [],
      cesfamShow: true,
      cesfamNom: "",
      cesfamSelected: 0,
      cesOpcion: null,
      comunaNom: "",
      comunaSelected: 0,
      comOpcion: null,
      mode: 0,
      modeTxt: "Diario",
      startDate: null,
      endDate: null,
      showGraf: false,
    };
  }

  formatFecha(sem, year) {
    var fechaInicioSemana = moment().years(year).isoWeek(sem).startOf("isoweek");

    return moment(fechaInicioSemana).format("YYYY-MM-DD");
  }

  cambiaBar(event, value) {
    if (value.val === 0) {
      this.setState({
        cesfamShow: true,
        cesOpcion: null,
        cesfamSelected: 0,
        cesfamNom: "",
        comOpcion: null,
        comunaSelected: 0,
        comunaNom: "",
        data: [],
      });
    } else if (value.val === 1) {
      this.setState({
        cesfamShow: false,
        cesOpcion: null,
        cesfamSelected: 0,
        cesfamNom: "",
        comOpcion: null,
        comunaSelected: 0,
        comunaNom: "",
        data: [],
        cesfams: [],
      });
    }
  }

  componentDidMount() {
    this.cargarCesfams();
    this.fillData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.cesfamShow) {
      if (prevState.cesOpcion !== this.state.cesOpcion || prevState.comOpcion !== this.state.comOpcion || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate || prevState.mode !== this.state.mode) {
        this.setState({ showGraf: false });
        this.fillData();
      }
    } else if (!this.state.cesfamShow) {
      if (prevState.comOpcion !== this.state.comOpcion || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate || prevState.mode !== this.state.mode) {
        this.setState({ showGraf: false });
        this.fillData();
      }
    }
  }

  fillData = async () => {
    let newData = [];
    if (this.state.cesfamShow) {
      if (this.state.cesfamSelected !== 0) {
        const apiUrl = global.config.modulos.repartos + "cobertura_vacunados_influenza/";
        var options = {
          method: "GET",
          params: {
            opcion: "1",
            mode: this.state.mode,
            cesfam: this.state.cesfamSelected,
          },
          headers: authHeader(),
        };

        if (this.state.startDate != null) {
          options.params["desde"] = moment(this.state.startDate).format("YYYY-MM-DD");
        }

        if (this.state.endDate != null) {
          options.params["hasta"] = moment(this.state.endDate).format("YYYY-MM-DD");
        }

        await axios(apiUrl, options).then((res) => {
          this.setState({ data: [] });
          if (this.state.mode === 0) {
            res.data.vacunados_por_fecha.map((res) => {
              newData = [...newData, { date: res.fecha, solicitudes_externas: res.cantidad_ext, dependencia_severa: res.cantidad_sev }];
            });
          } else if (this.state.mode === 1) {
            res.data.vacunados_por_fecha.map((res) => {
              newData = [...newData, { date: this.formatFecha(res.fecha, res.year), solicitudes_externas: res.cantidad_ext, dependencia_severa: res.cantidad_sev }];
            });
          }
          this.setState({ showGraf: true, data: newData });
        });
      }
    } else if (!this.state.cesfamShow) {
      if (this.state.comunaSelected !== 0) {
        const apiUrl = global.config.modulos.repartos + "cobertura_vacunados_influenza/";
        var options = {
          method: "GET",
          params: {
            opcion: "2",
            mode: this.state.mode,
            comuna: this.state.comunaSelected,
          },
          headers: authHeader(),
        };

        if (this.state.startDate != null) {
          options.params["desde"] = moment(this.state.startDate).format("YYYY-MM-DD");
        }

        if (this.state.endDate != null) {
          options.params["hasta"] = moment(this.state.endDate).format("YYYY-MM-DD");
        }

        await axios(apiUrl, options).then((res) => {
          this.setState({ data: [] });
          if (this.state.mode === 0) {
            res.data.vacunados_por_fecha.map((res) => {
              newData = [
                ...newData,
                {
                  date: res.fecha,
                  solicitudes_externas: res.cantidad_ext,
                  dependencia_severa: res.cantidad_sev,
                },
              ];
            });
          } else if (this.state.mode === 1) {
            res.data.vacunados_por_fecha.map((res) => {
              newData = [
                ...newData,
                {
                  date: this.formatFecha(res.fecha, res.year),
                  solicitudes_externas: res.cantidad_ext,
                  dependencia_severa: res.cantidad_sev,
                },
              ];
            });
          }
          this.setState({ showGraf: true, data: newData });
        });
      }
    }
  };

  setComuna = (event, value) => {
    if (this.state.cesfamShow) {
      this.setState({ data: [] });
      this.setState({
        comunaNom: value.label,
        comunaSelected: value.id,
        comOpcion: value,
        cesOpcion: null,
        cesfamNom: "",
        cesfamSelected: 0,
      });
      this.cargarCesfams(value.id);
    } else if (!this.state.cesfamShow) {
      this.setState({ comunaNom: value.label, comunaSelected: value.id, comOpcion: value });
    }
  };

  setMode = (event, value) => {
    this.setState({ mode: value.val, modeTxt: value.label });
  };

  setStartFecha = (e) => {
    this.setState({ startDate: e });
  };

  setEndFecha = (e) => {
    this.setState({ endDate: e });
  };

  setCesfam = (event, value) => {
    this.setState({ cesfamNom: value.label, cesfamSelected: value.id, cesOpcion: value });
  };

  cargarCesfams = async (comuna) => {
    const apiUrl = global.config.modulos.cesfams + "por_comuna/?id=" + comuna;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.datos.length > 0) {
        this.setState({ cesfams: [] });
        res.data.datos.map((res) => {
          if (res.ces_id == 1 || res.ces_id == 2 || res.ces_id == 3) {
            this.setState({
              cesfams: [...this.state.cesfams, { label: res.ces_nombre, val: res.ces_id, id: res.ces_id }],
            });
          }
        });
      }
    });
  };

  render() {
    const { data } = this.state;
    return (
      <div style={{ width: "100%", height: "100%", marginBottom: 180 }}>
        <GridItem xs={12} sm={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={2}>
              <Autocomplete disablePortal disableClearable id="combo-box-" options={this.props.opciones} getOptionLabel={(option) => option.label} defaultValue={this.props.opciones[0]} onChange={this.cambiaBar.bind(this)} renderInput={(params) => <TextField {...params} label="Por comuna o cesfam" />}></Autocomplete>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Autocomplete disablePortal disableClearable value={this.state.comOpcion} id="combo-box-" options={this.props.comunas} getOptionLabel={(option) => option.label} defaultValue={this.state.comOpcion} onChange={this.setComuna.bind(this)} renderInput={(params) => <TextField {...params} label="Selecionar Comuna" />}></Autocomplete>
            </Grid>
            {this.state.cesfamShow ? (
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete disablePortal disableClearable value={this.state.cesOpcion} id="combo-box-" defaultValue={this.state.cesOpcion} options={this.state.cesfams} noOptionsText={"Seleccione una comuna primero"} getOptionLabel={(option) => option.label} onChange={this.setCesfam.bind(this)} renderInput={(params) => <TextField {...params} label="Selecionar Cesfam" />}></Autocomplete>
              </Grid>
            ) : null}
            <Grid item xs={2} sm={2} md={2}>
              <Autocomplete disablePortal disableClearable id="combo-box-" options={this.props.tiempos} getOptionLabel={(option) => option.label} defaultValue={this.props.tiempos[0]} onChange={this.setMode.bind(this)} renderInput={(params) => <TextField {...params} label="Diario o Semanal" />}></Autocomplete>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  disableToolbar
                  invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                  disableFuture="true"
                  value={this.state.startDate}
                  initialFocusedDate={this.startDate}
                  format="yyyy-MM-dd"
                  id="date-picker-inline"
                  label="Desde"
                  name="fechaDesde"
                  onChange={(e) => this.setStartFecha(e)}
                  KeyboardButtonProps={{
                    "aria-label": "cambiar fecha",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  disableToolbar
                  invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                  disableFuture="true"
                  value={this.state.endDate}
                  initialFocusedDate={this.endDate}
                  format="yyyy-MM-dd"
                  id="date-picker-inline2"
                  label="Hasta"
                  name="fechaHasta"
                  onChange={(e) => this.setEndFecha(e)}
                  KeyboardButtonProps={{
                    "aria-label": "cambiar fecha",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </GridItem>
        <br></br>
        {this.state.cesfamShow ? (
          <h3 className="textColor">
            Rendimiento {this.state.modeTxt} solicitudes externas y dependencia severa Cesfam {this.state.cesfamNom}
          </h3>
        ) : (
          <h3 className="textColor">
            Rendimiento {this.state.modeTxt} solicitudes externas y dependencia severa Comuna {this.state.comunaNom}
          </h3>
        )}
        {this.state.showGraf ? (
          <div class="middle-content">
            <div class="chartContainer chartGrid">
              <div class="chart scroll-container">
                <GraficoSolExternasInfluenza data={data}></GraficoSolExternasInfluenza>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
