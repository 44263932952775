import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import iconoAgendar from "../../assets/img/usuarios/icono_agendar.svg";

import { store } from "./../../store/index";
import { handleError } from "./../../components/Helpers/helpers.js";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MaterialTable from "material-table";
import Cargando from "./../../components/Helpers/Cargando";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import ImprimirHora from "./ImprimirHora";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import MotivoReserva from "./MotivoReserva.js";

export default class AgendarHora extends Component {
  constructor(props) {
    super(props);

    var d = new Date();

    this.state = {
      totalRepeticiones: 0,
      usuId: props.usuario,
      horas_en_pausa: props.horas_en_pausa | null,
      reservador: props.reservador | null,
      espPrimaria: 0,
      canal: 5,
      sigla: "",
      espSecundaria: 0,
      datosEspPrimarias: [],
      datosEspSecundarias: [],
      datosEspecialidad: [],
      horarios: [],
      ciclo: null,
      open: true,
      familiar: props.familiar || false,
      filtros: true,
      quitar_reservas_nsp: null,
      quitar_prioridad: null,
      quitar_usuario_bloqueado: null,
      quitar_hora_emparejada: null,
      quitar_sector: null,

      contacto: null,
      otroTelefono: "",
      telefono: null,
      telefonos: [],

      fechaCalendario: [],
      fechaBCalendario: [],
      verCalendario: false,
      fechaSelect: new Date(),
      fechaReservas: [d.setDate(d.getDate() - 1)],
      fechaReservasP: [d.setDate(d.getDate() - 1)],

      fechaInicio: null,
      fechaTermino: null,
      sector: null,

      teleconsulta: false,
      tieneExamenes: false,
      examenes: [],
      examen: null,
      modalidades: [],
      modalidad: "",
      especialidad: null,

      existeContactoTelefono: false,
      existeContactoTelefonoOtro: false,
      canalesTeleconsulta: [],
      horariosExcluidos: [],

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      herramientas: store.getState().herramientas,
      cargando: false,
      motivoReserva: false,
      formErrors: [],
    };
  }

  componentDidMount() {
    this.fetchBuscarEspecialidades(this.state.usuId, this.state.canal, null);
    this.canalesTeleconsulta();
  }

  cambiarFiltro = (filtro) => {
    if (filtro) {
      this.setState({
        filtros: filtro,
        quitar_reservas_nsp: null,
        quitar_prioridad: null,
        quitar_hora_emparejada: null,
        quitar_usuario_bloqueado: null,
        quitar_sector: null,
      });
    } else {
      this.setState({
        filtros: filtro,
        quitar_reservas_nsp: true,
        quitar_prioridad: true,
        quitar_hora_emparejada: null,
        quitar_usuario_bloqueado: true,
        quitar_sector: true,
      });
    }
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  fetchBuscarEspecialidades = async (usuid, canal, sigla) => {
    axios
      .get(global.config.modulos.especialidades + "buscar/", {
        headers: authHeader(),
        params: {
          uspId: this.state.uspId,
          usuid: usuid,
          canal: canal,
          sigla: sigla,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          if (sigla) {
            this.setState({
              datosEspSecundarias: res.data.datos,
            });
          } else {
            this.setState({
              datosEspPrimarias: res.data.datos,
              espSecundaria: 0,
            });
          }
        } else {
          NotificationManager.error(res.data.mensaje);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  mensajesEspecialidad = async (sigla, canal, cesfam) => {
    var res = await axios(global.config.modulos.especialidades + "mensajes/", {
      headers: authHeader(),
      params: { sigla: sigla, cesfam: cesfam, canal: canal },
    });
    return res.data;
  };

  buscarHorarios = async () => {
    let sigla = this.state.sigla;
    let canal = this.state.canal;
    let cesfam = this.state.cesfam;

    let mensajes = await this.mensajesEspecialidad(sigla, canal, cesfam);
    if (mensajes.estado) {
      let total = mensajes.datos.length - 1;
      let actual = this.state.totalRepeticiones;

      confirmAlert({
        title: "",
        message: mensajes.datos[actual].mensaje,
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: () => {
              if (mensajes.datos[actual].mee_tipo === 2) {
                this.setState({ totalRepeticiones: 0 });
                return false;
              }
              if (total > actual) {
                this.setState(
                  {
                    totalRepeticiones: actual + 1,
                  },
                  () => {
                    this.buscarHorarios();
                  }
                );
              } else {
                this.setState({ totalRepeticiones: 0 });
                this.buscarHoras(this.state.cesfam, this.state.sector, null, null);
              }
            },
          },
        ],
      });
    } else {
      this.buscarHoras(this.state.cesfam, this.state.sector, null, null);
    }
  };

  buscarHoras = async (cesfam, sector, fechaInicio, fechaTermino) => {
    let sigla = this.state.sigla;
    let canal = this.state.canal;
    let horariosExcluidos = [];
    const horariosIniciales = [];
    let cantidadHorasB1 = 0;
    let diferenciaHorasB1 = 0;
    let cantidadHorasB2 = 20;
    let diferenciaHorasB2 = 0;

    this.setState({
      cesfam: cesfam,
      sector: sector,
      fechaInicio: fechaInicio,
      fechaTermino: fechaTermino,
      cargando: true,
    });

    var horas_en_pausa = this.state.horas_en_pausa;
    if (horas_en_pausa == 0) {
      horas_en_pausa = null;
    }

    await Promise.all([
      axios
        .get(global.config.modulos.reservas + "obtener_reservas_some_por_cesfam/", {
          headers: authHeader(),
          params: {
            cesfam: cesfam,
          },
        })
        .then((res) => {
          if (res.data.estado) {
            cantidadHorasB1 = res.data.datos.res_cantidad_horas_b1;
            cantidadHorasB2 = res.data.datos.res_cantidad_horas_b2;
            diferenciaHorasB1 = res.data.datos.res_diferencia_horas_b1;
            diferenciaHorasB2 = res.data.datos.res_diferencia_horas_b2;
          }
        }),
    ]);

    await Promise.all([
      parseInt(cantidadHorasB1) > 0 &&
        axios
          .get(global.config.modulos.reservas + "horariosDisponibles/", {
            headers: authHeader(),
            params: {
              uspId: this.state.uspId,
              idUsuario: this.state.usuId,
              sigla: sigla,
              canal: canal,
              cesfam: cesfam,
              sector: sector,
              nHoras: cantidadHorasB1,
              diffHoras: diferenciaHorasB1,
              quitar_reservas_nsp: this.state.quitar_reservas_nsp,
              quitar_prioridad: this.state.quitar_prioridad,
              quitar_usuario_bloqueado: this.state.quitar_usuario_bloqueado,
              quitar_hora_emparejada: this.state.quitar_hora_emparejada,
              quitar_sector: this.state.quitar_sector,
              deshabilitar_hora_en_gestion: true,
              fecha_hora_inicio: fechaInicio,
              fecha_hora_termino: fechaTermino,
              horas_en_pausa: horas_en_pausa,
              examen: this.state.examen,
            },
          })
          .then((res) => {
            if (res.data.estado) {
              res.data.horas.map((hor) => {
                hor["hor_atencion"] = hor["hor_atencion"].replace("T", " ");
                horariosExcluidos.push(hor["hor_id"]);
              });
              horariosIniciales.push(...res.data.horas);
            }
          }),
    ]);

    axios
      .get(global.config.modulos.reservas + "horariosDisponibles/", {
        headers: authHeader(),
        params: {
          uspId: this.state.uspId,
          idUsuario: this.state.usuId,
          sigla: sigla,
          canal: canal,
          cesfam: cesfam,
          sector: sector,
          nHoras: cantidadHorasB2,
          diffHoras: diferenciaHorasB2,
          quitar_reservas_nsp: this.state.quitar_reservas_nsp,
          quitar_prioridad: this.state.quitar_prioridad,
          quitar_usuario_bloqueado: this.state.quitar_usuario_bloqueado,
          quitar_hora_emparejada: this.state.quitar_hora_emparejada,
          quitar_sector: this.state.quitar_sector,
          deshabilitar_hora_en_gestion: true,
          fecha_hora_inicio: fechaInicio,
          fecha_hora_termino: fechaTermino,
          horas_en_pausa: horas_en_pausa,
          horariosExcluidos: horariosExcluidos.join(","),
          examen: this.state.examen,
        },
      })
      .then((res) => {
        this.setState({
          cargando: false,
        });
        if (res.data.estado) {
          res.data.horas.map((hor) => {
            hor["hor_atencion"] = hor["hor_atencion"].replace("T", " ");
          });
          this.setState({
            horarios: [...horariosIniciales, ...res.data.horas],
            ciclo: res.data.ciclo,
          });
        } else {
          if (horariosIniciales.length == 0) {
            if (res.data.emparejada) {
              confirmAlert({
                title: "",
                message: "Este paciente ya posee una hora de " + res.data.nombre_especialidad + ", Segur@ que desea reservar esta hora?",
                buttons: [
                  {
                    label: "Cancelar",
                    className: "btnCancelar",
                    onClick: () => {
                      this.setState({
                        horarios: [],
                      });
                    },
                  },
                  {
                    label: "Aceptar",
                    className: "btnAceptar",
                    onClick: () => {
                      this.setState(
                        {
                          quitar_hora_emparejada: true,
                        },
                        () => {
                          this.buscarHorarios();
                        }
                      );
                    },
                  },
                ],
              });
            } else if (res.data.tipo == "horasOtroCentro") {
              confirmAlert({
                onClickOutside: () => {
                  if (this.state.espSecundaria != 0) {
                    this.setState({
                      espSecundaria: 0,
                      horarios: [],
                    });
                  } else {
                    this.setState({
                      espPrimaria: 0,
                      horarios: [],
                    });
                  }
                },
                customUI: ({ onClose }) => {
                  return (
                    <div className="agendarOtroCesfam">
                      <GridContainer className="agendar-container">
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                          <h2>{res.data.mensaje}</h2>
                          <br></br>
                          <h2>{res.data.mensaje_buscar}</h2>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}></GridItem>
                        {res.data.cesfams.map((row, index) => (
                          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: 10 }}>
                            <button
                              className="botonGeneral"
                              onClick={() => {
                                onClose();
                                this.buscarHoras(row.ces_id, this.state.sector, this.state.fechaInicio, this.state.fechaTermino);
                              }}
                            >
                              {row.nombre_centro}
                            </button>
                          </GridItem>
                        ))}
                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
                          <Button
                            onClick={() => {
                              onClose();
                            }}
                            className="botonDialogCancel fondoRojo"
                          >
                            Cancelar
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  );
                },
              });
            } else if (res.data.tipo == "horasOtroSector") {
              confirmAlert({
                onClickOutside: () => {
                  if (this.state.espSecundaria != 0) {
                    this.setState({
                      espSecundaria: 0,
                      horarios: [],
                    });
                  } else {
                    this.setState({
                      espPrimaria: 0,
                      horarios: [],
                    });
                  }
                },
                customUI: ({ onClose }) => {
                  return (
                    <div className="agendarOtroCesfam">
                      <GridContainer className="agendar-container">
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                          <h2>{res.data.mensaje}</h2>
                          <br></br>
                          <h2>{res.data.mensaje_buscar}</h2>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}></GridItem>
                        {res.data.sectores.map((row, index) => (
                          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: 10 }}>
                            <button
                              className="botonGeneral"
                              onClick={() => {
                                onClose();
                                this.buscarHoras(this.state.cesfam, row.sec_id, this.state.fechaInicio, this.state.fechaTermino);
                              }}
                            >
                              {row.nombre_sector}
                            </button>
                          </GridItem>
                        ))}
                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
                          <Button
                            onClick={() => {
                              onClose();
                            }}
                            className="botonDialogCancel fondoRojo"
                          >
                            Cancelar
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  );
                },
              });
            } else if (res.data.tipo == "usuarioBloqueado") {
              confirmAlert({
                onClickOutside: () => {
                  if (this.state.espSecundaria != 0) {
                    this.setState({
                      espSecundaria: 0,
                      horarios: [],
                    });
                  } else {
                    this.setState({
                      espPrimaria: 0,
                      horarios: [],
                    });
                  }
                },
                title: "",
                message: res.data.mensaje,
                buttons: [
                  {
                    label: "Aceptar",
                    className: "btnAceptar",
                    onClick: () => {},
                  },
                ],
              });
            } else {
              this.setState({
                horarios: [],
                quitar_hora_emparejada: null,
              });
              NotificationManager.error(res.data.mensaje);
            }
          } else {
            this.setState({
              horarios: horariosIniciales,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          cargando: false,
        });
        handleError(err);
      });
  };

  valorEspPrimaria = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        datosEspSecundarias: [],
        espSecundaria: 0,
        horarios: [],
        tieneExamenes: false,
        examen: null,
        modalidad: "",
        especialidad: e.target.value,
      },
      () => {
        this.traerContacto();
        this.traerExamenes(e.target.value);
      }
    );
    this.state.datosEspPrimarias.map((esp) => {
      if (esp.esr_especialidad == e.target.value) {
        if (esp.esr_entrega_horas == 0) {
          this.fetchBuscarEspecialidades(this.state.usuId, this.state.canal, esp.esp_sigla);
          this.setState({
            sigla: "",
          });
        } else {
          this.state.sigla = esp.esp_sigla;
        }
      }
    });
  };

  valorEspSecundaria = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        horarios: [],
        tieneExamenes: false,
        examen: null,
        modalidad: "",
        especialidad: e.target.value,
      },
      () => {
        this.traerContacto();
        this.traerExamenes(e.target.value);
      }
    );
    this.state.datosEspSecundarias.map((esp) => {
      if (esp.esr_especialidad == e.target.value) {
        this.state.sigla = esp.esp_sigla;
      }
    });
  };

  confirmarExamen = async () => {
    await this.validarUsuario();
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "¿El paciente tiene la orden para el examen?",
        buttons: [
          {
            label: "No",
            className: "btnCancelar",
            onClick: async () => {
              await this.guardarRespuestaExamen(2, 1, this.state.usuId);
              confirmAlert({
                title: "",
                message: "La orden de examen es obligatoria para realizar la reserva y posteriormente el examen, una vez que el paciente presente la orden del examen podrá reservar la hora de atención.",
                buttons: [
                  {
                    label: "Aceptar",
                    className: "btnAceptar",
                  },
                ],
              });
            },
          },
          {
            label: "Sí",
            className: "btnAceptar",
            onClick: async () => {
              await this.guardarRespuestaExamen(1, 1, this.state.usuId);
              this.revisarEmbarazo();
            },
          },
        ],
      });
    }
  };

  revisarEmbarazo = async () => {
    if (this.state.usu_edad >= 12 && this.state.usu_edad <= 52 && this.state.usu_sexo == "F" && (this.state.especialidad == 2567 || this.state.especialidad == 2568)) {
      confirmAlert({
        title: "",
        message: "¿La Paciente tiene sospecha de embarazo?",
        buttons: [
          {
            label: "No",
            className: "btnCancelar",
            onClick: async () => {
              await this.guardarRespuestaExamen(2, 2, this.state.usuId);
              this.buscarHorarios();
            },
          },
          {
            label: "Sí",
            className: "btnAceptar",
            onClick: async () => {
              await this.guardarRespuestaExamen(1, 2, this.state.usuId);
              this.confirmarEmbarazo();
            },
          },
        ],
      });
    } else {
      this.buscarHorarios();
    }
  };

  confirmarEmbarazo = async () => {
    confirmAlert({
      title: "",
      message: "¿Estás seguro que la paciente indica tener sospecha de embarazo?",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
          onClick: async () => {
            await this.guardarRespuestaExamen(2, 3, this.state.usuId);
            this.buscarHorarios();
          },
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: async () => {
            await this.guardarRespuestaExamen(1, 3, this.state.usuId);
            confirmAlert({
              title: "",
              message: "La Paciente no puede realizarse el examen, dado que puede afectar su salud por tener sospecha de embarazo",
              buttons: [
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                },
              ],
            });
          },
        },
      ],
    });
  };

  guardarRespuestaExamen = async (respuesta, pregunta, usuId) => {
    var data = {
      uspId: this.state.uspId,
      respuesta: respuesta,
      pregunta: pregunta,
      usuario: usuId,
    };
    let apiUrl = global.config.modulos.agendamientos + "imagenologia_respuestas/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    await axios(apiUrl, options);

    return true;
  };

  reservarHora = (horario, cesfam) => {
    if (this.validaciones()) {
      axios
        .post(
          global.config.modulos.reservas + "guardar_hora/",
          {
            horario: horario,
            usuario: this.state.usuId,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          if (res.data.estado) {
            confirmAlert({
              title: "",
              message: "La hora fue guardada por 3 min, segur@ que desea reservar esta hora?",
              buttons: [
                {
                  label: "Cancelar",
                  className: "btnCancelar",
                  onClick: () => null,
                },
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                  onClick: () => {
                    this.setState({
                      cargando: true,
                    });

                    let telefono = this.state.telefono;
                    if (telefono == "otro") {
                      this.agregarTelefono(this.state.otroTelefono, this.state.usuId, this.state.uspId);
                      telefono = this.state.otroTelefono;
                    }

                    axios
                      .get(global.config.modulos.agendamientos + "motivos_reserva_horario/", {
                        headers: authHeader(),
                        params: {
                          especialidad: this.state.especialidad,
                        },
                      })
                      .then((res) => {
                        if (res.data.estado) {
                          this.setState({
                            cargando: false,
                            motivos: res.data.motivos,
                            horarioReserva: horario,
                            cesfamReserva: cesfam,
                            telefonoReserva: telefono,
                            tipoReserva: res.data.tipo,
                            motivoReserva: true,
                          });
                        } else {
                          axios
                            .post(
                              global.config.modulos.agendamientos + "agendar_horario/",
                              {
                                horario: horario,
                                cesfam: cesfam,
                                usp_id: this.state.uspId,
                                canal: this.state.canal,
                                usu_id: this.state.usuId,
                                grupo_familiar: this.state.reservador,
                                tipo_contacto: this.state.contacto,
                                telefono_contacto: telefono,
                                examen: this.state.examen,
                                modalidad: this.state.modalidad,
                              },
                              {
                                headers: authHeader(),
                              }
                            )
                            .then(async (res) => {
                              this.setState({
                                cargando: false,
                              });

                              if (res.data.estado) {
                                NotificationManager.success(res.data.mensaje);
                                this.salir();
                                if (this.state.herramientas.includes("H61")) {
                                  this.mostrarImprimir(horario, this.state.usuId, this.state.examen);
                                }
                              } else {
                                NotificationManager.error(res.data.mensaje);
                                this.salir();
                              }
                            })
                            .catch((err) => {
                              this.setState({
                                cargando: false,
                              });
                            });
                        }
                      })
                      .catch((err) => {
                        this.setState({
                          cargando: false,
                        });
                      });
                  },
                },
              ],
            });
          } else {
            NotificationManager.error(res.data.mensaje);
          }
        });
    }
  };

  validaciones() {
    const { teleconsulta, contacto, telefono, otroTelefono, tieneExamenes, examen, modalidad } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (teleconsulta && contacto == "3" && (telefono == null || telefono == 0)) {
      formIsValid = false;
      formErrors["selTelefonoErr"] = "Selecciona un teléfono.";
      //NotificationManager.error("Selecciona un teléfono.");
    } else if (teleconsulta && telefono == "otro" && !otroTelefono) {
      formIsValid = false;
      formErrors["telefonoErr"] = "Escribe el teléfono de contacto.";
      //NotificationManager.error("Escribe el teléfono de contacto.");
    } else if (teleconsulta && telefono == "otro" && otroTelefono.length < 9) {
      formIsValid = false;
      formErrors["telefonoErr"] = "Verifica el largo del teléfono de contacto, deben ser 9 dígitos.";
      //NotificationManager.error("Verifica el largo del teléfono de contacto, deben ser 9 dígitos.");
    }
    if (tieneExamenes && examen == null) {
      formIsValid = false;
      formErrors["examenErr"] = "Seleciona el examen que se realizara el paciente.";
    }

    if (tieneExamenes && modalidad == "") {
      formIsValid = false;
      formErrors["modalidadErr"] = "Seleciona la modalidad";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  traerContacto = () => {
    var especialidad = "";
    if (this.state.espPrimaria != 0 && this.state.espSecundaria == 0) {
      var e = document.getElementById("espPrimaria");
      especialidad = e.textContent;
    } else if (this.state.espPrimaria != 0 && this.state.espSecundaria != 0) {
      var e = document.getElementById("espSecundaria");
      especialidad = e.textContent;
    }

    if (especialidad.includes("Teleconsulta")) {
      this.setState({
        teleconsulta: true,
        contacto: 1,
        existeContactoTelefono: false,
        existeContactoTelefonoOtro: false,
        telefono: null,
        telefonos: [],
        otroTelefono: "",
      });
    } else {
      this.setState({
        teleconsulta: false,
        contacto: null,
        telefono: null,
        telefonos: [],
        otroTelefono: "",
      });
    }
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    if (name == "contacto") {
      if (value == 3) {
        this.setState({ existeContactoTelefono: true });
        this.traerTelefonos();
      } else {
        this.setState({
          existeContactoTelefono: false,
          telefono: null,
          telefonos: [],
          otroTelefono: "",
          existeContactoTelefonoOtro: false,
        });
      }
    }

    if (name == "telefono") {
      if (value == "otro") {
        this.setState({ existeContactoTelefonoOtro: true });
      } else {
        this.setState({ existeContactoTelefonoOtro: false });
      }
    }

    this.setState({ [name]: value });
  };

  traerTelefonos = () => {
    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        telefonos: res.data.datos,
        telefono: 0,
      });
    });
  };

  agregarTelefono = (telefono, usuId, uspId) => {
    axios
      .post(
        global.config.modulos.telefonos + "agregar_telefono/",
        {
          telefono: telefono,
          usu_id: usuId,
          tipo: 2,
          canal: 5,
          usp_id: uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  mostrarImprimir = (horario, usuId, examen) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ImprimirHora horario={horario} usuId={usuId} examen={examen} ocultar={onClose}></ImprimirHora>;
      },
    });
  };

  canalesTeleconsulta = async () => {
    axios
      .get(global.config.modulos.videollamada + "canales_teleconsulta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        this.setState({
          canalesTeleconsulta: data,
        });
      })
      .catch((err) => {});
  };

  traerExamenes = async (especialidad) => {
    axios
      .get(global.config.modulos.especialidades + "examenes/", {
        headers: authHeader(),
        params: {
          especialidad: especialidad,
          uspId: this.state.uspId,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        if (data.length > 0) {
          this.setState({
            examenes: data,
            tieneExamenes: true,
          });
          this.cambiarFiltro(false);
          this.traerModalidades();
        } else {
          this.cambiarFiltro(true);
        }
      })
      .catch((err) => {});
  };

  validarUsuario = async () => {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;
    var res = await axios.get(apiUrl, {
      headers: authHeader(),
    });
    var data = res.data;
    this.setState({ usu_edad: data.datos.usu_edad, usu_sexo: data.datos.usu_sexo });

    return true;
  };

  traerModalidades() {
    const apiUrl = global.config.modulos.usuarios + "tipos_convenios/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: this.state.uspId,
        },
      })
      .then((res) => {
        var datos = res.data.datos;
        if (datos.length == 1) {
          this.setState({
            modalidades: res.data.datos,
            modalidad: res.data.datos[0]["uct_id"],
          });
        } else {
          this.setState({
            modalidades: res.data.datos,
          });
        }
      });
  }

  agendarHorario = () => {
    this.setState({
      motivoReserva: false,
      cargando: true,
    });

    axios
      .post(
        global.config.modulos.agendamientos + "agendar_horario/",
        {
          horario: this.state.horarioReserva,
          cesfam: this.state.cesfamReserva,
          usp_id: this.state.uspId,
          canal: this.state.canal,
          usu_id: this.state.usuId,
          grupo_familiar: this.state.reservador,
          tipo_contacto: this.state.contacto,
          telefono_contacto: this.state.telefonoReserva,
          examen: this.state.examen,
          modalidad: this.state.modalidad,
        },
        {
          headers: authHeader(),
        }
      )
      .then(async (res) => {
        this.setState({
          cargando: false,
        });

        if (res.data.estado) {
          NotificationManager.success(res.data.mensaje);
          this.salir();
          if (this.state.herramientas.includes("H61")) {
            this.mostrarImprimir(this.state.horarioReserva, this.state.usuId, this.state.examen);
          }
        } else {
          NotificationManager.error(res.data.mensaje);
          this.salir();
        }
      })
      .catch((err) => {
        this.setState({
          cargando: false,
        });
      });
  };

  ocultarMotivoReserva = () => {
    this.setState({
      motivoReserva: false,
    });
  };

  render() {
    const { examenErr, modalidadErr, selTelefonoErr, telefonoErr } = this.state.formErrors;

    const { filtros } = this.state;

    const datosExamen = (dato) => {
      if (dato == null) {
        this.setState({
          examen: null,
        });
      } else {
        this.setState({
          examen: dato.exs_id,
        });
      }
    };

    return (
      <GridContainer className="agendar-container">
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer className="agendar-container">
            <GridItem id="modalAgendarEspecialidades" xs={12} style={{ marginTop: 30, float: "left" }}>
              {filtros ? (
                <Button onClick={() => this.cambiarFiltro(false)} style={{ color: "#fff" }} className=" fondoVerde">
                  Filtros Habilitados
                </Button>
              ) : (
                <Button onClick={() => this.cambiarFiltro(true)} style={{ color: "#fff", backgroundColor: "#d34a4a" }} className=" ">
                  Filtros Deshabilitados
                </Button>
              )}
            </GridItem>
            <GridItem id="modalAgendarEspecialidades" xs={5} sm={5} md={5} style={{ marginTop: 30 }}>
              <InputLabel id="label-habilitado">Especialidades</InputLabel>
              <Select style={{ with: 300 }} labelId="label-habilitado" id="espPrimaria" name="espPrimaria" value={this.state.espPrimaria} onChange={this.valorEspPrimaria} fullWidth>
                <MenuItem value={0}>Seleccione especialidad</MenuItem>
                {this.state.datosEspPrimarias.map((esp, index) => {
                  return esp.sin_horas_futuras ? (
                    <MenuItem style={{ color: "gray" }} onClick={() => NotificationManager.warning("No hay agenda creada para este tipo de atención.")} value={esp.esr_especialidad} key={index}>
                      {esp.esp_nombre}
                    </MenuItem>
                  ) : (
                    <MenuItem style={{ color: "black" }} value={esp.esr_especialidad} key={index}>
                      {esp.esp_nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </GridItem>
            {this.state.datosEspSecundarias.length > 0 ? (
              <GridItem id="modalAgendarAtenciones" xs={5} sm={5} md={5} style={{ marginTop: 30 }}>
                <InputLabel id="label-habilitado">Tipos de atención</InputLabel>
                <Select labelId="label-habilitado" id="espSecundaria" name="espSecundaria" value={this.state.espSecundaria} onChange={this.valorEspSecundaria} fullWidth>
                  <MenuItem value={0}>Seleccione tipo de atención</MenuItem>
                  {this.state.datosEspSecundarias.map((esp, index) => {
                    return esp.sin_horas_futuras ? (
                      <MenuItem style={{ color: "gray" }} onClick={() => NotificationManager.warning("No hay agenda creada para este tipo de atención.")} value={esp.esr_especialidad} key={index}>
                        {esp.esp_nombre}
                      </MenuItem>
                    ) : (
                      <MenuItem style={{ color: "black" }} value={esp.esr_especialidad} key={index}>
                        {esp.esp_nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </GridItem>
            ) : null}
            <GridItem id="modalAgendarBuscar" xs={2} sm={2} md={2} style={{ marginTop: 40 }}>
              <Button onClick={this.state.tieneExamenes ? this.confirmarExamen : this.buscarHorarios} className="botonDialog fondoNaranjo">
                Buscar
              </Button>
            </GridItem>
          </GridContainer>

          {this.state.teleconsulta ? (
            <>
              <br></br>
              <InputLabel>Contacto</InputLabel>
              <Select style={{ width: 200 }} labelId="label-habilitado" id="contacto" name="contacto" value={this.state.contacto} onChange={this.cambiarValores}>
                {this.state.canalesTeleconsulta.map((res, index) => {
                  return (
                    <MenuItem value={res.cta_id} key={index}>
                      {res.cta_canal}
                    </MenuItem>
                  );
                })}
              </Select>
              <br></br>
              <br></br>
              {this.state.existeContactoTelefono ? (
                <>
                  <InputLabel>Teléfono</InputLabel>
                  <Select style={{ width: 200 }} labelId="label-habilitado" id="telefono" name="telefono" value={this.state.telefono} onChange={this.cambiarValores}>
                    <MenuItem value={0}>Seleccione</MenuItem>

                    {this.state.telefonos.map((res, index) => {
                      return (
                        <MenuItem value={res.tel_telefono} key={index}>
                          {res.tel_telefono}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={"otro"}>Otro</MenuItem>
                  </Select>
                  {selTelefonoErr && <div style={{ color: "red", paddingBottom: 10 }}>{selTelefonoErr}</div>}
                  <br></br>
                  <br></br>
                  {this.state.existeContactoTelefonoOtro ? (
                    <>
                      <InputLabel>Otro</InputLabel>
                      <TextField
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                        }}
                        id="standard-basic"
                        label=""
                        value={this.state.otroTelefono}
                        style={{ width: 200 }}
                        type="number"
                        name="otroTelefono"
                        onChange={this.cambiarValores}
                        onInput={(e) => {
                          e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                        }}
                      />
                      {telefonoErr && <div style={{ color: "red", paddingBottom: 10 }}>{telefonoErr}</div>}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}

          {this.state.tieneExamenes ? (
            <Grid xs={5} sm={5} md={5} className={"autocompleteBuscar"}>
              <InputLabel>Examen*</InputLabel>
              <Autocomplete
                id="cmbExamenes"
                onChange={function (event, res) {
                  if (res === null) {
                    datosExamen(null);
                  } else {
                    datosExamen(res);
                  }
                }}
                size="small"
                options={Object.values(this.state.examenes)}
                getOptionLabel={(option) => "0" + option.exs_codigo + " -- " + option.exs_prestacion}
                filterOptions={createFilterOptions({
                  stringify: (option) => "0" + option.exs_codigo + option.exs_prestacion,
                })}
                renderInput={(params) => <TextField {...params} />}
              ></Autocomplete>
              {examenErr && <div style={{ color: "red", paddingBottom: 10 }}>{examenErr}</div>}
              <br></br>
              <br></br>

              <InputLabel>Modalidad*</InputLabel>
              <Select id="modalidad" name="modalidad" value={this.state.modalidad} onChange={this.cambiarValores} displayEmpty className={""} style={{ width: "100%" }}>
                <MenuItem value="" disabled>
                  <em>Seleccione modalidad</em>
                </MenuItem>

                {this.state.modalidades &&
                  this.state.modalidades.map((uct, index) => (
                    <MenuItem value={uct.uct_id} key={index}>
                      {uct.uct_nombre}
                    </MenuItem>
                  ))}
              </Select>
              {modalidadErr && <div style={{ color: "red", paddingBottom: 10 }}>{modalidadErr}</div>}
            </Grid>
          ) : null}

          <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ minHeight: 200, marginTop: 40 }}>
            {this.state.horarios.length > 0 ? (
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "Hora",
                    field: "hor_atencion",
                    defaultSort: "asc",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.hor_atencion}</p>
                      </React.Fragment>
                    ),
                  },
                  { title: "Especialidad", field: "esp_nombre" },
                  {
                    title: "Profesional",
                    field: "pro_nombre",
                    field: "pro_apellido",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.pro_nombre + " " + rowData.pro_apellido}</p>
                      </React.Fragment>
                    ),
                  },
                  { title: "Cesfam", field: "ces_nombre" },
                ]}
                actions={[
                  {
                    icon: () => <img src={iconoAgendar} style={{ width: 40 }} />,
                    tooltip: this.state.familiar ? "Reservar esta hora para el familiar" : "Reservar esta hora para el paciente",
                    onClick: (event, rowData) => this.reservarHora(rowData.hor_id, rowData.hor_cesfam),
                  },
                ]}
                data={this.state.horarios}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  sorting: true,
                  pageSize: 10,
                  pageSizeOptions: [{ value: this.state.horarios.length, label: "All" }],
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay comentarios registrados",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
            <Button onClick={this.salir} className="botonDialogCancel fondoRojo">
              Cancelar
            </Button>
          </GridItem>
        </GridItem>
        {this.state.cargando ? <Cargando /> : null}
        {this.state.motivoReserva ? <MotivoReserva motivos={this.state.motivos} horarioReserva={this.state.horarioReserva} usuarioReserva={this.state.usuId} tipoReserva={this.state.tipoReserva} ocultarAgendar={this.agendarHorario} ocultar={this.ocultarMotivoReserva} /> : null}
      </GridContainer>
    );
  }
}
