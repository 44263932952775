import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class ObervacionesTeleconsulta extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      geg_id: props.gegId,
      usuario: props.usuario,
      observaciones: [],
    };
  }

  componentDidMount() {
    this.fetchObservacionesTeleconsulta();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  fetchObservacionesTeleconsulta = async () => {
    axios
      .get(global.config.modulos.videollamada + "observaciones/", {
        headers: authHeader(),
        params: { usuario: this.state.usuario },
      })
      .then((res) => {
        this.setState({
          observaciones: res.data.observaciones,
        });
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Observaciones teleconsulta</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30, maxHeight: 500, overflow: "scroll" }}>
                  <Table stickyHeader aria-label="simple table" className=" bordeTabla">
                    <TableHead>
                      <TableRow>
                        <TableCell className="cellHead" align="center">
                          Fecha
                        </TableCell>
                        <TableCell className="cellHead" align="center">
                          Observación
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.observaciones &&
                        this.state.observaciones.map((res, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="center">{res.vlo_fecha_registro.replace("T", " ")}</TableCell>
                              <TableCell align="center">{res.vlo_observacion}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
