import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";

export default class UsuarioEnTratamiento extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    this.state = {
      open: true,

      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      usuId: props.id_usuario,

      pagina: 0,
      filas: 10,
      total: 0,
      tratamientos: [],

      fecha_inicio: date,
      tratamiento: props.programa_cita,
      tipo_lista: props.tipo_lista,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  componentDidMount() {
    this.obtenerTratamientos();
  }

  fecha_inicio = (e) => {
    const fecha_inicio = e.target.value;
    this.setState({ fecha_inicio: fecha_inicio });
  };

  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });
  };

  agregarTratamiento = () => {
    if (this.state.fecha_inicio == null) {
      NotificationManager.error("Debe ingresar una fecha de inicio");
      return;
    }
    if (this.state.tratamiento == null) {
      NotificationManager.error("Debe seleccionar un tratamiento");
      return;
    }

    axios
      .patch(
        global.config.modulos.listaEspera + "agregar_cita_tratamiento/",
        {
          id_usuario: this.state.usuId,
          fecha_inicio: this.state.fecha_inicio,
          tratamiento: this.state.tratamiento,
          usp_id: this.state.usp_id,
          tipo_lista: this.state.tipo_lista,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha citado a tratamiento al paciente");
          this.salir();
          this.setState({
            fecha_inicio: null,
            tratamiento: null,
          });
        } else {
          NotificationManager.error("No se ha podido agregar el tratamiento al usuario");
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "700px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <h2>Programa</h2>
            </div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ display: "flex", marginBottom: "3%" }}>
                  <FormControl style={{ width: "30%", marginRight: "4%" }}>
                    <InputLabel id="demo-simple-select-label">fecha ingreso</InputLabel>
                    <Input type="date" name="fecha inicio" value={this.state.fecha_inicio} onChange={this.fecha_inicio} />
                  </FormControl>
                  <FormControl style={{ width: "50%" }}>
                    <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
                    <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
                      <MenuItem value={null}>Seleccione Tratamiento</MenuItem>
                      {this.state.tratamientos.map((tratamiento, i) => {
                        return (
                          <MenuItem key={i} value={tratamiento.lt_id}>
                            {tratamiento.lt_nombre}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    marginLeft: "65%",
                    marginTop: "30px",
                  }}
                >
                  <Buttons
                    onClick={() => this.agregarTratamiento()}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      backgroundColor: "#00ac77",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Guardar
                  </Buttons>
                  <Buttons
                    onClick={this.salir}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Cancelar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
