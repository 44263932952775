import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText, TextareaAutosize } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";

export default class UsuarioCrearObservacionLibre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      loi_id: props.id_usuario,

      open: true,
      observacion_libre: null,
    };
  }
  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  observacion_libre = (e) => {
    const observacion_libre = e.target.value;
    this.setState({ observacion_libre: observacion_libre });
  };

  agregarTratamiento = () => {
    if (this.state.observacion_libre == null) {
      NotificationManager.error("Debe ingresar una observacion");
      return;
    }

    axios
      .post(
        global.config.modulos.listaEspera + "crear_observacion_usuario/",
        {
          loi_id: this.state.loi_id,
          uspId: this.state.uspId,
          observacion: this.state.observacion_libre,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha agregado una observacion al usuario");
          this.salir();
          this.setState({
            observacion_libre: null,
          });
        } else {
          NotificationManager.error("No se ha podido agregar el tratamiento al usuario");
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent style={{ width: "700px", maxWidth: "100%", height: "100%", maxHeight: "100%" }}>
            <div style={{ marginBottom: "20px" }}>
              <h4>Observación libre</h4>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ display: "flex", marginBottom: "3%" }}>
                  <FormControl style={{ width: "90%", marginLeft: "5%" }}>
                    <TextField name="observacion_libre" multiline rows={6} value={this.state.observacion_libre} onChange={this.observacion_libre} style={{ border: "1px solid black", borderRadius: "5px", padding: "7px" }} />
                  </FormControl>
                </div>

                <div style={{ display: "flex", marginBottom: "1%", marginLeft: "65%", marginTop: "30px" }}>
                  <Buttons
                    onClick={() => this.agregarTratamiento()}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      backgroundColor: "#00ac77",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Guardar
                  </Buttons>
                  <Buttons
                    onClick={this.salir}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Cancelar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
