export function loginUser(cesfam, usp, nombreusp, herramientas, cliente, id_medico, tipo, letraTam) {
  return {
    type: "LOGIN",
    payload: {
      logged: true,
      cesfam: cesfam,
      usp: usp,
      nombreusp: nombreusp,
      herramientas: herramientas,
      cliente: cliente,
      id_medico: id_medico,
      tipo: tipo,
      letraTam: letraTam,
    },
  };
}

export function logoutUser() {
  return {
    type: "LOGIN",
    payload: {
      logged: false,
      cesfam: "",
      usp: "",
      nombreusp: "",
      herramientas: [],
      cliente: "",
    },
  };
}

export function cambioCesfam(cesfam, cliente) {
  return {
    type: "CAMBIO_CESFAM",
    payload: {
      cesfam: cesfam,
      cliente: cliente,
    },
  };
}

export function cambioNombreCesfam(nombre_cesfam) {
  return {
    type: "CAMBIO_NOMBRE_CESFAM",
    payload: {
      nombre_cesfam: nombre_cesfam,
    },
  };
}

export function horario(fechaHorario) {
  return {
    type: "HORARIO",
    payload: {
      fechaHorario: fechaHorario,
    },
  };
}

export function showLoader() {
  return {
    type: "SHOW_LOADER",
  };
}

export function hideLoader() {
  return {
    type: "HIDE_LOADER",
  };
}

export function totem(totem, nombre_totem) {
  return {
    type: "TOTEM",
    payload: {
      totem: totem,
      nombre_totem: nombre_totem,
    },
  };
}
