import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class AgregarUsuario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parentescos: [],
      GFamiliar: [],
      gfaid: "",
      parentesco: "",
      rutFamiliar: "",
      idFamiliar: null,
      canal: 5,
      existe: 0,
      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      open: true,
    };

    this.revisaExisteRut = this.revisaExisteRut.bind(this);
  }
  componentDidMount() {
    this.traerParentescos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerParentescos() {
    let apiUrl = global.config.modulos.usuarios + "parentescos/";
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        parentescos: res.data.datos,
      });
    });
  }

  validaciones() {
    const { rutFamiliar, idFamiliar, usuId, parentesco, existe } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (existe === 0) {
      formIsValid = false;
      formErrors["rutErr"] = "El usuario no existe en Hora Salud";
    } else if (existe === 2) {
      formIsValid = false;
      formErrors["rutErr"] = "El usuario esta eliminado";
    }

    if (!rutFamiliar) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut del familiar.";
    } else if (!chileanRut.validate(rutFamiliar)) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut valido.";
    }

    if (parentesco === "") {
      formIsValid = false;
      formErrors["parentescoErr"] = "Selecciona el parentesco del familiar con el usuario.";
    }

    if (idFamiliar === usuId) {
      formIsValid = false;
      formErrors["rutErr"] = "No puede agregar al mismo usuario como familiar.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  revisaExisteRut() {
    if (!chileanRut.validate(this.state.rutFamiliar)) {
      return NotificationManager.error("El rut es inválido");
    }
    if (!this.state.parentesco) {
      return NotificationManager.error("Seleccione un parentesco");
    }

    var data = {
      rut: this.state.rutFamiliar,
      //Se pone canal 2 porque con 5 busca solo en el cesfam (pide el cesfam)
      //y se necesita que busque en cualquiera
      canal: 2,
    };
    let apiUrl = global.config.modulos.usuarios + "por_rut/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      var data = res.data;
      var estado = data.estado;
      if (estado) {
        if (data.datos["usu_id"] == this.state.usuId) {
          return NotificationManager.warning("No puedes agregar al mismo usuario como familiar");
        }

        if (data.datos["usu_estado"] == 0) {
          confirmAlert({
            title: "",
            message: "El familiar no se encuentra inscrito en HoraSalud. ¿deseas inscribirlo?",
            buttons: [
              {
                label: "Cancelar",
                className: "btnCancelar",
              },
              {
                label: "Aceptar",
                className: "btnAceptar",
                onClick: async () => {
                  const datos = { usu_estado: 1 };
                  await axios.patch(global.config.modulos.usuarios + "por_id/" + data.datos["usu_id"], datos, {
                    headers: authHeader(),
                  });
                  this.enviarFormulario(res.data.datos["usu_id"]);
                },
              },
            ],
          });
        } else {
          this.enviarFormulario(res.data.datos["usu_id"]);
        }
      } else {
        NotificationManager.error("El rut no se necuentra en HoraSalud");
      }
    });
  }

  enviarFormulario = (idFamiliar) => {
    var data = {
      gfa_usuario: this.state.usuId,
      gfa_usuario_parentesco: idFamiliar,
      gfa_parentesco: this.state.parentesco,
      gfa_usuario_panel: this.state.uspId,
      gfa_fecha_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let apiUrl = global.config.modulos.grupo_familiar + "agregar/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Familiar registrado con éxito");
        this.salir();
      } else {
        NotificationManager.info("Este Familiar ya existe en tu grupo");
      }
    });
  };

  render() {
    const { rutErr, parentescoErr } = this.state.formErrors;
    const { parentescos, GFamiliar } = this.state;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <img className="marca-agua"></img>

          <h2>Agregar familiar</h2>

          <div style={{ marginTop: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel>Rut Familiar</InputLabel>
                <input type="text" id="rutFamiliar" name="rutFamiliar" value={this.state.rutFamiliar} onChange={this.cambiarValores} placeholder="Ej. 12345678-9" className={rutErr ? "inputText showError" : "inputText"} maxLength={12} />
                {rutErr && <div style={{ color: "red", paddingBottom: 10 }}>{rutErr}</div>}
              </Grid>

              <Grid item xs={6}>
                <InputLabel>Parentescos</InputLabel>
                <Select id="parentesco" name="parentesco" value={this.state.parentesco} onChange={this.cambiarValores} displayEmpty className={parentescoErr ? " showError" : ""}>
                  <MenuItem value="" disabled>
                    <em>Seleccione parentesco</em>
                  </MenuItem>

                  {parentescos &&
                    parentescos.map((par, index) => (
                      <MenuItem value={par.par_id} key={index}>
                        {par.par_nombre}
                      </MenuItem>
                    ))}
                </Select>
                {parentescoErr && <div style={{ color: "red", paddingBottom: 10 }}>{parentescoErr}</div>}
              </Grid>
            </Grid>

            <div style={{ width: "100%", float: "left", marginTop: "32px" }}>
              <Button onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                Cancelar
              </Button>
              <Button onClick={this.revisaExisteRut} color="primary" id="btnAgregar" className="fondoVerde">
                Agregar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
