import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class GrupoFamiliarModificar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parentescos: [],
      gfaid: props.gfaid,
      parentesco: props.parentesco,
      rutFamiliar: props.rutFamiliar,

      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      open: true,
    };
  }
  componentDidMount() {
    this.traerParentescos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirActualizar = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarActualizado();
  };

  traerParentescos() {
    let apiUrl = global.config.modulos.usuarios + "parentescos/";
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        parentescos: res.data.datos,
      });
    });
  }

  validaciones = () => {
    const { parentesco } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (parentesco === "") {
      formIsValid = false;
      formErrors["parentescoErr"] = "Selecciona el parentesco del familiar con el usuario.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enviarFormulario = async () => {
    if (this.validaciones()) {
      const datos = {
        gfa_parentesco: this.state.parentesco,
        usp_id: this.state.uspId,
        usu_id: this.state.usuId,
      };

      var res = await axios.patch(global.config.modulos.grupo_familiar + "familiar_por_id/" + this.state.gfaid, datos, {
        headers: authHeader(),
      });
      var data = res.data;
      var estado = data.estado;
      if (estado) {
        NotificationManager.success(data.mensaje);
        this.salirActualizar();
      } else {
        NotificationManager.info(data.mensaje);
      }
    }
  };

  render() {
    const { parentescoErr } = this.state.formErrors;
    const { parentescos } = this.state;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <img className="marca-agua"></img>

          <h2>Modificar familiar</h2>

          <div style={{ marginTop: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel>Rut Familiar</InputLabel>
                <input type="text" id="rutFamiliar" name="rutFamiliar" value={this.state.rutFamiliar} onChange={this.cambiarValores} placeholder="Ej. 12345678-9" className={"inputText"} maxLength={12} disabled />
              </Grid>

              <Grid item xs={6}>
                <InputLabel>Parentescos</InputLabel>
                <Select id="parentesco" name="parentesco" value={this.state.parentesco} onChange={this.cambiarValores} displayEmpty className={parentescoErr ? " showError" : ""}>
                  <MenuItem value="" disabled>
                    <em>Seleccione parentesco</em>
                  </MenuItem>

                  {parentescos &&
                    parentescos.map((par, index) => (
                      <MenuItem value={par.par_id} key={index}>
                        {par.par_nombre}
                      </MenuItem>
                    ))}
                </Select>
                {parentescoErr && <div style={{ color: "red", paddingBottom: 10 }}>{parentescoErr}</div>}
              </Grid>
            </Grid>

            <div style={{ width: "100%", float: "left", marginTop: "32px" }}>
              <Button onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                Cancelar
              </Button>
              <Button onClick={this.enviarFormulario} color="primary" id="btnAgregar" className="fondoVerde">
                Modificar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
