import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { NotificationManager } from "react-notifications";

// core components
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import NativeSelect from "@material-ui/core/NativeSelect";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { confirmAlert } from "react-confirm-alert"; // Import

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModalCambioEsp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cesfam: store.getState().cesfam,
      usuario_panel: store.getState().usp,

      horario: props.horario,
      profesional: props.profesional,

      transformaciones: [],
      transformacion: [],

      open: true,
      formErrors: [],
    };
  }

  componentDidMount() {
    this.traerTransformaciones();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirExito = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarExito();
  };

  traerTransformaciones = () => {
    const apiUrl = global.config.modulos.api_transformador + "cantidad_horas_transformar_usp/";

    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          id_horario: this.state.horario,
        },
      })
      .then((res) => {
        this.setState({ transformaciones: res.data.datos }, () => {
          console.log(this.state.transformaciones);
        });
      });
  };

  validaciones() {
    const { transformacion } = this.state;

    let formIsValid = true;
    console.log("transformacion", transformacion);
    if (transformacion == "") {
      NotificationManager.warning("Debe Seleccionar un tipo de atención");
      formIsValid = false;
    }

    return formIsValid;
  }

  confirmarCambiarEspecialidad = () => {
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ que desea cambiar el tipo de atención?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => this.cambiarTipoAtencion(),
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  cambiarTipoAtencion = () => {
    const index = this.state.transformacion;
    const apiUrl = global.config.modulos.api_transformador + "transformar_hora_individual_nuevo/";

    axios
      .post(
        apiUrl,
        {
          usp_id: this.state.usuario_panel,
          id_horario: this.state.horario,
          cesfam: this.state.cesfam,
          especialidad_inicial: this.state.transformaciones[index]["especialidad_inicial"],
          especialidad_final: this.state.transformaciones[index]["especialidad_final"],
          rendimiento_inicial: this.state.transformaciones[index]["rendimiento_inicial"],
          rendimiento_final: this.state.transformaciones[index]["rendimiento_final"],
          cantidad_horas_iniciales: this.state.transformaciones[index]["cantidad_horas_iniciales"],
          cantidad_horas_final: this.state.transformaciones[index]["cantidad_final"],
          permite_pausada: true,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data["estado"]) {
          this.setState({ transformaciones: res.data["datos"] });
          this.salirExito();
          NotificationManager.success("Tipo de atención cambiado con éxito");
        } else {
          if (res.data["mensaje"]) {
            NotificationManager.error(res.data["mensaje"]);
          } else {
            NotificationManager.error("No es posible realizar el cambio de tipo de atención");
          }
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.dir(value);
    this.setState({ [name]: value });
  };

  render() {
    const { transformaciones } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" style={{ minHeight: "auto" }}>
        <DialogContent>
          {transformaciones ? (
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer className="agendar-container">
                  <GridItem xs={12} sm={12} md={12}>
                    <h2>Cambiar tipo de atención</h2>
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <InputLabel>Transformar</InputLabel>
                    <NativeSelect className={"selectModals"} defaultValue={""} name="transformacion" onChange={this.cambiarValores}>
                      <option value="">Seleccione</option>
                      {transformaciones &&
                        transformaciones.map((esp, index) => (
                          <option value={index}>
                            {esp.esp_nombre_inicial} x {esp.cantidad_horas_iniciales} ({esp.rendimiento_inicial} min) a {esp.esp_nombre_final} x {esp.cantidad_final} ({esp.rendimiento_final} min)
                          </option>
                        ))}
                    </NativeSelect>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button variant="contained" className="btnCargaHorario fondoRojo" style={{ backgroundColor: "#ea2828" }} onClick={this.salir}>
                  Cancelar
                </Button>
                <Button variant="contained" className="btnCargaHorario fondoVerde" onClick={this.confirmarCambiarEspecialidad}>
                  Cambiar tipo de atención
                </Button>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer className="agendar-container">
                  <GridItem xs={12} sm={12} md={12}>
                    <h2>Cambiar tipo de atención</h2>
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <InputLabel>No posee configuraciones para transformar esta atención</InputLabel>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button variant="contained" className="btnCargaHorario fondoRojo" style={{ backgroundColor: "#ea2828" }} onClick={this.salir}>
                  Cancelar
                </Button>
                <Button disabled variant="contained" className="btnCargaHorario " style={{ backgroundColor: "#a7a7a7" }} onClick={this.confirmarCambiarEspecialidad}>
                  Cambiar tipo de atención
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}
