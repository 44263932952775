import React, { useLayoutEffect, useRef } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";

const COLORS = ["#1b1b77", "#8dd4ff", "#5656cc", "#57b2ea", "#b0b0ff", "#226cbc"];

export default function GraficoBarraDemanda(props) {
  console.log(props);
  useLayoutEffect(() => {
    var root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = am5locales_es_ES;

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    chart.get("colors").set("colors", [am5.color(COLORS[0]), am5.color(COLORS[1]), am5.color(COLORS[2]), am5.color(COLORS[3]), am5.color(COLORS[4]), am5.color(COLORS[5])]);

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: props.data_graph,
      dataFields: {
        date: "Fecha",
        usp_nombre: "Módulo",
        alta_dem: "Demanda",
      },
      dataFieldsOrder: ["Fecha", "Módulo", "Demanda"],
      pngOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      pdfOptions: {
        addURL: false,
        includeData: true,
      },
      jsonOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      title: "Tickets",
      filePrefix: props.filename,
    });

    exporting.events.on("pdfdocready", function (event) {
      event.doc.content.unshift({
        text: props.filename,
        margin: [0, 30],
        style: {
          fontSize: 25,
          bold: true,
        },
      });
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "date",
      })
    );
    xAxis.data.setAll(props.data);

    function createSeries(name) {
      let toolt = am5.Tooltip.new(root, {});

      toolt.events.on("click", function (ev) {
        var datos = ev.target._dataItem.dataContext;
        props.handleBarCLick(datos);
      });

      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name,
          categoryXField: "date",
          clustered: true,
          tooltip: toolt,
        })
      );

      series.columns.template.events.on("click", function (ev) {
        var datos = ev.target._dataItem.dataContext;
        props.handleBarCLick(datos);
      });

      series.get("tooltip").label.set("text", "[bold]{name}\n[bold]Total: {valueY}");

      series.data.setAll(props.data);
    }

    for (var i = 0; i < props.opciones.length; i++) {
      createSeries(props.opciones[i]);
    }

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);
    chart.appear(1000, 100);

    var modal = am5.Modal.new(root, {
      content: "No se encontraron datos para estos filtros",
    });

    if (props.data.length < 1) {
      modal.open();
    } else {
      modal.close();
    }

    return () => {
      root.dispose();
    };
  }, []);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
}
