import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";
import moment from "moment";
import axios from "axios";
import { NotificationManager } from "react-notifications";
// core components
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import MultiSelect from "react-multi-select-component";
import GridItem from "components/grid/GridItem.js";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// estilos
import FuncionarioCSS from "assets/css/funcionario.module.css";
import "./../../assets/css/funcionario.css";

export default class AgregarUsuarioPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombre: "",
      apellido: "",
      correo: "",
      rut: this.props.rut,
      usuario: "",
      contrasena: "",
      contrasenaRepetir: "",
      telefono: "",
      cesfams: [],
      GAcceso: 0,
      cesfamsSeleccionados: [],
      seleccionadosCesfam: "",
      rol: "",
      roles: [],
      stringMultiCesfams: {
        selectSomeItems: "Seleccionar cesfams...",
        allItemsAreSelected: "Todos están seleccionados .",
        selectAll: "Seleccionar todo",
        search: "Buscar ",
        clearSearch: "Borrar búsqueda",
      },
      formErrors: {},
      cliente: store.getState().cliente,
      open: true,
      herramientas: store.getState().herramientas,
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
    };

    this.salir = () => {
      this.setState({
        open: false,
      });
      props.ocultar();
    };

    this.traerRoles();
    this.traerCesfams();
    this.initialState = this.state;
  }

  mostrarAlerta(mensaje) {
    NotificationManager.success(mensaje);
  }

  validaciones() {
    const { nombre, apellido, correo, rut, contrasena, contrasenaRepetir, cesfamsSeleccionados, existe } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!nombre) {
      formIsValid = false;
      formErrors["nombreErr"] = "Escribe el nombre.";
    }

    if (!apellido) {
      formIsValid = false;
      formErrors["apellidoErr"] = "Escribe el apellido.";
    }

    if (correo != "") {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correo)) {
        formIsValid = false;
        formErrors["correoErr"] = "El correo es invalido.";
      }
    } else if (!correo) {
      formIsValid = false;
      formErrors["correoErr"] = "Escribe un correo.";
    }

    if (!rut) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut)) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut válido.";
    }

    if (!contrasena) {
      formIsValid = false;
      formErrors["contrasenaErr"] = "Escribe la contraseña.";
    }

    if (!contrasenaRepetir) {
      formIsValid = false;
      formErrors["contrasenaRepetirErr"] = "Escribe la contraseña.";
    } else if (contrasena != contrasenaRepetir) {
      formIsValid = false;
      formErrors["contrasenaRepetirErr"] = "Debe ser la misma contraseña.";
    }

    if (cesfamsSeleccionados.length === 0) {
      formIsValid = false;
      formErrors["cesfamsSeleccionadosErr"] = "Selecciona los cesfam que visualizara.";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enviarFormulario = (e) => {
    e.preventDefault();
    if (this.validaciones()) {
      var cesfamsSelect = "";
      var cesfams = this.state.cesfamsSeleccionados;
      for (var i = 0; i < cesfams.length; i++) {
        cesfamsSelect += cesfams[i]["value"] + ",";
      }
      cesfamsSelect = cesfamsSelect.substring(0, cesfamsSelect.length - 1);

      var data = {
        usp_nombre: this.state.nombre,
        usp_apellido_p: this.state.apellido,
        usp_email: this.state.correo,
        usp_rut: this.state.rut,
        usp_usuario: this.state.rut,
        usp_password: this.state.contrasena,
        usp_estado: 1,
        usp_comuna: store.getState().cliente,
        usp_cesfam: this.state.cesfamsSeleccionados[0]["value"],
        usp_rol: this.state.rol,
        usp_cesfams_visibles: cesfamsSelect,
        usp_fecha_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
        usp_telefono: this.state.telefono,
        usp_tipo: 2,
        GAcceso: this.state.GAcceso,
      };
      let apiUrl = global.config.modulos.usuariospanel + "agregar/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        if (res.data.estado) {
          // TIPO 7 : ACCIONES EN GESTOR ACCESOS
          let tipo = 7;
          let mensaje = "El usuariopanel " + this.state.uspId + " Creo el acceso de " + res.data.datos.usp_id + " Con los siguientes datos: " + JSON.stringify(data);

          this.agregarAccion(mensaje, this.state.uspId, res.data.datos.usp_id, tipo);

          this.mostrarAlerta("Acceso agregado con éxito");
          this.salir();
        } else {
          this.mostrarAlerta("Ya existe un acceso asociado a este rut");
        }
      });
    }
  };

  agregarAccion = (accion, uspId, usuario, tipo) => {
    const datos = {
      accion: accion,
      uspId: uspId,
      usuario: usuario,
      tipo: tipo,
    };
    let apiUrl = global.config.modulos.usuariospanel + "agregar_accion/";
    axios
      .post(apiUrl, datos, {
        headers: authHeader(),
      })
      .then((res) => {});
  };

  traerCesfams() {
    const apiUrl = global.config.modulos.cesfams + "por_comuna/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          id: this.state.cliente,
        },
      })
      .then((res) => {
        let cesResult = [];
        if (this.state.herramientas.includes("H62")) {
          for (var i = 0; i < res.data["datos"].length; i++) {
            let ces = {
              value: res.data["datos"][i]["ces_id"],
              label: res.data["datos"][i]["ces_nombre"],
            };
            cesResult.push(ces);
          }
        } else if (this.state.herramientas.includes("H63")) {
          for (var i = 0; i < res.data["datos"].length; i++) {
            if (res.data["datos"][i]["ces_id"] == this.state.cesfam) {
              let ces = {
                value: res.data["datos"][i]["ces_id"],
                label: res.data["datos"][i]["ces_nombre"],
              };
              cesResult.push(ces);
            }
          }
        }
        this.setState({
          cesfams: cesResult,
        });
      });
  }

  traerRoles() {
    const apiUrl = global.config.modulos.roles + "por_comuna/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          comuna: this.state.cliente,
        },
      })
      .then((res) => {
        this.setState({
          roles: res.data["datos"],
          rol: res.data["datos"][0]["rol_id"],
        });
      });
  }
  render() {
    const { nombreErr, apellidoErr, correoErr, rutErr, contrasenaErr, contrasenaRepetirErr, cesfamsSeleccionadosErr, rolesErr, telefonoErr } = this.state.formErrors;
    const { cesfams, cesfamsSeleccionados, roles, rol, stringMultiCesfams } = this.state;

    const cambioRol = (event) => {
      this.setState({
        rol: event.target.value,
      });
    };

    const cambioGestorAcceso = (event) => {
      this.setState({
        GAcceso: event.target.value,
      });
    };
    const cambioCesfam = (cesfamsSeleccionados) => {
      this.setState({ cesfamsSeleccionados });
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" className="DialogFuncionarios" aria-describedby="alert-dialog-description">
        <DialogContent>
          <GridItem xs={12} sm={12} md={12} style={{ width: "900px" }}>
            <h1 style={{ paddingLeft: "30px", color: "#818181" }}>Crear acceso</h1>
            <br />
            <br />
            <br />
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                padding: "0px",
                marginBottom: "40px",
              }}
            >
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Nombre</InputLabel>

                <input type="text" name="nombre" value={this.state.nombre} onChange={this.cambiarValores} placeholder="" className={nombreErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                {nombreErr && <div className={FuncionarioCSS.mensajeErr}>{nombreErr}</div>}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Apellido</InputLabel>
                <input type="text" name="apellido" value={this.state.apellido} onChange={this.cambiarValores} placeholder="" className={apellidoErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                {apellidoErr && <div className={FuncionarioCSS.mensajeErr}>{apellidoErr}</div>}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Rut</InputLabel>
                <input type="text" name="rut" maxLength={12} value={this.state.rut} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={rutErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                {rutErr && <div className={FuncionarioCSS.mensajeErr}>{rutErr}</div>}
              </GridItem>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                marginBottom: "40px",
              }}
            >
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Correo</InputLabel>
                <input type="text" name="correo" value={this.state.correo} onChange={this.cambiarValores} placeholder="Ejemplo admin@horasalud.cl" className={correoErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                {correoErr && <div className={FuncionarioCSS.mensajeErr}>{correoErr}</div>}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Télefono</InputLabel>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                  }}
                  id="standard-basic"
                  placeholder=""
                  value={this.state.telefono}
                  style={{ width: "100%" }}
                  type="number"
                  name="telefono"
                  error={telefonoErr ? true : false}
                  onChange={this.cambiarValores}
                  onInput={(e) => {
                    e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                  }}
                />
                {telefonoErr && <div className={FuncionarioCSS.mensajeErr}>{telefonoErr}</div>}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Rol</InputLabel>
                <Select id="rol" name="rol" value={rol} onChange={cambioRol} displayEmpty style={{ width: "100%" }} className={rolesErr ? " showError" : ""}>
                  <MenuItem value="" disabled>
                    <em>Seleccione rol</em>
                  </MenuItem>

                  {roles &&
                    roles.map((rl, index) => (
                      <MenuItem value={rl.rol_id} key={index}>
                        {rl.rol_nombre}
                      </MenuItem>
                    ))}
                </Select>
              </GridItem>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                marginBottom: "40px",
              }}
            >
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Contraseña</InputLabel>
                <input type="password" name="contrasena" value={this.state.contrasena} onChange={this.cambiarValores} placeholder="" className={contrasenaErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} autoComplete="off" />
                {contrasenaErr ? <div className={FuncionarioCSS.mensajeErr}>{contrasenaErr}</div> : <div></div>}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Confirmar Contraseña</InputLabel>
                <input type="password" name="contrasenaRepetir" value={this.state.contrasenaRepetir} onChange={this.cambiarValores} placeholder="" className={contrasenaRepetirErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} autoComplete="off" />
                {contrasenaRepetirErr && <div className={FuncionarioCSS.mensajeErr}>{contrasenaRepetirErr}</div>}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <InputLabel>Cesfams habilitados</InputLabel>
                <MultiSelect className="funcionario_multi_selector" options={cesfams} value={cesfamsSeleccionados} onChange={cambioCesfam} labelledBy={"Select"} overrideStrings={stringMultiCesfams} />

                {cesfamsSeleccionadosErr && <div className={FuncionarioCSS.mensajeErr}>{cesfamsSeleccionadosErr}</div>}
              </GridItem>
            </GridItem>
            {this.state.herramientas.includes("H64") && (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  padding: "0 !important",
                  display: "flex",
                  marginBottom: "40px",
                  alignItems: "flex-end",
                }}
              >
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Habilitar Gestor Acceso</InputLabel>

                  <Select id="rol" name="rol" value={this.state.GAcceso} onChange={cambioGestorAcceso} style={{ width: "100%" }} displayEmpty>
                    <MenuItem value="" disabled>
                      <em>Seleccione permiso</em>
                    </MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={109}>Accesos de CESFAM</MenuItem>
                    <MenuItem value={108}>Accesos de Comuna</MenuItem>
                  </Select>
                </GridItem>
              </GridItem>
            )}
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <button onClick={this.salir} style={{ width: "150px" }} className={FuncionarioCSS.boton}>
                Cancelar
              </button>
              <button onClick={this.enviarFormulario} style={{ width: "150px" }} className={FuncionarioCSS.boton}>
                Agregar
              </button>
            </div>
          </GridItem>
        </DialogContent>
      </Dialog>
    );
  }
}
