import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import chileanRut from "chilean-rut";
import { LiveKitRoom, DisplayOptions, DisplayContext, ParticipantView } from "@livekit/react-components";

import { PropTypes } from "prop-types";
import { confirmAlert } from "react-confirm-alert"; // Import
import { connect } from "react-redux";
import { store } from "../../store/index";

import cortar from "assets/img/video_llamada/cortar.svg";
import recargar from "assets/img/video_llamada/recargar.svg";
import ausente from "assets/img/video_llamada/ausente.svg";

import adjuntar_archivo from "assets/img/video_llamada/adjuntar_archivo.svg";
import escribir_mensaje from "assets/img/video_llamada/escribir_mensaje.svg";
import escribir_observacion from "assets/img/video_llamada/escribir_observacion.svg";
import indicaciones from "assets/img/video_llamada/indicaciones.svg";
import logo_v2 from "assets/img/video_llamada/logo_v2.svg";
import observaciones from "assets/img/video_llamada/observaciones.svg";
import reservar_llamada from "assets/img/video_llamada/reservar_llamada.svg";
import ver_archivos from "assets/img/video_llamada/ver_archivos.svg";

import fondo_escribir from "assets/img/video_llamada/fondo_escribir.png";
import fondo_datos from "assets/img/video_llamada/fondo_datos.svg";
import datos_pacientes from "assets/img/video_llamada/datos_pacientes.svg";
import fondo_footer from "assets/img/video_llamada/footerVideo.svg";

import checkActivo from "assets/img/video_llamada/check.svg";
import cerrar_popup from "assets/img/video_llamada/cerrar popup.svg";
import descargar_archivo_blanco from "assets/img/video_llamada/descargar_archivo_blanco.svg";

import icono_guardar from "assets/img/usuarios/icono_anunciar_llegada.svg";
import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import icono_eliminar from "assets/img/usuarios/icono_eliminar.svg";

import AgendarUsuario from "./ModalAgendarHoraVD";

import { socketVideollamada, socketRTC } from "../../service/socket";

import { showLoader, hideLoader } from "../../store/actions/actions";

import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";

import moment from "moment";
import "moment/locale/es";
import { Room, RoomEvent, setLogLevel, VideoPresets, ControlsProps } from "livekit-client";

import "react-confirm-alert/src/react-confirm-alert.css";

var countdown;
var countdownAtr;
var roomStart = new Date().getTime();
var activeCall;

var fechaBuscaHoras = moment().format("YYYY-MM-DD");
var clasificacionEsp = 0;

var urlArchivoDescargar = "";
var nombreArchivoDescargar = "";

class VideoLlamada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: true,
      video: true,

      firma: false,

      bridge: "",
      correo: "",
      ultimoTelefono: "",

      modCorreo: false,
      modTelefono: false,

      token: window.localStorage.getItem("token"),
      nombreSala: window.localStorage.getItem("nombreSala"),
      id_sala: window.localStorage.getItem("idSala"),
      tipo_gestion: window.localStorage.getItem("tipoGestion"),

      ageId: window.localStorage.getItem("ageId"),
      usu_id: window.localStorage.getItem("usuId"),
      proId: store.getState().id_medico,
      proPerfil: "1",
      cesfam: window.localStorage.getItem("cesfam"),
      canal: 5,

      datosUsuario: "",
      infoAcom: "",
      contenedor: "",
      tablaObservaciones: "",
      detalleObservacion: "",
      timerC: "",
      countdownC: "",
      btnActivo: false,
      pantalla: 1,
      indicacion: "",

      uspId: store.getState().usp,
    };
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  componentDidMount() {
    this.props.dispatch(hideLoader());
    this.traerDatos();

    var nombreSala = window.localStorage.getItem("nombreSala");
    socketRTC.on("recibir_archivo", (data) => {
      if (nombreSala == data.sala && 1 == data.tipo) {
        this.revisarArchivoEnviado();
      }
    });
    socketRTC.on("recibir_mensaje", (data) => {
      if (nombreSala == data.sala && 1 == data.tipo) {
        this.mensajesNoLeidos();
      }
    });
    socketRTC.on("terminar_llamada", (data) => {
      if (nombreSala == data.sala && 1 == data.tipo) {
        this.alertaFinalizoLlamada();
      }
    });
    socketRTC.on("sala_espera", (data) => {
      if (this.state.proId == data.profesional) {
        this.traerDatos();
      }
    });
    socketRTC.on("sala_espera_pendiente", (data) => {
      if (this.state.proId == data.profesional) {
        if (this.state.ageId == data.agenda) {
          confirmAlert({
            title: "¡Atención!",
            message: "El paciente acaba de ingresar a la sala de espera",
            buttons: [
              {
                label: "Contactar",
                onClick: this.volverAContactar.bind(this),
              },
            ],
          });
        } else {
          confirmAlert({
            title: "¡Atención!",
            message: "El siguiente paciente se encuentra esperando para ser atendido.",
            buttons: [
              {
                label: "Aceptar",
              },
            ],
          });

          if (document.getElementById("infoAtrasado").style.display != "block") {
            this.iniciarTAtraso(null);
            this.traerDatos();
          }
        }
      }
    });
    this.datosUsuarios();
  }

  componentWillUnmount() {
    clearInterval(countdown);
    clearInterval(countdownAtr);
  }

  mensajesNoLeidos = async () => {
    var datos = { nombreSala: this.state.nombreSala, tipo: 1 };
    var res = await axios.get(global.config.modulos.videollamada + "mensaje_no_leido/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    if (data["estado"]) {
      var idMensaje = data["vlm_id"];

      const datos = { vlm_estado: 2 };
      axios
        .patch(global.config.modulos.videollamada + "mensaje/" + idMensaje, datos, { headers: authHeader() })
        .then(function (res) {})
        .catch(function (err) {
          console.log(err);
        });

      document.getElementById("MensajeEnviadoM").innerHTML = data["vlm_mensaje"];
      this.setState({ contenedorPopM: "info9" });
    }
  };

  alertaFinalizoLlamada = async () => {
    activeCall = false;

    const datos2 = { sala: this.state.id_sala, accion: 2 };
    await axios
      .post(global.config.modulos.videollamada + "registrar_logs/", datos2, { headers: authHeader() })
      .then(function (res) {})
      .catch(function (err) {});

    const datos = {
      titulo: "termino_llamada_usuario",
      canal: this.state.canal,
    };
    var res = await axios.get(global.config.modulos.aplicaciones + "mensajes_por_titulo/", {
      headers: authHeader(),
      params: datos,
    });
    var data = res.data;
    console.log(data);
    confirmAlert({
      title: data["msg_mensaje"],
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  muteAudio = () => {
    document.querySelectorAll("._hRq5k._3Bgvz")[0].click();
    this.setState({ audio: !this.state.audio });
  };

  muteVideo = () => {
    document.querySelectorAll("._hRq5k._3Bgvz")[1].click();
    this.setState({ video: !this.state.video });
  };

  datosUsuarios = async () => {
    await axios.get(global.config.modulos.usuarios + "por_id/" + this.state.usu_id, { headers: authHeader() }).then(async (res) => {
      var data = res.data;

      var nombre = data.datos["usu_nombre"] + " " + data.datos["usu_apellido_p"];
      var edad = data.datos["usu_edad"];
      var correo = data.datos["usu_correo_electronico"];
      var ultimoTelefono = data.datos["ultimoTelefono"];

      this.setState({
        nombre: nombre,
        edad: edad,
        correo: correo,
        correoMod: correo,
        ultimoTelefonoMod: ultimoTelefono,
        ultimoTelefono: ultimoTelefono,
      });

      /*  var rend = await this.obtenerRend();
      var start = rend.hora_at;
      var rendi = rend.rendimiento; */

      //await this.iniciarTRestante(start, rendi);

      var es_acomp = await this.buscarAcomp();
      if (edad < 18 && !es_acomp.estado) {
        this.estaacomp();
      } else if (edad < 18 && es_acomp.estado) {
        this.acompInfo();
      } else if (edad >= 18) {
        this.sinAcompanante();
      }
    });
  };

  estaacomp = () => {
    var datos = [];
    datos.push(
      <>
        <label className="DataAlerta">¿El paciente se encuentra acompañado por un mayor de 18 años?</label>
        <br />
        <button className="boton botonVerde" onClick={this.acompIngresar}>
          Si
        </button>
        <button className="boton botonRojo" onClick={this.noacomp}>
          No
        </button>
      </>
    );
    this.setState({ infoAcom: datos });
    document.getElementById("infoacom").style.display = "initial";
  };

  noacomp = () => {
    var datos = [];
    datos.push(
      <>
        <label className="DataAlerta">Se requiere que un menor de edad o persona en situación de discapacidad sea atendido con un acompañante, ¿esta seguro que desea continuar la teleconsulta?</label>
        <br />
        <button className="boton botonVerde" onClick={this.sinAcompanante.bind(this)}>
          Si
        </button>
        <button className="boton botonRojo" onClick={this.cortar.bind(this)}>
          No
        </button>
      </>
    );
    this.setState({ infoAcom: datos });
  };

  sinAcompanante = () => {
    document.getElementById("infoacom").style.display = "none";
  };

  acompIngresar = async () => {
    var acompData = await this.acompParentesco();
    console.log(acompData);
    var datos = [];
    datos.push(
      <>
        <label className="DataAlerta">Ingrese el rut</label>
        <br />
        <label className="DataAlerta">del acompañante </label>
        <br />
        <input type="text" maxLength="9" className="acompTextArea" id="rutAcom"></input>
        <br />
        <button className="boton botonVerde" id="validarAcomp" onClick={this.validarAcomp}>
          Validar
        </button>

        <div id="validacionAcomp" style={{ display: "none" }}>
          <label className="DataAlerta">Ingrese el nombre</label>
          <br />
          <label className="DataAlerta">del acompañante </label>
          <br />
          <input type="text" className="acompTextArea" id="nombreAcom"></input>
          <br />
          <label className="DataAlerta">Seleccione parentesco</label>
          <br />
          <select className="acompSelect" name="parentesco" id="parentesco">
            {acompData.datos.map((aco, index) => (
              <option value={aco.vlp_id}>{aco.vlp_parentesco}</option>
            ))}
          </select>
          <br />
          <button className="boton botonVerde" onClick={this.agregarAcomp}>
            Aceptar
          </button>
          <button className="boton botonRojo" onClick={this.estaacomp}>
            Cancelar
          </button>
        </div>
      </>
    );
    this.setState({ infoAcom: datos });
  };

  validarAcomp = async () => {
    var rutAcom = document.getElementById("rutAcom").value;
    if (chileanRut.validate(rutAcom)) {
      var data = {
        rut: rutAcom,
      };
      var res = await axios.post(global.config.modulos.aplicaciones + "usuario_por_rut/", data, { headers: authHeader() });
      document.getElementById("validarAcomp").style.display = "none";
      document.getElementById("validacionAcomp").style.display = "initial";

      if (res.data.estado) {
        document.getElementById("nombreAcom").value = res.data.usu_nombre;
      }
    } else {
      this.mostrarAlerta("Debe ingresar un rut válido");
    }
  };

  agregarAcomp = async () => {
    var rutAcom = document.getElementById("rutAcom").value;
    var nombreAcom = document.getElementById("nombreAcom").value;
    var parent = document.getElementById("parentesco").value;

    if (nombreAcom != "" && chileanRut.validate(rutAcom)) {
      var data = {
        vlac_rut_acom: rutAcom,
        vlac_nombre_acom: nombreAcom,
        vlac_parentesco: parent,
        vlac_agenda: this.state.ageId,
      };

      var res = await axios.post(global.config.modulos.videollamada + "guardar_acomp/", data, { headers: authHeader() });
      if (res.data.estado) {
        this.acompInfo();
      }
    } else {
      this.mostrarAlerta("Debe ingresar el nombre del acompañante");
    }
  };

  acompParentesco = async () => {
    var respuesta = {};
    await axios.get(global.config.modulos.videollamada + "video_llamada_parentescos/", { headers: authHeader() }).then(function (res) {
      respuesta = res;
    });
    return respuesta.data;
  };

  acompInfo = async () => {
    var acompData = await this.buscarAcomp();
    var datos = [];
    datos.push(
      <>
        <label className="datosPaciente">
          <b>Datos del acompañante</b>
          <br />
          <b>RUT: </b>
          {acompData.acomp["vlac_rut_acom"]}
          <br />
          <b>Nombre: </b>
          {acompData.acomp["vlac_nombre_acom"]}
          <br />
          <b>Parentesco: </b>
          {acompData.parent}
        </label>
      </>
    );
    this.setState({ infoAcom: datos });
    document.getElementById("infoacom").style.display = "initial";
  };

  obtenerRend = async () => {
    var datos = { agenda: this.state.ageId };
    var respuesta = {};
    await axios
      .get(global.config.modulos.videollamada + "rendimiento_por_agenda/", {
        headers: authHeader(),
        params: datos,
      })
      .then(function (res) {
        respuesta = res;
      });

    return respuesta.data;
  };

  buscarAcomp = async () => {
    var datos = { agenda: this.state.ageId };
    var respuesta = {};
    await axios
      .get(global.config.modulos.videollamada + "acomp_por_agenda/", {
        headers: authHeader(),
        params: datos,
      })
      .then(function (res) {
        respuesta = res;
      });
    return respuesta.data;
  };

  iniciarTRestante = async (start, rend) => {
    var hora = new Date(start);
    var limit = new Date(hora.getTime() + rend * 60000);
    countdown = setInterval(function () {
      var now = new Date().getTime();
      var remaining = limit - now;
      var r_minute = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
      var r_second = Math.floor((remaining % (1000 * 60)) / 1000);
      var tx_r_second = r_second < 10 ? "0" + r_second + " min" : r_second + " min";
      var tx_r_minute = r_minute < 10 ? "0" + r_minute : r_minute;

      var remain_timer = "<label style='color: red;margin:0px'><b>" + tx_r_minute + ":" + tx_r_second + "</b></label><br><label style='color: red;'>Restante</label>";

      if (activeCall) {
        var elapsed = (roomStart - now) * -1;
        var e_minute = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
        var e_second = Math.floor((elapsed % (1000 * 60)) / 1000);
        var tx_e_second = e_second < 10 ? "0" + e_second + " min" : e_second + " min";
        var tx_e_minute = e_minute < 10 ? "0" + e_minute : e_minute;
        var elapsed_timer = "<label style='color: white;margin:0px'><b>" + tx_e_minute + ":" + tx_e_second + "</label></b><br><label style='color: white;'>Transcurrido</label>";

        document.getElementById("call-timer").innerHTML = elapsed_timer;
      }
      if (r_minute >= 0 && r_second >= 0) {
        document.getElementById("call-countdown").innerHTML = remain_timer;
      } else {
        remain_timer = "<label style='color: red;margin:0px'><b>00:00 min</b></label><br><label style='color: red;'>Restante</label>";
        document.getElementById("call-countdown").innerHTML = remain_timer;
      }
    }, 1000);
  };

  showTimers = () => {
    axios
      .get(global.config.modulos.profesionales + "por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam, { headers: authHeader() })
      .then(function (res) {
        var data = res.data;
        var estado = data.estado;
        if (estado) {
          if (data.pro_tiempo_restante == 1) {
            document.getElementById("call-countdown").style.display = "block";
          }
          if (data.pro_tiempo_transcurrido == 1) {
            document.getElementById("call-timer").style.display = "block";
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  hideTimers = () => {
    document.getElementById("call-countdown").style.display = "none";
    document.getElementById("call-timer").style.display = "none";
  };

  cortar = () => {
    const options = {
      method: "PATCH",
      data: { vls_estado: 2 },
      headers: authHeader(),
    };
    axios(global.config.modulos.videollamada + "sala_por_id/" + this.state.id_sala, options)
      .then(async (res) => {
        socketRTC.emit("terminar_llamada", {
          sala: this.state.nombreSala,
          tipo: 2,
        });

        const options2 = {
          method: "POST",
          data: {
            agenda: this.state.ageId,
            estadoHora: 7,
          },
          headers: authHeader(),
        };
        await axios(global.config.modulos.agendamientos + "cambio_estado_agenda/", options2);
        window.location.replace("/pacientes");
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  recargarPagina = () => {
    this.volverAContactar();

    socketRTC.emit("recargar_conexion", {
      sala: this.state.nombreSala,
      tipo: 2,
    });

    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  usuarioAusente = () => {
    const datos = { vls_estado: 2 };
    axios
      .patch(global.config.modulos.videollamada + "sala_por_id/" + this.state.id_sala, datos, { headers: authHeader() })
      .then(async (res) => {
        const datos = {
          agenda: this.state.ageId,
          estadoHora: 9,
        };
        await axios.post(global.config.modulos.agendamientos + "cambio_estado_agenda/", datos, { headers: authHeader() });
        this.props.history.push("/pacientes");
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  abrirModalObservaciones = () => {
    if (this.state.contenedor == "info2") {
      this.setState({ contenedor: "" });
    } else {
      this.setState({ contenedor: "info2" });
    }
  };

  abrirModalObservacionesHistorial = async () => {
    var datos = {
      nombreSala: this.state.nombreSala,
      perfil: this.state.proPerfil,
      agenda: this.state.ageId,
    };
    var res = await axios.get(global.config.modulos.videollamada + "observaciones/", {
      headers: authHeader(),
      params: datos,
    });
    var data = res.data;
    if (data["observaciones"].length > 0) {
      var datos = [];
      var observaciones = data["observaciones"];
      for (var i = 0; i < observaciones.length; i++) {
        var fechaIngreso = observaciones[i]["vlo_fecha_registro"];
        var arr = fechaIngreso.split("T");
        var fecha = arr[0].split("-");
        var hora = arr[1];

        var ano = fecha[0];
        var mes = fecha[1];
        var dia = fecha[2];

        var fechaFormateada = dia + "-" + mes + "-" + ano;
        datos.push(
          <tr>
            <td>
              <center>{fechaFormateada}</center>
            </td>
            <td>
              <center>{observaciones[i]["nombre_profesional"]}</center>
            </td>
            <td>
              <center>
                <button className="botonesTabla" onClick={this.abrirModalDetalleObservacion.bind(this, observaciones[i]["vlo_id"])}>
                  Ver más
                </button>
              </center>
            </td>
          </tr>
        );
      }
      this.setState({ tablaObservaciones: datos });
      if (this.state.contenedor == "info4") {
        this.setState({ contenedor: "" });
      } else {
        this.setState({ contenedor: "info4" });
      }
    } else {
      this.mostrarAlerta("No hay observaciones ingresadas.");
    }
  };

  abrirModalDetalleObservacion = async (observacion) => {
    var res = await axios.get(global.config.modulos.videollamada + "observacion_por_id/" + observacion, { headers: authHeader() });
    var data = res.data;

    var fechaIngreso = data["vlo_fecha_registro"];
    var area = data["area"];
    var realizada = data["nombre_profesional"];
    var observacion = data["vlo_observacion"];

    var arr = fechaIngreso.split("T");
    var fecha = arr[0].split("-");
    var hora = arr[1];

    var ano = fecha[0];
    var mes = fecha[1];
    var dia = fecha[2];

    var fechaFormateada = dia + "-" + mes + "-" + ano;

    var datos = [];
    datos.push(
      <>
        <label>
          <strong>Fecha:</strong> {fechaFormateada}
        </label>
        <br />
        <label>
          <strong>Area:</strong>
          {area}
        </label>
        <br />
        <label>
          <strong>Realizada por:</strong>
          {realizada}
        </label>
        <br />
        <label>
          <strong>Observación:</strong>
          <br />
          <div className="observacionDetalle">{observacion}</div>
        </label>
        <div className="col-12" style={{ textAlign: "center" }}>
          <button className="btnCerrar" onClick={this.cerrarContenedores}>
            Cerrar
          </button>
        </div>
      </>
    );

    this.setState({ detalleObservacion: datos });
    this.setState({ contenedor: "info8" });
  };

  abrirModalMensaje = () => {
    if (this.state.contenedor == "info6") {
      this.setState({ contenedor: "" });
    } else {
      this.setState({ contenedor: "info6" });
    }
  };

  guardarMensaje = async () => {
    var mensaje = document.getElementById("txtMensaje").value;

    if (mensaje == "") {
      this.mostrarAlerta("Ingresa el mensaje");
    } else {
      const datos = {
        nombreSala: this.state.nombreSala,
        mensaje: mensaje,
        tipo: 2,
      };
      var res = await axios.post(global.config.modulos.videollamada + "guardar_mensaje/", datos, { headers: authHeader() });

      var data = res.data;
      document.getElementById("txtMensaje").value = "";
      this.cerrarContenedores();
      socketRTC.emit("enviar_mensaje", {
        sala: this.state.nombreSala,
        tipo: 2,
      });
      this.mostrarAlerta(data["mensaje"]);
    }
  };

  guardarObservacion = async () => {
    var observacion = document.getElementById("txtObservaciones").value;
    if (observacion == "") {
      this.mostrarAlerta("Ingrese la observación");
    } else {
      const datos = {
        profesional: this.state.proId,
        usuario: this.state.usu_id,
        observacion: observacion,
        tipo_atencion: this.state.proPerfil,
      };
      var res = await axios.post(global.config.modulos.videollamada + "guardar_observacion/", datos, { headers: authHeader() });

      var data = res.data;
      document.getElementById("txtObservaciones").value = "";
      this.cerrarContenedores();
      this.mostrarAlerta(data["mensaje"]);
    }
  };

  enviarMensajeRespuesta = async () => {
    var mensaje = document.getElementById("mensajePopup").value;
    if (mensaje == "") {
      this.mostrarAlerta("Ingresa el mensaje");
    } else {
      const datos = {
        nombreSala: this.state.nombreSala,
        mensaje: mensaje,
        tipo: 2,
      };
      var res = await axios.post(global.config.modulos.videollamada + "guardar_mensaje/", datos, { headers: authHeader() });
      var data = res.data;
      document.getElementById("mensajePopup").value = "";
      socketRTC.emit("enviar_mensaje", {
        sala: this.state.nombreSala,
        tipo: 2,
      });
      this.mostrarAlerta(data["mensaje"]);
      this.cerrarMensajePop();
    }
  };

  cerrarContenedores = () => {
    this.setState({ contenedor: "" });
  };

  abrirReservar = () => {
    if (this.state.contenedor == "info7") {
      this.setState({ contenedor: "" });
      this.cerrarModalAgendamiento();
    } else {
      this.setState({ contenedor: "info7" });
    }

    axios
      .get(global.config.modulos.profesionales + "especialidades_por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam, { headers: authHeader() })
      .then(function (res) {
        var data = res.data;

        var estado = data["estado"];
        if (estado) {
          var selector = "";
          var especialidades = data["datos"];
          var conteoEspecialidades = especialidades.length;

          selector += "<option value='0'>Seleccionar</option>";
          for (var i = 0; i < conteoEspecialidades; i++) {
            selector += "<option value='" + especialidades[i]["esp_sigla"] + "," + especialidades[i]["esp_clasificacion"] + "'>" + especialidades[i]["esp_nombre"] + "</option>";
          }
          document.getElementById("selectEspecialidad").innerHTML = selector;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  cerrarMensajePop = () => {
    this.setState({ contenedorPopM: "" });
  };

  cambioEspecialidad = () => {
    var datos = document.getElementById("selectEspecialidad").value;
    if (datos == 0) {
      document.getElementById("contenedorTipoAtencion").style.display = "none";
      document.getElementById("contenedorHoras").style.display = "none";

      document.getElementById("contenedorCanalPreferido").style.display = "none";
      document.getElementById("canalPreferido").value = 1;

      document.getElementById("contenedorTelefonosUsuario").style.display = "none";
      document.getElementById("telefonoN").style.display = "none";
      document.getElementById("telefonoN").value = "";
    } else {
      this.cerrarModalAgendamiento();

      var arr = datos.split(",");
      var sigla = arr[0];
      this.traerTipoAtencion(sigla);
    }
  };

  traerTipoAtencion = async (sigla) => {
    const datos = {
      usuid: this.state.usu_id,
      canal: this.state.canal,
      sigla: sigla,
    };
    var res = await axios(global.config.modulos.especialidades + "buscar/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    var estado = data["estado"];
    if (estado) {
      var selector = "";
      var especialidades = data["datos"];
      var conteoEspecialidades = especialidades.length;
      for (var i = 0; i < conteoEspecialidades; i++) {
        selector += "<option value='" + especialidades[i]["esp_sigla"] + "," + especialidades[i]["esp_clasificacion"] + "'>" + especialidades[i]["esp_nombre"] + "</option>";
      }
      document.getElementById("selectTipoAtencion").innerHTML = selector;
      document.getElementById("contenedorTipoAtencion").style.display = "block";
    } else {
      this.traerHoras(1);
    }
  };

  traerHoras = async (tipoEsp) => {
    var sigla = "";
    var tipo = "";
    if (tipoEsp == 1) {
      var select = document.getElementById("selectEspecialidad").value;
      var arr = select.split(",");
      sigla = arr[0];
      tipo = arr[1];
    } else {
      var select = document.getElementById("selectTipoAtencion").value;
      var arr = select.split(",");
      sigla = arr[0];
      tipo = arr[1];
    }

    clasificacionEsp = tipo;

    const datos = {
      idUsuario: this.state.usu_id,
      sigla: sigla,
      idProfesional: this.state.proId,
      horariosExcluidos: "",
      canal: this.state.canal,
      cesfam: this.state.cesfam,
      fecha_inicio: fechaBuscaHoras,
      nHoras: 10,
    };
    var res = await axios(global.config.modulos.reservas + "horariosDisponibles/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    var estado = data["estado"];
    if (estado) {
      var selector = "";
      var horas = data["horas"];
      var conteoHoras = horas.length;
      if (conteoHoras > 0) {
        for (var i = 0; i < conteoHoras; i++) {
          var diaTexto = moment(horas[i]["hor_atencion"]).format("dddd");
          var dia = moment(horas[i]["hor_atencion"]).format("DD");
          var mes = moment(horas[i]["hor_atencion"]).format("MMMM");
          var ano = moment(horas[i]["hor_atencion"]).format("YYYY");
          var hora = moment(horas[i]["hor_atencion"]).format("HH:mm");

          var fechaFormateada = diaTexto + " " + dia + " " + mes + " del " + ano + " " + hora + " hrs.";

          //Lunes 00 de Abril del 2021 00:00 hrs

          selector += "<option value='" + horas[i]["hor_atencion"] + "," + horas[i]["hor_id"] + "," + horas[i]["ces_nombre"] + "'>" + fechaFormateada + "</option>";
        }
      }
      document.getElementById("selectHoras").innerHTML = selector;
      document.getElementById("contenedorHoras").style.display = "initial";
      this.setState({ btnActivo: true });

      if (tipo == 2) {
        document.getElementById("contenedorCanalPreferido").style.display = "block";
      } else {
        document.getElementById("contenedorCanalPreferido").style.display = "none";
        document.getElementById("canalPreferido").value = 1;

        document.getElementById("contenedorTelefonosUsuario").style.display = "none";
        document.getElementById("telefonoN").style.display = "none";
        document.getElementById("telefonoN").value = "";
      }
    } else {
      if (data.agenda != null) {
        document.getElementById("selectEspecialidad").value = 0;
        this.cerrarModalAgendamiento();
        this.detalleAnulaHora(data.agenda);
      } else {
        this.mostrarAlerta(data["mensaje"]);

        document.getElementById("selectEspecialidad").value = 0;
        this.cerrarModalAgendamiento();
      }
    }
  };

  validaciones = () => {
    let formIsValid = true;

    var canalPreferido = document.getElementById("canalPreferido").value;
    var telefonosUsuario = document.getElementById("telefonosUsuario").value;
    var telefonoManual = document.getElementById("telefonoN").value;

    if (clasificacionEsp == 2 && canalPreferido == 3 && telefonosUsuario == 0 && telefonoManual == "") {
      this.mostrarAlerta("Debe ingresar el número al cual se contactara al paciente");
      formIsValid = false;
    } else if (clasificacionEsp == 2 && canalPreferido == 3 && telefonosUsuario == 0 && telefonoManual != "" && telefonoManual.length < 9) {
      this.mostrarAlerta("El largo del número es incorrecto, Verifícalo");
      formIsValid = false;
    }

    return formIsValid;
  };

  confirmarAgendarHora = () => {
    var datos = document.getElementById("selectHoras").value;
    var arr = datos.split(",");
    var horAtencion = arr[0];
    var horId = arr[1];
    var cesfam = arr[2];

    var mensajeCanal = "";

    if (clasificacionEsp == 2) {
      var e = document.getElementById("canalPreferido");
      var canalPreferido = e.options[e.selectedIndex].value;
      var canalPreferidoNombre = e.options[e.selectedIndex].text;

      var eT = document.getElementById("telefonosUsuario");
      if (canalPreferido == 3) {
        var telefonosUsuario = eT.options[eT.selectedIndex].value;
        mensajeCanal = "Por " + canalPreferidoNombre.toLowerCase() + " al número ";
        if (telefonosUsuario == 0) {
          mensajeCanal += document.getElementById("telefonoN").value;
        } else {
          mensajeCanal += eT.options[eT.selectedIndex].text;
        }
      } else {
        mensajeCanal = "Por " + canalPreferidoNombre.toLowerCase();
      }
    }

    if (this.validaciones()) {
      var et = document.getElementById("selectTipoAtencion");
      var opcion = et.options[et.selectedIndex];

      if (opcion == undefined) {
        et = document.getElementById("selectEspecialidad");
        opcion = et.options[et.selectedIndex];
      }
      var tipoAte = et.options[et.selectedIndex].text;

      var dia = moment(horAtencion).format("DD");
      var mes = moment(horAtencion).format("MMMM");
      var hora = moment(horAtencion).format("HH:mm");

      var cesfam = "En Cesfam " + cesfam;
      var fechaTexto = dia + " de " + mes;
      var horaTexto = "a las " + hora + " hrs.";

      var mensaje = "¿La hora que deseas reservar es para " + tipoAte + " el día " + fechaTexto + " " + horaTexto + " " + mensajeCanal + " ?";

      confirmAlert({
        title: "Confirma por favor!",
        message: mensaje,
        buttons: [
          {
            label: "Aceptar",
            onClick: this.agendarHora.bind(this, horId),
          },
          {
            label: "Cancelar",
          },
        ],
      });
    }
  };

  agendarHora = async (horario) => {
    var datos = {
      horario: horario,
      cesfam: this.state.cesfam,
      canal: this.state.canal,
      usu_id: this.state.usu_id,
    };

    if (clasificacionEsp == 2) {
      var canalPreferido = document.getElementById("canalPreferido").value;
      if (canalPreferido == 3) {
        var telefonosUsuario = document.getElementById("telefonosUsuario").value;
        if (telefonosUsuario == 0) {
          var numeroManual = document.getElementById("numeroManual").value;
          datos = {
            horario: horario,
            cesfam: this.state.cesfam,
            canal: this.state.canal,
            usu_id: this.state.usu_id,
            tipo_contacto: canalPreferido,
            telefono_contacto: numeroManual,
          };
        } else {
          datos = {
            horario: horario,
            cesfam: this.state.cesfam,
            canal: this.state.canal,
            usu_id: this.state.usu_id,
            tipo_contacto: canalPreferido,
            telefono_contacto: telefonosUsuario,
          };
        }
      } else {
        datos = {
          horario: horario,
          cesfam: this.state.cesfam,
          canal: this.state.canal,
          usu_id: this.state.usu_id,
          tipo_contacto: canalPreferido,
        };
      }
    }

    var res = await axios.post(global.config.modulos.agendamientos + "/agendar_horario/", datos, { headers: authHeader() });

    var data = res.data;
    this.setState({ contenedor: "" });
    this.cerrarModalAgendamiento();
    confirmAlert({
      title: data["mensaje"],
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  cambioCanalPreferido = () => {
    var canalPreferido = document.getElementById("canalPreferido").value;
    if (canalPreferido == 3) {
      axios
        .get(global.config.modulos.telefonos + "/telefonos_por_usuario/" + this.state.usu_id, { headers: authHeader() })
        .then(function (res) {
          var data = res.data;

          var selector = "";
          var telefonos = data["datos"];
          var conteoTelefonos = telefonos.length;
          for (var i = 0; i < conteoTelefonos; i++) {
            selector += "<option value='" + telefonos[i]["tel_telefono"] + "'>" + telefonos[i]["tel_telefono"] + "</option>";
          }
          selector += "<option value='0'>OTRO</option>";

          document.getElementById("telefonosUsuario").innerHTML = selector;
          document.getElementById("contenedorTelefonosUsuario").style.display = "block";

          if (conteoTelefonos == 0) {
            document.getElementById("telefonoN").style.display = "block";
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      document.getElementById("contenedorTelefonosUsuario").style.display = "none";
      document.getElementById("telefonoN").style.display = "none";
      document.getElementById("telefonoN").value = "";
    }
  };

  cambioTelefono = () => {
    var telefonosUsuario = document.getElementById("telefonosUsuario").value;
    if (telefonosUsuario == 0) {
      document.getElementById("telefonoN").style.display = "block";
    } else {
      document.getElementById("telefonoN").style.display = "none";
      document.getElementById("telefonoN").value = "";
    }
  };

  detalleAnulaHora = async (ageId) => {
    var res = await axios.get(global.config.modulos.agendamientos + "agenda_por_id/" + ageId, { headers: authHeader() });

    var data = res.data;
    var dia = moment(data["hor_atencion"]).format("DD");
    var mes = moment(data["hor_atencion"]).format("MMMM");
    var ano = moment(data["hor_atencion"]).format("YYYY");
    var hora = moment(data["hor_atencion"]).format("HH:mm");

    var fechaTexto = dia + " de " + mes;
    var horaTexto = "a las " + hora + " hrs.";
    var especialidad = data["especialidad"];

    var reserva = "El paciente ya tiene una hora reservada para " + especialidad + " El día " + fechaTexto + " " + horaTexto + " ¿deseas anular la hora?";

    confirmAlert({
      title: reserva,
      message: "",
      buttons: [
        {
          label: "Anular",
          onClick: this.confirmarAnulaHora.bind(this, ageId, especialidad, fechaTexto, horaTexto),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };

  confirmarAnulaHora = async (ageId, especialidad, fecha, hora) => {
    var mensaje = "¿Está seguro que deseas anular la hora para " + especialidad + " Del día " + fecha + " " + hora + "?";

    setTimeout(async () => {
      confirmAlert({
        title: mensaje,
        message: "",
        buttons: [
          {
            label: "Aceptar",
            onClick: await this.anulaHora.bind(this, ageId),
          },
          {
            label: "Cancelar",
          },
        ],
      });
    }, 100);
  };

  anulaHora = async (ageId) => {
    const datos = { agenda: ageId, canal: this.state.canal };
    var res = await axios.post(global.config.modulos.agendamientos + "/anular_hora/", datos, { headers: authHeader() });

    var data = res.data;
    this.limpiarDatos();
    confirmAlert({
      title: data["mensaje"],
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  limpiarDatos = () => {
    document.getElementById("selectEspecialidad").value = 0;
    this.cambioEspecialidad();
  };

  cerrarModalAgendamiento = () => {
    document.getElementById("contenedorTipoAtencion").style.display = "none";
    document.getElementById("contenedorHoras").style.display = "none";

    document.getElementById("contenedorCanalPreferido").style.display = "none";
    document.getElementById("canalPreferido").value = 1;

    document.getElementById("contenedorTelefonosUsuario").style.display = "none";
    document.getElementById("telefonoN").style.display = "none";
    document.getElementById("telefonoN").value = "";

    this.setState({ btnActivo: false });
  };

  enviarArchivos = async () => {
    var formData = new FormData(document.forms.namedItem("FormEnviarArchivos"));

    formData.append("tipo", 2);
    formData.append("sala", this.state.nombreSala);

    var res = await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    var data = res.data;

    if (data == "ErrorFormato") {
      confirmAlert({
        title: "El formato del archivo no es compatible, el formato compatible es (PNG,JPGE,JPG,PDF,GIF)",
        message: "",
        buttons: [
          {
            label: "Aceptar",
          },
        ],
      });
    } else if (data == "errorAlEnviar") {
      confirmAlert({
        title: "No se ha podido enviar el archivo",
        message: "",
        buttons: [
          {
            label: "Aceptar",
          },
        ],
      });
    } else if (data == "subidoExito") {
      socketRTC.emit("enviar_archivo", {
        sala: this.state.nombreSala,
        tipo: 2,
      });

      document.getElementById("archivoSubirTxt").value = "";
      this.setState({ contenedor: "" });

      confirmAlert({
        title: "Se ha enviado Exitosamente el archivo",
        message: "",
        buttons: [
          {
            label: "Aceptar",
          },
        ],
      });
    }
  };

  revisarArchivoEnviado = async () => {
    var datos = { nombreSala: this.state.nombreSala, tipo: 1 };

    var res = await axios.get(global.config.modulos.videollamada + "ultimo_archivo_recibido/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    if (data["estado"]) {
      var idArchivo = data["vla_id"];

      confirmAlert({
        title: "EL PACIENTE HA ENVIADO UN ARCHIVO.",
        message: "",
        buttons: [
          {
            label: "Revisar",
            onClick: await this.abrirArchivo.bind(this, idArchivo),
          },
        ],
      });
    }
  };

  abrirEnviarArchivo = () => {
    document.getElementById("archivoSubir").click();
  };

  abrirArchivo = async (archivo) => {
    var res = await axios.get(global.config.modulos.videollamada + "archivo/" + archivo, { headers: authHeader() });

    var data = res.data;
    urlArchivoDescargar = global.config.modulos.REACT_APP_URL_SERVER_ + "/descarga_archivo?archivo=" + data["vla_nombre_archivo"] + "&formato=" + data["vla_formato_archivo"];

    nombreArchivoDescargar = data["vla_nombre_archivo"];
    var fechaHora = moment(data["vla_fecha_registro"]).format("DD/MM/YYYY HH:mm");

    var acciones = [];
    acciones.push(
      <div className="accionesArchivos" id="accionesArchivos">
        <label class="nombreArchivoDescargar">
          {nombreArchivoDescargar}
          <br></br>
          {fechaHora}
        </label>
        <img className="btnDescargarArchivo" src={descargar_archivo_blanco} onClick={this.descargarArchivo.bind(this, archivo)} />
      </div>
    );
    this.setState({ contenedorPopImg: "info10", accionesArchivos: acciones });

    if (data["vla_nombre_archivo"].includes("pdf")) {
      var img = '<iframe src="' + data["vla_url_archivo"] + '" class="preview" style="display:block"/>';
      document.getElementById("imagenGuardada").innerHTML = img;

      document.getElementById("pdfViewerGuardado").style.display = "none";
      document.getElementById("imagenGuardada").style.display = "block";
    } else {
      var img = '<img src="' + data["vla_url_archivo"] + '" class="preview"/>';
      document.getElementById("imagenGuardada").innerHTML = img;

      document.getElementById("pdfViewerGuardado").style.display = "none";
      document.getElementById("imagenGuardada").style.display = "block";
    }
  };

  descargarArchivo = async (archivo) => {
    var res = await axios.get(global.config.modulos.videollamada + "archivo/" + archivo, { headers: authHeader() });

    var data = res.data;
    urlArchivoDescargar = global.config.modulos.REACT_APP_URL_SERVER_ + "/descarga_archivo?archivo=" + data["vla_nombre_archivo"] + "&formato=" + data["vla_formato_archivo"];

    window.open(urlArchivoDescargar);
  };

  cerrarModalArchivosGuardados = () => {
    this.setState({ contenedorPopImg: "" });
  };

  traerDatos = async () => {
    var datos = {
      pro_id: this.state.proId,
    };

    var res = await axios.get(global.config.modulos.videollamada + "agenda_telemedicina/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    var firma = data.firma;

    if (firma) {
      this.setState({
        firma: true,
      });
    }

    var agenda = data.agenda;
    var conteoAgenda = agenda.length;

    var datos = [];
    for (var i = 0; i < conteoAgenda; i++) {
      var hora = moment(agenda[i]["horario"]).format("HH:mm");
      var ahora = moment().format("HH:mm");

      if (hora <= ahora && !agenda[i]["estado_check"] && this.state.ageId != agenda[i]["age_id"]) {
        if (document.getElementById("infoAtrasado").style.display != "block") {
          this.iniciarTAtraso(agenda[i]["horario"]);
          this.traerDatos();
        }
      }

      datos.push(
        <tr>
          <td>{agenda[i]["nombre_usuario"]}</td>
          <td>{hora}</td>
          <td>
            {this.state.ageId == agenda[i]["age_id"] ? (
              <center className={"estadoSala"} style={{ background: "#DF8B0E" }}>
                {"En curso"}
              </center>
            ) : (
              <>
                {agenda[i]["estado_check"] ? (
                  <img src={checkActivo} style={{ width: "1vw", float: "right" }} />
                ) : (
                  <center className={"estadoSala"} style={{ background: agenda[i]["color"] }}>
                    {agenda[i]["estado"]}
                  </center>
                )}
              </>
            )}
          </td>
        </tr>
      );
    }

    this.setState({ infoPacientes: datos });
  };

  videoLlamada = async () => {
    this.props.dispatch(showLoader());

    socketRTC.emit("terminar_llamada", {
      sala: this.state.nombreSala,
      tipo: 2,
    });

    await axios.post(
      global.config.modulos.agendamientos + "cambio_estado_agenda/",
      {
        agenda: this.state.ageId,
        estadoHora: 7,
      },
      { headers: authHeader() }
    );

    const datos2 = { agenda: this.state.ageId, profesional: this.state.proId, sala: this.state.id_sala };
    var res = await axios.post(global.config.modulos.videollamada + "cambio_estado_agenda_grupal/", datos2, { headers: authHeader() });
    var datos = res.data;
    if (datos.estado) {
      const datos2 = { agenda: datos["age_id"] };
      var res;
      if (datos["llamada_grupal"] == 1) {
        res = await axios.post(global.config.modulos.videollamada + "crear_sala_grupal/", datos2, { headers: authHeader() });
      } else {
        datos2["dispositivo"] = 1;
        datos2["tipo"] = datos["age_tipo_gestion"];
        res = await axios.post(global.config.modulos.videollamada + "crear_sala/", datos2, { headers: authHeader() });
      }
      var data = res.data;
      if (data.estado) {
        var resToken = await axios.get(global.config.modulos.videollamada + "token_sala/?uspId=" + this.state.uspId + "&sala=" + data.vls_nombre, { headers: authHeader() });
        var dataToken = resToken.data;

        window.localStorage.setItem("ageId", datos["age_id"]);
        window.localStorage.setItem("cesfam", datos["age_cesfam"]);
        window.localStorage.setItem("tipoGestion", datos["age_tipo_gestion"]);

        window.localStorage.setItem("nombreSala", data.vls_nombre);
        window.localStorage.setItem("idSala", data.vls_id);
        window.localStorage.setItem("token", dataToken.token);

        if (datos["llamada_grupal"] == 1) {
          window.location.replace("/videoLlamadaGrupal");
        } else {
          window.location.replace("/videoLlamada");
        }
      } else {
        this.props.dispatch(hideLoader());
        confirmAlert({
          title: data.mensaje,
          message: "",
          buttons: [
            {
              label: "Aceptar",
            },
          ],
        });
      }
    } else {
      this.props.dispatch(hideLoader());
      confirmAlert({
        title: datos.mensaje,
        message: "",
        buttons: [
          {
            label: "Aceptar",
          },
        ],
      });
    }
  };

  iniciarTAtraso = async (inicio) => {
    document.getElementById("infoAtrasado").style.display = "block";

    if (inicio != null) {
      var inicioAtraso = new Date(inicio).getTime();
    } else {
      var inicioAtraso = new Date().getTime();
    }
    countdownAtr = setInterval(function () {
      var now = new Date().getTime();
      var elapsed = (inicioAtraso - now) * -1;
      var e_minute = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
      var e_second = Math.floor((elapsed % (1000 * 60)) / 1000);
      var tx_e_second = e_second < 10 ? "0" + e_second + " min" : e_second + " min";
      var tx_e_minute = e_minute < 10 ? "0" + e_minute : e_minute;
      var elapsed_timer = "<label style='color: #ff000a'><b> " + tx_e_minute + ":" + tx_e_second + "</b></label>";
      document.getElementById("countdownAtraso").innerHTML = elapsed_timer;
    }, 1000);
  };

  agendarUsuario = () => {
    this.setState({
      agendarUsuario: true,
    });
  };

  ocultarAgendar = () => {
    this.setState({ agendarUsuario: false });
  };

  abrirModalArchivos = async () => {
    var datos = {
      usuId: this.state.usu_id,
      carpeta: "Teleconsulta",
    };

    var res = await axios.get(global.config.modulos.videollamada + "archivos_por_usuario/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    var archivos = data.archivos;
    if (archivos.length > 0) {
      var datos = [];
      for (var i = 0; i < archivos.length; i++) {
        var fechaIngreso = archivos[i]["vla_fecha_registro"];
        var arr = fechaIngreso.split("T");
        var fecha = arr[0].split("-");
        var hora = arr[1];

        var ano = fecha[0];
        var mes = fecha[1];
        var dia = fecha[2];

        var fechaFormateada = dia + "-" + mes + "-" + ano;
        datos.push(
          <tr>
            <td>
              <center>{fechaFormateada}</center>
            </td>
            <td>
              <center>{archivos[i]["vla_nombre_archivo"].substring(0, 10) + "...."}</center>
            </td>
            <td>
              <center>
                <button className="botonesTabla" onClick={this.abrirArchivo.bind(this, archivos[i]["vla_id"])}>
                  Ver más
                </button>
              </center>
            </td>
          </tr>
        );
      }
      this.setState({ tablaArchivos: datos });
      if (this.state.contenedor == "info11") {
        this.setState({ contenedor: "" });
      } else {
        this.setState({ contenedor: "info11" });
      }
    } else {
      this.mostrarAlerta("No hay archivos ingresados.");
    }
  };

  abrirModalCertificado = () => {
    if (this.state.contenedor == "info12") {
      this.setState({ contenedor: "" });
    } else {
      this.setState({ contenedor: "info12" });
    }
  };

  cerrarModalCertificado = () => {
    this.setState({ contenedor: "" });
  };

  abrirModalCertificadoIndicaciones = () => {
    if (this.state.contenedor == "info13") {
      this.setState({ contenedor: "" });
    } else {
      this.setState({ contenedor: "info13" });
    }
  };

  confirmarEnviarCertificado = (tipo) => {
    confirmAlert({
      title: "Confirma por favor!",
      message: "¿Segur@ que deseas enviar el certificado?",
      buttons: [
        {
          label: "Aceptar",
          onClick: this.enviarCertificado.bind(this, tipo),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };

  enviarCertificado = async (tipo) => {
    const datos = { tipo: tipo, agenda: this.state.ageId, nombre_sala: this.state.nombreSala, sala: this.state.id_sala };

    if (this.state.indicacion != "") {
      datos["indicacion"] = this.state.indicacion;
    }

    var res = await axios.post(global.config.modulos.videollamada + "generar_certificado_pdf/", datos, { headers: authHeader() });
    var data = res.data;
    this.cerrarModalCertificado();
    socketRTC.emit("enviar_archivo", {
      sala: this.state.nombreSala,
      tipo: 2,
    });

    this.setState({
      indicacion: "",
    });
    confirmAlert({
      title: data.mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  guardarIndicacion = () => {
    if (this.state.indicacion == "") {
      this.mostrarAlerta("Ingresa la indicación");
    } else {
      this.confirmarEnviarCertificado(1);
    }
  };

  volverAContactar = () => {
    const datos2 = { sala: this.state.nombreSala, ageId: this.state.ageId };
    axios
      .post(global.config.modulos.videollamada + "volver_a_contactar/", datos2, { headers: authHeader() })
      .then(function (res) {})
      .catch(function (err) {});
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  modificarCorreo = () => {
    this.setState({
      modCorreo: true,
      correoMod: this.state.correo,
    });
  };

  confirmarModificarCorreo = () => {
    const { correoMod } = this.state;
    if (correoMod == "") {
      NotificationManager.warning("Ingresa el correo.");
      return false;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correoMod)) {
      NotificationManager.warning("Verifica el formato del correo.");
      return false;
    }

    confirmAlert({
      title: correoMod,
      message: "¿Es correcto?",
      buttons: [
        {
          label: "Editar",
          className: "btnCancelar",
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () =>
            axios
              .patch(
                global.config.modulos.usuarios + "por_id/" + this.state.usu_id,
                {
                  usu_correo_electronico: correoMod,
                  usp_id: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                this.setState({
                  correo: correoMod,
                });
                this.ocultarModificarCorreo();
              })
              .catch((error) => {
                console.log(error);
              }),
        },
      ],
    });
  };

  cambiarCorreo = (e) => {
    const { name, value } = e.target;
    this.setState({ correo: value });
  };

  ocultarModificarCorreo = () => {
    this.setState({
      modCorreo: false,
    });
  };

  modificarTelefono = () => {
    this.setState({
      modTelefono: true,
      ultimoTelefonoMod: this.state.ultimoTelefono,
    });
  };

  confirmarModificarTelefono = () => {
    const { ultimoTelefonoMod } = this.state;
    if (ultimoTelefonoMod == "") {
      NotificationManager.warning("Ingresa el teléfono.");
      return false;
    }

    if (ultimoTelefonoMod.toString().length > 0 && ultimoTelefonoMod.toString().length < 9) {
      NotificationManager.warning("Escribe el número de celular correcto.");
      return false;
    }

    confirmAlert({
      title: ultimoTelefonoMod,
      message: "¿Es correcto?",
      buttons: [
        {
          label: "Editar",
          className: "btnCancelar",
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () =>
            axios
              .post(
                global.config.modulos.telefonos + "agregar_telefono/",
                {
                  telefono: ultimoTelefonoMod,
                  usu_id: this.state.usu_id,
                  tipo: 1,
                  canal: this.state.canal,
                  usp_id: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                this.setState({
                  ultimoTelefono: ultimoTelefonoMod,
                });
                this.ocultarModificarTelefono();
              })
              .catch(function (err) {
                console.log(err);
              }),
        },
      ],
    });
  };

  ocultarModificarTelefono = () => {
    this.setState({
      modTelefono: false,
    });
  };

  validarTelefono = (e) => {
    let valorTelefono = "";

    var telefono = document.getElementById("ultimoTelefonoMod");
    var nums_v = telefono.value.match(/\d+/g);
    if (nums_v != null) {
      if (telefono.value.toString().length >= 1) {
        valorTelefono = telefono.value;
      } else {
        valorTelefono = "";
      }
    } else {
      valorTelefono = "";
    }
    this.setState({ ultimoTelefonoMod: valorTelefono });
  };

  render() {
    const archivosS = () => {
      var img = document.getElementById("archivoSubir").value;
      if (img != "") {
        if (this.state.contenedor == "info5") {
          this.setState({ contenedor: "" });
        } else {
          this.setState({ contenedor: "info5" });
        }

        document.getElementById("reviewImg").style.display = "block";
        document.getElementById("archivoSubirTxt").value = img.split("\\").pop();
      } else {
        document.getElementById("reviewImg").style.display = "none";
        document.getElementById("archivoSubirTxt").value = "";
        this.setState({ contenedor: "" });
      }
    };

    const cancelarArchivo = () => {
      document.getElementById("reviewImg").style.display = "none";
      document.getElementById("archivoSubirTxt").value = "";
      this.setState({ contenedor: "" });
    };

    const displayOptions = {
      stageLayout: "grid",
      showStats: false,
    };

    const onConnected = (room) => {
      room.localParticipant.setCameraEnabled(true);
      room.localParticipant.setMicrophoneEnabled(true);

      setTimeout(function () {
        document.querySelector("._2HbZ0").classList.add("solo_2vumQ");
      }, 1000);

      var partici = room.participants.size + 1;
      if (partici == 2) {
        var elemt = document.getElementsByClassName("_34YL4")[0];
        elemt.style.width = "unset";
        elemt.style.height = "unset";
        elemt.style.position = "initial";
        setTimeout(function () {
          document.querySelector("._2HbZ0").classList.add("solo_2vumQ");
          let children = elemt.querySelectorAll("._2WNzJ");
          children[0].classList.add("video1");
          document.getElementsByClassName("_2WNzJ")[1].style.objectFit = "contain";
        }, 2000);
      }
    };

    const updateParticipantSize = (room) => {
      var partici = room.participants.size + 1;

      if (partici >= 2) {
        var elemt = document.getElementsByClassName("_34YL4")[0];
        elemt.style.width = "unset";
        elemt.style.height = "unset";
        elemt.style.position = "initial";
        setTimeout(function () {
          document.querySelector("._2HbZ0").classList.add("solo_2vumQ");
          let children = elemt.querySelectorAll("._2WNzJ");
          children[0].classList.add("video1");
          document.getElementsByClassName("_2WNzJ")[1].style.objectFit = "contain";
        }, 2000);
      }

      /*  setNumParticipants(room.participants.size + 1); */
    };

    const onParticipantDisconnected = (room) => {
      updateParticipantSize(room);
    };

    return (
      <DisplayContext.Provider value={displayOptions}>
        <div className="roomContainer">
          <div className={`media-bridge ${this.state.bridge}`}>
            <article className="_2sm4earticle">
              <LiveKitRoom
                roomOptions={{
                  adaptiveStream: "adaptiveStream",
                  dynacast: "dynacast",
                  publishDefaults: {
                    simulcast: "simulcast",
                  },
                  videoCaptureDefaults: {
                    resolution: VideoPresets.h720.resolution,
                  },
                }}
                onConnected={(room) => {
                  onConnected(room);
                  room.on(RoomEvent.ParticipantConnected, () => updateParticipantSize(room));
                  room.on(RoomEvent.ParticipantDisconnected, () => onParticipantDisconnected(room));
                  updateParticipantSize(room);
                }}
                url={"https://hsdc.cl/"}
                token={this.state.token}
              />

              <div id="call-timer" className="timer"></div>
              <div id="call-countdown" className="countdown"></div>
            </article>

            <div
              className="infoPacientes"
              id="infoPacientes"
              style={{
                backgroundImage: `url("${datos_pacientes}")`,
              }}
            >
              <label style={{ fontSize: "1.3vw" }}>Pacientes del día:</label>
              <div style={{ overflowY: "scroll", height: "95%" }}>
                <table style={{ width: "100%", marginTop: "10px", fontSize: "1vw" }}>
                  <tbody>{this.state.infoPacientes}</tbody>
                </table>
              </div>
            </div>

            <div
              className="infoAtrasado"
              id="infoAtrasado"
              style={{
                backgroundImage: `url("${datos_pacientes}")`,
              }}
            >
              <label className="DataAlerta">
                Estas atrasado en
                <strong style={{ color: "#ff000a" }} id="countdownAtraso"></strong>
                <br></br> para atender al siguiente paciente,
                <br></br> ¿Deseas terminar la llamada actual
                <br></br>y pasar a la siquiente?
              </label>
              <br />
              <button className="boton botonVerde" style={{ fontSize: "1vw" }} onClick={this.videoLlamada}>
                Si
              </button>
              <button className="boton botonRojo" style={{ fontSize: "1vw" }}>
                No
              </button>
            </div>

            <div className="accionesVideo">
              <svg className="mute-audio" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-10 -10 68 68" onClick={this.muteAudio}>
                <title>title</title>
                <circle className="circleAcciones" cx="24" cy="24" r="34">
                  <title>Mute audio</title>
                </circle>
                <path style={this.state.audio ? { display: "none" } : { display: "block" }} className="on" transform="scale(0.6), translate(17,18)" d="M38 22h-3.4c0 1.49-.31 2.87-.87 4.1l2.46 2.46C37.33 26.61 38 24.38 38 22zm-8.03.33c0-.11.03-.22.03-.33V10c0-3.32-2.69-6-6-6s-6 2.68-6 6v.37l11.97 11.96zM8.55 6L6 8.55l12.02 12.02v1.44c0 3.31 2.67 6 5.98 6 .45 0 .88-.06 1.3-.15l3.32 3.32c-1.43.66-3 1.03-4.62 1.03-5.52 0-10.6-4.2-10.6-10.2H10c0 6.83 5.44 12.47 12 13.44V42h4v-6.56c1.81-.27 3.53-.9 5.08-1.81L39.45 42 42 39.46 8.55 6z" fill="white"></path>
                <path style={this.state.audio ? { display: "block" } : { display: "none" }} className="off" transform="scale(0.6), translate(17,18)" d="M24 28c3.31 0 5.98-2.69 5.98-6L30 10c0-3.32-2.68-6-6-6-3.31 0-6 2.68-6 6v12c0 3.31 2.69 6 6 6zm10.6-6c0 6-5.07 10.2-10.6 10.2-5.52 0-10.6-4.2-10.6-10.2H10c0 6.83 5.44 12.47 12 13.44V42h4v-6.56c6.56-.97 12-6.61 12-13.44h-3.4z" fill="white"></path>
              </svg>

              <svg className="mute-video" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-10 -10 68 68" onClick={this.muteVideo}>
                <circle className="circleAcciones" cx="24" cy="24" r="34">
                  <title>Mute video</title>
                </circle>
                <path style={this.state.video ? { display: "none" } : { display: "block" }} className="on" transform="scale(0.6), translate(17,16)" d="M40 8H15.64l8 8H28v4.36l1.13 1.13L36 16v12.36l7.97 7.97L44 36V12c0-2.21-1.79-4-4-4zM4.55 2L2 4.55l4.01 4.01C4.81 9.24 4 10.52 4 12v24c0 2.21 1.79 4 4 4h29.45l4 4L44 41.46 4.55 2zM12 16h1.45L28 30.55V32H12V16z" fill="white"></path>
                <path style={this.state.video ? { display: "block" } : { display: "none" }} className="off" transform="scale(0.6), translate(17,16)" d="M40 8H8c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zm-4 24l-8-6.4V32H12V16h16v6.4l8-6.4v16z" fill="white"></path>
              </svg>
            </div>

            <div
              className="info1"
              id="info1"
              style={{
                backgroundImage: `url("${datos_pacientes}")`,
              }}
            >
              <label className="datosPaciente" style={{ wordWrap: "break-word" }}>
                <label>
                  <strong>Nombre: </strong> {this.state.nombre}
                </label>
                <br />
                <label>
                  <strong>Edad: </strong>
                  {this.state.edad} años
                </label>
                <br />
                <label>
                  <strong>Correo: </strong>
                </label>
                <label>
                  {this.state.correo || this.state.modCorreo ? (
                    <>
                      {this.state.modCorreo ? (
                        <>
                          <input type="text" name="correoMod" value={this.state.correoMod} onChange={this.cambiarValores} placeholder="" style={{ padding: "0" }} />
                          <Tooltip title="Actualizar correo" aria-label="Actualizar correo">
                            <img
                              style={{
                                width: 18,
                                float: "inherit",
                                marginBottom: -4,
                              }}
                              onClick={this.confirmarModificarCorreo}
                              className="imagenBotonAccion "
                              src={icono_guardar}
                            />
                          </Tooltip>
                          <Tooltip title="Cancelar" aria-label="Cancelar">
                            <img
                              style={{
                                width: 18,
                                float: "inherit",
                                marginBottom: -4,
                              }}
                              onClick={this.ocultarModificarCorreo}
                              className="imagenBotonAccion "
                              src={icono_eliminar}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          {this.state.correo}
                          <Tooltip title="Modificar correo" aria-label="Modificar correo">
                            <img
                              style={{
                                width: 18,
                                float: "inherit",
                                marginBottom: -4,
                              }}
                              onClick={this.modificarCorreo}
                              className="imagenBotonAccion "
                              src={icono_modificar}
                            />
                          </Tooltip>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {"No informado"}
                      <Tooltip title="Modificar correo" aria-label="Modificar correo">
                        <img
                          style={{
                            width: 18,
                            float: "inherit",
                            marginBottom: -4,
                          }}
                          onClick={this.modificarCorreo}
                          className="imagenBotonAccion "
                          src={icono_modificar}
                        />
                      </Tooltip>
                    </>
                  )}
                </label>
                <br />
                <label>
                  <strong>Teléfono: </strong>
                </label>
                <label>
                  {this.state.ultimoTelefono || this.state.modTelefono ? (
                    <>
                      {this.state.modTelefono ? (
                        <>
                          <input type="text" id="ultimoTelefonoMod" name="ultimoTelefonoMod" value={this.state.ultimoTelefonoMod} onChange={this.validarTelefono} placeholder="" style={{ padding: "0" }} maxLength={9} pattern="^-?[0-9+() ]*\d*\.?\d*$" />
                          <Tooltip title="Actualizar teléfono" aria-label="Actualizar teléfono">
                            <img
                              style={{
                                width: 18,
                                float: "inherit",
                                marginBottom: -4,
                              }}
                              onClick={this.confirmarModificarTelefono}
                              className="imagenBotonAccion "
                              src={icono_guardar}
                            />
                          </Tooltip>
                          <Tooltip title="Cancelar" aria-label="Cancelar">
                            <img
                              style={{
                                width: 18,
                                float: "inherit",
                                marginBottom: -4,
                              }}
                              onClick={this.ocultarModificarTelefono}
                              className="imagenBotonAccion "
                              src={icono_eliminar}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          {this.state.ultimoTelefono}
                          <Tooltip title="Modificar teléfono" aria-label="Modificar teléfono">
                            <img
                              style={{
                                width: 18,
                                float: "inherit",
                                marginBottom: -4,
                              }}
                              onClick={this.modificarTelefono}
                              className="imagenBotonAccion "
                              src={icono_modificar}
                            />
                          </Tooltip>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {"No informado"}
                      <Tooltip title="Modificar teléfono" aria-label="Modificar teléfono">
                        <img
                          style={{
                            width: 18,
                            float: "inherit",
                            marginBottom: -4,
                          }}
                          onClick={this.modificarTelefono}
                          className="imagenBotonAccion "
                          src={icono_modificar}
                        />
                      </Tooltip>
                    </>
                  )}
                </label>
              </label>
            </div>
            <div
              className="infoacom"
              id="infoacom"
              style={{
                backgroundImage: `url("${datos_pacientes}")`,
              }}
            >
              {this.state.infoAcom}
            </div>

            <div
              className="info2"
              style={
                this.state.contenedor == "info2"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none", backgroundImage: `url("${fondo_escribir}")` }
              }
            >
              <label className="labelMensaje">Observación:</label>
              <textarea className="textArea" id="txtObservaciones"></textarea>
              <div className="col-12">
                <button className="btnGuardar" id="btnGuardarObservacion" onClick={this.guardarObservacion}>
                  Guardar
                </button>
                <button className="btnCerrar" id="btnCerrarObservacion" onClick={this.cerrarContenedores}>
                  Cancelar
                </button>
              </div>
            </div>

            <div
              className="info4"
              style={
                this.state.contenedor == "info4"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none", backgroundImage: `url("${fondo_escribir}")` }
              }
            >
              <div className="tablaObs">
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <td>Fecha</td>
                      <td>Profesional</td>
                      <td>Observación</td>
                    </tr>
                  </thead>
                  <tbody id="cuerpoHistorialObservaciones">{this.state.tablaObservaciones}</tbody>
                </table>
              </div>
            </div>

            <div
              className="info5"
              style={
                this.state.contenedor == "info5"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none" }
              }
            >
              <div id="reviewImg">
                <input type="text" name="archivoSubirTxt" id="archivoSubirTxt" className="inputArchivo" autoComplete="off" readOnly />
              </div>
              <div className="col-12">
                <button className="btnGuardar" id="btnEnviarArchivo" onClick={this.enviarArchivos}>
                  Enviar
                </button>
                <button className="btnCerrar" id="btnCerrarArchivo" onClick={cancelarArchivo}>
                  Cancelar
                </button>
              </div>
            </div>

            <div
              className="info6"
              style={
                this.state.contenedor == "info6"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none" }
              }
            >
              <label className="labelMensaje">Escribir mensaje:</label>
              <textarea className="textArea" id="txtMensaje"></textarea>
              <div className="col-12">
                <button className="btnGuardar" id="btnGuardarMensaje" onClick={this.guardarMensaje}>
                  Enviar
                </button>
                <button className="btnCerrar" id="btnCerrarMensaje" onClick={this.cerrarContenedores}>
                  Cancelar
                </button>
              </div>
            </div>

            <div
              className="info7"
              style={
                this.state.contenedor == "info7"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none" }
              }
            >
              <div className="contenedorAtencion">
                <label className="tituloAcciones">Especialidades</label>
                <br></br>
                <select className="selectReservarMed" id="selectEspecialidad" onChange={this.cambioEspecialidad.bind(this)}></select>
                <div id="contenedorTipoAtencion" style={{ display: "none" }}>
                  <label className="tituloAcciones">Tipo de atención</label>
                  <br></br>
                  <select className="selectReservarMed" id="selectTipoAtencion" name="selectTipoAtencion"></select>
                </div>
                <div id="contenedorHoras" style={{ display: "none" }}>
                  <label className="tituloAcciones">Horas de atención disponibles</label>
                  <br></br>
                  <select className="selectReservarMed" id="selectHoras"></select>
                </div>
                <div id="contenedorCanalPreferido" style={{ display: "none" }}>
                  <label className="tituloAcciones">Canal de contacto preferido por el paciente</label>
                  <select className="selectReservarMed" id="canalPreferido" name="canalPreferido" onChange={this.cambioCanalPreferido.bind(this)}>
                    <option value="1">Contacto App</option>
                    <option value="2">Contacto Portal web</option>
                    <option value="3">Contacto telefónico</option>
                  </select>
                </div>
                <div id="contenedorTelefonosUsuario" style={{ display: "none" }}>
                  <div className="">
                    <select className="selectReservarMed" id="telefonosUsuario" name="telefonosUsuario" onChange={this.cambioTelefono.bind(this)}></select>
                  </div>
                  <div className="col-12">
                    <input type="number" className="selectReservarMed" id="telefonoN" name="telefonoN" placeholder="(+56)" maxLength="9" />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button className={!this.state.btnActivo ? "btnGuardar btnDisabledMed" : "btnGuardar"} id="btnReservar" disabled={!this.state.btnActivo} onClick={this.confirmarAgendarHora}>
                  Reservar
                </button>
                <button className="btnCerrar" id="btnCerrarMensaje" onClick={(this.cerrarModalAgendamiento, this.cerrarContenedores)}>
                  Cancelar
                </button>
              </div>
            </div>

            <div
              className="info8"
              id="info8"
              style={
                this.state.contenedor == "info8"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none" }
              }
            >
              {this.state.detalleObservacion}
            </div>
            <div
              className="info9"
              id="info9"
              style={
                this.state.contenedorPopM == "info9"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none" }
              }
            >
              <label>Mensaje del paciente:</label>
              <br />
              <label className="MensajeEnviadoM" id="MensajeEnviadoM"></label>
              <textarea className="textAreaPopup" id="mensajePopup" placeholder="Debes ingresar tu respuesta..."></textarea>
              <div className="col-12">
                <button className="btnGuardarM" onClick={this.enviarMensajeRespuesta}>
                  Enviar
                </button>
                <button className="btnCerrarM" onClick={this.cerrarMensajePop}>
                  Cerrar
                </button>
              </div>
            </div>

            <div
              className="info10"
              id="info10"
              style={
                this.state.contenedorPopImg == "info10"
                  ? {
                      display: "block",
                    }
                  : { display: "none" }
              }
            >
              <img className="btnCerrarPopup" src={cerrar_popup} onClick={this.cerrarModalArchivosGuardados} />
              <div className="contenedorPdfImg">
                <canvas className="pdfViewerGuardado" id="pdfViewerGuardado"></canvas>
                <div className="imagenGuardada" id="imagenGuardada"></div>
                {this.state.accionesArchivos}
              </div>
            </div>

            <div
              className="info11"
              style={
                this.state.contenedor == "info11"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none", backgroundImage: `url("${fondo_escribir}")` }
              }
            >
              <div className="tablaObs">
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <td>Fecha</td>
                      <td>Archivo</td>
                      <td>Acción</td>
                    </tr>
                  </thead>
                  <tbody id="cuerpoHistorialObservaciones">{this.state.tablaArchivos}</tbody>
                </table>
              </div>
            </div>

            <div
              className="info12"
              style={
                this.state.contenedor == "info12"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none" }
              }
            >
              <div className="col-12 info12_contendor">
                <button className="info12_btnsV" id="btnEnviarArchivo" onClick={this.confirmarEnviarCertificado.bind(this, 2)}>
                  Enviar Certificado
                </button>
                <br></br>
                <button className="info12_btnsV" id="btnEnviarArchivo" onClick={this.abrirModalCertificadoIndicaciones}>
                  Enviar Certificado con indicaciones
                </button>
                <br></br>
                <button className="info12_btnsN" id="btnCerrarArchivo" onClick={this.cerrarModalCertificado}>
                  Cancelar
                </button>
              </div>
            </div>

            <div
              className="info12"
              style={
                this.state.contenedor == "info13"
                  ? {
                      display: "block",
                      backgroundImage: `url("${fondo_escribir}")`,
                    }
                  : { display: "none" }
              }
            >
              <div className="col-12 info12_contendor" style={{ alignItems: "flex-start" }}>
                <label className="labelMensaje">Indicaciones:</label>
                <textarea className="textArea" id="indicacion" name="indicacion" onChange={this.cambiarValores} value={this.state.indicacion}></textarea>
                <div className="col-12">
                  <button className="btnGuardar" id="btnGuardarObservacion" onClick={this.guardarIndicacion}>
                    Enviar
                  </button>
                  <button className="btnCerrar" id="btnCerrarObservacion" onClick={this.abrirModalCertificado}>
                    Cancelar
                  </button>
                </div>
              </div>
            </div>

            <footer
              className="footer-video"
              style={{
                backgroundImage: `url("${fondo_footer}")`,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <div style={{ float: "left", marginLeft: "1vw" }}>
                  <img className="accionesFooter" src={cortar} title="Terminar llamada " onClick={this.cortar} />
                  <img className="accionesFooter" src={recargar} title="Actualizar llamada " onClick={this.recargarPagina} />
                  <img className="accionesFooter" src={ausente} title="Usuario se ausento a cita " onClick={this.usuarioAusente} />
                </div>
                <img src={logo_v2} style={{ width: "13vw" }} />
                <div style={{ float: "right", marginLeft: "1vw" }}>
                  {this.state.firma && <img className="accionesFooter" src={indicaciones} onClick={this.abrirModalCertificado} title="Certificado " />}
                  <img className="accionesFooter" src={escribir_observacion} onClick={this.abrirModalObservaciones} title="Agregar observación " />
                  <img className="accionesFooter" src={observaciones} onClick={this.abrirModalObservacionesHistorial} title="Ver historial de observaciones " />
                  <img className="accionesFooter" src={reservar_llamada} onClick={this.agendarUsuario} title="Reservar Horas " />
                  <form id="FormEnviarArchivos" enctype="multipart/form-data" style={{ cursor: "pointer", float: "right" }}>
                    <img className="accionesFooter" src={adjuntar_archivo} onClick={this.abrirEnviarArchivo} title="Subir archivo " />
                    <input type="file" id="archivoSubir" name="archivoSubir" accept=".png, .jpg, .jpeg, .pdf" style={{ display: "none" }} onChange={archivosS} />
                  </form>
                  <img className="accionesFooter" src={escribir_mensaje} onClick={this.abrirModalMensaje} title="Escribir mensaje " />
                  <img className="accionesFooter" src={ver_archivos} onClick={this.abrirModalArchivos} title="Ver archivos" />
                </div>
              </div>
            </footer>
            {this.state.agendarUsuario ? <AgendarUsuario ocultar={this.ocultarAgendar} usuario={this.state.usu_id}></AgendarUsuario> : null}
          </div>
        </div>
      </DisplayContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(VideoLlamada);
