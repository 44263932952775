import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class AnularHora extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      motivoTxt: "",

      uspId: store.getState().usp,
      usuId: props.usuId,
      ageId: props.ageid,
      canal: 5,

      motivosAnulacion: [],
      formErrors: {},
      open: true,
    };

    this.traerMotivosAnulacion();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAlertaEliminado() {
    NotificationManager.success("Usuario eliminado con éxito");
  }

  validaciones() {
    const { motivo, motivoTxt } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (motivo == "" || motivo == 0) {
      formIsValid = false;
      formErrors["motivoErr"] = "Selecciona el motivo.";
    }

    if (motivo == "otro" && motivoTxt == "") {
      formIsValid = false;
      formErrors["motivoTxtErr"] = "Escribe el motivo.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "Segur@ que desea anular esta hora de atención?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
            onClick: () => null,
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: async () => {
              const { motivo, motivoTxt } = this.state;

              var motivoAnulacion = "";
              if (motivo == "otro") {
                motivoAnulacion = motivoTxt;
              } else {
                motivoAnulacion = motivo;
              }

              let apiUrl = global.config.modulos.agendamientos + "anular_hora/";
              const options = {
                method: "POST",
                data: {
                  agenda: this.state.ageId,
                  canal: this.state.canal,
                  usp_id: this.state.uspId,
                  motivo: motivoAnulacion,
                },
                headers: authHeader(),
              };
              axios(apiUrl, options).then((res) => {
                if (res.data.estado) {
                  NotificationManager.info(res.data.mensaje);
                  this.salir();
                }
              });
            },
          },
        ],
      });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerMotivosAnulacion() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_anulacion_hora/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        this.setState({
          motivosAnulacion: res.data.motivos,
        });
      });
  }

  render() {
    const { motivoErr, motivoTxtErr } = this.state.formErrors;

    const cambioMotivoAnulacion = (event) => {
      if (event.target.value == "otro") {
        this.setState({
          motivo: event.target.value,
          mostrarMotivoTxt: true,
        });
      } else {
        this.setState({
          motivo: event.target.value,
          mostrarMotivoTxt: false,
        });
      }
    };

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="anularHora">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                <img className="marca-agua"></img>
                <GridContainer className="agendar-container">
                  <GridItem xs={12}>
                    <h2>Anulación</h2>
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Select id="motivo" name="motivo" value={this.state.motivo} onChange={cambioMotivoAnulacion} displayEmpty>
                      <MenuItem value="" disabled>
                        <em>¿Porqué esta anulando la hora?</em>
                      </MenuItem>

                      {this.state.motivosAnulacion &&
                        this.state.motivosAnulacion.map((mb, index) => (
                          <MenuItem value={mb.mah_motivo} key={index}>
                            {mb.mah_motivo}
                          </MenuItem>
                        ))}
                    </Select>
                    {motivoErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoErr}</div>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.state.mostrarMotivoTxt ? <TextareaAutosize name="motivoTxt" aria-label="minimum height" className={"selectSimple "} style={{ float: "left", minWidth: 250, marginTop: 20 }} rowsMin={3} placeholder="motivo..." value={this.state.motivoTxt} onChange={this.cambiarValores} /> : null}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {motivoTxtErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoTxtErr}</div>}
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cancelar
                    </Button>
                    <Button className="botonDialog fondoVerde" onClick={this.enviarFormulario}>
                      Aceptar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
