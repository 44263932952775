const StackedProgressBar = (props) => {
    const { bgcolor, completed, amount, tooltipColor, 
            small, estimated, amount_est, title,
            onClick, id, tooltipColor2, main, alerta, cumpl } = props;
  
    const containerStyles = {
      height: 23,
      width: '100%',
      backgroundColor: "#d1d1d1",
      borderRadius: 50,
      margin: 10
    }

    const containerStylesTp = {
      height: 30,
      width: '100%',
      borderRadius: 50,
      margin: 10
    }
  
    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: tooltipColor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
        top: '-100%',
        position: 'relative',
    }

    const fillerStyles2 = {
      height: '100%',
      width: `${estimated}%`,
      backgroundColor: tooltipColor2,
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
  }

    const tooltip = {
      backgroundColor: tooltipColor,
      borderColor: tooltipColor+' transparent',
    }

    const tooltip2 = {
      backgroundColor: tooltipColor2,
      borderColor: tooltipColor2+' transparent'
    }

    const detail = {
      cursor: 'pointer'
    }

    return (
      <div id={id}>
        <p className={
          `${completed < 25 ? "redtext" : completed == 100 ? "greentext" : "normaltext"} 
          ${main ? "main": "notmain"}`} 
          onClick={onClick} style={detail}>
            {`${title}  `}{completed < 25 ? 
              <img className={'smallIcon'} src={`${alerta}`} alt="*">

              </img> 
            : ""}
        </p>
        <div style={containerStyles}>
        <div style={fillerStyles2}>
            <span className={`${small ? "smallTooltip" : "" } bubble`} style={tooltip2}>{`${amount_est}`}</span>
        </div>
        <div style={fillerStyles}>          
            <span className={`${small ? "smallTooltip" : "" } bubble`} style={tooltip}>{`${amount}`}</span>
        </div>
        </div>
      </div>
    );
  };
  
  export default StackedProgressBar;