import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class CambiarSolucion extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      solucion: "",
      teleconsulta: "",
      presencial: "",
      activarTeleconsulta: false,
      activarPresencial: false,
      gegId: props.gegId,
      usuId: props.usuario,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (value === "Se agenda teleconsulta") {
      return this.setState({
        activarTeleconsulta: true,
        activarPresencial: false,
        teleconsulta: "",
        presencial: "",
      });
    } else if (value === "Se agenda atención presencial") {
      return this.setState({
        activarTeleconsulta: false,
        activarPresencial: true,
        teleconsulta: "",
        presencial: "",
      });
    }

    this.setState({
      activarTeleconsulta: false,
      activarPresencial: false,
      teleconsulta: "",
      presencial: "",
    });
  };

  cambiarValor = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cambiarSolucion = () => {
    let especialidad = null;
    if (this.state.activarTeleconsulta) {
      if (this.state.teleconsulta == "") {
        return NotificationManager.error("Selecciona el tipo de atención");
      } else {
        especialidad = this.state.teleconsulta;
      }
    } else if (this.state.activarPresencial) {
      if (this.state.presencial == "") {
        return NotificationManager.error("Selecciona el tipo de atención");
      } else {
        especialidad = this.state.presencial;
      }
    }

    if (this.state.solucion === "Fallecimiento") {
      confirmAlert({
        title: "",
        message: "Seguro que desea finalizar este caso con motivo 'FALLECIDO'? el paciente será eliminado de HoraSalud",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: () => {
              axios
                .patch(
                  global.config.modulos.usuarios + "por_id/" + this.state.usuId,
                  {
                    usu_id: this.state.usuId,
                    usu_estado: 2,
                    usu_motivo_eliminacion: 5,
                  },
                  {
                    headers: authHeader(),
                  }
                )
                .then((res) => {
                  this.guardarSolucion();
                });
            },
          },
        ],
      });
    } else {
      this.guardarSolucion();
    }
  };

  guardarSolucion = () => {
    let especialidad = null;
    axios
      .patch(
        global.config.modulos.gestion,
        {
          geg_id: this.state.gegId,
          geg_solucion: this.state.solucion,
          geg_especialidad: especialidad,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.generarHistorial("Se cambia la solución", this.state.gegId, this.state.uspId);
        this.salir();
      });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.salir();
      });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Cambiar solución</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Indique la solución entregada</InputLabel>
                    <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="solucion" name="solucion" value={this.state.solucion} onChange={this.cambiarValores} label="Solución">
                      <MenuItem value={"Agendar visita domiciliaria"}>Agendar visita domiciliaria</MenuItem>
                      <MenuItem value={"Confección de receta"}>Confección de receta</MenuItem>
                      <MenuItem value={"Entrega a domicilio"}>Entrega a domicilio</MenuItem>
                      <MenuItem value={"Entrega presencial"}>Entrega presencial</MenuItem>
                      <MenuItem value={"Fallecimiento"}>Fallecimiento</MenuItem>
                      <MenuItem value={"No responde"}>No responde</MenuItem>
                      <MenuItem value={"Paciente rechazó atención"}>Paciente rechazó atención</MenuItem>
                      <MenuItem value={"Se agenda atención presencial"}>Se agenda atención presencial</MenuItem>
                      <MenuItem value={"Se agenda teleconsulta"}>Se agenda teleconsulta</MenuItem>
                      <MenuItem value={"Usuario informado"}>Usuario informado</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                {this.state.activarTeleconsulta ? (
                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Seleccione el tipo de atención que se reservó</InputLabel>
                      <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="teleconsulta" name="teleconsulta" value={this.state.teleconsulta} onChange={this.cambiarValor} label="Teleconsulta">
                        <MenuItem value={"TES"}>Teleconsulta Asistenmte Social</MenuItem>
                        <MenuItem value={"TEE"}>Teleconsulta Enfermero</MenuItem>
                        <MenuItem value={"TEK"}>Teleconsulta kinesiólogo</MenuItem>
                        <MenuItem value={"TMA"}>Teleconsulta Matrona</MenuItem>
                        <MenuItem value={"TEM"}>Teleconsulta Médico</MenuItem>
                        <MenuItem value={"TEN"}>Teleconsulta Nutrición</MenuItem>
                        <MenuItem value={"TEO"}>Teleconsulta Odontólogo</MenuItem>
                        <MenuItem value={"TEP"}>Teleconsulta Psicólogo</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}
                {this.state.activarPresencial ? (
                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Seleccione el tipo de atención que se reservó</InputLabel>
                      <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="presencial" name="presencial" value={this.state.presencial} onChange={this.cambiarValor} label="Presencial">
                        <MenuItem value={"P_ASO"}>Asistente Social Presencial</MenuItem>
                        <MenuItem value={"P_DEN"}>Dental Presencial</MenuItem>
                        <MenuItem value={"P_ENF"}>Enfermero Presencial</MenuItem>
                        <MenuItem value={"P_KIN"}>Kinesiólogo Presencial</MenuItem>
                        <MenuItem value={"P_MAT"}>Matrona Presencial</MenuItem>
                        <MenuItem value={"P_MED"}>Médico Presencial</MenuItem>
                        <MenuItem value={"P_NUT"}>Nutrición Presencial</MenuItem>
                        <MenuItem value={"P_PSI"}>Psicólogo Presencial</MenuItem>
                        <MenuItem value={"TDM"}>Toma de muestras</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.cambiarSolucion} style={{ marginLeft: 20 }} color="primary" className="fondoVerde">
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
