import React, { useEffect, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { IdleModal } from "./ModalInactividad";
import { logoutUser } from "../../store/actions/actions";
import { connect } from "react-redux";
import { socketSesion, socketNotificacion } from "../../service/socket";
import { store } from "./../../store/index";
import socket from "socket.io-client/lib/socket";

const LoginTimeout = ({ logoutUser }) => {
  const crono = 60 * 30;
  const cronoModal = 60;
  var tiempo = crono;
  var intervalo;

  const [usp] = React.useState(store.getState().usp);
  const [displayModal, setDisplayModal] = useState(false);
  const [inactive, setInactive] = useState(false);
  const events = ["mousemove", "mousedown", "click", "scroll", "keypress", "visibilitychange"];

  // Reinicia el contador de tiempo
  const resetTimeout = () => {
    // Envia un mensaje al socket para reiniciar el contador en todas las pestañas
    socketSesion.emit("tiempoSesion", { usuario_panel: usp });
    tiempo = crono;
  };

  useEffect(() => {
    // Cierra la sesion si en otra pestana se llama handleLogout()
    socketSesion.on("cerrarSesion" + usp, () => {
      setDisplayModal(false);
      setInactive(true);
      logoutUser();
    });

    // Reinicia el contador de tiempo si en otra pestana se llama resetTimeout()
    socketSesion.on("reiniciarTiempo" + usp, () => {
      tiempo = crono;
    });

    // Cierra el modal si en otra pestana se llama cierreManual()
    socketSesion.on("cerrarMod" + usp, () => {
      handleClose();
    });

    contadorInactividad();

    // Agrega el listener de eventos
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    // Despeja el intervalo al cierre y remueve los eventos
    return () => {
      clearInterval(intervalo);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, []);

  // Inicializa el contador de tiempo
  function contadorInactividad() {
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    intervalo = setInterval(() => {
      if (tiempo > 0) {
        tiempo = tiempo - 1;
      }

      if (tiempo == 0) {
        for (let i in events) {
          window.removeEventListener(events[i], resetTimeout);
        }
        setDisplayModal(true);
        if (inactive) {
          clearInterval(intervalo);
        }
      }
    }, 1000);
  }

  // Funcion para cerrar el modal de advertencia
  function handleClose() {
    setDisplayModal(false);
    resetTimeout();
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
  }

  // Funcion al cerrar el modal manualmente
  function cierreManual() {
    // Envia un mensaje al socket para que cierre el modal en todas las pestanas
    socketSesion.emit("usuarioVuelve", { usuario_panel: usp });
    handleClose();
  }

  // Funcion que maneja el cierre de sesion
  function handleLogout() {
    setDisplayModal(false);
    setInactive(true);
    // Envia un mensaje al socket para que cierre la sesion en todas las pestanas
    socketSesion.emit("autocierre", { usuario_panel: usp });
  }

  return (
    <>
      <IdleModal showModal={displayModal} handleClose={() => cierreManual()} handleLogout={() => handleLogout()} tiempoModal={cronoModal}></IdleModal>
    </>
  );
};

export default connect(null, { logoutUser })(LoginTimeout);
