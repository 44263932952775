import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import chileanRut from "chilean-rut";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import signo from "../../assets/img/signo.svg";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/grid/GridItem";
import GridContainer from "components/grid/GridContainer";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import icono_inscribir from "../../assets/img/inscribirBene.svg";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import ben from "../../assets/css/beneficio.module.css";

import ModalMotivo from "../../components/Beneficios/ModalMotivoLtb";
import ModalDesinscribir from "../../components/Beneficios/ModalDesinscribirLtb";
import { NotificationManager } from "react-notifications";

export default class GestionLatenciaBene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cliente: store.getState().cliente,
      usp: store.getState().usp,
      modalMotivo: false,
      modalConfirmarReingreso: false,
      grabacion: "",
      texto: "",
      fecha: "",
      open: false,
      openD: false,
      modalDesinscribir: false,
      usu: "",
      ben: "",
      ben_nombre: "",
      ordenarPrioridad: null,
      orden: null,
      orden1: null,
      orden2: null,
      searchTerm: "",
      usuConf: "",
      benConf: "",
    };
  }

  componentDidMount() {
    this.titulo();
    this.traerDatos();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Rechazo Dental Junaeb <span title='Paciente Dental Junaeb que rechaza la reserva'><img src=" + signo + "></img></span>";
  };

  activarRegistro = () => {
    let apiUrl = global.config.modulos.beneficios + "datos_latencias_beneficios/";
    axios
      .post(
        apiUrl,
        {
          accion: 1,
          usu: this.state.usuConf,
          ben: this.state.benConf,
          usu_panel: this.state.usp,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.data.estado === "false") {
          NotificationManager.error("Error al activar registro");
        } else {
          NotificationManager.success("Registro activado");
          this.setState({ modalConfirmarReingreso: false });
          this.traerDatos();
        }
      });
  };

  abrirModalMotivo = (grabacion, respuesta_app, fecha) => {
    this.setState(
      {
        grabacion: grabacion,
        texto: respuesta_app,
        fecha: fecha,
        open: true,
      },
      () => {
        this.setState({ modalMotivo: true });
      }
    );
  };

  abrirModalDesinscribir = (u, b, bn) => {
    this.setState({ modalDesinscribir: true, usu: u, ben: b, ben_nombre: bn });
  };

  cerrarModalDesinscribir = () => {
    this.setState({ modalDesinscribir: false });
  };

  cerrarModalMotivo = () => {
    this.setState({ modalMotivo: false });
  };

  traerDatos = () => {
    let apiUrl = global.config.modulos.beneficios + "datos_latencias_beneficios/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          cliente: this.state.cliente,
          orden: this.state.ordenarPrioridad,
        },
      })
      .then((res) => {
        this.setState(
          {
            data: [],
            modalDesinscribir: false,
          },
          () => {
            this.setState({ data: res.data.datos });
          }
        );
      });
  };

  handleFiltradorCampo = (campo) => {
    var campo = campo;
    if (this.state.ordenarPrioridad === null) {
      campo = "-" + campo;
    } else if (this.state.ordenarPrioridad === "-" + campo) {
      campo = campo;
    } else if (this.state.ordenarPrioridad === campo) {
      campo = null;
    }
    this.setState(
      {
        ordenarPrioridad: campo,
      },
      () => {
        if (this.state.ordenarPrioridad === "-fecha" || this.state.ordenarPrioridad === "fecha") {
          if (this.state.ordenarPrioridad === "-fecha") {
            this.setState({ orden: "↑", orden1: "", orden2: "" });
          } else if (this.state.ordenarPrioridad === "fecha") {
            this.setState({ orden: "↓", orden1: "", orden2: "" });
          } else {
            this.setState({ orden: "", orden1: "", orden2: "" });
          }
        } else if (this.state.ordenarPrioridad === "-rut" || this.state.ordenarPrioridad === "rut") {
          if (this.state.ordenarPrioridad === "-rut") {
            this.setState({ orden1: "↑", orden: "", orden2: "" });
          } else if (this.state.ordenarPrioridad === "rut") {
            this.setState({ orden1: "↓", orden: "", orden2: "" });
          } else {
            this.setState({ orden1: "", orden: "", orden2: "" });
          }
        } else if (this.state.ordenarPrioridad === "-nombre" || this.state.ordenarPrioridad === "nombre") {
          if (this.state.ordenarPrioridad === "-nombre") {
            this.setState({ orden2: "↑", orden1: "", orden: "" });
          } else if (this.state.ordenarPrioridad === "nombre") {
            this.setState({ orden2: "↓", orden1: "", orden: "" });
          } else {
            this.setState({ orden2: "", orden1: "", orden: "" });
          }
        } else {
          this.setState({ orden: "", orden1: "", orden2: "" });
        }

        this.traerDatos();
      }
    );
  };

  activarModalReingreso = (u, b) => {
    this.setState({ modalConfirmarReingreso: false, usuConf: "", benConf: "" }, () => {
      this.setState({
        modalConfirmarReingreso: true,
        usuConf: u,
        benConf: b,
      });
    });
  };

  salirConfir = () => {
    this.setState({ modalConfirmarReingreso: false, usuConf: "", benConf: "" });
  };

  handleSearch = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  render() {
    const { data, searchTerm } = this.state;

    const filteredCargas = data.filter((item) => item.rut.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
      <div className="middle-area">
        <h1 style={{ paddingBottom: "30px", color: "rgb(128, 128, 128)" }}>Paciente Dental Junaeb que rechaza la reserva</h1>
        <div
          style={{
            marginRight: "2%",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Input placeholder="Buscar por RUT" type="text" onChange={this.handleSearch} />
        </div>
        <div className="middle-content" style={{ height: "70%", width: "100%" }}>
          <GridContainer className="latencia_beneficio">
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        <a style={{ cursor: "pointer" }} onClick={() => this.handleFiltradorCampo("fecha")}>
                          Fecha registro {this.state.orden}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a style={{ cursor: "pointer" }} onClick={() => this.handleFiltradorCampo("rut")}>
                          RUT paciente {this.state.orden1}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a style={{ cursor: "pointer" }} onClick={() => this.handleFiltradorCampo("nombre")}>
                          Nombre paciente {this.state.orden2}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <a style={{ cursor: "pointer" }}>Motivo</a>
                      </TableCell>
                      <TableCell align="center">
                        <a style={{ cursor: "pointer" }}>Acciones</a>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCargas.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">{moment(row.fecha).format("DD-MM-YYYY")}</TableCell>
                        <TableCell align="center">{chileanRut.format(row.rut, true)}</TableCell>
                        <TableCell align="center">
                          {row.nombre} {row.apellido_p} {row.apellido_m}
                        </TableCell>
                        <TableCell align="center">
                          <img
                            onClick={() => {
                              this.abrirModalMotivo(row.grabacion, row.respuesta_app, row.fecha);
                            }}
                            src={ver_mas}
                            className="mediumIcon"
                            alt="ver_mas"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <img
                            onClick={(e) => {
                              this.activarModalReingreso(row.usu_id, row.beneficio);
                            }}
                            src={icono_inscribir}
                            className="mediumIcon"
                            alt="icono_inscribir"
                            style={{ marginRight: "10px" }}
                          />
                          <img
                            onClick={() => {
                              this.abrirModalDesinscribir(row.usu_id, row.beneficio, row.ben_nombre);
                            }}
                            src={icono_eliminar}
                            className="mediumIcon"
                            alt="icono_eliminar"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </GridContainer>

          {this.state.modalConfirmarReingreso ? (
            <Dialog open={this.state.modalConfirmarReingreso} onClose={this.salirConfir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="escucharAudio">
              <DialogContent>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h3>¿Estás segur@ que deseas reingresar al paciente para ser contactado nuevamente?</h3>
                    <div
                      style={{
                        paddingTop: "55px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          boxShadow: "none",
                        }}
                        onClick={(e) => this.activarRegistro()}
                      >
                        Activar
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#eaa028",
                          color: "white",
                          boxShadow: "none",
                        }}
                        onClick={() => this.salirConfir()}
                      >
                        Cancelar
                      </Button>{" "}
                    </div>
                  </GridItem>
                </GridContainer>
              </DialogContent>
            </Dialog>
          ) : null}

          {this.state.modalMotivo ? <ModalMotivo ocultar={this.cerrarModalMotivo} grabacion={this.state.grabacion} texto={this.state.texto} fecha={this.state.fecha} open={this.state.open}></ModalMotivo> : null}

          {this.state.modalDesinscribir ? <ModalDesinscribir usu={this.state.usu} ben={this.state.ben} ben_nombre={this.state.ben_nombre} usp={this.state.usp} ocultar={this.cerrarModalDesinscribir} traerDatos={this.traerDatos}></ModalDesinscribir> : null}
        </div>
      </div>
    );
  }
}
