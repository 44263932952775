import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import BeneficioDesinscribir from "./BeneficioDesinscribirRespiratorioAntofagasta.js";
import { store } from "./../../store/index";
import axios from "axios";
import CiclosInscribir from "./../../components/Beneficios/BeneficioInscribirCiclo.js";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioRespiratorioAntofagasta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      ciclo: props.ciclo,
      usuId: props.usuId,
      datosBeneficio: props.beneficio,
      formErrors: {},
      modalBeneficioInscribir: false,
      modalCiclosInscribir: false,
      modalBeneficioDesinscribir: false,
      beneficio: null,
      nombreBeneficio: null,
      open: true,
      herramientas: store.getState().herramientas,
      especialidades: "",
    };
  }

  mostrarCiclosInscribir = () => {
    confirmAlert({
      title: "",
      message: "Recuerda que para activar el Beneficio Respiratorio primero debe realizarse el ingreso con Médic@ y el ingreso con Kinesiología ¿estás seguro que quieres dejar activo el beneficio para que el paciente pueda reservar los controles?",
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .post(
                global.config.modulos.beneficios + "inscribir/",
                {
                  fechaInicio: null,
                  fechaExtra: null,
                  fechaTermino: null,
                  usuario: this.state.usuId,
                  beneficio: this.state.datosBeneficio.ben_id,
                  usuarioPanel: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                // if (res.data.estado) {
                //   NotificationManager.success("Inscrito con éxito");
                // } else {
                //   NotificationManager.error("Ha ocurrido un error al inscribir");
                // }
                // this.traeBeneficios();
                if (this.state.ciclo) {
                  this.setState({ modalCiclosInscribir: true });
                } else {
                  this.traeBeneficios();
                }
              });
          },
        },
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
      ],
    });
  };

  ocultarCiclosInscribir = () => {
    this.setState({ modalCiclosInscribir: false });
    this.traeBeneficios();
  };

  traeBeneficios = () => {
    this.props.traeBeneficios();
  };

  mostrarBeneficioInscribir = (beneficio, nombre, especialidad) => {
    this.setState({
      modalBeneficioInscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
      especialidades: especialidad,
    });
  };

  ocultarBeneficioInscribir = () => {
    this.setState({ modalBeneficioInscribir: false });
    this.asignarDatosProfesional();
    this.traeBeneficios();
  };

  mostrarBeneficioDesinscribir = (beneficio, nombre) => {
    this.setState({
      modalBeneficioDesinscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
    });
  };

  ocultarBeneficioDesinscribir = () => {
    this.setState({ modalBeneficioDesinscribir: false });
    this.traeBeneficios();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  inscribir = () => {
    confirmAlert({
      title: "",
      message: "Recuerda que para activar el Beneficio Respiratorio primero debe realizarse el ingreso con Médic@ y el ingreso con Kinesiología ¿estás seguro que quieres dejar activo el beneficio para que el paciente pueda reservar los controles?",
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .post(
                global.config.modulos.beneficios + "inscribir/",
                {
                  fechaInicio: null,
                  fechaExtra: null,
                  fechaTermino: null,
                  usuario: this.state.usuId,
                  beneficio: this.state.datosBeneficio.ben_id,
                  usuarioPanel: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                if (res.data.estado) {
                  NotificationManager.success("Inscrito con éxito");
                } else {
                  NotificationManager.error("Ha ocurrido un error al inscribir");
                }
                this.traeBeneficios();
              });
          },
        },
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
      ],
    });
  };

  render() {
    const beneficio = this.state.datosBeneficio;

    return (
      <React.Fragment>
        {beneficio.estadoUsuario ? (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Activo
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Fecha de ingreso: </label> {beneficio.usuario.usb_fecha_inicio}
                </p>

                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioDesinscribir(beneficio.ben_id, beneficio.ben_nombre)} className="botonDesinscribirBeneficio">
                      Desinscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        ) : (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Inactivo
                </p>

                <br />
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={this.mostrarCiclosInscribir} className="botonInscribirBeneficio">
                      Inscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        )}

        {this.state.modalBeneficioDesinscribir ? <BeneficioDesinscribir ocultar={this.ocultarBeneficioDesinscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} idPrioridad={this.state.idPrioridad} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioDesinscribir> : null}
        {this.state.modalCiclosInscribir ? <CiclosInscribir ocultar={this.ocultarCiclosInscribir} usuId={this.state.usuId} ciclo={this.state.ciclo}></CiclosInscribir> : null}
      </React.Fragment>
    );
  }
}
