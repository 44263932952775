import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { connect } from "react-redux";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";

import AgregarReparto from "../../components/Repartos/AgregarReparto.js";
import AgregarManual from "../../components/Repartos/AgregarManual.js";
import DetalleContacto from "../../components/Repartos/DetalleContacto.js";
import ModificarPaciente from "../../components/Repartos/ModificarPaciente.js";
import AgregarCargaRni from "../../components/Repartos/AgregarCargaRni.js";

import icono_modificar from "../../assets/img/usuarios/icono_modificar.svg";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import icono_ver_mas from "../../assets/img/usuarios/icono_ver_mas.svg";

import signo from "./../../assets/img/signo.svg";

class ListaVacunacionCovid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      usuario_reparto: 0,
      repartos: [],
      agregarReparto: false,
      agregarManual: false,
      detalleContacto: false,
      modificarPaciente: false,
      agregarRNI: false,
      siglasHerramienta: ["H20"],
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.fetchReparto();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Vacunación Covid <span title=''><img src=" + signo + "></img></span>";
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  fetchReparto = async () => {
    const apiUrl = global.config.modulos.repartos + "pacientes_cargados/?cesfam=" + this.state.cesfam;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;

      var repartos = [];

      var visitas = data.datos;
      var conteoVisitas = visitas.length;
      if (conteoVisitas > 0) {
        for (var j = 0; j < conteoVisitas; j++) {
          var estado = "";
          var estado_visita = visitas[j].rus_estado_visita;
          var vacunado = visitas[j].vacunado;

          if (vacunado == 1) {
            estado = "Vacunado";
          } else if (estado_visita == 3) {
            estado = "Pendiente";
          } else if (estado_visita == 4) {
            estado = "Rechazo Vacuna";
          } else {
            estado = "Ingresado";
          }

          var vis = {
            rus_id: visitas[j].rus_id,
            rut: visitas[j].rus_rut,
            nombre_usuario: visitas[j].nombre_usuario,
            ultima_vacuna: visitas[j].ultima_vacuna,
            proxima_vacuna: visitas[j].proxima_vacuna,
            fecha_proxima_vacuna: visitas[j].fecha_proxima_vacuna,
            direccion: visitas[j].rus_direccion,
            telefono: visitas[j].rus_telefono,
            estado: estado,
          };
          repartos.push(vis);
        }
      }
      this.setState({ repartos: repartos });
    });
  };

  agregarRNI = (e) => {
    this.setState({ agregarRNI: true });
  };

  ocultarAgregarRNI = () => {
    this.setState({ agregarRNI: false });
    this.fetchReparto();
  };

  agregarReparto = (e) => {
    this.setState({ agregarReparto: true });
  };

  ocultarAgregar = () => {
    this.setState({ agregarReparto: false });
    this.fetchReparto();
  };

  agregarManual = (e) => {
    this.setState({ agregarManual: true });
  };

  ocultarAgregarManual = () => {
    this.setState({ agregarManual: false });
    this.fetchReparto();
  };

  modificarPaciente = (usuario_reparto) => {
    this.setState({
      usuario_reparto: usuario_reparto,
      modificarPaciente: true,
    });
  };

  ocultarModificar = () => {
    this.setState({ modificarPaciente: false });
    this.fetchReparto();
  };

  verDetalle = (usuario_reparto) => {
    this.setState({ usuario_reparto: usuario_reparto, detalleContacto: true });
  };

  ocultarDetalle = () => {
    this.setState({ detalleContacto: false });
  };

  confirmarEliminarPaciente(rus_id) {
    let apiUrl = global.config.modulos.repartos + "cantidad_en_domicilio/";

    axios(apiUrl, {
      headers: authHeader(),
      params: {
        rus_id: rus_id,
      },
    }).then((res) => {
      var data = res.data;

      var cantidad_domicilio = data.cantidad_domicilio;
      if (cantidad_domicilio == 1) {
        confirmAlert({
          title: "¿Seguro(a) que deseas eliminar el paciente de la ruta?",
          message: "",
          buttons: [
            {
              label: "Cancelar",
              className: "btnCancelar",
            },
            {
              label: "Aceptar",
              className: "btnAceptar",
              onClick: () => this.eliminarPaciente(rus_id, 1),
            },
          ],
        });
      } else {
        confirmAlert({
          title: "¿Existen pacientes con la misma dirección, deseas eliminarlos a todos o solo al seleccionado?",
          message: "",
          buttons: [
            {
              label: "Cancelar",
              className: "btnCancelar",
            },
            {
              label: "Seleccionado",
              className: "btnAceptar",
              onClick: () => this.eliminarPaciente(rus_id, 1),
            },
            {
              label: "Todos",
              className: "btnAceptar",
              onClick: () => this.eliminarPaciente(rus_id, 2),
            },
          ],
        });
      }
    });
  }

  eliminarPaciente(rus_id, tipo) {
    var data = {
      tipo: tipo,
      rus_id: rus_id,
    };
    let apiUrl = global.config.modulos.repartos + "paciente/";
    const options = {
      method: "DELETE",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.mostrarAlerta(res.data.mensaje);
      this.fetchReparto();
    });
  }

  descargar_excel() {
    let apiUrl = global.config.modulos.repartos + "descargar_pacientes_cargados/?cesfam=" + this.state.cesfam;
    axios((window.location.href = apiUrl), { headers: authHeader() }).then((res) => {});
  }

  cambiarVista = (vista) => {
    this.props.history.push(vista);
  };

  render() {
    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer style={{ "justify-content": "flex-end" }}>
            {this.state.herramientas.includes(this.state.siglasHerramienta[0]) ? (
              <a onClick={() => this.cambiarVista("/graficosVacunas")} className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }}>
                Metas de vacunas
              </a>
            ) : (
              <></>
            )}
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <MaterialTable
                title=""
                columns={[
                  { title: "RUT", field: "rut" },
                  { title: "Nombre", field: "nombre_usuario" },
                  { title: "N° de dosis", field: "ultima_vacuna" },
                  {
                    title: "Fecha próxima dosis",
                    field: "fecha_proxima_vacuna",
                  },
                  { title: "Próxima dosis", field: "proxima_vacuna" },
                  { title: "Dirección", field: "direccion" },
                  { title: "Teléfono", field: "telefono" },
                  { title: "Estado", field: "estado" },
                  {
                    title: "Detalle",
                    render: (rowData) => (
                      <React.Fragment>
                        <div>
                          <img class="btnAccionesAnulacion" src={icono_ver_mas} aria-label="Detalle" title="Detalle" onClick={this.verDetalle.bind(this, rowData.rus_id)}></img>
                        </div>
                      </React.Fragment>
                    ),
                  },
                  {
                    title: "Acciones",
                    render: (rowData) => (
                      <React.Fragment>
                        <div>
                          <img class="btnAccionesAnulacion" src={icono_modificar} aria-label="Modificar" title="Modificar" style={{ marginRight: 10 }} onClick={this.modificarPaciente.bind(this, rowData.rus_id)}></img>
                          <img class="btnAccionesAnulacion" src={icono_eliminar} aria-label="Eliminar" title="Eliminar" onClick={this.confirmarEliminarPaciente.bind(this, rowData.rus_id)}></img>
                        </div>
                      </React.Fragment>
                    ),
                  },
                ]}
                data={this.state.repartos}
                actions={[
                  {
                    icon: "group_add",
                    tooltip: "Cargar pacientes",
                    isFreeAction: true,
                    onClick: (event) => this.agregarReparto(),
                  },
                  {
                    icon: "person_add",
                    tooltip: "Agregar manual",
                    isFreeAction: true,
                    onClick: (event) => this.agregarManual(),
                  },
                  {
                    icon: "person_add",
                    tooltip: "Agregar RNI",
                    isFreeAction: true,
                    onClick: (event) => this.agregarRNI(),
                  },
                  {
                    icon: "download",
                    tooltip: "Descargar excel",
                    isFreeAction: true,
                    onClick: (event) => this.descargar_excel(),
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                }}
              />

              {this.state.agregarReparto ? <AgregarReparto ocultar={this.ocultarAgregar}></AgregarReparto> : null}
              {this.state.detalleContacto ? <DetalleContacto ocultar={this.ocultarDetalle} usuario_reparto={this.state.usuario_reparto}></DetalleContacto> : null}
              {this.state.agregarManual ? <AgregarManual ocultar={this.ocultarAgregarManual}></AgregarManual> : null}
              {this.state.modificarPaciente ? <ModificarPaciente ocultar={this.ocultarModificar} usuario_reparto={this.state.usuario_reparto}></ModificarPaciente> : null}
              {this.state.agregarRNI ? <AgregarCargaRni ocultar={this.ocultarAgregarRNI}></AgregarCargaRni> : null}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaVacunacionCovid);
