import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import chileanRut from "chilean-rut";
// core components
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import Titulo from "../titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import Paginacion from "../Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { Tooltip } from "recharts";
import Buttons from "react-multi-date-picker/components/button";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { NotificationManager } from "react-notifications";
import Input from "@material-ui/core/Input";

export default class AgregarPrioritarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      lapid: props.lap_id,
      open: true,

      prioDetalle: [],
      datosEsp: [],
      datoAtencion: [],
      datoPrioridad: [],

      dato_esp: null,
      dato_atn: null,
      rut: null,
      estado_esp: true,
      estado_atn: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.obtenerEspecialidad();
    this.obtenerPrioridad();
  }
  obtenerEspecialidad = () => {
    axios
      .get(global.config.modulos.prioritarios + "especialidades", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datosEsp: res.data.datos,
          });
        }
      });
  };

  seleccionaresp = (e) => {
    const dato_esp = e.target.value;
    this.setState({ dato_esp: dato_esp, dato_atn: null, estado_esp: false }, () => {
      this.obtenerAtencion();
    });
  };

  obtenerAtencion = () => {
    axios
      .get(global.config.modulos.prioritarios + "especialidades/atencion", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          sigla: this.state.dato_esp,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datoAtencion: res.data.datos,
          });
        }
      });
  };

  seleccionarAtencion = (e) => {
    const dato_atn = e.target.value;
    this.setState({ dato_atn: dato_atn, estado_atn: false });
  };

  obtenerPrioridad = () => {
    axios
      .get(global.config.modulos.prioritarios + "ver_prioridades/", {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datoPrioridad: res.data.datos,
          });
        }
      });
  };

  filtroTexto = (e) => {
    const rut = e.target.value;
    this.setState({ rut: rut });
  };

  AgregarPrioridad = () => {
    if (this.state.rut == null) {
      NotificationManager.error("Debe de ingresar un rut", "Error", 3000);
      return;
    } else if (chileanRut.validate(this.state.rut) == false) {
      NotificationManager.error("Rut no valido", "Error", 3000);
      return;
    } else if (this.state.dato_esp == null) {
      NotificationManager.error("Debe de seleccionar una especialidad para poder seleccionar la atencion", "Error", 3000);
      return;
    } else if (this.state.dato_atn == "null" || this.state.dato_atn == null) {
      NotificationManager.error("Debe de seleccionar una atencion", "Error", 3000);
      return;
    }

    axios
      .post(
        global.config.modulos.prioritarios + "ver_prioritarios/agregar_prioritario",
        {
          rut_usuario: this.state.rut,
          cesfam: this.state.cesfam,
          tipo_atencion: this.state.dato_atn,
          prioridad: this.state.dato_prio,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success(res.data.mensaje, "Exito", 3000);
          this.salir();
        } else {
          NotificationManager.warning(res.data.mensaje, "Error", 3000);
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="buscarUsuarioGestion">
          <DialogContent>
            <DialogContentText style={{ fontSize: "30px", fontWeight: "bold" }}>Agregar paciente prioritario</DialogContentText>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    marginTop: "30",
                    marginBottom: "2%",
                    display: "flex",
                  }}
                >
                  <Input
                    type="text"
                    name="texto_filtro"
                    value={this.state.rut}
                    onChange={this.filtroTexto}
                    placeholder="RUT"
                    style={{
                      padding: 6,
                      border: "none",
                      borderBottom: "1px solid black",
                      background: "transparent",
                      marginTop: "2%",
                      onFocus: {
                        background: "transparent",
                        borderBottom: "1px solid black",
                        border: "none",
                      },
                      height: "35px",
                      width: "185px",
                    }}
                  />
                  <FormControl style={{ width: "150px", marginLeft: "4%" }}>
                    <InputLabel id="demo-simple-select-label">Especialidad</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.dato_esp} label="Tipo" onChange={this.seleccionaresp}>
                      {this.state.datosEsp.map((res, index) => (
                        <MenuItem key={index} value={res.esp_sigla}>
                          {res.esp_nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl disabled={this.state.estado_esp} style={{ width: "150px", marginLeft: "4%" }}>
                    <InputLabel id="demo-simple-select-label">Tipo Atención</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.dato_atn} label="Tipo" onChange={this.seleccionarAtencion}>
                      {this.state.datoAtencion.map((res, index) => (
                        <MenuItem key={index} value={res.esp_id}>
                          {res.esp_nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </GridItem>
              <GridItem></GridItem>
              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Button style={{ marginLeft: "65%" }} color="primary" className="fondoVerde" onClick={this.AgregarPrioridad}>
                  Agregar
                </Button>
                <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 15 }} color="primary" className="fondoRojo">
                  Cancelar
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
