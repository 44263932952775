import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem, MenuItem } from "@material-ui/core";
import { store } from "store/index";

import NotificationManager from "react-notifications/lib/NotificationManager.js";
import { confirmAlert } from "react-confirm-alert";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";

export default class PrioridadEdad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condiciones: [],
      prioridades: [],
      condicionesSeleccionadas: [],
      totem: store.getState().totem,
    };
  }

  componentDidMount() {
    this.traerCondiciones();
    this.cargarCondicionesPorTotem();
  }

  Alerta_mensajes = (titulo, mensaje) => {
    confirmAlert({
      title: titulo,
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  removerCondicionArr = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  traerCondiciones = () => {
    const apiUrl = global.config.modulos.usuarios + "condiciones/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ condiciones: res.data.datos });
    });
  };

  guardarCondicionCui = (event) => {
    let condiciones = this.state.condicionesSeleccionadas;
    let valor = event.target.value;

    if (valor == null || valor == NaN) {
      return;
    }

    valor = parseInt(valor);

    if (condiciones.includes(valor)) {
      condiciones = this.removerCondicionArr(condiciones, valor);
    } else {
      condiciones.push(valor);
    }
    console.log(valor);

    this.setState({ condicionesSeleccionadas: condiciones });
  };

  guardarPrioridadCondicion = () => {
    let totemid = this.state.totem;
    let condiciones = this.state.condicionesSeleccionadas;

    const apiUrl = global.config.modulos.admin_totem + "prioridad_por_condicion/";
    axios.post(apiUrl, { totemid: totemid, condiciones: condiciones }, { headers: authHeader() }).then((res) => {
      let titulo = "Cambios guardados exitosamente";
      let mensaje = "";
      this.Alerta_mensajes(titulo, mensaje);
    });
  };

  cargarCondicionesPorTotem = () => {
    let totemid = this.state.totem;

    const apiUrl = global.config.modulos.admin_totem + "prioridad_por_condicion/";
    axios.get(apiUrl, { headers: authHeader(), params: { totemid: totemid } }).then((res) => {
      if (res.data.estado) {
        let condiciones = res.data.condiciones.split(";").slice(0, -1);
        condiciones = condiciones.map((str) => parseInt(str, 10));
        this.setState({ condicionesSeleccionadas: condiciones });
      }
    });
  };
  render() {
    const { condiciones } = this.state;
    return (
      <>
        <Grid item xs={10} style={{ marginTop: "20px", marginLeft: "10px" }}>
          <FormControl>
            <FormLabel component="legend">Condiciones</FormLabel>
            <FormGroup style={{ display: "inline" }}>
              {condiciones &&
                condiciones.map((con, index) => {
                  if (con.con_id == 1) {
                    //validacion temporal ya que no se puede quitar de momento la condicion "ninguna"
                    return false;
                  }
                  console.log(this.state.condicionesSeleccionadas.includes(con.con_id));
                  return <FormControlLabel key={index} control={<Checkbox value={con.con_id} onClick={(e) => this.guardarCondicionCui(e)} checked={this.state.condicionesSeleccionadas.includes(con.con_id) ? true : false} name={con.con_nombre} />} label={con.con_nombre} />;
                })}
            </FormGroup>
          </FormControl>

          <button
            style={{
              position: "relative",
              float: "right",
              zIndex: "9",
              height: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.1rem",
              color: "#fff",
              padding: "9px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
              marginRight: "20px",
              backgroundColor: "#eaa028 !important",
              background: "orange",
              width: "200px",
              marginTop: "25px",
            }}
            onClick={() => {
              this.guardarPrioridadCondicion();
            }}
          >
            Guardar
          </button>
        </Grid>
      </>
    );
  }
}
