import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { NotificationManager } from "react-notifications";
import DateFnsUtils from "@date-io/date-fns";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class Motivo extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      fechaRepetir: null,
      gegId: props.gegId,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    this.setState({ fechaRepetir: e });
  };

  repetirCaso = () => {
    let fecha = moment(this.state.fechaRepetir).format("YYYY-MM-DD");

    axios
      .patch(
        global.config.modulos.gestion,
        {
          geg_id: this.state.gegId,
          geg_repeticion_desde: fecha,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.generarHistorial("se repite el caso para el " + fecha, this.state.gegId, this.state.uspId);
        NotificationManager.success("Recordatorio ingresado con éxito");

        this.salir();
      });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.salir();
      });
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="">
                <GridContainer className="agendar-container">
                  <GridItem xs={12}>
                    <h2>Repetir caso</h2>
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      disablePast="true"
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha"
                      value={this.state.fechaRepetir}
                      name="fechaRepetir"
                      onChange={(e) => this.cambiarValores(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                      Cancelar
                    </Button>
                    <Button onClick={this.repetirCaso} style={{ marginLeft: 20 }} color="primary" className="fondoVerde">
                      Aceptar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </MuiPickersUtilsProvider>
    );
  }
}
