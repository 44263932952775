import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem, MenuItem } from "@material-ui/core";
import { store } from "store/index";

import InputLabel from "@material-ui/core/InputLabel";

import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NotificationManager from "react-notifications/lib/NotificationManager.js";
import { Select } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import crearTotem from "assets/css/crearTotem.module.css";

import eliminar from "assets/img/usuarios/icono_eliminar.svg";
import modificar from "assets/img/admin/modificar.svg";
import MaterialTable from "material-table";

export default class PrioridadCondicion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genero: "nulo",
      condiciones: [],
      prioridades: [],
      condicionesSeleccionadas: [],
      modPrioridad: false,
      id_prioridad: null,
      prioridadDesde_id: "0",
      prioridadHasta_id: "0",
      genero_id: "nulo",
      totem: store.getState().totem,
    };
  }

  componentDidMount() {
    this.configuracion_prioridad();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  Alerta_mensajes = (titulo, mensaje) => {
    confirmAlert({
      title: titulo,
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  configuracion_prioridad = () => {
    const apiUrl = global.config.modulos.admin_totem + "ver_prioridades/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ prioridades: res.data.mensaje });
        } else {
          this.setState({ prioridades: [] });
        }
      });
  };

  configuracion_prioridad_por_id = (id_prioridad) => {
    const apiUrl = global.config.modulos.admin_totem + "ver_prioridades_por_id/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
          id_prioridad: id_prioridad,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            genero_id: res.data.mensaje[0].ptt_genero,
            prioridadDesde_id: res.data.mensaje[0].ptt_edad_menor,
            prioridadHasta_id: res.data.mensaje[0].ptt_edad_mayor,
          });
        } else {
          this.setState({
            genero_id: null,
            prioridadDesde_id: "0",
            prioridadHasta_id: "0",
          });
        }
      });
  };
  cambiarValores = (e) => {
    const { name, value } = e.target;
    console.log(value);
    this.setState({ [name]: value });
  };

  modificar_configuracion = () => {
    var data = { totem: this.state.totem, genero: this.state.genero };
    if (this.state.genero == "nulo") {
      NotificationManager.error("Seleccione Genero");
      return;
    }

    //var genero = document.getElementById("genero").value;
    var prioridadHasta = document.getElementById("prioridadHasta").value;
    var prioridadDesde = document.getElementById("prioridadDesde").value;

    data["desde"] = prioridadDesde;
    data["hasta"] = prioridadHasta;

    axios.post(global.config.modulos.admin_totem + "agregar_prioridad/", data, { headers: authHeader() }).then((res) => {
      this.mostrarAlerta("Guardado con éxito");
      this.configuracion_prioridad();
    });
  };

  modificar_prioridad = () => {
    var data = {
      totem: this.state.totem,
      genero: this.state.genero_id,
      id_prioridad: this.state.id_prioridad,
      desde: this.state.prioridadDesde_id,
      hasta: this.state.prioridadHasta_id,
    };
    if (this.state.genero_id == "nulo") {
      NotificationManager.error("Seleccione Genero");
      return;
    }

    axios.patch(global.config.modulos.admin_totem + "modificar_prioridad/", data, { headers: authHeader() }).then((res) => {
      this.mostrarAlerta("Modificado con exito");
    });
    this.cerrarModificarPrioridad();
  };
  abrirModificarPrioridad = (id_prioridad) => {
    console.log(id_prioridad);
    this.setState({ modPrioridad: true, id_prioridad: id_prioridad });
    this.configuracion_prioridad_por_id(id_prioridad);
  };
  cerrarModificarPrioridad = () => {
    this.setState({ modPrioridad: false, id_prioridad: null }, () => {
      this.configuracion_prioridad();
    });
  };
  eliminar_prioridad = (id_prioridad) => {
    const apiUrl = global.config.modulos.admin_totem + "eliminar_prioridad/";
    const data = { totem: this.state.totem, id_prioridad: id_prioridad };
    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
      this.mostrarAlerta("Eliminado con exito");
      this.configuracion_prioridad();
    });
  };

  render() {
    return (
      <>
        <GridItem xs={9} sm={9} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} style={{ marginRight: "40px" }}>
              <InputLabel className={crearTotem.textosTitulos}>Genero</InputLabel>
              <Select labelId="demo-simple-select-label" id="genero" name="genero" disableUnderline={true} className={crearTotem.selectores} value={this.state.genero} onChange={this.cambiarValores}>
                <MenuItem value={"nulo"}>Seleccione genero</MenuItem>
                <MenuItem value={"todos"}>Seleccionar todo</MenuItem>
                <MenuItem value={"M"}>Masculino</MenuItem>
                <MenuItem value={"F"}>Femenino</MenuItem>
                <MenuItem value={"I"}>Intersex</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={2} sm={2}>
              <InputLabel className={crearTotem.textosTitulos}>Edad desde</InputLabel>
              <input type="number" id="prioridadDesde" className={crearTotem.input} defaultValue={"0"} min="0" max="200" />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputLabel className={crearTotem.textosTitulos}>Edad hasta</InputLabel>
              <input type="number" id="prioridadHasta" className={crearTotem.input} defaultValue={"0"} min="0" max="200" />
            </Grid>
          </Grid>
          <a
            className={crearTotem.btnAgregarAcceso}
            style={{ width: "15%" }}
            onClick={() => {
              this.modificar_configuracion();
            }}
          >
            {"Guardar"}
          </a>
        </GridItem>

        <GridItem xs={9} sm={9} md={9}>
          <div className={crearTotem.divBordeTablaAccesos} style={{ fontSize: "1rem !important" }}>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "Generos",
                  field: "ptt_genero",
                },
                {
                  title: "Edad desde",
                  field: "ptt_edad_menor",
                },
                {
                  title: "Edad hasta",
                  field: "ptt_edad_mayor",
                },
                {
                  title: "Acciones",
                  render: (rowData) => (
                    <React.Fragment>
                      <p>
                        <img className="botonesTablas" src={modificar} style={{ width: "40px", marginRight: "15px" }} onClick={() => this.abrirModificarPrioridad(rowData.ptt_id)} />
                        <img className="botonesTablas" style={{ width: "40px" }} src={eliminar} onClick={() => this.eliminar_prioridad(rowData.ptt_id)} />
                      </p>
                    </React.Fragment>
                  ),
                },
              ]}
              data={this.state.prioridades}
              options={{
                actionsColumnIndex: -1,
                search: false,
              }}
              localization={{
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "filas",
                  firstTooltip: "primera",
                  previousTooltip: "anterior",
                  nextTooltip: "siguiente",
                  lastTooltip: "última",
                },
                toolbar: {
                  nRowsSelected: "{0} fila(s) seleccionadas",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No tiene historial de atenciones",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </div>

          {this.state.modPrioridad ? (
            <div>
              <Dialog open={this.state.modPrioridad} onClose={this.cerrarModificarPrioridad} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
                <DialogContent>
                  <h1 style={{ color: "#666666" }}>Modificar Prioridad</h1>
                  <br></br>
                  <br></br>

                  <Grid item xs={12} sm={12} style={{ width: "500px" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <InputLabel className={crearTotem.textosTitulos}>Genero</InputLabel>
                        <Select labelId="demo-simple-select-label" disableUnderline={true} id="genero_id" name="genero_id" className={crearTotem.selectores} value={this.state.genero_id} onChange={this.cambiarValores}>
                          <MenuItem value={"nulo"}>Seleccione genero</MenuItem>
                          <MenuItem value={"todos"}>Seleccionar todo</MenuItem>
                          <MenuItem value={"M"}>Masculino</MenuItem>
                          <MenuItem value={"F"}>Femenino</MenuItem>
                          <MenuItem value={"I"}>Intersex</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <InputLabel className={crearTotem.textosTitulos}>Edad desde</InputLabel>
                        <input type="number" id="prioridadDesde_id" name="prioridadDesde_id" value={this.state.prioridadDesde_id} onChange={this.cambiarValores} className={crearTotem.input} defaultValue={"0"} min="0" max="200" />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <InputLabel className={crearTotem.textosTitulos}>Edad hasta</InputLabel>
                        <input type="number" id="prioridadHasta_id" name="prioridadHasta_id" value={this.state.prioridadHasta_id} onChange={this.cambiarValores} className={crearTotem.input} defaultValue={"0"} min="0" max="200" />
                      </Grid>
                    </Grid>
                    <br></br>
                    <a
                      className={crearTotem.btnAgregarAcceso}
                      style={{
                        width: "6vw",
                        height: "40px",
                        marginTop: "15px",
                      }}
                      onClick={() => {
                        this.modificar_prioridad();
                      }}
                    >
                      {"Guardar"}
                    </a>
                  </Grid>
                </DialogContent>
              </Dialog>
            </div>
          ) : null}
        </GridItem>
      </>
    );
  }
}
