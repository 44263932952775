import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import MaterialTable, { MTableHeader, MTableBody, MTablePagination } from "material-table";
import "react-confirm-alert/src/react-confirm-alert.css";
import { store } from "./../../store/index";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import icono_vista_6 from "../../assets/img/usuarios/icono_vista_6.svg";
// iconos final
import iconoEliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import iconoModificar from "../../assets/img/usuarios/icono_modificar.svg";
import iconoAsist from "../../assets/img/metas/asistida_manual.svg";
import iconoVer from "../../assets/img/usuarios/icono_ver_mas.svg";
import iconoVerDisabled from "../../assets/img/metas/ver_mas_desactivado.svg";

import anunciarLlegada from "../../assets/img/usuarios/icono_anunciar_llegada.svg";
import AgregarMeta from "./AgregarMeta.js";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AsistidaGeneral from "./AsistidaGeneral";
import AsistidaDetalle from "./AsistidaDetalle";
import { AssignmentReturnedSharp } from "@material-ui/icons";
// estilos
//import "../../assets/css/agregar.css";

export default class ListadoMetas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metas: [],
      atenciones: [],
      difusion: {},
      canales: "",
      formErrors: {},
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      usuId: props.usuario,

      modalAtenciones: false,
      modalDifusion: false,

      modalElim: false,
      modalAsist: false,
      asistGeneral: false,
      asistDetalle: false,
      modalEdit: false,
      modalNew: false,
      metaAccion: {},
    };

    this.cerrarAsistidaGeneral = this.cerrarAsistidaGeneral.bind(this);
    this.cerrarAsistidaDetalle = this.cerrarAsistidaDetalle.bind(this);
    this.editarMetaCancel = this.editarMetaCancel.bind(this);
  }

  componentDidMount() {
    this.traerMetas();
  }

  traerMetas() {
    let apiUrl = this.props.activas ? global.config.modulos.metas + "lista_metas/?cesfam=" + this.state.cesfam : global.config.modulos.metas + "lista_metas_inactivas/?cesfam=" + this.state.cesfam + "&usp=" + this.state.uspId;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ metas: res.data.meta });
    });
  }

  traerAtenciones() {
    let apiUrl = global.config.modulos.metas + "detalle_metas_atencion/?meta=" + this.state.metaAccion.met_id;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ atenciones: res.data.meta, modalAtenciones: true });
    });
  }

  traerDifusion() {
    let apiUrl = global.config.modulos.metas + "detalle_metas_difusion/?meta=" + this.state.metaAccion.met_id;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ difusion: res.data.dif[0], canales: res.data.canales, modalDifusion: true });
    });
  }

  tipoPeriodo(tipo) {
    switch (tipo) {
      case 1:
        return "Mensual";
      case 2:
        return "Trimestral";
      case 3:
        return "Semestral";
      case 4:
        return "Anual";
    }
  }

  tipoArea(tipo) {
    switch (tipo) {
      case 1:
        return "Por Comuna";
      case 2:
        return "Por Cesfam";
      case 3:
        return "Por Sector";
    }
  }

  tipoDifus(tipo) {
    switch (tipo) {
      case 1:
        return "Información";
      case 2:
        return "Encuestas";
      case 3:
        return "Reserva";
      case 4:
        return "Inscripción";
    }
  }

  editarMetaModal(rowData) {
    this.setState({ modalEdit: true, metaAccion: rowData });
  }

  editarMetaCancel(rowData) {
    this.setState({ modalEdit: false }, () => {
      this.setState({ metaAccion: {} });
      this.traerMetas();
    });
  }

  eliminarMeta() {
    var data = {
      meta: {
        met_estado: 0,
      },
    };

    let apiUrl = global.config.modulos.metas + "editar_meta/" + this.state.metaAccion.met_id;

    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      this.traerMetas();
      this.eliminarMetaCancel();
      NotificationManager.info("Meta eliminada correctamente");
    });
  }

  eliminarMetaConfirm(rowData) {
    this.setState({ modalElim: true, metaAccion: rowData });
  }

  eliminarMetaCancel(rowData) {
    this.setState({ modalElim: false }, () => {
      this.setState({ metaAccion: {} });
    });
  }

  verAtenciones(rowData) {
    this.setState({ metaAccion: rowData }, () => {
      this.traerAtenciones();
    });
  }

  cerrarAtenciones() {
    this.setState({ modalAtenciones: false });
  }

  verDifusion(rowData) {
    this.setState({ metaAccion: rowData }, () => {
      this.traerDifusion();
    });
  }

  cerrarDifusion() {
    this.setState({ modalDifusion: false });
  }

  mostrarModalAsistencia(rowData) {
    this.setState({ metaAccion: rowData, modalAsist: true });
  }

  cerrarModalAsistencia() {
    this.setState({ modalAsist: false }, () => {
      this.setState({ metaAccion: {} });
    });
  }

  mostrarAsistidaGeneral(rowData) {
    this.setState({ asistGeneral: true });
  }

  cerrarAsistidaGeneral() {
    this.setState({ asistGeneral: false }, () => {
      this.cerrarModalAsistencia();
    });
  }

  mostrarAsistidaDetalle(rowData) {
    this.setState({ asistDetalle: true });
  }

  cerrarAsistidaDetalle() {
    this.setState({ asistDetalle: false }, () => {
      this.cerrarModalAsistencia();
    });
  }

  render() {
    return (
      <>
        <div className="historialHoras">
          <Grid container spacing={2} className="fichaUsuario scroll-container">
            <Grid item xs={12}>
              <div>
                <div className="datosUsuario " style={{ border: "none", marginTop: "0", marginLeft: "0" }}>
                  <TableContainer style={{ width: "100%", marginTop: "" }}>
                    <Table aria-label="simple table" className="" style={{ textAlign: "center" }}>
                      <MaterialTable
                        title=""
                        columns={[
                          {
                            title: "Nombre Meta",
                            field: "met_nombre",
                            headerStyle: { fontSize: "16px" },
                          },
                          {
                            title: "Meta",
                            field: "met_meta",
                            headerStyle: { fontSize: "16px" },
                          },
                          {
                            title: "Periodo",
                            field: "met_periodo",
                            headerStyle: { fontSize: "16px" },
                            render: (rowData) => <p>{this.tipoPeriodo(rowData.met_periodo)}</p>,
                          },
                          {
                            title: "Área",
                            field: "met_area",
                            headerStyle: { fontSize: "16px" },
                            render: (rowData) => <p>{this.tipoArea(rowData.met_area)}</p>,
                          },
                          {
                            title: "Tipos de atención",
                            headerStyle: { fontSize: "16px" },
                            render: (rowData) => (
                              <>
                                <Tooltip title="Ver Tipos de Atención" aria-label="" style={{ marginRight: 2 }}>
                                  <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.verAtenciones.bind(this, rowData)} src={iconoVer} />
                                </Tooltip>
                              </>
                            ),
                          },
                          {
                            title: "Alerta",
                            headerStyle: { fontSize: "16px" },
                            render: (rowData) => <p>Ninguna</p>,
                          },
                          {
                            title: "Difusión",
                            headerStyle: { fontSize: "16px" },
                            render: (rowData) => (
                              <>
                                {rowData.met_difusion ? (
                                  <Tooltip title="Ver Difusión" aria-label="" style={{ marginRight: 2 }}>
                                    <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.verDifusion.bind(this, rowData)} src={iconoVer} />
                                  </Tooltip>
                                ) : (
                                  <>
                                    <Tooltip title="No posee difusión" aria-label="" style={{ marginRight: 2 }}>
                                      <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} src={iconoVerDisabled} />
                                    </Tooltip>
                                  </>
                                )}
                              </>
                            ),
                          },
                          {
                            title: "Acciones",
                            headerStyle: { fontSize: "16px" },
                            hidden: this.props.activas ? false : true,
                            render: (rowData) => (
                              <>
                                <Tooltip title="Editar" aria-label="">
                                  <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.editarMetaModal.bind(this, rowData)} src={iconoModificar} />
                                </Tooltip>
                                <Tooltip title="Asistida Manual" aria-label="">
                                  <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.mostrarModalAsistencia.bind(this, rowData)} src={iconoAsist} />
                                </Tooltip>
                                <Tooltip title="Eliminar" aria-label="">
                                  <img className="mediumIcon" style={{ marginTop: 0 }} onClick={this.eliminarMetaConfirm.bind(this, rowData)} src={iconoEliminar} />
                                </Tooltip>
                              </>
                            ),
                          },
                        ]}
                        data={this.state.metas}
                        options={{
                          actionsColumnIndex: -1,
                          sorting: false,
                          search: true,
                          toolbar: false,
                          actions: true,
                          exportAllData: true,
                          exportButton: true,
                        }}
                        localization={{
                          header: {
                            actions: "a",
                          },
                          toolbar: {
                            searchPlaceholder: "Buscar",
                            searchTooltip: "Buscar",
                            exportTitle: "Exportar",
                            exportAriaLabel: "Exportar",
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                          },
                          body: {
                            emptyDataSourceMessage: "No se encuentran metas para este cesfam",
                          },
                          pagination: {
                            labelRowsSelect: "Filas",
                            labelDisplayedRows: "{from}-{to} de {count}",
                          },
                        }}
                        components={{
                          Header: (props) => (
                            <div className="datosUsuario " style={{ border: "", borderBottom: "none", borderBottomLeftRadius: "0", borderBottomRightRadius: "0", marginTop: "0", marginLeft: "0" }}>
                              <MTableHeader {...props} />
                            </div>
                          ),

                          Body: (props) => (
                            <div className="datosUsuario " style={{ border: "", borderTop: "none", borderTopLeftRadius: "0", borderTopRightRadius: "0", marginTop: "0", marginLeft: "0" }}>
                              <MTableBody {...props} />
                            </div>
                          ),
                        }}
                      ></MaterialTable>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <>
          {this.state.modalAtenciones ? (
            <Dialog open={this.state.modalAtenciones} onClose={this.cerrarAtenciones.bind(this)} className="modificarUsuario">
              <DialogContent>
                <GridContainer className="">
                  <GridItem xs={12}>
                    <GridContainer className="">
                      <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                        <h1>Tipos de Atención</h1>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 50 }}>
                      <div className="datosUsuario " style={{ border: "", marginTop: "0", marginLeft: "0" }}>
                        <TableContainer style={{ width: "100%", marginTop: "" }}>
                          <Table aria-label="simple table" className="" style={{ textAlign: "center" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal", paddingLeft: 30 }}>Nombre Meta</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal", paddingRight: 30 }}>Tipos de Atención</h3>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.atenciones.map((atencion, index) => (
                                <TableRow>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <h3 style={{ fontWeight: "normal", paddingLeft: 30 }}>{index === 0 ? this.state.metaAccion.met_nombre : null}</h3>
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <h3 style={{ fontWeight: "normal", paddingRight: 30 }}>{atencion.atencion}</h3>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                      <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.cerrarAtenciones.bind(this)}>
                        Atrás
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </DialogContent>
            </Dialog>
          ) : null}

          {this.state.modalDifusion ? (
            <Dialog open={this.state.modalDifusion} onClose={this.cerrarDifusion.bind(this)} className="modificarUsuario">
              <DialogContent>
                <GridContainer className="">
                  <GridItem xs={12}>
                    <GridContainer className="">
                      <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                        <h1>Difusión de meta</h1>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <TableCell style={{ textAlign: "center" }}>
                        <h3>Nombre Meta</h3>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <h3 style={{ fontWeight: "normal" }}> {this.state.metaAccion.met_nombre}</h3>
                      </TableCell>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 15 }}>
                      <div className="datosUsuario " style={{ border: "", marginTop: "0", marginLeft: "0" }}>
                        <TableContainer style={{ width: "100%", marginTop: "" }}>
                          <Table aria-label="simple table" className="" style={{ textAlign: "center" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ paddingLeft: 30, fontWeight: "normal" }}>Nombre Difusión</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>Código Difusión</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>Tipo</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>Canales</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>Inicio</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ paddingRight: 30, fontWeight: "normal" }}>Fin</h3>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal", paddingLeft: 30 }}>{this.state.difusion.dic_nombre}</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>{this.state.difusion.dic_codigo}</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>{this.tipoDifus(this.state.difusion.dic_tipo)}</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>{this.state.canales}</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal" }}>{moment(this.state.difusion.dic_fecha_inicio).format("DD-MM-YYYY")}</h3>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <h3 style={{ fontWeight: "normal", paddingRight: 30 }}>{moment(this.state.difusion.dic_fecha_termino).format("DD-MM-YYYY")}</h3>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                      <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.cerrarDifusion.bind(this)}>
                        Atrás
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </DialogContent>
            </Dialog>
          ) : null}

          {this.state.modalElim ? (
            <Dialog open={this.state.modalElim} onClose={this.eliminarMetaCancel.bind(this)} className="modificarUsuario">
              <DialogContent>
                <GridContainer className="">
                  <GridItem xs={12}>
                    <GridContainer className="">
                      <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                        <h1>¿Seguro que desea eliminar la meta {this.state.metaAccion.met_nombre}?</h1>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                      <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8, color: "white" }} className="fondoRojoBeneficio" onClick={this.eliminarMeta.bind(this)}>
                        Eliminar
                      </Button>
                      <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.eliminarMetaCancel.bind(this)}>
                        Atrás
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </DialogContent>
            </Dialog>
          ) : null}

          {this.state.modalAsist ? (
            <Dialog open={this.state.modalAsist} onClose={this.cerrarModalAsistencia.bind(this)} className="modificarUsuario">
              <DialogContent>
                <GridContainer className="">
                  <GridItem xs={12}>
                    <GridContainer className="">
                      <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                        <h1>Asistida Manual</h1>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer className="">
                  <GridItem xs={12}>
                    <GridContainer className="">
                      <GridItem xs={12} style={{ marginTop: 20, marginLeft: 10, display: "flex", justifyContent: "flex-end" }}>
                        <Button style={{ marginRight: 15, paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.mostrarAsistidaGeneral.bind(this)}>
                          <h3 style={{ fontWeight: "normal" }}>Asistida manual general</h3>
                        </Button>
                        <Button style={{ marginRight: 14, marginleft: 15, paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.mostrarAsistidaDetalle.bind(this)}>
                          <h3 style={{ fontWeight: "normal" }}>Asistida manual detalle</h3>
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                      <Button style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.cerrarModalAsistencia.bind(this)}>
                        Cerrar
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </DialogContent>
            </Dialog>
          ) : null}

          {this.state.modalEdit ? <AgregarMeta ocultar={this.editarMetaCancel} editar={true} met_edit={this.state.metaAccion}></AgregarMeta> : null}

          {this.state.asistGeneral ? <AsistidaGeneral met={this.state.metaAccion} ocultar={this.cerrarAsistidaGeneral} /> : null}

          {this.state.asistDetalle ? <AsistidaDetalle met={this.state.metaAccion} ocultar={this.cerrarAsistidaDetalle} /> : null}
        </>
      </>
    );
  }
}
