import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import "moment/locale/es";
import { store } from "../../../store/index";

// core components
import { Grid, Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "./../../../components/grid/GridContainer.js";

//JS
//IMAGENES
//CSS
import SolicitudTrabajoCSS from "../../../assets/css/solicitudTrabajo.module.css";
import moment from "moment";

class ModalSolicitud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vista: this.props.vista,
      data: this.props.data,
      cesfam: this.props.data.sol_cesfam,
      titulo: this.props.titulo,
      arrPrioridades: this.props.prioridades,
      arrDesarrolladores: this.props.desarrolladores,
      usp_id: store.getState().usp,

      //Datos
      observacion: "",
      motivo_reprogramar: "",
      fecha_reprogramada: "",
      motivo_rechazo: "",
      fecha_entrega_tecnico: "",
      motivo_rechazo_qa: "",
      observacion_cliente: "",
      fec_desde: "",
      fec_hasta: "",
      cant_opciones: "",
      tipo_fecha: "",
      motivo_rechazo_cesfam: "",
      hras_desarrollo: 0,
      checkBox: 0,
    };
  }

  componentWillUnmount() {
    this.setState({
      observacion: "",
      motivo_reprogramar: "",
      fecha_reprogramada: "",
      motivo_rechazo: "",
      fecha_entrega_tecnico: "",
      motivo_rechazo_qa: "",
      observacion_cliente: "",
      fec_desde: "",
      fec_hasta: "",
      cant_opciones: "",
      tipo_fecha: "",
    });
  }

  componentDidMount() {
    this.setState({ observacion: this.props.data.sol_obs, fecha_entrega_tecnico: this.props.data.sol_fecha_entrega_dev });
    if (this.props.data.sol_tipo == 1) {
      this.setState({ prio: 7 });
    }
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  valorCheckbox = (valor) => {
    this.setState({ checkBox: valor });
  };

  renderizarVista = () => {
    const { data, vista, observacion, motivo_reprogramar, fecha_reprogramada, arrDesarrolladores, arrPrioridades, motivo_rechazo, fecha_entrega_tecnico, motivo_rechazo_qa, observacion_cliente, fec_desde, fec_hasta, hra_desde, hra_hasta, motivo_rechazo_cesfam, entrega_aprox_cliente, hras_desarrollo, usp_id } = this.state;

    if (vista == 1) {
      /* SOLO VISUALIZACION DEL MOTIVO */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={8} sm={8} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
              <br />
              <textarea value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 2) {
      /* SOLO VISUALIZACION DE LA OBSERVACION */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={8} sm={8} className={SolicitudTrabajoCSS.paddingGrid}>
              <Tooltip title="Observacion hacia el ingeniero" aria-label="Observacion hacia el ingeniero">
                <label className={SolicitudTrabajoCSS.labelInput}>Observación interna</label>
              </Tooltip>
              <br />
              <textarea disabled value={data.sol_obs} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 3) {
      /* EDITAR OBSERVACION INTERNA */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
              <br />
              <textarea value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Observación interna</label>
              <br />
              <textarea
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                onChange={this.cambiarValores}
                value={observacion}
                name="observacion"
                id="observacion"
                className={SolicitudTrabajoCSS.textareaModalSolicitud}
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 4) {
      /* REPROGRAMACION DE FECHA DE ENTREGA */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Prioridad</label>
              <br />
              <input value={data.prio_desc} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Técnico asignado</label>
              <br />
              <input value={data.nom_dev} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>{data.sol_entrega_aprox_cliente ? "Fecha de entrega anterior" : "Fecha comprometida cliente"}</label>
              <br />
              {data.sol_entrega_aprox_cliente ? <input value={data.entrega_aprox_cliente} type="date" className={SolicitudTrabajoCSS.inputModalSolicitud} /> : <input onChange={this.cambiarValores} value={entrega_aprox_cliente} name="entrega_aprox_cliente" id="entrega_aprox_cliente" min={moment().format("YYYY-MM-DD")} type="date" className={SolicitudTrabajoCSS.inputModalSolicitud} style={{ border: "solid 3px #ea9f29", color: "#4d4d4c" }} />}
            </Grid>

            {data.sol_entrega_aprox_cliente && (
              <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                <label className={SolicitudTrabajoCSS.labelInput}>Nueva fecha de entrega</label>
                <br />
                <input onChange={this.cambiarValores} value={fecha_reprogramada} name="fecha_reprogramada" id="fecha_reprogramada" min={data.sol_entrega_aprox_cliente} type="date" className={SolicitudTrabajoCSS.inputModalSolicitud} style={{ border: "solid 3px #ea9f29", color: "#4d4d4c" }} />
              </Grid>
            )}
          </Grid>
          {data.sol_entrega_aprox_cliente && (
            <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
              <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
                <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
                <br />
                <textarea onChange={this.cambiarValores} value={motivo_reprogramar} name="motivo_reprogramar" id="motivo_reprogramar" className={SolicitudTrabajoCSS.textareaModalSolicitud} style={{ border: "solid 3px #ea9f29", color: "#4d4d4c" }} />
              </Grid>
            </Grid>
          )}
        </>
      );
    } else if (vista == 5 || vista == 16) {
      /* APROBAR SOLICITUD */

      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
              <br />
              <textarea value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Observación interna</label>
              <br />
              <textarea
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                onChange={this.cambiarValores}
                value={observacion}
                name="observacion"
                id="observacion"
                className={SolicitudTrabajoCSS.textareaModalSolicitud}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            {vista == 5 && (
              <>
                <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                  <label className={SolicitudTrabajoCSS.labelInput}>Asignar prioridad</label>
                  <br />
                  <select
                    className={SolicitudTrabajoCSS.inputModalSolicitud}
                    id={"prio"}
                    name={"prio"}
                    disabled={data.sol_tipo == 1 && true}
                    value={data.sol_tipo == 1 ? 7 : this.state.prio}
                    style={{
                      background: "transparent",
                      border: "solid 3px #ea9f29",
                      color: "#4d4d4c",
                    }}
                    onChange={this.cambiarValores}
                  >
                    <option key={0} value={""} selected></option>
                    {arrPrioridades.map((pri) => (
                      <option key={pri.prio_id} value={pri.prio_id}>
                        {pri.prio_id === 1 ? pri.prio_nombre : `${pri.prio_nombre}  ${pri.prio_descripcion}`}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                  <label className={SolicitudTrabajoCSS.labelInput}>Asignado a</label>
                  <br />
                  <select
                    className={SolicitudTrabajoCSS.inputModalSolicitud}
                    id={"dev"}
                    name={"dev"}
                    value={this.state.dev}
                    style={{
                      background: "transparent",
                      border: "solid 3px #ea9f29",
                      color: "#4d4d4c",
                    }}
                    onChange={this.cambiarValores}
                  >
                    <option key={0} value={""}></option>
                    {arrDesarrolladores.map((dev) => (
                      <option key={dev.dev_id} value={dev.dev_id}>
                        {dev.dev_nombre}
                      </option>
                    ))}
                  </select>
                </Grid>
              </>
            )}

            {data.sol_tipo != 1 && data.sol_tipo != 5 ? (
              <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                <label className={SolicitudTrabajoCSS.labelInput}>Fecha comprometida cliente</label>
                <br />
                <input
                  style={{
                    background: "transparent",
                    border: "solid 3px #ea9f29",
                    color: "#4d4d4c",
                  }}
                  name="fecha_reprogramada"
                  id="fecha_reprogramada"
                  min={moment().format("YYYY-MM-DD")}
                  onChange={this.cambiarValores}
                  value={data.sol_entrega_aprox_cliente}
                  type="date"
                  className={SolicitudTrabajoCSS.inputModalSolicitud}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                  <label className={SolicitudTrabajoCSS.labelInput}>Tipo de fecha</label>
                  <br />
                  <select
                    className={SolicitudTrabajoCSS.inputModalSolicitud}
                    id="tipo_fecha"
                    name="tipo_fecha"
                    value={this.state.tipo_fecha}
                    style={{
                      background: "transparent",
                      border: "solid 3px #ea9f29",
                      color: "#4d4d4c",
                    }}
                    onChange={this.cambiarValores}
                  >
                    <option key={0} value={""}></option>
                    <option key={1} value={1}>
                      Fecha cerrada
                    </option>
                    <option key={2} value={2}>
                      Fecha abierta
                    </option>
                  </select>
                </Grid>
                {this.state.tipo_fecha == 1 && (
                  <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                    <label className={SolicitudTrabajoCSS.labelInput}>Cantidad de opciones</label>
                    <br />
                    <select
                      className={SolicitudTrabajoCSS.inputModalSolicitud}
                      id={"cant_opciones"}
                      name={"cant_opciones"}
                      value={this.state.cant_opciones}
                      style={{
                        background: "transparent",
                        border: "solid 3px #ea9f29",
                        color: "#4d4d4c",
                      }}
                      onChange={this.cambiarValores}
                    >
                      <option key={0} value={""}></option>
                      <option key={1} value={1}>
                        1
                      </option>
                      <option key={2} value={2}>
                        2
                      </option>
                      <option key={3} value={3}>
                        3
                      </option>
                      <option key={4} value={4}>
                        4
                      </option>
                    </select>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          {this.state.tipo_fecha == 2 ? (
            <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
              <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                <label className={SolicitudTrabajoCSS.labelInput}>Desde</label>
                <br />
                <input
                  style={{
                    background: "transparent",
                    border: "solid 3px #ea9f29",
                    color: "#4d4d4c",
                  }}
                  name="fec_desde"
                  id="fec_desde"
                  min={moment().format("YYYY-MM-DD")}
                  onChange={this.cambiarValores}
                  value={fec_desde}
                  type="date"
                  className={SolicitudTrabajoCSS.inputModalSolicitud}
                />{" "}
              </Grid>
              <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                <label className={SolicitudTrabajoCSS.labelInput}>Hasta</label>
                <br />
                <input
                  style={{
                    background: "transparent",
                    border: "solid 3px #ea9f29",
                    color: "#4d4d4c",
                  }}
                  name="fec_hasta"
                  id="fec_hasta"
                  disabled={fec_desde == "" && true}
                  min={fec_desde}
                  onChange={this.cambiarValores}
                  value={fec_hasta}
                  type="date"
                  className={SolicitudTrabajoCSS.inputModalSolicitud}
                />
              </Grid>
              <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                <label className={SolicitudTrabajoCSS.labelInput}>Horario desde - hasta</label>
                <br />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <input
                    style={{
                      background: "transparent",
                      border: "solid 3px #ea9f29",
                      color: "#4d4d4c",

                      width: "45%",
                    }}
                    name="hra_desde"
                    id="hra_desde"
                    min="13:00:00"
                    onChange={this.cambiarValores}
                    value={hra_desde}
                    type="time"
                    className={SolicitudTrabajoCSS.inputModalSolicitud}
                  />

                  <input
                    style={{
                      background: "transparent",
                      border: "solid 3px #ea9f29",
                      color: "#4d4d4c",

                      width: "45%",
                    }}
                    name="hra_hasta"
                    id="hra_hasta"
                    min={hra_desde}
                    onChange={this.cambiarValores}
                    value={hra_hasta}
                    type="time"
                    className={SolicitudTrabajoCSS.inputModalSolicitud}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            this.state.tipo_fecha == 1 &&
            this.state.cant_opciones.length > 0 &&
            Array.from({ length: this.state.cant_opciones }, (_, index) => (
              <>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  key={index}
                  className={SolicitudTrabajoCSS.paddingGrid}
                  style={{
                    display: "flex",
                    marginBottom: 25,
                    paddingLeft: index == 1 || index == 3 ? 0 : 20,
                    paddingRight: index == 1 || index == 3 ? 20 : 0,
                  }}
                >
                  <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
                    <label className={SolicitudTrabajoCSS.labelInput}>Fecha opción {index + 1}</label>
                    <br />
                    <input
                      style={{
                        background: "transparent",
                        border: "solid 3px #ea9f29",
                        color: "#4d4d4c",
                      }}
                      type="date"
                      name={"fec_cerrada_" + index}
                      id={"fec_cerrada_" + index}
                      min={moment().format("YYYY-MM-DD")}
                      value={this.state["fec_cerrada_" + index]}
                      onChange={this.cambiarValores}
                      className={SolicitudTrabajoCSS.inputModalSolicitud}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
                    <label className={SolicitudTrabajoCSS.labelInput}>Hora opción {index + 1} </label>
                    <br />
                    <input
                      style={{
                        background: "transparent",
                        border: "solid 3px #ea9f29",
                        color: "#4d4d4c",
                      }}
                      type="time"
                      name={"hor_cerrada_" + index}
                      id={"hor_cerrada_" + index}
                      value={this.state["hor_cerrada_" + index]}
                      onChange={this.cambiarValores}
                      className={SolicitudTrabajoCSS.inputModalSolicitud}
                    />
                    <br />
                  </Grid>
                </Grid>
              </>
            ))
          )}
        </>
      );
    } else if (vista == 6) {
      /* RECHAZAR SOLICITUD */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
              <br />
              <textarea value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Observación interna</label>
              <br />
              <textarea onChange={this.cambiarValores} disabled value={observacion} name="observacion" id="observacion" className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo de rechazo</label>
              <br />
              <textarea
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                value={motivo_rechazo}
                name="motivo_rechazo"
                id="motivo_rechazo"
                onChange={this.cambiarValores}
                className={SolicitudTrabajoCSS.textareaModalSolicitud}
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 7) {
      /* ASIGNAR FECHA DE ENTREGA */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
              <br />
              <textarea value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Observación interna</label>
              <br />
              <textarea disabled onChange={this.cambiarValores} value={observacion} name="observacion" id="observacion" className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Prioridad</label>
              <br />
              <input value={data.nom_prio} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha comprometida cliente</label>
              <br />
              <input value={data.sol_entrega_aprox} className={SolicitudTrabajoCSS.inputModalSolicitud} disabled />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha de entrega técnico</label>
              <br />
              <input
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                name="fecha_entrega_tecnico"
                id="fecha_entrega_tecnico"
                min={moment().format("YYYY-MM-DDT20:00")}
                onChange={this.cambiarValores}
                value={fecha_entrega_tecnico}
                type="datetime-local"
                className={SolicitudTrabajoCSS.inputModalSolicitud}
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 8) {
      /* ENVIAR A PRODUCCION y FINALIZAR TAREA DEV */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 35px" }}>
            {data.sol_estado == 5 && usp_id == 7 ? <p style={{ fontSize: 19 }}>¿Estás segur@ de dar por terminado el ticket?</p> : data.sol_estado == 5 ? <p style={{ fontSize: 19 }}>¿Estás segur@ que deseas enviar el desarrollo terminado a producción?</p> : data.sol_estado == 6 ? <p style={{ fontSize: 19 }}>¿Estás segur@ que la solicitud ha sido completada?</p> : null}
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Prioridad</label>
              <br />
              <input value={data.nom_prio} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha comprometida cliente</label>
              <br />
              <input value={data.sol_entrega_aprox} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha de entrega técnico</label>
              <br />
              <input name="fecha_entrega_tecnico" id="fecha_entrega_tecnico" disabled value={data.sol_fecha_entrega_developer + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Cantidad de horas</label>
              <br />
              <input
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                name="hras_desarrollo"
                id="hras_desarrollo"
                onChange={this.cambiarValores}
                value={hras_desarrollo}
                type="number"
                min={0}
                className={SolicitudTrabajoCSS.inputModalSolicitud}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>
                Observación para cliente <span style={{ fontSize: "0.6vw" }}>{" (Opcional)"}</span>
              </label>
              <br />
              <textarea
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                onChange={this.cambiarValores}
                name="observacion_cliente"
                id="observacion_cliente"
                value={observacion_cliente}
                className={SolicitudTrabajoCSS.textareaModalSolicitud}
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 9) {
      /* RECHAZAR SOLICITUD EN QA */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 30px" }}>
            <p style={{ fontSize: 19 }}>¿Estás segur@ que deseas rechazar el desarrollo?</p>
          </Grid>

          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Prioridad</label>
              <br />
              <input value={data.prio_desc} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha comprometida cliente</label>
              <br />
              <input value={data.entrega_aprox_cliente} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Técnico a cargo</label>
              <br />
              <input value={data.nom_dev} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha de entrega técnico</label>
              <br />
              <input name="fecha_entrega_tecnico" id="fecha_entrega_tecnico" disabled max={data.sol_entrega_aprox_cliente && data.sol_entrega_aprox_cliente + "T23:59"} value={data.fecha_entrega_dev + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo de rechazo</label>
              <br />
              <textarea
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                onChange={this.cambiarValores}
                name="motivo_rechazo_qa"
                id="motivo_rechazo_qa"
                value={motivo_rechazo_qa}
                className={SolicitudTrabajoCSS.textareaModalSolicitud}
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 10) {
      /* APROBAR QA */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 30px" }}>
            <p style={{ fontSize: 19 }}>¿Estás segur@ que la producción ha finalizado?</p>
          </Grid>

          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Prioridad</label>
              <br />
              <input value={data.prio_desc} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha comprometida cliente</label>
              <br />
              <input value={data.entrega_aprox_cliente} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Técnico a cargo</label>
              <br />
              <input value={data.nom_dev} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha de entrega técnico</label>
              <br />
              <input name="fecha_entrega_tecnico" id="fecha_entrega_tecnico" disabled min={moment().format("YYYY-MM-DDT20:00")} max={data.sol_entrega_aprox_cliente && data.sol_entrega_aprox_cliente + "T23:59"} value={data.fecha_entrega_dev + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 11) {
      /* FINALIZAR SOLICITUD APROBADA */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_recepcion + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Técnico a cargo</label>
              <br />
              <input value={data.nom_dev} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            {data.sol_tipo != 1 && data.sol_tipo != 5 && (
              <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                <label className={SolicitudTrabajoCSS.labelInput}>Fecha de entrega técnico</label>
                <br />
                <input name="fecha_entrega_tecnico" id="fecha_entrega_tecnico" disabled value={data.fecha_entrega_dev + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
              <br />
              <textarea onChange={this.cambiarValores} name="motivo_rechazo_qa" id="motivo_rechazo_qa" value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
            <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>
                Observación para cliente <span style={{ fontSize: "0.6vw" }}>{" (Opcional)"}</span>
              </label>
              <br />
              <textarea
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                onChange={this.cambiarValores}
                name="observacion_cliente"
                id="observacion_cliente"
                value={observacion_cliente}
                className={SolicitudTrabajoCSS.textareaModalSolicitud}
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 12 || vista == 13) {
      /* RESPUESTAS DE HORAS ESTABLECIDAD POR HORASALUD EN VISITAS A TERRENO */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha de ingreso</label>
              <br />
              <input value={data.fecha_solicitud} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_solicitud + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Tipo de solicitud</label>
              <br />
              <input value={data.tipo_solicitud} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          {vista == 12 && (
            <>
              <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, paddingLeft: 30 }}>
                <p style={{ fontSize: 19 }}>Seleccione una fecha para la reunión:</p>
              </Grid>

              <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
                {data.opc_visita_1 && (
                  <Grid item xs={!data.opc_visita_2 ? 12 : data.opc_visita_2 && !data.opc_visita_3 ? 6 : data.opc_visita_3 && !data.opc_visita_4 ? 4 : 3} sm={!data.opc_visita_2 ? 12 : data.opc_visita_2 && !data.opc_visita_3 ? 6 : data.opc_visita_3 && !data.opc_visita_4 ? 4 : 3} className={SolicitudTrabajoCSS.paddingGrid} style={{ display: "flex", alignItems: "center", textAlign: data.opc_visita_4 && "center" }}>
                    <input type="checkbox" id="checkBox" className={SolicitudTrabajoCSS.checkboxInput} name="checkBox" onClick={() => this.valorCheckbox(1)} style={{ backgroundColor: this.state.checkBox == 1 && "#eaa028" }} />
                    <label onClick={() => this.valorCheckbox(1)} className={SolicitudTrabajoCSS.labelInput} style={{ paddingLeft: "10px", fontSize: "1vw" }}>
                      {data.opc_visita_4 ? (
                        <>
                          <p>{data.opc_visita_1.split(" ")[0]}</p> <p>{data.opc_visita_1.split(" ")[1]} hrs</p>
                        </>
                      ) : (
                        data.opc_visita_1 + " hrs"
                      )}
                    </label>
                  </Grid>
                )}
                {data.opc_visita_2 && (
                  <Grid item xs={!data.opc_visita_3 ? 6 : data.opc_visita_3 && !data.opc_visita_4 ? 4 : 3} sm={!data.opc_visita_3 ? 6 : data.opc_visita_3 && !data.opc_visita_4 ? 4 : 3} className={SolicitudTrabajoCSS.paddingGrid} style={{ display: "flex", alignItems: "center", textAlign: data.opc_visita_4 && "center" }}>
                    <input type="checkbox" id="checkBox" name="checkBox" onClick={() => this.valorCheckbox(2)} style={{ backgroundColor: this.state.checkBox == 2 && "#eaa028" }} className={SolicitudTrabajoCSS.checkboxInput} />
                    <label onClick={() => this.valorCheckbox(2)} className={SolicitudTrabajoCSS.labelInput} style={{ paddingLeft: "10px", fontSize: "1vw" }}>
                      {data.opc_visita_4 ? (
                        <>
                          <p>{data.opc_visita_2.split(" ")[0]}</p> <p>{data.opc_visita_2.split(" ")[1]} hrs</p>
                        </>
                      ) : (
                        data.opc_visita_2 + " hrs"
                      )}
                    </label>
                  </Grid>
                )}
                {data.opc_visita_3 && (
                  <Grid item xs={!data.opc_visita_4 ? 4 : 3} sm={!data.opc_visita_4 ? 4 : 3} className={SolicitudTrabajoCSS.paddingGrid} style={{ display: "flex", alignItems: "center", textAlign: data.opc_visita_4 && "center" }}>
                    <input type="checkbox" name="checkBox" id="checkBox" onClick={() => this.valorCheckbox(3)} style={{ backgroundColor: this.state.checkBox == 3 && "#eaa028" }} className={SolicitudTrabajoCSS.checkboxInput} />
                    <label onClick={() => this.valorCheckbox(3)} className={SolicitudTrabajoCSS.labelInput} style={{ paddingLeft: "10px", fontSize: "1vw" }}>
                      {data.opc_visita_4 ? (
                        <>
                          <p>{data.opc_visita_3.split(" ")[0]}</p> <p>{data.opc_visita_3.split(" ")[1]} hrs</p>
                        </>
                      ) : (
                        data.opc_visita_3 + " hrs"
                      )}
                    </label>
                  </Grid>
                )}
                {data.opc_visita_4 && (
                  <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid} style={{ display: "flex", alignItems: "center", textAlign: data.opc_visita_4 && "center" }}>
                    <input type="checkbox" name="checkBox" id="checkBox" onClick={() => this.valorCheckbox(4)} style={{ backgroundColor: this.state.checkBox == 4 && "#eaa028" }} className={SolicitudTrabajoCSS.checkboxInput} />
                    <label onClick={() => this.valorCheckbox(4)} className={SolicitudTrabajoCSS.labelInput} style={{ paddingLeft: "10px", fontSize: "1vw" }}>
                      {data.opc_visita_4 ? (
                        <>
                          <p>{data.opc_visita_4.split(" ")[0]}</p> <p>{data.opc_visita_4.split(" ")[1]} hrs</p>
                        </>
                      ) : (
                        data.opc_visita_4 + " hrs"
                      )}
                    </label>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {vista == 13 && (
            <>
              <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, paddingLeft: 30 }}>
                <p style={{ fontSize: 19 }}>¿Es posible realizar la visita en este periodo de tiempo?</p>
              </Grid>

              <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
                <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                  <label className={SolicitudTrabajoCSS.labelInput}>Desde</label>
                  <br />
                  <input value={data.fcv_fecha_desde} className={SolicitudTrabajoCSS.inputModalSolicitud} />
                </Grid>
                <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                  <label className={SolicitudTrabajoCSS.labelInput}>Hasta</label>
                  <br />
                  <input value={data.fcv_fecha_hasta} className={SolicitudTrabajoCSS.inputModalSolicitud} />
                </Grid>
                <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
                  <label className={SolicitudTrabajoCSS.labelInput}>Horario desde - hasta</label>
                  <div style={{ display: "flex" }}>
                    <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid} style={{ paddingLeft: 0 }}>
                      <input value={data.fcv_hora_desde} className={SolicitudTrabajoCSS.inputModalSolicitud} />
                    </Grid>
                    <Grid item xs={6} sm={6} className={SolicitudTrabajoCSS.paddingGrid} style={{ paddingRight: 0 }}>
                      <input value={data.fcv_hora_hasta} className={SolicitudTrabajoCSS.inputModalSolicitud} />
                    </Grid>
                  </div>
                  <br />
                </Grid>
              </Grid>
            </>
          )}
        </>
      );
    } else if (vista == 14) {
      /* APROBAR SOLICITUD CESFAM */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha ingreso solicitud</label>
              <br />
              <input value={data.fecha_solicitud + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Tipo de solicitud</label>
              <br />
              <input value={data.tipo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.nombre_quien_solicita} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={8} sm={8} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo</label>
              <br />
              <textarea name="observacion_cliente" id="observacion_cliente" value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
          </Grid>
        </>
      );
    } else if (vista == 15) {
      /* RECHAZAR SOLICITUD CESFAM */
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Fecha confirmación solicitud</label>
              <br />
              <input value={data.fecha_solicitud + " hrs"} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Tipo de solicitud</label>
              <br />
              <input value={data.tipo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.nombre_quien_solicita} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={3} sm={3} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={8} sm={8} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo de solicitud</label>
              <br />
              <textarea name="observacion_cliente" id="observacion_cliente" value={data.sol_motivo} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
            <Grid item xs={8} sm={8} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo de rechazo</label>
              <br />
              <textarea
                style={{
                  background: "transparent",
                  border: "solid 3px #ea9f29",
                  color: "#4d4d4c",
                }}
                onChange={this.cambiarValores}
                name="motivo_rechazo_cesfam"
                id="motivo_rechazo_cesfam"
                value={motivo_rechazo_cesfam}
                className={SolicitudTrabajoCSS.textareaModalSolicitud}
              />
            </Grid>
          </Grid>
        </>
      );
    }
    if (vista == 17) {
      /* SOLO VISUALIZACION DEL MOTIVO DE RECHAZO CESFAM*/
      return (
        <>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>N° de Ticket</label>
              <br />
              <input value={data.sol_codigo} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Nombre</label>
              <br />
              <input value={data.sol_autoriza} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
            <Grid item xs={4} sm={4} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>CESFAM</label>
              <br />
              <input value={data.ces_nombre} className={SolicitudTrabajoCSS.inputModalSolicitud} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex", marginBottom: 25, padding: "0 20px" }}>
            <Grid item xs={8} sm={8} className={SolicitudTrabajoCSS.paddingGrid}>
              <label className={SolicitudTrabajoCSS.labelInput}>Motivo de rechazo</label>
              <br />
              <textarea disabled value={data.sol_motivo_rechazo_cesfam} className={SolicitudTrabajoCSS.textareaModalSolicitud} />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  modalSalir = () => {
    this.props.salir();
  };

  efectoVacio = (element) => {
    element.classList.add(SolicitudTrabajoCSS.error);
    setTimeout(() => {
      element.classList.remove(SolicitudTrabajoCSS.error);
    }, 500);
  };

  /* -----------------------  AQUI IRAN TODOS LAS REQUEST -----------------------  */

  reprogramar = (solId, fechaEntregaCliente) => {
    const { motivo_reprogramar, fecha_reprogramada, entrega_aprox_cliente } = this.state;

    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";
    let data = {
      solId: solId,
      tipo: 7,
      uspId: store.getState().usp,
    };

    if (!fechaEntregaCliente) {
      if (entrega_aprox_cliente) {
        data["hrsRespuesta"] = entrega_aprox_cliente;
      } else {
        let entrega_aprox_cliente = document.getElementById("entrega_aprox_cliente");
        this.efectoVacio(entrega_aprox_cliente);
        return;
      }
    } else {
      if (motivo_reprogramar && fecha_reprogramada) {
        data["motivoRep"] = motivo_reprogramar;
        data["fechaReprogramada"] = fecha_reprogramada;
      } else {
        if (!motivo_reprogramar) {
          let motivo = document.getElementById("motivo_reprogramar");
          this.efectoVacio(motivo);
          return;
        }

        if (!fecha_reprogramada) {
          let reprogramar = document.getElementById("fecha_reprogramada");
          this.efectoVacio(reprogramar);
          return;
        }
      }
    }

    axios.patch(apiUrl, data, { headers: authHeader() }).then(() => {
      this.props.solicitudes();
      this.modalSalir();
    });
  };

  editObservacion = (solId) => {
    const { observacion } = this.state;
    if (observacion != "" && observacion != null) {
      const apiUrl = global.config.modulos.solicitudTrabajo + "editar_obs_solicitud/";
      const data = {
        solId: solId,
        obs: observacion,
      };
      axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
        if (res.data.estado) {
          this.props.solicitudes();
          this.modalSalir();
        }
      });
    } else {
      let observacion = document.getElementById("observacion");
      this.efectoVacio(observacion);
    }
  };

  validacion = (solId, tipo) => {
    const { vista, prio, dev, fec_desde, fec_hasta, hra_desde, hra_hasta, tipo_fecha, cant_opciones } = this.state;

    let validacion = true;
    /* VALIDA SI HAY PRIORIDAD O SI HAY TECNICO ASIGNADO */
    if (vista == 5) {
      if (!prio || !dev) {
        if (!prio) {
          let prioridad = document.getElementById("prio");
          this.efectoVacio(prioridad);
          validacion = false;
        }

        if (!dev) {
          let desarrollador = document.getElementById("dev");
          this.efectoVacio(desarrollador);
          validacion = false;
        }
      }
    }

    /* EN CASO DE SER VISITAS TECNICAS O REUNION-CAPACITACION, 
    VALIDA LAS FECHAS DE VISITAS */

    if (tipo == 1 || tipo == 5) {
      if (!tipo_fecha) {
        let tipo_fecha = document.getElementById("tipo_fecha");
        this.efectoVacio(tipo_fecha);
        validacion = false;
      }
      if (tipo_fecha == 2) {
        if (!fec_desde) {
          let fec_desde = document.getElementById("fec_desde");
          this.efectoVacio(fec_desde);
          validacion = false;
        }

        if (!fec_hasta) {
          let fec_hasta = document.getElementById("fec_hasta");
          this.efectoVacio(fec_hasta);
          validacion = false;
        }

        if (!hra_desde) {
          let hra_desde = document.getElementById("hra_desde");
          this.efectoVacio(hra_desde);
          validacion = false;
        }

        if (!hra_hasta) {
          let hra_hasta = document.getElementById("hra_hasta");
          this.efectoVacio(hra_hasta);
          validacion = false;
        }
      } else if (tipo_fecha == 1) {
        if (!cant_opciones) {
          let cant_opciones = document.getElementById("cant_opciones");
          this.efectoVacio(cant_opciones);
          validacion = false;
        } else {
          let index = 0;
          while (index < cant_opciones) {
            const fec_opc = document.getElementById("fec_cerrada_" + index);
            const hor_opc = document.getElementById("hor_cerrada_" + index);

            if (!fec_opc.value) {
              this.efectoVacio(fec_opc);
              validacion = false;
            }
            if (!hor_opc.value) {
              this.efectoVacio(hor_opc);
              validacion = false;
            }
            index++;
          }
        }
      }
    }
    return validacion;
  };

  aprobarSolicitud = (solId, tipo) => {
    const { vista, prio, dev, fecha_reprogramada, observacion, fec_desde, fec_hasta, hra_desde, hra_hasta, tipo_fecha, cant_opciones } = this.state;

    if (this.validacion(solId, tipo)) {
      const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";
      let data = {
        solId: solId,
        observacion: observacion,
        tipo: 4,
        solEstado: 5,
        uspId: store.getState().usp,
        vista: vista,
      };

      if (fecha_reprogramada) {
        data["hrsRespuesta"] = fecha_reprogramada;
      }

      if (vista == 5) {
        data["solPrioridad"] = prio;
        data["solDev"] = dev;
      }

      axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
        if (tipo == 1 || tipo == 5) {
          const apiUrl = global.config.modulos.solicitudTrabajo + "guardar_fecha_visitas/";

          let data = {
            solId: solId,
            cesfam: this.state.cesfam,
            fcv_sol_tipo: tipo_fecha,
            fcv_fecha_aceptada: 0,
          };

          if (tipo_fecha == 1) {
            let index = 0;
            while (index < cant_opciones) {
              let posicion = index + 1;
              let opcion_fecha = this.state["fec_cerrada_" + index] + " " + this.state["hor_cerrada_" + index];
              data["fcv_opc_" + posicion] = opcion_fecha;
              index++;
            }
          } else if (tipo_fecha == 2) {
            data["fcv_fecha_desde"] = fec_desde;
            data["fcv_fecha_hasta"] = fec_hasta;
            data["fcv_hora_desde"] = hra_desde;
            data["fcv_hora_hasta"] = hra_hasta;
          }

          axios.post(apiUrl, data, { headers: authHeader() });
        }

        this.props.solicitudes();
        this.modalSalir();
      });
    }
  };

  rechazarSolicitud = (solId) => {
    const { motivo_rechazo } = this.state;
    if (motivo_rechazo) {
      var data = {
        solId: solId,
        tipo: 3,
        uspId: store.getState().usp,
        solEstado: 4,
        motivoRechazo: motivo_rechazo,
      };
      const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";

      axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
        this.props.solicitudes();
        this.modalSalir();
      });
    } else {
      let motivo_rechazo = document.getElementById("motivo_rechazo");
      this.efectoVacio(motivo_rechazo);
    }
  };

  asignarFechaEntregaDev = (solId) => {
    const { fecha_entrega_tecnico } = this.state;
    if (fecha_entrega_tecnico) {
      var data = {
        solId: solId,
        tipo: 6,
        uspId: store.getState().usp,
        tiempoEntrega: fecha_entrega_tecnico,
      };
      const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";

      axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
        this.props.solicitudes();
        this.modalSalir();
      });
    } else {
      let fecha_entrega_tecnico = document.getElementById("fecha_entrega_tecnico");
      this.efectoVacio(fecha_entrega_tecnico);
    }
  };

  enviarQa = (solId, datos) => {
    const { hras_desarrollo, observacion_cliente } = this.state;
    if (hras_desarrollo == 0) {
      let hras_desarrollo = document.getElementById("hras_desarrollo");
      this.efectoVacio(hras_desarrollo);
      return;
    }
    let estado = 6;
    if (datos.sol_estado == 6) {
      estado = 9;
    }

    var data = {
      solId: solId,
      tipo: 2,
      uspId: store.getState().usp,
      solEstado: estado,
      sol_obs_final_cliente: observacion_cliente,
    };

    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";

    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
      const data = {
        tde_tiempo_desarrollo: hras_desarrollo,
        tde_comuna: datos.sol_comuna,
        tde_cesfam: datos.sol_cesfam,
        tde_usuario_panel: store.getState().usp,
        tde_jefe_some: datos.sol_rutau,
        tde_solicitud: datos.sol_codigo,
        tde_tipo_solicitud: datos.sol_tipo,
      };

      const apiUrl = global.config.modulos.solicitudTrabajo + "tiempos_desarrollos/";
      axios.post(apiUrl, data, { headers: authHeader() }).then((res) => {
        this.props.solicitudes();
        this.modalSalir();
      });
    });
  };

  rechazarSolicitudQa = (solId) => {
    const { motivo_rechazo_qa } = this.state;

    var data = {
      solId: solId,
      tipo: 10,
      uspId: store.getState().usp,
      sol_qa: false,
      sol_qa_rechazo: motivo_rechazo_qa,
      solEstado: 5,
    };

    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";

    if (motivo_rechazo_qa) {
      axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
        this.props.solicitudes();
        this.modalSalir();
      });
    } else {
      let motivo_rechazo_qa = document.getElementById("motivo_rechazo_qa");
      this.efectoVacio(motivo_rechazo_qa);
    }
  };
  aprobarQa = (solId) => {
    var data = {
      solId: solId,
      tipo: 10,
      uspId: store.getState().usp,
      sol_qa: true,
      sol_qa_rechazo: null,
      solEstado: 9,
    };

    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";

    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
      this.props.solicitudes();
      this.modalSalir();
    });
  };

  terminarSolicitud = (solId) => {
    var data = {
      solId: solId,
      tipo: 5,
      uspId: store.getState().usp,
      solEstado: 7,
      sol_obs_final_cliente: this.state.observacion_cliente,
    };

    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_administrador/";

    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
      this.props.solicitudes();
      this.modalSalir();
    });
  };

  AceptarFechas = (solId, datos) => {
    const { vista, checkBox } = this.state;

    if (vista == 12 && checkBox == 0) {
      let checkBoxes = document.querySelectorAll('input[name="checkBox"]');

      checkBoxes.forEach((checkBox) => {
        this.efectoVacio(checkBox);
      });
      return;
    }

    const apiUrl = global.config.modulos.solicitudTrabajo + "estado_visita_cesfam/";

    var data = {
      solId: solId,
      estado: 1,
      vista: vista,
    };

    if (vista == 12) {
      data["sol_entrega_aprox_cliente"] = datos["fcv_opc_" + checkBox];
    }

    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
      this.props.solicitudes();
      this.modalSalir();
      this.props.cuentaSolicitudesTerreno();
    });
  };

  cambiarEstadoVisita = (solId, estado) => {
    var data = {
      solId: solId,
      estado: estado,
    };

    const apiUrl = global.config.modulos.solicitudTrabajo + "estado_visita_cesfam/";
    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
      this.props.solicitudes();
      this.modalSalir();
    });
  };

  aprobarSolicitudCesfam = (solId, tipo) => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";

    let data = {
      id: solId,
      estado: 2,
      uspId: store.getState().usp,
    };

    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
      this.props.solicitudes();
      this.modalSalir();
    });
  };

  rechazarSolicitudCesfam = (solId) => {
    const { motivo_rechazo_cesfam } = this.state;

    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";

    let data = {
      id: solId,
      estado: 1,
      uspId: store.getState().usp,
      motivoRechazo: motivo_rechazo_cesfam,
    };

    if (!motivo_rechazo_cesfam) {
      let motivo_rechazo_cesfam = document.getElementById("motivo_rechazo_cesfam");
      this.efectoVacio(motivo_rechazo_cesfam);
    } else {
      axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {
        this.props.solicitudes();
        this.modalSalir();
      });
    }
  };

  render() {
    const { titulo, vista, data } = this.state;
    return (
      <>
        <Dialog open={true} onClose={() => this.modalSalir(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalSolicitudTrabajo" style={{ width: "52.6vw", margin: "auto" }}>
          <DialogContent>
            <h1 style={{ fontSize: "2.2vw", marginBottom: "35px", color: "#4d4d4d", paddingLeft: "10px" }}>{titulo}</h1>
            <GridContainer>{this.renderizarVista()}</GridContainer>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <button
                style={{
                  background: vista == 15 ? "#d24b4a" : "#3ab549",
                  fontSize: vista == 12 || vista == 13 ? "1.2rem" : "1rem",
                  cursor: "pointer",
                }}
                className={SolicitudTrabajoCSS.botonesAccione}
                onClick={() => {
                  vista == 3 ? this.editObservacion(data.sol_id) : vista == 4 ? this.reprogramar(data.sol_id, data.sol_entrega_aprox_cliente) : vista == 5 ? this.aprobarSolicitud(data.sol_id, data.sol_tipo) : vista == 6 ? this.rechazarSolicitud(data.sol_id) : vista == 7 ? this.asignarFechaEntregaDev(data.sol_id) : vista == 8 ? this.enviarQa(data.sol_id, data) : vista == 9 ? this.rechazarSolicitudQa(data.sol_id) : vista == 10 ? this.aprobarQa(data.sol_id) : vista == 11 ? this.terminarSolicitud(data.sol_id) : vista == 12 ? this.AceptarFechas(data.sol_codigo, data) : vista == 13 ? this.AceptarFechas(data.sol_codigo, data) : vista == 14 ? this.aprobarSolicitudCesfam(data.sol_id, data.sol_tipo_id) : vista == 15 ? this.rechazarSolicitudCesfam(data.sol_codigo) : vista == 16 ? this.aprobarSolicitud(data.sol_codigo, data.sol_tipo) : vista == 17 ? this.modalSalir() : this.modalSalir();
                }}
              >
                {vista == 12 ? "Enviar" : vista == 13 ? "Si" : vista == 15 ? "Rechazar" : "Aceptar"}
              </button>
              <button
                style={{
                  background: vista == 13 ? "#d24b4a" : "rgb(233, 160, 40)",
                  fontSize: vista == 12 || vista == 13 ? "1.1rem" : "1rem",
                  cursor: "pointer",
                }}
                className={SolicitudTrabajoCSS.botonesAccione}
                onClick={() => {
                  this.modalSalir();
                }}
              >
                {vista == 13 ? "Salir" : "Cancelar"}
              </button>

              {vista == 13 && (
                <button
                  style={{ background: "#e99f28", fontSize: "1.1rem", cursor: "pointer" }}
                  className={SolicitudTrabajoCSS.botonesAccione}
                  onClick={() => {
                    this.cambiarEstadoVisita(data.sol_codigo, 2);
                  }}
                >
                  Solicitar otra fecha
                </button>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default ModalSolicitud;
