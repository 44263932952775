import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Button from "@material-ui/core/Button";
import Titulo from "components/titulo/Titulo.js";
import signo from "assets/img/signo.svg";
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "assets/css/general.css";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import GridContainer from "components/grid/GridContainer";
import { Grid } from "@material-ui/core";
import img from "../../../assets/img/icono calendario.svg";
import graf from "assets/css/graf.module.css";
import tamanio_letra from "../../../assets/img/usuarios/iconos_colores/tamaño_letra.svg";
import InformacionUsuarioCSS from "../../../assets/css/usuarios.module.css";
import Ejemplo from "../../../assets/img/ejemplo-descarga.svg";
import { store } from "../../../store/index";
export default class GraficoSectores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_inicio: "",
      fecha_termino: "",
      fecha_ini: "",
      fecha_ter: "",
      primera_fecha: "",
      op1: "1",
      op2: "0.3",
      op3: "0.3",
      op4: "0.3",
      h1: "33px",
      la1: "18px",
      la2: "25px",
      la2With: "2475px",
      withima: "2428px",
      heigtima: "207px",
      id_ces: store.getState().cesfam,
      usp: store.getState().usp,
    };
  }

  traerPrimeraFecha = () => {
    axios.get(global.config.modulos.grafico_demanda + "datos_primera_fecha_ima/", { headers: authHeader() }).then((res) => {
      this.setState({ primera_fecha: res.data.datos }, () => {
        console.log(this.state.primera_fecha);
      });
    });
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Datos Imagenología<span title=''><img src=" + signo + "></img></span>";
  };

  actualizarFechaInicial = (fecha) => {
    this.setState({
      fecha_inicio: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
      fecha_ini: fecha,
    });
  };

  actualizarFechaTerminal = (fecha) => {
    this.setState({
      fecha_termino: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
      fecha_ter: fecha,
    });
  };

  descargar = () => {
    if (this.state.fecha_ini > this.state.fecha_ter && this.state.fecha_ter) {
      axios.post(
        global.config.modulos.grafico_demanda + "datos_imagen/",
        {
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          ces: this.state.id_ces,
          usp: this.state.usp,
        },
        { headers: authHeader() }
      );
      NotificationManager.info("La fecha de inicio es mayor a la de termino");
    } else if (this.state.fecha_inicio && this.state.fecha_termino) {
      axios
        .post(
          global.config.modulos.grafico_demanda + "datos_imagen/",
          {
            fecha_inicio: this.state.fecha_inicio,
            fecha_termino: this.state.fecha_termino,
            ces: this.state.id_ces,
            usp: this.state.usp,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Datos_Imagenologia_${this.state.fecha_inicio}_${this.state.fecha_termino}.xlsx`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
        });
    } else {
      axios
        .post(
          global.config.modulos.grafico_demanda + "datos_imagen/",
          {
            fecha_inicio: this.state.fecha_inicio,
            fecha_termino: this.state.fecha_termino,
            ces: this.state.id_ces,
            usp: this.state.usp,
          },
          { headers: authHeader() }
        )
        .then((response) => {
          console.log(response.data);
          NotificationManager.info(response.data.error);
        });
    }
  };

  cambiarTamanio = (tipo) => {
    if (tipo === 4) {
      this.setState({
        op1: "1",
        op2: "0.3",
        op3: "0.3",
        op4: "0.3",
        la2: "25px",
        la2With: "2475px",
        withima: "2428px",
        heigtima: "207px",
      });
    } else if (tipo === 3) {
      this.setState({
        op1: "0.3",
        op2: "1",
        op3: "0.3",
        op4: "0.3",
        la2: "19px",
        la2With: "2055px",
        withima: "2015px",
        heigtima: "173px",
      });
    } else if (tipo === 2) {
      this.setState({
        op1: "0.3",
        op2: "0.3",
        op3: "1",
        op4: "0.3",
        la2: "16px",
        la2With: "1565px",
        withima: "1536px",
        heigtima: "131px",
      });
    } else if (tipo === 1) {
      this.setState({
        op1: "0.3",
        op2: "0.3",
        op3: "0.3",
        op4: "1",
        la2: "12px",
        la2With: "1220px",
        withima: "1203px",
        heigtima: "103px",
      });
    }
  };

  componentDidMount() {
    this.titulo();
    this.traerPrimeraFecha();
  }
  render() {
    return (
      <>
        <div className="middle-area">
          <div className={InformacionUsuarioCSS.seleccioonTamanio}>
            <img
              src={tamanio_letra}
              id="img4"
              name="img_tamTexto"
              onClick={() => this.cambiarTamanio(4, 1)}
              style={{
                width: "60%",
                cursor: "pointer",
                opacity: this.state.op1,
              }}
            />
            <img
              src={tamanio_letra}
              name="img_tamTexto"
              id="img3"
              onClick={() => this.cambiarTamanio(3, 1)}
              style={{
                width: "50%",
                cursor: "pointer",
                opacity: this.state.op2,
              }}
            />
            <img
              src={tamanio_letra}
              id="img2"
              name="img_tamTexto"
              onClick={() => this.cambiarTamanio(2, 1)}
              style={{
                width: "45%",
                cursor: "pointer",
                opacity: this.state.op3,
              }}
            />
            <img
              src={tamanio_letra}
              name="img_tamTexto"
              id="img1"
              onClick={() => this.cambiarTamanio(1, 1)}
              style={{
                width: "40%",
                cursor: "pointer",
                opacity: this.state.op4,
              }}
            />
          </div>
          <GridContainer>
            <Grid style={{ display: "flex", marginLeft: "30px", marginTop: "20px" }}>
              <Grid
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  placeholder="Fecha de inicio"
                  weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                  onChange={this.actualizarFechaInicial}
                  months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octtubre", "Noviembre", "Diciembre"]}
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  format="DD/MM/YYYY"
                  minDate={this.state.primera_fecha}
                ></DatePicker>
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  placeholder="Fecha de Termino"
                  weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                  onChange={this.actualizarFechaTerminal}
                  months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octtubre", "Noviembre", "Diciembre"]}
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  format="DD/MM/YYYY"
                  minDate={this.state.primera_fecha}
                />

                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </Grid>
              <Grid>
                <Button
                  style={{
                    marginLeft: "12px",
                    background: "#eaa028",
                    color: "white",
                    height: "2rem",
                  }}
                  onClick={() => {
                    this.descargar();
                  }}
                >
                  Descargar
                </Button>
              </Grid>
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid style={{ marginLeft: "30px", marginTop: "15px" }}>
              <h1
                style={{
                  fontSize: this.state.h1,
                  marginTop: "15px",
                  marginLeft: "15px",
                  color: "#808080",
                }}
              >
                Ejemplos del archivo de descarga
              </h1>
              <label
                style={{
                  marginTop: "10px",
                  marginLeft: "15px",
                  color: "#808080",
                  fontSize: this.state.la1,
                }}
              >
                El excel que se descargará contendrá los siguientes datos:
              </label>
            </Grid>

            <Grid xs={11} className={graf.divImagenologia}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "auto",
                }}
              >
                <label
                  style={{
                    marginTop: "10px",
                    marginLeft: "15px",
                    color: "rgb(128, 128, 128)",
                    fontSize: this.state.la2,
                    width: this.state.la2With,
                  }}
                ></label>
                <img
                  style={{
                    width: this.state.withima,
                    height: this.state.heigtima,
                    marginTop: "15px",
                    marginLeft: "15px",
                    backgroundColor: "white",
                    marginBottom: "25px",
                  }}
                  src={Ejemplo}
                ></img>
              </div>
            </Grid>
          </GridContainer>
        </div>
      </>
    );
  }
}
