import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "../../store/index";
import axios from "axios";
class FiltrosOfertaDemanda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      especialidades: [],
      selectedEsp: "",
      selectedSem: "",
      cesfam: store.getState().cesfam,
    };
    this.onChangeEsp = this.onChangeEsp.bind(this);
    this.onChangeSem = this.onChangeSem.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.especialidades();
  }
  selectorEspecialidad = (e) => {
    const selectedEsp = e.target.value;
    this.setState({ selectedEsp: selectedEsp });
    console.log("selectedEsp", selectedEsp);
  };

  especialidades = () => {
    axios.get(global.config.modulos.reportes + "listado_especialidades/", { headers: authHeader(), params: { cesfam: this.state.cesfam } }).then((res) => {
      if (res.data.estado) {
        this.setState({
          especialidades: res.data.datos,
        });
      }
    });
  };

  onChangeEsp = (event) => {
    this.setState({ selectedEsp: event.target.value });
  };

  onChangeSem = (event) => {
    this.setState({ selectedSem: event.target.value });
  };

  handleClick = () => {
    this.props.cambiarFiltro(this.state.selectedEsp, this.state.selectedSem);
  };

  render() {
    return (
      <div className="filtros-oferta-demanda">
        <div className="lista-especialidades">
          <select className={"select-especialidad"} id="seleccionarEsp" value={this.state.selectedEsp} onChange={this.onChangeEsp}>
            <option value={null}>Seleccione una especialidad</option>
            {this.state.especialidades.map((esp) => (
              <option key={esp.value} value={esp.id}>
                {esp.nombre}
              </option>
            ))}
          </select>
        </div>
        <div>
          <input type="week" className={"select-semana"} id="seleccionarSem" name="semana" value={this.state.selectedSem} onChange={this.onChangeSem}></input>
        </div>
        <div>
          <button className="boton-filtrar" onClick={this.handleClick}>
            Filtrar
          </button>
        </div>
      </div>
    );
  }
}

export default FiltrosOfertaDemanda;
