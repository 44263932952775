import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import moment from "moment";
import "moment/locale/es";

// core components

import { store } from "./../../store/index";
import { Grid, List, ListItem } from "@material-ui/core";
import PacientesAtendidos from "./PacientesAtendidos";
import ListaPacientesAtendidos from "./ListaPacientesAtendidos";

import DatosTicket from "./Admin/usuarios/DatosTicket";

import { confirmAlert } from "react-confirm-alert"; // Import
import { socketTotem } from "../../service/socket";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/pasanumeros.css";

var repetir_numero = 1;
var repetir_nombre = 1;

export default class PasaNumeros1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numAnterior: {
        antNombre: "--",
        antRut: "--",
        antNacion: "--",
        antHoraLlegada: "--",
        antEdad: "--",
        antNumero: "--",
        antTicketId: "",
      },
      numActual: {
        acUsuarioId: "",
        acNombre: "--",
        acRut: "--",
        acNacion: "--",
        acHoraLlegada: "--",
        acEdad: "--",
        acEspera: "--",
        acEsperaMins: "--",
        acNumero: "--",
        acTicketId: "",
      },
      numSiguiente: {
        sigNombre: "--",
        sigRut: "--",
        sigNacion: "--",
        sigEspera: "--",
        sigNumero: "--",
        sigTicketId: "",
      },
      opciones: [],
      opcionSelec: 0,
      pendientes: "000",

      modulo: null,
      uspId: store.getState().usp,
      opcionesUsuarioP: null,
      totem: null,
      botonPasarNumeroHabilitado: true,
      botonAcciones: false,
      botonRepetirHabilitado: false,
      botonNombreHabilitado: false,
      ticketsAtendidos: false,
      pacientesAtendidos: false,
      listaPacientesAtendidos: false,
      datosTicket: false,
    };
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  componentDidMount() {
    this.datosFuncionario();
    this.titulo();

    var fecha = moment().subtract(1, "day").format("YYYY-MM-DD");
    this.pacientesAtendidos(fecha);
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Pasar Número <span title=''></span>";
  };

  datosFuncionario = () => {
    var apiUrl = global.config.modulos.usuariospanel + "por_id/" + this.state.uspId;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;
      this.setState({ opcionesUsuarioP: data.usp_opciones, modulo: data.usp_modulo }, () => {
        this.opcionesActivas();
      });
    });
  };

  pacientesAtendidos = (fecha) => {
    var apiUrl = global.config.modulos.totem + "pacientes_atendidos/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          fecha: fecha,
          uspId: this.state.uspId,
        },
      })
      .then((res) => {
        var data = res.data;

        if (data.tickets != "000") {
          this.setState({ pacientesAtendidos: true, ticketsAtendidos: data.tickets });
        }
      });
  };

  opcionesActivas = () => {
    axios
      .post(
        global.config.modulos.totem + "opciones_funcionario/",
        {
          opciones: this.state.opcionesUsuarioP,
          uspId: this.state.uspId,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        var datos = res.data;

        this.setState({
          opciones: datos.opciones,
          opcionSelec: datos.opciones[0].opc_id,
          totem: datos.opciones[0].opc_totem,
        });

        this.datos_opcion(datos.opciones[0].opc_id);
      });
  };

  ticket_faltantes = (opcion) => {
    var apiUrl = global.config.modulos.totem + "ticket_faltantes/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          opcion: opcion,
        },
      })
      .then((res) => {
        var data = res.data;

        this.setState({ pendientes: data.tickets_faltantes });
      });
  };

  proximo_atendido = (opcion) => {
    var apiUrl = global.config.modulos.totem + "proximo_atendido/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          opcion: opcion,
        },
      })
      .then((res) => {
        var data = res.data;

        const sigActivo = {
          sigNumero: data.proxAtendido,
        };

        this.setState({ numSiguiente: sigActivo });
      });
  };

  datos_opcion = (opcion) => {
    for (var i = 0; i < this.state.opciones.length; i++) {
      socketTotem.off("ticket_pendientes-" + this.state.opciones[i].opc_id);
    }

    socketTotem.on("ticket_pendientes-" + opcion, (data) => {
      if (data.opcion == opcion) {
        this.ticket_faltantes(data.opcion);
        this.proximo_atendido(data.opcion);
      }
    });

    this.setState({ botonRepetirHabilitado: false, opcionSelec: opcion, botonAcciones: false, botonPasarNumeroHabilitado: true });

    var div = document.getElementsByClassName("btnEnabledOpc");
    while (div.length) {
      div[0].classList.remove("btnEnabledOpc");
    }

    var element = document.getElementById("opc-" + opcion);
    element.classList.add("btnEnabledOpc");

    var apiUrl = global.config.modulos.totem + "datos_opcion_funcionario/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: this.state.uspId,
          opcion: opcion,
        },
      })
      .then((res) => {
        var data = res.data;
        var estado = data.estado;

        var newAnterior;
        if (estado) {
          newAnterior = {
            antNombre: data.nombre_usuario,
            antRut: data.rut,
            antNacion: data.nacionalidad,
            antHoraLlegada: data.Hora_llegada,
            antEdad: data.edad,
            antNumero: data.ticket,
            antTicketId: data.ticket_id,
          };
        } else {
          newAnterior = {
            antNombre: "--",
            antRut: "--",
            antNacion: "--",
            antHoraLlegada: "--",
            antEdad: "--",
            antNumero: "--",
            antTicketId: "--",
          };
        }

        var numActual = {
          acUsuarioId: "",
          acNombre: "--",
          acRut: "--",
          acNacion: "--",
          acHoraLlegada: "--",
          acEdad: "--",
          acEspera: "--",
          acEsperaMins: "--",
          acNumero: "--",
          acTicketId: "",
        };

        const sigActivo = {
          sigNumero: data.proxAtendido,
        };

        this.setState({ numActual: numActual, numAnterior: newAnterior, pendientes: data.tickets_faltantes, numSiguiente: sigActivo });
      });
  };

  // Funcion que renderiza la tabla donde se muestra el numero actual
  MostrarActual = (num) => {
    return (
      <div class="elmNumeros elmNumerosGrid">
        <div class="elmData1">
          <div>
            <p class="inline">
              <b>Nombre: </b>
              {num.acNombre}
            </p>
            <br></br>
          </div>
          <br></br>
          <div>
            <p class="inline">
              <b>RUT: </b> {num.acRut}
            </p>
            <br></br>
          </div>
          <br></br>
          <div>
            <p class="inline">
              <b>Nacionalidad: </b> {num.acNacion}
            </p>
          </div>
        </div>
        <div class="elmData2">
          <div>
            <p class="inline">
              <b>Hora de llegada: </b> {num.acHoraLlegada}
            </p>
            <br></br>
          </div>
          <br></br>
          <div>
            <p class="inline">
              <b>Edad: </b> {num.acEdad}
            </p>
            <br></br>
          </div>
          <br></br>
        </div>
        <div class="elmData3">
          <div class="tiempoEspera">
            <h3>{num.acEspera}</h3>
            <p>
              <b>Minutos Esperando </b>
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Funcion que renderiza la tabla donde se muestra el numero anterior
  MostrarAnterior = (num) => {
    return (
      <div class="elmNumeros elmNumerosGrid">
        <div class="elmData1">
          <div>
            <p class="inline">
              <b>Nombre: </b>
              {num.antNombre}
            </p>
            <br></br>
          </div>
          <br></br>
          <div>
            <p class="inline">
              <b>RUT: </b> {num.antRut}
            </p>
            <br></br>
          </div>
          <br></br>
          <div>
            <p class="inline">
              <b>Nacionalidad: </b> {num.antNacion}
            </p>
          </div>
        </div>
        <div class="elmData2">
          <div>
            <p class="inline">
              <b>Hora de llegada: </b> {num.antHoraLlegada}
            </p>
            <br></br>
          </div>
          <br></br>
          <div>
            <p class="inline">
              <b>Edad: </b> {num.antEdad}
            </p>
            <br></br>
          </div>
          <br></br>
        </div>
        <div class="elmData3">
          <div class="numeroAnterior">
            <p class="inline">
              <b>Número Anterior: </b> <br></br>
              <label class="numAntNum">{num.antNumero}</label>
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Funcion que renderiza la tabla donde se muestran los numeros y el boton de pasar
  MostrarNumeros = (actual, sig) => {
    return (
      <div class="numsGrid">
        <div class="numInfo">
          <h3 class="numActual borde">{actual.acNumero}</h3>
          <br></br>
          <h3 class="numSig bordeSmall">{sig.sigNumero} </h3>
          <br></br>
          <div class="wrapper">
            <a className={this.state.botonAcciones ? "btnAccionA btnCentradoPresenta" : "btnDisabled btnCentradoPresenta"} onClick={this.state.botonAcciones ? this.registrarSePresenta.bind(this, 1) : null}>
              Se presenta
            </a>
            <a className={this.state.botonAcciones ? "btnAccionR btnCentradoPresenta" : "btnDisabled btnCentradoPresenta"} onClick={this.state.botonAcciones ? this.registrarSePresenta.bind(this, 2) : null}>
              No se presenta
            </a>
          </div>
          <br></br>
          <div class="wrapper">
            <a className={this.state.botonPasarNumeroHabilitado ? "btnEnabled btnCentrado" : "btnDisabled btnCentrado"} onClick={this.state.botonPasarNumeroHabilitado ? this.PasarNumero.bind(this) : null}>
              Pasar Número
            </a>
          </div>
          <br></br>
          <div class="wrapper">
            <a className={this.state.botonRepetirHabilitado ? "btnEnabled btnCentrado" : "btnDisabled btnCentrado"} onClick={this.state.botonRepetirHabilitado ? this.RepetirNumero.bind(this) : null}>
              Repetir Número
            </a>
          </div>
          <br></br>
          <div class="wrapper">
            <a className={this.state.botonNombreHabilitado ? "btnEnabled btnCentrado" : "btnDisabled btnCentrado"} onClick={this.state.botonNombreHabilitado ? this.LlamarNombre.bind(this) : null}>
              Llamar por Nombre
            </a>
          </div>
        </div>
        <div className="attInfo">
          <div class="borde pendientes">
            <p>Público pendiente:</p>
            <p>
              <b> {this.state.pendientes}</b>
            </p>
            <hr></hr>
            <p>Su tiempo de atención</p>
            <p>
              es de: <b>{actual.acEsperaMins}</b>
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Funcion que pasa el numero, obteniendolo del backend y poniendolo como numero actual
  PasarNumero = async () => {
    repetir_numero = 1;
    repetir_nombre = 1;

    var apiUrl = global.config.modulos.totem + "pasar_numero/";
    var data = {
      uspId: this.state.uspId,
      modulo: this.state.modulo,
      opcion: this.state.opcionSelec,
      totem: this.state.totem,
    };
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    var res = await axios(apiUrl, options);
    var data = res.data;
    var estado = data.estado;
    if (estado) {
      if (data.usuario_id != null) {
        this.setState({ botonNombreHabilitado: true });
      } else {
        this.setState({ botonNombreHabilitado: false });
      }

      this.setState({ botonRepetirHabilitado: true, botonAcciones: true, botonPasarNumeroHabilitado: false });

      const newActivo = {
        acUsuarioId: data.usuario_id,
        acNombre: data.nombre_usuario,
        acRut: data.rut,
        acNacion: data.nacionalidad,
        acHoraLlegada: data.Hora_llegada,
        acEdad: data.edad,
        acEspera: data.minutosEsperando,
        acEsperaMins: data.tiempoAtencion,
        acNumero: data.ticket,
        acTicketId: data.ticket_id,
      };

      if (this.state.numActual.acTicketId != "") {
        this.MoverAnterior(this.state.numActual);
      }

      const sigActivo = {
        sigNumero: data.proxAtendido,
      };

      setTimeout(function () {
        socketTotem.emit("pasa_numero", {
          ticket: data.ticket,
          totem: data.totem,
          ticket_id: data.ticket_id,
        });
      }, 1000);

      this.setState({ numActual: newActivo, pendientes: data.tickets_faltantes, numSiguiente: sigActivo });
    } else {
      this.mostrarAlerta("No existen mas ticket para pasar");
    }
  };

  // Funcion que desplaza el numero actual hacia la posicion de numero anterior
  MoverAnterior = (numActual) => {
    const newAnterior = {
      antNombre: numActual.acNombre,
      antRut: numActual.acRut,
      antNacion: numActual.acNacion,
      antHoraLlegada: numActual.acHoraLlegada,
      antEdad: numActual.acEdad,
      antNumero: numActual.acNumero,
      antTicketId: numActual.acTicketId,
    };

    this.setState({ numAnterior: newAnterior });
  };

  // Funcion que llama al TTS para que repita el numero
  RepetirNumero = () => {
    if (repetir_numero <= 2) {
      repetir_numero = repetir_numero + 1;

      var apiUrl = global.config.modulos.totem + "repetir_numero/";
      var data = {
        ticket: this.state.numActual.acTicketId,
      };
      const options = {
        method: "GET",
        params: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        socketTotem.emit("repetir_numero", {
          totem: this.state.totem,
        });
      });

      if (repetir_numero > 2) {
        this.setState({ botonRepetirHabilitado: false });
      }
    } else {
      this.setState({ botonRepetirHabilitado: false });
    }
  };

  // Funcion que llama al TTS para que llame por Nombre de Paciente al numero
  LlamarNombre = () => {
    if (repetir_nombre <= 2) {
      repetir_nombre = repetir_nombre + 1;

      var apiUrl = global.config.modulos.totem + "llamar_por_nombre/";
      var data = {
        ticket: this.state.numActual.acTicketId,
      };
      const options = {
        method: "GET",
        params: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        socketTotem.emit("llamar_por_nombre", {
          totem: this.state.totem,
        });
      });

      if (repetir_nombre > 2) {
        this.setState({ botonNombreHabilitado: false });
      }
    } else {
      this.setState({ botonNombreHabilitado: false });
    }
  };

  // Funcion que registra si se presento el paciente o no
  registrarSePresenta = (tipo) => {
    this.setState({ botonPasarNumeroHabilitado: true, botonAcciones: false });

    var apiUrl = global.config.modulos.totem + "registrar_se_presenta/";
    var data = {
      ticket: this.state.numActual.acTicketId,
      tipo: tipo,
    };
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {});
  };

  cambiar_opcion = (opcion) => {
    this.setState({ botonNombreHabilitado: false });
    this.datos_opcion(opcion);
  };

  ocultar_historial = () => {
    this.setState({ listaPacientesAtendidos: false });
  };

  historial = () => {
    this.setState({ listaPacientesAtendidos: true });
  };

  ocultar_tickets_usuario = () => {
    this.setState({ datosTicket: false });
  };

  tickets_usuario = () => {
    this.setState({ datosTicket: true });
  };

  render() {
    return (
      <div className="middle-area middleOverride">
        <div className="middle-content">
          <Grid item xs={12} sm={12}>
            {this.state.opciones.map((opc, index) => (
              <a
                className={"btnOpcion btnDisabled"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.cambiar_opcion(opc.opc_id);
                }}
                id={"opc-" + opc.opc_id}
              >
                {opc.opc_nombre}
              </a>
            ))}
            <a
              className={"btnOpcion btnHistorial"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.historial();
              }}
            >
              {"Historial"}
            </a>

            <a
              className={"btnOpcion btnHistorial"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.tickets_usuario();
              }}
            >
              {"Tickets por paciente"}
            </a>
          </Grid>
          <div className="middle-content numeroGrid" style={{ marginBottom: 180 }}>
            <div class="infoPasa">
              {this.MostrarActual(this.state.numActual)}
              <p style={{ paddingBottom: "5px", paddingLeft: "5px", fontSize: "150%" }}>
                <br></br>
                <b>Número Previo</b>
                <br></br>
              </p>
              {this.MostrarAnterior(this.state.numAnterior)}
            </div>
            <div class="nums">{this.MostrarNumeros(this.state.numActual, this.state.numSiguiente)}</div>
          </div>

          {this.state.pacientesAtendidos ? <PacientesAtendidos tickets={this.state.ticketsAtendidos}></PacientesAtendidos> : null}
          {this.state.listaPacientesAtendidos ? <ListaPacientesAtendidos ocultar={this.ocultar_historial}></ListaPacientesAtendidos> : null}
          {this.state.datosTicket ? <DatosTicket ocultar={this.ocultar_tickets_usuario}></DatosTicket> : null}
        </div>
      </div>
    );
  }
}
