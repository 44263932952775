import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Titulo from "./../../components/titulo/Titulo.js";
import axios from "axios";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { handleError } from "./../../components/Helpers/helpers.js";
import { confirmAlert } from "react-confirm-alert";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { logoutUser } from "./../../store/actions/actions";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/cambiarClave.css";

class CambiarClave extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp: store.getState().usp,
      claveNueva: "",
      claveConfirmacion: "",
      clave: "password",
      verClave: false,
    };
  }

  logout = () => {
    this.props.logoutUser();
  };

  validaciones() {
    const { claveNueva, claveConfirmacion } = this.state;

    let formIsValid = true;

    if (claveNueva.length < 7 && claveConfirmacion.length < 7) {
      formIsValid = false;
      this.mostrarAlerta("Las contraseña deben ser mayor a 6 caracteres");
    }

    if (claveNueva != claveConfirmacion) {
      formIsValid = false;
      this.mostrarAlerta("Las contraseñas deben ser iguales");
    }

    return formIsValid;
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiar_pass = () => {
    if (this.validaciones()) {
      axios
        .post(
          global.config.modulos.usuariospanel + "cambiar_pass/",
          {
            usp_id: this.state.usp,
            pass_nueva: this.state.claveNueva,
            pass_confirmacion: this.state.claveConfirmacion,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          if (res.data.estado) {
            confirmAlert({
              title: "Éxito",
              message: res.data.mensaje,
              buttons: [
                {
                  label: "Aceptar",
                  onClick: () => this.logout(),
                  className: "btnAceptar",
                },
              ],
            });
          } else {
            confirmAlert({
              title: "Error",
              message: res.data.mensaje,
              buttons: [
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                },
              ],
            });
          }
        })
        .catch((err) => handleError(err));
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  verClaves = () => {
    const { clave } = this.state;

    if (clave === "password") {
      this.setState({
        clave: "text",
        verClave: true,
      });
    } else {
      this.setState({
        clave: "password",
        verClave: false,
      });
    }
  };

  render() {
    const { clave, verClave } = this.state;
    return (
      <div className="middle-area cambiarClave">
        <Titulo links={[]} linkActual="" titulo="Cambiar contraseña" descripcion="Permite cambiar la contraseña actual" />
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                id="claveNueva"
                name="claveNueva"
                label="Nueva contraseña"
                type={clave}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  marginTop: 20,
                }}
              />

              <TextField
                id="claveConfirmacion"
                name="claveConfirmacion"
                label="Repetir nueva contraseña"
                type={clave}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  marginTop: 20,
                }}
              />

              <Tooltip title="Ver Contraseñas" aria-label="Ver Contraseñas">
                <img
                  className="mediumIcon"
                  src={ver_mas}
                  onClick={this.verClaves}
                  style={{
                    marginTop: 30,
                    filter: verClave ? "grayscale(0%)" : "grayscale(100%)",
                  }}
                />
              </Tooltip>
            </GridItem>
            <GridItem xs={12}>
              <Button onClick={() => this.cambiar_pass()} className="botonGeneral">
                Cambiar
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default connect(null, { logoutUser })(CambiarClave);
