import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

export default function CargaGraficoDemanda(props) {
  //console.log(props.data)
  useLayoutEffect(() => {
    const dia_set = new Set();
    const diasemana = props.data[0]["dia_semana"];
    const fecha = props.data[0]["Fecha"];
    const color_set = new Set();
    const fechaDividida = fecha.split("-");
    const dd = fechaDividida[2];
    const mm = fechaDividida[1];
    const yyyy = fechaDividida[0];
    const fechaN = `${dd}-${mm}-${yyyy}`;

    for (let index = 0; index < props.data.length; index++) {
      dia_set.add(props.data[index]);
      color_set.add(am5.color(props.data[index]["color"]));
    }

    const dia = [...dia_set];
    const color = [...color_set];

    let root = am5.Root.new("graficoLunes");

    var responsive = am5themes_Responsive.newEmpty(root);

    responsive.addRule({
      name: "AxisRendererY",
      relevant: am5themes_Responsive.widthXXS,

      settings: {
        inside: true,
      },
    });

    root.setThemes([am5themes_Animated.new(root), responsive]);

    root.locale = am5locales_es_ES;
    root._logo.dispose();

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
      })
    );
    chart.children.unshift(
      am5.Label.new(root, {
        text: `Demanda Día ${fechaN}`,
        fontSize: 22,
        x: am5.percent(29),
        fontWeight: 500,
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "Value",
        categoryField: "category",
        endAngle: 270,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{category}: [bold]{value} Horas[/]",
          fill: am5.color(0xff5566),
          autoTextColor: false,
        }),
      })
    );

    series.get("colors").set("colors", color);

    series.slices.template.states.create("active", {
      shiftRadius: 1,
      strokeWidth: 2,
    });

    series.labels.template.setAll({
      fontSize: "0.8rem",
      fill: am5.color("#808080"),
      text: `[bold]{tipo}\n{category}[/]\n{value}`,
      textAlign: "left",
    });

    series.slices.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 4,
    });

    series.slices.template.events.on("click", function (ev) {
      series.slices.each(function (slice) {
        if (slice != ev.target && slice.get("active")) {
          slice.set("active", false);
        }
      });
    });

    var data = dia;

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: dia,
      title: ``,
      pdfOptions: {
        imageFormat: "jpg",
        includeData: true,
        addColumnNames: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      xlsxOptions: {
        imageFormat: "jpg",
        includeData: true,
        addColumnNames: true,
      },
      pngOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      filePrefix: `Demanda ${diasemana} ${props.data[0]["Fecha"]}`,
      dataFields: {
        category: `Categoria`,
        Value: `Total`,
        Fecha: `Fecha`,
      },
    });

    series.data.setAll(data);
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);
  return (
    <>
      <div id="graficoLunes" style={{ width: "100%", height: "440px", marginTop: "-1%" }}></div>
    </>
  );
}
