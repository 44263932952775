import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
/* eslint-disable no-use-before-define */

import { store } from "store/index";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

//IMG

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";

export default class ModalAlerta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
      contenido: props.contenido,
    };
  }

  componentDidMount() {}
  cerrarModal = () => {
    this.props.cerrarModal(2);
  };
  render() {
    return (
      <>
        <Dialog key={0} open={this.state.modal} onClose={this.cerrarModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="">
          <DialogContent>{this.state.contenido}</DialogContent>
        </Dialog>
      </>
    );
  }
}
