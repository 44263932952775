import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import BeneficioDesinscribir from "./BeneficioDesinscribirPsicologo";
import BeneficioInscribir from "./BeneficioInscribirPsicologo";
import { store } from "./../../store/index";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import moment from "moment";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioPsicologo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      datosBeneficio: props.beneficio,
      formErrors: {},
      modalBeneficioInscribir: false,
      modalBeneficioDesinscribir: false,
      beneficio: null,
      nombreBeneficio: null,
      open: true,
      herramientas: store.getState().herramientas,
      especialidades: "",
      nombreProfesional: "",
      idPrioridad: null,
    };
  }

  componentDidMount() {
    this.asignarDatosProfesional();
  }

  asignarDatosProfesional = async () => {
    let arrEspecialidades = this.state.datosBeneficio.ben_especialidades.split(",");

    var arrProfe = [];
    for (var i = 0; i < arrEspecialidades.length; i++) {
      var res = await axios.get(global.config.modulos.especialidades, {
        headers: authHeader(),
        params: {
          esp_id: parseInt(arrEspecialidades[i]),
        },
      });
      var especialidad = res.data.datos;
      var data = {
        espNombre: especialidad.esp_nombre,
      };
      var profesionales = await this.traeProfesionalPrioritario(this.state.usuId, arrEspecialidades[i]);
      if (profesionales.estado) {
        data.profesionales = profesionales.datos;
        arrProfe.push(data);
      }
    }

    this.setState({
      profesionales: arrProfe,
    });
  };

  traeProfesionalPrioritario = async (usuario, especialidad) => {
    var res = await axios.get(global.config.modulos.profesionales + "obtener_profesional_preferente/", {
      headers: authHeader(),
      params: {
        usuario: usuario,
        especialidad: especialidad,
      },
    });
    return res.data;
  };

  traeBeneficios = () => {
    this.props.traeBeneficios();
  };

  mostrarBeneficioInscribir = (beneficio, nombre, especialidad) => {
    this.setState({
      modalBeneficioInscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
      especialidades: especialidad,
    });
  };

  ocultarBeneficioInscribir = () => {
    this.setState({ modalBeneficioInscribir: false });
    this.asignarDatosProfesional();
    this.traeBeneficios();
  };

  mostrarBeneficioDesinscribir = (beneficio, nombre) => {
    this.setState({
      modalBeneficioDesinscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
    });
  };

  ocultarBeneficioDesinscribir = () => {
    this.setState({ modalBeneficioDesinscribir: false });
    this.traeBeneficios();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const beneficio = this.state.datosBeneficio;

    return (
      <React.Fragment>
        {beneficio.estadoUsuario ? (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Activo
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Fecha de ingreso: </label> {beneficio.usuario.usb_fecha_inicio}
                </p>

                {this.state.profesionales &&
                  this.state.profesionales.map((res, index) => {
                    return (
                      <>
                        <p className="parrafoBeneficio">
                          <label className="labelBeneficio">Especialidad: </label>
                          {res.espNombre}
                          <br></br>
                          <label className="labelBeneficio">Profesional asignado: </label>
                          {res.profesionales.nombre + " " + res.profesionales.apellido}
                        </p>
                      </>
                    );
                  })}

                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioDesinscribir(beneficio.ben_id, beneficio.ben_nombre)} className="botonDesinscribirBeneficio">
                      Desinscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        ) : (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Inactivo
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Fecha de ingreso: </label>
                  {" --"}
                </p>
                <br />
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioInscribir(beneficio.ben_id, beneficio.ben_nombre, beneficio.ben_especialidades)} className="botonInscribirBeneficio">
                      Inscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        )}
        {this.state.modalBeneficioInscribir ? <BeneficioInscribir ocultar={this.ocultarBeneficioInscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} especialidades={this.state.especialidades} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioInscribir> : null}
        {this.state.modalBeneficioDesinscribir ? <BeneficioDesinscribir ocultar={this.ocultarBeneficioDesinscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} idPrioridad={this.state.idPrioridad} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioDesinscribir> : null}
      </React.Fragment>
    );
  }
}
