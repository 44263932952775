import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import { store } from "store/index";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import { Grid } from "@material-ui/core";
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";
import MaterialTable from "material-table";
import Countdown from "react-countdown";
import axios from "axios";
import moment from "moment";

//JS
import ModalSolicitud from "../Dialog/ModalSolicitud";

// IMG
import Aprobar from "assets/img/solicitud_trabajo/iconos/aprobar.svg";
import Rechazar from "assets/img/solicitud_trabajo/iconos/rechazar.svg";
import Aprobar_gris from "assets/img/solicitud_trabajo/iconos/aprobarr_en_gris.svg";
import imgObservacion from "assets/img/solicitud_trabajo/iconos/motivo.svg";
import imgEditar from "assets/img/solicitud_trabajo/iconos/editar.svg";
import imgCalendario from "assets/img/solicitud_trabajo/iconos/fecha_entrega.svg";

export default class EnProduccion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prio: 0,
      seleccion: 2,
      pasoSolicitud: 1,
      abrirModal: false,
      dataSolicitud: null,
      tituloModalSolicitud: "",
      tipo_produccion: {},
    };
  }

  componentDidMount() {
    this.titulo();
    this.solicitudes();
    this.conteoSolicitudes();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "<span title='Solicitudes espera producción'>Solicitudes espera producción</span>";
  };

  solicitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          tipo: 3,
          uspId: store.getState().usp,
          estado: 6,
          tipo_solicitud: this.state.seleccion,
          enProduccion: true,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          let datos = res.data.datos;
          this.setState({
            todas_solicitudes: datos,
          });
        } else {
          this.setState({
            todas_solicitudes: [],
          });
        }
      });
  };

  conteoSolicitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_por_tipo/";
    axios.get(apiUrl, { headers: authHeader(), params: { prod: true } }).then((res) => {
      this.setState({
        tipo_produccion: res.data.tipo_produccion,
      });
    });
  };

  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  cambiarSeleccion = (seleccion) => {
    this.setState({ seleccion: seleccion }, () => {
      this.solicitudes();
    });
  };

  abrirModalMotivo = (data, titulo, vista) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo, vistaModal: vista });
  };

  pasoSolicitud = (paso) => {
    this.setState({ pasoSolicitud: paso });
  };

  render() {
    const { tipo_produccion } = this.state;
    return (
      <>
        <div className="middle-area" style={{ height: "80%", marginTop: 0 }}>
          <div className="middle-content" style={{ marginTop: 0, overflowY: "scroll", paddingTop: 5 }}>
            <div style={{ display: "flex", marginLeft: 90, width: "95%" }}>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${this.state.seleccion == 2 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(2)}>
                {tipo_produccion[2] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Informar error
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${this.state.seleccion == 3 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(3)}>
                {tipo_produccion[3] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Nueva herramienta
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${this.state.seleccion == 7 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(7)}>
                {tipo_produccion[7] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Solicitud de operaciones
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${this.state.seleccion == 4 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(4)}>
                {tipo_produccion[4] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Otro
              </button>
            </div>

            <GridContainer style={{ overflowY: "scroll", height: "85%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <div style={{ marginBottom: 100, overflowY: "scroll" }}>
                    <Grid item xs={12} sm={12}>
                      <div className="divBordeTablaHistorialHoras" style={{ paddingLeft: "20px" }}>
                        <GridContainer style={{ overflowY: "scroll", height: "85%" }}>
                          <GridItem xs={12} sm={12} md={12}>
                            <div>
                              <div style={{ marginBottom: 100, overflowY: "scroll" }}>
                                <Grid item xs={12} sm={12}>
                                  <div className="divBordeTablaHistorialHoras" style={{ paddingLeft: "20px" }}>
                                    <MaterialTable
                                      title=""
                                      columns={[
                                        {
                                          title: "Fecha de ingreso",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <p>{rowData.fecha_solicitud.split(" ")[0]}</p>
                                              <p>{rowData.fecha_solicitud.split(" ")[1]} hrs</p>
                                            </React.Fragment>
                                          ),
                                          field: "fecha_solicitud",
                                          headerStyle: {
                                            fontSize: "15px",
                                          },
                                        },
                                        {
                                          title: "N° de ticket",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <span style={{ cursor: "pointer", color: rowData.sol_estado == 8 ? "#808080" : null }} onClick={() => this.irTicket(rowData.sol_codigo)}>
                                                {rowData.sol_codigo}
                                              </span>
                                            </React.Fragment>
                                          ),
                                          field: "sol_codigo",
                                        },
                                        {
                                          title: "Nombre",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <p>{rowData.sol_dev == 1 ? "Sin asignar" : rowData.sol_autoriza}</p>
                                            </React.Fragment>
                                          ),
                                          field: "sol_autoriza",
                                        },
                                        {
                                          title: "CESFAM",
                                          field: "ces_nombre",
                                        },
                                        {
                                          title: "Prioridad",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <p>{rowData.sol_prioridad == 1 ? "Sin asignar" : rowData.prio_desc}</p>
                                            </React.Fragment>
                                          ),
                                          field: "sol_prioridad",
                                        },
                                        {
                                          title: "Tecnico a cargo",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <p>{rowData.sol_dev == 1 ? "Sin asignar" : rowData.nom_dev}</p>
                                            </React.Fragment>
                                          ),
                                          field: "sol_dev",
                                        },
                                        {
                                          title: "Fecha de entrega a cliente",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <p>{rowData.sol_entrega_aprox_cliente == null ? "Sin asignar" : moment(rowData.sol_entrega_aprox_cliente).format("DD-MM-YYYY")}</p>
                                            </React.Fragment>
                                          ),
                                          field: "sol_entrega_aprox_cliente",
                                        },
                                        {
                                          title: "Fecha de entrega del técnico",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <p>{rowData.fecha_entrega_dev.split(" ")[0]}</p>
                                              <p>{rowData.fecha_entrega_dev.split(" ")[1]} hrs</p>
                                            </React.Fragment>
                                          ),
                                          field: "sol_fecha_entrega",
                                        },
                                        {
                                          title: "Motivo",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <div style={{ display: "flex", justifyContent: "center" }}>
                                                <img src={imgObservacion} style={{ width: "30px", cursor: "pointer" }} onClick={() => this.abrirModalMotivo(rowData, "Motivo", 1)} />
                                              </div>
                                            </React.Fragment>
                                          ),
                                          field: "sol_motivo",
                                        },
                                        {
                                          title: "Observación interna",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <div style={{ display: "flex", justifyContent: "center" }}>
                                                <img src={imgObservacion} style={{ width: "30px", cursor: "pointer", marginRight: "5%", marginLeft: "-5%" }} onClick={() => this.abrirModalMotivo(rowData, "Observación", 2)} />
                                              </div>
                                            </React.Fragment>
                                          ),
                                          field: "sol_obs",
                                        },
                                        {
                                          title: "Acciones",
                                          render: (rowData) => (
                                            <React.Fragment>
                                              <div style={{ display: "flex", justifyContent: "center" }}>
                                                <img src={Aprobar} style={{ width: "30px", cursor: "pointer", marginRight: "5%" }} onClick={() => this.abrirModalMotivo(rowData, "Producción finalizada", 10)} />
                                                <img src={Rechazar} style={{ width: "30px", cursor: "pointer", marginRight: "5%" }} onClick={() => this.abrirModalMotivo(rowData, "Rechazar desarrollo", 9)} />
                                              </div>
                                            </React.Fragment>
                                          ),
                                        },
                                      ]}
                                      data={this.state.todas_solicitudes}
                                      options={{
                                        actionsColumnIndex: -1,
                                        search: false,
                                        toolbar: false,
                                        rowStyle: (rowData) => ({
                                          backgroundColor: rowData.sol_prioridad == 2 ? "#d3ecd3" : rowData.sol_prioridad == 3 ? "#f6edcc" : rowData.sol_prioridad == 4 ? "#c6d7ec" : rowData.sol_prioridad == 5 ? "#fde4d1" : rowData.sol_prioridad == 6 ? "#f3cbca" : rowData.sol_prioridad == 7 ? "#ddcec5" : null,
                                        }),
                                      }}
                                      localization={{
                                        header: {
                                          actions: "Acciones",
                                        },
                                        pagination: {
                                          labelDisplayedRows: "{from}-{to} de {count}",
                                          labelRowsSelect: "filas",
                                          firstTooltip: "primera",
                                          previousTooltip: "anterior",
                                          nextTooltip: "siguiente",
                                          lastTooltip: "última",
                                        },
                                        toolbar: {
                                          nRowsSelected: "{0} fila(s) seleccionadas",
                                          searchTooltip: "Buscar",
                                          searchPlaceholder: "Buscar",
                                        },
                                        body: {
                                          emptyDataSourceMessage: <span style={{ fontSize: "1.5rem" }}>No tiene tickets pendientes</span>,

                                          filterRow: {
                                            filterTooltip: "Buscar",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </Grid>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        {this.state.abrirModal && <ModalSolicitud data={this.state.dataSolicitud} titulo={this.state.tituloModalSolicitud} salir={this.cerrarModal} vista={this.state.vistaModal} solicitudes={this.solicitudes} />}
      </>
    );
  }
}
