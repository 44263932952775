import React, { Component } from "react";
import { store } from "../../store/index";
import { connect } from "react-redux";

import { withStyles, makeStyles } from "@material-ui/core/styles";

// core components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "../grid/GridContainer";
import GridItem from "../grid/GridItem.js";

import CambiarRendimiento from "./ModalCambioRendimiento";
import DuplicarHorario from "./ModalDuplicarHorario";
// estilos

class ModalAccionesCarga extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      horId: props.horario,
      espId: props.especialidad,

      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  duplicarHorario = (horario) => {
    this.setState({
      duplicarHorario: true,
      horarioCam: horario,
    });
  };

  ocultarDuplicarHorario = () => {
    this.setState({
      duplicarHorario: false,
    });
  };

  ocultarDuplicarHorarioActualizado = () => {
    this.setState({ duplicarHorario: false });
  };

  cambiarRendimiento = (horario, especialidad) => {
    this.setState({
      cambiarRendimiento: true,
      horarioCam: horario,
      especialidadCam: especialidad,
    });
  };

  ocultarCambiarRendimiento = () => {
    this.setState({
      cambiarRendimiento: false,
    });
  };

  ocultarExitoCambiarRendimiento = () => {
    this.setState({
      cambiarRendimiento: false,
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <GridContainer>
            <Button variant="contained" className="btnDupRenHorario fondoAzul" onClick={this.duplicarHorario.bind(this, this.state.horId)}>
              Duplicar horario
            </Button>
            <Button variant="contained" className="btnDupRenHorario fondoVerde" onClick={this.cambiarRendimiento.bind(this, this.state.horId, this.state.espId)}>
              Cambiar rendimiento anterior
            </Button>
          </GridContainer>
          {this.state.cambiarRendimiento ? <CambiarRendimiento horario={this.state.horarioCam} especialidad={this.state.especialidadCam} ocultar={this.ocultarCambiarRendimiento} ocultarExito={this.ocultarExitoCambiarRendimiento}></CambiarRendimiento> : null}
          {this.state.duplicarHorario ? <DuplicarHorario horario={this.state.horarioCam} ocultar={this.ocultarDuplicarHorario} ocultarActualizado={this.ocultarDuplicarHorarioActualizado}></DuplicarHorario> : null}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ModalAccionesCarga);
