import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridContainer from "./../../components/grid/GridContainer.js";
import Titulo from "./../../components/titulo/Titulo.js";
import { Grid } from "@material-ui/core";
import "moment/locale/es";
import axios from "axios";
import MetasVacunas from "./MetasVacunas.js";
import VistaCoberturaInfluenza from "./VistaCoberturaInfluenza.js";
import VistaRendimientoInfluenza from "./VistaRendimientoInfluenza.js";
import VistaSolExternasInfluenza from "./VistaSolExternasInfluenza.js";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/graficos.css";
import "./../../assets/css/usuarios.css";

export default class MenuVacunas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuRoot: false,
      mostrarMetasVac: false,
      mostrarCobertura: false,
      mostrarRendimiento: false,
      mostrarRendSolc: false,
      opciones: [
        { label: "Por Cesfam", val: 0 },
        { label: "Por Comuna", val: 1 },
      ],
      tiempos: [
        { label: "Diario", val: 0, meta: 20 },
        { label: "Semanal", val: 1, meta: 90 },
      ],
      comunas: [],
    };
  }

  componentDidMount() {
    this.cargarComunas();
  }

  cargarComunas = async () => {
    const apiUrl = global.config.modulos.cesfams + "comunas_con_cesfam/";
    axios.get(apiUrl,{headers: authHeader(),}).then((res) => {
      res.data.datos.map((res) => {
        if (res.ces_comuna == 1) {
          this.setState({
            comunas: [...this.state.comunas, { label: res.com_nombre, val: res.ces_comuna, id: res.ces_comuna }],
            mostrarMetasVac: true,
          });
        }
      });
    });
  };

  mostrarOpcion(opcion) {
    switch (opcion) {
      case 1:
        this.setAllFalse();
        this.setState({ mostrarMetasVac: true });
        break;
      case 2:
        this.setAllFalse();
        this.setState({ mostrarCobertura: true });
        break;
      case 3:
        this.setAllFalse();
        this.setState({ mostrarRendimiento: true });
        break;
      case 4:
        this.setAllFalse();
        this.setState({ mostrarRendSolc: true });
        break;
      default:
        this.setAllFalse();
        break;
    }
  }

  setAllFalse() {
    this.setState({
      menuRoot: false,
      mostrarMetasVac: false,
      mostrarCobertura: false,
      mostrarRendimiento: false,
      mostrarRendSolc: false,
    });
  }

  cambiarVista = (vista) => {
    this.props.history.push(vista);
  };

  render() {
    return (
      <div className="middle-area">
        {this.state.menuRoot ? (
          <Grid item xs={12} sm={4}>
            <h1 className="content-titulo">Menu Vacunas influenza</h1>
          </Grid>
        ) : null}
        {this.state.mostrarMetasVac ? (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={8} sm={8}>
                <h1 className="content-titulo">Metas Vacunas influenza</h1>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionInfluenza")}>
                  Volver a lista de pacientes
                </a>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionCovid")}>
                  Vacunación Covid_19
                </a>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {this.state.mostrarCobertura ? (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={8} sm={8}>
                <h1 className="content-titulo">Cobertura influenza</h1>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionInfluenza")}>
                  Volver a lista de pacientes
                </a>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionCovid")}>
                  Vacunación Covid_19
                </a>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {this.state.mostrarRendimiento ? (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={8} sm={8}>
                <h1 className="content-titulo">Rendimiento influenza</h1>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionInfluenza")}>
                  Volver a lista de pacientes
                </a>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionCovid")}>
                  Vacunación Covid_19
                </a>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {this.state.mostrarRendSolc ? (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={8} sm={8}>
                <h1 className="content-titulo">Rendimiento solicitudes externas y dependencia severa influenza</h1>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionInfluenza")}>
                  Volver a lista de pacientes
                </a>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", textAlign: "center" }} onClick={() => this.cambiarVista("/listaVacunacionCovid")}>
                  Vacunación Covid_19
                </a>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <br></br>
        <GridContainer>
          <Grid item xs={12} sm={12}>
            <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 1)}>
              Metas Vacunas
            </a>
            <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 2)}>
              Cobertura
            </a>
            <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 3)}>
              Rendimiento
            </a>
            <a className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 4)}>
              Rendimiento solicitudes externas
            </a>
          </Grid>
        </GridContainer>
        <br></br>
        {this.state.mostrarMetasVac ? <MetasVacunas opciones={this.state.opciones} comunas={this.state.comunas}></MetasVacunas> : null}
        {this.state.mostrarCobertura ? <VistaCoberturaInfluenza opciones={this.state.opciones} tiempos={this.state.tiempos} comunas={this.state.comunas}></VistaCoberturaInfluenza> : null}
        {this.state.mostrarRendimiento ? <VistaRendimientoInfluenza opciones={this.state.opciones} tiempos={this.state.tiempos} comunas={this.state.comunas}></VistaRendimientoInfluenza> : null}
        {this.state.mostrarRendSolc ? <VistaSolExternasInfluenza opciones={this.state.opciones} tiempos={this.state.tiempos} comunas={this.state.comunas}></VistaSolExternasInfluenza> : null}
      </div>
    );
  }
}
