import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

import moment from "moment";
import "moment/locale/es";

export default function GraficoLineaHora(props) {
  console.log(props);
  useLayoutEffect(() => {
    var root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = am5locales_es_ES;

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
      })
    );

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: props.data,
      dataFields: {
        fecha: "Día",
        seg: "Hora",
        cantidad_npr: "Cantidad Normal",
        cantidad_prf: "Cantidad Preferencial",
      },
      dataFieldsOrder: ["Día", "Hora", "Cantidad Normal", "Cantidad Preferencial"],
      pngOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      pdfOptions: {
        addURL: false,
        includeData: true,
      },
      jsonOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      title: props.title,
      filePrefix: props.filename,
    });

    exporting.events.on("pdfdocready", function (event) {
      event.doc.content.unshift({
        text: props.filename,
        margin: [0, 30],
        style: {
          fontSize: 25,
          bold: true,
        },
      });
    });

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "hour",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
        }),
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    chart.get("colors").set("colors", [am5.color("#8787f4"), am5.color("#ef9943")]);

    let toolt = am5.Tooltip.new(root, {
      labelText: "[bold]{seg}\n{fecha}\n{cantidad_npr} " + props.titulo,
    });

    toolt.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleLineCLick(datos);
    });

    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Normal",
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "cantidad_npr",
        valueXField: "hora",
        tooltip: toolt,
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
      templateField: "strokeSettings",
    });

    series.data.processor = am5.DataProcessor.new(root, {
      numericFields: ["cantidad_npr"],
      dateFields: ["hora"],
      dateFormat: "HH",
    });

    let bulletTemplate = am5.Template.new(root, {});

    bulletTemplate.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleLineCLick(datos);
    });

    series.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(
          root,
          {
            radius: 10,
            fill: series.get("fill"),
          },
          bulletTemplate
        ),
      });
    });

    series.get("tooltip").label.set("text", "[bold]Total: {valueY}");

    let toolt2 = am5.Tooltip.new(root, {
      labelText: "[bold]{seg}\n{fecha}\n{cantidad_prf} " + props.titulo,
    });

    toolt2.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleLineCLick(datos);
    });

    var series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Prefencial",
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "cantidad_prf",
        valueXField: "hora",
        tooltip: toolt2,
      })
    );

    series2.strokes.template.setAll({
      strokeWidth: 3,
      templateField: "strokeSettings",
    });

    series2.data.processor = am5.DataProcessor.new(root, {
      numericFields: ["cantidad_prf"],
      dateFields: ["hora"],
      dateFormat: "HH",
    });

    let bulletTemplate2 = am5.Template.new(root, {});

    bulletTemplate2.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleLineCLick(datos);
    });

    series2.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(
          root,
          {
            radius: 10,
            fill: series2.get("fill"),
          },
          bulletTemplate2
        ),
      });
    });

    series2.get("tooltip").label.set("text", "[bold]Total: {valueY}");

    series.data.setAll(props.data);
    series2.data.setAll(props.data);

    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      })
    );
    cursor.lineY.set("visible", false);

    let legend = chart.rightAxesContainer.children.push(am5.Legend.new(root, {}));
    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right",
    });

    legend.data.setAll(chart.series.values);

    series.appear(1000, 100);
    series2.appear(1000, 100);
    chart.appear(1000, 100);

    var modal = am5.Modal.new(root, {
      content: "No se encontraron datos para estos filtros",
    });

    if (props.data.length < 1) {
      modal.open();
    } else {
      modal.close();
    }

    return () => {
      root.dispose();
    };
  }, []);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
}
