import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default class TablaBloqueadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: [],
      datas: [],
      dates: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      hora_inicio: props.hora_inicio,
      hora_termino: props.hora_termino,
      marginb: props.marginb,
      leftb: props.lengthb,
      withb: props.withb,
      heightb: props.heightb,
      week: props.week,
      sexo: props.sexo,
      tipo_hora: props.tipo_hora,
      root: "",
      id_com: props.id_com,
      id_ces: "",
      sectores: props.sectores,
    };
  }

  tablas_bloqueadas() {
    this.setState({ total: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "horas_bloqueadas/", {
        headers: authHeader(),
        params: {
          fecha_act: this.state.fecha_actual,
          fecha_ini: this.state.fecha_inicio,
          fecha_ter: this.state.fecha_termino,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ total: res.data.datos[1] }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}
        });
      });

    this.setState({ datas: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "horas_bloqueadas/", {
        headers: authHeader(),
        params: {
          fecha_act: this.state.fecha_actual,
          fecha_ini: this.state.fecha_inicio,
          fecha_ter: this.state.fecha_termino,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ datas: res.data.datos }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv6");

          let myTheme = am5.Theme.new(root);

          myTheme.rule("Grid", ["base"]).setAll({
            strokeOpacity: 0.1,
          });

          root.setThemes([am5themes_Animated.new(root), myTheme]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
            })
          );
          let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
          });
          yRenderer.grid.template.set("location", 1);

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0,
              categoryField: "category",
              renderer: yRenderer,
            })
          );

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0,
              min: 0,
              renderer: am5xy.AxisRendererX.new(root, {
                visible: true,
                strokeOpacity: 0.1,
              }),
            })
          );

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "value",
              sequencedInterpolation: true,
              categoryYField: "category",
              tooltip: am5.Tooltip.new(root, {
                labelText: "[bold]{value} Horas[/]",
              }),
            })
          );

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              sprite: am5.Label.new(root, {
                centerY: am5.p50,
                centerX: am5.p100,
                fill: root.interfaceColors.get("alternativeText"),
                text: "{value}",
                populateText: true,
              }),
            });
          });

          let columnTemplate = series.columns.template;

          columnTemplate.setAll({
            cursorOverStyle: "pointer",
            tooltipText: "{category}: {value}",
            cornerRadiusBR: 10,
            cornerRadiusTR: 10,
            strokeOpacity: 0,
          });
          columnTemplate.adapters.add("fill", (fill, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          columnTemplate.adapters.add("stroke", (stroke, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          let data = [
            { category: "|", value: this.state.datas[1]["value"] },
            { category: "I", value: this.state.datas[0]["value"] },
          ];

          let thiss = this;

          columnTemplate.events.on("click", function (ev) {
            if (ev.target.dataItem.dataContext.category === "I") {
              thiss.handleClick("horas_disponibles");
            } else {
              thiss.handleClick("horas_bloqueadas");
            }
          });

          yAxis.data.setAll(data);
          series.data.setAll(data);

          series.appear(1000);
          chart.appear(500, 50);
        });
      });
  }

  handleClick = (index) => {
    if (index === "horas_disponibles") {
      this.setState(
        {
          marginb: "20%",
          leftb: "2%",
          withb: "50%",
          heightb: "50%",
        },
        () => {
          this.props.mostrarGraficoBloqueadas();
        }
      );
    }
    if (index === "horas_bloqueadas") {
      this.setState(
        {
          marginb: "20%",
          leftb: "2%",
          withb: "50%",
          heightb: "50%",
        },
        () => {
          this.props.mostrarGrafBlo();
        }
      );
    }
  };

  componentDidMount() {
    this.tablas_bloqueadas();
  }

  render() {
    return (
      <div
        id="tablas"
        style={{
          width: "80%",
          overflowY: "scroll",
          overflowX: "scroll",
          height: this.state.heightb,
          zIndex: 1,
          position: "fixed",
          marginTop: this.state.marginb,
          left: this.state.leftb,
        }}
      >
        <h2 style={{ marginLeft: "9%", marginTop: "20px" }}>horas iniciales por estado de hora</h2>
        <div
          id="chartdiv6"
          style={{
            display: "flex",
            textAlign: "center",
            width: "30%",
            height: "30%",
            zIndex: 1,
            marginLeft: "1%",
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
        >
          <table id="Bloqueadas" className="Bloqueadas" style={{ marginLeft: "15%", marginRight: "2%", marginTop: "6%" }}>
            <thead id="titulo bloqueadas">
              <tr id="1">
                <th style={{ border: "1px solid blue" }}>Tipo de hora</th>
                <th style={{ border: "1px solid blue" }}>Cantidad</th>
              </tr>
            </thead>
            <tbody id="body bloqueadas">
              {this.state.datas.map((row, index) => (
                <tr key={index} onClick={() => this.handleClick(row.category)}>
                  <td style={{ border: "1px solid blue", padding: "8px" }}>{row.category}</td>
                  <td style={{ border: "1px solid blue", padding: "8px" }}>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
