import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";

// core components
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";

import iconoVer from "../../assets/img/usuarios/icono_ver_mas.svg";
import icono_vista_5 from "../../assets/img/usuarios/icono_vista_5.svg";

import DetalleHoraBloqueada from "./../../components/Usuarios/DetalleHoraBloqueada";
import DetalleHoras from "./DetalleHoras";

// estilos
//import "../../assets/css/agregar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class HistorialHoras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      horas: [],
      colorEstado: ["", "", "", "", "#37a5cf", "#47cf37", "red", "#ffe000", "", "#ee4646", "", "black"],
      formErrors: {},
      anularHora: false,

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      usuId: props.usuario,

      age_id: null,
      modalHoraBloqueada: false,
    };

    this.modalAnular = this.modalAnular.bind(this);
  }

  componentDidMount() {
    this.traerHistorial(this.state.usuId);
  }

  ocultarDetalleHoraBloqueada = () => {
    this.setState({ modalHoraBloqueada: false });
  };

  mostrarDetalleHoraBloqueada = (age_id) => {
    this.setState({ modalHoraBloqueada: true, age_id: age_id });
  };

  traerHistorial(usuId) {
    let apiUrl = global.config.modulos.agendamientos + "horas_historicas_usuario/" + usuId;
    axios.get(apiUrl, { headers: authHeader(), params: { canal: 5, cesfam: this.state.cesfam } }).then((res) => {
      if (res.data.estado) {
        res.data.datos.map((res) => {
          res["age_fecha_registro"] = res["age_fecha_registro"].replace("T", " ");
          res["hor_atencion"] = res["hor_atencion"].replace("T", " ");
        });
        var horas = res.data.datos;
        this.setState({
          horas: horas,
        });
      }
    });
  }

  fechaTexto(dateString) {
    var meses = [" ", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    var fecha = dateString.split("-");
    var ano = fecha[0];
    var mes = meses[parseInt(fecha[1])];
    var dia = fecha[2];

    var fechaCompleta = dia + " de " + mes + " del " + ano;

    return fechaCompleta;
  }

  modalAnular = (age_id = null) => {
    this.setState({
      anularHora: !this.state.anularHora,
      age_id: age_id,
    });
    this.traerHistorial(this.state.usuId);
  };

  ocultarDetalleHora = () => {
    this.setState({ modalHora: false });
  };

  mostrarDetalleHora = (age_id) => {
    this.setState({ modalHora: true, age_id: age_id });
  };

  render() {
    return (
      <div className="historialHoras" style={{ marginLeft: "-10px", marginBottom: 180 }}>
        <Grid container spacing={2} className="fichaUsuario scroll-container">
          <Grid item xs={12}>
            <div>
              <img className="imagenDatoSeleccionado" src={icono_vista_5} style={{ marginTop: 65 }} />
              <div className="datosUsuario " style={{ border: "inherit", marginTop: "-110px" }}>
                <div className="divBordeTablaHistorialHoras" style={{ width: "100%", float: "left" }}>
                  <MaterialTable
                    title=""
                    columns={[
                      {
                        title: "Fecha de solicitud",
                        field: "age_fecha_registro",
                      },
                      { title: "Tipo de atención", field: "especialidad" },
                      { title: "Profesional", field: "nombreProfesional" },
                      {
                        title: "Canal",
                        render: (rowData) =>
                          rowData.age_canal == 5 ? (
                            <React.Fragment>
                              <p>
                                {rowData.canal}: {rowData.medio_reserva}
                              </p>
                              <p>{rowData.nombre_usuario_panel}</p>

                              {rowData.age_grupo_familiar ? (
                                <React.Fragment>
                                  <Tooltip title={rowData.familiar_que_reservo + " - " + rowData.rut_familiar_que_reservo} aria-label={rowData.familiar_que_reservo + " - " + rowData.rut_familiar_que_reservo}>
                                    <p
                                      style={{
                                        marginTop: 5,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {"Reservada por grupo familiar"}
                                    </p>
                                  </Tooltip>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            rowData.canal
                          ),
                      },
                      {
                        title: "Estado",
                        render: (rowData) =>
                          rowData.estadoHorario == 0 ? (
                            <a onClick={() => this.mostrarDetalleHoraBloqueada(rowData.age_id)} style={{ cursor: "pointer" }}>
                              Bloqueada
                            </a>
                          ) : rowData.modalidad != "--" ? (
                            <React.Fragment>
                              <Tooltip
                                title={
                                  <React.Fragment>
                                    <div>
                                      <label
                                        style={{
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {rowData.modalidad}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                }
                                placement="right"
                                interactive
                                arrow
                                className="tooltipHorario"
                              >
                                <p
                                  style={{
                                    marginTop: 5,
                                    cursor: "pointer",
                                  }}
                                >
                                  {rowData.estadoAgenda}
                                </p>
                              </Tooltip>
                            </React.Fragment>
                          ) : (
                            rowData.estadoAgenda
                          ),
                      },

                      { title: "Fecha de atención", field: "hor_atencion" },
                      {
                        title: "Registros de la hora",
                        headerStyle: { fontSize: "16px" },
                        render: (rowData) => (
                          <>
                            <Tooltip title="Ver Registros de la hora" aria-label="" style={{ marginRight: 2 }}>
                              <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.mostrarDetalleHora.bind(this, rowData.age_id)} src={iconoVer} />
                            </Tooltip>
                          </>
                        ),
                      },
                    ]}
                    data={this.state.horas}
                    options={{
                      actionsColumnIndex: -1,
                      search: true,
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No tiene horas pasadas",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        {this.state.modalHoraBloqueada ? <DetalleHoraBloqueada ocultar={this.ocultarDetalleHoraBloqueada} agenda={this.state.age_id}></DetalleHoraBloqueada> : null}
        {this.state.modalHora ? <DetalleHoras ocultar={this.ocultarDetalleHora} agenda={this.state.age_id}></DetalleHoras> : null}
      </div>
    );
  }
}
