import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "store/index";
import "moment/locale/es";
import moment from "moment";

// core components
import { Grid } from "@material-ui/core";
import GridContainer from "components/grid/GridContainer.js";
import { confirmAlert } from "react-confirm-alert";
import InputLabel from "@material-ui/core/InputLabel";

import MaterialTable from "material-table";

//JS
import ModalAlerta from "../Ticket/modalAlerta";
import ModalSolicitud from "../Dialog/ModalSolicitud";
import Pendientes from "./Pendiente";
import Enviados from "./Enviados";
import Rechazados from "./Rechazados";
import Terminados from "./Terminados";

//IMAGENES

//CSS
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

class HistorialNotificaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      solicitudes: [],

      contenido: "",
      mensajeErr: "",

      modalDeAlerta: false,
      motivoErr: false,
      abrirModal: false,

      seleccion: 1,
    };
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Confirmación de solicitud<span title='Confirmación de solicitud'></span>";
  };

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  cerrar = () => {
    this.setState({
      modalDeAlerta: false,
    });
  };

  cambiarSeleccion = (seleccion) => {
    this.setState({ seleccion: seleccion });
  };

  abrirModalMotivo = (data, titulo, vista) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo, vistaModal: vista });
  };

  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  render() {
    const { seleccion } = this.state;

    return (
      <>
        <div className="middle-area" style={{ height: "80%" }}>
          <div className="middle-content">
            <div style={{ display: "flex", marginLeft: 90, width: "95%" }}>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 1 && SolicitudTrabajo.botonSelected}`} style={{ backgroundColor: "#c641a7", height: 45 }} onClick={() => this.cambiarSeleccion(1)}>
                Pendientes
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 2 && SolicitudTrabajo.botonSelected}`} style={{ backgroundColor: "#3fb8b7", height: 45 }} onClick={() => this.cambiarSeleccion(2)}>
                Enviados
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 3 && SolicitudTrabajo.botonSelected}`} style={{ backgroundColor: "#eaa029", height: 45 }} onClick={() => this.cambiarSeleccion(3)}>
                Rechazados
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 4 && SolicitudTrabajo.botonSelected}`} style={{ backgroundColor: "#1bacdd", height: 45 }} onClick={() => this.cambiarSeleccion(4)}>
                Terminados
              </button>
            </div>

            {seleccion == 1 && <Pendientes seleccion={seleccion} />}
            {seleccion == 2 && <Enviados seleccion={seleccion} />}
            {seleccion == 3 && <Rechazados seleccion={seleccion} />}
            {seleccion == 4 && <Terminados seleccion={seleccion} />}
          </div>
        </div>

        {this.state.abrirModal && <ModalSolicitud data={this.state.dataSolicitud} titulo={this.state.tituloModalSolicitud} salir={this.cerrarModal} vista={this.state.vistaModal} solicitudes={this.solicitudes} />}
      </>
    );
  }
}

export default HistorialNotificaciones;
