import React from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function TableHeader(props) {
  const theme = useTheme();
  const nombreTitulo = props.nombreTitulo;
  const [ordenarPrioridad, setOrdenarPrioridad] = React.useState(null);
  const [ordenar, setOrdenar] = React.useState(null);

  function handleFiltradorCampo(campo) {
    var campo = campo;
    //console.log("ordenarPrioridad: " + ordenarPrioridad);
    //var ordens = 'asc'
    if (campo != "nulo") {
      setOrdenar(true);
      if (campo === ordenarPrioridad) {
        campo = "-" + campo;
        //ordens = 'desc'
        setOrdenar("asc");
      } else if (ordenarPrioridad === "-" + campo) {
        campo = null;
        //var ordens = null
        setOrdenar("desc");
      }
      setOrdenarPrioridad(campo);
      props.datocampo(campo);
    }
  }

  //function that ordenar icon apears in only one column
  function handleOrdenarIcon(campo) {
    if (ordenarPrioridad === campo) {
      return true;
    } else if (ordenarPrioridad === "-" + campo) {
      return true;
    } else {
      return false;
    }
  }

  //tableHead with table row and TableSortLabel

  return (
    <TableHead>
      <TableRow>
        {nombreTitulo.map((column, index) =>
          column.filtro == "nulo" ? (
            <TableCell key={index} align="center">
              {" "}
              <p style={{ fontSize: "14px" }}>
                {column.nombre} {handleOrdenarIcon(column.filtro)}{" "}
              </p>
            </TableCell>
          ) : (
            <TableCell key={column.filtro}>
              <TableSortLabel active={handleOrdenarIcon(column.filtro)} direction={ordenar === "asc" ? "asc" : "desc"} onClick={() => handleFiltradorCampo(column.filtro)}>
                <p style={{ fontSize: "14px" }}>{column.nombre}</p>
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
    /* Send campo  */
  );
}
