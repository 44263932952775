import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import moment from "moment";
import "moment/locale/es";

import { store } from "store/index";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Grid, List, ListItem } from "@material-ui/core";
import { socketTotem } from "../../../service/socket";

// core components
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import MaterialTable from "material-table";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import icono_eliminar from "assets/img/usuarios/icono_eliminar.svg";

import chileanRut from "chilean-rut";
import Tooltip from "@material-ui/core/Tooltip";

// CSS
import crearTotem from "assets/css/crearTotem.module.css";

class Opciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opc_id: 0,

      separarPre: "",

      cesfam: store.getState().cesfam,
      totem: store.getState().totem,

      opciones: [],
      agregar: false,
      modificar: false,
    };
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  componentDidMount() {
    this.opcionesCreadasTot();
  }

  opcionesCreadasTot = () => {
    const apiUrl = global.config.modulos.admin_totem + "opciones_creadas/?totem=" + this.state.totem;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;
      this.setState({ opciones: data.opciones });
    });
  };

  cambiar_valores = (e) => {
    const { value, name } = e.target;
    console.log(value, name);

    this.setState({ [name]: value });
  };

  alertaEliminarOpcion = (opc_id) => {
    confirmAlert({
      title: "",
      message: "Deseas eliminar esta opción",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminar(opc_id),
          className: "btnAceptar",
        },
      ],
    });
  };

  eliminar = (opc_id) => {
    const apiUrl = global.config.modulos.admin_totem + "opcion/?opc_id=" + opc_id;
    axios.delete(apiUrl, { headers: authHeader() }).then((res) => {
      var apiUrl = global.config.modulos.admin_totem + "reiniciar_pantalla/";
      axios
        .get(apiUrl, {
          headers: authHeader(),
          params: {
            totem: this.state.totem,
          },
        })
        .then((res) => {
          this.opcionesCreadasTot();
        });
    });
  };

  modificar = (opc_id) => {
    this.setState(
      {
        modificar: true,
        opc_id: opc_id,
      },
      () => {
        this.datos_opcion(opc_id);
      }
    );
  };

  salirModificar = () => {
    this.setState({
      modificar: false,
      opc_id: 0,
      selectPre: "0",
    });
  };

  datos_opcion = (opc_id) => {
    const apiUrl = global.config.modulos.admin_totem + "opcion/?opc_id=" + opc_id;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;

      document.getElementById("nombre").value = data.opc_nombre;
      document.getElementById("nombre_pantalla").value = data.opc_nombre_pantalla;
      document.getElementById("abreviatura").value = data.opc_abreviatura;
      document.getElementById("cantidad").value = data.opc_cantidad_ticket;

      var preferencial = 0;
      if (data.opc_preferencial != 0) {
        preferencial = 1;
      }
      document.getElementById("selectPre").value = preferencial;
      if (preferencial == 1) {
        this.setState({ selectPre: preferencial }, () => {
          document.getElementById("separarPre").value = data.opc_preferencial_activo;
        });
      }
    });
  };

  modificarD = () => {
    if (this.validacion()) {
      const nombre = document.getElementById("nombre").value;
      const nombre_pantalla = document.getElementById("nombre_pantalla").value;
      const abreviatura = document.getElementById("abreviatura").value;
      const cantidad = document.getElementById("cantidad").value;
      const selectPre = document.getElementById("selectPre").value;
      if (selectPre == 1) {
        var separarPre = document.getElementById("separarPre").value;
      } else {
        var separarPre = false;
      }

      const data = {
        opc_id: this.state.opc_id,
        nombre: nombre,
        nombre_pantalla: nombre_pantalla,
        abreviatura: abreviatura,
        cantidad_ticket: cantidad,
        preferencial: selectPre,
        totem: this.state.totem,
        separarPre: separarPre,
      };

      axios.patch(global.config.modulos.admin_totem + "opcion/", data, { headers: authHeader() }).then((res) => {
        socketTotem.emit("reiniciar_pantalla", {
          totem: this.state.totem,
        });

        this.salirModificar();
        this.opcionesCreadasTot();
        this.mostrarAlerta("Modificado con éxito");
      });
    }
  };

  agregar = (e) => {
    this.setState({ agregar: true, opc_id: 0 });
  };

  ocultarAgregar = () => {
    this.setState({ agregar: false, opc_id: 0, selectPre: "0" });
    this.opcionesCreadasTot();
  };

  validacion = () => {
    const nombre = document.getElementById("nombre").value;
    const nombre_pantalla = document.getElementById("nombre_pantalla").value;
    const abreviatura = document.getElementById("abreviatura").value;
    const preferencia = document.getElementById("selectPre").value;

    if (!nombre) {
      this.mostrarAlerta("Escribe el nombre del módulo.");
      return false;
    } else if (!nombre_pantalla) {
      this.mostrarAlerta("Escribe el nombre que saldra en la pantalla.");
      return false;
    } else if (!abreviatura) {
      this.mostrarAlerta("Escribe la abreviatura.");
      return false;
    }

    if (preferencia == "1") {
      if (this.state.separarPre == "") {
        this.mostrarAlerta("Seleccione si necesita separar la preferencia.");
        return false;
      }
    }

    return true;
  };

  agregarD = () => {
    if (this.validacion()) {
      var nombre = document.getElementById("nombre").value;
      var nombre_pantalla = document.getElementById("nombre_pantalla").value;
      var abreviatura = document.getElementById("abreviatura").value;
      var cantidad = document.getElementById("cantidad").value;
      var selectPre = document.getElementById("selectPre").value;

      if (selectPre == 1) {
        var separarPre = document.getElementById("separarPre").value;
      } else {
        var separarPre = false;
      }

      var data = {
        nombre: nombre,
        nombre_pantalla: nombre_pantalla,
        abreviatura: abreviatura,
        cantidad_ticket: cantidad,
        preferencial: selectPre,
        totem: this.state.totem,
        separarPre: separarPre,
      };

      axios.post(global.config.modulos.admin_totem + "opcion/", data, { headers: authHeader() }).then((res) => {
        socketTotem.emit("reiniciar_pantalla", {
          totem: this.state.totem,
        });

        this.ocultarAgregar();
        this.opcionesCreadasTot();
        this.mostrarAlerta("Agregado con éxito");
      });
    }
  };

  render() {
    return (
      <GridContainer style={{ width: "100%", marginTop: "10px", justifyContent: "space-between" }}>
        <GridItem xs={12} sm={12} md={12}>
          <div className={crearTotem.divBordeTablaAccesos} style={{ fontSize: "1rem !important" }}>
            <MaterialTable
              title=""
              columns={[
                { title: "Nombre del módulo", field: "opc_nombre" },
                {
                  title: "Cantidad de tickets por día",
                  field: "opc_cantidad_ticket",
                },
                {
                  title: "Opción preferencial",
                  field: "opc_preferencial",
                  render: (rowData) => <React.Fragment>{rowData.opc_preferencial == 1 ? "SÍ" : "NO"}</React.Fragment>,
                },
                {
                  title: "Acciones",
                  render: (rowData) => (
                    <React.Fragment>
                      <div>
                        <img className="botonesTablas" style={{ marginRight: "20px", width: "30px" }} src={icono_modificar} onClick={this.modificar.bind(this, rowData.opc_id)} />
                        <img className="botonesTablas" style={{ width: "30px" }} src={icono_eliminar} onClick={this.alertaEliminarOpcion.bind(this, rowData.opc_id)} />
                      </div>
                    </React.Fragment>
                  ),
                },
              ]}
              data={this.state.opciones}
              actions={[
                {
                  icon: "person_add",
                  tooltip: "Agregar opción",
                  isFreeAction: true,
                  onClick: (event) => this.agregar(),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                search: true,
              }}
              localization={{
                header: {
                  actions: "Acciones",
                },
              }}
            />
          </div>
        </GridItem>

        {this.state.modificar ? (
          <div>
            <Dialog open={this.state.modificar} onClose={this.salirModificar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent>
                <h1>Modificar módulo</h1>
                <br></br>
                <br></br>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Nombre módulo</InputLabel>
                      <input type="text" id={"nombre"} className={crearTotem.input} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Nombre módulo en pantalla</InputLabel>
                      <input type="text" id={"nombre_pantalla"} className={crearTotem.input} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Abreviatura en ticket</InputLabel>
                      <input type="text" id={"abreviatura"} className={crearTotem.input} style={{ width: "100%" }} maxLength="4" />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <InputLabel className={crearTotem.textosTitulos}>Cantidad tickets</InputLabel>
                      <input type="number" id={"cantidad"} className={crearTotem.input} defaultValue={"1"} min="1" style={{ width: "100%" }} />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <InputLabel className={crearTotem.textosTitulos}>Opción preferencial</InputLabel>
                      <NativeSelect className={crearTotem.selectores} onChange={this.cambiar_valores} name="selectPre" disableUnderline={true} id={"selectPre"} style={{ width: "100%" }}>
                        <option value="0">No</option>
                        <option value="1">Sí</option>
                      </NativeSelect>
                    </Grid>

                    {this.state.selectPre == "1" ? (
                      <Grid item xs={4} sm={4} style={{ marginTop: "20px" }}>
                        <Tooltip title="Si esta opción se configura de forma manual, el funcionario podrá decidir, según su criterio, a qué fila llamar durante la atención. Por ejemplo, en el módulo de farmacia, se podrá elegir entre llamar a la fila 'normal' o 'preferencial'.">
                          <InputLabel className={crearTotem.textosTitulos}>Fila preferencial</InputLabel>
                        </Tooltip>
                        <NativeSelect className={crearTotem.selectores} onChange={this.cambiar_valores} name="separarPre" value={this.state.separarPre} disableUnderline={true} id={"separarPre"} style={{ width: "100%" }}>
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value={false}>Llamado manual</option>
                          <option value={true}>Llamado automático</option>
                        </NativeSelect>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <br></br>
                <br></br>
                <a
                  className={crearTotem.btnAgregarAcceso}
                  style={{ width: "15%" }}
                  onClick={() => {
                    this.modificarD();
                  }}
                >
                  {"Modificar"}
                </a>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}

        {this.state.agregar ? (
          <div>
            <Dialog open={this.state.agregar} onClose={this.ocultarAgregar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent>
                <h1>Agregar módulo</h1>
                <br></br>
                <br></br>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Nombre módulo</InputLabel>
                      <input type="text" id={"nombre"} className={crearTotem.input} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Nombre módulo en pantalla</InputLabel>
                      <input type="text" id={"nombre_pantalla"} className={crearTotem.input} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Abreviatura en ticket</InputLabel>
                      <input type="text" id={"abreviatura"} className={crearTotem.input} style={{ width: "100%" }} maxLength={4} />
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      sm={4}
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <InputLabel className={crearTotem.textosTitulos}>Cantidad tickets</InputLabel>
                      <input type="number" id={"cantidad"} className={crearTotem.input} defaultValue={"1"} min="1" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={4} sm={4} style={{ marginTop: "20px" }}>
                      <InputLabel className={crearTotem.textosTitulos}>Opción preferencial</InputLabel>
                      <NativeSelect disableUnderline={true} onChange={this.cambiar_valores} className={crearTotem.selectores} name="selectPre" id={"selectPre"} style={{ width: "100%" }}>
                        <option value="0">No</option>
                        <option value="1">Sí</option>
                      </NativeSelect>
                    </Grid>

                    {this.state.selectPre == "1" ? (
                      <Grid item xs={4} sm={4} style={{ marginTop: "20px" }}>
                        <Tooltip title="Si esta opción se configura de forma manual, el funcionario podrá decidir, según su criterio, a qué fila llamar durante la atención. Por ejemplo, en el módulo de farmacia, se podrá elegir entre llamar a la fila 'normal' o 'preferencial'.">
                          <InputLabel className={crearTotem.textosTitulos}>Fila preferencial</InputLabel>
                        </Tooltip>
                        <NativeSelect className={crearTotem.selectores} onChange={this.cambiar_valores} name="separarPre" value={this.state.separarPre} disableUnderline={true} id={"separarPre"} style={{ width: "100%" }}>
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value={false}>Llamado manual</option>
                          <option value={true}>Llamado automático</option>
                        </NativeSelect>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <br></br>
                <br></br>
                <a
                  className={crearTotem.btnAgregarAcceso}
                  style={{ width: "6vw", height: "40px" }}
                  onClick={() => {
                    this.agregarD();
                  }}
                >
                  Agregar
                </a>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Opciones);
