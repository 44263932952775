import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Titulo from "./../../components/titulo/Titulo.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NotificationManager } from "react-notifications";
import csvBeneficio from "./../../assets/img/ejemplo_csv_beneficio.png";
import csvBeneficioEmbarazada from "../../assets/img/imgCargaMasiva/6.png";
import csvBeneficioControlEmbarazo from "../../assets/img/imgCargaMasiva/8.png";
import csvBeneficioTratamiento from "../../assets/img/imgCargaMasiva/7.png";
import csvBeneficioIraEra from "../../assets/img/imgCargaMasiva/11.png";
import csvBeneficioKine from "../../assets/img/imgCargaMasiva/3.png";
import csvBeneficioCronico from "../../assets/img/imgCargaMasiva/2.png";
import csvBeneficioRiesgoDental from "../../assets/img/imgCargaMasiva/2.png";
import csvMultipleProfesional from "../../assets/img/imgCargaMasiva/csv_multiple_profesional.png";
import csvBeneficioCiclo from "../../assets/img/imgCargaMasiva/beneficioCiclo.png";
import { store } from "./../../store/index";
import carga_ciclos from "../../assets/img/imgCargaMasiva/ciclos.svg";
import carga_beneficios from "../../assets/img/imgCargaMasiva/beneficios.svg";
import carga_prenaturo from "../../assets/img/imgCargaMasiva/prematuros.svg";
import carga_reservaExterba from "../../assets/img/imgCargaMasiva/reservas externas.svg";
import carga_fallecidos from "../../assets/img/imgCargaMasiva/fallecidos.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";

import signo from "./../../assets/img/signo.svg";

// CSS
import "./../../assets/css/usuarios.css";
import moduleCM from "../../assets/css/cargamasiva.module.css";

export default class CargarBeneficios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      beneficios: [],
      beneficio: null,
      id_beneficio: null,
      tipo_beneficio: null,
      nombre_beneficio: null,
      variacion_beneficio: 0,
      datos: [],
      ciclos: [],
      ciclo: null,
      tipo_carga: "cargarBeneficios",
      herramientas: store.getState().herramientas,
      ultimaCarga: null,
      niveles: [],
      nivel: null,
      idCarga: null,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.traeBeneficios();
    this.titulo();
  }

  ultimaCarga() {
    axios
      .get(global.config.modulos.cargas_masivas + "beneficios/ultima_carga/", {
        headers: authHeader(),
        params: {
          id_usp: this.state.uspId,
          nombre_beneficio: this.state.nombre_beneficio,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          let fecha = res.data.datos.cam_fecha_registro.split("T");
          dia = String(fecha[0]).split("-");
          let dia = dia[0] + "-" + dia[1] + "-" + dia[2];
          this.setState({ ultimaCarga: "Última carga: " + dia + " a las " + fecha[1], idCarga: res.data.datos.cam_id });
        } else {
          this.setState({ ultimaCarga: null });
        }
      });
  }

  descargarExcel = (e) => {
    const url = global.config.modulos.cargas_masivas + "beneficios/excel_errores/?id_carga=" + this.state.idCarga;
    window.open(url, "_blank").focus();
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva  <span title='Permite ingresar o actualizar a través de un archivo .csv información sobre los ciclos de los pacientes'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  traeBeneficios = () => {
    axios
      .get(global.config.modulos.cargas_masivas + "beneficios/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ beneficios: res.data.datos });
        }
      });
  };

  traerNiveles(ciclo) {
    axios
      .get(global.config.modulos.cargas_masivas + "ciclos/niveles/", {
        headers: authHeader(),
        params: { cic_id: ciclo },
      })
      .then((res) => {
        this.setState({ niveles: res.data, nivel: null });
      })
      .catch((error) => {
        this.setState({ niveles: [], nivel: null });
      });
  }

  cambioValor = (event) => {
    const { name, value } = event.target;
    let beneficio = value.split(";");
    let id_beneficio = beneficio[0];
    let tipo_beneficio = beneficio[1];
    let variacion = beneficio[2];
    let index = beneficio[3];
    let nombre = beneficio[4];
    this.setState(
      {
        [name]: value,
        id_beneficio: id_beneficio,
        tipo_beneficio: tipo_beneficio,
        variacion_beneficio: variacion,
        ciclos: this.state.beneficios[index]["ciclos_menu"],
        nombre_beneficio: nombre,
      },
      () => {
        this.ultimaCarga();
      }
    );
  };

  cambioValorSimple = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleOnDrop = (data) => {
    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    this.setState({ datos: registros });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    document.getElementById("cargando").style.display = "block";

    if (this.state.tipo_beneficio == 15 && this.state.ciclos.length > 0) {
      if (this.state.nivel == null) {
        NotificationManager.warning("Debe seleccionar el nivel de atención", "", 30 * 1000);
        document.getElementById("cargando").style.display = "none";
        return;
      }
    }

    axios
      .post(
        global.config.modulos.cargas_masivas + "beneficios/carga_masiva/",
        {
          beneficios: this.state.datos,
          uspId: this.state.uspId,
          beneficio: this.state.id_beneficio,
          nivel: this.state.nivel,
          id_ciclo: this.state.ciclo,
          cesfam: this.state.cesfam,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success("los registros se están ingresando, se le notificará cuando termine", "", 3 * 60 * 1000);
        } else {
          NotificationManager.warning(res.data.mensaje, "", 5 * 60 * 1000);
          if (res.data.errores.length > 0) {
            let mensaje = "";

            res.data.errores.forEach((reg) => {
              mensaje += "rut paciente: " + reg["rut_paciente"] + ", error: " + reg["mensaje"] + "\n";
            });

            const element = document.createElement("a");
            const file = new Blob([mensaje], {
              type: "text/plain;charset=utf-8",
            });
            element.href = URL.createObjectURL(file);
            element.download = "excepciones.txt";
            document.body.appendChild(element);
            element.click();
          }
        }
      });
  }

  cambiarVista = (e) => {
    const { name, value } = e.target;
    this.setState({ tipo_carga: value }, () => {
      this.props.history.push(this.state.tipo_carga);
    });
  };

  cargarCiclos = () => {
    this.props.history.push("/cargarCiclos");
  };

  cargarFallecidos = () => {
    this.props.history.push("/cargarFallecidos");
  };

  cargarCondiciones = () => {
    this.props.history.push("/cargarCondiciones");
  };

  cargarBeneficios = () => {
    this.props.history.push("/cargarBeneficios");
  };

  cargarReservasExternas = () => {
    this.props.history.push("/cargarReservasExternas");
  };

  cambioValorCiclo = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.traerNiveles(event.target.value);
      }
    );
  };

  render() {
    const { beneficios, beneficio, datos, ultimaCarga, niveles, ciclos } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={moduleCM.divIcon}>
                    <img className={moduleCM.imgCMciclos1} src={carga_ciclos} onClick={this.cargarCiclos} />

                    <img className={moduleCM.imgCMbeneficio1} src={carga_beneficios} onClick={this.cargarBeneficios} />

                    <img className={moduleCM.imgCMfallecido} src={carga_fallecidos} onClick={this.cargarFallecidos} />

                    {this.state.herramientas.includes("H44") ? <img className={moduleCM.imgCMprematuro} src={carga_prenaturo} onClick={this.cargarCondiciones} /> : null}

                    {this.state.herramientas.includes("H47") ? <img className={moduleCM.imgCMreservaExterna} src={carga_reservaExterba} onClick={this.cargarReservasExternas} /> : null}
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Grid item xs={12} sm={12}>
                    {ultimaCarga && (
                      <Grid item xs={12} sm={12}>
                        <Button
                          onClick={this.descargarExcel}
                          style={{
                            backgroundColor: "#bf3c50",
                            color: "white",
                            marginTop: 20,
                          }}
                        >
                          Descargar errores - {ultimaCarga}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                      Inscribir/Desinscribir
                    </InputLabel>
                    <NativeSelect
                      className={moduleCM.selectCiclos}
                      value={this.state.tipo_carga}
                      onChange={this.cambiarVista}
                      inputProps={{
                        name: "tipo_carga",
                        id: "tipo_carga",
                      }}
                    >
                      <option value="cargarBeneficios">Inscribir en beneficio</option>
                      <option selected value="eliminarBeneficios">
                        Desinscribir de beneficio
                      </option>
                    </NativeSelect>
                  </Grid>
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                          Beneficio
                        </InputLabel>
                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.beneficio}
                          onChange={this.cambioValor}
                          inputProps={{
                            name: "beneficio",
                            id: "beneficio",
                          }}
                        >
                          <option value="">Seleccione beneficio</option>
                          {beneficios &&
                            beneficios.map((cic, index) => (
                              <option value={cic["ben_id"] + ";" + cic["ben_tipo"] + ";" + cic["ben_variacion"] + ";" + index + ";" + cic["ben_nombre"]} key={index}>
                                {cic["ben_nombre"]}
                              </option>
                            ))}
                        </NativeSelect>
                      </Grid>

                      {this.state.tipo_beneficio == 15 && ciclos.length > 0 ? (
                        <>
                          <Grid item xs={6} sm={6}>
                            <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                              Ciclo
                            </InputLabel>

                            <NativeSelect
                              className={moduleCM.selectCiclos}
                              value={this.state.ciclo}
                              onChange={this.cambioValorCiclo}
                              inputProps={{
                                name: "ciclo",
                                id: "ciclo",
                              }}
                            >
                              <option value="">Seleccione ciclo</option>
                              {ciclos &&
                                ciclos.map((cic, index) => (
                                  <option value={cic["id"]} key={index}>
                                    {cic["nombre"]}
                                  </option>
                                ))}
                            </NativeSelect>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required style={{ maxWidth: "50%" }}>
                              Debes seleccionar el último tipo de atención que tuvo el paciente, a partir de ello se configura la próxima atención que le corresponde.
                            </InputLabel>
                            <NativeSelect
                              className={moduleCM.selectCiclos}
                              value={this.state.nivel}
                              onChange={this.cambioValorSimple}
                              inputProps={{
                                name: "nivel",
                                id: "nivel",
                              }}
                            >
                              <option value="">Seleccione atención</option>
                              {niveles &&
                                niveles.map((ces, index) => (
                                  <option value={ces["cin_id"]} key={index}>
                                    {ces["cin_nombre"]}
                                  </option>
                                ))}
                            </NativeSelect>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                  <br></br>
                  {beneficio && (
                    <Grid item xs={12} sm={12}>
                      <span>Cargar CSV</span>
                      <div className={moduleCM.csvCargaMasiva}>
                        <CSVReader
                          onDrop={this.handleOnDrop}
                          onError={this.handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={this.handleOnRemoveFile}
                          config={{
                            header: true,
                            dynamicTyping: true,
                            skipEmptyLines: true,
                            transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                          }}
                        >
                          <div className={moduleCM.divSpanArchivo}>
                            <span className={moduleCM.spanArchivo}>Seleccione archivo</span>
                            <span style={{ fontSize: "0.9vw" }}> O arrastre archivo .CSV </span>
                          </div>
                        </CSVReader>
                        <LinearProgress id="cargando" style={{ display: "none" }} />
                      </div>
                    </Grid>
                  )}
                </Grid>
                {beneficio && datos.length > 0 && (
                  <Grid item xs={12}>
                    <Button
                      onClick={this.cargaMasiva}
                      style={{
                        backgroundColor: "#3cbf71",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      Cargar registros
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {this.state.tipo_beneficio == 4 && <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioTratamiento} alt="csv" />}
                {this.state.tipo_beneficio == 1 && (
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <label>* Si no incluye la fecha de inicio del beneficio se tomará la fecha de carga como inicio del beneficio.</label>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioEmbarazada} alt="csv" />
                    </Grid>
                  </React.Fragment>
                )}
                {this.state.tipo_beneficio == 7 && <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioIraEra} alt="csv" />}
                {this.state.tipo_beneficio == 5 && <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioKine} alt="csv" />}
                {this.state.tipo_beneficio == 3 && this.state.variacion_beneficio == 1 && <img className={moduleCM.imgEjemploCiclo} src={csvMultipleProfesional} alt="csv" />}
                {((this.state.tipo_beneficio == 3 && this.state.variacion_beneficio != 1) || this.state.tipo_beneficio == 14) && <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioKine} alt="csv" />}
                {this.state.tipo_beneficio == 6 && <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioRiesgoDental} alt="csv" />}
                {this.state.tipo_beneficio == 12 && <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioControlEmbarazo} alt="csv" />}
                {(this.state.tipo_beneficio == 8 || this.state.tipo_beneficio == 9 || this.state.tipo_beneficio == 10 || this.state.tipo_beneficio == 11 || this.state.tipo_beneficio == 13) && <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioCronico} alt="csv" />}
                {this.state.tipo_beneficio == 15 && ciclos.length > 0 ? <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioCiclo} alt="csv" /> : null}
                {this.state.tipo_beneficio == 15 && ciclos.length == 0 ? <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioRiesgoDental} alt="csv" /> : null}
                {this.state.tipo_beneficio == 17 && ciclos.length == 0 ? <img className={moduleCM.imgEjemploCiclo} src={csvBeneficioRiesgoDental} alt="csv" /> : null}
              </Grid>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
