import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class CasosRegistrados extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      open: true,
      usuario: props.usuario,
      demanda: props.demanda,
      registros: [],
    };
  }

  componentDidMount() {
    this.casosRegistrados();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  casosRegistrados = () => {
    axios
      .get(global.config.modulos.demanda + "registros_usuario/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuario,
          idDemanda: this.state.demanda,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ registros: res.data.datos });
        }
      });
  };

  render() {
    const { registros } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="casosRegistrados">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <h2>Gestiones del paciente</h2>
              <GridContainer className="agendar-container">
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  {registros &&
                    registros.map((reg, index) => (
                      <p
                        key={index}
                        style={{
                          marginTop: 10,
                          borderRadius: 10,
                          border: "2px solid #eaa028",
                          padding: 15,
                        }}
                      >
                        <GridContainer className="agendar-container">
                          <GridItem xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Gestión:</label> {"Nivel 3"}
                            </p>
                          </GridItem>
                          <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Fecha de ingreso:</label>
                              {reg.ged_fecha_registro.replace("T", " ")}
                            </p>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Centro:</label>
                              {reg.cesfam}
                            </p>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Ubicación:</label>
                              {reg.ubicacion}
                            </p>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Motivo finalización:</label>
                              {reg.ged_motivo_fin ? reg.ged_motivo_fin : "--"}
                            </p>
                          </GridItem>
                          <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Tomado por:</label>
                              {reg.usuario_panel}
                            </p>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Requerimiento:</label>
                              {reg.ged_requerimiento}
                            </p>

                            <React.Fragment>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Fecha de finalización:</label>
                                {reg.ged_fecha_finalizacion ? reg.ged_fecha_finalizacion.replace("T", " ") : "--"}
                              </p>
                            </React.Fragment>
                          </GridItem>
                          <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Observación:</label>
                              {reg.ged_motivo_fin_libre ? reg.ged_motivo_fin_libre : "--"}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </p>
                    ))}
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <a onClick={this.salir} style={{ marginLeft: 20 }} className="btnAgregarCaso fondoNaranjo">
                    Aceptar
                  </a>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
