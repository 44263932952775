import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GraficoPrincipal from "components/Graficos/OfertaRechazo/PrincipalGraf";
import GraficoBloqueadas from "components/Graficos/OfertaRechazo/GraficoBloqueadasPG";
import TablaBloqueadas from "components/Graficos/OfertaRechazo/TablaBloqueadas";
import TablaSexo from "components/Graficos/OfertaRechazo/TablaSexo";
import GraficoCes from "components/Graficos/OfertaRechazo/CesfamBlo";
import GraficoCesfam from "components/Graficos/OfertaRechazo/CesfamXsector";
import GraficoAgendas from "components/Graficos/OfertaRechazo/AgendasXcesfam";
import GraficoCanal from "components/Graficos/OfertaRechazo/CanalesGraf";
import TablaDemanda from "components/Graficos/OfertaRechazo/DemandaTabla";
import GrafS_C from "components/Graficos/OfertaRechazo/Age_Ces_S_C";
import GrafL_S_C from "components/Graficos/OfertaRechazo/Lib_Ces_S_C";
import GrafBlo from "components/Graficos/OfertaRechazo/GrafBloqu";
import GrafBloCes from "components/Graficos/OfertaRechazo/GrafBloquCes";
import GrafRecha from "components/Graficos/OfertaRechazo/GrafRecha";
import GrafRechaCes from "components/Graficos/OfertaRechazo/GrafRechaCes";
import GrafOfr from "components/Graficos/OfertaRechazo/GrafOfre";
import GrafOfrCes from "components/Graficos/OfertaRechazo/GrafOfreCes";
import GrafRechaCan from "components/Graficos/OfertaRechazo/GrafRechaCanal";
import GrafOfrCan from "components/Graficos/OfertaRechazo/GrafOfreCanal";
import Button from "@material-ui/core/Button";
import Titulo from "components/titulo/Titulo.js";
import signo from "assets/img/signo.svg";

import "assets/css/general.css";
import "assets/css/usuarios.css";
import "assets/css/general.css";

import { Input, InputLabel } from "@material-ui/core";
import GrafBloquCes from "components/Graficos/OfertaRechazo/GrafBloquCes";

export default class OfertaRechazo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_actual: "",
      fecha_inicio: "",
      fecha_termino: "",
      hora_inicio: "",
      hora_termino: "",
      id_com: "",
      sexo: "",
      id_ces: props.ces_id,
      id_sec: props.id_sec,
      marginb: "0px",
      withb: "40%",
      heightb: "70%",
      lengthb: "40%",
      withPG: "40%",
      heightPG: "70%",
      withBQ: "40%",
      heightBQ: "70%",
      op: "1",
      trans: "opacity 500ms ease-in-out",
      ces_id: "",
      tipo_hora: props.tipo_hora,
      mostrarGraficoBloqueada: false,
      mostrarGraficoPrincipal: false,
      mostrarTablaBloqueadas: false,
      mostrarTablaSexo: false,
      mostrarGraficoCes: false,
      mostrarGraficoCesfam: false,
      mostrarGraficoAgendas: false,
      mostrarGraficoCanal: false,
      mostrarTablaDemanda: false,
      mostrarGrafBlo: false,
      mostrarGrafBloCes: false,
      mostrarS_C: false,
      mostrarL_S_C: false,
      mostrarGrafRecha: false,
      mostrarGrafRechaCes: false,
      mostrarGrafRechaCanal: false,
      mostrarGrafOfr: false,
      mostrarGrafOfrCes: false,
      mostrarGrafOfrCan: false,
      mostrarFiltro: "none",
      filtro: true,
      sectores: [],
      loading: true,
      mostraVolver: "none",
      display: "none",
      displayS: "none",
      edad_max: "",
      edad_min: "",
      displayE: "none",
      stc_nombre: "",
      sec: "",
      id_b_1: "",
      id_b_2: "",
      display12: "50%",
    };
  }

  actualizarDatos = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  mostrarPrincipal = () => {
    this.setState(
      {
        mostrarGraficoBloqueada: false,
        mostrarFiltro: "none",
        mostraVolver: "none",
        displayE: "none",
      },
      () => {
        this.setState({ mostrarGraficoPrincipal: true, displayS: "none" });
      }
    );
  };
  mostrarTablaDemanda = () => {
    this.setState(
      {
        mostrarGraficoCanal: false,
        mostrarGraficoAgendas: false,
        mostrarGraficoBloqueada: false,
        mostrarTablaDemanda: false,
        mostrarGraficoCes: false,
        mostrarTablaSexo: false,
        mostraVolver: "initial",
      },
      () => {
        this.setState({
          mostrarTablaDemanda: true,

          displayS: "none",
        });
      }
    );
  };

  mostrarTablaBloqueadas = () => {
    this.setState(
      {
        mostrarGraficoCanal: false,
        mostrarGraficoAgendas: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoCes: false,
        mostrarTablaBloqueadas: false,
        mostrarTablaSexo: false,
        mostraVolver: "initial",
      },
      () => {
        this.setState({
          mostrarTablaBloqueadas: true,
          displayE: "none",
        });
      }
    );
  };

  mostrarGraficoBloqueadas = () => {
    this.setState(
      {
        mostrarGraficoAgendas: false,
        mostrarGraficoCanal: false,
        mostrarTablaSexo: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoCes: false,
        mostrarGraficoCesfam: false,
        mostrarTablaDemanda: false,
        mostrarTablaBloqueadas: false,
        mostrarGraficoPrincipal: false,
        displayE: "initial",
      },
      () => {
        this.setState({
          mostrarGraficoBloqueada: true,
        });
      }
    );
  };

  mostrarTablaSexo = () => {
    this.setState(
      {
        mostrarGraficoCanal: false,
        mostrarTablaSexo: false,
        mostrarGraficoCesfam: false,
      },
      () => {
        this.setState({
          mostrarTablaSexo: true,
          mostrarGraficoBloqueada: true,
        });
      }
    );
  };

  mostrarGrafBlo = () => {
    this.setState(
      {
        mostrarGrafBlo: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoAgendas: false,
        mostrarGraficoCanal: false,
        mostrarTablaSexo: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoCes: false,
        mostrarGraficoCesfam: false,
        mostrarTablaDemanda: false,
        mostrarTablaBloqueadas: false,
        mostrarGraficoPrincipal: false,
      },
      () => {
        this.setState({
          mostrarGrafBlo: true,
        });
      }
    );
  };

  mostrarGrafBloCes = (id_b_1) => {
    this.setState(
      {
        id_b_1: id_b_1,
        mostrarGrafBloCes: false,
      },
      () => {
        this.setState({
          mostrarGrafBloCes: true,
        });
      }
    );
  };

  mostrarS_C = (id_com) => {
    this.setState(
      {
        mostrarGraficoAgendas: false,
        id_com: id_com,
      },
      () => {
        this.setState({
          mostrarS_C: true,
        });
      }
    );
  };

  mostrarL_S_C = (id_com) => {
    this.setState(
      {
        mostrarL_S_C: false,
        id_com: id_com,
      },
      () => {
        this.setState({
          mostrarL_S_C: true,
        });
      }
    );
  };

  mostrarGraficosCes = () => {
    this.setState(
      {
        mostrarGraficoCanal: false,
        mostrarGraficoCes: false,
        mostrarGraficoCesfam: false,
      },
      () => {
        this.setState({
          mostrarGraficoCes: true,
          mostrarGraficoBloqueada: true,
        });
      }
    );
  };

  mostrarGraficoCesfam = (stc_nombre, sec) => {
    this.setState(
      {
        sec: sec,
        mostrarGraficoAgendas: false,
        mostrarGraficoCanal: false,
        stc_nombre: stc_nombre,
        mostrarGraficoCes: false,
        mostrarTablaSexo: false,
        mostrarGraficoCesfam: false,
        mostrarGraficoBloqueada: false,
      },
      () => {
        this.setState({
          mostrarGraficoCesfam: true,
        });
      }
    );
  };

  mostrarGraficoCanal = (ces_id) => {
    this.setState(
      {
        mostrarGraficoCanal: false,
        ces_id: ces_id,
      },
      () => {
        this.setState({
          mostrarGraficoCanal: true,
        });
      }
    );
  };

  mostrarGraficoAgendas = (stc_nombre, sec) => {
    this.setState(
      {
        sec: sec,
        stc_nombre: stc_nombre,
        mostrarGraficoCes: false,
        mostrarTablaSexo: false,
        mostrarGraficoCesfam: false,
        mostrarGraficoAgendas: false,
        mostrarGraficoBloqueada: false,
      },
      () => {
        this.setState({
          mostrarGraficoAgendas: true,
        });
      }
    );
  };

  mostrarGrafRecha = () => {
    this.setState(
      {
        mostrarGrafBlo: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoAgendas: false,
        mostrarGraficoCanal: false,
        mostrarTablaSexo: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoCes: false,
        mostrarGraficoCesfam: false,
        mostrarTablaDemanda: false,
        mostrarTablaBloqueadas: false,
        mostrarGraficoPrincipal: false,
        mostrarGrafRecha: false,
      },
      () => {
        this.setState({
          mostrarGrafRecha: true,
        });
      }
    );
  };

  mostrarGrafRechaCes = (id_b_1) => {
    this.setState(
      {
        id_b_1: id_b_1,
        mostrarGrafRechaCes: false,
      },
      () => {
        this.setState({
          mostrarGrafRechaCes: true,
        });
      }
    );
  };

  mostrarGrafOfr = () => {
    this.setState(
      {
        mostrarGrafBlo: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoAgendas: false,
        mostrarGraficoCanal: false,
        mostrarTablaSexo: false,
        mostrarGraficoBloqueada: false,
        mostrarGraficoCes: false,
        mostrarGraficoCesfam: false,
        mostrarTablaDemanda: false,
        mostrarTablaBloqueadas: false,
        mostrarGraficoPrincipal: false,
        mostrarGrafRecha: false,
        mostrarGrafOfr: false,
      },
      () => {
        this.setState({
          mostrarGrafOfr: true,
        });
      }
    );
  };

  mostrarGrafOfrCes = (id_b_1) => {
    this.setState(
      {
        id_b_1: id_b_1,
        mostrarGrafOfrCes: false,
      },
      () => {
        this.setState({
          mostrarGrafOfrCes: true,
        });
      }
    );
  };

  mostrarGrafRechaCanal = (id_b_2) => {
    this.setState(
      {
        mostrarGrafRechaCan: false,
        id_b_2: id_b_2,
      },
      () => {
        this.setState({
          display12: "",
          mostrarGrafRecha: false,
          mostrarGrafRechaCan: true,
        });
      }
    );
  };

  mostrarGrafOfrCan = (id_b_2) => {
    this.setState(
      {
        mostrarGrafOfrCan: false,
        id_b_2: id_b_2,
      },
      () => {
        this.setState({
          display12: "",
          mostrarGrafOfr: false,
          mostrarGrafOfrCan: true,
        });
      }
    );
  };

  titulo = () => {
    if (this.state.fecha_actual === "") {
      document.getElementsByClassName("content-titulo")[0].innerHTML = "Graficos Horas del Dia actual <span title=''><img src=" + signo + "></img></span>";
    } else {
      document.getElementsByClassName("content-titulo")[0].innerHTML = "Graficos Horas <span title=''><img src=" + signo + "></img></span>";
    }
  };

  volver = () => {
    if (this.state.mostrarGraficoCanal === true) {
      this.setState({
        mostrarGraficoCanal: false,
      });
    } else if (this.state.mostrarL_S_C === true) {
      this.setState({
        mostrarL_S_C: false,
      });
    } else if (this.state.mostrarGraficoAgendas === true) {
      this.setState({
        mostrarGraficoAgendas: false,
        mostrarGraficoCes: true,
        mostrarGraficoBloqueada: true,
        mostrarTablaSexo: true,
      });
    } else if (this.state.mostrarGraficoCes === true || this.state.mostrarTablaSexo === true) {
      this.setState({
        mostrarGraficoCes: false,
        mostrarTablaSexo: false,
      });
    } else if (this.state.mostrarGraficoBloqueada === true) {
      this.setState({
        mostrarGraficoBloqueada: false,
        mostrarTablaBloqueadas: true,
        mostrarTablaDemanda: true,
        mostrarGraficoPrincipal: true,
      });
    } else if (this.state.mostrarTablaBloqueadas === true || this.state.mostrarTablaDemanda === true) {
      this.setState({
        mostrarTablaBloqueadas: false,
        mostrarTablaDemanda: false,
        mostraVolver: "none",
      });
    } else if (this.state.mostrarS_C === true) {
      this.setState({
        mostrarS_C: false,
        mostrarGraficoAgendas: true,
      });
    } else if (this.state.mostrarGraficoCesfam === true) {
      this.setState({
        mostrarGraficoCesfam: false,
        mostrarGraficoCes: true,
        mostrarGraficoBloqueada: true,
        mostrarTablaSexo: true,
      });
    } else if (this.state.mostrarGrafBlo === true) {
      this.setState({
        mostrarGrafBlo: false,
        mostrarTablaBloqueadas: true,
        mostrarTablaDemanda: true,
        mostrarGraficoPrincipal: true,
        mostrarGrafBloCes: false,
      });
    } else if (this.state.mostrarGrafBlo === true || this.state.mostrarGrafBloCes === true) {
      this.setState({
        mostrarGrafBloCes: false,
      });
    } else if (this.state.mostrarGrafRecha === true) {
      this.setState({
        mostrarGrafRecha: false,
        mostrarTablaBloqueadas: true,
        mostrarTablaDemanda: true,
        mostrarGraficoPrincipal: true,
        mostrarGrafBlo: false,
        mostrarGrafRechaCes: false,
      });
    } else if (this.state.mostrarGrafOfr === true) {
      this.setState({
        mostrarGrafRecha: false,
        mostrarTablaBloqueadas: true,
        mostrarTablaDemanda: true,
        mostrarGraficoPrincipal: true,
        mostrarGrafBlo: false,
        mostrarGrafOfr: false,
        mostrarGrafOfrCes: false,
      });
    } else if (this.state.mostrarGrafRechaCan === true) {
      this.setState(
        {
          mostrarGrafRechaCan: false,
          mostrarGrafRechaCes: false,
          mostrarGrafRecha: true,
          display12: "50%",
        },
        () => {
          this.setState({
            mostrarGrafRechaCes: true,
          });
        }
      );
    } else if (this.state.mostrarGrafOfrCan === true) {
      this.setState(
        {
          mostrarGrafOfrCan: false,
          mostrarGrafOfrCes: false,
          mostrarGrafOfr: true,
          display12: "50%",
        },
        () => {
          this.setState({
            mostrarGrafOfrCes: true,
          });
        }
      );
    }
  };

  botonBorrar = () => {
    this.setState({
      fecha_inicio: "",
      fecha_termino: "",
      hora_inicio: "",
      hora_termino: "",
      fecha_actual: "",
      sexo: "",
      edad_max: "",
      edad_min: "",
    });
  };

  filtrarDatos = () => {
    this.setState(
      {
        filtro: false,
      },
      () => {
        this.setState({ filtro: true });
      }
    );
  };

  componentDidMount() {
    this.mostrarPrincipal();
    this.titulo();
  }

  render() {
    const { fecha_inicio, fecha_termino, edad_max, edad_min, fecha_actual } = this.state;

    return (
      <div className="middle-area">
        <label style={{ marginLeft: "10px" }}>Rango de fechas: </label>
        <Input
          name="fecha_inicio"
          type="date"
          value={fecha_inicio}
          onChange={this.actualizarDatos}
          style={{
            backgroundColor: "transparent",
            borderBottom: "solid 1px",
            marginLeft: "10px",
          }}
        ></Input>
        <Input
          name="fecha_termino"
          type="date"
          value={fecha_termino}
          onChange={this.actualizarDatos}
          style={{
            backgroundColor: "transparent",
            borderBottom: "solid 1px",
            marginLeft: "10px",
          }}
        ></Input>

        <input
          name="edad_min"
          placeholder="Edad minima"
          value={edad_min}
          onChange={this.actualizarDatos}
          style={{
            backgroundColor: "transparent",
            borderBottom: "solid 1px",
            marginLeft: "10px",
            display: this.state.displayE,
          }}
        ></input>

        <input
          name="edad_max"
          value={edad_max}
          onChange={this.actualizarDatos}
          placeholder="Edad maxima"
          style={{
            backgroundColor: "transparent",
            borderBottom: "solid 1px",
            marginLeft: "10px",
            display: this.state.displayE,
          }}
        ></input>

        <Button className="botonGeneral" onClick={() => this.filtrarDatos()} style={{ marginLeft: "10px" }}>
          FILTRAR
        </Button>
        <Button className="botonGeneral" onChange={this.actualizarDatos} onClick={() => this.volver()} style={{ marginLeft: "10px", display: this.state.mostraVolver }}>
          VOLVER
        </Button>
        <Button className="botonGeneral" onClick={() => this.botonBorrar()} style={{ marginLeft: "10px" }}>
          LIMPIAR
        </Button>
        {this.state.filtro && this.state.mostrarGraficoPrincipal && <GraficoPrincipal fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} mostrarTablaBloqueadas={this.mostrarTablaBloqueadas} mostrarTablaDemanda={this.mostrarTablaDemanda} withPG={this.state.withPG} heightPG={this.state.heightPG} hora_inicio={this.state.hora_inicio} hora_termino={this.state.hora_termino} displayE={this.state.displayE} op={this.state.op} trans={this.state.trans} />}
        {this.state.filtro && this.state.mostrarGraficoBloqueada && <GraficoBloqueadas fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} withBQ={this.state.withBQ} heightBQ={this.state.heightBQ} hora_inicio={this.state.hora_inicio} hora_termino={this.state.hora_termino} mostrarTablaSexo={this.mostrarTablaSexo} mostrarGraficosCes={this.mostrarGraficosCes} edad_max={this.state.edad_max} edad_min={this.state.edad_min} />}

        {this.state.filtro && this.state.mostrarTablaBloqueadas && <TablaBloqueadas edad_max={this.state.edad_max} edad_min={this.state.edad_min} withb={this.state.withb} heightb={this.state.heightb} lengthb={this.state.lengthb} marginb={this.state.marginb} fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} mostrarGraficoBloqueadas={this.mostrarGraficoBloqueadas} mostrarGrafBlo={this.mostrarGrafBlo} />}
        {this.state.filtro && this.state.mostrarTablaDemanda && <TablaDemanda edad_max={this.state.edad_max} edad_min={this.state.edad_min} withb={this.state.withb} heightb={this.state.heightb} lengthb={this.state.lengthb} marginb={this.state.marginb} fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} mostrarGrafRecha={this.mostrarGrafRecha} mostrarGrafOfre={this.mostrarGrafOfr} />}
        {this.state.filtro && this.state.mostrarTablaSexo && <TablaSexo fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} stc_nombre={this.state.stc_nombre} mostrarGraficoAgendas={this.mostrarGraficoAgendas} />}
        {this.state.filtro && this.state.mostrarGraficoCes && <GraficoCes fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} stc_nombre={this.state.stc_nombre} mostrarGraficoCesfam={this.mostrarGraficoCesfam} />}
        {this.state.filtro && this.state.mostrarGraficoCesfam && <GraficoCesfam fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} stc_nombre={this.state.stc_nombre} mostrarL_S_C={this.mostrarL_S_C} />}
        {this.state.filtro && this.state.mostrarGraficoAgendas && <GraficoAgendas fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} stc_nombre={this.state.stc_nombre} mostrarGraficoCanal={this.mostrarGraficoCanal} mostrarS_C={this.mostrarS_C} ces_id={this.state.ces_id} sec={this.state.sec} />}
        {this.state.filtro && this.state.mostrarGraficoCanal && <GraficoCanal fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} ces_id={this.state.ces_id} edad_max={this.state.edad_max} edad_min={this.state.edad_min} />}
        {this.state.filtro && this.state.mostrarS_C && <GrafS_C fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} id_com={this.state.id_com} edad_max={this.state.edad_max} edad_min={this.state.edad_min} mostrarGraficoCanal={this.mostrarGraficoCanal} />}
        {this.state.filtro && this.state.mostrarL_S_C && <GrafL_S_C fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} id_com={this.state.id_com} edad_max={this.state.edad_max} edad_min={this.state.edad_min} />}
        {this.state.filtro && this.state.mostrarGrafBlo && <GrafBlo fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} id_b_1={this.state.id_b_1} mostrarGrafBloCes={this.mostrarGrafBloCes} />}
        {this.state.filtro && this.state.mostrarGrafBloCes && <GrafBloquCes fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} id_b_1={this.state.id_b_1} />}
        {this.state.filtro && this.state.mostrarGrafRecha && <GrafRecha fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} mostrarGrafRechaCes={this.mostrarGrafRechaCes} />}
        {this.state.filtro && this.state.mostrarGrafRechaCes && <GrafRechaCes fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} id_b_1={this.state.id_b_1} display12={this.state.display12} mostrarGrafRechaCanal={this.mostrarGrafRechaCanal} />}
        {this.state.filtro && this.state.mostrarGrafOfr && <GrafOfr fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} mostrarGrafOfrCes={this.mostrarGrafOfrCes} />}
        {this.state.filtro && this.state.mostrarGrafOfrCes && <GrafOfrCes fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} id_b_1={this.state.id_b_1} display12={this.state.display12} mostrarGrafOfrCan={this.mostrarGrafOfrCan} />}
        {this.state.filtro && this.state.mostrarGrafRechaCan && <GrafRechaCan fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} id_b_2={this.state.id_b_2} />}
        {this.state.filtro && this.state.mostrarGrafOfrCan && <GrafOfrCan fecha_actual={this.state.fecha_actual} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} edad_max={this.state.edad_max} edad_min={this.state.edad_min} id_b_2={this.state.id_b_2} />}
      </div>
    );
  }
}
