import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";

import { connect } from "react-redux";
import chileanRut from "chilean-rut";
import Countdown from "react-countdown";

// core components
import ver_mas from "assets/img/gestion/ver_mas.svg";
import agregar from "assets/img/gestion/agendar.svg";

import modi_icon from "assets/img/usuarios/icono_modificar.svg";

import Paginacion from "components/Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";

import TableHeader from "components/Table/TableHeader";

import VistaUsuarios from "components/GestionPrioritarios/VistaUsuarios";
import EditarDatos from "components/GestionDespacho/EditarDatos";
import VerObservaciones from "components/GestionDespacho/VerObservaciones";
import VerHistorial from "components/GestionDespacho/Historial";
import AgregarObservacion from "components/GestionDespacho/AgregarObservacion";
import RegistrosPrevios from "components/GestionDespacho/RegistrosPrevios";

import { confirmAlert } from "react-confirm-alert"; // Import

class GestionGeneralTomados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      pagina: 0,
      total: 0,
      filas: 10,
      nombreTitulo: [
        { nombre: "Fecha de solicitud", filtro: "loi_fecharegistro" },
        { nombre: "Tiempo de espera", filtro: "loi_rut" },
        { nombre: "Rut paciente", filtro: "loi_nombre" },
        { nombre: "Dirección", filtro: "loi_fecha_nacimiento" },
        { nombre: "Registros previos", filtro: "loi_sexo" },
        { nombre: "Despacho", filtro: "loi_cesfam" },
        { nombre: "Observaciones", filtro: "loi_cesfam" },
        { nombre: "Historial", filtro: "loi_cesfam" },
        { nombre: "Requerimiento", filtro: "loi_cesfam" },
      ],
      texto_filtro: props.texto_filtro,

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
    };
  }

  componentDidMount() {
    this.traeTabla();
  }

  traeTabla = () => {
    axios
      .get(global.config.modulos.gestion + "tabla_gestion_despacho/", {
        headers: authHeader(),
        params: {
          texto_filtro: this.state.texto_filtro,
          cesfam: this.state.cesfam,
          ubicacion: 3,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ datos: res.data.datos });
        }
      });
  };

  mostrarModalUsuario = (usuId) => {
    this.setState({
      modalUsuario: true,
      usuId: usuId,
    });
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  tomarCaso = (gdoId) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea tomar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.demanda + "tomar_caso/",
                {
                  gdoId: gdoId,
                  uspId: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {});
          },
        },
      ],
    });
  };

  mostrarRegistrosPrevios = (usu_id, gdoId) => {
    this.setState({
      registrosPrevios: true,
      usu_id: usu_id,
      gdoId: gdoId,
    });
  };

  ocultarRegistrosPrevios = () => {
    this.setState({
      registrosPrevios: false,
    });
  };

  mostrarHistorial = (gdoId) => {
    this.setState({
      verHistorial: true,
      gdoId: gdoId,
    });
  };

  ocultarHistorial = () => {
    this.setState({
      verHistorial: false,
    });
  };

  mostrarModalEditar = (usu_id, direccion) => {
    this.setState({
      mostrarModalEditar: true,
      usu_id: usu_id,
      direccion: direccion,
    });
  };

  cerrarModalEditar = () => {
    this.setState({
      mostrarModalEditar: false,
    });
    this.traeTabla();
  };

  verObservaciones = (gdoId) => {
    this.setState({
      verObservaciones: true,
      gdoId: gdoId,
    });
  };

  ocultarObservaciones = () => {
    this.setState({
      verObservaciones: false,
    });
  };

  agregarObservacion = (gdoId) => {
    this.setState({
      agregarObservacion: true,
      gdoId: gdoId,
    });
  };

  ocultarAgregarObservacion = () => {
    this.setState({
      agregarObservacion: false,
    });
  };

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }

    return (
      <span>
        {days + " días, "}
        {hours}:{minutes}:{seconds}
      </span>
    );
  };

  render() {
    return (
      <div className="middle-content">
        <TableContainer style={{ marginBottom: "120px" }}>
          <Table className="tablaGeneralGestion bordeTabla" aria-label="simple table">
            <TableHeader nombreTitulo={this.state.nombreTitulo} datocampo={this.handleFiltradorCampo} />
            <TableBody>
              {this.state.datos.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <p style={{ fontSize: "14px" }}>{moment(row.gdo_fecha_registro).format("DD-MM-YYYY HH:mm")} hrs</p>
                  </TableCell>

                  <TableCell align="center">
                    <Countdown date={row.gdo_fecha_registro.replace("T", " ")} renderer={this.renderer} overtime={true} />
                  </TableCell>

                  <TableCell>{<a onClick={() => this.mostrarModalUsuario(row.usuario)}>{chileanRut.format(row.rut, true)}</a>}</TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <p style={{ fontSize: "14px" }}>{row.direccion}</p>
                    </React.Fragment>
                    <React.Fragment>
                      <Tooltip title={<h4>Modificar dirección</h4>}>
                        <div>
                          <img class="mediumIcon" src={modi_icon} onClick={() => this.mostrarModalEditar(row.usuario, row.direccion)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>

                  <TableCell align="center">
                    <div>
                      <img class="mediumIcon" src={ver_mas} onClick={() => this.mostrarRegistrosPrevios(row.usuario, row.gdo_id)}></img>
                    </div>
                  </TableCell>

                  <TableCell align="center">{row.gdo_despacho == 1 ? <label>Alimentos</label> : row.gdo_despacho == 2 ? <label>Medicamentos y/o alimentos</label> : <label>Medicamentos</label>}</TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip title="Ver observaciones" aria-label="Ver observaciones" onClick={() => this.verObservaciones(row.gdo_id)}>
                        <img className="mediumIcon" src={ver_mas} />
                      </Tooltip>
                    </React.Fragment>
                    <React.Fragment>
                      <Tooltip title="Agregar observación" aria-label="Agregar observación" onClick={() => this.agregarObservacion(row.gdo_id)}>
                        <img className="mediumIcon" src={agregar} />
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip title="Historial" aria-label="Historial" onClick={() => this.mostrarHistorial(row.gdo_id)}>
                        <img className="mediumIcon" src={ver_mas} />
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <p style={{ fontSize: "14px" }}>{row.gdo_estado_solicitud == null ? <>--</> : row.gdo_estado_solicitud != null ? <>{row.gdo_estado_solicitud == 1 ? <>Aprobado</> : <>Rechazado</>}</> : <>--</>}</p>
                    </React.Fragment>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
        </TableContainer>

        {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.usuId}></VistaUsuarios> : null}
        {this.state.mostrarModalEditar ? <EditarDatos ocultar={this.cerrarModalEditar} usu_id={this.state.usu_id} direccion={this.state.direccion}></EditarDatos> : null}
        {this.state.agregarObservacion ? <AgregarObservacion ocultar={this.ocultarAgregarObservacion} gdo_id={this.state.gdoId}></AgregarObservacion> : null}
        {this.state.verObservaciones ? <VerObservaciones ocultar={this.ocultarObservaciones} gdo_id={this.state.gdoId}></VerObservaciones> : null}
        {this.state.verHistorial ? <VerHistorial ocultar={this.ocultarHistorial} gdo_id={this.state.gdoId}></VerHistorial> : null}
        {this.state.registrosPrevios ? <RegistrosPrevios ocultar={this.ocultarRegistrosPrevios} usu_id={this.state.usu_id} gestion={this.state.gdoId}></RegistrosPrevios> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(GestionGeneralTomados);
