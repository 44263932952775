import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { store } from "./../../store/index";

// estilos
//import "../../assets/css/agregar.css";

export default class DetalleReservaExterna extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      uspid: store.getState().usp,
      idRegistro: props.idRegistro,
      intentosContacto: [],
      open: true,
    };
  }

  componentDidMount() {
    this.traerDetalle();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerDetalle = () => {
    axios
      .get(global.config.modulos.reservas_externas + "detalle/", {
        headers: authHeader(),
        params: {
          id_reserva_externa: this.state.idRegistro,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ intentosContacto: res.data.datos });
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="recordatoriosExternosDetalle">
          <DialogContent>
            <h2>Detalle contacto</h2>
            <Table className="bordeTabla" aria-label="simple table " stickyHeader style={{ marginTop: 20 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="cellInicio" align="center">
                    Fecha contacto
                  </TableCell>
                  <TableCell align="center">Canal</TableCell>
                  <TableCell align="center">Teléfono</TableCell>
                  <TableCell className="cellTermino" align="center">
                    Detalle
                  </TableCell>
                  <TableCell className="cellTermino" align="center">
                    Estado
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.intentosContacto.map((int, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{int["grd_fecha_registro"].replace("T", " ")}</TableCell>
                    <TableCell align="center">{int["canal"]}</TableCell>
                    <TableCell align="center">{int["grd_telefono"] ? int["grd_telefono"] : "--"}</TableCell>

                    <TableCell align="center">{int["grd_contesta"] == 1 ? "Responde" : "No responde"}</TableCell>
                    <TableCell align="center">
                      {int["grd_respuesta"] == 1 && "Confirma"}
                      {int["grd_respuesta"] == 2 && "Anula"}
                      {int["grd_respuesta"] == 0 && "No presiona ninguna opción"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button className="botonDialog fondoRojo" onClick={this.salir}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
