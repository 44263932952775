import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import "react-datepicker/dist/react-datepicker.css";
import Titulo from "../../components/titulo/Titulo.js";
import nsp_por_paciente_con_texto from "../../assets/img/gestion/nsp_por_paciente_con_texto.svg";
import grafico_historico_con_texto from "../../assets/img/gestion/grafico_historico_con_texto.svg";
import grafico_historico_sin_texto from "../../assets/img/gestion/grafico historico.svg";
import nsp_por_paciente_sin_texto from "../../assets/img/gestion/nsp por paciente.svg";
import ListaNSP from "./ListaNSP.js";
import GraficoNSP from "./GraficoNSP.js";
import moment from "moment";

import { Grid, Input, InputLabel, Select, MenuItem, Tooltip } from "@material-ui/core";

import GridContainer from "../../components/grid/GridContainer.js";
import GridItem from "../../components/grid/GridItem";

import Button from "@material-ui/core/Button";

//import "./../../../assets/css/graficos.css";
import signo from "./../../assets/img/signo.svg";

class VistaNSP extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");
    this.state = {
      name: "",
      showListaNSP: false,
      showGraficoNSP: true,
      startDate: null,
      endDate: null,
      nsp: [],
      mostrarData: false,
      fecha_actual: date,
    };
    //console.log(this.state.fecha_actual);
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "NSP (No se presenta) <span title='Podrá visualizar toda la información relacionada a los pacientes NSP'><img src=" + signo + "></img></span>";
  };

  abrirNsp = () => {
    this.setState({ showListaNSP: false, showGraficoNSP: true, mostrarData: false });
  };

  abrirListaNsp = () => {
    this.setState({ showListaNSP: true, showGraficoNSP: false, mostrarData: false });
  };

  filtrarFechas = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  botonFiltrarFechas = () => {
    if (this.state.showListaNSP) {
      this.setState({ showListaNSP: false }, () => {
        this.setState({ showListaNSP: true, mostrarData: true });
      });
    }

    if (this.state.showGraficoNSP) {
      this.setState({ showGraficoNSP: false }, () => {
        this.setState({ showGraficoNSP: true, mostrarData: true });
      });
    }
  };

  onMouseOver = (e) => {
    e.target.style.width = parseInt(e.target.style.width) + 20;
    e.target.style.height = parseInt(e.target.style.height) + 20;
  };
  onMouseLeave = (e) => {
    e.target.style.width = parseInt(e.target.style.width) - 20;
    e.target.style.height = parseInt(e.target.style.height) - 20;
  };

  render() {
    const { showListaNSP, showGraficoNSP, mostrarData } = this.state;
    const fecha_completa = moment().format("YYYY-MM-DD");

    return (
      <div className="middle-area">
        <div className="middle-content">
          <div style={{ marginLeft: "20px", display: "flex" }}>
            {showGraficoNSP ? (
              <Tooltip title="Grafico Nsp" aria-label="Lista en otros casos">
                <img className="imagenBotonAccionMenu" src={grafico_historico_con_texto} onClick={() => this.abrirNsp()} style={{ width: 80, height: 80 }} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} />
              </Tooltip>
            ) : (
              <Tooltip title="Grafico Nsp" aria-label="Lista en otros casos">
                <img className="imagenBotonAccionMenu" src={grafico_historico_sin_texto} onClick={() => this.abrirNsp()} style={{ width: 55, height: 55, marginTop: 5 }} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} />
              </Tooltip>
            )}
            {showListaNSP ? (
              <Tooltip title="Lista Nsp" aria-label="Lista en otros casos">
                <img className="imagenBotonAccionMenu" src={nsp_por_paciente_con_texto} onClick={() => this.abrirListaNsp()} style={{ width: 80, height: 80 }} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} />
              </Tooltip>
            ) : (
              <Tooltip title="Lista Nsp" aria-label="Lista en otros casos">
                <img className="imagenBotonAccionMenu" src={nsp_por_paciente_sin_texto} onClick={() => this.abrirListaNsp()} style={{ width: 55, height: 55, marginTop: "5px" }} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} />
              </Tooltip>
            )}
          </div>

          {showGraficoNSP ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: "25px" }}>
                  <Grid container spacing={12} style={{ alignItems: "center" }}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Button className="fondoVerde" onClick={this.botonFiltrarFechas}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </GridItem>
              </GridContainer>
              <GraficoNSP startDate={this.state.startDate} endDate={this.state.endDate} />
            </>
          ) : null}
          {showListaNSP ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: "25px" }}>
                  <Grid container spacing={12} style={{ alignItems: "center" }}>
                    <Grid item xs={12} sm={3} md={1} style={{ maxWidth: "30%" }}>
                      <InputLabel>Fecha Inicio</InputLabel>
                      <Input className={"calendarios-nsp"} type="date" name="startDate" inputProps={{ max: fecha_completa }} value={this.state.startDate} onChange={this.filtrarFechas}></Input>
                    </Grid>
                    <Grid item xs={12} sm={3} md={1} style={{ maxWidth: "30%" }}>
                      <InputLabel>Fecha Termino</InputLabel>
                      <Input className={"calendarios-nsp"} type="date" name="endDate" inputProps={{ max: fecha_completa }} value={this.state.endDate} onChange={this.filtrarFechas}></Input>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Button className="fondoVerde" onClick={this.botonFiltrarFechas}>
                        Filtrar
                      </Button>
                    </Grid>
                  </Grid>
                </GridItem>
              </GridContainer>
            </>
          ) : null}

          {showListaNSP && mostrarData ? (
            <>
              <ListaNSP startDate={this.state.startDate} endDate={this.state.endDate} />
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default VistaNSP;
