const initialState = {
  isAuthUser: false,
  cesfam: "",
  usp: "",
  cliente: "",
  nombreusp: "",
  herramientas: [],
  nombre_cesfam: "",
  id_medico: "",
  tipo: 2,
  loading: false,
};

function rootReducer(state = initialState, action) {
  if (action.type === "LOGIN") {
    return Object.assign({}, state, {
      isAuthUser: action.payload.logged,
      cesfam: action.payload.cesfam,
      usp: action.payload.usp,
      nombreusp: action.payload.nombreusp,
      herramientas: action.payload.herramientas,
      cliente: action.payload.cliente,
      id_medico: action.payload.id_medico,
      tipo: action.payload.tipo,
      letraTam: action.payload.letraTam,
    });
  }

  if (action.type === "HORARIO") {
    return Object.assign({}, state, {
      fechaHorario: action.dateString,
    });
  }

  if (action.type === "CAMBIO_CESFAM") {
    return Object.assign({}, state, {
      cesfam: action.payload.cesfam,
      cliente: action.payload.cliente,
    });
  }

  if (action.type === "CAMBIO_NOMBRE_CESFAM") {
    return Object.assign({}, state, {
      nombre_cesfam: action.payload.nombre_cesfam,
    });
  }

  if (action.type === "CAMBIO_NOMBRE_CESFAM") {
    return Object.assign({}, state, {
      nombre_cesfam: action.payload.nombre_cesfam,
    });
  }

  if (action.type === "SHOW_LOADER") {
    return Object.assign({}, state, {
      loading: true,
    });
  }

  if (action.type === "HIDE_LOADER") {
    return Object.assign({}, state, {
      loading: false,
    });
  }

  if (action.type === "TOTEM") {
    return Object.assign({}, state, {
      totem: action.payload.totem,
      nombre_totem: action.payload.nombre_totem,
    });
  }
  return state;
}

export default rootReducer;
