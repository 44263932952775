import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import chileanRut from "chilean-rut";

// core components
import ver_mas from "../../assets/img/iconos correccion/inscribir.svg";
import eliminar from "../../assets/img/iconos correccion/icono_eliminar.svg";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import Paginacion from "./../../components/Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import TableHeader from "../../components/Table/TableHeader";

import AgregarUsuarioPercapitado from "../../components/NoPercapitados/AgregarUsuarioPercapitado";
import EliminarPercapitado from "../../components/NoPercapitados/EliminarPercapitado";

import signo from "./../../assets/img/signo.svg";

class NoPercapitados extends Component {
  constructor() {
    super();

    this.state = {
      noPercapitados: [],
      cesfam: store.getState().cesfam,
      pagina: 0,
      filas: 10,
      total: 0,
      nombreTitulo: [
        { nombre: "Rut", filtro: "unp_rut" },
        { nombre: "Nombre", filtro: "unp_nombre" },
        { nombre: "Apellido Paterno", filtro: "unp_apellido_p" },
        { nombre: "Apellido Materno", filtro: "unp_apellido_m" },
        { nombre: "Edad", filtro: "unp_fecha_nacimiento" },
        { nombre: "Fecha de ingresoo", filtro: "unp_fecha_registro" },
        { nombre: "Teléfono 1", filtro: "unp_telefono1" },
        { nombre: "Telefono 2", filtro: "unp_telefono2" },
        { nombre: "Cesfam o Comuna", filtro: "unp_cesfam" },
        { nombre: "Acciones", filtro: "nulo" },
      ],
      ordenarPrioridad: null,
      texto_filtro: null,
      showModalAgregarUsuarioPercapitado: false,
      id_usuario: null,
      showModalEliminarPercapitado: false,
      comuna: null,
    };
  }

  componentDidMount() {
    this.obtenerNoPercapitados(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Pacientes No Percapitados <span title='Pacientes que actualmente no estan percapitados en HoraSalud.'><img src=" + signo + "></img></span>";
  };

  obtenerNoPercapitados = (pagina, fila, filtrador) => {
    axios
      .get(global.config.modulos.noPercapitados + "ver_no_percapitados", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          pagina: pagina,
          fila: fila,
          ordenarPrioridad: filtrador,
          texto_filtro: this.state.texto_filtro,
          comuna: this.state.comuna,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            noPercapitados: res.data.datos,
            total: res.data.total,
            pagina: res.data.pagina,
            ordenarPrioridad: res.data.prioridad,
          });
        } else {
          this.setState({
            noPercapitados: [],
            total: 0,
            pagina: 0,
            ordenarPrioridad: null,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  mostrarCesfams = async () => {
    await this.setState({
      cesfam: store.getState().cesfam,
      comuna: null,
    });
    this.aplicarFiltros();
  };

  mostrarComuna = () => {
    const apiUrl = global.config.modulos.noPercapitados + "comunas_cliente?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        cesfam: null,
        comuna: res.data["datos"][0]["comuna"],
      });
      this.aplicarFiltros();
    });
  };

  handleFiltradorCampo = (campo) => {
    var campo = campo;
    //var ordens = 'asc'
    if (campo === this.state.ordenarPrioridad) {
      campo = campo + "asc";
      //ordens = 'desc'
    } else if (this.state.ordenarPrioridad === campo + "asc") {
      campo = null;
      //var ordens = null
    }

    this.setState({
      ordenarPrioridad: campo,
    });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.obtenerNoPercapitados(pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  handleFiltradorCampo = (ordenarPrioridad) => {
    this.setState({
      ordenarPrioridad: ordenarPrioridad,
    });
    this.obtenerNoPercapitados(this.state.pagina, this.state.filas, ordenarPrioridad);
  };

  aplicarFiltros = () => {
    this.obtenerNoPercapitados(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.obtenerNoPercapitados(this.state.pagina, filas, this.state.ordenarPrioridad);
  };

  seleccionarSector = (e) => {
    const dato_sec = e.target.value;
    this.setState({ dato_sec: dato_sec });
  };

  filtroTexto = (e) => {
    const texto_filtro = e.target.value;
    this.setState({ texto_filtro: texto_filtro });
  };
  filtroFecha1 = (e) => {
    const fecha1 = e.target.value;
    this.setState({ fecha1: fecha1 });
  };
  filtroFecha2 = (e) => {
    const fecha2 = e.target.value;
    this.setState({ fecha2: fecha2 });
  };

  ModalAgregarUsuarioPercapitado = (unp_id) => {
    this.setState({ showModalAgregarUsuarioPercapitado: true, id_usuario: unp_id });
  };

  hideModalAgregarUsuarioPercapitado = () => {
    this.setState({ showModalAgregarUsuarioPercapitado: false });
    this.obtenerNoPercapitados(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };
  ModalEliminarPercapitado = (unp_id) => {
    this.setState({ showModalEliminarPercapitado: true, id_usuario: unp_id });
  };

  hideModalEliminarPercapitado = () => {
    this.setState({ showModalEliminarPercapitado: false });
    this.obtenerNoPercapitados(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  agregar_usuario = (unp_id, rut, nombre) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" style={{ backgroundColor: "white", width: "100%", padding: "40px", borderRadius: "20px" }}>
            <h1 style={{ fontSize: "30px", fontWeight: "bold", marginBottom: "20px" }}>Ingreso a HoraSalud</h1>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p>
                  ¿Desea ingresar a HoraSalud al paciente {nombre} Rut {chileanRut.format(rut, true)}?
                </p>
                <div style={{ display: "flex", marginBottom: "3%", marginLeft: "70%", marginTop: "2%" }}>
                  <Buttons
                    onClick={() => {
                      this.ModalAgregarUsuarioPercapitado(unp_id);
                      onClose();
                    }}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "1%",
                      fontSize: "20px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                    }}
                  >
                    Aceptar
                  </Buttons>
                  <Buttons
                    onClick={onClose}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "1%",
                      fontSize: "20px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                    }}
                  >
                    Cancelar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        );
      },
    });
  };

  render() {
    return (
      <div className="middle-area">
        <div style={{ display: "flex", marginBottom: "1%", marginTop: "1%" }}>
          <Buttons
            onClick={() => {
              this.mostrarComuna();
            }}
            className={"btnOpcion fondoNaranjo"}
            style={{
              paddingLeft: "2%",
              paddingRight: "2%",
              cursor: "pointer",
              marginLeft: "2%",
              marginTop: "1%",
              fontSize: "20px",
              float: "left",
              border: "0",
              textAlign: "center",
            }}
          >
            Comunal
          </Buttons>
          <Buttons
            onClick={() => {
              this.mostrarCesfams();
            }}
            className={"btnOpcion fondoNaranjo"}
            style={{
              paddingLeft: "2%",
              paddingRight: "2%",
              cursor: "pointer",
              marginLeft: "2%",
              marginTop: "1%",
              fontSize: "20px",
              float: "left",
              border: "0",
              textAlign: "center",
            }}
          >
            Cesfam
          </Buttons>
        </div>
        <div style={{ display: "flex", marginBottom: "1%" }}>
          <Input
            type="text"
            name="texto_filtro"
            value={this.state.texto_filtro}
            onChange={this.filtroTexto}
            placeholder="Nombre o Rut sin puntos ni guión"
            style={{
              padding: 6,
              border: "0px solid black",
              borderRadius: 5,
              marginTop: "1%",
              float: "left",
              marginLeft: "2%",
              height: "35px",
              width: "20%",
            }}
          />

          <Buttons
            onClick={this.aplicarFiltros}
            className={"btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginLeft: "2%",
              marginTop: "1%",
              fontSize: "20px",
              float: "left",
              border: "0",
              height: "35px",
              paddingBottom: "2px",
              textAlign: "center",
            }}
          >
            Filtro
          </Buttons>
        </div>
        <div className="middle-content" style={{ height: "70%", width: "100%" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer>
                <Table className="tablaGeneralGestion bordeTabla" aria-label="simple table">
                  <TableHeader nombreTitulo={this.state.nombreTitulo} datocampo={this.handleFiltradorCampo} />
                  <TableBody>
                    {this.state.noPercapitados.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{chileanRut.format(row.unp_rut, true)}</TableCell>
                        <TableCell align="center">{row.unp_nombre}</TableCell>
                        <TableCell align="center">{row.unp_apellido_p}</TableCell>
                        <TableCell align="center">{row.unp_apellido_m}</TableCell>
                        <TableCell align="center">{row.edad}</TableCell>
                        <TableCell align="center">{moment(row.unp_fecha_registro).format("DD-MM-YYYY HH:MM")}</TableCell>
                        <TableCell align="center">{row.unp_telefono1 ? row.unp_telefono1 : "No encontrado"}</TableCell>
                        <TableCell align="center">{row.unp_telefono2 ? row.unp_telefono2 : "No encontrado"}</TableCell>
                        <TableCell align="center">{row.nombre_cesfam}</TableCell>

                        <TableCell align="center">
                          <React.Fragment>
                            <div style={{ display: "flex" }}>
                              <img style={{ padding: "2px", width: "40px", height: "40px" }} class="btnAccionesAnulacion" src={ver_mas} aria-label="Ver horas pedidas" title="Ver horas pedidas" onClick={() => this.agregar_usuario(row.unp_id, row.unp_rut, row.nombre_completo)}></img>
                              <img style={{ padding: "2px", width: "37px", height: "37px" }} class="btnAccionesAnulacion" src={eliminar} aria-label="Ver horas pedidas" title="Ver horas pedidas" onClick={() => this.ModalEliminarPercapitado(row.unp_id)}></img>
                            </div>
                          </React.Fragment>{" "}
                          {/* <Button onClick= {() =>this.ModalAgregarUsuarioPercapitado(row.unp_id)}>Agregar Usuario</Button><Button>Eliminar Usuario</Button> */}{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
              </TableContainer>
            </GridItem>
          </GridContainer>
        </div>
        {this.state.showModalAgregarUsuarioPercapitado ? <AgregarUsuarioPercapitado ocultar={this.hideModalAgregarUsuarioPercapitado} id_usuario={this.state.id_usuario}></AgregarUsuarioPercapitado> : null}
        {this.state.showModalEliminarPercapitado ? <EliminarPercapitado ocultar={this.hideModalEliminarPercapitado} id_usuario={this.state.id_usuario}></EliminarPercapitado> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(NoPercapitados);
