import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
//import "../../assets/css/agregar.css";

export default class ReservaExternaDescargaExcel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      uspid: store.getState().usp,
      idRegistro: props.idRegistro,
      intentosContacto: [],
      open: true,
      fechaDesde: null,
      fechaHasta: null,
    };
  }

  componentDidMount() {
    this.traerDetalle();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerDetalle = () => {
    axios
      .get(global.config.modulos.reservas_externas + "detalle/", {
        headers: authHeader(),
        params: {
          id_reserva_externa: this.state.idRegistro,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ intentosContacto: res.data.datos });
        }
      });
  };

  descargarExcel = () => {
    if (!this.state.fechaDesde || !this.state.fechaHasta) {
      return NotificationManager.error("Por favor selecciona ambas fechas");
    }
    window.open(global.config.modulos.base + "download/excel_recordatorios_externos/?fechaDesde=" + this.state.fechaDesde + "&fechaHasta=" + this.state.fechaHasta + "", "_blank");
    this.salir();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="recordatoriosExternosDetalle">
          <DialogContent>
            <h2>Seleccione las fechas de descarga</h2>
            <div style={{ marginTop: 30 }}>
              <label style={{ marginRight: 5 }}>Desde</label>
              <input
                type="date"
                name="fechaDesde"
                value={this.state.fechaDesde}
                onChange={this.cambiarValores}
                style={{
                  padding: 5,
                  borderRadius: 5,
                  border: "none",
                  marginRight: 15,
                }}
              />
              <label style={{ marginRight: 5 }}>Hasta</label>
              <input
                type="date"
                name="fechaHasta"
                value={this.state.fechaHasta}
                onChange={this.cambiarValores}
                style={{
                  padding: 5,
                  borderRadius: 5,
                  border: "none",
                  marginRight: 15,
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button className="botonDialog fondoVerde" onClick={this.descargarExcel}>
              Descargar
            </Button>
            <Button className="botonDialog fondoRojo" onClick={this.salir}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
