import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class CasosRegistrados extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      usuario: props.usuario,
      gestion: null,
      demanda: null,
      registros: [],
      registrosDemanda: [],
    };
  }

  componentDidMount() {
    this.casosRegistrados();
    this.casosRegistradosDemanda();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  casosRegistrados = () => {
    axios
      .get(global.config.modulos.gestion + "registros_usuario/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuario,
          gegid: this.state.gestion,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          console.log("gestion", res.data.datos);
          this.setState({ registros: res.data.mensaje.gestion });
        }
      });
  };

  casosRegistradosDemanda = () => {
    axios
      .get(global.config.modulos.demanda + "registros_usuario/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuario,
          idDemanda: this.state.demanda,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          console.log("demanda", res.data.datos);
          this.setState({ registrosDemanda: res.data.datos });
        }
      });
  };

  render() {
    const { registros, tipo, registrosDemanda } = this.state;
    const gestiones = ["", "NO Covid", "Covid", "Plan Contigo", "Apoyo Social"];
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="casosRegistrados">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <h2>Gestiones del paciente</h2>
              <GridContainer className="agendar-container">
                {registros.length > 0 || registrosDemanda.length > 0 ? (
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    {registros.map((reg, index) => (
                      <p
                        key={index}
                        style={{
                          marginTop: 10,
                          borderRadius: 10,
                          border: "2px solid #eaa028",
                          padding: 15,
                        }}
                      >
                        <GridContainer className="agendar-container">
                          {!tipo ? (
                            <GridItem xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Gestión:</label> {gestiones[reg.geg_tipo]}
                              </p>
                            </GridItem>
                          ) : null}

                          <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Fecha de ingreso:</label> {reg.geg_fecha_ingreso ? reg.geg_fecha_ingreso.replace("T", " ") : ""}
                            </p>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Cesfam:</label> {reg.cesfam}
                            </p>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Ubicación:</label> {reg.ubicacion}
                            </p>
                          </GridItem>
                          <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Tomado por:</label> {reg.tomado}
                            </p>
                            {this.state.tipo == 1 ? (
                              <p>
                                <label style={{ fontWeight: "bold" }}>Requerimiento:</label> {reg.geg_requerimiento}
                              </p>
                            ) : null}
                            {reg.geg_ubicacion == 6 ? (
                              <React.Fragment>
                                <p>
                                  <label style={{ fontWeight: "bold" }}>Fecha de finalización:</label> {reg.geg_fecha_finalizacion ? reg.geg_fecha_finalizacion.replace("T", " ") : ""}
                                </p>
                                <p>
                                  <label style={{ fontWeight: "bold" }}>Solución:</label> {reg.geg_solucion}
                                </p>
                              </React.Fragment>
                            ) : null}
                          </GridItem>
                          <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                            <p>
                              <label style={{ fontWeight: "bold" }}>Última observación:</label> {reg.observacion}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </p>
                    ))}
                    {registrosDemanda &&
                      registrosDemanda.map((reg, index) => (
                        <p
                          key={index}
                          style={{
                            marginTop: 10,
                            borderRadius: 10,
                            border: "2px solid #eaa028",
                            padding: 15,
                          }}
                        >
                          <GridContainer className="agendar-container">
                            <GridItem xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Gestión:</label> {"Nivel 3"}
                              </p>
                            </GridItem>
                            <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Fecha de ingreso:</label>
                                {reg.ged_fecha_registro.replace("T", " ")}
                              </p>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Centro:</label>
                                {reg.cesfam}
                              </p>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Ubicación:</label>
                                {reg.ubicacion}
                              </p>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Motivo finalización:</label>
                                {reg.ged_motivo_fin ? reg.ged_motivo_fin : "--"}
                              </p>
                            </GridItem>
                            <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Tomado por:</label>
                                {reg.usuario_panel}
                              </p>
                              <p>
                                <label style={{ fontWeight: "bold" }}>Requerimiento:</label>
                                {reg.ged_requerimiento}
                              </p>

                              <React.Fragment>
                                <p>
                                  <label style={{ fontWeight: "bold" }}>Fecha de finalización:</label>
                                  {reg.ged_fecha_finalizacion ? reg.ged_fecha_finalizacion.replace("T", " ") : "--"}
                                </p>
                              </React.Fragment>
                            </GridItem>
                          </GridContainer>
                        </p>
                      ))}
                  </GridItem>
                ) : (
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    Rut sin gestiones activas
                  </GridItem>
                )}
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <a onClick={this.salir} style={{ marginLeft: 20 }} className="btnAgregarCaso fondoNaranjo">
                    Aceptar
                  </a>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
