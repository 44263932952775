import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "../../../assets/css/graficosD.css";
import Cargando from "components/Helpers/Cargando";

export default class GraficoHbSector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      dates: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      fecha_semana: props.fecha_semana,
      fecha_mes: props.fecha_mes,
      fecha_año: props.fecha_año,
      cesfam: props.cesfam,
      root: "",
      idCliente: props.idCliente,
      color: props.color,
      total: "",
      cesNombre: props.cesNombre,
      today: new Date(),
      year: "",
      month: "",
      day: "",
      dateStr: "",
      titulo_archivo: "",
      cargando: true,
    };
  }

  GraficoHbSector() {
    this.setState({ datas: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "bloqueadaXsectorCli/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_semana: this.state.fecha_semana,
          fecha_mes: this.state.fecha_mes,
          fecha_año: this.state.fecha_año,
          cliente: this.state.idCliente,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos en el grafico de horarios bloqueados por sector");
          this.setState({ cargando: false });
          return;
        }
        this.setState(
          {
            datas: res.data.datos[0],
            total: res.data.datos[1]["total_general"],
          },
          () => {
            try {
              this.state.root.dispose();
            } catch (e) {}

            let root = am5.Root.new("chartdiv22");
            root._logo.dispose();

            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
              am5percent.PieChart.new(root, {
                endAngle: 270,
              })
            );

            let series = chart.series.push(
              am5percent.PieSeries.new(root, {
                valueField: "total",
                categoryField: "sector",
                endAngle: 270,
                tooltip: am5.Tooltip.new(root, {
                  autoTextColor: false,
                  labelText: "{category}: [bold]{value} Horas[/]",
                  fill: am5.color(0xff5566),
                }),
              })
            );

            series.get("colors").set("colors", [am5.color("#47BAA4"), am5.color("#EAA028"), am5.color("#EF4597"), am5.color("#6E45AF"), am5.color("#8CBCF4"), am5.color("#D82323"), am5.color("#F7D856"), am5.color("#CEACE5"), am5.color("#9BCFB1"), am5.color("#2466A8")]);

            series.slices.template.states.create("active", {
              shiftRadius: 1,
              strokeWidth: 2,
            });

            series.labels.template.setAll({
              fontSize: "0.9rem",
              fill: am5.color("#808080"),
              text: `[bold]{category}[/]\n {valuePercentTotal.formatNumber('0.00')}% de ${this.state.total}\n {value}`,
              textAlign: "left",
            });

            series.slices.template.setAll({
              stroke: am5.color(0xffffff),
              strokeWidth: 4,
            });

            series.slices.template.events.on("click", function (ev) {
              series.slices.each(function (slice) {
                if (slice != ev.target && slice.get("active")) {
                  slice.set("active", false);
                }
              });
            });

            let exporting = am5plugins_exporting.Exporting.new(root, {
              menu: am5plugins_exporting.ExportingMenu.new(root, {}),
              dataSource: this.state.datas,
              title: `HORAS BLOQUEADAS POR SECTOR`,
              pdfOptions: {
                imageFormat: "jpg",

                addColumnNames: true,
              },
              htmlOptions: {
                disabled: true,
              },
              jpgOptions: {
                disabled: true,
              },
              csvOptions: {
                disabled: true,
              },
              jsonOptions: {
                disabled: true,
              },
              printOptions: {
                disabled: true,
              },
              xlsxOptions: {
                imageFormat: "jpg",

                addColumnNames: true,
              },
              pngOptions: {
                disabled: true,
              },
              pdfdataOptions: {
                disabled: true,
              },
              filePrefix: this.state.titulo_archivo,
              dataFields: {
                sector: "Sector",
                total: "Total",
                aa: ``,
                bb: `Total ${this.state.total}`,
              },
            });

            series.data.setAll(this.state.datas);
            this.setState({ cargando: false });
            series.appear(1000, 100);
          }
        );
      });
  }
  componentDidMount() {
    this.setState(
      {
        year: this.state.today.getFullYear().toString().slice(),
        month: (this.state.today.getMonth() + 1).toString().padStart(2, "0"),
        day: this.state.today.getDate().toString().padStart(2, "0"),
        act_date: new Date(this.state.fecha_actual),
        ini_date: new Date(this.state.fecha_inicio),
        ter_date: new Date(this.state.fecha_termino),
      },
      () => {
        if (!this.state.fecha_actual && !this.state.fecha_termino && !this.state.fecha_inicio) {
          this.setState({
            titulo_archivo: ` ${this.state.day}-${this.state.month}-${this.state.year}`,
          });
        } else if ((this.state.fecha_actual !== "") & !this.state.fecha_termino && !this.fecha_inicio) {
          this.setState({
            titulo_archivo: `  ${this.state.act_date.getDate().toString().padStart(2, "0")}-${(this.state.act_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.act_date.getFullYear().toString().slice()}`,
          });
        } else {
          this.setState({
            act_date: "",
            titulo_archivo: `${this.state.ini_date.getDate().toString().padStart(2, "0")}-${(this.state.ini_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ini_date.getFullYear().toString().slice()} ~ ${this.state.ter_date.getDate().toString().padStart(2, "0")}-${(this.state.ter_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ter_date.getFullYear().toString().slice()}`,
          });
        }
      }
    );
    this.GraficoHbSector();
  }

  render() {
    return (
      <div style={{ margin: "30px 13%" }}>
        {this.state.cargando ? <Cargando /> : null}
        <div
          id="chartdiv22"
          style={{
            width: "60vw",
            height: "55vh",
            zIndex: -1,
            position: "absolute",
          }}
        >
          <div>
            <h1
              style={{
                color: "#808080",
                fontSize: "1.6vw",
              }}
            >
              Horas bloqueadas por sector{" "}
            </h1>
            <h3
              style={{
                color: "#808080",
                fontSize: "0.9vw",
              }}
            >
              Horas bloqueadas {">"} Sector{" "}
            </h3>
            <h3
              style={{
                color: "#808080",
                fontSize: "1vw",
              }}
            >
              Total:{this.state.total}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
