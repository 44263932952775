import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class AgregarObservacionConHistorial extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      observaciones: props.observaciones,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  render() {
    const { observaciones } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agregarObservacion">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12}>
              <h2>Observaciones</h2>
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 30 }}>
              <TableContainer style={{ maxHeight: 300 }}>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTablaCovid">
                  <TableHead stickyHeader>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha
                      </TableCell>
                      <TableCell align="center">Funcionario</TableCell>
                      <TableCell align="center">Observación</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {observaciones &&
                      observaciones.map((res, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{res.ggo_fecha_registro.replace("T", " ")}</TableCell>
                          <TableCell align="center">{res.ggo_usuario_panel}</TableCell>
                          <TableCell align="center">{res.ggo_observacion}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
