import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { store } from "./../../store/index";

import moment from "moment";
import "moment/locale/es";

import Tooltip from "@material-ui/core/Tooltip";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import AgendarHora from "./../Agendar/ModalAgendarHora.js";
import CambiarRut from "./ModalCambiarRut.js";
import AnularHora from "../Usuarios/AnularHora";
import CompletarFolio from "../Usuarios/CompletarFolio";

// estilos
import Button from "@material-ui/core/Button";
import iconoHorario from "assets/img/profesionales/agenda_dia_icon.svg";
import icono_reservar from "assets/img/usuarios/icono_reservar.svg";
import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import iconoEliminar from "assets/img/usuarios/icono_eliminar.svg";
import iconoAsistencia from "assets/img/asistencia.svg";
import anunciarLlegada from "assets/img/usuarios/icono_anunciar_llegada.svg";

import { confirmAlert } from "react-confirm-alert";

import NotificationManager from "react-notifications/lib/NotificationManager";

import InformacionUsuarioCSS from "assets/css/usuarios.module.css";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class VerHorario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      proId: this.props.profesional,

      tabla: "",
      nombreProfesional: "",
      fechaActual: "",
      fechaCalendario: moment().format("YYYY-MM-DD"),

      cambiarRut: false,
      agendarHora: false,
      horarioAgendar: "",
      agendaReservada: "",
      usuarioReserva: "",

      herramientas: store.getState().herramientas,
    };
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  componentDidMount() {
    this.horariosProfesional(this.props.profesional, this.state.fechaCalendario);
  }

  horariosProfesional = (proId, fechaCalendario) => {
    var fecha = moment(fechaCalendario, "YYYY-MM-DD");
    var dia = fecha.format("dddd	DD");
    var mes = fecha.format("MMMM YYYY");
    var fechaTexto = "Agenda del día " + dia + " de " + mes;

    this.setState({
      fechaActual: fechaTexto,
    });

    axios
      .get(global.config.modulos.agendamientos + "horarios_profesional_por_fecha/", {
        headers: authHeader(),
        params: {
          profesional: proId,
          fecha: fechaCalendario,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        var data = res.data;

        this.setState({
          nombreProfesional: data.nombreProfesional,
        });

        var tabla = [];
        tabla.push(
          <TableHead>
            <TableRow className={"tablaRow "}>
              <TableCell className={"tablaRow cellInicio"} key={0}>
                Rut
              </TableCell>
              <TableCell className={"tablaRow"} key={0}>
                Nombre
              </TableCell>
              <TableCell className={"tablaRow"} key={0}>
                Tipo de atención
              </TableCell>
              <TableCell className={"tablaRow"} key={0}>
                Horario
              </TableCell>
              <TableCell className={"tablaRow"} key={0}>
                Estado
              </TableCell>
              <TableCell className={"tablaRow"} key={0}>
                Canal
              </TableCell>
              <TableCell className={"tablaRow cellTermino"} key={0}>
                Acción
              </TableCell>
            </TableRow>
          </TableHead>
        );

        var rows = [];

        var horario = data.datos;
        var conteoHorario = horario.length;
        if (conteoHorario > 0) {
          for (var j = 0; j < conteoHorario; j++) {
            var horId = horario[j].hor_id;
            var horAtencion = horario[j].hora;
            var hora = moment(horAtencion).format("HH:mm");

            var rutUsuario = horario[j].usu_rut;
            var nombreUsuario = horario[j].usu_nombre;
            var especialidad = horario[j].especialidad;
            var espId = horario[j].esp_id;
            var horEstado = horario[j].estado_hora;
            var ageId = horario[j].age_id;
            var usuId = horario[j].usu_id;
            var modalidad = horario[j].modalidad;
            var canal = horario[j].canal;
            var funcionario = horario[j].funcionario;
            var fechaRegistro = horario[j].fecha_bloqueo;

            var cell = [];
            cell.push(<TableCell className={"tablaRow"}>{rutUsuario}</TableCell>);
            cell.push(<TableCell className={"tablaRow"}>{nombreUsuario}</TableCell>);
            cell.push(<TableCell className={"tablaRow"}>{especialidad}</TableCell>);
            cell.push(<TableCell className={"tablaRow"}>{hora}</TableCell>);

            if (modalidad != "--") {
              cell.push(
                <TableCell
                  className={"tablaRow"}
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  <Tooltip
                    title={
                      <React.Fragment>
                        <div>
                          <label
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {modalidad}
                          </label>
                        </div>
                      </React.Fragment>
                    }
                    placement="right"
                    interactive
                    arrow
                    className="tooltipHorario"
                  >
                    <a title="Paciente" className="mr-1" id={"Popover-" + horId}>
                      {horEstado}
                    </a>
                  </Tooltip>
                </TableCell>
              );
            } else {
              if (horEstado == "Bloqueada") {
                cell.push(
                  <TableCell
                    className={"tablaRow"}
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    <Tooltip
                      title={
                        <React.Fragment>
                          <div>
                            <label>
                              {"Funcionario:"} {funcionario}
                            </label>
                            <br></br>
                            <label>
                              {"Fecha Bloqueo:"} {fechaRegistro}
                            </label>
                          </div>
                        </React.Fragment>
                      }
                      placement="right"
                      interactive
                      arrow
                      className="tooltipHorario"
                    >
                      <a title="Paciente" className="mr-1" id={"Popover-" + horId}>
                        {horEstado}
                      </a>
                    </Tooltip>
                  </TableCell>
                );
              } else {
                cell.push(
                  <TableCell
                    className={"tablaRow"}
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {horEstado}
                  </TableCell>
                );
              }
            }

            if (canal != "--") {
              cell.push(
                <TableCell className={"tablaRow"}>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <div>
                          {funcionario != "--" && (
                            <label>
                              {"Funcionario: "}
                              {funcionario}
                            </label>
                          )}
                          <br></br>
                          <label>
                            {"Fecha de reserva: "} {fechaRegistro}
                          </label>
                        </div>
                      </React.Fragment>
                    }
                    placement="right"
                    interactive
                    arrow
                    className="tooltipHorario"
                  >
                    <a title="Paciente" className="mr-1" id={"Popover-" + horId}>
                      {canal}
                    </a>
                  </Tooltip>
                </TableCell>
              );
            } else {
              cell.push(<TableCell className={"tablaRow"}>{canal}</TableCell>);
            }

            var diaHoy = moment().format("YYYY-MM-DD");
            var diaAtencion = moment(horAtencion).format("YYYY-MM-DD");
            var fechaHoraAhora = moment().format("YYYY-MM-DD HH:mm:ss");

            var reservar = 0;
            if (this.state.herramientas.includes("H17")) {
              if (horEstado.includes("Disponible") || horEstado.includes("En pausa")) {
                reservar = 1;
              }
            } else if (this.state.herramientas.includes("H18")) {
              if (horEstado.includes("Disponible")) {
                reservar = 1;
              }
            } else {
              reservar = 0;
            }

            if (reservar == 1) {
              if (diaHoy == diaAtencion && horAtencion > fechaHoraAhora) {
                cell.push(
                  <TableCell className={"tablaRow celdaConImg"}>
                    <img class="imagenBotonReservarHorario" src={icono_reservar} aria-label="Reservar una hora" title="Reservar una hora" onClick={this.agendarHora.bind(this, horId, especialidad)}></img>
                  </TableCell>
                );
              } else {
                cell.push(<TableCell className={"tablaRow"}>{"--"}</TableCell>);
              }
            } else {
              if (diaHoy == diaAtencion && horAtencion > fechaHoraAhora && (horEstado == "Reservada" || horEstado == "Confirmada")) {
                cell.push(
                  <TableCell className={"tablaRow celdaConImg"}>
                    <img class="imagenBotonReservarHorario" src={icono_modificar} aria-label="Cambiar paciente" title="Cambiar paciente" onClick={this.cambiarRut.bind(this, horId, especialidad, ageId)}></img>

                    <img class="imagenBotonReservarHorario" src={iconoEliminar} aria-label="Anular la hora del paciente" title="Anular la hora del paciente" onClick={this.anularHora.bind(this, ageId, usuId)}></img>

                    {this.state.herramientas.includes("H59") && ageId != null && <img class="imagenBotonReservarHorario" src={iconoAsistencia} aria-label="Asistencia" title="Asistencia" onClick={this.asistencia.bind(this, ageId)}></img>}

                    {this.state.herramientas.includes("H73") && horAtencion <= moment().add(6, "hours").format("YYYY-MM-DD HH:mm:ss").toString() ? (
                      <Tooltip title={"Anunciar llegada del paciente"} aria-label={"Anunciar llegada del paciente"}>
                        <img className="imagenBotonReservarHorario" src={anunciarLlegada} onClick={this.mostrarModalAnunciarLlegada.bind(this, ageId, espId, usuId)} />
                      </Tooltip>
                    ) : null}
                  </TableCell>
                );
              } else {
                cell.push(
                  <TableCell className={"tablaRow celdaConImg"}>
                    {this.state.herramientas.includes("H73") && horEstado != "Bloqueada" && ageId != null && horAtencion >= moment().subtract(6, "minutes").format("YYYY-MM-DD HH:mm:ss").toString() ? (
                      <Tooltip title={"Anunciar llegada del paciente"} aria-label={"Anunciar llegada del paciente"}>
                        <img className="imagenBotonReservarHorario" src={anunciarLlegada} onClick={this.mostrarModalAnunciarLlegada.bind(this, ageId, espId, usuId)} />
                      </Tooltip>
                    ) : null}

                    {this.state.herramientas.includes("H59") && horEstado != "Bloqueada" && ageId != null ? <img class="imagenBotonReservarHorario" src={iconoAsistencia} aria-label="Asistencia" title="Asistencia" onClick={this.asistencia.bind(this, ageId)}></img> : "--"}
                  </TableCell>
                );
              }
            }

            rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
          }
        } else {
          rows.push(
            <TableRow>
              <TableCell align="center" colSpan={5}>
                {"Sin agenda disponible"}
              </TableCell>
            </TableRow>
          );
        }
        tabla.push(<TableBody>{rows}</TableBody>);

        this.setState({
          tabla: (
            <Table stickyHeader size="small" aria-label="a dense table">
              {tabla}
            </Table>
          ),
        });
      });
  };

  descargarHorario = (tipo) => {
    axios
      .get(global.config.modulos.profesionales + "horario_por_fecha_tipo/", {
        headers: authHeader(),
        params: {
          proid: this.state.proId,
          fecha: this.state.fechaCalendario,
          tipo: tipo,
        },
      })
      .then((res) => {
        var data = res.data;

        var estado = data.estado;
        if (estado) {
          window.open(res.data.rutaPdf, "_blank").focus();
        } else {
          this.mostrarAlerta(data.mensaje);
        }
      });
  };

  agendarHora = (horId, especialidad) => {
    this.setState({
      agendarHora: true,
      horarioAgendar: horId,
      nombreEspecialidad: especialidad,
    });
  };

  ocultar_agendar = () => {
    this.setState({ agendarHora: false });
    this.horariosProfesional(this.props.profesional, this.state.fechaCalendario);
  };

  cambiarRut = (horId, especialidad, agenda) => {
    this.setState({
      cambiarRut: true,
      horarioAgendar: horId,
      nombreEspecialidad: especialidad,
      agendaReservada: agenda,
    });
  };

  ocultar_cambiar_rut = () => {
    this.setState({ cambiarRut: false });
    this.horariosProfesional(this.props.profesional, this.state.fechaCalendario);
  };

  anularHora = (agenda, usuId) => {
    this.setState({
      anularHora: true,
      agendaReservada: agenda,
      usuarioReserva: usuId,
    });
  };

  ocultar_anular_hora = () => {
    this.setState({ anularHora: false });
    this.horariosProfesional(this.props.profesional, this.state.fechaCalendario);
  };

  mostrarModalAnunciarLlegada = (ageId, especialidad, usuId) => {
    confirmAlert({
      title: "",
      message: "Desea confirmar la llegada de este paciente?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.traerExamenes(ageId, especialidad, usuId),
          className: "btnAceptar",
        },
      ],
    });
  };

  traerExamenes = async (agenda, especialidad, usuId) => {
    axios
      .get(global.config.modulos.especialidades + "examenes/", {
        headers: authHeader(),
        params: {
          especialidad: especialidad,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        if (data.length > 0) {
          this.confirmarExamen(agenda, usuId);
        } else {
          this.anunciarLlegada(agenda);
        }
      })
      .catch((err) => {});
  };

  confirmarExamen = (agenda, usuId) => {
    confirmAlert({
      title: "",
      message: "¿El paciente tiene la orden para el examen?",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
          onClick: () => {
            NotificationManager.error("La orden de examen es obligatoria para realizar la reserva y posteriormente el examen, una vez que el paciente presente la orden del examen podrá reservar la hora de atención.");
          },
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () => {
            this.mostrarCompletarFolio(agenda, usuId);
          },
        },
      ],
    });
  };

  mostrarCompletarFolio = (agenda, usuId) => {
    this.setState({
      completarFolio: true,
      agendaReservada: agenda,
      usuarioReserva: usuId,
    });
  };

  ocultarCompletarFolio = () => {
    this.setState({
      completarFolio: false,
    });
    this.horariosProfesional(this.props.profesional, this.state.fechaCalendario);
  };

  anunciarLlegada = (ageId) => {
    var data = {
      agenda_id: ageId,
      usuario_panel: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "confirmar_llegada/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Llegada confirmada con éxito");
        this.horariosProfesional(this.props.profesional, this.state.fechaCalendario);
      }
    });
  };

  asistencia = (ageId) => {
    confirmAlert({
      title: "",
      message: "¿El paciente ha asistido a su hora médica?",
      buttons: [
        {
          label: "No se presentó",
          className: "btnCancelar",
          onClick: () => this.cambiarEstadoAgenda(ageId, 9),
        },
        {
          label: "Sí, se presentó",
          className: "btnAceptar",
          onClick: () => this.cambiarEstadoAgenda(ageId, 7),
        },
      ],
    });
  };

  cambiarEstadoAgenda = async (ageId, estadoAgenda) => {
    const datos = { agenda: ageId, estadoHora: estadoAgenda, uspId: this.state.uspId };
    await axios.post(global.config.modulos.agendamientos + "cambio_estado_agenda/", datos, { headers: authHeader() });
    this.horariosProfesional(this.props.profesional, this.state.fechaCalendario);
  };

  cambiarValoresFecha = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.horariosProfesional(this.props.profesional, value);
  };

  render() {
    return (
      <div>
        {this.state.agendarHora ? <AgendarHora ocultar={this.ocultar_agendar} horario={this.state.horarioAgendar} nombreEspecialidad={this.state.nombreEspecialidad} nombreProfesional={this.state.nombreProfesional}></AgendarHora> : null}

        {this.state.anularHora ? <AnularHora ocultar={this.ocultar_anular_hora} ageid={this.state.agendaReservada} usuId={this.state.usuarioReserva}></AnularHora> : null}

        {this.state.cambiarRut ? <CambiarRut ocultar={this.ocultar_cambiar_rut} horario={this.state.horarioAgendar} nombreEspecialidad={this.state.nombreEspecialidad} nombreProfesional={this.state.nombreProfesional} ageId={this.state.agendaReservada}></CambiarRut> : null}

        {this.state.completarFolio ? <CompletarFolio ocultar={this.ocultarCompletarFolio} ageId={this.state.agendaReservada} usuId={this.state.usuarioReserva}></CompletarFolio> : null}

        <Grid container spacing={3} style={{ marginLeft: 0 }}>
          <Grid id={"vistaHorariosProfesional"} item xs={12} style={{ maxWidth: "98%" }}>
            <h2 style={{ textAlign: "center", marginTop: 15, marginBottom: -38 }}>{this.state.fechaActual}</h2>

            <input type="date" id="fechaCalendario" name="fechaCalendario" value={this.state.fechaCalendario} className={InformacionUsuarioCSS.SelectFecha} style={{ height: "initial", float: "right", marginTop: 32, width: "auto" }} onChange={this.cambiarValoresFecha}></input>

            <div style={{ marginTop: 50 }}>
              <img className="imagenDatoSeleccionadoProfesional" src={iconoHorario} onClick={this.vista1Ficha} />

              <div className="datosProfesional " style={{ border: "rgb(234 160 40) 2px solid" }}>
                <Grid container spacing={2} className="contenidoDatosUsuario" style={{ padding: "3px 3px 0 3px" }}>
                  <Grid className="datosIndividuales" item xs={12}>
                    <TableContainer style={{ height: 200 }}>{this.state.tabla}</TableContainer>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 150 }}>
            <Button variant="contained" className={"fondoNaranjo botonDescargarHorario"} onClick={() => this.descargarHorario(2)}>
              Descargar agenda PM del día
            </Button>
            <Button variant="contained" className={"fondoNaranjo botonDescargarHorario"} onClick={() => this.descargarHorario(1)}>
              Descargar agenda AM del día
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
