import React, { useEffect, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { NotificationManager } from "react-notifications";
import AgregarSuspension from "./AgregarSuspension.js";
import { store } from "./../../store/index";
import MaterialTable from "material-table";
import { confirmAlert } from "react-confirm-alert";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

const SuspenderRecordatorios = (props) => {
  const [open, setOpen] = useState(true);
  const [suspensiones, setSuspensiones] = useState([]);
  const [cesfam, setCesfam] = useState(store.getState().cesfam);
  const [uspId, setUspId] = useState(store.getState().usp);
  const [modalSuspension, setModalSuspension] = useState(false);

  useEffect(() => {
    traerSuspensiones();
  }, []);

  const traerSuspensiones = (e) => {
    axios
      .get(global.config.modulos.reservas_externas + "obtener_suspensiones/", {
        headers: authHeader(),
        params: { cesfam: cesfam },
      })
      .then((res) => {
        setSuspensiones(res.data.datos);
      });
  };

  const eliminarSuspension = (id) => {
    confirmAlert({
      title: "",
      message: "¿Está seguro que desea eliminar la suspensión?",
      buttons: [
        {
          className: "btnCancelar",
          label: "No",
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.reservas_externas + "desuspender_recordatorios/",
                {
                  id_bloqueo: id,
                  usuario_panel: uspId,
                },
                {
                  headers: authHeader(),
                }
              )

              .then((res) => {
                traerSuspensiones();
                if (res.data.estado) {
                  NotificationManager.success(res.data.mensaje);
                } else {
                  NotificationManager.warning(res.data.mensaje, null, 20000);
                }
              });
          },
        },
      ],
    });
  };

  const mostrarAgregarSuspension = () => {
    setModalSuspension(true);
  };

  const ocultarAgregarSuspension = () => {
    setModalSuspension(false);
    traerSuspensiones();
  };

  return (
    <Dialog open={open} onClose={props.ocultar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="buscarUsuarioGestion">
      <DialogContent>
        <GridContainer className="agendar-container">
          <GridItem xs={12} sm={12} md={12} className="">
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2>Suspenciones registradas</h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ marginTop: "0px" }}>
                <MaterialTable
                  style={{ width: "100%", height: "25%", marginBottom: "5px", fontSize: "14px" }}
                  title=""
                  columns={[
                    { title: "Rut profesional", field: "ges_rut_profesional" },
                    {
                      title: "Fecha inicio",
                      field: "ges_fecha_inicio",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>{rowData.ges_fecha_inicio.replace("T", " ")}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: "Fecha término",
                      field: "ges_fecha_termino",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>{rowData.ges_fecha_termino.replace("T", " ")}</p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                  data={suspensiones}
                  actions={[
                    {
                      icon: "add",
                      tooltip: "Agregar suspensión",
                      isFreeAction: true,
                      onClick: (event, rowData) => mostrarAgregarSuspension(),
                    },
                    {
                      icon: "delete",
                      tooltip: "Eliminar suspensión",
                      onClick: (event, rowData) => eliminarSuspension(rowData.ges_id),
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    search: true,
                  }}
                  localization={{
                    header: {
                      actions: "Acciones",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay suspensiones para mostrar",
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Button id={"modalModificarUsuarioBtnCancelar"} onClick={props.ocultar} style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" className="fondoRojo">
                  Cerrar
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
      {modalSuspension && <AgregarSuspension ocultar={() => ocultarAgregarSuspension} />}
    </Dialog>
  );
};

export default SuspenderRecordatorios;
