import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NotificationManager } from "react-notifications";
import csvFallecidos from "./../../assets/img/ejemplo_csv_fallecidos.png";
import { store } from "./../../store/index";
import carga_ciclos from "../../assets/img/gestion/carga_masiva_ciclos.svg";
import carga_beneficios from "../../assets/img/gestion/carga_masiva_beneficios.svg";
import carga_fallecidos from "../../assets/img/gestion/carga_masiva_fallecidos.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import signo from "./../../assets/img/signo.svg";
import moduleCM from "../../assets/css/cargamasiva.module.css";

// CSS
import "./../../assets/css/usuarios.css";

export default class CargarUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      datos: [],
      pacientes: 1,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva <label style='font-size: 1vw'>Cargar fallecidos</label> <span title='Permite ingresar o actualizar a través de de un archivo .csv información sobre los ciclos de los pacientes'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleOnDrop = (data, file) => {
    console.log(data);
    console.log(file);

    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    if (registros.length == 0) {
      NotificationManager.error("Revise el formato del csv o si contiene datos");
      return false;
    }
    this.setState({ datos: registros, archivo: file });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    this.cargarArchivo();
    const { archivo } = this.state;

    document.getElementById("cargando").style.display = "block";

    var pacientes = this.state.pacientes;
    if (this.state.pacientes == "null") {
      pacientes = null;
    }

    axios
      .post(
        global.config.modulos.carga_masiva_usuario + "cargar_usuarios/",
        {
          datos: this.state.datos,
          uspId: this.state.uspId,
          cesfam: this.state.cesfam,
          nombreArchivo: archivo.name,
          actualizacion: pacientes,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success("Registros ingresados con éxito");
          if (res.data.errores.length > 0) {
            let mensaje = "";

            res.data.errores.forEach((reg) => {
              mensaje += reg + "\n";
            });

            const element = document.createElement("a");
            const file = new Blob([mensaje], {
              type: "text/plain;charset=utf-8",
            });
            element.href = URL.createObjectURL(file);
            element.download = "excepciones.txt";
            document.body.appendChild(element);
            element.click();
          }

          if (res.data.ingresados.length > 0) {
            let mensaje = "";

            res.data.ingresados.forEach((reg) => {
              mensaje += reg + "\n";
            });

            const element = document.createElement("a");
            const file = new Blob([mensaje], {
              type: "text/plain;charset=utf-8",
            });
            element.href = URL.createObjectURL(file);
            element.download = "ingresados.txt";
            document.body.appendChild(element);
            element.click();
          }
        }
      })
      .catch((error) => {
        document.getElementById("cargando").style.display = "none";
        NotificationManager.error("Error al ingresar los registros");
      });
  }

  cargarArchivo = async () => {
    const { archivo } = this.state;
    const data = new FormData();
    data.append("file", archivo);
    data.append("filename", archivo.name);
    await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_masiva", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return archivo.name;
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { ciclo, datos } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="cargarUsuarios">
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <label>
                        Ejemplo de llenado de archivo .CSV <br />
                        <br /> [ run, dv ]
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <img style={{ width: 300 }} src={csvFallecidos} alt="csv" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <div style={{ float: "left", marginLeft: "19px" }}>
                        <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                          Pacientes
                        </InputLabel>
                        <NativeSelect name="pacientes" className={moduleCM.selectCiclos} value={this.state.pacientes} onChange={this.cambiarValores}>
                          <option value="1">Actualizar información</option>
                          <option value="null">Agregar y Actualizar información</option>
                        </NativeSelect>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                        config={{
                          header: true,
                          dynamicTyping: true,
                          skipEmptyLines: true,
                          transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                        }}
                      >
                        <span>Arrastra el archivo CSV o presiona para cargar.</span>
                      </CSVReader>
                      <LinearProgress id="cargando" style={{ display: "none" }} />
                    </Grid>
                  </Grid>
                  {datos.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={this.cargaMasiva}
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Cargar registros
                      </Button>
                    </Grid>
                  )}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
