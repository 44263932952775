import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";
import axios from "axios";
// core components

import GridItem from "components/grid/GridItem";
import TextField from "@material-ui/core/TextField";

import { store } from "../../../store/index";
import { Grid, Button, emphasize } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";

import Autocomplete from "@material-ui/lab/Autocomplete";
import GraficoLinea from "./GraficoLinea";
import GraficoLineaSem from "./GraficoLineaSem";
import GraficoLineaDia from "./GraficoLineaDia";
import GraficoLineaHora from "./GraficoLineaHora";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

//CSS
import crearTotem from "assets/css/crearTotem.module.css";
import "moment/locale/es";

export default class GraficoEspera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfams: [],
      cesfamNom: "",
      cesOpcion: null,
      comunaNom: "",
      comOpcion: null,
      data: [],
      uspId: store.getState().usp,
      timeSelected: null,
      startDate: null,
      endDate: null,
      showDownload: false,
      areaSelected: null,
      areaName: "",
      subSelected: null,
      subName: "",
      modulSelected: null,
      modulName: "",
      displayText: "",
      year: false,
      mes: false,
      sem: false,
      dia: false,
      dateSelect: null,
      prefSel: { label: "Normal", val: 0 },
    };
  }

  unsetGrafs() {
    this.setState({
      year: false,
      mes: false,
      sem: false,
      dia: false,
      timeSelected: null,
      dateSelect: null,
    });
  }

  setComuna = (event, value) => {
    this.setState(
      {
        data: [],
        comunaNom: value.label,
        comunaSelected: value.id,
        comOpcion: value,
        cesOpcion: null,
        cesfamNom: "",
        cesfamSelected: 0,
        showDownload: false,
      },
      () => {
        this.cargarCesfams(value.id);
      },
      () => {
        this.unsetGrafs();
      }
    );
  };

  setCesfam = (event, value) => {
    this.setState({
      cesfamNom: value.label,
      cesfamSelected: value.id,
      cesOpcion: value,
      areaSelected: null,
    });
  };

  setTime = (event, value) => {
    if (value !== null) {
      switch (value.val) {
        case 1:
          this.setState({
            year: false,
            mes: false,
            sem: true,
            dia: false,
            timeSelected: value,
            dateSelect: null,
            lineaData: false,
            lineaDataSem: false,
            lineaDataDia: false,
            lineaDataHora: false,
          });
          break;
        case 2:
          this.setState({
            year: false,
            mes: true,
            sem: false,
            dia: false,
            timeSelected: value,
            dateSelect: null,
            lineaData: false,
            lineaDataSem: false,
            lineaDataDia: false,
            lineaDataHora: false,
          });
          break;
        case 3:
          this.setState({
            year: true,
            mes: false,
            sem: false,
            dia: false,
            timeSelected: value,
            dateSelect: null,
            lineaData: false,
            lineaDataSem: false,
            lineaDataDia: false,
            lineaDataHora: false,
          });
          break;
        case 0:
          this.setState({
            year: false,
            mes: false,
            sem: false,
            dia: true,
            timeSelected: value,
            dateSelect: null,
            lineaData: false,
            lineaDataSem: false,
            lineaDataDia: false,
            lineaDataHora: false,
          });
          break;
        default:
          this.setState({
            year: false,
            mes: false,
            sem: false,
            dia: false,
            timeSelected: value,
            dateSelect: null,
            lineaData: false,
            lineaDataSem: false,
            lineaDataDia: false,
            lineaDataHora: false,
          });
          break;
      }
    } else {
      this.setState({
        year: false,
        mes: false,
        sem: false,
        dia: false,
        timeSelected: value,
        dateSelect: null,
        lineaData: false,
        lineaDataSem: false,
        lineaDataDia: false,
        lineaDataHora: false,
      });
    }
  };

  setArea = (event, value) => {
    this.unsetGrafs();
    if (value === null) {
      this.setState({
        areaSelected: null,
        subSelected: null,
        modulSelected: null,
        lineaData: false,
        lineaDataSem: false,
        lineaDataDia: false,
        lineaDataHora: false,
      });
    } else {
      this.setState(
        {
          areaSelected: value,
          subSelected: null,
          areaName: value.label,
          modulSelected: null,
          lineaData: false,
          lineaDataSem: false,
          lineaDataDia: false,
          lineaDataHora: false,
        },
        () => {
          this.props.cargarSubOpciones(value.id);
          this.props.cargarModulos(value.id, null);
        }
      );
    }
  };

  setArOp = (event, value) => {
    this.unsetGrafs();
    this.setState(
      {
        subSelected: value,
        subName: value.label,
        lineaData: false,
        lineaDataSem: false,
        lineaDataDia: false,
        lineaDataHora: false,
      },
      () => {
        this.props.cargarModulos(this.state.areaSelected.id, value.id);
      }
    );
  };

  setModule = (event, value) => {
    this.setState(
      {
        modulSelected: value,
        modulName: value.label,
        lineaData: false,
        lineaDataSem: false,
        lineaDataDia: false,
        lineaDataHora: false,
      },
      () => {
        this.fillData();
      }
    );
  };

  cargarCesfams = async (comuna) => {
    const apiUrl = global.config.modulos.admin_totem + "lista_cesfams/?comuna=" + comuna;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.cesfams.length > 0) {
        this.setState({ cesfams: [] });
        res.data.cesfams.map((res) => {
          this.setState({
            cesfams: [...this.state.cesfams, { label: res.ces_nombre, val: res.ces_id, id: res.ces_id }],
          });
        });
      }
    });
  };

  setStartFecha = (e) => {
    this.setState({ startDate: e });
  };

  setEndFecha = (e) => {
    this.setState({ endDate: e });
  };

  setFecha = (e) => {
    this.setState(
      {
        dateSelect: e,
        lineaData: false,
        lineaDataSem: false,
        lineaDataDia: false,
        lineaDataHora: false,
      },
      () => {
        this.fillData();
      }
    );
  };

  setPref = (event, value) => {
    if (value !== null) {
      switch (value.val) {
        case 0:
          this.setState(
            {
              prefSel: value,
              lineaData: false,
              lineaDataSem: false,
              lineaDataDia: false,
              lineaDataHora: false,
            },
            () => {
              this.fillData();
            }
          );
          break;
        case 1:
          this.setState(
            {
              prefSel: value,
              lineaData: false,
              lineaDataSem: false,
              lineaDataDia: false,
              lineaDataHora: false,
            },
            () => {
              this.fillData();
            }
          );
          break;
        case 2:
          this.setState(
            {
              prefSel: value,
              lineaData: false,
              lineaDataSem: false,
              lineaDataDia: false,
              lineaDataHora: false,
            },
            () => {
              this.fillData();
            }
          );
      }
    } else {
      this.setState(
        {
          prefSel: value,
          lineaData: false,
          lineaDataSem: false,
          lineaDataDia: false,
          lineaDataHora: false,
        },
        () => {
          this.fillData();
        }
      );
    }
  };

  componentDidMount() {}

  formatFecha(sem, year) {
    var fechaInicioSemana = moment().years(year).isoWeek(sem).startOf("isoweek");

    return moment(fechaInicioSemana).format("YYYY-MM-DD");
  }

  fillData = async () => {
    var text = "";
    if (this.state.year) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Tiempo de espera " + prefSel + " área de atención, año " + moment(this.state.dateSelect).format("YYYY") + " en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_totem/?data_opcion=4&sub_opcion=3&cesfam=" + this.state.cesOpcion.id + "&year=" + moment(this.state.dateSelect).format("YYYY");

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        if (res.data.tickets.length > 0) {
          res.data.tickets[0].data.map((res) => {
            this.setState({
              data: [
                ...this.state.data,
                {
                  fecha: moment(this.state.dateSelect).format("YYYY") + "-" + moment(res.mesnum, "M").format("MM"),
                  mes: moment(res.mesnum, "M").format("MMMM"),
                  cantidad_npr: res.cantidad_npr,
                  cantidad_prf: res.cantidad_prf,
                },
              ],
            });
          });
        } else {
          this.setState({
            data: [],
          });
        }

        this.setState({
          displayText: text,
          lineaData: true,
          lineaDataSem: false,
          lineaDataDia: false,
          lineaDataHora: false,
        });
      });
    }

    if (this.state.mes && this.state.dateSelect !== null) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Tiempo de espera " + prefSel + " " + moment(this.state.dateSelect).format("MMMM") + " " + moment(this.state.dateSelect).format("YYYY") + " en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_totem/?data_opcion=4&sub_opcion=2&cesfam=" + this.state.cesOpcion.id + "&year=" + moment(this.state.dateSelect).format("YYYY") + "&mes=" + moment(this.state.dateSelect).format("MM");

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        if (res.data.tickets.length > 0) {
          res.data.tickets[0].data.map((res2) => {
            this.setState({
              data: [
                ...this.state.data,
                {
                  cantidad_npr: res2.cantidad_npr,
                  cantidad_prf: res2.cantidad_prf,
                  seg: res2.seg,
                  weekstart: res2.weekstart,
                  weekend: res2.weekend,
                  weeknum: res2.weeknum,
                  week: res2.weekstart + "-" + res2.weekend,
                  mes: moment(res2.weekstart, "DD/MM/YYYY").format("MMMM"),
                },
              ],
            });
          });
        } else {
          this.setState({
            data: [],
          });
        }

        this.setState({
          displayText: text,
          lineaData: false,
          lineaDataSem: true,
          lineaDataDia: false,
          lineaDataHora: false,
        });
      });
    }

    if (this.state.sem && this.state.dateSelect !== null) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Tiempo de espera " + prefSel + " semana y del " + moment(this.state.dateSelect).format("DD") + " de " + moment(this.state.dateSelect).format("MMMM") + " del " + moment(this.state.dateSelect).format("YYYY") + " en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_totem/?data_opcion=4&sub_opcion=1&cesfam=" + this.state.cesOpcion.id + "&year=" + moment(this.state.dateSelect).format("YYYY") + "&sem=" + moment(this.state.dateSelect).format("W");

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        var data = [];
        if (res.data.tickets.length > 0) {
          data = res.data.tickets[0].data;
        } else {
          data = [];
        }
        this.setState({
          data: data,
          displayText: text,
          lineaData: false,
          lineaDataSem: false,
          lineaDataDia: true,
          lineaDataHora: false,
        });
      });
    }

    if (this.state.dia && this.state.dateSelect !== null) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Tiempo de espera " + prefSel + " dia " + moment(this.state.dateSelect).format("DD") + " de " + moment(this.state.dateSelect).format("MMMM") + " del " + moment(this.state.dateSelect).format("YYYY") + " en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_totem/?data_opcion=4&sub_opcion=0&cesfam=" + this.state.cesOpcion.id + "&dia=" + moment(this.state.dateSelect).format("YYYY-MM-DD");

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        if (res.data.tickets.length > 0) {
          res.data.tickets[0].data.map((res) => {
            this.setState({
              data: [
                ...this.state.data,
                {
                  hora: moment(res.seg, "H").format("HH:mm"),
                  seg: moment(res.seg, "H").format("HH:mm"),
                  cantidad_npr: res.cantidad_npr,
                  cantidad_prf: res.cantidad_prf,
                  fecha: res.tit_fecha_sacado__date,
                },
              ],
            });
          });
        } else {
          this.setState({
            data: [],
          });
        }
        this.setState({
          displayText: text,
          lineaData: false,
          lineaDataSem: false,
          lineaDataDia: false,
          lineaDataHora: true,
        });
      });
    }
  };

  handleLineCLick = (datos) => {
    const { year, mes, sem } = this.state;
    if (year) {
      var mess = moment(datos.fecha).format("MM");
      let yr = moment(this.state.dateSelect).format("YYYY");

      let fecha2 = mess + "-" + yr;
      fecha2 = moment(fecha2, "MM-YYYY");

      this.setState(
        {
          year: false,
          mes: true,
          timeSelected: { label: "Mensual", val: 2 },
          dateSelect: fecha2,
          lineaData: false,
          lineaDataSem: false,
          lineaDataDia: false,
          lineaDataHora: false,
        },
        () => {
          this.fillData();
        }
      );
    } else if (mes) {
      let semm = datos.weeknum;
      let yr = moment(this.state.dateSelect).format("YYYY");
      let fecha2 = moment().year(yr).week(semm);

      this.setState(
        {
          mes: false,
          sem: true,
          timeSelected: { label: "Semanal", val: 1 },
          dateSelect: fecha2,
          lineaData: false,
          lineaDataSem: false,
          lineaDataDia: false,
          lineaDataHora: false,
        },
        () => {
          this.fillData();
        }
      );
    } else if (sem) {
      let date = datos.tit_fecha_sacado__date;
      this.setState(
        {
          sem: false,
          dia: true,
          timeSelected: { label: "Diario", val: 0 },
          dateSelect: date,
          lineaData: false,
          lineaDataSem: false,
          lineaDataDia: false,
          lineaDataHora: false,
        },
        () => {
          this.fillData();
        }
      );
    }
  };

  render() {
    const { data } = this.state;
    return (
      <>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "2%" }}>
          <Grid container spacing={3}>
            <Grid item xs={1} sm={1} md={1}>
              <InputLabel className={crearTotem.textosTitulos}>Comuna </InputLabel>
              <Autocomplete
                disablePortal
                disableClearable
                value={this.state.comOpcion}
                id="combo-box-comuna"
                options={this.props.comunas}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                getOptionLabel={(option) => option.label}
                defaultValue={this.state.comOpcion}
                onChange={this.setComuna.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <InputLabel className={crearTotem.textosTitulos}>Cesfam </InputLabel>
              <Autocomplete
                disablePortal
                disableClearable
                value={this.state.cesOpcion}
                id="combo-box-cesfam"
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                defaultValue={this.state.cesOpcion}
                options={this.state.cesfams}
                noOptionsText={"Seleccione una comuna primero"}
                getOptionLabel={(option) => option.label}
                onChange={this.setCesfam.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <InputLabel className={crearTotem.textosTitulos}>Tipo de atención </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-atencion"
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                options={this.props.atenciones}
                getOptionLabel={(option) => option.label}
                onChange={this.setArea.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            {this.props.opAteVisible ? (
              <Grid item xs={1} sm={1} md={1}>
                <Autocomplete disablePortal disableClearable id="combo-box-subatencion" options={this.props.opAte} value={this.state.subSelected} defaultValue={this.state.subSelected} noOptionsText={this.state.areaSelected ? "Esta área no posee opciones" : "Seleccione una área primero"} getOptionLabel={(option) => option.label} onChange={this.setArOp.bind(this)} renderInput={(params) => <TextField {...params} label="Opcion" />}></Autocomplete>
              </Grid>
            ) : null}
            <Grid item xs={1} sm={1} md={1}>
              <InputLabel className={crearTotem.textosTitulos}>Módulo </InputLabel>
              <Autocomplete
                disablePortal
                disableClearable
                id="combo-box-modulo"
                options={this.props.modulos}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                noOptionsText={"Seleccione una atención primero"}
                getOptionLabel={(option) => option.label}
                value={this.state.modulSelected}
                defaultValue={this.state.modulSelected}
                onChange={this.setModule.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <InputLabel className={crearTotem.textosTitulos}>Tiempo </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-tiempo"
                options={this.props.tiempos}
                getOptionLabel={(option) => option.label}
                value={this.state.timeSelected}
                defaultValue={this.state.timeSelected}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                onChange={this.setTime.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            {this.state.year ? (
              <Grid item xs={1} sm={1} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                    disableFuture="true"
                    openTo="year"
                    views={["year"]}
                    value={this.state.dateSelect}
                    initialFocusedDate={this.dateSelect}
                    format="yyyy"
                    id="date-picker-inline"
                    label="Año"
                    name="yearpick"
                    onChange={(e) => this.setFecha(e)}
                    KeyboardButtonProps={{
                      "aria-label": "cambiar fecha",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}

            {this.state.mes ? (
              <Grid item xs={1} sm={1} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                    disableFuture="true"
                    openTo="month"
                    views={["year", "month"]}
                    value={this.state.dateSelect}
                    initialFocusedDate={this.dateSelect}
                    format="yyyy-MM"
                    id="date-picker-inline"
                    label="Año y mes"
                    name="yearmonthpick"
                    onChange={(e) => this.setFecha(e)}
                    KeyboardButtonProps={{
                      "aria-label": "cambiar fecha",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}

            {this.state.sem ? (
              <Grid item xs={1} sm={1} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                    disableFuture="true"
                    openTo="date"
                    views={["year", "month", "date"]}
                    value={this.state.dateSelect}
                    initialFocusedDate={this.dateSelect}
                    format="yyyy-MM-dd"
                    id="date-picker-inline"
                    label="Fecha de semana"
                    name="yearmonthweekpick"
                    onChange={(e) => this.setFecha(e)}
                    KeyboardButtonProps={{
                      "aria-label": "cambiar fecha",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}

            {this.state.dia ? (
              <Grid item xs={1} sm={1} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                    disableFuture="true"
                    openTo="date"
                    views={["year", "month", "date"]}
                    value={this.state.dateSelect}
                    initialFocusedDate={this.dateSelect}
                    format="yyyy-MM-dd"
                    id="date-picker-inline"
                    label="Fecha"
                    name="yearmonthweekpick"
                    onChange={(e) => this.setFecha(e)}
                    KeyboardButtonProps={{
                      "aria-label": "cambiar fecha",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}

            <Grid item xs={1} sm={1} md={2}>
              <InputLabel className={crearTotem.textosTitulos}>Preferencia </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-pref"
                options={this.props.opPref}
                getOptionLabel={(option) => option.label}
                value={this.state.prefSel}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                defaultValue={this.state.prefSel}
                onChange={this.setPref.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
        </GridItem>
        <br></br>
        <h3 className="textColor">{this.state.displayText}</h3>
        <div className="coverVac chartContainer chartGrid">
          <div class="chart">{this.state.lineaData ? <GraficoLinea data={data} titulo="minutos de espera promedio" filename={this.state.displayText} handleLineCLick={this.handleLineCLick} /> : null}</div>
          <div class="chart">{this.state.lineaDataSem ? <GraficoLineaSem data={data} titulo="minutos de espera promedio" filename={this.state.displayText} handleLineCLick={this.handleLineCLick} /> : null}</div>
          <div class="chart">{this.state.lineaDataDia ? <GraficoLineaDia data={data} titulo="minutos de espera promedio" filename={this.state.displayText} handleLineCLick={this.handleLineCLick} /> : null}</div>
          <div class="chart">{this.state.lineaDataHora ? <GraficoLineaHora data={data} titulo="minutos de espera promedio" filename={this.state.displayText} handleLineCLick={this.handleLineCLick} /> : null}</div>
        </div>
      </>
    );
  }
}
