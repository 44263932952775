import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";
import "moment/locale/es";
import axios from "axios";
// core components

import GridContainer from "./../../../components/grid/GridContainer.js";
import GridItem from "./../../../components/grid/GridItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { store } from "./../../../store/index";
import { Grid } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { Input, InputLabel } from "@material-ui/core";

// CSS
import "./../../../assets/css/general.css";
import "./../../../assets/css/usuarios.css";
import "./../../../assets/css/graficos.css";
import GraficoReservadas from "./GraficoReservadas.js";

export default class CantidadReservadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      selected: 0,
      showCes: false,
      showAte: false,
      showProf: false,
      showDate: false,
      showApply: false,
      showGraf: false,
      showTable: false,
      filename: "",

      especialidades: [],
      espSelected: null,
      espNombre: "",
      espSigla: "",
      profesionales: [],
      profSelected: null,
      profNombre: "",

      fechaInicio: "",
      fechaFin: "",

      data: [],
    };
  }

  componentDidMount() {
    this.cargarEspecialidades(this.state.cesfam);
  }

  cambiaGraf(event, value) {
    if (value.val == 0) {
      this.setState({ grafComuna: false });
    } else if (value.val == 1) {
      this.setState({ grafComuna: true });
    }
  }

  parametrosUrl() {
    let urlparams = "";
    let nombreArchivo = "Intentos de Llamada - ";

    urlparams = urlparams + "cesfam=" + this.state.cesfam;
    nombreArchivo = nombreArchivo + " - " + this.state.cesfam;

    urlparams = urlparams + "&especialidad=" + this.state.espSelected.id;
    nombreArchivo = nombreArchivo + " - " + this.state.espSelected.label;

    if (this.state.espSelected && this.state.profSelected) {
      urlparams = urlparams + "&profesional=" + this.state.profSelected.id;
      nombreArchivo = nombreArchivo + " - " + this.state.profSelected.label;
    }

    if (this.state.fechaInicio) {
      urlparams = urlparams + "&fechaInicio=" + this.state.fechaInicio;
    }

    if (this.state.fechaFin) {
      urlparams = urlparams + "&fechaFin=" + this.state.fechaFin;
    }

    nombreArchivo = nombreArchivo + " - " + moment(this.state.dateSelect).format("DD-MM-YYYY");
    this.setState({ filename: nombreArchivo });
    return urlparams;
  }

  cargarDatos = async () => {
    this.setState({ data: [], showGraf: false, llamadas: [] });

    const options = {
      method: "GET",
      headers: authHeader(),
    };
    let data = [];
    let params = this.parametrosUrl();
    const apiUrl = global.config.modulos.videollamada_grafico + "llamadas_reservadas/?" + params;
    await axios(apiUrl, options).then((res) => {
      res.data.llamadas.map((res) => {
        let entrada = { date: res.fecha_creacion, tconsultas_totales: res.tconsultas_totales, tconsultas_realizadas: res.tconsultas_realizadas, tconsultas_fallidas: res.tconsultas_fallidas };
        data.push(entrada);
      });
      this.setState({ data: data, showGraf: true, llamadas: res.data.lista });
    });
  };

  cargarEspecialidades = async (cesfam) => {
    const apiUrl = global.config.modulos.videollamada_grafico + "lista_especialidades/?cesfam=" + cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.especialidades.length > 0) {
        var ind = 0;
        this.setState({ especialidades: [] });
        res.data.especialidades.map((res) => {
          //console.log(res)
          this.setState({ especialidades: [...this.state.especialidades, { label: res.esp_nombre, val: ind, id: res.esp_id, sig: res.esp_sigla }] });
          ind = ind + 1;
        });
      }
    });
  };

  cargarProfesionales = async (especialidad) => {
    const apiUrl = global.config.modulos.videollamada_grafico + "lista_profesionales/?especialidad=" + especialidad;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.profesionales.length > 0) {
        var ind = 0;
        this.setState({ profesionales: [] });
        res.data.profesionales.map((res) => {
          this.setState({ profesionales: [...this.state.profesionales, { label: res.prof_nombre + " " + res.prof_apellido, val: ind, id: res.prof_id }] });
          ind = ind + 1;
        });
      }
    });
  };

  setEspecialidad = (event, value) => {
    if (value === null) {
      this.setState({ espSelected: null, espNombre: null, espSigla: null }, () => {
        this.setState({ showProf: true, showDate: true, showApply: true, profSelected: null, dateSelect: null, showGraf: false });
      });
    } else {
      this.setState({ espSelected: value, espNombre: value.label, espSigla: value.sig }, () => {
        this.cargarProfesionales(value.id);
        this.setState({ showProf: true, showDate: true, showApply: true, profSelected: null, dateSelect: null, showGraf: false });
      });
    }
  };

  setProfesional = (event, value) => {
    if (value === null) {
      this.setState({ profSelected: null, profNombre: null }, () => {
        this.setState({ showDate: true, dateSelect: null, showGraf: false });
      });
    } else {
      this.setState({ profSelected: value, profNombre: value.label }, () => {
        this.setState({ showDate: true, dateSelect: null, showGraf: false });
      });
    }
  };

  setFecha = (e) => {
    this.setState({ dateSelect: e, showGraf: false });
  };

  verTabla() {
    this.setState({ showTable: true, showGraf: false });
  }

  verGrafico() {
    this.setState({ showTable: false, showGraf: true });
  }

  cambiarValores = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const fecha_completa = moment().format("YYYY-MM-DD");

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container spacing={3}>
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete disablePortal id="combo-box-atencion" value={this.state.espSelected} options={this.state.especialidades} getOptionLabel={(option) => option.label} onChange={this.setEspecialidad.bind(this)} renderInput={(params) => <TextField {...params} label="Tipo de Atencion" />}></Autocomplete>
              </Grid>
              {this.state.showProf ? (
                <Grid item xs={2} sm={2} md={2}>
                  <Autocomplete disablePortal id="combo-box-prof" options={this.state.profesionales} value={this.state.profSelected} getOptionLabel={(option) => option.label} onChange={this.setProfesional.bind(this)} renderInput={(params) => <TextField {...params} label="Profesional" />}></Autocomplete>
                </Grid>
              ) : null}
              {this.state.showDate ? (
                <>
                  <Grid item xs={2} sm={2} md={2}>
                    <InputLabel>Fecha Inicio</InputLabel>
                    <Input className={"calendarios-nsp"} style={{ border: "0px" }} type="date" name="fechaInicio" inputProps={{ max: fecha_completa }} value={this.state.fechaInicio} onChange={this.cambiarValores}></Input>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <InputLabel>Fecha Termino</InputLabel>
                    <Input className={"calendarios-nsp"} style={{ border: "0px" }} type="date" name="fechaFin" inputProps={{ max: fecha_completa }} value={this.state.fechaFin} onChange={this.cambiarValores}></Input>
                  </Grid>
                </>
              ) : null}
              {this.state.showApply ? (
                <Grid item xs={1} sm={1} md={1}>
                  <Button className="botonDialog fondoNaranjo downloadButton" onClick={this.cargarDatos}>
                    Aplicar
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </GridItem>
        </GridContainer>

        <div class="middle-content">
          {this.state.showGraf ? (
            <div class="chartContainer chartGrid scroll-container">
              <GraficoReservadas key={"chart"} data={this.state.data} fecha={moment(this.state.dateSelect).format("YYYY-MM-DD")} esp={this.state.espNombre} sig={this.state.espSigla} prof={this.state.profNombre} filename={this.state.filename} />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
