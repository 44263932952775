import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import { NotificationManager } from "react-notifications";

export default class ReasignarCaso extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      usuarioPanel: null,
      gegId: props.gegId,
      cesfam_vista: store.getState().cesfam,
      usuariosPanel: [],
      tomado: props.tomado,
    };
  }

  componentDidMount() {
    this.usuariosReasignacion();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  usuariosReasignacion = () => {
    axios
      .get(global.config.modulos.gestion + "reasignacion/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam_vista, tomado: this.state.tomado },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ usuariosPanel: res.data.datos });
        }
      });
  };

  reasignarCaso = () => {
    if (this.validaciones()) {
      axios
        .patch(
          global.config.modulos.gestion,
          {
            geg_id: this.state.gegId,
            geg_tomado: this.state.usuarioPanel,
            reasignacion: true,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          NotificationManager.success("Caso reasignado con éxito");
          this.generarHistorial("Se reasigna caso", this.state.gegId, this.state.uspId);
          this.salir();
        });
    }
  };

  validaciones = () => {
    if (!this.state.usuarioPanel) {
      return false;
    }

    return true;
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.salir();
      });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Reasignar caso</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Por favor seleccione:</InputLabel>
                    <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="usuarioPanel" name="usuarioPanel" value={this.state.usuarioPanel} onChange={this.cambiarValores} label="">
                      {this.state.usuariosPanel.map((usp, index) => (
                        <MenuItem key={index} value={usp.usp_id}>
                          {usp.usp_nombre + " " + usp.usp_apellido_p}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button style={{ marginLeft: 20 }} color="primary" onClick={this.reasignarCaso} className="fondoVerde">
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
