import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paginacion from "./../../components/Table/Pagination.js";
import MenuTab from "./../../components/GestionReservaExterna/MenuTab.js";
import { NotificationManager } from "react-notifications";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DetalleReservaExterna from "./../../components/GestionReservaExterna/DetalleReservaExterna";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import { confirmAlert } from "react-confirm-alert";

// CSS
import "./../../assets/css/gestion.css";

export default class GesionSospechoso extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: props.usp,
      cesfam: props.cesfam,
      ubicacion: 7,
      pagina: 0,
      filas: 10,
      total: 0,
      idRegistro: null,
      modalDetalle: false,
      demanda: [],
      texto_filtro: props.rutFiltro,
    };
  }

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  componentDidMount() {
    this.fetchGestion(this.state.pagina, this.state.filas);
  }

  fetchGestion = async (pagina, filas) => {
    axios
      .get(global.config.modulos.reservas_externas + "registros/", {
        headers: authHeader(),
        params: {
          ubicacion: this.state.ubicacion,
          pagina: pagina,
          filas: filas,
          usp: this.state.usp,
          cesfam: this.state.cesfam,
          texto_filtro: this.state.texto_filtro,
        },
      })
      .then((res) => {
        this.setState({
          demanda: res.data.datos,
          total: res.data.total,
          pagina: res.data.pagina,
        });
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchGestion(pagina, this.state.filas);
  };

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.fetchGestion(this.state.pagina, filas);
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  aplicarFiltros = () => {
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarDetalle = (id) => {
    this.setState({
      modalDetalle: true,
      idRegistro: id,
    });
  };

  ocultarDetalle = () => {
    this.setState({
      modalDetalle: false,
    });
  };

  reasignar = (id) => {
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea marcar esta hora como reasignada?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            axios
              .put(
                global.config.modulos.reservas_externas + "actualizar/",
                {
                  gre_id: id,
                  gre_reasignada: 1,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                this.fetchGestion(this.state.pagina, this.state.filas);
              });
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  mostrarPreguntas = (datosRespuetsas) => {
    if (datosRespuetsas) {
      let resps = datosRespuetsas.split(";");
      let preguntas = [];
      preguntas[1] = "¿ha tenido alguno de los siguientes síntomas? Fiebre, Tos seca, Malestar general o Dolor muscular, Falta de aire, Mucosidad nasal o Estornudos";

      return resps.map((resp, index) => {
        if (resp) {
          let respuesta = "No";
          if (resp === "1") {
            respuesta = "Si";
          }
          return (
            <Box p={1}>
              <Typography>
                <p>{preguntas[index]}</p>
                <p>{respuesta}</p>
              </Typography>
            </Box>
          );
        }
      });
    }
  };

  render() {
    const { demanda } = this.state;
    const colorUrgencia = {
      Alto: "rgb(193 39 45)",
      Medio: "rgb(255 255 0)",
      Bajo: "rgb(57 181 74)",
      AltoColor: "#fff",
      MedioColor: "#000",
      BajoColor: "#fff",
    };

    return (
      <GridContainer style={{ width: "100%" }}>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div style={{ float: "left" }}>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <input
                  type="text"
                  name="texto_filtro"
                  value={this.state.texto_filtro}
                  onChange={this.cambiarValores}
                  placeholder="Nombre o Rut"
                  className={"inputText"}
                  style={{
                    padding: 6,
                    border: "1px solid black",
                    borderRadius: 5,
                    float: "left",
                  }}
                />
                <a
                  onClick={this.aplicarFiltros}
                  color="primary"
                  className="btnAgregarCaso fondoNaranjo"
                  style={{
                    padding: "7px 13px 7px 13px",
                    fontSize: 14,
                    float: "left",
                    marginLeft: 30,
                  }}
                >
                  Buscar
                </a>
              </p>
            </div>
            <MenuTab modal={7} cambiarVentana={this.props.cambiarVentana} />
            <div style={{ marginTop: 30, marginBottom: 100 }}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha de ingreso
                      </TableCell>
                      <TableCell align="center">Rut</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Edad</TableCell>
                      <TableCell align="center">Cesfam</TableCell>
                      <TableCell align="center">Tipo de atención</TableCell>
                      <TableCell align="center">Lugar de atención</TableCell>
                      <TableCell align="center">Fecha de atención</TableCell>
                      <TableCell align="center">Rut profesional</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demanda.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row.gre_fecha_ingreso ? row.gre_fecha_ingreso.replace("T", " ") : "--"}</TableCell>
                        <TableCell align="center">{row.rut}</TableCell>
                        <TableCell align="center">{row.nombre_usuario}</TableCell>
                        <TableCell align="center">{row.edad}</TableCell>
                        <TableCell align="center">{row.cesfam_usuario}</TableCell>
                        <TableCell align="center">{row.gre_tipo_atencion}</TableCell>
                        <TableCell align="center">{row.lugar_atencion}</TableCell>
                        <TableCell align="center">{row.gre_fecha_atencion ? row.gre_fecha_atencion.replace("T", " ") : "--"}</TableCell>

                        <TableCell align="center">{row.gre_rut_profesional}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
            </div>
          </div>
          {this.state.modalDetalle && <DetalleReservaExterna idRegistro={this.state.idRegistro} ocultar={this.ocultarDetalle} />}
        </GridItem>
      </GridContainer>
    );
  }
}
