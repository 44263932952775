import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import { store } from "../../store/index";
import { connect } from "react-redux";

import { withStyles, makeStyles } from "@material-ui/core/styles";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";

import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

import { FaTrash } from "react-icons/fa";
import { showLoader, hideLoader } from "./../../store/actions/actions";

// estilos

class ClonarHorario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      proId: this.props.profesional,
      semana: this.props.semana,
      tipoCarga: this.props.tipoCarga,

      cantidadSemanas: 0,

      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirActualizado = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarRepetirSemanaActualizado();
  };

  componentDidMount() {}

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  horariosParaCargar = () => {
    this.enviarFormulario();
  };

  enviarFormulario = (e) => {
    if (this.state.cantidadSemanas <= 0) {
      NotificationManager.warning("La cantidad de semanas no puede ser 0 o menor que 0");
    } else if (this.state.cantidadSemanas > 24) {
      NotificationManager.warning("La cantidad de semanas no puede ser mayor a 24");
    } else {
      this.props.dispatch(showLoader());

      var sem = this.state.semana;
      var arrSemana = sem.split("-W");

      var nSemana = arrSemana[1];
      var añoPorNSemana = arrSemana[0];

      var fechaInicioSemana = moment().years(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

      var fechaTerminoSemana = moment().years(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

      var data = {
        cesfam: this.state.cesfam,
        uspId: this.state.uspId,
        profesional: this.state.proId,
        nSemanas: this.state.cantidadSemanas,
        fechaInicio: fechaInicioSemana,
        fechaTermino: fechaTerminoSemana,
        tipoCarga: this.state.tipoCarga,
      };

      let apiUrl = global.config.modulos.agendamiento_celery + "clonar_semana/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options)
        .then((res) => {
          this.props.dispatch(hideLoader());

          setTimeout(() => {
            if (res.data.estado) {
              this.salirActualizado();

              this.mostrarAlerta(res.data.mensaje);
            } else {
              this.mostrarAlerta(res.data.mensaje);
            }
          }, 500);
        })
        .catch((err) => {
          this.props.dispatch(hideLoader());
        });
    }
  };

  render() {
    const { tipoCarga } = this.state;

    var titulo = "";
    if (tipoCarga == 2) {
      titulo = "Repetir semana última carga realizada";
    } else {
      titulo = "Repetir semana completa";
    }

    return (
      <Dialog open={this.state.open} onClose={this.salirActualizado} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <div className="cargarHorario">
            <GridContainer>
              <GridItem xs={12}>
                <h2>{titulo}</h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel>Cantidad semanas próximas en donde se repetirá</InputLabel>
                    <input type="number" name="cantidadSemanas" value={this.state.cantidadSemanas} onChange={this.cambiarValores} placeholder="" className={"inputTextAgendar"} min="0" max="24" />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12} style={{ float: "right" }}>
                      <Grid item xs={12} sm={8} md={8} style={{ float: "left", marginTop: 20 }} className="modalHorarioCopiar">
                        <Button onClick={this.enviarFormulario} className="fondoVerde">
                          Repetir semana
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{
                          float: "left",
                          marginTop: 20,
                          marginLeft: 20,
                        }}
                        className="modalHorarioCopiar"
                      >
                        <Button onClick={this.salirActualizado} className="fondoRojo">
                          Cancelar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ClonarHorario);
