import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import { store } from "./../../store/index";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import chileanRut from "chilean-rut";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { confirmAlert } from "react-confirm-alert";
import { NotificationManager } from "react-notifications";

import MotivoReserva from "components/Usuarios/MotivoReserva";

import ImprimirHora from "./../Usuarios/ImprimirHora";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Cargando from "./../../components/Helpers/Cargando";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class ModalAgendarHora extends Component {
  constructor(props) {
    super(props);

    var teleconsulta = false;
    var contacto = null;
    if (props.nombreEspecialidad.includes("Teleconsulta")) {
      teleconsulta = true;
      contacto = 1;
    }

    this.state = {
      cargando: false,

      cesfam: store.getState().cesfam,
      canal: 5,

      horario: props.horario,
      horaAtencion: "",
      especialidad: "",
      esp_sigla: "",

      rut_usuario: "",
      usu_id: "",
      usu_edad: "",
      usu_sexo: "",
      telefonos: [],
      otroTelefono: "",
      telefono: "",
      contacto: contacto,

      nombreProfesional: props.nombreProfesional,
      nombreEspecialidad: props.nombreEspecialidad,
      teleconsulta: teleconsulta,
      canalesTeleconsulta: [],

      tieneExamenes: false,
      examenes: [],
      examen: null,
      modalidad: "",
      modalidades: [],

      herramientas: store.getState().herramientas,
      uspId: store.getState().usp,
      open: true,
      motivoReserva: false,
      formErrors: [],
    };

    this.traerHorario();
    this.canalesTeleconsulta();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  alerta = (mensaje) => {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  };

  traerHorario = () => {
    let apiUrl = global.config.modulos.agendamientos + "horario_por_id/" + this.state.horario;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;
      var estado = data.estado;

      if (estado) {
        var horaAtencion = moment(data.hor_atencion).format("DD-MM-YYYY HH:mm:ss");

        this.setState({ horaAtencion: horaAtencion, especialidad: data.hor_especialidad }, this.traerEspecialidad(data.hor_especialidad), this.traerExamenes(data.hor_especialidad, data.hor_profesional));
      }
    });
  };

  traerEspecialidad = (especialidad) => {
    let apiUrl = global.config.modulos.especialidades + "?esp_id=" + especialidad;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;
      this.setState({
        esp_sigla: data.datos.esp_sigla,
      });
    });
  };

  validaciones() {
    const { rut_usuario, teleconsulta, contacto, telefono, otroTelefono, tieneExamenes, examen, modalidad } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!rut_usuario) {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut_usuario)) {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut válido.";
    } else if (teleconsulta && contacto == "3" && telefono == "") {
      formIsValid = false;
      formErrors["usuarioErr"] = "Selecciona un teléfono.";
    } else if (teleconsulta && telefono == "otro" && !otroTelefono) {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el teléfono de contacto.";
    }
    if (tieneExamenes && examen == null) {
      formIsValid = false;
      formErrors["examenErr"] = "Seleciona el examen que se realizara el paciente.";
    }

    if (tieneExamenes && modalidad == "") {
      formIsValid = false;
      formErrors["modalidadErr"] = "Seleciona la modalidad";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  revisarPendiente = async () => {
    var validarUser = await this.validarUsuario(1);
    if (validarUser) {
      var usuId = validarUser;
      axios
        .get(global.config.modulos.reservas + "hora_pendiente/", {
          headers: authHeader(),
          params: {
            usuario: usuId,
            especialidad: this.state.esp_sigla,
            cesfam: this.state.cesfam,
          },
        })
        .then((res) => {
          var data = res.data;
          var estado = data.estado;
          if (estado) {
            if (data.datos.tipo == "pendiente") {
              this.alerta(data.datos.mensaje);
              return false;
            }
          } else {
            axios
              .get(global.config.modulos.agendamientos + "revisar_hora_futura/", {
                headers: authHeader(),
                params: {
                  usuario: usuId,
                  horario: this.state.horario,
                },
              })
              .then((res) => {
                var data = res.data;
                var estado = data.estado;
                if (estado) {
                  confirmAlert({
                    title: "",
                    message: res.data.mensaje,
                    buttons: [
                      {
                        label: "Cancelar",
                        className: "btnCancelar",
                        onClick: () => null,
                      },
                      {
                        label: "Aceptar",
                        className: "btnAceptar",
                        onClick: () => {
                          setTimeout(() => {
                            this.reservarHora(usuId);
                          }, 50);
                        },
                      },
                    ],
                  });
                } else {
                  this.reservarHora(usuId);
                }
              });
          }
        });
    }
  };

  reservarHora = (usuId) => {
    confirmAlert({
      title: "",
      message: "Segur@ que desea reservar esta hora?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
          onClick: () => null,
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            this.setState({
              cargando: true,
            });

            let telefono = this.state.telefono;
            if (telefono == "otro") {
              this.agregarTelefono(this.state.otroTelefono, usuId, this.state.uspId);
              telefono = this.state.otroTelefono;
            }

            axios
              .get(global.config.modulos.agendamientos + "motivos_reserva_horario/", {
                headers: authHeader(),
                params: {
                  especialidad: this.state.especialidad,
                },
              })
              .then((res) => {
                if (res.data.estado) {
                  this.setState({
                    cargando: false,
                  });

                  this.setState({
                    cargando: false,
                    motivos: res.data.motivos,
                    horarioReserva: this.state.horario,
                    cesfamReserva: this.state.cesfam,
                    telefonoReserva: telefono,
                    usuarioReserva: usuId,
                    tipoReserva: res.data.tipo,
                    motivoReserva: true,
                  });
                } else {
                  axios
                    .post(
                      global.config.modulos.agendamientos + "agendar_horario/",
                      {
                        horario: this.state.horario,
                        cesfam: this.state.cesfam,
                        usp_id: this.state.uspId,
                        canal: this.state.canal,
                        usu_id: usuId,
                        tipo_contacto: this.state.contacto,
                        telefono_contacto: telefono,
                        examen: this.state.examen,
                        modalidad: this.state.modalidad,
                      },
                      { headers: authHeader() }
                    )
                    .then((res) => {
                      this.setState({
                        cargando: false,
                      });
                      if (res.data.estado) {
                        this.salir();
                        if (this.state.herramientas.includes("H61")) {
                          this.mostrarImprimir(this.state.horario, usuId, this.state.examen);
                        }
                      } else {
                        this.alerta(res.data.mensaje);
                        this.salir();
                      }
                    })
                    .catch((err) => {
                      this.setState({
                        cargando: false,
                      });
                    });
                }
              });
          },
        },
      ],
    });
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    if (name == "contacto") {
      if (value == 3) {
        (async () => {
          var validar = await this.validarUsuario(2);
          if (validar) {
            this.setState({ existeContactoTelefono: true });
            this.setState({ [name]: value });
          } else {
            this.setState({ [name]: "1" });
          }
        })();
      } else {
        this.setState({ existeContactoTelefono: false });
        this.setState({ [name]: value });
      }
    } else if (name == "telefono") {
      if (value == "otro") {
        this.setState({ existeContactoTelefonoOtro: true });
      } else {
        this.setState({ existeContactoTelefonoOtro: false });
      }
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  validarUsuario = async (tipo) => {
    var formIsValid = false;
    if (this.validaciones()) {
      var data = {
        rut: this.state.rut_usuario,
        canal: this.state.canal,
        cesfam: store.getState().cesfam,
      };

      let apiUrl = global.config.modulos.usuarios + "por_rut/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      var res = await axios(apiUrl, options);
      var data = res.data;
      var estado = data.estado;

      if (estado) {
        if (data.datos.usu_estado == 2) {
          let formErrors = {};

          formErrors["usuarioErr"] = "Paciente eliminado de HoraSalud.";
          this.setState({ formErrors: formErrors });
        } else if (data.datos.usu_estado == 0) {
          let formErrors = {};

          formErrors["usuarioErr"] = "Paciente no inscrito en HoraSalud.";
          this.setState({ formErrors: formErrors });
        } else {
          var usuId = data.datos.usu_id;
          this.setState({ usu_id: usuId, usu_edad: data.datos.usu_edad, usu_sexo: data.datos.usu_sexo });
          if (tipo == 2) {
            this.traerTelefonos(usuId);
          }
          formIsValid = usuId;
        }
      } else {
        let formErrors = {};

        formErrors["usuarioErr"] = res.data.mensaje;
        this.setState({ formErrors: formErrors });
      }
    }
    return formIsValid;
  };

  traerTelefonos = (usuId) => {
    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario/" + usuId;
    axios(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        telefonos: res.data.datos,
        usuId: usuId,
      });
    });
  };

  agregarTelefono = (telefono, usuId, uspId) => {
    axios
      .post(
        global.config.modulos.telefonos + "agregar_telefono/",
        { headers: authHeader() },
        {
          telefono: telefono,
          usu_id: usuId,
          tipo: 2,
          canal: this.state.canal,
          usp_id: uspId,
        }
      )
      .then((res) => {});
  };

  mostrarImprimir = (horario, usuId, examen) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ImprimirHora horario={horario} usuId={usuId} examen={examen} ocultar={onClose}></ImprimirHora>;
      },
    });
  };

  canalesTeleconsulta = async () => {
    axios
      .get(global.config.modulos.videollamada + "canales_teleconsulta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        this.setState({
          canalesTeleconsulta: data,
        });
      })
      .catch((err) => {});
  };

  traerExamenes = async (especialidad, profesional) => {
    axios
      .get(global.config.modulos.especialidades + "examenes/", {
        headers: authHeader(),
        params: {
          profesional: profesional,
          especialidad: especialidad,
          uspId: this.state.uspId,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        if (data.length > 0) {
          this.setState({
            examenes: data,
            tieneExamenes: true,
          });
          this.traerModalidades();
        }
      })
      .catch((err) => {});
  };

  confirmarExamen = async () => {
    if (this.validaciones()) {
      var validarUser = await this.validarUsuario(1);
      if (validarUser) {
        var usu_id = validarUser;
        confirmAlert({
          title: "",
          message: "¿El paciente tiene la orden para el examen?",
          buttons: [
            {
              label: "No",
              className: "btnCancelar",
              onClick: async () => {
                await this.guardarRespuestaExamen(2, 1, usu_id);
                confirmAlert({
                  title: "",
                  message: "La orden de examen es obligatoria para realizar la reserva y posteriormente el examen, una vez que el paciente presente la orden del examen podrá reservar la hora de atención.",
                  buttons: [
                    {
                      label: "Aceptar",
                      className: "btnAceptar",
                    },
                  ],
                });
              },
            },
            {
              label: "Sí",
              className: "btnAceptar",
              onClick: async () => {
                await this.guardarRespuestaExamen(1, 1, usu_id);
                this.revisarEmbarazo();
              },
            },
          ],
        });
      }
    }
  };

  revisarEmbarazo = async () => {
    if (this.state.usu_edad >= 12 && this.state.usu_edad <= 52 && this.state.usu_sexo == "F" && (this.state.especialidad == 2567 || this.state.especialidad == 2568)) {
      confirmAlert({
        title: "",
        message: "¿La Paciente tiene sospecha de embarazo?",
        buttons: [
          {
            label: "No",
            className: "btnCancelar",
            onClick: async () => {
              await this.guardarRespuestaExamen(2, 2, this.state.usu_id);
              this.revisarPendiente();
            },
          },
          {
            label: "Sí",
            className: "btnAceptar",
            onClick: async () => {
              await this.guardarRespuestaExamen(1, 2, this.state.usu_id);
              this.confirmarEmbarazo();
            },
          },
        ],
      });
    } else {
      this.revisarPendiente();
    }
  };

  confirmarEmbarazo = async () => {
    confirmAlert({
      title: "",
      message: "¿Estás seguro que la paciente indica tener sospecha de embarazo?",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
          onClick: async () => {
            await this.guardarRespuestaExamen(2, 3, this.state.usu_id);
            this.revisarPendiente();
          },
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: async () => {
            await this.guardarRespuestaExamen(1, 3, this.state.usu_id);
            confirmAlert({
              title: "",
              message: "La Paciente no puede realizarse el examen, dado que puede afectar su salud por tener sospecha de embarazo",
              buttons: [
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                },
              ],
            });
          },
        },
      ],
    });
  };

  guardarRespuestaExamen = async (respuesta, pregunta, usu_id) => {
    var data = {
      uspId: this.state.uspId,
      respuesta: respuesta,
      pregunta: pregunta,
      usuario: usu_id,
    };
    let apiUrl = global.config.modulos.agendamientos + "imagenologia_respuestas/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    await axios(apiUrl, options);

    return true;
  };

  traerModalidades() {
    const apiUrl = global.config.modulos.usuarios + "tipos_convenios/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: this.state.uspId,
        },
      })
      .then((res) => {
        var datos = res.data.datos;
        if (datos.length == 1) {
          this.setState({
            modalidades: res.data.datos,
            modalidad: res.data.datos[0]["uct_id"],
          });
        } else {
          this.setState({
            modalidades: res.data.datos,
          });
        }
      });
  }

  agendarHorario = () => {
    this.setState({
      motivoReserva: false,
      cargando: true,
    });

    axios
      .post(
        global.config.modulos.agendamientos + "agendar_horario/",
        {
          horario: this.state.horario,
          cesfam: this.state.cesfam,
          usp_id: this.state.uspId,
          canal: this.state.canal,
          usu_id: this.state.usuarioReserva,
          tipo_contacto: this.state.contacto,
          telefono_contacto: this.state.telefonoReserva,
          examen: this.state.examen,
          modalidad: this.state.modalidad,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        this.setState({
          cargando: false,
        });
        if (res.data.estado) {
          this.salir();
          if (this.state.herramientas.includes("H61")) {
            this.mostrarImprimir(this.state.horario, this.state.usuarioReserva, this.state.examen);
          }
        } else {
          this.alerta(res.data.mensaje);
          this.salir();
        }
      })
      .catch((err) => {
        this.setState({
          cargando: false,
        });
      });
  };

  ocultarMotivoReserva = () => {
    this.setState({
      motivoReserva: false,
    });
  };

  render() {
    const { usuarioErr, examenErr, modalidadErr } = this.state.formErrors;
    const { rut_usuario } = this.state;

    const datosExamen = (dato) => {
      if (dato == null) {
        this.setState({
          examen: null,
        });
      } else {
        this.setState({
          examen: dato.exs_id,
        });
      }
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Reservar hora</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <GridContainer className="agendar-container">
                    <GridItem xs={3}>
                      <p style={{ fontWeight: "bold" }}>Nombre profesional</p>
                      <p style={{ marginTop: 5 }}>{this.state.nombreProfesional}</p>
                    </GridItem>
                    <GridItem xs={3}>
                      <p style={{ fontWeight: "bold" }}>Especialidad</p>
                      <p style={{ marginTop: 5 }}>{this.state.nombreEspecialidad}</p>
                    </GridItem>
                    <GridItem xs={3}>
                      <p style={{ fontWeight: "bold" }}>Horario</p>
                      <p style={{ marginTop: 5 }}>{this.state.horaAtencion}</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <br></br>
                  <InputLabel>Rut</InputLabel>
                  <input type="text" id="rut_usuario" name="rut_usuario" value={rut_usuario} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={usuarioErr ? "inputTextAgendar showError" : "inputTextAgendar"} maxLength={12} />
                  {usuarioErr && <div style={{ color: "red", paddingBottom: 10 }}>{usuarioErr}</div>}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <br></br>
                  {this.state.teleconsulta ? (
                    <>
                      <InputLabel>Contacto</InputLabel>
                      <select id="contacto" name="contacto" value={this.state.contacto} onChange={this.cambiarValores} className={"inputSelectAgendar"}>
                        {this.state.canalesTeleconsulta.map((res, index) => {
                          return (
                            <option value={res.cta_id} key={index}>
                              {res.cta_canal}
                            </option>
                          );
                        })}
                      </select>
                      <br></br>
                      <br></br>
                      {this.state.existeContactoTelefono ? (
                        <>
                          <InputLabel>Teléfono</InputLabel>
                          <select id="telefono" name="telefono" value={this.state.telefono} onChange={this.cambiarValores} className={"inputSelectAgendar"}>
                            <option selected disabled value="">
                              Seleccione
                            </option>
                            {this.state.telefonos.map((res, index) => {
                              return (
                                <option key={index} value={res.tel_telefono}>
                                  {res.tel_telefono}
                                </option>
                              );
                            })}
                            <option value="otro">Otro</option>
                          </select>
                          <br></br>
                          <br></br>
                          {this.state.existeContactoTelefonoOtro ? (
                            <>
                              <InputLabel>Otro</InputLabel>
                              <TextField
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                                }}
                                id="standard-basic"
                                label=""
                                value={this.state.otroTelefono}
                                style={{ with: 300 }}
                                type="number"
                                name="otroTelefono"
                                onChange={this.cambiarValores}
                                onInput={(e) => {
                                  e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                                }}
                              />
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {this.state.tieneExamenes ? (
                    <>
                      <InputLabel>Examen*</InputLabel>
                      <Autocomplete
                        id="cmbExamenes"
                        onChange={function (event, res) {
                          if (res === null) {
                            datosExamen(null);
                          } else {
                            datosExamen(res);
                          }
                        }}
                        size="small"
                        options={Object.values(this.state.examenes)}
                        getOptionLabel={(option) => "0" + option.exs_codigo + " -- " + option.exs_prestacion}
                        filterOptions={createFilterOptions({
                          stringify: (option) => "0" + option.exs_codigo + option.exs_prestacion,
                        })}
                        renderInput={(params) => <TextField {...params} />}
                      ></Autocomplete>
                      {examenErr && <div style={{ color: "red", paddingBottom: 10 }}>{examenErr}</div>}
                      <br></br>
                      <br></br>

                      <InputLabel>Modalidad*</InputLabel>
                      <Select id="modalidad" name="modalidad" value={this.state.modalidad} onChange={this.cambiarValores} displayEmpty className={""} style={{ width: "100%" }}>
                        <MenuItem value="" disabled>
                          <em>Seleccione modalidad</em>
                        </MenuItem>

                        {this.state.modalidades &&
                          this.state.modalidades.map((uct, index) => (
                            <MenuItem value={uct.uct_id} key={index}>
                              {uct.uct_nombre}
                            </MenuItem>
                          ))}
                      </Select>
                      {modalidadErr && <div style={{ color: "red", paddingBottom: 10 }}>{modalidadErr}</div>}
                    </>
                  ) : null}

                  <Button variant="contained" className="btnCargaHorario fondoVerde" onClick={this.state.tieneExamenes ? this.confirmarExamen : this.revisarPendiente}>
                    Reservar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.cargando ? <Cargando /> : null}
        {this.state.motivoReserva ? <MotivoReserva motivos={this.state.motivos} horarioReserva={this.state.horarioReserva} usuarioReserva={this.state.usuarioReserva} tipoReserva={this.state.tipoReserva} ocultarAgendar={this.agendarHorario} ocultar={this.ocultarMotivoReserva} /> : null}
      </Dialog>
    );
  }
}
