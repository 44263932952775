import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "../../store/index";
import axios from "axios";
import moment from "moment";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { NotificationManager } from "react-notifications";
import { CSVReader } from "react-papaparse";
import csvRepartos from "../../assets/img/ejemplo_csv_repartos.png";
import "./../../assets/css/repartos.css";
import csvArchivo from "../../assets/archivos/formatoVacunacion.csv";

// estilos

export default class AgregarCargaRni extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datosCarga: [],
      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      open: true,
    };

    this.salir = () => {
      this.setState({
        open: false,
      });
      props.ocultar();
    };

    this.initialState = this.state;
  }

  cargaMasiva = () => {
    document.getElementById("cargando").style.display = "none";
    if (this.state.datosCarga.length == 0) {
      NotificationManager.warning("Agrega el archivo");
    } else if (this.state.datosCarga.length > 100000) {
      NotificationManager.warning("Sobrepasa los usuarios por carga (100 mil).");
    } else {
      document.getElementById("cargando").style.display = "block";
      var data = {
        uspId: this.state.uspId,
        cesfam: this.state.cesfam,
        datos: this.state.datosCarga,
      };
      let apiUrl = global.config.modulos.repartos + "carga_usuarios_rni_influeza_csv/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        document.getElementById("cargando").style.display = "none";

        if (res.data["errores"].length > 0) {
          var mensaje = "";
          var i;
          for (i = 0; i < res.data["errores"].length; i++) {
            mensaje += res.data["errores"][i][0] + "\n";
          }

          const element = document.createElement("a");
          const file = new Blob([mensaje], {
            type: "text/plain;charset=utf-8",
          });
          element.href = URL.createObjectURL(file);
          element.download = "erroresArchivoAcargar.txt";
          document.body.appendChild(element);
          element.click();
        }

        this.salir();
      });
    }
  };

  handleOnDrop = (data) => {
    console.log(data);
    this.setState({ datosCarga: data });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} id="dialogReparto">
        <DialogContent>
          <div className="formDiv" style={{ float: "none" }}>
            <h4>Agrega el archivo con los usuarios</h4>
            <br></br>

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <img style={{ width: "auto" }} />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <CSVReader
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={this.handleOnRemoveFile}
                    config={{
                      header: true,
                      dynamicTyping: true,
                      skipEmptyLines: true,
                      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                      encoding: "windows-1252",
                    }}
                  >
                    <span>Arrastra el archivo CSV o presiona para cargar.</span>
                  </CSVReader>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <LinearProgress id="cargando" style={{ display: "none" }} />
                </Grid>
              </Grid>
            </Grid>
            <div style={{ width: "100%", float: "left", marginTop: "32px" }}>
              <Button onClick={this.cargaMasiva} color="primary">
                Agregar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
