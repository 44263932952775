import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

// core components
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import chileanRut from "chilean-rut";
import Titulo from "../titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import Paginacion from "../Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { Tooltip } from "recharts";
import Buttons from "react-multi-date-picker/components/button";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default class PrioritariosNsp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      lapid: props.lap_id,
      prioDetalle: [],
      usuario: props.usuario,
      especialidad: props.especialidad,
      pagina: 0,
      filas: 10,
      total: 0,
    };
    console.log(this.state.usuario);
    console.log(this.state.especialidad);
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.obtenerPrioDetalle(this.state.pagina, this.state.filas);
  }

  obtenerPrioDetalle = async (pagina, filas) => {
    axios
      .get(global.config.modulos.prioritarios + "ver_prioritarios/nsp", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuario,
          paginas: pagina,
          filas: filas,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            prioDetalle: res.data.datos,
            total: res.data.total,
            pagina: res.data.pagina,
          });
        }
      });
    //console.log(this.state.prioDetalle);
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.obtenerPrioDetalle(pagina, this.state.filas);
  };
  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.obtenerPrioDetalle(this.state.pagina, filas);
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="buscarUsuarioGestion">
          <DialogContent>
            <DialogContentText style={{ fontSize: "30px", fontWeight: "bold" }}>Historial NSP</DialogContentText>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ marginTop: "30", marginBottom: "2%" }}>
                  <TableContainer>
                    <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Rut paciente</TableCell>
                          <TableCell align="center">Fecha solicitud</TableCell>
                          <TableCell align="center">Hora perdida</TableCell>
                          <TableCell align="center">Tipo de atención</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.prioDetalle.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{chileanRut.format(row.rut_usuario, true)}</TableCell>
                            <TableCell align="center">{moment(row.fecha_solicitud).format("DD/MM/YYYY HH:MM")} </TableCell>
                            <TableCell align="center">{moment(row.hora_atencion).format("DD/MM/YYYY HH:MM")}</TableCell>
                            <TableCell align="center">{row.nombre_especialidad}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
                  </TableContainer>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: "92%", color: "white" }} className="btnOpcion fondoNaranjo">
                  Cerrar
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
