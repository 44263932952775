/*
Este componente se usa para mostrar un tooltip con información
Se le debe entregar los siguientes props:
- texto: texto que se muestra en el tooltip
- top: distancia desde el borde superior de la pantalla, por defecto es 35

*/
import React from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export default function InfoTooltip(props) {
  const { texto } = props;
  const [open, setOpen] = React.useState(false);
  const [fixedTop, setFixedTop] = React.useState(props.top != null ? props.top : 35);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {texto && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<label style={{ color: "white", fontSize: 15 }}>{texto}</label>}
          >
            <InfoIcon style={{ cursor: "pointer", width: 30, marginTop: fixedTop }} onClick={handleTooltipOpen} />
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
}
