import React, { useEffect, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { NotificationManager } from "react-notifications";
import { store } from "./../../store/index";
import BasicTextField from "components/Common/BasicTextField.js";
import chileanRut from "chilean-rut";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import moment from "moment";

const AgregarSuspension = (props) => {
  const [open, setOpen] = useState(true);
  const [ocultar, setOcultar] = useState(props.ocultar);
  const [suspensiones, setSuspensiones] = useState([]);
  const [cesfam, setCesfam] = useState(store.getState().cesfam);
  const [uspId, setUspId] = useState(store.getState().usp);
  const [fechaInicio, setFechaInicio] = useState(moment().format("YYYY-MM-DD HH:mm"));
  const [fechaTermino, setFechaTermino] = useState(moment().format("YYYY-MM-DD HH:mm"));
  const [rutProfesional, setRutProfesional] = useState("");

  useEffect(() => {
    //traerSuspensiones();
  }, []);

  const salir = () => {
    setOpen(false);
    ocultar();
  };

  const agregarSuspension = (e) => {
    if (!chileanRut.validate(rutProfesional)) {
      NotificationManager.error("Escribe el rut válido.");
      return;
    }

    axios
      .post(
        global.config.modulos.reservas_externas + "suspender_recordatorios/",
        {
          cesfam: cesfam,
          fecha_inicio: fechaInicio,
          fecha_termino: fechaTermino,
          usuario_panel: uspId,
          rut_profesional: rutProfesional,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Suspensión agregada");
          salir();
        }
      });
  };

  return (
    <Dialog open={open} onClose={salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="buscarUsuarioGestion">
      <DialogContent>
        <GridContainer className="agendar-container">
          <GridItem xs={12} sm={12} md={12} className="">
            <GridContainer className="agendar-container" style={{ display: "block" }}>
              <GridItem xs={12}>
                <h2>Agregar suspensión</h2>
              </GridItem>
              <BasicTextField titulo={"Rut profesional"} valorActual={rutProfesional} cambiarValor={setRutProfesional} nombreVar={"rutProfesional"} tipo="text" componenteFuncional={true} />

              <BasicTextField titulo={"Fecha de inicio"} valorActual={fechaInicio} cambiarValor={setFechaInicio} nombreVar={"fechaInicio"} tipo="datetime-local" componenteFuncional={true} />

              <BasicTextField titulo={"Fecha de término"} valorActual={fechaTermino} cambiarValor={setFechaTermino} nombreVar={"fechaTermino"} tipo="datetime-local" componenteFuncional={true} />

              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Button onClick={salir} style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" className="fondoRojo">
                  Cerrar
                </Button>
                <Button onClick={agregarSuspension} style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" className="fondoVerde">
                  Agregar
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

export default AgregarSuspension;
