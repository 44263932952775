import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import chileanRut from "chilean-rut";

import { connect } from "react-redux";
import { store } from "./../../store/index";
import { withStyles, makeStyles } from "@material-ui/core/styles";

// core components
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import TimePicker from "rc-time-picker";

import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

import AgendarHora from "components/Agendar/ModalAgendarHora";
import crearTotem from "assets/css/crearTotem.module.css";

// estilos
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
  },
}))(Tooltip);

class Horarios extends Component {
  constructor(props) {
    super(props);

    var weeknumber = moment().isoWeek();
    weeknumber = weeknumber < 10 ? "0" + weeknumber : weeknumber;
    var yearnumber = moment().year();
    var semanaActual = yearnumber + "-W" + weeknumber;

    this.state = {
      rendimiento: 15,
      disabled: false,
      semana: semanaActual,

      tabla: "",
      tiempoInicio: "",
      tiempoTermino: "",

      especialidades: [],
      especialidad: "0",
      fecha: new Date(),
      agendarHora: false,

      cesfam: store.getState().cesfam,
      usp_id: store.getState().usp,
    };
  }

  componentDidMount() {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Horario de Profesionales<span title='asdasd'><span>";

    this.especialidades();
    this.traerHorarioCesfam();
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  especialidades = () => {
    axios
      .get(global.config.modulos.especialidades, {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          sigla: "Todas",
          tipo: 2,
          usp_id: this.state.usp_id,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            especialidades: res.data.datos,
          });
        }
      });
  };

  traerHorarioCesfam() {
    const apiUrl = global.config.modulos.cesfams + "cesfam_horario/?cesfam=" + this.state.cesfam;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var horarioFuncionamiento = res.data.horarioFuncionamiento;
      var fechaActual = moment().format("YYYY-MM-DD");
      this.setState({
        tiempoInicio: moment(fechaActual + " " + horarioFuncionamiento.hora_inicio),
        tiempoTermino: moment(fechaActual + " " + horarioFuncionamiento.hora_termino),
      });
    });
  }

  traerRendimientos(espId) {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_rendimiento/" + espId;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState(
        {
          rendimientos: res.data,
          rendimiento: res.data[0]["esr_rendimiento"],
        },
        () => {
          this.horarios(espId);
        }
      );
    });
  }

  horarios = (especialidad) => {
    var rendimiento = this.state.rendimiento;

    var horarioInicio = this.state.tiempoInicio.format("HH:mm");
    var horarioTermino = this.state.tiempoTermino.format("HH:mm");

    var sem = this.state.semana;

    var arrSemana = sem.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];

    var fechaInicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");
    var fechaTerminoSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

    var data = {
      cesfam: this.state.cesfam,
      especialidad: especialidad,
      fechaInicioSemana: fechaInicioSemana,
      fechaTerminoSemana: fechaTerminoSemana,
      usp_id: this.state.usp_id,
    };

    let apiUrl = global.config.modulos.agendamientos + "horario_semanal/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var fechaInicio = fechaInicioSemana + " " + horarioInicio;
      var fechaTermino = fechaInicioSemana + " " + horarioTermino;

      var fecha = fechaInicio;

      var minutosAsumar = parseFloat(rendimiento);

      var arregloRendimiento = [];
      var profesionales = [];
      arregloRendimiento.push(fecha);
      do {
        fecha = moment(fecha).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");
        arregloRendimiento.push(fecha);
      } while (fecha < fechaTermino);

      var estado = res.data.estado;
      if (!estado) {
        var tipo = res.data.tipo;

        var tabla = [];

        var rowsHead = [];
        rowsHead.push(<TableCell className={"tablaRow cellInicio"} key={0}></TableCell>);

        rowsHead.push(
          <TableCell className={"tablaRow cellTermino"}>
            <p style={{ textAlign: "center" }}>Horario </p>
          </TableCell>
        );

        tabla.push(
          <TableHead>
            <TableRow>{rowsHead}</TableRow>
          </TableHead>
        );

        var rows = [];
        for (var j = 0; j < arregloRendimiento.length; j++) {
          var cell = [];

          var fecha = arregloRendimiento[j].split(" ");
          var horasF = fecha[1];
          var horaR = horasF.substring(5, 0);

          cell.push(<TableCell className={"tablaRow"}>{horaR}</TableCell>);

          var motivo = "Sin agenda";
          if (tipo == 1) {
            motivo = "Día feriado";
          }

          cell.push(
            <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
              <div>
                <span>
                  <span className={"contenedorDatos"}>
                    <button
                      title="HORARIO BLOQUEADO"
                      className={"botonesCargaMasiva porSeleccionar"}
                      style={{
                        backgroundColor: "#6a6c6a",
                      }}
                    >
                      <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                        {motivo}
                      </label>
                    </button>
                  </span>
                </span>
              </div>
            </TableCell>
          );
          rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
        }
      } else {
        var horarioMedico = res.data.horarios;

        var tabla = [];

        var rowsHead = [];
        rowsHead.push(<TableCell className={"tablaRow cellInicio"} key={0}></TableCell>);

        for (var i = 0; i < horarioMedico.length; i++) {}

        tabla.push(
          <TableHead>
            <TableRow>{rowsHead}</TableRow>
          </TableHead>
        );

        var rows = [];
        var cell = [];

        cell.push(
          <TableCell className={"tablaRow horasHorario"}>
            <p style={{ textAlign: "center" }}>Semana </p>
          </TableCell>
        );

        for (var f = 0; f < horarioMedico.length; f++) {
          var fecha = moment(fechaInicioSemana);
          for (var i = 0; i <= 6; i++) {
            profesionales.push(horarioMedico[f]["pro_id"]);

            if (i == 2) {
              rowsHead.push(
                <TableCell className={"tablaRow"}>
                  <p style={{ textAlign: "center" }}>{horarioMedico[f]["profesional"]} </p>
                </TableCell>
              );
            } else {
              rowsHead.push(<TableCell className={i == 6 ? "tablaRow finalSemanasHorario" : "tablaRow semanasHorario"}></TableCell>);
            }

            cell.push(
              <TableCell className={i == 6 ? "tablaRow finalSemanasHorario" : "tablaRow semanasHorario"} style={{ textAlign: "center" }}>
                <p style={{ textAlign: "center" }}>{fecha.format("dddd")} </p>
                <p style={{ textAlign: "center" }}>{fecha.format("DD-MM-YYYY")}</p>
              </TableCell>
            );
            fecha = moment(fecha).add(1, "day");
          }
        }
        rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);

        for (var j = 0; j < arregloRendimiento.length; j++) {
          var cell = [];

          var fecha = arregloRendimiento[j].split(" ");
          var horasF = fecha[1];
          var horaR = horasF.substring(5, 0);

          cell.push(
            <TableCell className={"tablaRow horasHorario"} id={horaR}>
              {horaR}
            </TableCell>
          );

          for (var f = 0; f < horarioMedico.length; f++) {
            var fechaInicioS = moment(fechaInicioSemana).format("YYYY-MM-DD");

            for (var i = 0; i <= 6; i++) {
              var fechaHorario = moment(fechaInicioS).format("YYYY-MM-DD");

              var horasMed = horarioMedico[f]["horas"];
              var reuniones = horarioMedico[f]["reuniones"];

              var proId = horarioMedico[f]["pro_id"];
              var Hora = horaR + ":00";
              var fechaHora = fechaHorario + " " + Hora;

              var horarioRendimiento = 0;
              var bloqueoPorReunion = 0;
              var horarioUsado = 0;
              var espe = "";
              var examen = "";
              var cesColor = "#70b789";

              var horId = "";
              var horEspecialidad = "";
              var horAtencion = "";
              var horMotivo = "";
              var horRendimiento = "";
              var horRendimiento2 = "";
              var tipoReunion = "";
              var horEstado = "";
              var horReservada = "";
              var colorEstado = "";
              var ageEstado = "";
              var ageEstadoNombre = "";
              var nombreUsuario = "";
              var rutUsuario = "";
              var hora_ate = "";
              var direccion = "";
              var nombreProfesional = "";
              var cesNombre = "";

              for (var m = 0; m < reuniones.length; m++) {
                var fecha_reunion = reuniones[m]["fecha_reunion"];
                var inicio_reunion = reuniones[m]["hora_inicio"];
                var termino_reunion = reuniones[m]["hora_termino"];

                if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
                  bloqueoPorReunion = 1;
                  tipoReunion = reuniones[m]["reunion"];
                }
              }

              for (var ex = 0; ex < horasMed.length; ex++) {
                horAtencion = moment(horasMed[ex]["hor_atencion"]);
                horRendimiento = horasMed[ex]["hor_rendimiento"];

                var fechaAtencion = horAtencion.format("YYYY-MM-DD");
                var horaAtencion = horAtencion.format("HH:mm:ss");
                horAtencion = horAtencion.format("YYYY-MM-DD HH:mm:ss");

                var minutosAsumar = parseFloat(horRendimiento) - 1;
                var fechaFinal = moment(horAtencion).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");

                if (horAtencion == fechaHora && fechaAtencion == fechaHorario) {
                  horarioUsado = 1;

                  horId = horasMed[ex]["hor_id"];
                  horEspecialidad = horasMed[ex]["hor_especialidad"];
                  nombreProfesional = horasMed[ex]["nombre_profesional"];
                  cesNombre = horasMed[ex]["ces_nombre"];

                  espe = horasMed[ex]["esp_nombre"];
                  examen = horasMed[ex]["examen"];

                  horMotivo = horasMed[ex]["hor_motivo"];
                  horEstado = horasMed[ex]["hor_estado"];
                  horReservada = horasMed[ex]["hor_reservada"];
                  hora_ate = moment(horasMed[ex]["hor_atencion"]);
                  hora_ate = hora_ate.format("HH:mm");
                  horRendimiento2 = horasMed[ex]["hor_rendimiento"];
                  direccion = horasMed[ex]["direccion"];

                  nombreUsuario = "";
                  rutUsuario = "";

                  if (horEstado == 3) {
                    colorEstado = "#A42828";
                    ageEstadoNombre = "Pausada";
                  } else if (horEstado == 0) {
                    colorEstado = "#898483";
                    ageEstadoNombre = "Bloqueada por";
                    nombreUsuario = horMotivo;
                    cesColor = "#898483";
                  } else {
                    if (horReservada == 0) {
                      //Disponible
                      colorEstado = "#0D7D34";
                      ageEstadoNombre = "Disponible";
                    } else if (horReservada == 2) {
                      cesColor = horasMed[ex]["ces_color"];
                      ageEstado = horasMed[ex]["age_estado"];
                      ageEstadoNombre = horasMed[ex]["age_estado_nombre"];
                      nombreUsuario = horasMed[ex]["nombreUsuarioReserva"];
                      rutUsuario = chileanRut.format(horasMed[ex]["rutUsuarioReserva"], true);

                      if (ageEstado == 4) {
                        colorEstado = "#D4D400";
                      } else if (ageEstado == 5) {
                        colorEstado = "#F6A30F";
                      } else if (ageEstado == 7) {
                        colorEstado = "#2A4899";
                      } else if (ageEstado == 9) {
                        colorEstado = "#E51D20";
                      }
                      //Disponible
                    } else if (horReservada == 1) {
                      //En proceso de reserva
                      ageEstadoNombre = "En proceso";
                      colorEstado = "#F7BCC8";
                    }
                  }
                } /*  else if (horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora) {
                  horarioRendimiento = 1;

                  horId = horasMed[ex]["hor_id"];
                  horEspecialidad = horasMed[ex]["hor_especialidad"];
                  nombreProfesional = horasMed[ex]["nombre_profesional"];
                  cesNombre = horasMed[ex]["ces_nombre"];

                  espe = horasMed[ex]["esp_nombre"];
                  examen = horasMed[ex]["examen"];

                  horMotivo = horasMed[ex]["hor_motivo"];
                  horEstado = horasMed[ex]["hor_estado"];
                  horReservada = horasMed[ex]["hor_reservada"];
                  hora_ate = moment(horasMed[ex]["hor_atencion"]);
                  hora_ate = hora_ate.format("HH:mm");
                  horRendimiento2 = horasMed[ex]["hor_rendimiento"];
                  direccion = horasMed[ex]["direccion"];

                  nombreUsuario = "";
                  rutUsuario = "";

                  if (horEstado == 3) {
                    colorEstado = "#A42828";
                    ageEstadoNombre = "Pausada";
                  } else if (horEstado == 0) {
                    colorEstado = "#898483";
                    ageEstadoNombre = "Bloqueada por";
                    nombreUsuario = horMotivo;
                    cesColor = "#898483";
                  } else {
                    if (horReservada == 0) {
                      //Disponible
                      colorEstado = "#0D7D34";
                      ageEstadoNombre = "Disponible";
                    } else if (horReservada == 2) {
                      cesColor = horasMed[ex]["ces_color"];

                      ageEstado = horasMed[ex]["age_estado"];
                      ageEstadoNombre = horasMed[ex]["age_estado_nombre"];
                      nombreUsuario = horasMed[ex]["nombreUsuarioReserva"];
                      rutUsuario = chileanRut.format(horasMed[ex]["rutUsuarioReserva"], true);

                      if (ageEstado == 4) {
                        colorEstado = "#D4D400";
                      } else if (ageEstado == 5) {
                        colorEstado = "#F6A30F";
                      } else if (ageEstado == 7) {
                        colorEstado = "#2A4899";
                      } else if (ageEstado == 9) {
                        colorEstado = "#E51D20";
                      }
                      //Disponible
                    } else if (horReservada == 1) {
                      //En proceso de reserva
                      ageEstadoNombre = "En proceso";
                      colorEstado = "#F7BCC8";
                    }
                  }
                } */
              }

              if (bloqueoPorReunion == 1) {
                cell.push(
                  <TableCell className={i == 6 ? "tablaRow horarioBloqueado finalSemanasHorarioCell" : "tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          <div id="tablahorarioProfesional" className="cargarHorario">
                            <Table className="bordeTabla">
                              <TableContainer>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className={"MuiTableCell-head tablaRow cellInicio"} key={0}>
                                      Bloqueado por
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{tipoReunion}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </TableContainer>
                            </Table>
                          </div>
                        }
                      >
                        <button
                          title={"REUNIÓN " + tipoReunion}
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label className={"textoBotonesCarga"} style={{ color: "#adadad" }}>
                            Bloqueado por<br></br>
                            {tipoReunion}
                          </label>
                        </button>
                      </HtmlTooltip>
                    </div>
                  </TableCell>
                );
              } else if (horarioUsado == 1) {
                cell.push(
                  <TableCell className={i == 6 ? "tablaRow horarioBloqueado finalSemanasHorarioCell" : "tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          horReservada == 2 ? (
                            <div id="tablahorarioProfesional" className="cargarHorario">
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} style={{ fontSize: "15px", padding: 20 }}>
                                    <label>
                                      <strong>Nombre paciente:</strong>
                                    </label>
                                    <br></br>
                                    <br></br>
                                    <label>{nombreUsuario}</label>
                                    <br></br>
                                    <br></br>

                                    <label>
                                      <strong>Rut:</strong>
                                    </label>
                                    <br></br>
                                    <br></br>

                                    <label>{rutUsuario}</label>
                                    <br></br>
                                    <br></br>

                                    <label>
                                      <strong>Tipo de atención:</strong>
                                    </label>
                                    <br></br>
                                    <br></br>

                                    <label>{espe}</label>
                                    <br></br>
                                    <br></br>

                                    <label>
                                      <strong> Centro de salud:</strong>
                                    </label>
                                    <br></br>
                                    <br></br>

                                    <label>Cesfam {cesNombre}</label>
                                    <br></br>
                                    <br></br>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            <div id="tablahorarioProfesional" className="cargarHorario">
                              <Table className="bordeTabla">
                                <TableContainer>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className={"MuiTableCell-head tablaRow cellInicio"} key={0}>
                                        Hora
                                      </TableCell>
                                      <TableCell className={"MuiTableCell-head tablaRow cellTermino"} key={1}>
                                        Estado
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>{hora_ate}</TableCell>
                                      <TableCell
                                        style={{
                                          backgroundColor: colorEstado,
                                          color: "#fff",
                                        }}
                                      >
                                        {ageEstadoNombre}
                                        <br></br>
                                        {nombreUsuario}
                                        <br></br>
                                        {direccion}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </TableContainer>
                              </Table>
                            </div>
                          )
                        }
                      >
                        <button
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: cesColor,
                          }}
                          id={horAtencion + "cont" + proId}
                          onClick={horReservada == 0 ? this.agendarHora.bind(this, horId, espe, nombreProfesional) : null}
                        >
                          <label
                            className="estadoHora"
                            style={{
                              backgroundColor: colorEstado,
                            }}
                          ></label>
                          <label
                            style={{
                              color: "#fff",
                            }}
                          >
                            {espe}
                            <br></br>
                            {hora_ate}
                            <br></br>
                            {horRendimiento2}
                          </label>
                        </button>
                      </HtmlTooltip>
                    </div>
                  </TableCell>
                );
              } else if (horarioRendimiento == 1) {
                cell.push(
                  <TableCell
                    className={i == 6 ? "tablaRow horarioBloqueado finalSemanasHorarioCell" : "tablaRow horarioBloqueado"}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span className={"contenedorDatos"}>
                      <button
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: cesColor,
                        }}
                        id={horAtencion + "cont" + proId}
                        onClick={this.activar_horas.bind(this, Hora, hora_ate, arregloRendimiento, proId, profesionales)}
                      >
                        <label
                          style={{
                            color: "#fff",
                          }}
                        >
                          {espe}
                          <br></br>
                          {hora_ate}
                          <br></br>({horRendimiento2})<br></br>
                          Rendimiento hora anterior
                        </label>
                      </button>
                    </span>
                  </TableCell>
                );
              } else {
                cell.push(
                  <TableCell className={i == 6 ? "tablaRow horarioBloqueado finalSemanasHorarioCell" : "tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                    <div>
                      <span>
                        <span className={"contenedorDatos"}></span>
                      </span>
                    </div>
                  </TableCell>
                );
              }

              fechaInicioS = moment(fechaInicioS).add(1, "day").format("YYYY-MM-DD");
            }
          }
          rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
        }
      }

      tabla.push(<TableBody>{rows}</TableBody>);

      this.setState({
        tabla: (
          <Table stickyHeader className="bordeTabla" size="small" aria-label="a dense table">
            {tabla}
          </Table>
        ),
      });
    });
  };

  activar_horas = (horaFinal, hora_ate, arregloRendimiento, proId, profesionales) => {
    for (var j = 0; j < arregloRendimiento.length; j++) {
      var fechaP = arregloRendimiento[j].split(" ");
      var horasFP = fechaP[1];
      var horaRP = horasFP.substring(5, 0);

      var activarColor = 0;
      var activarColorPro = 0;

      for (var x = 0; x < arregloRendimiento.length; x++) {
        if (horaRP == hora_ate) {
          activarColor = 1;
        } else if (hora_ate < horaRP && hora_ate < horaFinal && horaFinal >= horaRP) {
          activarColor = 1;
        }
      }

      if (activarColor == 1) {
        document.getElementById(horaRP).style.backgroundColor = "#4AFFFF";
        for (var p = 0; p < profesionales.length; p++) {
          if (proId == profesionales[p]) {
            document.getElementById(horaRP + "cont" + proId).style.boxShadow = "0 0 17px 3px #4AFFFF";
          } else {
            var prof = document.getElementById(horaRP + "cont" + profesionales[p]);
            if (prof != undefined) {
              prof.style.boxShadow = "none";
            }
          }
        }
      } else {
        document.getElementById(horaRP).style.backgroundColor = "";
        for (var p = 0; p < profesionales.length; p++) {
          var prof = document.getElementById(horaRP + "cont" + profesionales[p]);
          if (prof != undefined) {
            prof.style.boxShadow = "none";
          }
        }
      }
    }
  };

  cambiarValoresHora = (valor, hora) => {
    if (this.state.especialidad == "0") {
      NotificationManager.warning("Selecciona la especialidad");
      return;
    }

    if (valor != null) {
      if (hora == "inicio") {
        if (valor > this.state.tiempoTermino) {
          NotificationManager.warning("La hora de inicio no puede ser mayor a la de término");
        } else {
          this.setState(
            {
              tiempoInicio: valor,
              tabla: "",
            },
            () => {
              this.horarios(this.state.especialidad);
            }
          );
        }
      }
      if (hora == "termino") {
        if (valor < this.state.tiempoInicio) {
          NotificationManager.warning("La hora de término no puede ser menor a la de inicio");
        } else {
          this.setState(
            {
              tiempoTermino: valor,
              tabla: "",
            },
            () => {
              this.horarios(this.state.especialidad);
            }
          );
        }
      }
    }
  };

  agendarHora = (horId, especialidad, nombreProfesional) => {
    this.setState({
      agendarHora: true,
      horarioAgendar: horId,
      nombreEspecialidad: especialidad,
      nombreProfesional: nombreProfesional,
    });
  };

  ocultar_agendar = () => {
    this.setState({ agendarHora: false }, () => {
      this.horarios(this.state.especialidad);
    });
  };

  render() {
    const { tiempoInicio, tiempoTermino } = this.state;

    const traerDatos = (res) => {
      this.setState(
        {
          especialidad: res.esp_id,
          tabla: "",
        },
        () => {
          this.traerRendimientos(this.state.especialidad);
        }
      );
    };

    const cambiarSemana = (event) => {
      var semana = event.target.value;
      if (semana != "") {
        this.setState(
          {
            semana: semana,
            tabla: "",
          },
          () => {
            this.horarios(this.state.especialidad);
          }
        );
      }
    };

    return (
      <div className="middle-area" style={{ width: "85%" }}>
        <div className="middle-content">
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                  <div style={{ width: "48%", float: "left", marginTop: "16px" }}>
                    <p>
                      <label
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: 12,
                        }}
                      >
                        Hora inicio
                      </label>
                    </p>
                    <TimePicker minuteStep={5} showSecond={false} name="tiempoInicio" id="tiempoInicio" value={tiempoInicio} onChange={(e) => this.cambiarValoresHora(e, "inicio")} />
                  </div>
                  <div style={{ width: "48%", float: "left", marginTop: "16px" }}>
                    <p>
                      <label
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: 12,
                        }}
                      >
                        Hora Termino
                      </label>
                    </p>
                    <TimePicker minuteStep={5} showSecond={false} name="tiempoTermino" id="tiempoTermino" value={tiempoTermino} onChange={(e) => this.cambiarValoresHora(e, "termino")} />
                  </div>
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2} xl={1} style={{ marginTop: "16px", maxWidth: "100%", marginRight: "0vw" }}>
                  <InputLabel>Semana del horario</InputLabel>
                  <TextField id="semana" name="semana" type="week" value={this.state.semana} onChange={cambiarSemana} />
                </Grid>

                <Grid item xs={12} sm={3} md={3} style={{ marginTop: "16px" }}>
                  <InputLabel className={crearTotem.textosTitulos}>Tipo de atención</InputLabel>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="combo-box-comuna"
                    options={Object.values(this.state.especialidades)}
                    getOptionLabel={(option) => option.esp_nombre}
                    onChange={function (event, res) {
                      if (res != null) {
                        traerDatos(res);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Seleccionar"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <br></br>

            <Grid item xs={12} sm={12} md={12}>
              <div id="tablahorarioProfesional" className="cargarHorario">
                <TableContainer style={{ maxHeight: 600 }}>{this.state.tabla}</TableContainer>
              </div>
            </Grid>
          </React.Fragment>
        </div>
        {this.state.agendarHora ? <AgendarHora ocultar={this.ocultar_agendar} horario={this.state.horarioAgendar} nombreEspecialidad={this.state.nombreEspecialidad} nombreProfesional={this.state.nombreProfesional}></AgendarHora> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Horarios);
