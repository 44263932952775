import socketio from "socket.io-client";

const REACT_APP_URL_SERVER_ = global.config.modulos.REACT_APP_URL_SERVER_;

export const socketChat = socketio(REACT_APP_URL_SERVER_, {
  path: "/socket_chat_app/",
});

/* export const socketSesion = socketio(REACT_APP_URL_SERVER_, {
  path: "/socket_session_activa/",
}); */

export const socketSesion = socketio(REACT_APP_URL_SERVER_, {
  path: "/socket_session/",
});

export const socketNotificacion = socketio(REACT_APP_URL_SERVER_, {
  path: "/socket_notificaciones/",
});

export const socketTotem = socketio(REACT_APP_URL_SERVER_, {
  path: "/socket_totem/",
});

export const socketVideollamada = socketio(REACT_APP_URL_SERVER_, {
  path: "/socket_video_llamada/",
});

export const socketRTC = socketio(REACT_APP_URL_SERVER_, {
  path: "/webRTCMULTI/",
});
