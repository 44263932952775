import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { debounce } from "lodash";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AgregarDifusion from "../Difusiones/AgregarDifusion";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default class AgregarMeta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfamId: store.getState().cesfam,

      open: false,

      nombre: "",
      meta: "",

      periodoOpciones: [
        { value: "1", label: "Mensual", name: "periodoSelect" },
        { value: "2", label: "Trimestral", name: "periodoSelect" },
        { value: "3", label: "Semestral", name: "periodoSelect" },
        { value: "4", label: "Anual", name: "periodoSelect" },
      ],
      periodoSelect: null,

      areaOpciones: [
        { value: "1", label: "Por Comuna", name: "areaSelect", toggle: "comuna" },
        { value: "2", label: "Por Cesfam", name: "areaSelect", toggle: "cesfam" },
        { value: "3", label: "Por Sector", name: "areaSelect", toggle: "sector" },
      ],
      areaSelect: null,

      comunaSelect: null,

      cesfams: [],
      cesfamSelect: null,

      sectores: [],
      sectorSelect: null,

      especialidadOpciones: [],
      especialidadSelect: null,

      atencionOpciones: [],
      atencionSelect: [],

      difusionModOpciones: [
        { value: "0", label: "No Usar", name: "difusionModSelect" },
        { value: "1", label: "Nueva", name: "difusionModSelect" },
        { value: "2", label: "Existente", name: "difusionModSelect" },
      ],
      difusionModSelect: null,
      difusionOpciones: [],
      difusionSelect: null,

      sector: false,
      comuna: true, //default
      cesfam: false,

      alertaDif: false,
      nuevaDifusion: false,

      errorValid: false,
    };

    this.cerrarNuevaDifusion = this.cerrarNuevaDifusion.bind(this);
    this.cerrarAlertaDifusion = this.cerrarAlertaDifusion.bind(this);
  }

  componentDidMount = async () => {
    this.setState({ open: false }, async () => {
      this.obtenerListaCesfams();
      if (this.props.editar) {
        this.cargarMetaEditar();
      } else {
        var esp = await this.obtenerListaEspecialidades();
        this.setState({ open: true });
      }
    });
  };

  abrirAlertaDifusion() {
    this.setState({ alertaDif: true });
  }

  cerrarAlertaDifusion() {
    this.setState({ alertaDif: false });
  }

  nuevaDifusion = () => {
    this.setState({ nuevaDifusion: true });
  };

  cerrarNuevaDifusion(idnew) {
    if (idnew) {
      this.setState({ difusionSelect: { id: idnew }, nuevaDifusion: false }, () => {
        this.guardarMeta();
      });
    } else {
      this.setState({ nuevaDifusion: false });
    }
  }

  salir = () => {
    this.setState({
      open: false,
      difusionDetalle: false,
    });
    this.props.ocultar();
  };

  obtenerListaCesfams = () => {
    let apiUrl = global.config.modulos.metas + "lista_cesfams_metas/?cesfam=" + this.state.cesfamId;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let ces = [];
      let val = 0;
      this.setState({ comunaSelect: res.data.comuna });
      res.data.cesfams.map((item) => {
        ces.push({
          label: item.ces_nombre,
          val: val,
          id: item.ces_id,
          name: "cesfamSelect",
        });
        val++;
      });
      this.setState({ cesfams: ces }, () => {
        this.obtenerListaDifusiones();
      });
    });
  };

  obtenerListaSectores = async () => {
    let apiUrl = global.config.modulos.metas + "lista_sectores_metas/?cesfam=" + this.state.cesfamSelect.id;
    var res = await axios(apiUrl, { headers: authHeader() });
    let sec = [];
    let val = 0;
    res.data.sectores.map((item) => {
      sec.push({
        label: item.sec_nombre,
        val: val,
        id: item.sec_id,
        name: "sectorSelect",
      });
      val++;
    });
    this.setState({ sectores: sec });
  };

  obtenerListaEspecialidades = async () => {
    let ces = this.state.comuna ? this.state.cesfamId : this.state.cesfamSelect.id;
    let apiUrl = global.config.modulos.metas + "lista_especialidades_metas/?cesfam=" + ces;
    var res = await axios(apiUrl, { headers: authHeader() });
    let esp = [];
    let val = 0;
    res.data.especialidades.map((item) => {
      esp.push({
        label: item.esp_nombre,
        val: val,
        id: item.esp_id,
        name: "especialidadSelect",
        sigla: item.esp_sigla,
      });
      val++;
    });
    this.setState({ especialidadOpciones: esp });
  };

  obtenerListaAtenciones = async () => {
    let apiUrl = global.config.modulos.especialidades + "?cesfam=" + this.state.cesfamId + "&tipo=2&familia=" + this.state.especialidadSelect.sigla;
    var res = await axios(apiUrl, { headers: authHeader() });
    let ate = [];
    let val = 0;
    res.data.datos.map((item) => {
      ate.push({
        label: item.esp_nombre,
        val: val,
        id: item.esp_id,
        name: "atencionSelect",
      });
      val++;
    });
    this.setState({ atencionOpciones: ate });
  };

  obtenerListaDifusiones() {
    let apiUrl = this.state.comuna ? global.config.modulos.difusion + "por_comuna/" + this.state.comunaSelect : global.config.modulos.difusion + "por_cesfam/" + this.state.cesfamSelect.id + "?opcion=0";

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let dif = [];
      let val = 0;
      res.data.datos.map((item) => {
        dif.push({
          label: item.dic_nombre,
          val: val,
          id: item.dic_id,
          name: "difusionSelect",
        });
        this.setState({ difusionOpciones: dif });
      });
    });
  }

  cargarMetaEditar = async () => {
    if (this.props.editar) {
      const met_id = this.props.met_edit.met_id;
      let apiUrl = global.config.modulos.metas + "meta_por_id/" + met_id + "?cesfam=" + this.state.cesfamId;

      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        let areaSelect = this.state.areaOpciones.filter((item) => item.value === res.data.meta.met_area.toString())[0];
        let periodoSelect = this.state.periodoOpciones.filter((item) => item.value === res.data.meta.met_periodo.toString())[0];

        this.setState({ sector: res.data.meta.met_sector ? true : false, cesfam: res.data.meta.met_cesfam ? true : false, areaSelect: areaSelect, periodoSelect: periodoSelect }, async () => {
          let cesfamSelect = this.state.cesfam || this.state.sector ? this.state.cesfams.filter((item) => item.id === res.data.meta.met_cesfam)[0] : null;
          this.setState({ cesfamSelect: cesfamSelect }, async () => {
            var esp = await this.obtenerListaEspecialidades();
            let sectorSelect;
            if (this.state.sector) {
              var sec = await this.obtenerListaSectores();
              sectorSelect = this.state.sectores.filter((item) => item.id === res.data.meta.met_sector)[0];
            }
            let especialidadSelect = this.state.especialidadOpciones.filter((item) => item.id === res.data.especialidad.esp_id)[0];
            let difusionModSelect = res.data.meta.met_difusion ? this.state.difusionModOpciones.filter((item) => item.value === "2")[0] : this.state.difusionModOpciones.filter((item) => item.value === "0")[0];

            this.setState({ difusionModSelect: difusionModSelect, especialidadSelect: especialidadSelect, sectorSelect: sectorSelect }, async () => {
              var ate = await this.obtenerListaAtenciones();
              let atencionSelect = [];
              let atenciones = res.data.atencion;
              let difusionSelect = res.data.meta.met_difusion ? { id: res.data.meta.met_difusion, label: res.data.difusion } : null;

              atenciones.map((ate, index) => {
                let atencion = this.state.atencionOpciones.filter((item) => item.id === parseInt(ate.id))[0];
                atencionSelect.push(atencion);
              });

              this.setState(
                {
                  nombre: res.data.meta.met_nombre,
                  comunaSelect: res.data.meta.met_comuna,
                  meta: res.data.meta.met_meta,
                  atencionSelect: atencionSelect,
                  difusionSelect: difusionSelect,
                },
                () => {
                  this.setState({ open: true });
                }
              );
            });
          });
        });
      });
    }
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }
    const soloNumeros = ["meta", "repetir", "edadMin", "edadMax"];
    if (soloNumeros.includes(name)) {
      value = value.replace(/[^0-9]/g, "");
    }

    this.setState({ [name]: value });
  };

  validaciones(modal) {
    let formErrors = {};
    let validado = true;

    const { sector, comuna, cesfam } = this.state;

    const { nombre, meta, periodoSelect, areaSelect, cesfamSelect, sectorSelect, especialidadSelect, atencionSelect, difusionModSelect, difusionSelect } = this.state;

    if (nombre === "") {
      formErrors["nombreErr"] = "Debe introducir el nombre de la meta";
      validado = false;
    }

    if (meta === "") {
      formErrors["metaErr"] = "Debe introducir el número de la meta";
      validado = false;
    }

    if (!periodoSelect) {
      formErrors["periodoErr"] = "Debe seleccionar un periodo";
      validado = false;
    }

    if (!areaSelect) {
      formErrors["areaErr"] = "Debe seleccionar un área";
      validado = false;
    }

    if (cesfam && !cesfamSelect) {
      formErrors["cesfamErr"] = "Debe seleccionar un cesfam";
      validado = false;
    }

    if (sector && !cesfamSelect) {
      formErrors["cesfamErr"] = "Debe seleccionar un cesfam";
      validado = false;
    }

    if (sector && !sectorSelect) {
      formErrors["sectorErr"] = "Debe seleccionar un cesfam";
      validado = false;
    }

    if (!especialidadSelect) {
      formErrors["especialidadErr"] = "Debe seleccionar un cesfam";
      validado = false;
    }

    if (atencionSelect.length < 1) {
      formErrors["atencionErr"] = "Debe seleccionar al menos una atención";
      validado = false;
    }

    if (!difusionModSelect) {
      formErrors["difModErr"] = "Debe seleccionar una opcion de difusión";
      validado = false;
    }

    if (difusionModSelect === "2" && !difusionSelect) {
      formErrors["difErr"] = "Debe seleccionar una difusión";
      validado = false;
    }

    this.setState({ errorValid: formErrors });
    return validado;
  }

  cambiarSeleccion(event, value) {
    const name = value.name;
    this.setState({ [name]: value }, () => {
      switch (name) {
        case "areaSelect":
          const toggle = value.toggle;
          this.setState({
            comuna: false,
            sector: false,
            cesfam: false,
            [toggle]: true,
          });
          break;

        case "cesfamSelect":
          this.setState({ sectores: [], sectorSelect: null, especialidadSelect: null }, () => {
            this.obtenerListaSectores();
            this.obtenerListaEspecialidades();
            this.obtenerListaDifusiones();
          });
          break;

        case "especialidadSelect":
          this.setState({ atencionSelect: null, atencionSelect: [] }, () => {
            this.obtenerListaAtenciones();
          });
      }
    });
  }

  cambiarMultiSeleccion(event, value) {
    let noRepetirId = [...new Map(value.map((item) => [item["id"], item])).values()];
    this.setState({ atencionSelect: noRepetirId });
  }

  validarMeta = (e) => {
    if (this.validaciones()) {
      if (this.state.difusionModSelect.value === "1") {
        this.abrirAlertaDifusion();
      } else {
        this.props.editar ? this.actualizarMeta() : this.guardarMeta();
      }
    } else {
      console.log("no validados");
    }
  };

  guardarMeta() {
    var data = {
      meta: {
        met_nombre: this.state.nombre,
        met_cesfam: this.state.cesfam || this.state.sector ? this.state.cesfamSelect.id : null,
        met_sector: this.state.sector ? this.state.sectorSelect.id : null,
        met_comuna: this.state.comunaSelect,
        met_meta: this.state.meta,
        met_periodo: this.state.periodoSelect.value,
        met_area: this.state.areaSelect.value,
        met_estado: 1,
        met_alerta: null,
        met_difusion: this.state.difusionModSelect.value === "0" ? null : this.state.difusionSelect.id,
        met_usp: this.state.uspId,
        met_fecha_registro: moment().format(),
      },
      atencion: this.state.atencionSelect,
    };
    let apiUrl = global.config.modulos.metas + "crear_meta/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      NotificationManager.info("Meta creada correctamente");
      this.salir();
    });
  }

  actualizarMeta = (e) => {
    var data = {
      meta: {
        met_nombre: this.state.nombre,
        met_cesfam: this.state.cesfam || this.state.sector ? this.state.cesfamSelect.id : null,
        met_sector: this.state.sector ? this.state.sectorSelect.id : null,
        met_comuna: this.state.comunaSelect,
        met_meta: this.state.meta,
        met_periodo: this.state.periodoSelect.value,
        met_area: this.state.areaSelect.value,
        met_estado: 1,
        met_alerta: null,
        met_difusion: this.state.difusionModSelect.value === "0" ? null : this.state.difusionSelect.id,
      },
      atencion: this.state.atencionSelect,
    };
    let apiUrl = global.config.modulos.metas + "editar_meta/" + this.props.met_edit.met_id;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      NotificationManager.info("Meta actualizada correctamente");
      this.salir();
    });
  };

  render() {
    const { nombreErr, metaErr, periodoErr, areaErr, cesfamErr, sectorErr, especialidadErr, atencionErr, difModErr, difErr } = this.state.errorValid;

    return (
      <>
        <Dialog open={this.state.open} onClose={this.salir} className="modificarUsuario">
          <DialogContent>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>{this.props.editar ? <>Editar meta</> : <>Agregar meta</>}</h1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={4} style={{ marginTop: 30, marginLeft: 10 }}>
                  <InputLabel>Nombre Meta</InputLabel>
                  <input type="text" name="nombre" value={this.state.nombre} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {nombreErr ? <div style={{ color: "red", paddingBottom: 10 }}>{nombreErr}</div> : null}
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 30 }}>
                  <InputLabel>Meta</InputLabel>
                  <input type="text" maxLength={3} name="meta" value={this.state.meta} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {metaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{metaErr}</div> : null}
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 30 }}>
                  <Autocomplete name="periodo" disablePortal disableClearable id="periodoSelect" options={this.state.periodoOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.periodoSelect} renderInput={(params) => <TextField {...params} label="Periodo" />}></Autocomplete>
                  {periodoErr ? <div style={{ color: "red", paddingBottom: 10 }}>{periodoErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={4} style={{ marginTop: 20, marginLeft: 10 }}>
                  <Autocomplete name="area" disablePortal disableClearable id="areaSelect" options={this.state.areaOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.areaSelect} renderInput={(params) => <TextField {...params} label="Área" />}></Autocomplete>
                  {areaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{areaErr}</div> : null}
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 20 }}>
                  <Autocomplete name="cesfam" disablePortal disabled={this.state.cesfam || this.state.sector ? false : true} disableClearable id="cesfamSelect" options={this.state.cesfams} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.cesfamSelect} renderInput={(params) => <TextField {...params} label="Cesfam" />}></Autocomplete>
                  {cesfamErr ? <div style={{ color: "red", paddingBottom: 10 }}>{cesfamErr}</div> : null}
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 20 }}>
                  <Autocomplete name="sector" disabled={this.state.sector ? false : true} disablePortal disableClearable id="sectorSelect" options={this.state.sectores} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.sectorSelect} renderInput={(params) => <TextField {...params} label="Sector" />}></Autocomplete>
                  {sectorErr ? <div style={{ color: "red", paddingBottom: 10 }}>{sectorErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={3} style={{ marginLeft: 10, marginTop: 20 }}>
                  <Autocomplete name="especialidad" disableClearable id="especialidadSelect" options={this.state.especialidadOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.especialidadSelect} renderInput={(params) => <TextField {...params} label="Especialidad" />}></Autocomplete>
                  {especialidadErr ? <div style={{ color: "red", paddingBottom: 10 }}>{especialidadErr}</div> : null}
                </GridItem>
                <GridItem xs={8} style={{ marginTop: 20 }}>
                  <Autocomplete
                    name="atenciones"
                    disableClearable
                    multiple
                    id="combo-box-atenciones"
                    options={this.state.atencionOpciones}
                    getOptionLabel={(option) => option.label}
                    onChange={this.cambiarMultiSeleccion.bind(this)}
                    limitTags={1}
                    noOptionsText="Debe seleccionar una especialidad primero"
                    defaultValue={this.state.atencionSelect}
                    value={this.state.atencionSelect}
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.label}
                      </React.Fragment>
                    )}
                    renderInput={(params) => <TextField {...params} label="Atenciones" placeholder="Seleccione un tipo de atencion" />}
                  ></Autocomplete>
                  {atencionErr ? <div style={{ color: "red", paddingBottom: 10 }}>{atencionErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={5} style={{ marginLeft: 10, marginTop: 20 }}>
                  <Autocomplete name="difusionMod" disableClearable ariaLabel="difusionModSelect" id="difusionModSelect" options={this.state.difusionModOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.difusionModSelect} renderInput={(params) => <TextField {...params} label="Modo difusión" />}></Autocomplete>
                  {difModErr ? <div style={{ color: "red", paddingBottom: 10 }}>{difModErr}</div> : null}
                </GridItem>
                <GridItem xs={5} style={{ marginLeft: 10, marginTop: 20 }}>
                  <Autocomplete name="difusion" disableClearable disabled={this.state.difusionModSelect ? (this.state.difusionModSelect.value === "2" ? false : true) : true} ariaLabel="difusionSelect" id="difusionSelect" options={this.state.difusionOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.difusionSelect} renderInput={(params) => <TextField {...params} label="Difusion" />}></Autocomplete>
                  {difErr ? <div style={{ color: "red", paddingBottom: 10 }}>{difErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                  className="fondoVerde"
                  onClick={this.validarMeta.bind(this)}
                  /* onClick={this.enviarDifusion} */
                >
                  Confirmar
                </Button>
                <Button style={{ marginLeft: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.salir}>
                  Cancelar
                </Button>
              </GridItem>
            </GridItem>
          </DialogContent>
        </Dialog>

        {this.state.alertaDif ? (
          <Dialog open={this.state.alertaDif} onClose={this.cerrarAlertaDifusion.bind(this)} className="modificarUsuario">
            <DialogContent>
              <GridContainer className="">
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                      <h1>¡Atención!</h1>
                      <h2 style={{ fontWeight: "normal" }}>A continuación deberas configurar la difusión para esta meta.</h2>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                    <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8, color: "white" }} className="botonGeneral" onClick={this.nuevaDifusion.bind(this)}>
                      Continuar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </DialogContent>
          </Dialog>
        ) : null}

        {this.state.nuevaDifusion ? <AgregarDifusion ocultar={this.cerrarNuevaDifusion} editar={false} clon={false} dif_edit={null} /> : null}
      </>
    );
  }
}
