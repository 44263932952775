import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";
import "moment/locale/es";

export default class ConfirmarUsuarioTratamiento extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    this.state = {
      usp_id: store.getState().usp,
      cesfamus: store.getState().cesfam,
      //herramientas: store.getState().herramientas,
      //cliente: store.getState().cliente,
      open: true,
      //canal: 5,
      //rutExtranjero: false,
      //agregarndoUsuario: false,
      //ordenarPrioridad: null,
      pagina: 0,
      filas: 10,
      total: 0,
      id_usuario: props.id_usuario,
      tratamientos: [],
      tipo_lista: props.tipo_lista,
      mostrar_fecha: 0,
      fecha_inicio: moment(props.fecha_cita, "DD-MM-YYYY").format("YYYY-MM-DD"),
      tratamiento: props.programa_cita,
      cesfam_admin: store.getState().cesfam,
      estado_agenda: 0,
    };
    console.log(this.state.id_usuario);
    console.log(this.state.fecha_inicio);
  }
  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam_admin,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };
  componentDidMount() {
    this.obtenerTratamientos();
  }

  fecha_inicio = (e) => {
    const fecha_inicio = e.target.value;
    this.setState({ fecha_inicio: fecha_inicio });
    //console.log(this.state.fecha_inicio);
  };
  estado_cita = (e) => {
    const estado_agenda = e.target.value;
    this.setState({
      estado_agenda: estado_agenda,
      mostrar_fecha: 1,
    });
    //console.log(this.state.fecha_inicio);
  };
  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });
    //console.log(this.state.tratamiento);
  };

  agregarTratamiento = () => {
    if (this.state.tipo_lista === 2) {
      if (this.state.estado_agenda == null || this.state.estado_agenda == 0) {
        NotificationManager.error("Debe seleccionar el estado de la agenda");
        return;
      }
    }

    if (this.state.fecha_inicio == null) {
      NotificationManager.error("Debe ingresar una fecha de inicio");
      return;
    }

    if (this.state.tratamiento == null) {
      NotificationManager.error("Debe seleccionar un tratamiento");
      return;
    }

    axios
      .patch(
        global.config.modulos.listaEspera + "agregar_usuario_tratamiento/",
        {
          id_usuario: this.state.id_usuario,
          fecha_inicio: this.state.fecha_inicio,
          tratamiento: this.state.tratamiento,
          usp_id: this.state.usp_id,
          tipo_lista: this.state.tipo_lista,
          estado_agenda: this.state.estado_agenda,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha confirmado el tratamiento al usuario");
          this.salir();
          this.setState({
            fecha_inicio: null,
            tratamiento: null,
          });
        } else {
          NotificationManager.error("No se ha podido agregar el tratamiento al usuario");
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "700px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <h2>Confirmar Tratamiento</h2>
            </div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.tipo_lista === 2 ? (
                  <div style={{ display: "flex", marginBottom: "3%" }}>
                    <div style={{ marginBottom: "5px" }}>
                      <h4 style={{ marginBottom: "5px" }}></h4>
                    </div>
                    <div style={{ width: "100%" }}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Confirme el estado de la cita.</InputLabel>
                        <Select name="tratamiento" value={this.state.estado_agenda} onChange={this.estado_cita}>
                          <MenuItem value={null}>Seleccione estado</MenuItem>
                          <MenuItem value={"1"}>Asistida</MenuItem>
                          <MenuItem value={"2"}>Inasistente</MenuItem>
                          <MenuItem value={"3"}>Suspendida</MenuItem>
                        </Select>
                      </FormControl>

                      {this.state.mostrar_fecha === 1 ? (
                        <div style={{ marginTop: "10px" }}>
                          <h5 style={{ marginBottom: "5px" }}>A continuación puedes agregar una nueva fecha de citación:</h5>
                          <FormControl style={{ width: "45%", marginRight: "4%" }}>
                            <InputLabel id="demo-simple-select-label">fecha tratamiento</InputLabel>
                            <Input type="date" name="fecha inicio" value={this.state.fecha_inicio} onChange={this.fecha_inicio} />
                          </FormControl>
                          <FormControl style={{ width: "50%" }}>
                            <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
                            <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
                              <MenuItem value={null}>Seleccione Tratamiento</MenuItem>
                              {this.state.tratamientos.map((tratamiento, i) => {
                                return (
                                  <MenuItem key={i} value={tratamiento.lt_id}>
                                    {tratamiento.lt_nombre}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div style={{ display: "flex", marginBottom: "3%" }}>
                    <div style={{ marginBottom: "5px" }}>
                      <h4 style={{ marginBottom: "5px" }}>Seguro que desea confirmar la cita con los siguientes datos?</h4>
                      <FormControl style={{ width: "30%", marginRight: "4%" }}>
                        <InputLabel id="demo-simple-select-label">fecha ingreso</InputLabel>
                        <Input type="date" name="fecha inicio" value={this.state.fecha_inicio} onChange={this.fecha_inicio} />
                      </FormControl>
                      <FormControl style={{ width: "50%" }}>
                        <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
                        <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
                          <MenuItem value={null}>Seleccione Tratamiento</MenuItem>
                          {this.state.tratamientos.map((tratamiento, i) => {
                            return (
                              <MenuItem key={i} value={tratamiento.lt_id}>
                                {tratamiento.lt_nombre}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    marginLeft: "65%",
                    marginTop: "30px",
                  }}
                >
                  <Buttons
                    onClick={() => this.agregarTratamiento()}
                    //className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      backgroundColor: "#00ac77",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Guardar
                  </Buttons>
                  <Buttons
                    onClick={this.salir}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Cancelar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
