import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import { store } from "store/index";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import { Grid } from "@material-ui/core";
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";
import axios from "axios";

//JS
import TablaEnProceso from "./EnProceso";
import TablaIngresadas from "./Ingresadas";
// IMG
import solicitudIngresada from "assets/img/solicitud_trabajo/iconos/solicitudes_ingresadas.svg";
import solicitudProceso from "assets/img/solicitud_trabajo/iconos/solicitudes_proceso.svg";
import solicitudProceso_len from "assets/img/solicitud_trabajo/iconos/len_proceso.svg";
import solicitudIngresada_len from "assets/img/solicitud_trabajo/iconos/len_ingresadas.svg";

export default class Desarrollo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prio: 0,
      seleccion: 1,
      pasoSolicitud: 1,
      abrirModal: false,
      dataSolicitud: null,
      tituloModalSolicitud: "",
      en_proceso: 0,
      ingresadas: 0,
      produccion: 0,
      terminadas: 0,

      tipo_ingresada_dev: {},
      tipo_proceso_dev: {},
    };
  }

  componentDidMount() {
    this.titulo();
    this.conteoSolicitudes();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "<span title='Solicitudes Asignadas'>Solicitudes Asignadas</span>";
  };

  conteoSolicitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_por_tipo/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
        },
      })
      .then((res) => {
        this.setState({
          en_proceso: res.data.en_proceso,
          ingresadas: res.data.ingresadas,
          tipo_ingresada_dev: res.data.tipo_ingresada,
          tipo_proceso_dev: res.data.tipo_proceso,
        });
      });
  };

  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  cambiarSeleccion = (seleccion) => {
    this.setState({ seleccion: seleccion });
  };

  abrirModalMotivo = (data, titulo) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo });
  };
  pasoSolicitud = (paso) => {
    this.setState({ pasoSolicitud: paso });
  };

  render() {
    const { en_proceso, ingresadas, pasoSolicitud, seleccion, tipo_ingresada_dev, tipo_proceso_dev } = this.state;
    return (
      <>
        <div className="middle-area" style={{ height: "80%", marginTop: 0 }}>
          <div className="middle-content" style={{ marginTop: 0, overflowY: "scroll", paddingTop: 5 }}>
            <div className={SolicitudTrabajo.contenedorBotones}>
              <div className={`${SolicitudTrabajo.botonesIconos} ${pasoSolicitud == 1 && SolicitudTrabajo.botonesIconosSelected}`} onClick={() => this.pasoSolicitud(1)}>
                <img src={ingresadas > 0 ? solicitudIngresada_len : solicitudIngresada}></img>
                {ingresadas > 0 && (
                  <span
                    className={`${ingresadas > 0 && ingresadas < 10 ? (pasoSolicitud == 1 ? SolicitudTrabajo.tamanio_numero1_select : SolicitudTrabajo.tamanio_numero1) : pasoSolicitud == 1 ? SolicitudTrabajo.tamanio_numero2_select : SolicitudTrabajo.tamanio_numero2} 
              `}
                  >
                    {ingresadas}
                  </span>
                )}
              </div>
              <div className={`${SolicitudTrabajo.botonesIconos} ${pasoSolicitud == 2 && SolicitudTrabajo.botonesIconosSelected}`} onClick={() => this.pasoSolicitud(2)}>
                <img src={en_proceso > 0 ? solicitudProceso_len : solicitudProceso}></img>
                {en_proceso > 0 && (
                  <span
                    className={`${en_proceso > 0 && en_proceso < 10 ? (pasoSolicitud == 2 ? SolicitudTrabajo.tamanio_numero1_select : SolicitudTrabajo.tamanio_numero1) : pasoSolicitud == 2 ? SolicitudTrabajo.tamanio_numero2_select : SolicitudTrabajo.tamanio_numero2} 
              `}
                  >
                    {en_proceso}
                  </span>
                )}
              </div>
            </div>

            <div style={{ display: "flex", marginLeft: 90, width: "95%" }}>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 1 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(1)}>
                {pasoSolicitud === 1 && tipo_ingresada_dev[1] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                {pasoSolicitud === 2 && tipo_proceso_dev[1] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Visita técnica
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 2 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(2)}>
                {pasoSolicitud === 1 && tipo_ingresada_dev[2] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                {pasoSolicitud === 2 && tipo_proceso_dev[2] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Informar error
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 3 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(3)}>
                {pasoSolicitud === 1 && tipo_ingresada_dev[3] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                {pasoSolicitud === 2 && tipo_proceso_dev[3] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Nueva herramienta
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 5 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(5)}>
                {pasoSolicitud === 1 && tipo_ingresada_dev[5] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                {pasoSolicitud === 2 && tipo_proceso_dev[5] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Reunión o Capacitación
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 7 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(7)}>
                {pasoSolicitud === 1 && tipo_ingresada_dev[7] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                {pasoSolicitud === 2 && tipo_proceso_dev[7] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Solicitud de operaciones
              </button>
              <button className={`${SolicitudTrabajo.botonesSeleccion} ${seleccion == 4 && SolicitudTrabajo.botonSelected}`} onClick={() => this.cambiarSeleccion(4)}>
                {pasoSolicitud === 1 && tipo_ingresada_dev[4] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                {pasoSolicitud === 2 && tipo_proceso_dev[4] > 0 && <span className={SolicitudTrabajo.spanHaySolicitud}></span>}
                Otro
              </button>
            </div>

            <GridContainer style={{ overflowY: "scroll", height: "85%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <div style={{ marginBottom: 100, overflowY: "scroll" }}>
                    <Grid item xs={12} sm={12}>
                      <div className="divBordeTablaHistorialHoras" style={{ paddingLeft: "20px" }}>
                        {pasoSolicitud == 1 && <TablaIngresadas seleccion={seleccion} conteo={() => this.conteoSolicitudes()} />}
                        {pasoSolicitud == 2 && <TablaEnProceso seleccion={seleccion} conteo={() => this.conteoSolicitudes()} />}
                      </div>
                    </Grid>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </>
    );
  }
}
