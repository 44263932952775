import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";
import axios from "axios";
import { store } from "../../store/index";
import { connect } from "react-redux";

// core components
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import DualListBox from "react-dual-listbox";
import Grid from "@material-ui/core/Grid";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import MultiSelect from "react-multi-select-component";
import TextField from "@material-ui/core/TextField";

import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextosFechas from "../../components/titulo/TextosFechas";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TimePicker from "rc-time-picker";
import { showLoader, hideLoader } from "../../store/actions/actions";

// estilos
import "react-dual-listbox/lib/react-dual-listbox.css";

class ModificarBloqueo extends Component {
  constructor(props) {
    super(props);

    var fechaActual = moment().format("YYYY-MM-DD");
    this.state = {
      rec_id: props.configuracion,
      sector: "",
      sectores: [],
      especialidades: [],
      especialidadesSeleccionadas: [],
      stringMultiEspecialidades: {
        selectSomeItems: "Selecciona una o más especialidades",
        allItemsAreSelected: "Todos",
        selectAll: "Seleccionar todo",
        search: "Buscar ",
        clearSearch: "Borrar búsqueda",
      },
      profesionales: [],
      profesionalesSeleccionados: [],

      horaInicio: moment(fechaActual + " 08:00"),
      horaTermino: moment(fechaActual + " 17:00"),

      fechasSeleccionadas: "",
      fechasSeleccionadasTexto: "",
      limiteFecha: moment(),
      fechaInicio: "",
      fechaTermino: "",
      activarFechaTermino: false,
      tipo: "",
      tipoHoras: 1,
      diaSemana: "",
      diaSemanaActivo: false,
      clasificacion: 1,
      clasificacionDinamica: true,
      clasificacionPorFecha: false,

      motivosBloqueo: [],
      motivoBloqueo: "0",
      motivoBloqueoTxt: "",

      diasSemana: [],
      LUN: false,
      MAR: false,
      MIE: false,
      JUE: false,
      VIE: false,
      SAB: false,
      DOM: false,

      tipoReunion: 1,
      bloqueoSector: true,
      bloqueoProfesional: true,
      bloqueoEspecialidad: true,
      bloqueoGeneral: false,
      bloquearHorasProfesional: false,

      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      formErrors: {},
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.traerSectores();
    this.traerEspecialidades();
    this.traerProfesionales();
    this.traerConfiguracion();
    this.traerMotivosBloqueo();
  }

  mostrarAlertaAgregadaConExito() {
    NotificationManager.success("Configuración modificada con éxito");
  }

  validaciones() {
    const { sector, tipoReunion, clasificacion, fechaInicio, fechaTermino, activarFechaTermino, tipo, diasSemana, profesionalesSeleccionados, fechasSeleccionadasTexto, motivoBloqueo, motivoBloqueoTxt } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (tipoReunion == 1 && sector == "") {
      formIsValid = false;
      formErrors["sectorErr"] = "Debes seleccionar el sector para el bloqueo.";
    }

    if (tipoReunion != 4 && profesionalesSeleccionados.length == 0) {
      formIsValid = false;
      formErrors["tipoProfErr"] = "Debes ingresar profesionales para que se les aplique el bloqueo.";
    }

    if (tipoReunion == 5 && fechaInicio == "") {
      formIsValid = false;
      formErrors["fechaInicioErr"] = "Ingresa la fecha de inicio del bloqueo.";
    }

    if (clasificacion == 1) {
      if (fechaInicio == "") {
        formIsValid = false;
        formErrors["fechaInicioErr"] = "Ingresa la fecha de inicio del bloqueo.";
      }

      if (fechaTermino == "" && activarFechaTermino == false) {
        formIsValid = false;
        formErrors["fechaTerminoErr"] = "Ingresa la fecha de termino del bloqueo o seleccione todas las horas.";
      }

      if (tipo == "") {
        formIsValid = false;
        formErrors["tipoErr"] = "Selecciona el tipo de bloqueo.";
      } else {
        if (tipo != 1 && diasSemana.length == 0) {
          formIsValid = false;
          formErrors["tipoSemanaErr"] = "Debes seleccionar que día de la semana se le aplicara el bloqueo.";
        }
      }
    } else {
      if (fechasSeleccionadasTexto == "") {
        formIsValid = false;
        formErrors["fechaSeleccionarErr"] = "Ingresa la o las fechas del bloqueo.";
      }
    }

    if (motivoBloqueo == "0") {
      formIsValid = false;
      NotificationManager.warning("Debe Seleccionar un motivo");
    } else if (motivoBloqueo == "otro" && motivoBloqueoTxt == "") {
      formIsValid = false;
      NotificationManager.warning("Debe escribir el motivo");
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enviarFormulario = (e) => {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    if (this.validaciones()) {
      this.props.dispatch(showLoader());

      var diasSelect = "";
      var diasSemana = this.state.diasSemana;
      for (var i = 0; i < diasSemana.length; i++) {
        diasSelect += diasSemana[i] + ",";
      }
      diasSelect = diasSelect.substring(0, diasSelect.length - 1);

      var motivoBloqueoHora = null;
      var motivoBloqueoHoraTexto = null;
      if (motivoBloqueo != "Activa") {
        motivoBloqueoHora = motivoBloqueo;
        motivoBloqueoHoraTexto = motivoBloqueoTxt;
      }

      var horaInicio = moment(this.state.horaInicio).format("HH:mm");
      var horaTermino = moment(this.state.horaTermino).format("HH:mm");

      var data = {
        rec_id: this.state.rec_id,
        fechas: this.state.fechasSeleccionadasTexto,
        tipoReunion: this.state.tipoReunion,
        clasificacionFechas: this.state.clasificacion,
        especialidades: this.state.especialidadesSeleccionadas,
        profesionales: this.state.profesionalesSeleccionados,
        horaInicio: horaInicio,
        horaTermino: horaTermino,
        fechaInicio: this.state.fechaInicio,
        fechaTermino: this.state.fechaTermino,
        activarFechaTermino: this.state.activarFechaTermino,
        motivo: motivoBloqueoHora,
        motivo_texto: motivoBloqueoHoraTexto,
        diaSemana: diasSelect,
        tipo: this.state.tipo,
        sector: this.state.sector,
        cesfam: this.state.cesfam,
        uspId: this.state.uspId,
      };
      let apiUrl = global.config.modulos.agendamientos + "modificar_bloqueos/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options)
        .then((res) => {
          this.props.dispatch(hideLoader());

          var data = res.data;
          setTimeout(() => {
            var estado = data.estado;
            if (estado) {
              this.salir();
              this.mostrarAlertaAgregadaConExito();
            } else {
              NotificationManager.warning("Revise la configuración, no se realizo la modificación");
            }
          }, 500);
        })
        .catch((err) => {
          this.props.dispatch(hideLoader());
        });
    }
  };

  traerSectores() {
    const apiUrl = global.config.modulos.sectores + "por_cesfam/" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        sectores: res.data["datos"],
      });
    });
  }

  traerEspecialidades() {
    const apiUrl = global.config.modulos.agendamientos + "especialidades/" + this.state.cesfam;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let espResult = [];
      for (var i = 0; i < res.data.datos.length; i++) {
        let esp = {
          value: res.data.datos[i]["esp_sigla"],
          label: res.data.datos[i]["esp_nombre"],
        };
        espResult.push(esp);
      }

      this.setState({
        especialidades: espResult,
      });
    });
  }

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + this.state.cesfam;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data["estado"]) {
        let proResult = [];
        for (var i = 0; i < res.data["datos"].length; i++) {
          let pro = {
            value: res.data["datos"][i]["prc_profesional"],
            label: res.data["datos"][i]["nombreprofesional"],
          };
          proResult.push(pro);
        }
        this.setState({
          profesionales: proResult,
        });
      }
    });
  }

  traerProfesionalesPorEspecialidad(especialidadesSeleccionadas) {
    var data = {
      cesfam: this.state.cesfam,
      especialidades: especialidadesSeleccionadas,
    };
    let apiUrl = global.config.modulos.profesionales + "por_especialidades/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        let proResult = [];
        for (var i = 0; i < res.data["datos"].length; i++) {
          proResult.push(res.data["datos"][i]["pro_id"]);
        }
        this.setState({
          profesionalesSeleccionados: proResult,
        });
      }
    });
  }

  traerProfesionalesPorSector(sector) {
    let apiUrl = global.config.modulos.profesionales + "por_sector/" + sector;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        let proResult = [];
        for (var i = 0; i < res.data["datos"].length; i++) {
          proResult.push(res.data["datos"][i]["pro_id"]);
        }
        this.setState({
          profesionalesSeleccionados: proResult,
        });
      }
    });
  }

  traerMotivosBloqueo() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_bloqueo_horario/?cesfam=" + this.state.cesfam;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          motivosBloqueo: res.data,
        });
      }
    });
  }

  traerConfiguracion() {
    const apiUrl = global.config.modulos.agendamientos + "configuracion_bloqueo_por_id/" + this.state.rec_id;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var diasSemana = res.data["rec_dia_semana"].split(",");

      var sector = res.data.rec_sector;
      if (sector == null) {
        sector = "";
      }

      var motivoBloqueo = res.data.rec_motivo_bloqueo;
      var motivoBloqueoTexto = res.data.rec_motivo_bloqueo_texto;
      var tipoReunion = res.data.rec_tipo_reunion;

      var bloqueoSector;
      var bloqueoProfesional;
      var bloqueoEspecialidad;
      var bloquearHorasProfesional;

      if (tipoReunion == 1) {
        bloqueoSector = true;
        bloqueoProfesional = true;
        bloqueoEspecialidad = true;
        bloquearHorasProfesional = false;
      } else if (tipoReunion == 2) {
        bloqueoSector = false;
        bloqueoProfesional = true;
        bloqueoEspecialidad = false;
        bloquearHorasProfesional = false;
      } else if (tipoReunion == 3) {
        bloqueoSector = false;
        bloqueoProfesional = true;
        bloqueoEspecialidad = true;
        bloquearHorasProfesional = false;
      } else if (tipoReunion == 4) {
        bloqueoSector = false;
        bloqueoProfesional = false;
        bloqueoEspecialidad = false;
        bloquearHorasProfesional = false;
      }

      var diaSemana = false;
      if (res.data.rec_clasificacion_fecha == 1 && res.data.rec_tipo_fechas != 1 && res.data.rec_tipo_fechas != "") {
        diaSemana = true;
      }

      var clasificacionDinamica;
      var clasificacionPorFecha;

      if (res.data.rec_clasificacion_fecha == 1) {
        clasificacionDinamica = true;
        clasificacionPorFecha = false;
      } else {
        clasificacionDinamica = false;
        clasificacionPorFecha = true;
      }

      var fechasSeleccionadas = "";
      var fechasSeleccionadasTexto = "";
      if (res.data.rec_clasificacion_fecha == 2) {
        fechasSeleccionadas = res.data.rec_fecha_texto.split(",");
        fechasSeleccionadasTexto = res.data.rec_fecha_texto;
      } else {
        fechasSeleccionadas = "";
      }

      var mostrarMotivoTxt = false;
      if (motivoBloqueo == "otro") {
        mostrarMotivoTxt = true;
      }

      var profesionalesSeleccionados = [];
      if (res.data.rec_profesionales != "") {
        profesionalesSeleccionados = res.data.rec_profesionales.split(",").map(Number);
      }

      var especialidadesSeleccionadas = [];
      if (res.data.rec_especialidades != "") {
        especialidadesSeleccionadas = res.data.rec_especialidades.split(",").map(String);
      }

      var espResultSelect = [];
      var especialidadesCesfam = this.state.especialidades;
      for (var i = 0; i < especialidadesCesfam.length; i++) {
        for (var x = 0; x < especialidadesSeleccionadas.length; x++) {
          if (especialidadesCesfam[i]["value"] == especialidadesSeleccionadas[x]) {
            let esp = {
              label: especialidadesCesfam[i]["label"],
              value: especialidadesCesfam[i]["value"],
            };
            espResultSelect.push(esp);
          }
        }
      }

      var fechaActual = moment().format("YYYY-MM-DD");

      var horaInicio = res.data.rec_hora_inicio;
      var horaTermino = res.data.rec_hora_termino;

      var tipo = res.data.rec_tipo_fechas;
      if (tipo == null) {
        tipo = "";
      }

      var fechaInicio = res.data.rec_fecha_inicio;
      if (fechaInicio == null) {
        fechaInicio = "";
      }

      var fechaTermino = res.data.rec_fecha_termino;
      if (fechaTermino == null) {
        fechaTermino = "";
      }

      if (diasSemana == "") {
        diasSemana = [];
      }

      this.setState({
        tipoReunion: res.data.rec_tipo_reunion,
        sector: sector,
        profesionalesSeleccionados: profesionalesSeleccionados,
        especialidadesSeleccionadas: espResultSelect,
        fechaInicio: fechaInicio,
        fechaTermino: fechaTermino,
        horaInicio: moment(fechaActual + " " + horaInicio),
        horaTermino: moment(fechaActual + " " + horaTermino),
        clasificacion: res.data.rec_clasificacion_fecha,
        activarFechaTermino: res.data.rec_fecha_termino_activa,
        tipo: tipo,
        diasSemana: diasSemana,
        diaSemanaActivo: diaSemana,
        fechasSeleccionadas: fechasSeleccionadas,
        fechasSeleccionadasTexto: fechasSeleccionadasTexto,

        clasificacionDinamica: clasificacionDinamica,
        clasificacionPorFecha: clasificacionPorFecha,

        motivoBloqueo: motivoBloqueo,
        motivoBloqueoTxt: motivoBloqueoTexto,
        mostrarMotivoTxt: mostrarMotivoTxt,

        bloqueoSector: bloqueoSector,
        bloqueoProfesional: bloqueoProfesional,
        bloqueoEspecialidad: bloqueoEspecialidad,
        bloquearHorasProfesional: bloquearHorasProfesional,

        LUN: diasSemana != null ? (diasSemana.includes("1") ? true : false) : false,
        MAR: diasSemana != null ? (diasSemana.includes("2") ? true : false) : false,
        MIE: diasSemana != null ? (diasSemana.includes("3") ? true : false) : false,
        JUE: diasSemana != null ? (diasSemana.includes("4") ? true : false) : false,
        VIE: diasSemana != null ? (diasSemana.includes("5") ? true : false) : false,
        SAB: diasSemana != null ? (diasSemana.includes("6") ? true : false) : false,
        DOM: diasSemana != null ? (diasSemana.includes("7") ? true : false) : false,
      });
    });
  }

  cambiarValoresFecha = (e, fecha) => {
    const { name, value } = e.target;

    if (fecha == "inicio") {
      if (value > this.state.fechaTermino && this.state.fechaTermino != "") {
        NotificationManager.warning("La fecha de inicio no puede ser mayor a la de término");
      } else {
        this.setState({ fechaInicio: value });
      }
    }
    if (fecha == "termino") {
      if (value < this.state.fechaInicio && this.state.fechaInicio != "") {
        NotificationManager.warning("La fecha de término no puede ser menor a la de inicio");
      } else {
        this.setState({ fechaTermino: value });
      }
    }
  };

  cambiarValoresHora = (valor, hora) => {
    if (hora == "inicio") {
      if (valor > this.state.horaTermino) {
        NotificationManager.warning("La hora de inicio no puede ser mayor a la de término");
      } else {
        this.setState({ horaInicio: valor });
      }
    }
    if (hora == "termino") {
      if (valor < this.state.horaInicio) {
        NotificationManager.warning("La hora de término no puede ser menor a la de inicio");
      } else {
        this.setState({ horaTermino: valor });
      }
    }
  };

  render() {
    const { sectorErr, tipoProfErr, fechaInicioErr, fechaTerminoErr, tipoErr, tipoSemanaErr, fechaSeleccionarErr } = this.state.formErrors;

    const { sector, sectores, especialidades, especialidadesSeleccionadas, stringMultiEspecialidades, profesionales, profesionalesSeleccionados, horaInicio, horaTermino, fechasSeleccionadas, limiteFecha, fechaInicio, fechaTermino, tipo, clasificacion, tipoReunion, motivosBloqueo, motivoBloqueo, motivoBloqueoTxt } = this.state;

    const cambioMotivoBloqueo = (event) => {
      if (event.target.value == "otro") {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: true,
        });
      } else {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: false,
        });
      }
    };

    const cambioTipoReunion = (event) => {
      if (event.target.value == 1) {
        this.setState({
          tipoReunion: parseInt(event.target.value),
          bloqueoSector: true,
          bloqueoProfesional: true,
          bloqueoEspecialidad: true,
          profesionalesSeleccionados: [],
          especialidadesSeleccionadas: [],
          sector: "",
          tipo: "",
          diaSemana: "",
          clasificacion: 1,
          clasificacionDinamica: true,
          clasificacionPorFecha: false,
          diaSemanaActivo: false,
          bloquearHorasProfesional: false,
        });
      } else if (event.target.value == 2) {
        this.setState({
          tipoReunion: parseInt(event.target.value),
          bloqueoSector: false,
          bloqueoProfesional: true,
          bloqueoEspecialidad: false,
          profesionalesSeleccionados: [],
          especialidadesSeleccionadas: [],
          sector: "",
          tipo: "",
          diaSemana: "",
          clasificacion: 1,
          clasificacionDinamica: true,
          clasificacionPorFecha: false,
          diaSemanaActivo: false,
          bloquearHorasProfesional: false,
        });
      } else if (event.target.value == 3) {
        this.setState({
          tipoReunion: parseInt(event.target.value),
          bloqueoSector: false,
          bloqueoProfesional: true,
          bloqueoEspecialidad: true,
          profesionalesSeleccionados: [],
          especialidadesSeleccionadas: [],
          sector: "",
          tipo: "",
          diaSemana: "",
          clasificacion: 1,
          clasificacionDinamica: true,
          clasificacionPorFecha: false,
          diaSemanaActivo: false,
          bloquearHorasProfesional: false,
        });
      } else if (event.target.value == 4) {
        this.setState({
          tipoReunion: parseInt(event.target.value),
          bloqueoSector: false,
          bloqueoProfesional: false,
          bloqueoEspecialidad: false,
          profesionalesSeleccionados: [],
          especialidadesSeleccionadas: [],
          sector: "",
          tipo: "",
          diaSemana: "",
          clasificacion: 1,
          clasificacionDinamica: true,
          clasificacionPorFecha: false,
          diaSemanaActivo: false,
          bloquearHorasProfesional: false,
        });
      }
    };

    const cambiarSector = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });

      this.traerProfesionalesPorSector(value);
    };

    const cambiosListaProfesionales = (profesionalesSeleccionados) => {
      this.setState({ profesionalesSeleccionados });
    };

    const cambioEspecialidades = (especialidadesSeleccionadas) => {
      this.setState({ especialidadesSeleccionadas });
      this.traerProfesionalesPorEspecialidad(especialidadesSeleccionadas);
    };

    const cambiarFechas = (fechas) => {
      this.setState({
        fechasSeleccionadas: fechas,
        fechasSeleccionadasTexto: fechas.join(),
      });
    };

    const cambiarTipo = (e) => {
      const { name, value } = e.target;

      if (value == 1 || value == "") {
        this.setState({ [name]: value, diaSemanaActivo: false, diaSemana: "" });
      } else {
        this.setState({ [name]: value, diaSemanaActivo: true });
      }
    };

    const cambioClasificacion = (event) => {
      if (event.target.value == 1) {
        this.setState({
          clasificacion: parseInt(event.target.value),
          clasificacionDinamica: true,
          clasificacionPorFecha: false,
        });
      } else {
        this.setState({
          clasificacion: parseInt(event.target.value),
          clasificacionDinamica: false,
          clasificacionPorFecha: true,
          fechaInicio: "",
        });
      }
    };

    const cambiarFechaTermino = (e) => {
      const { name, checked } = e.target;
      this.setState({
        [name]: checked,
        activarFechaTermino: checked,
      });
    };

    const elegirDias = (event) => {
      const { name, value } = event.target;

      var semana = this.state.diasSemana;

      var check = event.target.checked;
      if (check == true) {
        semana.push(value);
      } else {
        const index = semana.indexOf(value);
        if (index > -1) {
          semana.splice(index, 1);
        }
      }

      this.setState({
        diasSemana: semana,
        [name]: check,
      });
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2>Modificar Horario general inhabilitado</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}></GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="cargarHorario" style={{ marginTop: "2vw" }}>
                  <div>
                    <React.Fragment>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <RadioGroup value={tipoReunion} onChange={cambioTipoReunion}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControlLabel value={1} control={<Radio />} label="Por sector" />
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControlLabel value={2} control={<Radio />} label="Por profesional" />
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControlLabel value={3} control={<Radio />} label="Por área" />
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControlLabel value={4} control={<Radio />} label="General" />
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}></Grid>
                            </Grid>
                          </RadioGroup>
                        </Grid>

                        {this.state.bloqueoSector ? (
                          <Grid item xs={6} sm={6} md={6}>
                            <InputLabel>Sectores</InputLabel>
                            <br></br>
                            <Select id="sector" name="sector" value={sector} onChange={cambiarSector} displayEmpty>
                              <MenuItem value="">
                                <em>Seleccione sector</em>
                              </MenuItem>

                              {sectores &&
                                sectores.map((sec, index) => (
                                  <MenuItem value={sec.sec_id} key={index}>
                                    {sec.sec_nombre}
                                  </MenuItem>
                                ))}
                            </Select>
                            {sectorErr && <div style={{ color: "red", paddingBottom: 10 }}>{sectorErr}</div>}
                          </Grid>
                        ) : null}

                        {this.state.bloqueoEspecialidad ? (
                          <Grid item xs={6} sm={6} md={6}>
                            <InputLabel>Especialidades</InputLabel>
                            <br></br>
                            <MultiSelect id="cmbEspecialidades" className={"selectMulti"} options={especialidades} value={especialidadesSeleccionadas} onChange={cambioEspecialidades} labelledBy={"Select"} overrideStrings={stringMultiEspecialidades} />
                          </Grid>
                        ) : null}

                        {this.state.bloqueoProfesional ? (
                          <Grid item xs={12} sm={12} md={12}>
                            <InputLabel>Profesionales</InputLabel>
                            <br></br>
                            <DualListBox canFilter options={profesionales} selected={profesionalesSeleccionados} onChange={cambiosListaProfesionales} filterPlaceholder="Buscar..." />
                            {tipoProfErr && <div style={{ color: "red", paddingBottom: 10 }}>{tipoProfErr}</div>}
                          </Grid>
                        ) : null}

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                              <InputLabel>Motivo de bloqueo</InputLabel>
                              <br></br>
                              <Select name="motivoBloqueo" className={"selectSimple"} value={motivoBloqueo} onChange={cambioMotivoBloqueo} style={{ minWidth: 250 }} displayEmpty>
                                <MenuItem value={"0"} key={0}>
                                  {"Selecciona"}
                                </MenuItem>

                                {motivosBloqueo &&
                                  motivosBloqueo.map((mb, index) => (
                                    <MenuItem value={mb.mb_descripcion} key={index}>
                                      {mb.mb_nombre}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              {this.state.mostrarMotivoTxt ? (
                                <TextareaAutosize
                                  name="motivoBloqueoTxt"
                                  aria-label="minimum height"
                                  className={"selectSimple "}
                                  style={{
                                    float: "left",
                                    minWidth: 250,
                                    marginTop: 20,
                                  }}
                                  rowsMin={3}
                                  placeholder="Escribe el motivo..."
                                  value={motivoBloqueoTxt}
                                  onChange={this.cambiarValores}
                                />
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <br></br>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={2}>
                              <p>
                                <label
                                  style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                  }}
                                >
                                  Hora inicio
                                </label>
                              </p>
                              <br></br>
                              <TimePicker
                                minuteStep={5}
                                showSecond={false}
                                name="horaInicio"
                                id="horaInicio"
                                value={horaInicio}
                                style={{
                                  width: 100,
                                }}
                                onChange={(e) => this.cambiarValoresHora(e, "inicio")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2}>
                              <p>
                                <label
                                  style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                  }}
                                >
                                  Hora Termino
                                </label>
                              </p>
                              <br></br>
                              <TimePicker
                                minuteStep={5}
                                showSecond={false}
                                name="horaTermino"
                                id="horaTermino"
                                value={horaTermino}
                                style={{
                                  width: 100,
                                }}
                                onChange={(e) => this.cambiarValoresHora(e, "termino")}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <InputLabel>Clasificación</InputLabel>
                          <br></br>
                          <RadioGroup name="clasificacion" id="clasificacion" value={clasificacion} onChange={cambioClasificacion}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={3} md={2}>
                                <FormControlLabel value={1} control={<Radio />} label="Por bloque" />
                              </Grid>
                              <Grid item xs={12} sm={3} md={4}>
                                <FormControlLabel value={2} control={<Radio />} label="Seleccionar fechas" />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </Grid>

                        {this.state.clasificacionDinamica ? (
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={3}>
                                <InputLabel>Fecha Inicio</InputLabel>
                                <br></br>
                                <TextField
                                  id="fechaInicio"
                                  name="fechaInicio"
                                  type="date"
                                  value={fechaInicio}
                                  inputProps={{
                                    min: limiteFecha.format("YYYY-MM-DD"),
                                  }}
                                  onChange={(e) => this.cambiarValoresFecha(e, "inicio")}
                                />
                                {fechaInicioErr && <div style={{ color: "red", paddingBottom: 10 }}>{fechaInicioErr}</div>}
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <InputLabel>Fecha Termino</InputLabel>
                                <br></br>
                                <TextField
                                  id="fechaTermino"
                                  name="fechaTermino"
                                  type="date"
                                  value={fechaTermino}
                                  inputProps={{
                                    min: limiteFecha.format("YYYY-MM-DD"),
                                  }}
                                  onChange={(e) => this.cambiarValoresFecha(e, "termino")}
                                  disabled={this.state.activarFechaTermino}
                                />
                                {fechaTerminoErr && (
                                  <div
                                    style={{
                                      color: "red",
                                      paddingBottom: 10,
                                    }}
                                  >
                                    {fechaTerminoErr}
                                  </div>
                                )}
                              </Grid>

                              <Grid item xs={12} sm={3} md={2}>
                                <FormControlLabel control={<Checkbox name="activarFechaTermino" checked={this.state.activarFechaTermino} onChange={cambiarFechaTermino} />} label="Permanente" />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}

                        {this.state.clasificacionDinamica ? (
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={4}>
                                <InputLabel>Tipo</InputLabel>
                                <br></br>
                                <Select id="tipo" name="tipo" value={tipo} onChange={cambiarTipo} displayEmpty>
                                  <MenuItem value="">
                                    <em>Selecciona</em>
                                  </MenuItem>

                                  <MenuItem value={1}>
                                    <em>Todos los días</em>
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <em>Los xx cada semana</em>
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <em>Los xx semana por medio</em>
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <em>El primer xx del mes</em>
                                  </MenuItem>
                                  <MenuItem value={5}>
                                    <em>El segundo xx del mes</em>
                                  </MenuItem>
                                  <MenuItem value={6}>
                                    <em>El tercer xx del mes</em>
                                  </MenuItem>
                                  <MenuItem value={7}>
                                    <em>El último xx del mes</em>
                                  </MenuItem>
                                </Select>
                                {tipoErr && <div style={{ color: "red", paddingBottom: 10 }}>{tipoErr}</div>}
                              </Grid>

                              {this.state.diaSemanaActivo ? (
                                <Grid item xs={12} sm={12} md={12}>
                                  <InputLabel>Días de la semana</InputLabel>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={3} md={1}>
                                      <FormControlLabel id="LUN" name="LUN" label="LUN" value="1" control={<Checkbox />} checked={this.state.LUN} onChange={elegirDias} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1}>
                                      <FormControlLabel id="MAR" name="MAR" label="MAR" value="2" control={<Checkbox />} checked={this.state.MAR} onChange={elegirDias} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1}>
                                      <FormControlLabel id="MIE" name="MIE" label="MIÉ" value="3" control={<Checkbox />} checked={this.state.MIE} onChange={elegirDias} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1}>
                                      <FormControlLabel id="JUE" name="JUE" label="JUE" value="4" control={<Checkbox />} checked={this.state.JUE} onChange={elegirDias} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1}>
                                      <FormControlLabel id="VIE" name="VIE" label="VIE" value="5" control={<Checkbox />} checked={this.state.VIE} onChange={elegirDias} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1}>
                                      <FormControlLabel id="SAB" name="SAB" label="SÁB" value="6" control={<Checkbox />} checked={this.state.SAB} onChange={elegirDias} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1}>
                                      <FormControlLabel id="DOM" name="DOM" label="DOM" value="7" control={<Checkbox />} checked={this.state.DOM} onChange={elegirDias} />
                                    </Grid>
                                  </Grid>
                                  {tipoSemanaErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {tipoSemanaErr}
                                    </div>
                                  )}
                                </Grid>
                              ) : null}
                            </Grid>
                          </Grid>
                        ) : null}

                        {this.state.clasificacionPorFecha ? (
                          <Grid item xs={12} sm={12} md={12}>
                            <Calendar multiple format="DD MMMM YYYY" sort weekStartDayIndex={1} plugins={[<DatePanel />]} locale={TextosFechas} value={fechasSeleccionadas} onChange={cambiarFechas} />
                            {fechaSeleccionarErr && (
                              <div
                                style={{
                                  color: "red",
                                  paddingBottom: 10,
                                }}
                              >
                                {fechaSeleccionarErr}
                              </div>
                            )}
                          </Grid>
                        ) : null}

                        <div
                          style={{
                            width: "100%",
                            float: "left",
                            marginTop: "32px",
                          }}
                        >
                          <Button
                            onClick={this.salir}
                            className="fondoRojo"
                            style={{
                              float: "right",
                              marginLeft: 10,
                              color: "#fff",
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button onClick={this.enviarFormulario} className="fondoVerde" style={{ float: "right", color: "#fff" }}>
                            Modificar
                          </Button>
                        </div>
                      </Grid>
                    </React.Fragment>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ModificarBloqueo);
