import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem, MenuItem } from "@material-ui/core";
import { store } from "store/index";

import InputLabel from "@material-ui/core/InputLabel";

import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NotificationManager from "react-notifications/lib/NotificationManager.js";
import { Select } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import crearTotem from "assets/css/crearTotem.module.css";

import eliminar from "assets/img/usuarios/icono_eliminar.svg";
import modificar from "assets/img/admin/modificar.svg";
import MaterialTable from "material-table";

//JS
import PrioridadEdad from "./Prioridad/PrioridadEdad";
import PrioridadCondicion from "./Prioridad/PrioridadCondicion";

export default class Prioridad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vista_prioridad: 1,
    };
  }

  cambiar_vistas = (vista) => {
    // 1: Por Edad
    // 2: Por Condicion
    this.setState({ vista_prioridad: vista });
  };

  render() {
    const { vista_prioridad } = this.state;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ marginBottom: "20px" }}>
          <button
            style={{
              padding: "12px",
              color: "white",
              borderRadius: "5px",
              cursor: "pointer",
              marginRight: "10px",
              background: vista_prioridad == 1 ? "#dd8114" : "#eaa028",
            }}
            onClick={() => this.cambiar_vistas(1)}
          >
            Prioridad por edad
          </button>
          <button
            style={{
              padding: "12px",
              color: "white",
              borderRadius: "5px",
              cursor: "pointer",
              marginRight: "10px",
              background: vista_prioridad == 2 ? "#dd8114" : "#eaa028",
            }}
            onClick={() => this.cambiar_vistas(2)}
          >
            Prioridad por condicion
          </button>
        </div>
        <GridContainer style={{ width: "100%", marginLeft: "20px !important" }}>{vista_prioridad == 1 ? <PrioridadEdad /> : vista_prioridad == 2 ? <PrioridadCondicion /> : null}</GridContainer>
      </GridItem>
    );
  }
}
