import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment, { relativeTimeThreshold } from "moment";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class FinalizarCaso extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      latencia: this.props.latencia,
      opcionSel: "",

      open: true,
    };
  }

  componentDidMount() {}

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validaciones() {
    const { opcionSel } = this.state;
    let formIsValid = true;

    if (opcionSel == "") {
      NotificationManager.warning("Selecciona la solución.");
      return false;
    }

    return formIsValid;
  }

  finalizarCaso = () => {
    if (this.validaciones()) {
      var data = {
        lbc_estado: this.state.opcionSel,
        lbc_latencia: this.state.latencia,
        lbc_usuario_panel: this.state.uspId,
      };
      let apiUrl = global.config.modulos.bloqueos + "tomar_caso/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        NotificationManager.info("Caso finalizado correctamente");
        this.salir();
      });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Finalizar caso</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <Select style={{ marginTop: 8, minWidth: 400 }} id="opcionSel" name="opcionSel" value={this.state.opcionSel} onChange={this.cambiarValores} displayEmpty>
                      <MenuItem value="" disabled>
                        <em>Solución entregada</em>
                      </MenuItem>
                      <MenuItem value={3}>Informado</MenuItem>
                      <MenuItem value={4}>No contesta</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.finalizarCaso} style={{ marginLeft: 20 }} color="primary" className="fondoVerde">
                    Finalizar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
