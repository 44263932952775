import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Grid } from "@material-ui/core";
import graf from "../../../assets/css/graf.module.css";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import { NotificationManager } from "react-notifications";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default class Grafico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      comuna: props.comuna,
      tipoFiltro: props.tipoFiltro,
      fecha_semana: props.fecha_semana,
      fecha_mes: props.fecha_mes,
      fecha_año: props.fecha_año,
    };
  }

  componentDidMount() {
    this.MostrarGrafico();
  }

  MostrarGrafico() {
    this.setState({ datas: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "grafico_entrada/", {
        headers: authHeader(),
        params: {
          comuna: this.state.comuna,
          tipoFiltro: this.state.tipoFiltro,
          semana: this.state.fecha_semana,
          mes: this.state.fecha_mes,
          año: this.state.fecha_año,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        } else {
          this.setState(
            {
              datas: res.data.datos,
            },
            () => {
              let root = am5.Root.new("chartdiv");
              root._logo.dispose();
              const myTheme = am5.Theme.new(root);

              root.locale = am5locales_es_ES;

              myTheme.rule("AxisLabel", ["minor"]).setAll({
                dy: 1,
              });

              myTheme.rule("Grid", ["minor"]).setAll({
                strokeOpacity: 0.08,
              });

              root.setThemes([am5themes_Animated.new(root), myTheme]);

              let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                  panX: false,
                  panY: false,
                  layout: root.verticalLayout,
                })
              );

              let cursor = chart.set(
                "cursor",
                am5xy.XYCursor.new(root, {
                  behavior: "none",
                })
              );
              cursor.lineY.set("visible", false);

              let xRenderer = am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 25,
              });

              xRenderer.labels.template.setAll({
                rotation: 0,
                centerY: am5.p50,
                fontSize: 12,
                maxWidth: 105,
                oversizedBehavior: "wrap",
              });

              let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                  categoryField: "legible",
                  renderer: xRenderer,
                  tooltip: am5.Tooltip.new(root, {}),
                })
              );

              xRenderer.grid.template.setAll({
                location: 1,
              });
              let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                  maxPrecision: 0,
                  renderer: am5xy.AxisRendererY.new(root, {
                    inversed: false,
                  }),
                })
              );

              xAxis.data.setAll(this.state.datas);

              let thiss = this;

              function createSeries(name, field, color) {
                let series = chart.series.push(
                  am5xy.LineSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: field,
                    categoryXField: "legible",
                    fill: color,
                    stroke: color,
                    tooltip: am5.Tooltip.new(root, {
                      pointerOrientation: "horizontal",
                      labelText: "[bold]{categoryX}[/]\n{name}: {valueY} minutos",
                      fill: am5.color(0xff5566),
                      autoTextColor: false,
                      keepTargetHover: true,
                    }),
                  })
                );

                series.bullets.push(function () {
                  var circle = am5.Circle.new(root, {
                    radius: 5,
                    fill: series.get("fill"),
                  });

                  return am5.Bullet.new(root, {
                    sprite: circle,
                  });
                });

                series.set("setStateOnChildren", true);
                series.states.create("hover", {});

                series.mainContainer.set("setStateOnChildren", true);
                series.mainContainer.states.create("hover", {});

                series.strokes.template.states.create("hover", {
                  strokeWidth: 4,
                });

                let data = thiss.state.datas;

                series.data.setAll(data);

                series.appear(1000);
              }

              createSeries("Móvil", "tiempo_legible_movil", am5.color(0x52b4db));
              createSeries("Fijo", "tiempo_legible_fijo", am5.color(0xd2bbea));

              let legend = chart.children.unshift(
                am5.Legend.new(root, {
                  centerX: am5.p50,
                  x: am5.p50,
                  y: am5.p0,
                  layout: root.horizontalLayout,
                })
              );

              legend.itemContainers.template.states.create("hover", {});

              legend.itemContainers.template.events.on("pointerover", function (e) {
                e.target.dataItem.dataContext.hover();
              });
              legend.itemContainers.template.events.on("pointerout", function (e) {
                e.target.dataItem.dataContext.unhover();
              });

              legend.data.setAll(chart.series.values);

              chart.appear(1000, 100);
            }
          );
        }
      });
  }

  render() {
    return <Grid id="chartdiv" className={graf.graficoCall1}></Grid>;
  }
}
