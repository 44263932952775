import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class ModificarSector extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      sector: "",
      uspId: parseInt(store.getState().usp),
      open: true,
      usuario: props.usuario,
      id_cesfam_usuario: props.id_cesfam_usuario,
      sectores: [],
    };
  }

  componentDidMount() {
    const cesfamCerroNavia = [5, 10];
    const cesfamLoAmor = [6, 9];

    if (cesfamCerroNavia.includes(this.state.id_cesfam_usuario)) {
      cesfamCerroNavia.map((sec) => {
        axios
          .get(global.config.modulos.sectores + "por_cesfam/" + sec, {
            headers: authHeader(),
          })
          .then((res) => {
            if (res.data.estado) {
              this.setState({
                sectores: this.state.sectores.concat(res.data.datos),
              });
            }
          });
      });
    } else if (cesfamLoAmor.includes(this.state.id_cesfam_usuario)) {
      cesfamLoAmor.map((sec) => {
        axios
          .get(global.config.modulos.sectores + "por_cesfam/" + sec, {
            headers: authHeader(),
          })
          .then((res) => {
            if (res.data.estado) {
              this.setState({
                sectores: this.state.sectores.concat(res.data.datos),
              });
            }
          });
      });
    }

    this.traerUsuario();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerUsuario() {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuario;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        sector: res.data.datos["usu_sector"] + ";" + res.data.datos["usu_cesfam"],
      });
    });
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        usu_sector: this.state.sector.split(";")[0],
        usu_cesfam: this.state.sector.split(";")[1],
        canal: 5,
        usp_id: this.state.uspId,
      };

      let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuario;

      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        NotificationManager.success("Sector modificado con éxito");
        this.salir();
      });
    }
  };

  validaciones = () => {
    const { sector } = this.state;

    if (sector == "") {
      NotificationManager.warning("Seleccione un sector");
      return false;
    }

    return true;
  };

  render() {
    const { sectores, sector } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Cambiar sector</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <InputLabel>Sector</InputLabel>
                  <select className="estiloSelect" name="sector" value={sector} onChange={this.cambiarValores}>
                    <option value="">Seleccione...</option>
                    {sectores.map((sector) => (
                      <option value={sector.sec_id + ";" + sector.sec_cesfam}>
                        {sector.sec_nombre} {"(" + sector.ces_nombre + ")"}
                      </option>
                    ))}
                  </select>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.enviarFormulario} color="primary" className="fondoVerde">
                    Modificar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
