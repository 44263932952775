import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";

import { NotificationManager } from "react-notifications";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import PlantillasObservaciones from "./PlantillasObservaciones";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class AgregarObservacionSinTeleconsulta extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      gestion: props.gegId,
      observacion: "",
      tipoObservacion: 1,
      usuario: props.usuario,
      modalPlantillasObservaciones: false,
      profesional: 4,
      tipoAtencion: 9,
      tipo: props.tipo,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  copiarTexto = (texto) => {
    this.setState({ observacion: texto });
  };

  mostrarModalPlantillasObservaciones = () => {
    this.setState({ modalPlantillasObservaciones: true });
  };

  ocultarModalPlantillasObservaciones = (mensaje = "") => {
    this.setState({ modalPlantillasObservaciones: false });
    this.copiarTexto(mensaje);
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enviarFormulario = () => {
    axios
      .post(
        global.config.modulos.gestion + "observaciones/",
        {
          ggo_usuario_panel: this.state.uspId,
          ggo_observacion: this.state.observacion,
          ggo_gestion: this.state.gestion,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        NotificationManager.success("Observacion agregada con éxito");
        this.salir();
      });
  };

  guardarPlantilla = () => {
    if (this.state.observacion == "") {
      return NotificationManager.warning("Primero debe escribir el mensaje");
    }
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea guardar esta observación como plantilla?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            axios
              .post(
                global.config.modulos.gestion + "registrar_plantilla_observacion/",
                {
                  usuario_panel: this.state.uspId,
                  mensaje: this.state.observacion,
                  tipo_gestion: this.state.tipo,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                if (res.data.estado) {
                  NotificationManager.success("Plantilla guardada con éxito");
                }
              });
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agregarObservacion">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Agregar observación</h2>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <TextField id="observacion" name="observacion" label="Observación" multiline rows={4} value={this.state.observacion} style={{ width: "100%" }} variant="outlined" onChange={this.cambiarValores} />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20, color: "white", float: "right" }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.enviarFormulario} style={{ color: "white", float: "right" }} color="primary" className="fondoVerde">
                    Agregar
                  </Button>
                  <Button style={{ marginLeft: 0, float: "left", color: "#fff" }} color="primary" onClick={this.guardarPlantilla} className="fondoVerde">
                    Guardar observación
                  </Button>
                  <Button style={{ marginLeft: 20, float: "left", color: "#fff" }} color="primary" onClick={this.mostrarModalPlantillasObservaciones} className="fondoRojo">
                    Seleccionar plantilla
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.modalPlantillasObservaciones ? <PlantillasObservaciones ocultar={this.ocultarModalPlantillasObservaciones} tipo={this.state.tipo}></PlantillasObservaciones> : null}
      </Dialog>
    );
  }
}
