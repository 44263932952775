import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";

import "../../assets/css/usuarios.css";

export default class EliminarPercapitado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      canal: 5,
      rutExtranjero: false,
      cliente: props.cliente,
      agregarndoUsuario: false,
      ordenarPrioridad: null,
      pagina: 0,
      filas: 10,
      total: 0,
      id_usuario: props.id_usuario,
      motivo: null,
    };
    console.log(this.state.id_usuario);
  }

  EliminarPercapitado = () => {
    if (this.state.motivo == null) {
      NotificationManager.error("Debe ingresar un motivo");
      return;
    }
    axios
      .get(global.config.modulos.noPercapitados + "eliminar_percapitado", {
        headers: authHeader(),
        params: {
          id_usuario: this.state.id_usuario,
          motivo: this.state.motivo,
        },
      })
      .then((response) => {
        if (response.data.estado) {
          NotificationManager.success("Percapitado eliminado correctamente");
          this.salir();
        } else {
          NotificationManager.error("Error al eliminar percapitado");
        }
      })
      .catch((error) => {
        NotificationManager.error("Error al eliminar percapitado");
      });
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  selectorMotivo = (e) => {
    const motivo = e.target.value;
    this.setState({ motivo: motivo });
    //console.log("selectedEsp", selectedEsp);
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
          <DialogContent>
            <DialogContentText style={{ fontSize: "30px", fontWeight: "bold" }}>Eliminar de no percapitados</DialogContentText>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p>Para eliminar de la lista no percapitados ingrese un motivo</p>
                <FormControl style={{ marginLeft: "1%", width: "70%" }}>
                  <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.motivo} label="Tipo" onChange={this.selectorMotivo}>
                    <MenuItem value={null}>Seleccione razón</MenuItem>
                    <MenuItem value={2}>Usuario no responde</MenuItem>
                    <MenuItem value={3}>Per-cápita en otra comuna</MenuItem>
                    <MenuItem value={4}>No acepta inscripción</MenuItem>
                  </Select>
                </FormControl>
                <div style={{ display: "flex", marginBottom: "3%", marginLeft: "60%", marginTop: "2%" }}>
                  <Buttons
                    onClick={this.EliminarPercapitado}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "1%",
                      fontSize: "20px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                    }}
                  >
                    Eliminar
                  </Buttons>
                  <Buttons
                    onClick={this.salir}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "1%",
                      fontSize: "20px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                    }}
                  >
                    Cancelar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
