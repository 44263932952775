import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridContainer from "./../../../components/grid/GridContainer.js";
import Titulo from "./../../../components/titulo/Titulo.js";
import { Grid } from "@material-ui/core";
import "moment/locale/es";
import axios from "axios";
import CantidadArchivos from "./CantidadArchivos.js";
import CantidadTeleconsultas from "./CantidadTeleconsultas.js";
import CantidadIntentos from "./CantidadIntentos.js";
import CantidadReservadas from "./CantidadReservadas.js";
import CantidadProfesionales from "./CantidadProfesionales.js";

// CSS
import "./../../../assets/css/general.css";
import "./../../../assets/css/graficos.css";
import "./../../../assets/css/usuarios.css";

export default class MenuReporteriaTeleconsulta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarRelizadasPerd: true,
      mostrarArchivos: false,
      mostrarCantTele: false,
      mostrarIntentos: false,
      mostrarConsultasProf: false,
      opciones: [
        { label: "Por Cesfam", val: 0 },
        { label: "Por Comuna", val: 1 },
      ],
      tiempos: [
        { label: "Diario", val: 0 },
        { label: "Semanal", val: 1 },
        { label: "Mensual", val: 2 },
        { label: "Anual", val: 3 },
      ],
      comunas: [],
    };
  }

  componentDidMount() {
    this.cargarComunas();
  }

  cargarComunas = async () => {
    const apiUrl = global.config.modulos.videollamada_grafico + "lista_comunas/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var ind = 0;
      res.data.comunas.map((res) => {
        this.setState({ comunas: [...this.state.comunas, { label: res.comuna_nom, val: ind, id: res.comuna_id }] });
        ind = ind + 1;
      });
    });
  };

  mostrarOpcion(opcion) {
    switch (opcion) {
      case 1:
        this.setAllFalse();
        this.setState({ mostrarRelizadasPerd: true });
        break;
      case 2:
        this.setAllFalse();
        this.setState({ mostrarArchivos: true });
        break;
      case 3:
        this.setAllFalse();
        this.setState({ mostrarCantTele: true });
        break;
      case 4:
        this.setAllFalse();
        this.setState({ mostrarIntentos: true });
        break;
      case 5:
        this.setAllFalse();
        this.setState({ mostrarConsultasProf: true });
        break;
      default:
        this.setAllFalse();
        break;
    }
  }

  setAllFalse() {
    this.setState({ mostrarRelizadasPerd: false, mostrarArchivos: false, mostrarCantTele: false, mostrarIntentos: false, mostrarConsultasProf: false });
  }

  render() {
    return (
      <div className="middle-area">
        <Grid item xs={12} sm={4}>
          <Titulo links={[]} linkActual="" titulo="Reportes Teleconsulta" descripcion="" />
        </Grid>
        <br></br>
        <GridContainer>
          <Grid item xs={12} sm={12}>
            <a className={this.state.mostrarRelizadasPerd ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 1)}>
              Teleconsultas<br></br>realizadas y perdidas
            </a>
            <a className={this.state.mostrarArchivos ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 2)}>
              Archivos enviados<br></br>y recibidos
            </a>
            <a className={this.state.mostrarCantTele ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 3)}>
              Hora y cantidad<br></br>de teleconsultas
            </a>
            <a className={this.state.mostrarIntentos ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 4)}>
              Intentos <br></br> de llamada
            </a>
            <a className={this.state.mostrarConsultasProf ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 5)}>
              Teleconsultas por<br></br>profesional
            </a>
          </Grid>
        </GridContainer>
        <br></br>
        {this.state.mostrarRelizadasPerd ? <CantidadReservadas comunas={this.state.comunas} /> : null}
        {this.state.mostrarArchivos ? <CantidadArchivos comunas={this.state.comunas} /> : null}
        {this.state.mostrarCantTele ? <CantidadTeleconsultas comunas={this.state.comunas} /> : null}
        {this.state.mostrarIntentos ? <CantidadIntentos comunas={this.state.comunas} /> : null}
        {this.state.mostrarConsultasProf ? <CantidadProfesionales comunas={this.state.comunas} /> : null}
      </div>
    );
  }
}
