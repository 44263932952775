import { createStore } from "redux";
import rootReducer from "./reducers/reducers";
//import { saveState, loadState } from "./actions/actions";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor };
