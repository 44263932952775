import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Divider } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "../../../assets/css/graficosD.css";
import Cargando from "components/Helpers/Cargando";
import VistaUsuarios from "../../../components/GestionPrioritarios/VistaUsuarios";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { YAxis } from "recharts";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
export default class GraficoPrincipal extends Component {
  constructor(props) {
    super(props);
    console.log("props cesfmas", props.cesfamsVisibles);
    this.state = {
      datas: [],
      datos: [],
      fecha: props.fecha,
      cliente: props.cliente,
      cesfam: props.cesfam,
      esp_especialidad: props.especialidad,
      sector: props.sector,
      sexo: props.sexo,
      fecha_inicio: props.fecha_inicio,
      fecha_final: props.fecha_final,
      id_cliente: props.id_cliente,
      cesfamsVisibles: props.cesfamsVisibles,
      id_persona: "",
      id_especialidad: "",
      heiGra: "210rem",
      root: "",
      modal: false,
      titulo: "Ranking global ",
      canal: props.canal,
      tipo: props.tipo,
      tex: "",
      tipCes: "",
      esp: props.esp,
      cargando: props.cargando,
      modalUsuario: false,
      id_usuario: "",
      modalCom: false,
      comuna: "",
      modalCes: false,
      cesfams: "",
      displayPirncipal: props.displayPirncipal,
    };
  }
  handleClickOpen = () => {
    this.setState({ modal: true });
  };
  handleClose = () => {
    this.setState({ modal: false, modalCom: false, modalCes: false });
  };
  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  GraficoPrincipal() {
    this.setState({ datas: [] });

    if (this.state.fecha_inicio === "" && this.state.fecha_final !== "") {
      NotificationManager.info("Seleccione una fecha de inicio");
      this.setState({ fecha_final: "" });
    }
    if (this.state.fecha_inicio !== "" && this.state.fecha_final === "") {
      NotificationManager.info("Seleccione una fecha final");
      this.setState({ fecha_inicio: "" });
    }

    if (this.state.tipo === "Persona") {
      this.setState({
        tex: "Ranking: {posicion}\nNombre: {Nombre}\nRut: {Rut}\nComuna: {comuna}\nCesfam: {cesfam}\nInasistencias: {NumInasistencia}",
      });
    } else {
      if (this.state.fecha_inicio !== "" && this.state.fecha_final !== "" && this.state.fecha !== "") {
        this.setState({
          tex: `{Prefijo} {NombreE} \nPersonas con NSP entre ${this.state.fecha_inicio} y ${this.state.fecha_final}: {NumInasistencia}`,
        });
      }
      if (this.state.fecha_inicio !== "" && this.state.fecha_final !== "" && this.state.fecha == "") {
        this.setState({
          tex: `{Prefijo} {NombreE} \nPersonas con NSP entre ${this.state.fecha_inicio} y ${this.state.fecha_final}: {NumInasistencia}`,
        });
      }
      if (this.state.fecha && this.state.fecha_inicio == "" && this.state.fecha_final == "") {
        this.setState({
          tex: `{Prefijo} {NombreE} \nPersonas con NSP desde ${this.state.fecha} hasta 6 meses atras: {NumInasistencia}`,
        });
      }
      if (this.state.fecha_inicio == "" && this.state.fecha_final == "" && this.state.fecha == "") {
        this.setState({
          tex: "{Prefijo} {NombreE} \nPersonas con NSP en los ultimos 6 meses: {NumInasistencia}",
        });
      }
    }
    if (this.state.tipo === "Canal") {
      this.setState({
        cargando: false,
        displayPirncipal: "none",
      });
    }

    console.log(this.state.cesfamsVisibles);
    axios
      .get(global.config.modulos.grafico_demanda + "NewRankingNSP/", {
        headers: authHeader(),
        params: {
          fecha: this.state.fecha,
          id_cliente: this.state.id_cliente,
          id_cesfam: this.state.cesfam,
          cesfamsVisibles: this.state.cesfamsVisibles,
          sector: this.state.sector,
          sexo: this.state.sexo,
          fecha_inicio: this.state.fecha_inicio,
          fecha_final: this.state.fecha_final,
          canal: this.state.canal,
          tipo: this.state.tipo,
          esp: this.state.esp,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay NSP");
          this.setState({ cargando: false });
          return;
        }
        this.setState({ datas: res.data.datos, excel: res.data.datos2 }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          if (this.state.cliente !== "") {
            this.setState({
              titulo: `Ranking Global`,
            });
          }

          if (this.state.cesfam !== "") {
            this.setState({
              titulo: `Ranking Global`,
            });
          }
          if (this.state.tipo === "Comuna") {
            this.setState({
              titulo: `Ranking Global`,
            });
          }

          let root = am5.Root.new("chartdiv1");

          const responsive = am5themes_Responsive.new(root);

          responsive.addRule({
            relevant: am5themes_Responsive.widthM,
            applying: function () {
              chart.set("layout", root.verticalLayout);
              YAxis.setAll({
                x: null,
                centerX: null,
                y: am5.p0,
                centerY: am5.p0,
              });
            },
            removing: function () {
              chart.set("layout", root.horizontalLayout);
              xAxis.setAll({
                x: am5.p50,
                centerX: am5.p50,
                y: null,
                centerY: null,
              });
            },
          });

          root.setThemes([am5themes_Animated.new(root), responsive]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
            })
          );

          chart.zoomOutButton.set("forceHidden", true);

          let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
          });

          yRenderer.grid.template.set("location", 1);

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0,
              categoryField: "NombreE",
              renderer: yRenderer,
              tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] }),
              interactive: true,
            })
          );

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0,
              min: 0,
              extraMax: 0.1,
              renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
              }),
            })
          );

          let tooltip = am5.Tooltip.new(root, {
            pointerOrientation: "left",
            autoTextColor: false,
            fill: am5.color(0xff5566),
            labelText: this.state.tex,
          });

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "NumInasistencia",
              categoryYField: "NombreE",
              tooltip: tooltip,
            })
          );

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                centerY: am5.p50,
                text: "{NumInasistencia}",
                populateText: true,
              }),
            });
          });

          series.columns.template.setAll({
            cornerRadiusTR: 5,
            cornerRadiusBR: 5,
            strokeOpacity: 0,
          });

          series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          let thiss = this;

          series.on("visible", function (visible, target) {
            if (visible) {
              thiss.setState({ cargando: false });
            }
          });

          series.columns.template.events.on("click", function (ev) {
            if (thiss.state.tipo === "Persona") {
              thiss.setState(
                {
                  id_persona: ev.target.dataItem.dataContext.id_persona,
                  id_especialidad: ev.target.dataItem.dataContext.id_esp,
                  modal: true,
                },
                () => {
                  thiss.setState({ datos: [] });
                  axios
                    .get(global.config.modulos.grafico_demanda + "RankingFechasInasistidas/", {
                      headers: authHeader(),
                      params: {
                        id_persona: thiss.state.id_persona,
                        fecha: thiss.state.fecha,
                        fecha_inicio: thiss.state.fecha_inicio,
                        fecha_final: thiss.state.fecha_final,
                        canal: thiss.state.canal,
                        sector: thiss.state.sector,
                        esp: thiss.state.esp,
                        id_especialidad: thiss.state.id_especialidad,
                        sexo: thiss.state.sexo,
                      },
                    })
                    .then((resp) => {
                      if (!resp.data.estado) {
                        NotificationManager.info("No hay datos");
                        return;
                      }
                      thiss.setState({ datos: resp.data.datos }, () => {
                        try {
                          thiss.state.root.dispose();
                        } catch (e) {}
                      });
                    });
                }
              );
            }
            if (thiss.state.tipo === "Comuna") {
              console.log(ev.target.dataItem.dataContext);
              thiss.setState(
                {
                  comuna: ev.target.dataItem.dataContext.NombreE,
                  modalCom: true,
                },
                () => {
                  thiss.setState({ datos: [] });
                  axios
                    .get(global.config.modulos.grafico_demanda + "cantidadInasistencias/", {
                      headers: authHeader(),
                      params: {
                        comuna: thiss.state.comuna,
                        fecha: thiss.state.fecha,
                        fecha_inicio: thiss.state.fecha_inicio,
                        fecha_final: thiss.state.fecha_final,
                        canal: thiss.state.canal,
                        sector: thiss.state.sector,
                        esp: thiss.state.esp,
                        sexo: thiss.state.sexo,
                      },
                    })
                    .then((resp) => {
                      if (!resp.data.estado) {
                        NotificationManager.info("No hay datos");
                        return;
                      }
                      thiss.setState({ datos: resp.data.datos }, () => {
                        try {
                          thiss.state.root.dispose();
                        } catch (e) {}
                      });
                    });
                }
              );
            }

            if (thiss.state.tipo === "Cesfam") {
              thiss.setState(
                {
                  cesfams: ev.target.dataItem.dataContext.NombreE,
                  modalCes: true,
                },
                () => {
                  thiss.setState({ datos: [] });
                  axios
                    .get(global.config.modulos.grafico_demanda + "cantidadInasistencias/", {
                      headers: authHeader(),
                      params: {
                        cesfam: thiss.state.cesfams,
                        fecha: thiss.state.fecha,
                        fecha_inicio: thiss.state.fecha_inicio,
                        fecha_final: thiss.state.fecha_final,
                        canal: thiss.state.canal,
                        sector: thiss.state.sector,
                        esp: thiss.state.esp,
                        sexo: thiss.state.sexo,
                      },
                    })
                    .then((resp) => {
                      if (!resp.data.estado) {
                        NotificationManager.info("No hay datos");
                        return;
                      }
                      thiss.setState({ datos: resp.data.datos }, () => {
                        try {
                          thiss.state.root.dispose();
                        } catch (e) {}
                      });
                    });
                }
              );
            }
          });

          let data = [];

          if (this.state.datas.length < 100) {
            let i = this.state.datas.length - 1;

            for (let index = i; index > -1; index--) {
              data.push(this.state.datas[index]);
            }
          } else {
            for (let index = 99; index > -1; index--) {
              data.push(this.state.datas[index]);
            }
          }

          if (this.state.datas.length < 3) {
            this.setState({ heiGra: "1.5rem" });
          }
          if (this.state.datas.length < 6) {
            this.setState({ heiGra: "20rem" });
          }
          if (this.state.datas.length > 5 && this.state.datas.length < 11) {
            this.setState({ heiGra: "35rem" });
          }
          if (this.state.datas.length > 10 && this.state.datas.length < 20) {
            this.setState({ heiGra: "50rem" });
          }
          if (this.state.datas.length > 19 && this.state.datas.length < 26) {
            this.setState({ heiGra: "64rem" });
          }
          if (this.state.datas.length > 25 && this.state.datas.length < 33) {
            this.setState({ heiGra: "75rem" });
          }
          if (this.state.datas.length > 33 && this.state.datas.length < 40) {
            this.setState({ heiGra: "87rem" });
          }
          if (this.state.datas.length > 40 && this.state.datas.length < 47) {
            this.setState({ heiGra: "93rem" });
          }

          yAxis.data.setAll(data);

          yAxis.get("renderer").labels.template.setup = (target) => {
            target.setAll({
              cursorOverStyle: "pointer",
              background: am5.Rectangle.new(root, {
                fill: am5.color(0x000000),
                fillOpacity: 0,
              }),
            });
          };

          yAxis.get("renderer").labels.template.events.on("click", (e) => {
            if (thiss.state.tipo === "Persona") {
              thiss.setState({ id_usuario: e.target.dataItem.dataContext.id_persona, modalUsuario: true });
            }
          });

          let colores = [];
          let length = 0;

          if (this.state.datas.length > 100) {
            length = 100;
          } else {
            length = this.state.datas.length;
          }

          for (let index = 0; index < length; index++) {
            colores.push(am5.color(this.state.datas[index]["color"]));
          }

          chart.get("colors").set("colors", colores.reverse());

          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: this.state.excel,
            title: `Datos Policonsultante`,
            pdfOptions: {
              imageFormat: "jpg",
              includeData: true,
            },
            htmlOptions: {
              disabled: true,
            },
            jpgOptions: {
              disabled: true,
            },
            csvOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            xlsxOptions: {
              imageFormat: "jpg",
              includeData: true,
            },
            pngOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            filePrefix: "datos policonsultantes",
          });

          series.data.setAll(data);

          chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
              behavior: "none",
              xAxis: xAxis,
              yAxis: yAxis,
            })
          );
          series.appear(1000);
          chart.appear(1000, 100);
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.GraficoPrincipal();
  }

  render() {
    return (
      <>
        <div
          id="tablas"
          style={{
            overflowY: "scroll",
            overflowX: "scroll",
            width: "90%",
            height: "70%",
            zIndex: 1,
            position: "fixed",
            textAlign: "center",
            display: this.state.displayPirncipal,
          }}
        >
          <h2 style={{ marginTop: "20px" }}>{this.state.titulo} </h2>

          <div
            id="chartdiv1"
            style={{
              display: "flex",
              width: "95%",
              height: this.state.heiGra,
              zIndex: 1,
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Dialog open={this.state.modal} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>{"Fechas de los Nsp"}</div>
                  <Divider />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="miTablaWrapper">
                    <table className="miTabla">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Canal</th>
                          <th>Especialidad</th>
                          <th>Nombre Profesional</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.datos.map((item) => {
                          return (
                            <tr key={item.fecha_Atenciones + item.canal + item.RutP}>
                              <td>{item.fecha_Atenciones}</td>
                              <td>{item.canal}</td>
                              <td>{item.RutP}</td>
                              <td>{item.NombreP}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose()} color="primary" autoFocus>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={this.state.modalCom} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>{"Cantidad de Nsp por Persona por Comuna"}</div>
                  <h4>{this.state.comuna}</h4>
                  <Divider />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="miTablaWrapper">
                    <table className="miTabla">
                      <thead>
                        <tr>
                          <th>Cantidad de Personas</th>
                          <th>Cantidad de Nsp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.datos.map((item) => {
                          return (
                            <tr key={item.Cant_Ina + item.Cant_Perso}>
                              <td>{item.Cant_Perso}</td>
                              <td>{item.Cant_Ina}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose()} color="primary" autoFocus>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={this.state.modalCes} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>{"Cantidad de Nsp por Persona por Cesfam"}</div>
                  <h4>{this.state.cesfams}</h4>
                  <Divider />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="miTablaWrapper">
                    <table className="miTabla">
                      <thead>
                        <tr>
                          <th>Cantidad de Personas</th>
                          <th>Cantidad de Nsp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.datos.map((item) => {
                          return (
                            <tr key={item.Cant_Ina + item.Cant_Perso}>
                              <td>{item.Cant_Perso}</td>
                              <td>{item.Cant_Ina}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose()} color="primary" autoFocus>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div>
            {this.state.cargando ? <Cargando /> : null}
            {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.id_usuario}></VistaUsuarios> : null}
          </div>
        </div>
      </>
    );
  }
}
