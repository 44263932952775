import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import reportes from "./../../assets/css/reportes.css";
import "moment/locale/es";
import { connect } from "react-redux";

import ModalPoliconsultante from "../../components/Reportes/ModalPoliconsultante";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import MaterialTable from "material-table";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import ListaEspecialidades from "./ListaEspecialidades";
import chileanRut from "chilean-rut";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHeader from "../../components/Table/TableHeader";
import Paginacion from "./../../components/Table/Pagination.js";
import { TableCell } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import { LinearProgress } from "@material-ui/core";

import signo from "./../../assets/img/signo.svg";

class ListaPoliconsultantes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuariosPoliconsultantes: [],
      cesfam: store.getState().cesfam,
      showModalUsuarioPoliconsultante: false,
      selectedEsp: null,
      id_usuario: null,
      especialidad_id: null,
      especialidades: [],
      filas: 10,
      total: 0,
      pagina: 0,

      ordenarPrioridad: null,
      texto_filtro: null,
      estadoCarga: false,
    };
    console.log(this.state.selectedEsp);
  }

  componentDidMount() {
    this.especialidades();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Policonsultantes <span title='Podrá visualizar toda la información relacionada a los pacientes policonsultantes'><img src=" + signo + "></img></span>";
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchPoliconsultantes();
  };

  aplicarFiltros = () => {
    this.setState(
      {
        estadoCarga: true,
      },
      () => this.fetchPoliconsultantes()
    );
  };
  fetchPoliconsultantes = () => {
    const apiURL = global.config.modulos.reportes + "listado_policonsultante/";
    document.getElementById("cargando").style.display = "block";
    axios
      .get(apiURL, {
        headers: authHeader(),
        params: {
          selectedEsp: this.state.selectedEsp,
          cesfam: this.state.cesfam,
          texto_filtro: this.state.texto_filtro,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            usuariosPoliconsultantes: res.data.datos,
          });
          document.getElementById("cargando").style.display = "None";
        } else {
          this.setState({
            usuariosPoliconsultantes: [],
          });
          document.getElementById("cargando").style.display = "None";
        }
      });
  };

  showModalUsuarioPoliconsultante = (id_usuario, especialidad_id) => {
    this.setState({
      showModalUsuarioPoliconsultante: true,
      id_usuario: id_usuario,
      especialidad_id: especialidad_id,
    });
  };

  hideModalListadoPolicosultante = () => {
    this.setState({ showModalUsuarioPoliconsultante: false });
  };

  selectorEspecialidad = (e) => {
    const selectedEsp = e.target.value;
    this.setState({ selectedEsp: selectedEsp });
    console.log("selectedEsp", selectedEsp);
  };

  especialidades = () => {
    axios
      .get(global.config.modulos.reportes + "listado_especialidades/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            especialidades: res.data.datos,
          });
        }
      });
  };

  filtroTexto = (e) => {
    const texto_filtro = e.target.value;
    this.setState({ texto_filtro: texto_filtro });
  };

  render() {
    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <div style={{ marginLeft: "1%", display: "flex" }}>
                <FormControl style={{ marginLeft: "1%", width: "20%" }}>
                  <InputLabel id="demo-simple-select-label">Especialidad</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.selectedEsp} label="Tipo" onChange={this.selectorEspecialidad}>
                    <MenuItem value={null}>Seleccione especialidad</MenuItem>
                    {this.state.especialidades.map((especialidad) => (
                      <MenuItem value={especialidad.id}>{especialidad.nombre}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {this.state.estadoCarga ? (
                  <Input
                    type="text"
                    name="texto_filtro"
                    value={this.state.texto_filtro}
                    onChange={this.filtroTexto}
                    placeholder="Nombre o Rut sin puntos ni guión"
                    className={"inputText"}
                    style={{
                      padding: 6,
                      marginTop: "1%",
                      border: "1px solid black",
                      borderRadius: 5,
                      float: "left",
                      marginLeft: "2%",
                      height: "40px",
                      width: "30%",
                    }}
                  />
                ) : null}

                <button className={"btnOpcion fondoNaranjo"} onClick={this.aplicarFiltros}>
                  Filtrar
                </button>
              </div>

              {/*    <ListaEspecialidades selectedEsp ={this.state.selectedEsp} 
                        cambiarEspecialidad = {this.cambiarEspecialidad}/>  */}

              {this.state.estadoCarga && (
                <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
                  <GridItem xs={12} style={{ marginTop: 30 }}></GridItem>
                  <MaterialTable
                    id="tablaBloqueos"
                    title=""
                    columns={[
                      {
                        title: "Rut",
                        render: (rowData) => (
                          <React.Fragment>
                            <div>{chileanRut.format(rowData.usu_rut, true)}</div>
                          </React.Fragment>
                        ),
                      },
                      { title: "Nombre", field: "nombre_usuario" },
                      {
                        title: "Edad",
                        field: "edad",
                      },
                      { title: "Sector", field: "nombre_sector" },
                      { title: "N° de horas reservadas", field: "total" },
                      {
                        title: "Ver horas reservadas",
                        render: (rowData) => (
                          <React.Fragment>
                            <div>
                              <img class="btnAccionesAnulacion" src={ver_mas} aria-label="Ver horas pedidas" title="Ver horas pedidas" onClick={() => this.showModalUsuarioPoliconsultante(rowData.age_usuario, rowData.age_especialidad)}></img>
                            </div>
                          </React.Fragment>
                        ),
                      },
                    ]}
                    data={this.state.usuariosPoliconsultantes}
                    options={{
                      sorting: true,
                      search: false,
                    }}
                    localization={{
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                    }}
                  ></MaterialTable>
                  <LinearProgress id="cargando" style={{ display: "none" }} />
                  {/* <TableContainer>
                    <Table
                      className="tablaGeneralGestion bordeTabla"
                      aria-label="simple table"
                    >
                      <TableHeader
                        nombreTitulo={this.state.nombreTitulo}
                        datocampo={this.handleFiltradorCampo}
                      />

                      <TableBody>
                        {this.state.usuariosPoliconsultantes.map(
                          (row, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                {chileanRut.format(row.usu_rut, true)}
                              </TableCell>
                              <TableCell align="center">
                                {row.nombre_usuario}
                              </TableCell>
                              <TableCell align="center">{row.edad}</TableCell>
                              <TableCell align="center">
                                {row.nombre_sector}
                              </TableCell>
                              <TableCell align="center">{row.total}</TableCell>
                              <TableCell align="center">
                                {" "}
                                <React.Fragment>
                                  <div>
                                    <img
                                      class="btnAccionesAnulacion"
                                      src={ver_mas}
                                      aria-label="Ver horas pedidas"
                                      title="Ver horas pedidas"
                                      onClick={() =>
                                        this.showModalUsuarioPoliconsultante(
                                          row.age_usuario,
                                          row.age_especialidad
                                        )
                                      }
                                    ></img>
                                  </div>
                                </React.Fragment>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                    <Paginacion
                      total={this.state.total}
                      paginacion={this.handlePagination}
                      filas_paginacion={this.handleFilasPagination}
                    />
                  </TableContainer> */}
                </GridItem>
              )}
            </GridItem>
          </GridContainer>
        </div>

        {this.state.showModalUsuarioPoliconsultante ? <ModalPoliconsultante ocultar={this.hideModalListadoPolicosultante} id_usuario={this.state.id_usuario} especialidad_id={this.state.especialidad_id}></ModalPoliconsultante> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaPoliconsultantes);
