// core components
import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "store/index";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import axios from "axios";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import Countdown from "react-countdown";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

//JS
import ModalSolicitud from "../Dialog/ModalSolicitud";

// IMG
import imgObservacion from "assets/img/solicitud_trabajo/iconos/motivo.svg";

export default class GestionDemandaIngreso extends Component {
  constructor(props) {
    super(props);

    this.state = {
      abrirModal: false,
      dataSolicitud: null,
      tituloModalSolicitud: "",
    };
  }

  componentDidMount() {
    this.solicitudes();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.seleccion !== this.props.seleccion) {
      this.solicitudes();
    }
  }

  solicitudes = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          tipo: 5,
          uspId: store.getState().usp,
          estado: "5,6",
          tipo_solicitud: this.props.seleccion,
        },
      })
      .then((res) => {
        this.props.conteo();
        if (res.data.estado) {
          this.setState({
            todas_solicitudes: res.data.datos,
          });
        } else {
          this.setState({
            todas_solicitudes: [],
          });
        }
      });
  };

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span style={{ color: "red" }}>
          - {days + " días, "} {hours}:{minutes}:{seconds}
        </span>
      );
    } else {
      return (
        <span>
          {days + " días, "}
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  irTicket = (cod) => {
    this.props.history.push({
      pathname: "/solicitud_trabajo",
      params: { cod },
    });
  };

  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  abrirModalMotivo = (data, titulo, vista) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo, vistaModal: vista });
  };

  render() {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: "1rem",
      },
    }));

    function BootstrapTooltip(props) {
      const classes = useStylesBootstrap();

      return <Tooltip arrow classes={classes} {...props} />;
    }

    return (
      <>
        <GridContainer style={{ overflowY: "scroll", height: "85%" }}>
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <div style={{ marginBottom: 100, overflowY: "scroll" }}>
                <Grid item xs={12} sm={12}>
                  <div className="divBordeTablaHistorialHoras" style={{ paddingLeft: "20px" }}>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: "Fecha de ingreso",
                          render: (rowData) => (
                            <React.Fragment>
                              <p>{rowData.fecha_solicitud.split(" ")[0]}</p>
                              <p>{rowData.fecha_solicitud.split(" ")[1]} hrs</p>
                            </React.Fragment>
                          ),
                          field: "fecha_solicitud",
                          headerStyle: {
                            fontSize: "15px",
                          },
                        },
                        {
                          title: "N° de ticket",
                          render: (rowData) => (
                            <React.Fragment>
                              <span style={{ cursor: "pointer", color: rowData.sol_estado == 8 ? "#808080" : null }} onClick={() => this.irTicket(rowData.sol_codigo)}>
                                {rowData.sol_codigo}
                              </span>
                            </React.Fragment>
                          ),
                          field: "sol_codigo",
                          headerStyle: {
                            fontSize: "15px",
                          },
                        },
                        {
                          title: "Nombre",
                          field: "nom_dev",
                        },

                        {
                          title: "CESFAM",
                          field: "ces_nombre",
                        },

                        {
                          title: "Prioridad",
                          field: "prio_desc",
                        },
                        {
                          title: "Tecnico a cargo",
                          field: "nom_dev",
                        },

                        {
                          title: "Fecha de entrega a cliente",
                          render: (rowData) => (
                            <React.Fragment>
                              {rowData.entrega_aprox_cliente == null ? (
                                "Sin asignar"
                              ) : (
                                <>
                                  <p>{rowData.entrega_aprox_cliente.split(" ")[0]}</p>
                                </>
                              )}
                            </React.Fragment>
                          ),
                          field: "sol_entrega_aprox_cliente",
                        },
                        {
                          title: "Fecha de entrega del técnico",
                          render: (rowData) => (
                            <React.Fragment>
                              {rowData.sol_fecha_entrega_dev == null ? (
                                "Sin asignar"
                              ) : (
                                <>
                                  <p>{rowData.fecha_entrega_dev.split(" ")[0]}</p>
                                  <p>{rowData.fecha_entrega_dev.split(" ")[1]} hrs</p>
                                </>
                              )}
                              <Countdown date={rowData.sol_fecha_entrega_dev} renderer={this.renderer} overtime={true} zeroPadTime={2} />
                            </React.Fragment>
                          ),
                          field: "sol_fecha_entrega",
                        },
                        {
                          title: "Motivo",
                          render: (rowData) => (
                            <React.Fragment>
                              <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={imgObservacion} style={{ width: "30px", cursor: "pointer" }} onClick={() => this.abrirModalMotivo(rowData, "Motivo", 1)} />
                              </div>
                            </React.Fragment>
                          ),
                          field: "tipo",
                        },
                        {
                          title: "Observacion",
                          render: (rowData) => (
                            <React.Fragment>
                              <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={imgObservacion} onClick={() => this.abrirModalMotivo(rowData, "Observación", 2)} style={{ width: "30px", cursor: "pointer", marginRight: "5%", marginLeft: "-5%" }} />
                              </div>
                            </React.Fragment>
                          ),
                          field: "sol_motivo",
                        },
                        {
                          title: "Fecha ingreso a producción",
                          render: (rowData) => (
                            <React.Fragment>
                              <p>{rowData.fecha_envioQa.split(" ")[0]}</p>
                              <p>{rowData.fecha_envioQa.split(" ")[1]} hrs</p>
                            </React.Fragment>
                          ),
                          field: "fecha_envioQa",
                        },
                        {
                          title: "Estado",
                          render: (rowData) => (
                            <React.Fragment>
                              {rowData.sol_qa ? (
                                <p>Aprobado</p>
                              ) : rowData.sol_qa == null ? (
                                <p>En proceso</p>
                              ) : (
                                !rowData.sol_qa && (
                                  <BootstrapTooltip title={rowData.sol_qa_rechazo}>
                                    <p>Rechazado</p>
                                  </BootstrapTooltip>
                                )
                              )}
                            </React.Fragment>
                          ),
                        },
                      ]}
                      data={this.state.todas_solicitudes}
                      options={{
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        rowStyle: (rowData) => ({
                          backgroundColor: rowData.sol_prioridad_id == 2 ? "#d3ecd3" : rowData.sol_prioridad_id == 3 ? "#f6edcc" : rowData.sol_prioridad_id == 4 ? "#c6d7ec" : rowData.sol_prioridad_id == 5 ? "#fde4d1" : rowData.sol_prioridad_id == 6 ? "#f3cbca" : rowData.sol_prioridad_id == 7 ? "#ddcec5" : null,
                        }),
                      }}
                      localization={{
                        header: {
                          actions: "Acciones",
                        },
                        pagination: {
                          labelDisplayedRows: "{from}-{to} de {count}",
                          labelRowsSelect: "filas",
                          firstTooltip: "primera",
                          previousTooltip: "anterior",
                          nextTooltip: "siguiente",
                          lastTooltip: "última",
                        },
                        toolbar: {
                          nRowsSelected: "{0} fila(s) seleccionadas",
                          searchTooltip: "Buscar",
                          searchPlaceholder: "Buscar",
                        },
                        body: {
                          emptyDataSourceMessage: <span style={{ fontSize: "1.5rem" }}>No tiene tickets pendientes</span>,
                          filterRow: {
                            filterTooltip: "Buscar",
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>
              </div>
            </div>
          </GridItem>
        </GridContainer>

        {this.state.abrirModal && <ModalSolicitud data={this.state.dataSolicitud} titulo={this.state.tituloModalSolicitud} salir={this.cerrarModal} vista={this.state.vistaModal} solicitudes={this.solicitudes} />}
      </>
    );
  }
}
