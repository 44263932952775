import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";
import MaterialTable from "material-table";

export default class Ver_detalle_llamadas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: store.getState().usp,
      cesfamus: store.getState().cesfam,
      //herramientas: store.getState().herramientas,
      //cliente: store.getState().cliente,
      open: true,
      //canal: 5,
      //rutExtranjero: false,
      //agregarndoUsuario: false,
      //ordenarPrioridad: null,
      pagina: 0,
      filas: 10,
      total: 0,
      id_oirs: props.id_oirs,
      detalle: [],
      historial: [],
      fecha_inicio: null,
      observacion: null,
      veces_llamado: "aun sin contactar",
      fecha_ultima_ves_llamado: null,
      veces_que_a_anulado: 0,
      fecha_anulada: null,
    };
  }
  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  obtenerHistorial = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_detalle_llamadas/", {
        headers: authHeader(),
        params: {
          idlista: this.state.id_oirs,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            detalle: res.data.datos,
            historial: res.data.datos[0]["historial_ofrecimientos"],
            veces_llamado: res.data.datos[0]["pre_veces_llamado"],
            fecha_ultima_ves_llamado: res.data.datos[0]["pre_ultima_fecha_llamado"],
            veces_que_a_anulado: res.data.datos[0]["pre_veces_anulada"],
            fecha_anulada: res.data.datos[0]["pre_fecha_anulada"],
            veces_rechazado: res.data.datos[0]["pre_veces_rechazado"],
            veces_nsp: res.data.datos[0]["pre_veces_nsp"],
            fecha_pausa_llamada: res.data.datos[0]["pre_fecha_pausa_llamada"],
          });
        }
      });
  };
  componentDidMount() {
    this.obtenerHistorial();
  }

  fecha_inicio = (e) => {
    const fecha_inicio = e.target.value;
    this.setState({ fecha_inicio: fecha_inicio });
    //console.log(this.state.fecha_inicio);
  };
  observacion = (e) => {
    const observacion = e.target.value;
    this.setState({ observacion: observacion });
    //console.log(this.state.tratamiento);
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="detalleHorasBloqueadas">
          <DialogContent>
            <div style={{ marginBottom: "5px" }}>
              <h2>Detalle</h2>
            </div>

            <GridContainer>
              <GridItem>
                <div
                  style={{
                    marginBottom: "5px",
                    marginTop: "10px",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      marginLeft: "5px",
                      marginBottom: "5px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    Veces que paciente Anulo:{" "}
                  </p>{" "}
                  <p
                    style={{
                      marginLeft: "5px",
                      marginBottom: "5px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.veces_que_a_anulado}
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "5px",
                    marginTop: "5px",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      marginLeft: "5px",
                      marginBottom: "5px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    Veces que paciente No se Presentó:{" "}
                  </p>{" "}
                  <p
                    style={{
                      marginLeft: "5px",
                      marginBottom: "5px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.veces_nsp}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      marginLeft: "5px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    Veces que Paciente rechazo una hora
                  </p>{" "}
                  <p
                    style={{
                      marginLeft: "5px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.veces_rechazado}
                  </p>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ display: "flex" }}>
                <MaterialTable
                  style={{ width: "100% ", fontSize: "14px" }}
                  title=""
                  columns={[
                    {
                      title: "Canal",
                      field: "nombre_canal",
                    },
                    { title: "Télefono", field: "telefono" },
                    { title: "Fecha registro ", field: "hoo_fecha_registro" },
                    {
                      title: "Detalle",
                      field: "hoo_observacion",
                      render: (rowData) => (
                        <React.Fragment>
                          <p
                            style={{
                              backgroundColor: rowData.color_detalle,
                              color: "#fff",
                              borderRadius: "5px",
                            }}
                          >
                            {rowData.hoo_observacion}
                          </p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: "Estado",
                      field: "respuesta",
                      render: (rowData) => (
                        <React.Fragment>
                          <p
                            style={{
                              backgroundColor: rowData.color_respuesta,
                              color: "#fff",
                              borderRadius: "5px",
                            }}
                          >
                            {rowData.respuesta}
                          </p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                  data={this.state.historial}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Usuario aun no se a contactado",
                    },
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    marginLeft: "90%",
                    marginTop: "30px",
                  }}
                >
                  <Buttons
                    onClick={this.salir}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Salir
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
