import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import { store } from "./../../store/index";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Countdown from "react-countdown";
import Paginacion from "./../../components/Table/Pagination.js";
import ModalTelefonos from "./../../components/Gestion/TelefonosHistorial.js";
import ModalModificarUsuario from "./../../components/Gestion/ModificarDatosUsuario.js";
import ModalModificarRequerimiento from "./../../components/Gestion/ModificarRequerimiento.js";
import Motivo from "./../../components/Gestion/Motivo.js";
import AgregarObservacion from "./../../components/Gestion/AgregarObservacion";
import ModificarObservacion from "./../../components/Gestion/ModificarObservacion";
import ObservacionesTeleconsulta from "./../../components/Gestion/ObservacionesTeleconsulta";
import RepetirCaso from "./../../components/Gestion/RepetirCaso";
import FinalizarCaso from "./../../components/Gestion/FinalizarCaso";
import FinalizarCasoMultiple from "./../../components/Gestion/FinalizarCasoMultiple";

import DerivarCaso from "./../../components/Gestion/DerivarCasoCovid";
import ReasignarCaso from "./../../components/Gestion/ReasignarCaso";
import { confirmAlert } from "react-confirm-alert"; // Import
import FormControl from "@material-ui/core/FormControl";
import copiar from "../../assets/img/usuarios/copiar.svg";
import CasosRegistrados from "./../../components/Gestion/CasosRegistrados";
import ModificarSector from "./../../components/Gestion/ModificarSector";

import Chat from "./../../components/Helpers/Chat";
import finalizar from "../../assets/img/gestion/finalizar.svg";
import derivar from "../../assets/img/gestion/derivar.svg";
import derivarUrgente from "../../assets/img/gestion/derivar_urgente.svg";
import agendar from "../../assets/img/gestion/agendar.svg";
import obs_teleconsulta from "../../assets/img/gestion/obs_teleconsulta.svg";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import iconoChat from "../../assets/img/gestion/comentario.svg";
import iconoChatDeshabilitado from "../../assets/img/gestion/app_no_habilitada.svg";
import pausar from "../../assets/img/gestion/pausar.svg";
import reasignar from "../../assets/img/gestion/reasignar.svg";
import repetir from "../../assets/img/gestion/repetir.svg";
import editar from "../../assets/img/gestion/editar.svg";
import Tooltip from "@material-ui/core/Tooltip";
import AgendarHora from "./../../components/Gestion/AgendarHora";
import AnularHora from "./../../components/Gestion/AnularHora";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { NotificationManager } from "react-notifications";
// CSS
import "./../../assets/css/gestion.css";

export default class GestionGeneralProceso extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerta: false,
      ubicacion: 3,
      tipo: 2,
      pagina: 0,
      filas: 10,
      secId: 0,
      total: 0,
      demanda: [],
      preguntas: false,
      especialidad: null,
      comentariosDemanda: false,
      gegId: null,
      ggoId: null,
      ggoObservacion: "",
      cesfam: props.cesfam,
      usp: props.uspId,
      modalTelefonos: false,
      modalModificarUsuario: false,
      modalModificarRequerimiento: false,
      modalMotivo: false,
      modalAgregarObservacion: false,
      modalModificarObservacion: false,
      modalObservacionesTeleconsulta: false,
      modalCasosRegistrados: false,
      modalRepetirCaso: false,
      modalFinalizarCaso: false,
      modalFinalizarCasoMultiple: false,
      modalDerivarCaso: false,
      modalReasignarCaso: false,
      nombre_usuario_panel: "",
      modalAgendarHora: false,
      modalAnularHora: false,
      casosSeleccionados: [],

      usuId: null,
      requerimiento: "",
      uspResignar: null,

      textoMotivo: null,
      audioMotivo: null,
      audioMotivoInicio: null,
      audioMotivoTermino: null,

      rut: null,
      direccion: null,
      cesfam_nombre: null,
      nombre_usuario: null,

      texto_filtro: props.rutFiltro || "",
      tomado_por_filtro: props.uspFiltro ? props.uspId : "",
      riesgo_filtro: null,
      usuarios_panel_filtro: [],
      cesfam_vista: store.getState().cesfam,
      usuario_panel_vista: store.getState().usp,
      chat: null,
      modalChat: false,
      derivacionUrgencia: 1,
      nombre_cesfam: props.nombre_cesfam,
      id_cesfam_usuario: null,
      modalModificarSector: false,
    };

    this.mostrarModalTelefonos = this.mostrarModalTelefonos.bind(this);
    this.ocultarModalTelefonos = this.ocultarModalTelefonos.bind(this);
  }

  componentDidMount() {
    this.usuariosReasignacion();
    this.fetchGestion(this.state.pagina, this.state.filas);
  }

  ocultarChat = () => {
    this.setState({ modalChat: false });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarChat = (chat, gegId, usuario, nombre_usuario, participante) => {
    if (chat == null) {
      axios
        .post(
          global.config.modulos.chat + "crear_chat/",
          {
            nombreArchivo: this.state.nombre_cesfam + " #CO" + gegId,
            tipoMensaje: 2,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          if (res.data.estado) {
            axios
              .patch(
                global.config.modulos.gestion,
                {
                  geg_id: gegId,
                  geg_chat: res.data.cht_id,
                },
                { headers: authHeader() }
              )
              .then((res) => {});

            this.setState({
              modalChat: true,
              chat: res.data.cht_id,
              usuId: usuario,
              nombre_usuario: nombre_usuario,
            });
          }
        });
    } else {
      this.actualizarMensajesLeidos(chat, participante);
      this.setState({
        modalChat: true,
        chat: chat,
        usuId: usuario,
        nombre_usuario: nombre_usuario,
      });
    }
  };

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  usuariosReasignacion = () => {
    axios
      .get(global.config.modulos.gestion + "reasignacion/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam_vista, tomado: null },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ usuarios_panel_filtro: res.data.datos });
        }
      });
  };

  mostrarModalTelefonos = (usuario, rut, direccion, cesfam, nombre_usuario, gegId, usuario_panel) => {
    this.setState({
      modalTelefonos: true,
      usuId: usuario,
      rut: rut,
      direccion: direccion,
      cesfam_nombre: cesfam,
      nombre_usuario: nombre_usuario,
      gegId: gegId,
      nombre_usuario_panel: usuario_panel,
    });
  };

  ocultarModalTelefonos = () => {
    this.setState({
      modalTelefonos: false,
    });
  };

  mostrarCasosRegistrados = (usuario, gestion) => {
    this.setState({
      modalCasosRegistrados: true,
      usuId: usuario,
      gegId: gestion,
    });
  };

  ocultarCasosRegistrados = () => {
    this.setState({
      modalCasosRegistrados: false,
    });
  };

  mostrarModificarSector = (usuario, id_cesfam_usuario) => {
    this.setState({
      modalModificarSector: true,
      usuId: usuario,
      id_cesfam_usuario: id_cesfam_usuario,
    });
  };

  ocultarModificarSector = () => {
    this.setState({
      modalModificarSector: false,
    });
  };

  mostrarModalModificarUsuario = (usuario) => {
    this.setState({
      modalModificarUsuario: true,
      usuId: usuario,
    });
  };

  ocultarModalModificarUsuario = () => {
    this.setState({
      modalModificarUsuario: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalModificarRequerimiento = (id, requerimiento) => {
    this.setState({
      modalModificarRequerimiento: true,
      gegId: id,
      requerimiento: requerimiento,
    });
  };

  ocultarModalModificarRequerimiento = () => {
    this.fetchGestion(this.state.pagina, this.state.filas);
    this.setState({
      modalModificarRequerimiento: false,
    });
  };

  mostrarModalMotivo = (gegId, audioMotivo, audioMotivoInicio, audioMotivoTermino, textoMotivo) => {
    this.setState({
      modalMotivo: true,
      gegId: gegId,
      audioMotivo: audioMotivo,
      audioMotivoInicio: audioMotivoInicio,
      audioMotivoTermino: audioMotivoTermino,
      textoMotivo: textoMotivo,
    });
  };

  ocultarModalMotivo = () => {
    this.setState({
      modalMotivo: false,
    });
  };

  mostrarModalAgendarHora = (id, usuario) => {
    this.setState({
      modalAgendarHora: true,
      gegId: id,
      usuId: usuario,
    });
  };

  ocultarModalAgendarHora = () => {
    this.setState({
      modalAgendarHora: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalAnularHora = (id, usuario, agenda) => {
    this.setState({
      modalAnularHora: true,
      gegId: id,
      usuId: usuario,
      gegAgenda: agenda,
    });
  };

  ocultarModalAnularHora = () => {
    this.setState({
      modalAnularHora: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalAgregarObservacion = (id, usuario) => {
    this.setState({
      modalAgregarObservacion: true,
      gegId: id,
      usuId: usuario,
    });
  };

  ocultarModalAgregarObservacion = () => {
    this.setState({
      modalAgregarObservacion: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalObservacionesTeleconsulta = (id, usuario) => {
    this.setState({
      modalObservacionesTeleconsulta: true,
      gegId: id,
      usuId: usuario,
    });
  };

  ocultarModalObservacionesTeleconsulta = () => {
    this.setState({
      modalObservacionesTeleconsulta: false,
    });
  };

  mostrarModalRepetirCaso = (id) => {
    this.setState({
      modalRepetirCaso: true,
      gegId: id,
    });
  };

  ocultarModalRepetirCaso = () => {
    this.setState({
      modalRepetirCaso: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalFinalizarCaso = (id, chat, usuario, estadoAgenda) => {
    if (this.state.casosSeleccionados.length > 0 && this.state.casosSeleccionados.includes(id)) {
      this.mostrarModalFinalizarCasoMultiple(id, usuario);
    } else {
      this.mostrarModalFinalizarCasoIndividual(id, chat, usuario, estadoAgenda);
    }
  };

  mostrarModalFinalizarCasoMultiple = (id, usuario) => {
    this.setState({
      modalFinalizarCasoMultiple: true,
      gegId: id,
      usuId: usuario,
    });
  };

  mostrarModalFinalizarCasoIndividual = (id, chat, usuario, estadoAgenda) => {
    this.setState({
      modalFinalizarCaso: true,
      gegId: id,
      chat: chat,
      usuId: usuario,
      estadoAgenda: estadoAgenda,
    });
  };

  ocultarModalFinalizarCaso = () => {
    this.setState({
      modalFinalizarCaso: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  ocultarModalFinalizarCasoMultiple = () => {
    this.setState({
      modalFinalizarCasoMultiple: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalDerivarCaso = (id, derivacion) => {
    this.setState({
      modalDerivarCaso: true,
      gegId: id,
      derivacionUrgencia: derivacion,
    });
  };

  ocultarModalDerivarCaso = () => {
    this.setState({
      modalDerivarCaso: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalReasignarCaso = (id, tomado) => {
    this.setState({
      modalReasignarCaso: true,
      gegId: id,
      uspResignar: tomado,
    });
  };

  ocultarModalReasignarCaso = () => {
    this.setState({
      modalReasignarCaso: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalModificarObservacion = (ggoId, observacion) => {
    this.setState({
      modalModificarObservacion: true,
      ggoId: ggoId,
      ggoObservacion: observacion,
    });
  };

  ocultarModalModificarObservacion = () => {
    this.setState({
      modalModificarObservacion: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  fetchGestion = async (pagina, filas) => {
    axios
      .get(global.config.modulos.gestion + "tabla/", {
        headers: authHeader(),
        params: {
          tipo: this.state.tipo,
          ubicacion: this.state.ubicacion,
          pagina: pagina,
          filas: filas,
          cesfam: this.state.cesfam,
          usp: this.state.usp,
          texto_filtro: this.state.texto_filtro,
          tomado_por_filtro: this.state.tomado_por_filtro,
          riesgo_filtro: this.state.riesgo_filtro,
        },
      })
      .then((res) => {
        this.setState({
          demanda: res.data.datos,
          total: res.data.total,
          pagina: res.data.pagina,
        });
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchGestion(pagina, this.state.filas);
  };

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.fetchGestion(this.state.pagina, filas);
  };

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span style={{ color: "red" }}>Tiempo Terminado</span>;
    } else {
      return (
        <span>
          {days + " días, "}
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  seleccionarVariosCasos = (gegId) => {
    let casos = this.state.casosSeleccionados;

    if (casos.includes(gegId)) {
      casos = casos.filter((item) => item !== gegId);
    } else {
      casos.push(gegId);
    }

    this.setState({ casosSeleccionados: casos });
  };

  pausarCaso = (gegid) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea pausar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion,
                {
                  geg_id: gegid,
                  geg_estado: 2,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                NotificationManager.success("Caso pausado con éxito");
                this.generarHistorial("Se pausa el caso", gegid, this.state.usp);
                this.fetchGestion(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  reanudarCaso = (gegid) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea reanudar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion,
                {
                  geg_id: gegid,
                  geg_estado: 1,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                NotificationManager.success("Caso reactivado con éxito");
                this.generarHistorial("Se reanuda el caso", gegid, this.state.usp);
                this.fetchGestion(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        { headers: authHeader() }
      )
      .then((res) => {});
  };

  cambiarValorCovid = (e, usuId) => {
    const { name, value } = e.target;
    axios
      .post(
        global.config.modulos.gestion + "estado_covid/",
        {
          gec_usuario_panel: this.state.usp,
          gec_estado: value,
          gec_usuario: usuId,
        },
        { headers: authHeader() }
      )
      .then((res) => {});
  };

  aplicarFiltros = () => {
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  tomarCaso = (gegid) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea tomar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion,
                {
                  geg_id: gegid,
                  geg_tomado: this.state.usp,
                  geg_ubicacion: 3,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                this.generarHistorial("Se toma el caso", gegid, this.state.usp);
                this.fetchGestion(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  actualizarMensajesLeidos = (chat, participante) => {
    axios.post(
      global.config.modulos.chat + "actualizar_chat_leido/",
      {
        chat: chat,
        participante: participante,
      },
      { headers: authHeader() }
    );
  };

  render() {
    const { demanda } = this.state;
    const colorUrgencia = {
      Alto: "rgb(193 39 45)",
      Medio: "rgb(255 255 0)",
      Bajo: "rgb(57 181 74)",
      AltoColor: "#fff",
      MedioColor: "#000",
      BajoColor: "#fff",
    };

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div style={{ float: "left" }}>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <FormControl variant="outlined">
                  <select
                    style={{
                      minWidth: 250,
                      padding: 6,
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                    id="riesgo_filtro"
                    name="riesgo_filtro"
                    value={this.state.riesgo_filtro}
                    onChange={this.cambiarValores}
                    placeholder="Riesgo"
                  >
                    <option value={null} disabled selected>
                      Riesgo
                    </option>
                    <option value={""}>Todos</option>
                    <option value={"Alto"}>Alto</option>
                    <option value={"Medio"}>Medio</option>
                    <option value={"Bajo"}>Bajo</option>
                  </select>
                </FormControl>
              </p>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <FormControl variant="outlined">
                  <select
                    style={{
                      minWidth: 250,
                      padding: 6,
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                    id="tomado_por_filtro"
                    name="tomado_por_filtro"
                    value={this.state.tomado_por_filtro}
                    onChange={this.cambiarValores}
                  >
                    <option value={null} disabled selected>
                      Tomado por
                    </option>
                    <option value={""}>Todos</option>
                    {this.state.usuarios_panel_filtro.map((usp, index) => {
                      if (usp.usp_id == this.state.usuario_panel_vista) {
                        return (
                          <option key={index} value={usp.usp_id}>
                            Mis registros
                          </option>
                        );
                      } else {
                        return (
                          <option key={index} value={usp.usp_id}>
                            {usp.usp_nombre + " " + usp.usp_apellido_p}
                          </option>
                        );
                      }
                    })}
                  </select>
                </FormControl>
              </p>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <input
                  type="text"
                  name="texto_filtro"
                  value={this.state.texto_filtro}
                  onChange={this.cambiarValores}
                  placeholder="Nombre o Rut"
                  className={"inputText"}
                  style={{
                    padding: 6,
                    border: "1px solid black",
                    borderRadius: 5,
                    float: "left",
                  }}
                />
                <a
                  onClick={this.aplicarFiltros}
                  color="primary"
                  className="btnAgregarCaso fondoNaranjo"
                  style={{
                    padding: "7px 13px 7px 13px",
                    fontSize: 14,
                    float: "left",
                    marginLeft: 30,
                  }}
                >
                  Buscar
                </a>
              </p>
            </div>

            <div style={{ marginTop: 30, marginBottom: 100 }}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTablaCovid">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha de ingreso
                      </TableCell>
                      <TableCell align="center">Sector</TableCell>
                      <TableCell align="center">Covid-19</TableCell>
                      <TableCell align="center">Nº de registros</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Motivo</TableCell>

                      <TableCell align="center">Observaciones</TableCell>
                      <TableCell align="center">Ver más</TableCell>
                      <TableCell align="center">Chat</TableCell>
                      <TableCell className="cellTermino" align="center" style={{ minWidth: 115 }}>
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demanda.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" className={row.geg_marcar_derivado ? (row.geg_marcar_derivado == 1 ? "bordeCasoDerivado" : "bordeCasoDerivadoUrgente") : ""}>
                          <p> {row.geg_fecha_ingreso.replace("T", " ")}</p>
                          <div
                            className="riesgo"
                            style={{
                              backgroundColor: colorUrgencia[row.geg_urgencia ? row.geg_urgencia : "Bajo"],
                              color: colorUrgencia[(row.geg_urgencia ? row.geg_urgencia : "Bajo") + "Color"],
                            }}
                          >
                            {row.geg_urgencia ? row.geg_urgencia : "Bajo"}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {row.nombre_sector}
                          <img className="smallIcon" src={editar} onClick={() => this.mostrarModificarSector(row.geg_usuario, row.id_cesfam_usuario)} />
                        </TableCell>
                        <TableCell align="center">
                          <select name="covid" defaultValue={row.estado_covid} onChange={(e) => this.cambiarValorCovid(e, row.geg_usuario)} style={{ padding: 10, borderRadius: 5 }}>
                            <option value="" selected>
                              Seleccione...
                            </option>
                            <option value="Positivo">Positivo</option>
                            <option value="Negativo">Negativo</option>
                            <option value="Sospecha">Sospecha</option>
                            <option value="Dudas">Dudas</option>
                          </select>
                        </TableCell>
                        <TableCell align="center">
                          {row.registros > 1 ? (
                            <a style={{ cursor: "pointer" }} onClick={() => this.mostrarCasosRegistrados(row.geg_usuario, row.geg_id)}>
                              {row.registros}
                            </a>
                          ) : (
                            row.registros
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                              <div>
                                <a variant="contained" color="primary" style={{ cursor: "pointer" }} {...bindTrigger(popupState)}>
                                  {row.nombre_usuario}
                                </a>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <Typography>
                                      <p>
                                        Rut: {row.rut}
                                        <Tooltip title="Copiar" aria-label="Copiar">
                                          <img className="smallIcon" src={copiar} onClick={() => this.copiarRut(row.rut)} />
                                        </Tooltip>
                                      </p>
                                      <p>
                                        Edad: {row.edad ? row.edad : "0"} años{" "}
                                        <Tooltip title="Editar" aria-label="Editar">
                                          <img className="smallIcon" src={editar} onClick={() => this.mostrarModalModificarUsuario(row.geg_usuario)} />
                                        </Tooltip>
                                      </p>
                                    </Typography>
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Revisar detalles de solicitud" aria-label="Revisar detalles de solicitud">
                            <img className="mediumIcon" src={ver_mas} onClick={() => this.mostrarModalMotivo(row.geg_id, row.geg_audio_motivo, row.geg_audio_motivo_inicio, row.geg_audio_motivo_termino, row.geg_texto_motivo)} />
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          {row.observaciones ? (
                            <React.Fragment>
                              {row.observaciones.map((res, index) => {
                                return (
                                  <PopupState key={index} variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                      <React.Fragment>
                                        <a variant="contained" color="primary" style={{ cursor: "pointer" }} {...bindTrigger(popupState)}>
                                          <div className="observaciones">{res.ggo_observacion}</div>
                                        </a>
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                        >
                                          <Box p={2}>
                                            <Typography>
                                              <p>
                                                <label style={{ fontWeight: "bold" }}>Funcionario: </label>
                                                {res.ggo_usuario_panel}
                                              </p>
                                              <p>
                                                <label style={{ fontWeight: "bold" }}>Fecha: </label>
                                                {res.ggo_fecha_registro.replace("T", " ")}
                                              </p>
                                            </Typography>
                                          </Box>
                                        </Popover>
                                      </React.Fragment>
                                    )}
                                  </PopupState>
                                );
                              })}
                            </React.Fragment>
                          ) : (
                            <p>No se han registrado observaciones</p>
                          )}
                          {row.observaciones.length > 0 ? (
                            <Tooltip title="Editar última observación del caso" aria-label="Editar última observación del caso">
                              <img className="smallMedIcon" src={editar} onClick={() => this.mostrarModalModificarObservacion(row.observaciones[row.observaciones.length - 1]["ggo_id"], row.observaciones[row.observaciones.length - 1]["ggo_observacion"])} />
                            </Tooltip>
                          ) : null}
                          <Tooltip title="Observaciones teleconsulta" aria-label="Observaciones teleconsulta">
                            <img className="smallMedIcon" src={obs_teleconsulta} onClick={() => this.mostrarModalObservacionesTeleconsulta(row.geg_id, row.geg_usuario)} />
                          </Tooltip>
                          <Tooltip
                            title="Agregar una observación a este caso"
                            aria-label="Agregar una observación a este caso"
                            onClick={() => {
                              this.mostrarModalAgregarObservacion(row.geg_id, row.geg_usuario);
                            }}
                          >
                            <img className="smallMedIcon" src={agendar} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Ver el registros de canales y detalle de acciones" aria-label="Ver el registros de canales y detalle de acciones">
                            <img className="mediumIcon" src={ver_mas} onClick={() => this.mostrarModalTelefonos(row.geg_usuario, row.rut, row.direccion, row.cesfam_usuario, row.nombre_usuario, row.geg_id, row.usuario_panel)} />
                          </Tooltip>
                        </TableCell>

                        {row.chat_habilitado === 1 ? (
                          <TableCell align="center" style={{ width: 90 }}>
                            <Tooltip title="Chat con el paciente" aria-label="Chat con el paciente">
                              <img className="mediumIcon" src={iconoChat} onClick={() => this.mostrarChat(row.geg_chat, row.geg_id, row.geg_usuario, row.nombre_usuario, row.participante)} />
                            </Tooltip>
                            {row.geg_chat && row.mensajes_no_leidos ? <span className="badge-mensajes-no-leidos">{row.mensajes_no_leidos}</span> : null}
                          </TableCell>
                        ) : (
                          <TableCell align="center" style={{ width: 90 }}>
                            <Tooltip title="Chat no habilitado" aria-label="Chat no habilitado">
                              <img className="mediumIcon" src={iconoChatDeshabilitado} />
                            </Tooltip>
                          </TableCell>
                        )}

                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row.geg_tomado == this.state.usp ? (
                            <React.Fragment>
                              <input
                                onChange={() => this.seleccionarVariosCasos(row.geg_id)}
                                checked={this.state.casosSeleccionados.includes(row.geg_id)}
                                type="checkbox"
                                style={{
                                  float: "right",
                                  marginTop: "-10px",
                                  marginRight: "-10px",
                                  cursor: "pointer",
                                }}
                              />
                              <Tooltip title="Finalizar el caso" aria-label="Finalizar el caso">
                                <img className="mediumIcon" src={finalizar} onClick={() => this.mostrarModalFinalizarCaso(row.geg_id, row.geg_chat, row.geg_usuario, row.estado_agenda)} />
                              </Tooltip>
                              {!this.state.casosSeleccionados.includes(row.geg_id) ? (
                                <React.Fragment>
                                  {row.geg_estado == 1 ? (
                                    <React.Fragment>
                                      <Tooltip title="Reasignar el caso" aria-label="Reasignar el caso">
                                        <img className="mediumIcon" src={reasignar} onClick={() => this.mostrarModalReasignarCaso(row.geg_id, row.geg_tomado)} />
                                      </Tooltip>
                                      <Tooltip title="Pausar el caso" aria-label="Pausar el caso">
                                        <img className="mediumIcon" src={pausar} onClick={() => this.pausarCaso(row.geg_id)} />
                                      </Tooltip>
                                      <Tooltip title="Repetir el caso" aria-label="Repetir el caso">
                                        <img className="mediumIcon" src={repetir} onClick={() => this.mostrarModalRepetirCaso(row.geg_id)} />
                                      </Tooltip>
                                      <Tooltip title="Derivar el caso" aria-label="Derivar el caso">
                                        <img className="mediumIcon" src={derivar} onClick={() => this.mostrarModalDerivarCaso(row.geg_id, 1)} />
                                      </Tooltip>
                                      <Tooltip title="Derivar el caso con urgencia" aria-label="Derivar el caso con urgencia">
                                        <img className="mediumIcon" src={derivarUrgente} style={{ width: 33 }} onClick={() => this.mostrarModalDerivarCaso(row.geg_id, 2)} />
                                      </Tooltip>
                                      {row.estado_agenda.length > 0 ? (
                                        <Tooltip title="Mostrar hora médica" aria-label="Mostrar una hora médica">
                                          <img className="mediumIcon" src={obs_teleconsulta} onClick={() => this.mostrarModalAnularHora(row.geg_id, row.geg_usuario, row.geg_agenda)} />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title="Reservar una hora médica" aria-label="Reservar una hora médica">
                                          <img className="mediumIcon" src={agendar} onClick={() => this.mostrarModalAgendarHora(row.geg_id, row.geg_usuario)} />
                                        </Tooltip>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Tooltip title="reanudar el caso" aria-label="Reanudar el caso">
                                        <img className="mediumIcon" src={pausar} onClick={() => this.reanudarCaso(row.geg_id)} />
                                      </Tooltip>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <Tooltip title="Tomar caso" aria-label="Tomar caso">
                              <img className="mediumIcon" src={derivar} onClick={() => this.tomarCaso(row.geg_id)} />
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
            </div>
          </div>
          {this.state.modalTelefonos ? <ModalTelefonos ocultar={this.ocultarModalTelefonos} usuId={this.state.usuId} rut={this.state.rut} cesfam={this.state.cesfam_nombre} gegId={this.state.gegId} nombre_usuario_panel={this.state.nombre_usuario_panel}></ModalTelefonos> : null}
          {this.state.modalModificarUsuario ? <ModalModificarUsuario ocultar={this.ocultarModalModificarUsuario} usuId={this.state.usuId}></ModalModificarUsuario> : null}
          {this.state.modalModificarRequerimiento ? <ModalModificarRequerimiento ocultar={this.ocultarModalModificarRequerimiento} gegId={this.state.gegId} requerimiento={this.state.requerimiento}></ModalModificarRequerimiento> : null}
          {this.state.modalMotivo ? <Motivo ocultar={this.ocultarModalMotivo} gegId={this.state.gegId} audioMotivo={this.state.audioMotivo} audioMotivoInicio={this.state.audioMotivoInicio} audioMotivoTermino={this.state.audioMotivoTermino} textoMotivo={this.state.textoMotivo}></Motivo> : null}
          {this.state.modalAgregarObservacion ? <AgregarObservacion ocultar={this.ocultarModalAgregarObservacion} gegId={this.state.gegId} usuario={this.state.usuId} tipo={this.state.tipo}></AgregarObservacion> : null}
          {this.state.modalModificarObservacion ? <ModificarObservacion ocultar={this.ocultarModalModificarObservacion} ggoId={this.state.ggoId} ggoObservacion={this.state.ggoObservacion}></ModificarObservacion> : null}
          {this.state.modalObservacionesTeleconsulta ? <ObservacionesTeleconsulta ocultar={this.ocultarModalObservacionesTeleconsulta} gegId={this.state.gegId} usuario={this.state.usuId}></ObservacionesTeleconsulta> : null}
          {this.state.modalRepetirCaso ? <RepetirCaso ocultar={this.ocultarModalRepetirCaso} gegId={this.state.gegId}></RepetirCaso> : null}
          {this.state.modalFinalizarCaso ? <FinalizarCaso ocultar={this.ocultarModalFinalizarCaso} gegId={this.state.gegId} chat={this.state.chat} usuId={this.state.usuId} estadoAgenda={this.state.estadoAgenda}></FinalizarCaso> : null}
          {this.state.modalFinalizarCasoMultiple ? <FinalizarCasoMultiple ocultar={this.ocultarModalFinalizarCasoMultiple} usuId={this.state.usuId} casosSeleccionados={this.state.casosSeleccionados}></FinalizarCasoMultiple> : null}
          {this.state.modalDerivarCaso ? <DerivarCaso ocultar={this.ocultarModalDerivarCaso} gegId={this.state.gegId} urgencia={this.state.derivacionUrgencia}></DerivarCaso> : null}
          {this.state.modalReasignarCaso ? <ReasignarCaso ocultar={this.ocultarModalReasignarCaso} gegId={this.state.gegId} tomado={this.state.uspResignar}></ReasignarCaso> : null}
          {this.state.modalAgendarHora ? <AgendarHora ocultar={this.ocultarModalAgendarHora} gegId={this.state.gegId} usuId={this.state.usuId}></AgendarHora> : null}
          {this.state.modalAnularHora ? <AnularHora ocultar={this.ocultarModalAnularHora} gegId={this.state.gegId} usuId={this.state.usuId} ageId={this.state.gegAgenda}></AnularHora> : null}
          {this.state.modalCasosRegistrados ? <CasosRegistrados ocultar={this.ocultarCasosRegistrados} gestion={this.state.gegId} usuario={this.state.usuId} tipo={this.state.tipo}></CasosRegistrados> : null}
          {this.state.modalChat ? <Chat ocultar={this.ocultarChat} chat={this.state.chat} usuario={this.state.usuId} nombre_usuario={this.state.nombre_usuario}></Chat> : null}
          {this.state.modalModificarSector ? <ModificarSector ocultar={this.ocultarModificarSector} usuario={this.state.usuId} id_cesfam_usuario={this.state.id_cesfam_usuario}></ModificarSector> : null}
        </GridItem>
      </GridContainer>
    );
  }
}
