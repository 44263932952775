import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "../../../assets/css/graficosD.css";
import Cargando from "components/Helpers/Cargando";
import { Grid } from "@material-ui/core";

export default class GrafOfInicialProfe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      fecha_semana: props.fecha_semana,
      fecha_mes: props.fecha_mes,
      fecha_año: props.fecha_año,
      cesfam: props.cesfam,
      root: "",
      idCliente: props.idCliente,
      ces: "",
      color: props.color,
      total: "",
      cesNombre: props.cesNombre,
      today: new Date(),
      year: "",
      month: "",
      day: "",
      dateStr: "",
      titulo_archivo: "",
      cargando: true,
    };
  }

  GrafOfInicialProfe() {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "ofetaInicialXprofesionalCli/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_semana: this.state.fecha_semana,
          fecha_mes: this.state.fecha_mes,
          fecha_año: this.state.fecha_año,
          cliente: this.state.idCliente,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos en el grafico de oferta inicial por profesional");
          this.setState({ cargando: false });
          return;
        }
        this.setState({ datos: res.data.datos[0], total: res.data.datos[1] }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv13");
          root._logo.dispose();
          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              pinchZoomX: false,
            })
          );

          let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
          cursor.lineY.set("visible", false);

          let xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 15,
          });
          xRenderer.labels.template.setAll({
            rotation: -90,
            centerY: am5.p50,
            centerX: am5.p0,
            paddingRight: 10,
            fontSize: 11,
            fontWeight: "bold",
          });

          xRenderer.grid.template.setAll({
            location: 1,
          });

          let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0.3,
              categoryField: "profesional",
              renderer: xRenderer,
            })
          );

          let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1,
              }),
            })
          );
          xAxis.get("renderer").labels.template.setAll({
            maxWidth: 200,
          });

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "total",
              sequencedInterpolation: true,
              categoryXField: "profesional",
              tooltip: am5.Tooltip.new(root, {
                labelText: "{profesional} \n {valueY}",
                autoTextColor: false,

                fill: am5.color(0xff5566),
              }),
            })
          );

          series.bullets.push(function () {
            var label = am5.Label.new(root, {
              text: "{valueY}",
              fill: "black",
              centerX: am5.p50,
              centerY: am5.p100,
              populateText: true,
              fontSize: 10,
              fontWeight: "bold",
            });

            label.adapters.add("centerY", function (centerY, target) {
              var dataItem = target.dataItem;
              if (dataItem) {
                var value = dataItem.get("valueY", 0);
                if (value < 0) {
                  return 0;
                }
              }

              return centerY;
            });

            return am5.Bullet.new(root, {
              locationY: 0.97,
              sprite: label,
            });
          });

          series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeOpacity: 0,
          });

          series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          chart.get("colors").set("colors", [am5.color("#47BAA4"), am5.color("#EAA028"), am5.color("#EF4597"), am5.color("#6E45AF"), am5.color("#8CBCF4"), am5.color("#D82323"), am5.color("#F7D856"), am5.color("#CEACE5"), am5.color("#9BCFB1"), am5.color("#2466A8")]);

          let data = this.state.datos;

          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: this.state.datos,
            title: `OFERTA INICIAL POR PROFESIONAL`,
            pdfOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            htmlOptions: {
              disabled: true,
            },
            jpgOptions: {
              disabled: true,
            },
            csvOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            xlsxOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            pngOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            filePrefix: this.state.titulo_archivo,
            dataFields: {
              profesional: "Profesional",
              total: "Total",
              aa: ``,
              bb: `Total ${this.state.total}`,
            },
          });

          xAxis.data.setAll(data);
          series.data.setAll(data);
          this.setState({ cargando: false });
          series.appear(1000);
          chart.appear(1000, 100);
        });
      });
  }

  componentDidMount() {
    this.setState(
      {
        year: this.state.today.getFullYear().toString().slice(),
        month: (this.state.today.getMonth() + 1).toString().padStart(2, "0"),
        day: this.state.today.getDate().toString().padStart(2, "0"),
        act_date: new Date(this.state.fecha_actual),
        ini_date: new Date(this.state.fecha_inicio),
        ter_date: new Date(this.state.fecha_termino),
      },
      () => {
        if (!this.state.fecha_actual && !this.state.fecha_termino && !this.state.fecha_inicio) {
          this.setState({
            titulo_archivo: ` ${this.state.day}-${this.state.month}-${this.state.year}`,
          });
        } else if ((this.state.fecha_actual !== "") & !this.state.fecha_termino && !this.fecha_inicio) {
          this.setState({
            titulo_archivo: `  ${this.state.act_date.getDate().toString().padStart(2, "0")}-${(this.state.act_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.act_date.getFullYear().toString().slice()}`,
          });
        } else {
          this.setState({
            act_date: "",
            titulo_archivo: `${this.state.ini_date.getDate().toString().padStart(2, "0")}-${(this.state.ini_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ini_date.getFullYear().toString().slice()} ~ ${this.state.ter_date.getDate().toString().padStart(2, "0")}-${(this.state.ter_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ter_date.getFullYear().toString().slice()}`,
          });
        }
      }
    );
    this.GrafOfInicialProfe();
  }

  render() {
    return (
      <div style={{ margin: "30px 13%" }}>
        {this.state.cargando ? <Cargando /> : null}
        <Grid
          id="chartdiv13"
          style={{
            width: "80vw",
            position: "absolute",
            height: "600px",
            zIndex: -1,
          }}
        >
          <div>
            <h1
              style={{
                color: "#808080",
                fontSize: "1.6vw",
              }}
            >
              Oferta inicial por profesional{" "}
            </h1>
            <h3
              style={{
                color: "#808080",
                fontSize: "0.9vw",
              }}
            >
              Oferta inicial {">"} Profesional
            </h3>
            <h3
              style={{
                color: "#808080",
                fontSize: "1vw",
              }}
            >
              Total:{this.state.total}
            </h3>
          </div>
        </Grid>
      </div>
    );
  }
}
