import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default class horasB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      dates: [],
      withBQ: props.withBQ,
      heightBQ: props.heightBQ,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      fecha_actual: props.fecha_actual,
      hora_inicio: props.hora_inicio,
      hora_termino: props.hora_termino,
      edad_max: props.edad_max,
      edad_min: props.edad_min,
      displayT: "none",
      root: "",
      chart: "",
      dato: "",
      display: "center",
    };
  }

  horasB = () => {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "horasAgendadas/", {
        headers: authHeader(),
        params: {
          fecha_act: this.state.fecha_actual,
          fecha_ini: this.state.fecha_inicio,
          fecha_ter: this.state.fecha_termino,
          edad_min: this.state.edad_min,
          edad_max: this.state.edad_max,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ datos: res.data.datos }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv5");

          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              layout: root.verticalLayout,
            })
          );

          let series = chart.series.push(
            am5percent.PieSeries.new(root, {
              valueField: "value",
              categoryField: "category",
              alignLabels: false,
            })
          );
          series.labels.template.setAll({
            textType: "circular",
            radius: 4,
          });
          series.labels.template.set("text", "{category}: [bold]{value} Horas");

          let thiss = this;
          let props = this.props;

          series.slices.template.events.on("click", function (ev) {
            if (ev.target._dataItem.dataContext.category === "Agendadas") {
              thiss.setState({ withBQ: "35%", heightBQ: "27%" }, () => {
                props.mostrarTablaSexo();
              });
            }
            if (ev.target._dataItem.dataContext.category === "Libres") {
              thiss.setState({ withBQ: "35%", heightBQ: "27%" }, () => {
                props.mostrarGraficosCes();
              });
            }
          });

          series.data.setAll(this.state.datos);

          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.percent(50),
              x: am5.percent(50),
              marginTop: 15,
              marginBottom: 15,
            })
          );

          legend.data.setAll(series.dataItems);

          series.appear(1000, 100);
        });
      });
  };

  componentDidMount() {
    this.horasB();
  }

  render() {
    return (
      <div
        id="chartdiv5"
        style={{
          width: "40%",
          height: "70%",
          zIndex: 1,
          position: "fixed",
          display: this.state.display,
          marginTop: "20px",
          textAlign: "center",
        }}
      >
        <h1>Horas Libres y Agendadas</h1>
      </div>
    );
  }
}
