import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import Button from "@material-ui/core/Button";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import { store } from "./../../store/index";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

// estilos
//import "../../assets/css/agregar.css";

export default class HorasOfrecidas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cesfam: store.getState().cesfam,
      uspid: store.getState().usp,
      usuario: props.usuario,

      canal: 5,
      open: true,
      horarios: [],
    };
  }

  componentDidMount() {
    this.horasOfrecidas();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  horasOfrecidas = () => {
    axios
      .get(global.config.modulos.reservas + "horarios_ofrecidos/", {
        headers: authHeader(),
        params: { usuario: this.state.usuario, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ horarios: res.data.datos });
        }
      });
  };

  render() {
    const { open, horarios } = this.state;
    return (
      <div>
        <Dialog open={open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="ultimaSolicitud">
          <DialogContent>
            <Grid container className="agendar-container">
              <Grid item xs={12} md={12}>
                <h2>Últimas 50 horas ofrecidas</h2>
              </Grid>

              <Grid item id="modalComentarioComentario" xs={12} style={{ marginTop: 30 }}>
                <TableContainer style={{ width: "100%", float: "left", marginTop: "" }}>
                  <Table aria-label="simple table" className="bordeTablaHorasFuturas">
                    <TableHead>
                      <TableRow>
                        <TableCell className="cellInicio" align="center">
                          Canal de ofrecimiento
                        </TableCell>
                        <TableCell align="center">Fecha de ofrecimiento</TableCell>
                        <TableCell align="center">Fecha de atención</TableCell>
                        <TableCell align="center">Profesional</TableCell>
                        <TableCell align="center">Especialidad</TableCell>
                        <TableCell className="cellTermino" align="center">
                          Estado final
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {horarios.length > 0 ? (
                        <React.Fragment>
                          {horarios.map((hor, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{hor.canal}</TableCell>
                              <TableCell align="center">{hor.hof_fecha_registro.replace("T", " ")}</TableCell>
                              <TableCell align="center">{hor.horario.replace("T", " ")}</TableCell>
                              <TableCell align="center">{hor.nombre_profesional}</TableCell>
                              <TableCell align="center">{hor.nombre_especialidad}</TableCell>
                              <TableCell align="center">{hor.estado_hora}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ) : (
                        <TableRow>
                          <TableCell align="center" style={{ height: 100 }} colSpan={6}>
                            No tiene horas ofrecidas
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 30, marginBottom: 30 }}>
                <Button className="botonDialog fondoRojo" onClick={this.salir}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
