import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";

import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import ModificarDireccion from "./ModificarDireccion.js";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import copiar from "../../assets/img/usuarios/copiar.svg";
import TelefonosCanales from "./../../components/Usuarios/Telefonos";
import ModificarUnidadVecinal from "./ModificarUnidadVecinal";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import Tooltip from "@material-ui/core/Tooltip";
import editar from "../../assets/img/gestion/editar.svg";
import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class Telefonos extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      telid: "",
      edad: "",
      telefono: "",
      telefonos: [],
      formErrors: {},
      uspId: parseInt(store.getState().usp),
      open: true,
      usuId: props.usuId,
      rut: props.rut,
      direccion: "",
      unidadVecinal: "",
      cesfam: props.cesfam,
      nombre_usuario: "",
      modalModificarDireccion: false,
      telefonosCanales: true,
      tipo: props.tipo,
      modalModificarUnidadVecinal: false,
    };
  }

  componentDidMount() {
    this.traerUsuario();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  mostrarAlertaExito() {
    NotificationManager.success("Teléfono registrado con éxito");
  }

  traerUsuario() {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        unidadVecinal: res.data["datos"]["usu_unidad_vecinal"],
        edad: res.data["datos"]["usu_edad"],
        direccion: res.data["datos"]["usu_direccion"] + (res.data["datos"]["usu_comuna_direccion"] ? ", " + res.data["datos"]["usu_comuna_direccion"] : ""),
        nombre_usuario: res.data["datos"]["usu_nombre"] + " " + res.data["datos"]["usu_apellido_p"],
      });
    });
  }

  mostrarModificarDireccion = () => {
    this.setState({
      modalModificarDireccion: true,
    });
  };

  ocultarModificarDireccion = () => {
    this.setState({ modalModificarDireccion: false });
    this.traerUsuario();
  };

  mostrarModificarUnidadVecinal = () => {
    this.setState({
      modalModificarUnidadVecinal: true,
    });
  };

  ocultarModificarUnidadVecinal = () => {
    this.setState({ modalModificarUnidadVecinal: false });
    this.traerUsuario();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fechaTexto(dateString) {
    var meses = [" ", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    var fecha = dateString.split("-");
    var ano = fecha[0];
    var mes = meses[parseInt(fecha[1])];
    var dia = fecha[2];

    var fechaCompleta = dia + " de " + mes + " del " + ano;

    return fechaCompleta;
  }

  enviarFormulario = (e) => {
    if (!this.state.telefono || this.state.telefono.length < 9) {
      return NotificationManager.warning("El teléfono debe tener un largo de 9 dígitos");
    }

    var data = {
      telefono: this.state.telefono,
      usu_id: this.state.usuId,
      tipo: 2,
      canal: 5,
      usp_id: this.state.uspId,
    };

    let apiUrl = global.config.modulos.telefonos + "agregar_telefono/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      this.mostrarAlertaExito();
      this.setState({
        telefono: "",
        telefonosCanales: false,
      });
      this.setState({
        telefonosCanales: true,
      });
    });
  };

  render() {
    const { telefonos, nombre_usuario, direccion, rut, edad, cesfam, unidadVecinal, tipo } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalTelefonos">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <GridContainer className="agendar-container">
                <GridItem xs={12}></GridItem>
                <GridItem style={{ marginTop: 20, marginLeft: 30 }}>
                  <p style={{ fontWeight: "bold" }}>Nombre</p>
                  <p style={{ marginTop: 10 }}>
                    <label>{nombre_usuario}</label>
                  </p>
                </GridItem>
                <GridItem style={{ marginTop: 20, marginLeft: 30 }}>
                  <p style={{ fontWeight: "bold" }}>Rut</p>
                  <p style={{ marginTop: 10 }}>
                    <label>
                      {rut}&nbsp;&nbsp;
                      <Tooltip title="Copiar RUT del paciente" aria-label="Copiar RUT del paciente">
                        <img className="smallIconRut" src={copiar} onClick={() => this.copiarRut(rut)} />
                      </Tooltip>
                    </label>
                  </p>
                </GridItem>
                <GridItem style={{ marginTop: 20, marginLeft: 30 }}>
                  <p style={{ fontWeight: "bold" }}>Edad</p>
                  <p style={{ marginTop: 10 }}>
                    <label>{edad} años</label>
                  </p>
                </GridItem>
                <GridItem style={{ marginTop: 20, marginLeft: 30 }}>
                  <p style={{ fontWeight: "bold" }}>Dirección</p>
                  <p style={{ marginTop: 10 }}>
                    <label>
                      {direccion}&nbsp;&nbsp;
                      <Tooltip title="Editar dirección del paciente" aria-label="Editar dirección del paciente">
                        <img
                          style={{
                            width: 20,
                            marginBottom: "-5px",
                            marginTop: "0px",
                          }}
                          className="smallIcon"
                          src={editar}
                          onClick={this.mostrarModificarDireccion}
                        />
                      </Tooltip>
                    </label>
                  </p>
                </GridItem>
                <GridItem style={{ marginTop: 20, marginLeft: 30 }}>
                  <p style={{ fontWeight: "bold" }}>Cesfam</p>
                  <p style={{ marginTop: 10 }}>
                    <label>{cesfam}</label>
                  </p>
                </GridItem>
                {tipo == 3 ? (
                  <GridItem style={{ marginTop: 20, marginLeft: 30 }}>
                    <p style={{ fontWeight: "bold" }}>Unidad vecinal</p>
                    <p style={{ marginTop: 10 }}>
                      <label>
                        {unidadVecinal}&nbsp;&nbsp;
                        <Tooltip title="Editar la unidad vecinal del paciente" aria-label="Editar la unidad vecinal del paciente">
                          <img
                            style={{
                              width: 20,
                              marginBottom: "-5px",
                              marginTop: "0px",
                            }}
                            className="smallIcon"
                            src={editar}
                            onClick={this.mostrarModificarUnidadVecinal}
                          />
                        </Tooltip>
                      </label>
                    </p>
                  </GridItem>
                ) : null}

                <GridItem id="modalComentarioComentario" xs={12} style={{ marginTop: 30, marginLeft: 30 }}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                    }}
                    id="standard-basic"
                    label="Teléfono"
                    value={this.state.telefono}
                    style={{ with: 300 }}
                    type="number"
                    name="telefono"
                    onChange={this.cambiarValores}
                    onInput={(e) => {
                      e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                    }}
                  />
                  <Button onClick={this.enviarFormulario} className="botonSimple fondoVerde" id="btnAgregar" style={{ marginLeft: 30, marginTop: 10 }}>
                    Agregar
                  </Button>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  {this.state.telefonosCanales ? <TelefonosCanales usuId={this.state.usuId}></TelefonosCanales> : null}
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button onClick={this.salir} className="botonSimple floatRigth fondoRojo">
                    Salir
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.modalModificarDireccion ? <ModificarDireccion ocultar={this.ocultarModificarDireccion} usuId={this.state.usuId}></ModificarDireccion> : null}
        {this.state.modalModificarUnidadVecinal ? <ModificarUnidadVecinal ocultar={this.ocultarModificarUnidadVecinal} usuId={this.state.usuId}></ModificarUnidadVecinal> : null}
      </Dialog>
    );
  }
}
