import React from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";
import { IconContext } from "react-icons";
import { store } from "./../../store/index";

function Submenu(props) {
  const [herramientas, setHerramientas] = React.useState(store.getState().herramientas);
  const divStyle = {
    marginTop: props.espacioTop,
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav style={divStyle} className="nav-submenu active">
          <ul className="nav-menu-items">
            {props.submenuData.map((item, index) => {
              if (herramientas.includes(item.sigla) || item.sigla == "") {
                return (
                  <li key={index} className="nav-text">
                    <Link to={item.path}>
                      {item.icon} <span>{item.title}</span>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Submenu;
