import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Cargando from "./../../components/Helpers/Cargando";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import copiar from "../../assets/img/usuarios/copiar.svg";

import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class PlantillasChat extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      nombreUsp: store.getState().nombreusp,
      open: true,
      filtro: "",
      mensaje: "",
      tipo: props.tipo,
      plantillas: [],
    };
  }

  componentDidMount() {
    this.traerPlantillas();
  }

  salir = (mensaje = "") => {
    this.setState({
      open: false,
    });
    this.props.ocultar(mensaje);
  };

  traerPlantillas = (filtro = null) => {
    axios
      .get(global.config.modulos.gestion + "registrar_plantilla_chat/", {
        headers: authHeader(),
        params: {
          tipo_gestion: this.state.tipo,
          usuario_panel: this.state.uspId,
          filtro: filtro,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ plantillas: res.data.datos });
        } else {
          this.setState({ plantillas: [] });
        }
      });
  };

  eliminarPlantilla = (id) => {
    confirmAlert({
      message: "¿Está seguro que desea eliminar esta plantilla?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion + "registrar_plantilla_chat/",
                {
                  id: id,
                  ggp_estado: 0,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                if (res.data.estado) {
                  this.traerPlantillas(this.state.filtro);
                  NotificationManager.success("Plantilla eliminada con éxito");
                } else {
                  NotificationManager.error(res.data.mensaje);
                }
              });
          },
        },
      ],
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    this.traerPlantillas(value);
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={() => this.salir("")} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="plantillasMensajeGlobal">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12}>
              <h2>Textos predeterminados para el chat</h2>
            </GridItem>
            <GridItem xs={12}>
              <div style={{ marginTop: 30 }}>
                <p>Filtrar</p>
                <input className="estiloSelect" type="text" name="filtro" value={this.state.filtro} onChange={this.cambiarValores} placeholder="" />
              </div>
            </GridItem>

            <GridItem xs={12} style={{ marginTop: 30 }}>
              <div style={{ height: 400, overflowY: "scroll" }}>
                {this.state.plantillas ? (
                  <React.Fragment>
                    {this.state.plantillas.map((res, index) => (
                      <React.Fragment key={index}>
                        <div className="plantilla-chat ">{res.ggp_mensaje}</div>
                        <div style={{ height: 30 }}>
                          <label
                            style={{
                              float: "left",
                              padding: 5,
                            }}
                          >
                            {res.ggp_fecha_registro.replace("T", " ")}
                          </label>
                          <a
                            onClick={() => this.eliminarPlantilla(res.ggp_id)}
                            style={{
                              float: "right",
                              padding: 5,
                              cursor: "pointer",
                            }}
                          >
                            <img style={{ width: 25 }} src={icono_eliminar} />
                          </a>
                          <a
                            onClick={() => this.salir(res.ggp_mensaje)}
                            style={{
                              float: "right",
                              padding: 5,
                              cursor: "pointer",
                            }}
                          >
                            <img style={{ width: 25 }} src={copiar} />
                          </a>
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ) : null}
              </div>
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 20 }}>
              <Button id={"modalModificarUsuarioBtnCancelar"} onClick={() => this.salir("")} style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" className="fondoRojo">
                Cancelar
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.modalCargando ? <Cargando color="#fff" type="spinningBubbles" /> : null}
      </Dialog>
    );
  }
}
