import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import { store } from "../../store/index";
// CSS
import "./../../assets/css/reservasExternas.css";

import vacunacion_covid from "../../assets/img/vacunacion_covid.svg";
import vacunacion_influenza from "../../assets/img/vacunacion_influenza.svg";

import signo from "./../../assets/img/signo.svg";

export default class MenuVacunatorio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      nombre_cesfam: store.getState().nombre_cesfam,
    };
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Vacunatorio <span title=''><img src=" + signo + "></img></span>";
  };

  cambiarVista = (vista) => {
    this.props.history.push(vista);
  };

  render() {
    return (
      <div className="middle-area">
        <GridContainer>
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <a onClick={() => this.cambiarVista("/listaVacunacionCovid")}>
              <img src={vacunacion_covid} style={{ width: "100%" }}></img>
            </a>
          </GridItem>
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <a onClick={() => this.cambiarVista("/listaVacunacionInfluenza")}>
              <img src={vacunacion_influenza} style={{ width: "100%" }}></img>
            </a>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
