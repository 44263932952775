import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import { store } from "./../../store/index";
import "./../../assets/css/inicio.css";
import GesIngreso from "./GestionGeneralIngreso";
import GesProceso from "./GestionGeneralProceso";
import GesDerivado from "./GestionGeneralDerivado";
import GesFinalizado from "./GestionGeneralFinalizado";
import MensajeGlobal from "./../../components/Gestion/MensajeGlobal";
import "./../../assets/css/gestion.css";
import AgregarCaso from "./../../components/Gestion/AgregarCasoOpciones.js";
import vista_ingreso from "../../assets/img/gestion/vista_ingreso.svg";
import vista_proceso from "../../assets/img/gestion/vista_proceso.svg";
import vista_derivado from "../../assets/img/gestion/vista_derivar.svg";
import vista_finalizado from "../../assets/img/gestion/vista_finalizar.svg";
import vista_ingreso_activa from "../../assets/img/gestion/vista_ingreso_activa.svg";
import vista_proceso_activa from "../../assets/img/gestion/vista_proceso_activa.svg";
import vista_derivado_activa from "../../assets/img/gestion/vista_derivar_activa.svg";
import vista_finalizado_activa from "../../assets/img/gestion/vista_finalizar_activa.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import BuscarUsuarioGestion from "./../../components/Gestion/BuscarUsuarioGestion";

import signo from "./../../assets/img/signo.svg";

export default class GestionGeneral extends Component {
  constructor(props) {
    super(props);

    let redireccion_ubicacion = 1;
    let rutFiltro = null;
    let uspFiltro = true;
    if (this.props.history.location.state != undefined) {
      redireccion_ubicacion = this.props.history.location.state.ubicacion;
      rutFiltro = this.props.history.location.state.rut;
      uspFiltro = false;
    }

    this.state = {
      alerta: false,
      ubicacion: 1,
      secId: 0,
      total: 0,
      tipo: 4,
      demanda: [],
      preguntas: false,
      especialidad: null,
      gedId: null,
      rutFiltro: rutFiltro,
      uspFiltro: uspFiltro,
      gestionIngreso: redireccion_ubicacion == 1 ? true : false,
      gestionProceso: redireccion_ubicacion == 3 ? true : false,
      gestionDerivado: redireccion_ubicacion == 5 ? true : false,
      gestionFinalizado: redireccion_ubicacion == 6 ? true : false,
      modalAgregarCaso: false,
      modalBuscarUsuarioGestion: false,
      uspId: store.getState().usp,
      cesfam: 25,
      nombre_cesfam: "Apoyo Social",
      nombre_ubicacion: "Ingresados",
      modalMensajeGlobal: false,
      vistaActual: "gestionIngreso",
    };
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Gestión Apoyo Social <label style='font-size:25px'>" + this.state.nombre_ubicacion + "</label> <span title='Permite gestionar de forma manual casos que ingresan con distintos requerimientos'><img src=" + signo + "></img></span>";
  };

  actualizarVista = (ubicacion, rut, uspFiltro = true) => {
    this.setState({ rutFiltro: rut, uspFiltro: uspFiltro }, () => {
      this.handleChange(ubicacion);
    });
  };

  activarVista = (vistaActivada) => {
    this.setState(
      {
        gestionIngreso: false,
        gestionProceso: false,
        gestionDerivado: false,
        gestionFinalizado: false,
      },
      () => {
        this.setState({ vistaActual: vistaActivada, [vistaActivada]: true }, () => {
          this.setState({ rutFiltro: null, uspFiltro: true });
        });
      }
    );
  };

  handleChange = (vista) => {
    if (vista == 1) {
      this.setState({ nombre_ubicacion: "Ingresados" }, () => {
        this.activarVista("gestionIngreso");
        this.titulo();
      });
    }
    if (vista == 3) {
      this.setState({ nombre_ubicacion: "En proceso" }, () => {
        this.activarVista("gestionProceso");
        this.titulo();
      });
    }
    if (vista == 5) {
      this.setState({ nombre_ubicacion: "Derivados" }, () => {
        this.activarVista("gestionDerivado");
        this.titulo();
      });
    }
    if (vista == 6) {
      this.setState({ nombre_ubicacion: "Finalizados" }, () => {
        this.activarVista("gestionFinalizado");
        this.titulo();
      });
    }
  };

  mostrarModalAgregarCaso = () => {
    this.setState({
      modalAgregarCaso: true,
    });
  };

  ocultarModalAgregarCaso = () => {
    this.setState({
      modalAgregarCaso: false,
    });
    this.setState({ gestionIngreso: false });
    this.activarVista("gestionIngreso");
  };

  mostrarModalBuscarUsuarioGestion = () => {
    this.setState({
      modalBuscarUsuarioGestion: true,
    });
  };

  ocultarModalBuscarUsuarioGestion = () => {
    this.setState({
      modalBuscarUsuarioGestion: false,
    });
  };

  mostrarMensajeGlobal = () => {
    this.setState({
      modalMensajeGlobal: true,
    });
  };

  ocultarMensajeGlobal = () => {
    this.setState({
      modalMensajeGlobal: false,
      gestionIngreso: false,
      gestionProceso: false,
      gestionDerivado: false,
      gestionFinalizado: false,
    });
    setTimeout(() => {
      this.setState({
        [this.state.vistaActual]: true,
      });
    }, 100);
  };

  render() {
    let botonGestionIngreso = this.state.gestionIngreso ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-4px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonGestionProceso = this.state.gestionProceso ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonGestionDerivado = this.state.gestionDerivado ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonGestionFinalizado = this.state.gestionFinalizado ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    return (
      <div className="middle-area">
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: 30 }}>
            <Tooltip title="Ingresados" aria-label="Ingresados">
              <img style={botonGestionIngreso} className="imagenBotonAccionMenu" src={this.state.gestionIngreso ? vista_ingreso_activa : vista_ingreso} onClick={() => this.handleChange(1)} />
            </Tooltip>
            <Tooltip title="En proceso" aria-label="En proceso">
              <img style={botonGestionProceso} className="imagenBotonAccionMenu" src={this.state.gestionProceso ? vista_proceso_activa : vista_proceso} onClick={() => this.handleChange(3)} />
            </Tooltip>
            <Tooltip title="Derivados" aria-label="Derivados">
              <img style={botonGestionDerivado} className="imagenBotonAccionMenu" src={this.state.gestionDerivado ? vista_derivado_activa : vista_derivado} onClick={() => this.handleChange(5)} />
            </Tooltip>
            <Tooltip title="Finalizados" aria-label="Finalizados">
              <img style={botonGestionFinalizado} className="imagenBotonAccionMenu" src={this.state.gestionFinalizado ? vista_finalizado_activa : vista_finalizado} onClick={() => this.handleChange(6)} />
            </Tooltip>

            <a onClick={this.mostrarModalAgregarCaso} className={"btnAgregarCaso fondoVerde"} style={{ cursor: "pointer" }}>
              Agregar caso
            </a>

            <a onClick={this.mostrarModalBuscarUsuarioGestion} className={"btnAgregarCaso fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }}>
              Buscar Usuario
            </a>
          </GridItem>
        </GridContainer>
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} style={{ margin: 0 }}>
              {this.state.gestionIngreso ? <GesIngreso rutFiltro={this.state.rutFiltro} uspId={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} actualizarVista={this.actualizarVista}></GesIngreso> : null}
              {this.state.gestionProceso ? <GesProceso rutFiltro={this.state.rutFiltro} uspFiltro={this.state.uspFiltro} uspId={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam}></GesProceso> : null}
              {this.state.gestionDerivado ? <GesDerivado rutFiltro={this.state.rutFiltro} uspId={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam}></GesDerivado> : null}
              {this.state.gestionFinalizado ? <GesFinalizado rutFiltro={this.state.rutFiltro} uspFiltro={this.state.uspFiltro} uspId={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} actualizarVista={this.actualizarVista}></GesFinalizado> : null}
              {this.state.modalAgregarCaso ? <AgregarCaso ocultar={this.ocultarModalAgregarCaso} tipo={this.state.tipo} cesfam={this.state.cesfam}></AgregarCaso> : null}
              {this.state.modalMensajeGlobal ? <MensajeGlobal ocultar={this.ocultarMensajeGlobal} tipo={this.state.tipo}></MensajeGlobal> : null}
              {this.state.modalBuscarUsuarioGestion ? <BuscarUsuarioGestion ocultar={this.ocultarModalBuscarUsuarioGestion} actualizarVista={this.actualizarVista} tipo={this.state.tipo}></BuscarUsuarioGestion> : null}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
