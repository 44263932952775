import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NotificationManager } from "react-notifications";

import csvCiclo from "./../../assets/img/imgCargaMasiva/cargaCicloEjemplo.png";
import csvExamen from "./../../assets/img/ejemplo_csv_examen.png";
import csvConsolidado from "./../../assets/img/ejemplo_csv_consolidado.png";

import { store } from "./../../store/index";
import carga_ciclos from "../../assets/img/imgCargaMasiva/ciclos.svg";
import carga_beneficios from "../../assets/img/imgCargaMasiva/beneficios.svg";
import carga_prenaturo from "../../assets/img/imgCargaMasiva/prematuros.svg";
import carga_reservaExterba from "../../assets/img/imgCargaMasiva/reservas externas.svg";
import carga_fallecidos from "../../assets/img/imgCargaMasiva/fallecidos.svg";
import Tooltip from "@material-ui/core/Tooltip";

import { FaQuestionCircle } from "react-icons/fa";

import signo from "./../../assets/img/signo.svg";

// CSS
import "./../../assets/css/usuarios.css";
import moduleCM from "../../assets/css/cargamasiva.module.css";

export default class CargarUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      ciclos: [],
      niveles: [],
      ciclo: null,
      nivel: null,
      datos: [],
      tipo_carga: "cargarCiclos",
      herramientas: store.getState().herramientas,
      ultimaCarga: null,
      idCarga: null,
      nombre_ciclo: "",
      id_ciclo: null,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.traeCiclos();
    this.titulo();
  }

  ultimaCarga() {
    axios
      .get(global.config.modulos.cargas_masivas + "ciclos/ultima_carga/", {
        headers: authHeader(),
        params: { id_usp: this.state.uspId, nombre_ciclo: this.state.nombre_ciclo, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          let fecha = res.data.datos.cam_fecha_registro.split("T");
          dia = String(fecha[0]).split("-");
          let dia = dia[0] + "-" + dia[1] + "-" + dia[2];
          this.setState({ ultimaCarga: "Última carga: " + dia + " a las " + fecha[1], idCarga: res.data.datos.cam_id });
        } else {
          this.setState({ ultimaCarga: null });
        }
      });
  }

  descargarExcel = (e) => {
    const url = global.config.modulos.cargas_masivas + "ciclos/excel_errores/?id_carga=" + this.state.idCarga;
    window.open(url, "_blank").focus();
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva <span title='Permite ingresar o actualizar a través de de un archivo .csv información sobre los ciclos de los pacientes'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  traeCiclos = () => {
    axios
      .get(global.config.modulos.cargas_masivas + "ciclos/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        this.setState({ ciclos: res.data });
      });
  };

  cambioValorCiclo = (event) => {
    const { name, value } = event.target;
    let ciclo = value.split(";");
    let id_ciclo = ciclo[0];
    let nombre_ciclo = ciclo[1];
    this.setState(
      {
        [name]: value,
        id_ciclo: id_ciclo,
        nombre_ciclo: nombre_ciclo,
      },
      () => {
        this.traerNiveles(id_ciclo);
        this.ultimaCarga();
      }
    );
  };

  cambioValor = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  traerNiveles(ciclo) {
    axios
      .get(global.config.modulos.cargas_masivas + "ciclos/niveles/", {
        headers: authHeader(),
        params: { cic_id: ciclo },
      })
      .then((res) => {
        this.setState({ niveles: res.data });
      });
  }

  handleOnDrop = (data) => {
    console.log(data);

    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    if (registros.length == 0) {
      NotificationManager.error("Revise el formato del csv o si contiene datos", "", 5 * 60 * 1000);
      return false;
    }
    this.setState({ datos: registros });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    document.getElementById("cargando").style.display = "block";

    axios
      .post(
        global.config.modulos.cargas_masivas + "ciclos/carga_masiva/",
        {
          ciclos: this.state.datos,
          uspId: this.state.uspId,
          ciclo: this.state.id_ciclo,
          nivel: this.state.nivel,
          cesfam: this.state.cesfam,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success("los registros se están ingresando, se le notificará cuando termine", "", 5 * 60 * 1000);
        } else {
          NotificationManager.error(res.data.mensaje, "", 5 * 60 * 1000);
          if (res.data.errores.length > 0) {
            let mensaje = "";

            res.data.errores.forEach((reg) => {
              mensaje += reg + "\n";
            });

            const element = document.createElement("a");
            const file = new Blob([mensaje], {
              type: "text/plain;charset=utf-8",
            });
            element.href = URL.createObjectURL(file);
            element.download = "excepciones.txt";
            document.body.appendChild(element);
            element.click();
          }
        }
      })
      .catch((error) => {
        document.getElementById("cargando").style.display = "none";
        NotificationManager.error("Error al ingresar los registros", "", 5 * 60 * 1000);
      });
  }

  cambiarVista = (e) => {
    const { name, value } = e.target;
    this.setState({ tipo_carga: value }, () => {
      this.props.history.push(this.state.tipo_carga);
    });
  };

  cargarCiclos = () => {
    this.props.history.push("/cargarCiclos");
  };

  cargarFallecidos = () => {
    this.props.history.push("/cargarFallecidos");
  };

  cargarCondiciones = () => {
    this.props.history.push("/cargarCondiciones");
  };

  cargarBeneficios = () => {
    this.props.history.push("/cargarBeneficios");
  };

  cargarReservasExternas = () => {
    this.props.history.push("/cargarReservasExternas");
  };

  render() {
    const { niveles, ciclos, ciclo, nivel, datos, ultimaCarga } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={moduleCM.divIcon}>
                    <img className={moduleCM.imgCMciclos} src={carga_ciclos} onClick={this.cargarCiclos} />

                    <img className={moduleCM.imgCMbeneficio} src={carga_beneficios} onClick={this.cargarBeneficios} />

                    <img className={moduleCM.imgCMfallecido} src={carga_fallecidos} onClick={this.cargarFallecidos} />

                    {this.state.herramientas.includes("H44") ? <img className={moduleCM.imgCMprematuro} src={carga_prenaturo} onClick={this.cargarCondiciones} /> : null}

                    {this.state.herramientas.includes("H47") ? <img className={moduleCM.imgCMreservaExterna} src={carga_reservaExterba} onClick={this.cargarReservasExternas} /> : null}
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  {ultimaCarga && (
                    <Grid item xs={12} sm={12}>
                      <Button
                        onClick={this.descargarExcel}
                        style={{
                          backgroundColor: "#bf3c50",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Descargar errores - {ultimaCarga}
                      </Button>
                    </Grid>
                  )}
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                      Inscribir/Desinscribir
                    </InputLabel>
                    <NativeSelect
                      className={moduleCM.selectCiclos}
                      value={this.state.tipo_carga}
                      onChange={this.cambiarVista}
                      inputProps={{
                        name: "tipo_carga",
                        id: "tipo_carga",
                      }}
                    >
                      <option selected value="cargarCiclos">
                        Inscribir en ciclo
                      </option>
                      <option value="eliminarCiclos">Desinscribir de ciclo</option>
                    </NativeSelect>
                  </Grid>
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                          Ciclo
                        </InputLabel>

                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.ciclo}
                          onChange={this.cambioValorCiclo}
                          inputProps={{
                            name: "ciclo",
                            id: "ciclo",
                          }}
                        >
                          <option value="">Seleccione ciclo</option>
                          {ciclos &&
                            ciclos.map((cic, index) => (
                              <option value={cic["cic_id"] + ";" + cic["cic_nombre"]} key={index}>
                                {cic["cic_nombre"]}
                              </option>
                            ))}
                        </NativeSelect>
                      </Grid>
                      {this.state.ciclo && this.state.ciclo != "11;Consolidado" && this.state.ciclo != "12;Examenes" && (
                        <Grid item xs={6} sm={6}>
                          <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                            Atención
                          </InputLabel>
                          <NativeSelect
                            className={moduleCM.selectCiclos}
                            value={this.state.nivel}
                            onChange={this.cambioValor}
                            inputProps={{
                              name: "nivel",
                              id: "nivel",
                            }}
                          >
                            <option value="">Seleccione atención</option>
                            {niveles &&
                              niveles.map((ces, index) => (
                                <option value={ces["cin_id"]} key={index}>
                                  {ces["cin_nombre"]}
                                </option>
                              ))}
                          </NativeSelect>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <br></br>
                  {this.state.ciclo && this.state.ciclo != "11;Consolidado" && this.state.ciclo != "12;Examenes" && (
                    <Grid item xs={12} sm={12}>
                      <label>(*) Si no selecciona atención el ciclo comenzará desde la primera atención</label>
                    </Grid>
                  )}
                  <br></br>
                  {ciclo && (
                    <Grid item xs={12} sm={12}>
                      <span>Cargar CSV</span>
                      <div className={moduleCM.csvCargaMasiva}>
                        <CSVReader
                          borderColor="#eaa028"
                          onDrop={this.handleOnDrop}
                          onError={this.handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={this.handleOnRemoveFile}
                          config={{
                            header: true,
                            dynamicTyping: true,
                            skipEmptyLines: true,
                            transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                          }}
                        >
                          <div className={moduleCM.divSpanArchivo}>
                            <span className={moduleCM.spanArchivo}>Seleccione archivo</span>
                            <span style={{ fontSize: "0.9vw" }}> O arrastre archivo .CSV </span>
                          </div>
                        </CSVReader>
                        <LinearProgress id="cargando" style={{ display: "none" }} />
                      </div>
                    </Grid>
                  )}
                  {ciclo && datos.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={this.cargaMasiva}
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Cargar registros
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                  {this.state.ciclo == "11;Consolidado" ? <img className={moduleCM.imgEjemploCiclo} src={csvConsolidado} alt="csv" /> : this.state.ciclo == "12;Examenes" ? <img className={moduleCM.imgEjemploCiclo} src={csvExamen} alt="csv" /> : <img className={moduleCM.imgEjemploCiclo} src={csvCiclo} alt="csv" />}
                </Grid>
              </Grid>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
