import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyC5EY1FCr5ErlX5FyxKHbS5ErhUtblHXyM",
  authDomain: "horasaludap.firebaseapp.com",
  projectId: "horasaludap",
  storageBucket: "horasaludap.appspot.com",
  messagingSenderId: "1044228219283",
  appId: "1:1044228219283:web:16869e49af473a33dfc866",
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: "BH-xRtw-LSLxlmNpOzfUtWUmuL24P792LF6EK39W9C-pwFKPDQ-rea_ZjT5y7-PSCh5FsIPKoxKrQZ4p2FVKPig" })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        setTokenFound("");
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      //console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
