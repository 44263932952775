import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import TimePicker from "rc-time-picker";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class FinalizarCasoMultiple extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      solucion: "",
      gegId: props.gegId,
      usuId: props.usuId,
      usuCesfam: null,
      canal: 5,
      casosSeleccionados: props.casosSeleccionados,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerUsuario() {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        usuCesfam: res.data.datos.usu_cesfam,
      });
    });
  }

  finalizarCaso = () => {
    let solucion = this.state.solucion;
    let casos = this.state.casosSeleccionados;
    let especialidad = this.state.especialidad;

    casos.map((caso, index) => {
      axios
        .get(global.config.modulos.gestion, {
          headers: authHeader(),
          params: { geg_id: caso },
        })
        .then((res) => {
          if (res.data.datos["geg_chat"] > 0) {
            axios
              .patch(
                global.config.modulos.chat + "modificar_chat/",
                {
                  id: res.data.datos["geg_chat"],
                  cht_estado: 0,
                },
                {
                  headers: authHeader(),
                }
              )
              .then(() => {});
          }
        });

      axios
        .patch(
          global.config.modulos.gestion,
          {
            geg_id: caso,
            geg_solucion: solucion,
            geg_especialidad: especialidad,
            geg_ubicacion: 6,
            geg_estado: 3,
            geg_fecha_finalizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          this.generarHistorial("Se finaliza el caso", caso, this.state.uspId);

          if (index === casos.length - 1) {
            NotificationManager.success("Casos finalizados con éxito");
            this.salir();
          }
        });
    });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Finalizar caso</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Indique la solución entregada</InputLabel>
                    <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="solucion" name="solucion" value={this.state.solucion} onChange={this.cambiarValores} label="Solución">
                      <MenuItem value={"Agendar visita domiciliaria"}>Agendar visita domiciliaria</MenuItem>
                      <MenuItem value={"Confección de receta"}>Confección de receta</MenuItem>
                      <MenuItem value={"Entrega a domicilio"}>Entrega a domicilio</MenuItem>
                      <MenuItem value={"Entrega presencial"}>Entrega presencial</MenuItem>
                      <MenuItem value={"No responde"}>No responde</MenuItem>
                      <MenuItem value={"Paciente rechazó atención"}>Paciente rechazó atención</MenuItem>
                      <MenuItem value={"Se agenda atención presencial"}>Se agenda atención presencial</MenuItem>

                      <MenuItem value={"Se agenda teleconsulta"}>Se agenda teleconsulta</MenuItem>
                      <MenuItem value={"Se entrega hora por SOME"}>Se entrega hora por SOME</MenuItem>

                      <MenuItem value={"Usuario informado"}>Usuario informado</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.finalizarCaso} style={{ marginLeft: 20 }} color="primary" className="fondoVerde">
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
