import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store";
import { NotificationManager } from "react-notifications";
import axios from "axios";

// core components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import "react-confirm-alert/src/react-confirm-alert.css";

export default class CambiarAtencion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      idDemanda: props.idDemanda,

      especialidad: null,
      especialidades: [],

      open: true,
      formErrors: {},
    };
  }

  componentDidMount() {
    this.getCesfamEspecialdad();
  }

  salir = (reiniciar) => {
    this.setState({
      open: false,
    });
    this.props.ocultar(reiniciar);
  };

  cambiarValores = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validaciones() {
    const { especialidad } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (especialidad == "" || especialidad == null) {
      formIsValid = false;
      formErrors["atencionErr"] = "Selecciona una atención";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  cambiarAtencion = () => {
    if (this.validaciones()) {
      axios
        .patch(
          global.config.modulos.demanda + "cambiar_atencion/",
          {
            id: this.state.idDemanda,
            usp: this.state.uspId,
            especialidad: this.state.especialidad,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          this.salir(true);
        });
    }
  };

  getCesfamEspecialdad = () => {
    axios
      .get(global.config.modulos.especialidades, {
        headers: authHeader(),
        params: { esp_id: this.props.especialidad },
      })
      .then((res) => {
        this.traerEspecialidades(res.data.datos.esp_cesfam_id);
      });
  };

  traerEspecialidades(cesfam) {
    axios.get(global.config.modulos.especialidades + "especialidades_entregan_horas/", { headers: authHeader(), params: { cesfam: cesfam } }).then((res) => {
      this.setState({
        especialidades: res.data.datos,
      });
    });
  }

  render() {
    const { atencionErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={() => this.salir(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" id="finalizarDemanda">
        <DialogContent>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>Cambiar atención</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                <FormControl fullWidth>
                  <InputLabel id="label-sector">Atención asociada</InputLabel>
                  <Select labelId="label-sector" id="especialidad" name="especialidad" value={this.state.especialidad || ""} fullWidth onChange={this.cambiarValores}>
                    {this.state.especialidades.map((e, index) => {
                      if (this.props.especialidad != e.esp_id) {
                        return (
                          <MenuItem key={index} value={e.esp_id}>
                            {e.esp_nombre}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  {atencionErr && <div style={{ color: "red", paddingBottom: 10 }}>{atencionErr}</div>}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                <Button style={{ float: "right", marginLeft: 10 }} onClick={this.salir} className="botonSimple fondoRojo">
                  Cerrar
                </Button>
                <Button onClick={this.cambiarAtencion} className="botonSimple fondoVerde" style={{ float: "right" }}>
                  Cambiar
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
