import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

import moment from "moment";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Comentarios extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      comid: "",
      comentario: "",
      comentarios: [],
      formErrors: {},

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      usuId: props.usuId,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAlertaExito() {
    NotificationManager.success("Comentario registrado con éxito");
  }

  mostrarAlertaModificado() {
    confirmAlert({
      title: "",
      message: "Comentario modificado con éxito",
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validaciones() {
    const { comentario } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!comentario) {
      formIsValid = false;
      formErrors["comentarioErr"] = "Escribe el comentario.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        com_comentario: this.state.comentario,
        com_usuario: this.state.usuId,
        com_estado: 1,
        com_usuario_panel: this.state.uspId,
        com_cesfam: this.state.cesfam,
      };
      let apiUrl = global.config.modulos.comentarios + "agregar_comentario/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        NotificationManager.success("Comentario registrado con éxito");
        this.salir();
      });
    }
  };

  render() {
    const { comentarioErr } = this.state.formErrors;
    const { comentarios } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="comentarios">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Comentarios</h2>
                </GridItem>

                <GridItem id="modalComentarioComentario" xs={12} style={{ marginTop: 30 }}>
                  <TextField id="standard-basic" label="Comentario" value={this.state.comentario} style={{ with: "100%" }} type="text" name="comentario" onChange={this.cambiarValores} className={comentarioErr ? "inputText showError" : "inputText"} />
                  {comentarioErr && <div style={{ color: "red", paddingBottom: 10 }}>{comentarioErr}</div>}
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button onClick={this.salir} className="botonSimple floatRigth fondoRojo" style={{ marginLeft: 20 }}>
                    Salir
                  </Button>
                  <Button onClick={this.enviarFormulario} className="botonSimple fondoVerde" id="btnAgregar" style={{ float: "right" }}>
                    Agregar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
