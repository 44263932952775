import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class AlertaHTMLOpciones extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      mensaje: props.mensaje.mensaje,
      gestion: props.mensaje.gestion,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  agregar = () => {
    this.setState({
      open: false,
    });
    this.props.agregarCaso();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="alertaHTML">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  {this.state.mensaje}
                  {this.state.gestion.map((gestion, index) => (
                    <p key={index} style={{ marginTop: 10 }}>
                      <p>
                        <label style={{ fontWeight: "bold" }}>Cesfam:</label> {gestion.cesfam}
                      </p>
                      <p>
                        <label style={{ fontWeight: "bold" }}>Ubicación:</label> {gestion.ubicacion}
                      </p>
                      <p>
                        <label style={{ fontWeight: "bold" }}>Tomado por:</label> {gestion.tomado}
                      </p>
                    </p>
                  ))}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <a onClick={this.salir} style={{ marginLeft: 20 }} className="btnAgregarCaso fondoNaranjo">
                    Cancelar
                  </a>
                  <a onClick={this.agregar} style={{ marginLeft: 20 }} className="btnAgregarCaso fondoVerde">
                    Aceptar
                  </a>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
