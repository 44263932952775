import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "../../../assets/css/graficosD.css";
import Cargando from "components/Helpers/Cargando";

export default class GraficoDeBarras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      datos: [],
      dates: [],
      datis: [],
      datus: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      fecha_semana: props.fecha_semana,
      fecha_mes: props.fecha_mes,
      fecha_año: props.fecha_año,
      cesfam: props.cesfam,
      root: "",
      idCliente: props.idCliente,
      ces: "",
      dd: "",
      di: "",
      db: "",
      df: "",
      today: new Date(),
      year: "",
      month: "",
      day: "",
      dateStr: "",
      titulo_archivo: "",
      cesNombre: props.cesNombre,
      cargando: true,
    };
  }

  grafico_barras4() {
    this.setState({
      datos: [],
      dateStr: `${this.state.year}-${this.state.month}-${this.state.day}`,
    });
    axios
      .get(global.config.modulos.grafico_demanda + "datosVistaDirector1/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_semana: this.state.fecha_semana,
          fecha_mes: this.state.fecha_mes,
          fecha_año: this.state.fecha_año,
          cliente: this.state.idCliente,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          this.setState({ cargando: false });
          return;
        }
        this.setState(
          {
            datos: res.data.datos[0],
            dd: res.data.datos[1],
            di: res.data.datos[2],
            db: res.data.datos[3],
            df: res.data.datos[4],
          },
          () => {
            try {
              this.state.root.dispose();
            } catch (e) {}

            let root = am5.Root.new("chartdiv1");
            root._logo.dispose();

            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
              am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                layout: root.verticalLayout,
                maxTooltipDistance: 1,
              })
            );

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            let legend = chart.children.push(
              am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
              })
            );

            let data = this.state.datos;

            let title2 = chart.children.unshift(
              am5.Label.new(root, {
                text: `Total Demanda: ${this.state.dd}  Total Oferta Inicial: ${this.state.di}  Total Bloqueos: ${this.state.db} Total Oferta Final: ${this.state.df}`,
                textAlign: "Left",
                width: am5.p100,
                fontSize: "1.2rem",
                fill: "#808080",
                fontWeight: "635",
                lineHeight: -1,
              })
            );

            let title = chart.children.unshift(
              am5.Label.new(root, {
                text: `Informacion General por Cesfam `,
                textAlign: "Left",
                width: am5.p100,
                fontSize: "2rem",
                fill: "#808080",
                fontWeight: "750",
                lineHeight: -1,
              })
            );

            let xRenderer = am5xy.AxisRendererX.new(root, {
              minGridDistance: 15,
              cellStartLocation: 0.1,
              cellEndLocation: 0.9,
            });
            xRenderer.labels.template.setAll({
              rotation: 0,
              centerY: am5.p50,
              paddingRight: 10,
              fontSize: "0.84rem",
            });

            xRenderer.grid.template.setAll({
              location: 1,
            });

            let xAxis = chart.xAxes.push(
              am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "cesfam",
                renderer: xRenderer,
              })
            );

            xAxis.data.setAll(data);

            let yAxis = chart.yAxes.push(
              am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {
                  strokeOpacity: 0.1,
                }),
              })
            );

            function makeSeries(name, fieldName) {
              let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: fieldName,
                  categoryXField: "cesfam",
                  populateText: true,

                  tooltip: am5.Tooltip.new(root, {
                    labelText: "[bold]{name}[/] : {valueY}",
                    autoTextColor: false,
                    fill: am5.color(0xff5566),
                  }),
                })
              );

              series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}:{valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0,
              });

              series.data.setAll(data);

              series.appear();

              series.bullets.push(function () {
                return am5.Bullet.new(root, {
                  locationY: 0,
                  sprite: am5.Label.new(root, {
                    text: "{valueY}",
                    fill: root.interfaceColors.get("alternativeText"),
                    centerY: 0,
                    centerX: am5.p50,
                    populateText: true,
                  }),
                });
              });

              legend.data.push(series);
            }

            chart.get("colors").set("colors", [am5.color("#eaa028"), am5.color("#ef4597"), am5.color("#6e45af"), am5.color("#4fc9ba")]);

            let exporting = am5plugins_exporting.Exporting.new(root, {
              menu: am5plugins_exporting.ExportingMenu.new(root, {}),
              dataSource: this.state.datos,

              title: `INFORMACION GENERAL POR CESFAM`,
              pdfOptions: {
                imageFormat: "jpg",
                addColumnNames: true,
              },
              htmlOptions: {
                disabled: true,
              },
              jpgOptions: {
                disabled: true,
              },
              csvOptions: {
                disabled: true,
              },
              jsonOptions: {
                disabled: true,
              },
              printOptions: {
                disabled: true,
              },
              xlsxOptions: {
                imageFormat: "jpg",

                addColumnNames: true,
              },
              pngOptions: {
                disabled: true,
              },
              pdfdataOptions: {
                disabled: true,
              },

              filePrefix: this.state.titulo_archivo,

              dataFields: {
                cesfam: "Cesfams",
                demanda: "Demanda",
                oferta_inicial: "Oferta Inicial",
                bloqueos: "Bloqueos",
                oferta_final: "Oferta Final",
                ab: " ",
                aa: `Demanda Total ${this.state.dd}`,
                bb: `Oferta Inicial Total ${this.state.di}`,
                cc: `Bloqueadas Total ${this.state.db}`,
                dd: `Oferta Final Total ${this.state.df}`,
              },
            });

            makeSeries("Demanda", "demanda");
            makeSeries("Oferta Inicial", "oferta_inicial");
            makeSeries("Bloqueadas", "bloqueos");
            makeSeries("Oferta Final", "oferta_final");
            this.setState({ cargando: false });
            chart.appear(1000, 100);
          }
        );
      });
  }

  componentDidMount() {
    this.setState(
      {
        year: this.state.today.getFullYear().toString().slice(),
        month: (this.state.today.getMonth() + 1).toString().padStart(2, "0"),
        day: this.state.today.getDate().toString().padStart(2, "0"),
        act_date: new Date(this.state.fecha_actual),
        ini_date: new Date(this.state.fecha_inicio),
        ter_date: new Date(this.state.fecha_termino),
      },
      () => {
        if (!this.state.fecha_actual && !this.state.fecha_termino && !this.state.fecha_inicio) {
          this.setState({
            titulo_archivo: `Grafico Comuna General ${this.state.day}-${this.state.month}-${this.state.year}`,
          });
        } else if ((this.state.fecha_actual !== "") & !this.state.fecha_termino && !this.fecha_inicio) {
          this.setState({
            titulo_archivo: ` Grafico Comuna General ${this.state.act_date.getDate().toString().padStart(2, "0")}-${(this.state.act_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.act_date.getFullYear().toString().slice()}`,
          });
        } else {
          this.setState({
            act_date: "",
            titulo_archivo: `Grafico Comuna General ${this.state.ini_date.getDate().toString().padStart(2, "0")}-${(this.state.ini_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ini_date.getFullYear().toString().slice()} ~ ${this.state.ter_date.getDate().toString().padStart(2, "0")}-${(this.state.ter_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ter_date.getFullYear().toString().slice()}`,
          });
        }
      }
    );

    this.grafico_barras4();
  }

  render() {
    return (
      <div style={{ display: "flex", alignItems: "flex-start", width: "120%" }}>
        {this.state.cargando ? <Cargando /> : null}
        <div
          id="chartdiv1"
          style={{
            width: "88vw",
            height: "63vh",
            zIndex: -1,
            position: "absolute",
            left: "5vw",
            marginTop: "15px",
          }}
        ></div>
      </div>
    );
  }
}
