import React from 'react';
import Button from "@material-ui/core/Button";
import GridItem from '../grid/GridItem';
import Dialog from "@material-ui/core/Dialog";
import GridContainer from "../grid/GridContainer.js";
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import CuentaRegModal from './TiempoModal'



export const IdleModal = ({showModal, handleClose, handleLogout, tiempoModal}) => {
    
    // Inicializa el cronometro del Modal si este ultimo esta visible
    var modCrono;
    if (showModal) {
        modCrono = CuentaRegModal(tiempoModal);
    }

    //Acciones al acabarse el tiempo establecido
    if (modCrono === 0) {
        handleLogout();
    }

        return(
            <Dialog open={showModal} onClose={handleClose} className="modificarUsuario">
                <GridContainer  className="agendar-container">
                    <GridItem xs={12} sm={12} md={12} className="" style={{ marginTop: 30, marginLeft: 20, marginRight: 10 }}>
                        <GridContainer className="">
                            <GridItem xs={12} style={{ marginTop: 10 }}>
                            <h1>¡Estás inactivo!</h1>
                            </GridItem>
                            <GridItem xs={12} style={{ marginTop: 20 }}>
                            <h3>Se desconectará su sesión automáticamente. <br/> ¿Desea permanecer aqui?</h3>
                            </GridItem>
                            <GridItem xs={12} style={{ marginTop: 30, display: "flex" , justifyContent: 'flex-end' }}>
                                <Button style={{ marginRight: 5 }} className="fondoVerde" onClick={handleClose}>
                                    Permanecer
                                </Button>
                                <Button style={{ marginLeft: 5, marginRight: 10 }} color="primary" className="botonGeneral" onClick={handleLogout}>
                                    Se Cerrará Sesión en {modCrono}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </Dialog>
        )
}