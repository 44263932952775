import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./../../assets/css/usuarios.css";
import { NotificationManager } from "react-notifications";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

export default class ModalDesinscribir extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      usu: props.usu,
      ben: props.ben,
      ben_nombre: props.ben_nombre,
      usp: props.usp,
      mostrarBotones: true,
      mostrarMotivoEliminacion: false,
      motivoSeleccionado: "",
    };
  }

  cambiarValores = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  confirmaDesinscribir = () => {
    this.setState({ mostrarBotones: false, mostrarMotivoEliminacion: true });
  };

  desactivarRegistro = () => {
    let apiUrl = global.config.modulos.beneficios + "datos_latencias_beneficios/";

    if (this.state.motivoSeleccionado === "") {
      NotificationManager.error("Seleccione un motivo");
      return;
    }

    axios
      .post(
        apiUrl,
        {
          accion: 2,
          usu: this.state.usu,
          ben: this.state.ben,
          usu_panel: this.state.usp,
          motivo: this.state.motivoSeleccionado,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado === "false") {
          NotificationManager.error("Error al desinscribir registro");
        } else {
          NotificationManager.success("Registro desinscrito");
          this.setState({ open: false }, () => {
            this.props.traerDatos();
          });
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="escucharAudio">
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h3>¿Estás segur@ que deseas desinscribir del beneficio {this.state.ben_nombre} al paciente?</h3>
                {this.state.mostrarBotones ? (
                  <div
                    style={{
                      paddingTop: "55px",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#bf4b3c",
                        color: "white",
                        boxShadow: "none",
                      }}
                      onClick={() => this.confirmaDesinscribir()}
                    >
                      Desinscribir
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#eaa028",
                        color: "white",
                        boxShadow: "none",
                      }}
                      onClick={() => this.salir()}
                    >
                      Cancelar
                    </Button>{" "}
                  </div>
                ) : null}
                {this.state.mostrarMotivoEliminacion ? (
                  <div
                    style={{
                      paddingTop: "25px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "contents" }}>
                      <FormControl
                        style={{
                          width: "60%",
                        }}
                      >
                        <InputLabel id="Filtro-label">Seleccione Motivo de Desinscripción</InputLabel>
                        <Select labelId="Filtro-label" id="Filtro-select" name="motivoSeleccionado" onChange={this.cambiarValores}>
                          <MenuItem value="">Seleccione el motivo</MenuItem>
                          <MenuItem value="1">Alta por término de tratamiento</MenuItem>
                          <MenuItem value="2">Alta por inasistencia</MenuItem>
                          <MenuItem value="3">Error de digitación</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row-reverse",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#eaa028",
                          color: "white",
                          top: "7px",
                          boxShadow: "none",
                        }}
                        onClick={() => this.desactivarRegistro()}
                      >
                        Aceptar
                      </Button>
                    </div>
                  </div>
                ) : null}
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
