import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "store";
import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import { confirmAlert } from "react-confirm-alert";
import MaterialTable from "material-table";
import AgregarUsuarioPanel from "components/UsuariosPanel/AgregarUsuarioPanel";
import ModificarUsuarioPanel from "components/UsuariosPanel/ModificarUsuarioPanel";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ModalAlerta from "../Solicitud/Ticket/modalAlerta";
import chileanRut from "chilean-rut";
import Cargando from "../../components/Helpers/Cargando";
import NotificationManager from "react-notifications/lib/NotificationManager";

// CSS
import FuncionarioCSS from "assets/css/funcionario.module.css";
import SolicitudCSS from "assets/css/solicitudTrabajo.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";

//IMG
import Editar from "assets/img/usuarios/icono_modificar.svg";
import Eliminar from "assets/img/usuarios/icono_eliminar.svg";

export default class Funcionarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limiteUsuariosPanel: 0,
      usuariosPanelCreados: 0,
      rut: "",
      usuariosCreados: "",
      limite: "",
      contenido: "",
      usuariosPanelDeshab: [],
      ModalRut: false,
      datosFuncionarios: false,
      cargando: false,
      datosF: [],
      vistaAcceso: 1,

      comuna: this.props.comuna,
      usp_id: 0,
      usuariosPanel: [],
      modal_alerta: false,
      agregarUsuarioPanel: false,
      modificarUsuarioPanel: false,
      cliente: store.getState().cliente,
      nombreusp: store.getState().nombreusp,
      usp: store.getState().usp,
      cesfam: store.getState().cesfam,
      herramientas: store.getState().herramientas,
    };

    this.accesosCreados();
    this.initialState = this.state;
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Administrador de accesos<span title='Accesos'></span>";
  };

  accesosCreados = () => {
    const apiUrl = global.config.modulos.clientes + "accesos_creados/" + this.state.cliente;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        limiteUsuariosPanel: res.data["limites"]["cll_usuarios_panel"],
        usuariosPanelCreados: res.data["usuariosPanelCreados"],
      });
    });
  };

  eliminarUsuarioPanelConfirmar(uspId, nombreAcceso) {
    let contenido = [];

    contenido.push(
      <div>
        <h2 style={{ color: "#808080", fontSize: "2.5rem" }}>¡Atención!</h2>
        <br />
        <br />
        <p>Estas deshabilitando un acceso</p>
        <p>Ingresa el motivo por el cual se deshabilitará el acceso</p>
        <br />
        <textarea
          type="text"
          id="motivo_eliminar_acceso"
          name="motivo_eliminar_acceso"
          style={{
            width: "100%",
            height: "20vh",
            padding: "1vw",
            border: "solid 0.2vw rgb(128, 128, 128)",
            borderRadius: "0.4vw",
            background: "transparent",
            fontSize: "1vw",
          }}
          placeholder="Motivo ..."
        ></textarea>
        <br />
        <br />
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <button onClick={this.cerrar} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
            Cancelar
          </button>
          <button onClick={() => this.eliminarUsuarioPanel(uspId, nombreAcceso)} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
            Deshabilitar
          </button>
        </div>
      </div>
    );
    this.setState({
      contenido: contenido,
      modal_alerta: true,
    });
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }
  cerrar = () => {
    this.setState({
      modal_alerta: false,
    });
  };

  componentDidMount() {
    this.fetchUsuariosPanel();
    this.titulo();
  }

  fetchUsuariosPanel = () => {
    /* this.setState({ cargando: true }); */
    if (this.state.herramientas.includes("H63") && this.state.vistaAcceso == 1) {
      /* POR CESFAM */
      var apiUrl = global.config.modulos.usuariospanel + "usp_por_cesfam_tipo2/" + this.state.cesfam;
    } else if (this.state.vistaAcceso == 2 || this.state.herramientas.includes("H62")) {
      this.setState({ vistaAcceso: 2 });
      /* POR COMUNA */
      var apiUrl = global.config.modulos.usuariospanel + "por_comuna/" + this.state.cliente;
    }

    let apiUrl_2 = global.config.modulos.usuariospanel + "limitador_acceso_cliente/";

    try {
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        this.setState({ cargando: false });

        this.setState({
          usuariosPanel: res.data["datos"],
          usuariosPanelDeshab: res.data["usp_deshabilitados"],
        });
      });
    } catch (error) {
      this.setState({ cargando: false });
    }

    axios(apiUrl_2, { headers: authHeader(), params: { comuna: this.state.cliente } }).then((res) => {
      this.setState({
        limite: res.data.limite,
        usuariosCreados: res.data.usuarios_creados,
      });
    });
  };

  agregarUsuarioPanel = (e) => {
    let apiUrl_2 = global.config.modulos.usuariospanel + "limitador_acceso_cliente/";
    axios(apiUrl_2, { headers: authHeader(), params: { comuna: this.state.cliente } }).then((res) => {
      if (res.data.estado) {
        this.setState({ ModalRut: true });
      } else {
        let contenido = [];
        contenido.push(
          <div>
            <h2 style={{ color: "#808080", fontSize: "2.5rem" }}>¡Atención!</h2>
            <br />
            <br />
            <p>Ya has superado el limite de accesos permitidos</p>
            <p>Si deseas tener mas accesos comuniquese con HoraSalud</p>
            <br />
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={this.cerrar} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
                Cerrar
              </button>
            </div>
          </div>
        );
        this.setState({
          contenido: contenido,
          modal_alerta: true,
        });
      }
    });
  };

  ocultarAgregar = () => {
    this.setState({ agregarUsuarioPanel: false });
    this.salir();
    this.fetchUsuariosPanel();
  };

  modificarUsuarioPanel = (id, estado) => {
    if (estado == 1) {
      this.setState({ modificarUsuarioPanel: true, usp_id: id });
    } else {
      let apiUrl = global.config.modulos.usuariospanel + "traer_accion/";

      axios.get(apiUrl, { headers: authHeader(), params: { usuario: id, tipo: 8 } }).then((res) => {
        let mensaje = res.data.mensaje;
        let contenido = [];

        contenido.push(
          <div>
            <h2 style={{ color: "#808080", fontSize: "2.5rem" }}>¡Atención!</h2>
            <br />
            <br />
            <p>{mensaje}</p>
            <p>¿Deseas habilitar nuevamente este acceso?</p>
            <br />
            <br />
            <br />
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <button onClick={this.cerrar} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
                No
              </button>
              <button onClick={() => this.habilitarUsp(id)} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
                Si
              </button>
            </div>
          </div>
        );
        this.setState({
          contenido: contenido,
          modal_alerta: true,
        });
      });
    }
  };

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  ocultarModificar = () => {
    this.setState({ modificarUsuarioPanel: false });
    this.fetchUsuariosPanel();
  };

  eliminarUsuarioPanel(uspId, nombreAcceso) {
    var motivo_eliminar_acceso = document.getElementById("motivo_eliminar_acceso").value;

    if (motivo_eliminar_acceso == "") {
      this.mostrarAlerta("Debe completar el motivo");
    } else {
      this.setState({ cargando: true });
      var data = {
        usp_estado: 0,
      };

      let apiUrl = global.config.modulos.usuariospanel + "por_id/" + uspId;
      let apiUrl2 = global.config.modulos.usuariospanel + "agregar_accion/";

      const options = {
        method: "DELETE",
        data: data,
        headers: authHeader(),
      };

      const datos = {
        accion: "El funcionario " + this.state.nombreusp + " Eliminó el acceso de " + nombreAcceso + ". Por el motivo: " + motivo_eliminar_acceso,
        uspId: this.state.usp,
        usuario: uspId,
        tipo: 8,
      };

      axios(apiUrl, options)
        .then((res) => {
          this.setState({
            usuariosPanel: this.state.usuariosPanel.filter((row) => row["usp_id"] !== uspId),
            cargando: false,
          });
          try {
            axios.post(apiUrl2, datos, { headers: authHeader() }).then((res) => {
              this.setState({
                motivo_eliminar_acceso: "",
                modal_alerta: false,
              });
            });
          } catch (e) {
            this.setState({
              motivo_eliminar_acceso: "",
              modal_alerta: false,
            });
          }
          this.fetchUsuariosPanel();
        })
        .catch((err) => {
          this.setState({
            cargando: false,
          });
        });
    }
  }

  componentDidUpdate() {
    if (this.state.comuna != this.props.comuna) {
      this.setState({
        comuna: this.props.comuna,
      });
      this.fetchUsuariosPanel();
    }
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  salir = () => {
    this.setState({
      ModalRut: false,
      rut: "",
    });
  };

  buscarRut = () => {
    if (this.state.rut != "") {
      const apiUrl = global.config.modulos.usuariospanel + "por_rut/";
      let contenido = [];

      if (chileanRut.validate(this.state.rut)) {
        axios.get(apiUrl, { headers: authHeader(), params: { usprut: this.state.rut } }).then((res) => {
          if (res.data.estado == 0 || res.data.estado == 2) {
            let datos = res.data.datos;
            let uspId = res.data["usp_id"];

            contenido.push(
              <div>
                <h2 style={{ color: "#808080", fontSize: "2.5rem" }}>¡Atención!</h2>
                <br />
                <br />
                <p>Este acceso se encuentra eliminado.</p>
                <p>¿Deseas habilitar nuevamente este acceso?</p>
                <br />
                <br />
                <br />
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <button onClick={this.cerrar} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
                    No
                  </button>
                  <button onClick={() => this.habilitarUsp(uspId)} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
                    Si
                  </button>
                </div>
              </div>
            );
            this.setState({
              contenido: contenido,
              modal_alerta: true,
              datosFuncionarios: true,
              datosF: datos,
            });
          } else if (res.data.estado == 1) {
            this.mostrarAlerta(res.data.mensaje);
          } else if (res.data.estado == 3) {
            this.setState({ agregarUsuarioPanel: true });
          }
        });
      } else {
        this.mostrarAlerta("Ingrese un rut válido");
      }
    }
  };

  habilitarUsp = (uspId) => {
    this.cerrar();
    this.salir();
    let apiUrl_2 = global.config.modulos.usuariospanel + "limitador_acceso_cliente/";

    axios(apiUrl_2, { headers: authHeader(), params: { comuna: this.state.cliente } }).then((res) => {
      let limite = res.data.limite;
      let usuariosCreados = res.data.usuarios_creados;
      let contenido = [];

      if (usuariosCreados >= limite) {
        contenido.push(
          <div>
            <h2 style={{ color: "#808080", fontSize: "2.5rem" }}>¡Atención!</h2>
            <br />
            <br />
            <p>Ya has superado el limite de accesos permitidos</p>
            <p>Si deseas tener mas accesos comuniquese con HoraSalud</p>
            <br />
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={this.cerrar} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
                Cerrar
              </button>
            </div>
          </div>
        );
        this.setState({
          contenido: contenido,
          modal_alerta: true,
        });
      } else {
        this.setState({ modificarUsuarioPanel: true, usp_id: uspId });
      }
    });
  };

  descargarExcel = async () => {
    this.setState({ loading: true });
    if (this.state.herramientas.includes("H62")) {
      /* COMUNA */
      let contenido = [];
      contenido.push(
        <div>
          <h2 style={{ color: "#808080", fontSize: "2.5rem" }}>Descargar accesos</h2>
          <br />
          <br />
          <GridItem xs={12} sm={12} md={12} style={{ display: "flex" }}>
            <button onClick={this.descargaComuna} className={SolicitudCSS.btnAcciones} style={{ width: "20vw" }}>
              Accesos Comuna
            </button>
            <button onClick={this.descargaCesfam} className={SolicitudCSS.btnAcciones} style={{ width: "20vw", margin: 0 }}>
              Accesos Cesfam
            </button>
          </GridItem>
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.cerrar} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
              Cerrar
            </button>
          </div>
        </div>
      );
      this.setState({
        contenido: contenido,
        modal_alerta: true,
      });
    } else if (this.state.herramientas.includes("H63")) {
      /* CESFAM */
      NotificationManager.warning("Se inicio proceso de descarga de excel esto puede tardar unos segundos", "Descargando....", 3000);
      await axios.get((window.location.href = global.config.modulos.usuariospanel + "descargar_excel_usuarios/?cesfam=" + store.getState().cesfam + "&tipo=" + 2 + "&comuna=" + store.getState().cliente), { headers: authHeader() });
    }
  };

  descargaComuna = async () => {
    NotificationManager.warning("Se inicio proceso de descarga de excel esto puede tardar unos segundos", "Descargando....", 3000);
    await axios.get((window.location.href = global.config.modulos.usuariospanel + "descargar_excel_usuarios/?comuna=" + store.getState().cliente + "&tipo=" + 1), { headers: authHeader() }).then(
      this.setState({
        modal_alerta: false,
      })
    );
  };
  descargaCesfam = async () => {
    NotificationManager.warning("Se inicio proceso de descarga de excel esto puede tardar unos segundos", "Descargando....", 3000);
    await axios.get((window.location.href = global.config.modulos.usuariospanel + "descargar_excel_usuarios/?cesfam=" + store.getState().cesfam + "&tipo=" + 2 + "&comuna=" + store.getState().cliente), { headers: authHeader() }).then(
      this.setState({
        modal_alerta: false,
      })
    );
  };

  mostrarAccesosDeshabilitados = () => {
    let contenido = [];
    contenido.push(
      <div>
        <h2 style={{ color: "#808080", fontSize: "2.5rem" }}>
          {this.state.herramientas.includes("H62") && this.state.vistaAcceso == 1 ? "Accesos deshabilitados del Cesfam" : null}
          {this.state.herramientas.includes("H63") && this.state.vistaAcceso == 2 ? "Accesos deshabilitados de la Comuna" : null}
        </h2>
        <br />
        <br />
        <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ display: "flex", width: "60vw" }}>
          <MaterialTable
            style={{ width: "100% ", fontSize: "14px" }}
            title=""
            columns={[
              {
                title: "Acceso",
                field: "nombrecompleto",
              },
              { title: "RUT ", field: "usp_rut" },
              { title: "Cesfam ", field: "cesfam" },
              { title: "Última conexión", field: "inicio_sesion" },
              { title: "Duración última conexión", field: "duracion" },
            ]}
            data={this.state.usuariosPanelDeshab}
            options={{
              actionsColumnIndex: -1,
              search: true,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Usuario aun no tiene observaciones",
              },
            }}
          />
        </GridItem>
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={this.cerrar} className={SolicitudCSS.btnAcciones} style={{ width: "150px" }}>
            Cerrar
          </button>
        </div>
      </div>
    );
    this.setState({
      contenido: contenido,
      modal_alerta: true,
    });
  };

  cambiarVista = (vista) => {
    this.setState(
      {
        vistaAcceso: vista,
      },
      () => {
        this.fetchUsuariosPanel();
      }
    );
  };
  render() {
    return (
      <>
        <div className="middle-area">
          <div className="middle-content">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginBottom: "20px",
                  }}
                >
                  <button className={FuncionarioCSS.boton} style={{ height: "3vw", width: "12%", fontSize: "1vw" }} onClick={(event) => this.agregarUsuarioPanel()}>
                    Crear acceso
                  </button>
                  <button onClick={this.descargarExcel} className={FuncionarioCSS.descargarExcelAcceso}>
                    Descargar excel de accesos
                  </button>
                  {this.state.herramientas.includes("H62") && (
                    <button style={this.state.vistaAcceso == 2 ? { background: "#d28507" } : null} onClick={() => this.state.vistaAcceso == 1 && this.cambiarVista(2)} className={FuncionarioCSS.descargarExcelAcceso}>
                      Accesos por comuna
                    </button>
                  )}

                  {this.state.herramientas.includes("H63") && (
                    <button onClick={() => this.state.vistaAcceso == 2 && this.cambiarVista(1)} style={this.state.vistaAcceso == 1 ? { background: "#d28507" } : null} className={FuncionarioCSS.descargarExcelAcceso}>
                      Accesos por cesfam
                    </button>
                  )}
                </div>

                <div className="divBordeTablaAccesos">
                  <div style={{ display: "flex", position: "relative" }}>
                    <p className={FuncionarioCSS.limites_autorizados} style={{ background: "#0273bf" }}>
                      {this.state.limite} Accesos autorizados
                    </p>
                    <p className={FuncionarioCSS.limites_autorizados} style={{ background: "#eb9e28" }}>
                      {this.state.usuariosCreados} Accesos en uso
                    </p>
                    <p className={FuncionarioCSS.limites_autorizados} style={{ background: "#109f4f" }}>
                      {this.state.limite - this.state.usuariosCreados} Accesos disponibles
                    </p>
                    <p className={FuncionarioCSS.limites_autorizados} style={{ background: "#808080", cursor: "pointer" }} onClick={this.mostrarAccesosDeshabilitados}>
                      {this.state.usuariosPanelDeshab.length} Accesos deshabilitados
                    </p>
                  </div>

                  <MaterialTable
                    title=""
                    columns={[
                      {
                        title: "Nombre",
                        field: "nombrecompleto",
                      },

                      {
                        title: "Rut",
                        field: "usp_rut",
                      },
                      {
                        title: "Cesfam",
                        field: "cesfam",
                      },
                      {
                        title: "Rol",
                        field: "rolnombre",
                      },
                      {
                        title: "Estado",
                        render: (rowData) => (
                          <React.Fragment>
                            <div>
                              <span style={rowData.usp_estado == 0 || rowData.usp_estado == 2 ? { fontWeight: "bold", color: "#939698" } : { fontWeight: "none", color: "black" }}>{rowData.estado_texto}</span>
                            </div>
                          </React.Fragment>
                        ),
                      },

                      {
                        title: "Acciones",
                        render: (rowData) => (
                          <React.Fragment>
                            <div>
                              <Tooltip title="Editar">
                                <img src={Editar} className={FuncionarioCSS.icono} style={{ marginRight: "10px" }} onClick={() => this.modificarUsuarioPanel(rowData["usp_id"], rowData["usp_estado"])} />
                              </Tooltip>
                              {rowData.usp_estado == 1 ? (
                                <Tooltip title="Deshabilitar">
                                  <img src={Eliminar} className={FuncionarioCSS.icono} onClick={() => this.eliminarUsuarioPanelConfirmar(rowData["usp_id"], rowData["nombrecompleto"])} />
                                </Tooltip>
                              ) : null}
                            </div>
                          </React.Fragment>
                        ),
                      },
                    ]}
                    data={this.state.usuariosPanel}
                    options={{
                      actionsColumnIndex: -1,
                      search: true,
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No hay accesos creados",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                  {this.state.agregarUsuarioPanel ? <AgregarUsuarioPanel rut={this.state.rut} ocultar={this.ocultarAgregar} datos={this.state.datosFuncionarios} datosF={this.state.datosF}></AgregarUsuarioPanel> : null}
                  {this.state.modificarUsuarioPanel ? <ModificarUsuarioPanel usuario_panel={this.state.usp_id} actualizar={this.fetchUsuariosPanel} ocultar={this.ocultarModificar}></ModificarUsuarioPanel> : null}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>

        <Dialog open={this.state.ModalRut} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <GridItem xs={12} sm={12} md={12}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  padding: "0px",
                  marginBottom: "40px",
                }}
              >
                <h1 style={{ color: "#818181" }}>Ingresar Rut</h1>
                <br />
                <br />
                <input type="text" name="rut" style={{ textAlign: "center" }} maxLength={12} className={FuncionarioCSS.inputText} value={this.state.rut} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" />
              </GridItem>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button onClick={this.buscarRut} style={{ width: "150px", margin: "0" }} className={FuncionarioCSS.boton}>
                  Buscar
                </button>
              </div>
            </GridItem>
          </DialogContent>
        </Dialog>

        {this.state.modal_alerta ? <ModalAlerta modal={this.state.modal_alerta} contenido={this.state.contenido} cerrarModal={this.cerrar} /> : null}
        {this.state.cargando ? <Cargando color="#fff" type="spinningBubbles" /> : null}
      </>
    );
  }
}
