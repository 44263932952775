import React, { Component, useState } from "react";
// core components

import { confirmAlert } from "react-confirm-alert";
import { store } from "store/index";

import ConfigTv from "./admin/ConfigTv.js";
import Ticket from "./admin/Ticket.js";
import Funcionarios from "./admin/Funcionarios.js";
import AccionesFuncionario from "./admin/AccionesFuncionario.js";
import Apariencia from "./admin/Apariencia.js";
import Horarios from "./admin/Horarios.js";
import Opciones from "./admin/Opciones.js";
import Opciones_tipo2 from "./admin/Opciones_tipo2.js";
import Usuarios from "./usuarios/ListaUsuarios.js";
import Datos from "./graficosTotem/MenuTotem.js";
import DatosTicket from "./admin/DatosTicket";
import Prioridad from "./admin/Prioridad.js";
import AccesoTotem from "./admin/AccesoTotem";
import CargarVideos from "./admin/CargarVideos";

// IMAGENES
import totem from "assets/img/admin_totem/nuevos_logo/totem.svg";
import prioridad from "assets/img/admin_totem/nuevos_logo/prioridad.svg";
import ticket from "assets/img/admin_totem/nuevos_logo/ticket.svg";
import tv from "assets/img/admin_totem/nuevos_logo/tv.svg";
import acceso from "assets/img/admin_totem/nuevos_logo/accesos.svg";
import paciente from "assets/img/admin_totem/nuevos_logo/pacientes.svg";
import datos from "assets/img/admin_totem/nuevos_logo/datos.svg";
import tick_paciente from "assets/img/admin_totem/nuevos_logo/tickets_paciente.svg";
import acceso_totem from "assets/img/admin_totem/nuevos_logo/acceso_totem.svg";

// CSS
import crearTotem from "assets/css/crearTotem.module.css";
import "assets/css/administrador.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Administrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.titulo();
    this.configurarTotem();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Administrar Totems <span title='Totems creados'></span>";
  };

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  configurarTotem = () => {
    var confTotemApa = false;
    var confTotemOpc = true;
    if (this.state.herramientas.includes("H54")) {
      confTotemApa = true;
      confTotemOpc = false;
    }

    this.setState({
      confTotem: true,
      confTotemApa: confTotemApa,
      confTotemOpc: confTotemOpc,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      configurarTv: false,
      datosTicket: false,
    });
  };

  configurarTotemApa = () => {
    this.setState({
      confTotem: true,
      confTotemApa: true,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      configurarTv: false,
      datosTicket: false,
    });
  };

  configurarTotemOpc = () => {
    this.setState({
      confTotem: true,
      confTotemApa: false,
      confTotemOpc: true,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      configurarTv: false,
      datosTicket: false,
    });
  };

  configurarTicket = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: true,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      datosTicket: false,
    });
  };

  configurarTv = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: true,
      configurarTvBtn1: true,
      configurarTvBtn2: false,
      confTv: true,
      datosTicket: false,
    });
  };

  configurarPrioridad = () => {
    this.setState({
      confPrioridad: true,
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      datosTicket: false,
    });
  };

  configurarPeriferico = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: true,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      configurarTv: false,
      datosTicket: false,
    });
  };

  configurarOpcionesPanel = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: true,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      datosTicket: false,
    });
  };

  configuracionHorarios = () => {
    this.setState({
      confTotem: true,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: true,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: false,
      datosTicket: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
    });
  };

  cargarVideos = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: true,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      configurarTv: true,
      configurarTvBtn1: false,
      configurarTvBtn2: true,
      cargarVideos: true,
      datosTicket: false,
    });
  };

  usuarios = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: true,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      configurarTv: false,
      datosTicket: false,
    });
  };

  datos = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: true,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      datosTicket: false,
    });
  };

  accesoTotem = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: true,
      configurarTv: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      cargarVideos: false,
      datosTicket: false,
    });
  };

  configTv = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: true, // cuadro con opciones completo
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTv: true, // imagen principal
      configurarTvBtn1: true,
      datosTicket: false,
    });
  };

  tickets_usuario = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      datosTicket: true,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: false,
      usuarios: false,
      datos: false,
      confPrioridad: false,
      accesoTotem: false,
      cargarVideos: false,
      configurarTvBtn1: false,
      configurarTvBtn2: false,
      configurarTv: false,
    });
  };

  ocultar_tickets_usuario = () => {
    this.setState({ datosTicket: false });
  };

  render() {
    return (
      <div className="middle-area middleOverride">
        <div className="middle-contentAdmin">
          <div style={{ width: "100%", float: "left", marginBottom: "2%" }}>
            {this.state.herramientas.includes("H36") && (
              <img
                id="totem"
                src={totem}
                className={crearTotem.btnAdministrar}
                style={this.state.confTotem ? { width: "9vh" } : {}}
                onClick={() => {
                  this.configurarTotem();
                }}></img>
            )}

            {this.state.herramientas.includes("H37") && (
              <img
                id="prioridad"
                src={prioridad}
                className={crearTotem.btnAdministrar}
                style={this.state.confPrioridad ? { width: "9vh" } : {}}
                onClick={() => {
                  this.configurarPrioridad();
                }}></img>
            )}

            {this.state.herramientas.includes("H38") && (
              <img
                id="ticket"
                src={ticket}
                className={crearTotem.btnAdministrar}
                style={this.state.confTicket ? { width: "9vh" } : {}}
                onClick={() => {
                  this.configurarTicket();
                }}></img>
            )}

            {this.state.herramientas.includes("H39") && (
              <img
                id="tv"
                src={tv}
                className={crearTotem.btnAdministrar}
                style={this.state.configurarTv ? { width: "9vh" } : {}}
                onClick={() => {
                  this.configurarTv();
                }}></img>
            )}

            {this.state.herramientas.includes("H40") && (
              <img
                id="acceso"
                src={acceso}
                className={crearTotem.btnAdministrar}
                style={this.state.confPeri ? { width: "9vh" } : {}}
                onClick={() => {
                  this.configurarPeriferico();
                }}></img>
            )}
            {/* <a
              className={this.state.confOpcionesPanel ? "btnAcciones botonAccionActivo" : "btnAcciones"}
              onClick={() => {
                this.configurarOpcionesPanel();
              }}>
              {"Opciones"}
            </a> */}

            {this.state.herramientas.includes("H52") && (
              <img
                id="paciente"
                src={paciente}
                className={crearTotem.btnAdministrar}
                style={this.state.usuarios ? { width: "9vh" } : {}}
                onClick={() => {
                  this.usuarios();
                }}></img>
            )}

            {this.state.herramientas.includes("H41") && (
              <img
                id="datos"
                src={datos}
                className={crearTotem.btnAdministrar}
                style={this.state.datos ? { width: "9vh" } : {}}
                onClick={() => {
                  this.datos();
                }}></img>
            )}

            <img
              id="tick_paciente"
              src={tick_paciente}
              className={crearTotem.btnAdministrar}
              style={this.state.datosTicket ? { width: "9vh" } : {}}
              onClick={() => {
                this.tickets_usuario();
              }}></img>

            {this.state.herramientas.includes("H53") && (
              <img
                id="acceso_totem"
                src={acceso_totem}
                className={crearTotem.btnAdministrar}
                style={this.state.accesoTotem ? { width: "9vh" } : {}}
                onClick={() => {
                  this.accesoTotem();
                }}></img>
            )}
          </div>

          <br></br>
          <br></br>
          <br></br>

          {this.state.confTotem && this.state.herramientas.includes("H36") && (
            <div style={{ width: "100%", float: "left", marginBottom: "1%" }}>
              {this.state.confTotem && this.state.herramientas.includes("H54") && (
                <a
                  className={this.state.confTotemApa ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                  onClick={() => {
                    this.configurarTotemApa();
                  }}>
                  Configuración
                  <br />
                  apariencia
                </a>
              )}

              <a
                className={this.state.confTotemOpc ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                onClick={() => {
                  this.configurarTotemOpc();
                }}>
                Configuración
                <br />
                opciones
              </a>

              <a
                className={this.state.confTotemHor ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                onClick={() => {
                  this.configuracionHorarios();
                }}>
                Configuración
                <br />
                horarios
              </a>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          )}

          {this.state.confTv ? (
            <div style={{ width: "100%", float: "left" }}>
              <a
                className={this.state.configurarTvBtn1 ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                style={{ width: "12%", height: "45px", display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={() => {
                  this.configTv();
                }}>
                Configuración Tv
              </a>
              <a
                className={this.state.cargarVideos ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                style={{ width: "12%", height: "45px", display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={() => {
                  this.cargarVideos();
                }}>
                Cargar videos
              </a>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          ) : null}
          <br></br>
          <br></br>

          {this.state.configurarTvBtn1 ? <ConfigTv></ConfigTv> : <></>}

          {this.state.confTicket ? <Ticket></Ticket> : <></>}
          {this.state.confPeri ? <Funcionarios></Funcionarios> : <></>}
          {this.state.confOpcionesPanel ? <AccionesFuncionario></AccionesFuncionario> : <></>}
          {this.state.confTotemApa ? <Apariencia></Apariencia> : <></>}
          {this.state.confTotemOpc ? <Opciones_tipo2></Opciones_tipo2> : <></>}
          {this.state.confTotemHor ? <Horarios></Horarios> : <></>}
          {this.state.cargarVideos ? <CargarVideos></CargarVideos> : <></>}
          {this.state.usuarios ? <Usuarios></Usuarios> : <></>}
          {this.state.datos ? <Datos></Datos> : <></>}
          {this.state.datosTicket ? <DatosTicket></DatosTicket> : <></>}
          {this.state.confPrioridad ? <Prioridad></Prioridad> : <></>}
          {this.state.accesoTotem ? <AccesoTotem></AccesoTotem> : <></>}
        </div>
      </div>
    );
  }
}
