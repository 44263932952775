import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Table from "@material-ui/core/Table";
import moment from "moment";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import iconoAgendar from "../../assets/img/usuarios/icono_agendar.svg";
import { store } from "./../../store/index";
import { handleError } from "./../../components/Helpers/helpers.js";
import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
// estilos
import "../../assets/css/usuarios.css";

export default class AgendarHora extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalRepeticiones: 0,
      usuId: props.usuario,
      reservador: props.reservador | null,
      espPrimaria: 0,
      canal: 5,
      sigla: "",
      idEspecialidad: null,
      espSecundaria: 0,
      uspId: store.getState().usp,
      datosEspPrimarias: [],
      datosEspSecundarias: [],
      datosEspecialidad: [],
      horarios: [],
      ciclo: null,
      open: true,
      cesfam: store.getState().cesfam,
      familiar: props.familiar || false,
      filtros: true,
      quitar_reservas_nsp: null,
      quitar_prioridad: null,
      quitar_usuario_bloqueado: null,
      quitar_hora_emparejada: null,
      herramientas: store.getState().herramientas,

      contacto: null,
      otroTelefono: "",
      telefono: null,
      telefonos: [],
      canalesTeleconsulta: [],

      teleconsulta: false,
      existeContactoTelefono: false,
      existeContactoTelefonoOtro: false,
    };
  }

  componentDidMount() {
    this.fetchBuscarEspecialidades(this.state.usuId, this.state.canal, null);
    this.canalesTeleconsulta();
  }

  cambiarFiltro = (filtro) => {
    if (filtro) {
      this.setState({
        filtros: filtro,
        quitar_reservas_nsp: null,
        quitar_prioridad: null,
        quitar_hora_emparejada: null,
        quitar_usuario_bloqueado: null,
      });
    } else {
      this.setState({
        filtros: filtro,
        quitar_reservas_nsp: true,
        quitar_prioridad: true,
        quitar_hora_emparejada: null,
        quitar_usuario_bloqueado: true,
      });
    }
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  fetchBuscarEspecialidades = async (usuid, canal, sigla) => {
    axios
      .get(global.config.modulos.especialidades + "buscar/", {
        headers: authHeader(),
        params: {
          usuid: usuid,
          canal: canal,
          sigla: sigla,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          if (sigla) {
            this.setState({
              datosEspSecundarias: res.data.datos,
            });
          } else {
            this.setState({
              datosEspPrimarias: res.data.datos,
              espSecundaria: 0,
            });
          }
        } else {
          NotificationManager.error(res.data.mensaje);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  mensajesEspecialidad = async (sigla, canal, cesfam) => {
    var res = await axios(global.config.modulos.especialidades + "mensajes/", {
      headers: authHeader(),
      params: { sigla: sigla, cesfam: cesfam, canal: canal },
    });
    return res.data;
  };

  cuestionarioEspecialidad = async (idEspecialidad, cesfam) => {
    var res = await axios(global.config.modulos.cuestionarios, {
      headers: authHeader(),
      params: { cesfam: cesfam, especialidad: idEspecialidad },
    });
    return res.data;
  };

  preguntaCuestionario = async (pregunta) => {
    var res = await axios(global.config.modulos.cuestionarios + "preguntas/", {
      headers: authHeader(),
      params: { pregunta: pregunta },
    });
    return res.data;
  };

  guardarRespuestaCuestionario = async (pregunta, codigo, cuestionario, respuesta, usuario, canal) => {
    var res = await axios.post(
      global.config.modulos.cuestionarios + "respuestas/",
      {
        pregunta: pregunta,
        codigo: codigo,
        cuestionario: cuestionario,
        respuesta: respuesta,
        usuario: usuario,
        canal: canal,
      },
      {
        headers: authHeader(),
      }
    );
    return res.data;
  };

  muestraPregunta = async (pregunta, codigo) => {
    let respuestas = await this.preguntaCuestionario(pregunta);

    let botones = [];
    respuestas.respuestas.map((res) => {
      botones.push({
        label: res.crp_respuesta,
        className: "btnCancelar",
        onClick: () => {
          this.guardarRespuestaCuestionario(respuestas.cup_id, codigo, respuestas.cup_cuestionario_id, res.crp_id, this.state.usuId, this.state.canal);
          if (res.crp_redireccion_pregunta) {
            this.muestraPregunta(res.crp_redireccion_pregunta, codigo);
          } else if (res.crp_redireccion_especialidad) {
            axios(global.config.modulos.especialidades, {
              headers: authHeader(),
              params: { esp_id: res.crp_redireccion_especialidad },
            }).then((res) => {
              if (res.data.estado) {
                this.setState(
                  {
                    sigla: res.data.datos.esp_sigla,
                  },
                  () => {
                    this.buscarHoras(this.state.cesfam);
                  }
                );
              }
            });
          } else if (res.crp_redireccion_termino) {
            return false;
          }
        },
      });
    });

    confirmAlert({
      title: "",
      message: respuestas.cup_pregunta,
      buttons: botones,
    });
  };

  buscarHorarios = async () => {
    let sigla = this.state.sigla;
    let idEspecialidad = this.state.idEspecialidad;
    let canal = this.state.canal;
    let cesfam = this.state.cesfam;

    let cuestionarios = await this.cuestionarioEspecialidad(idEspecialidad, cesfam);
    if (cuestionarios.estado) {
      this.muestraPregunta(cuestionarios.datos[0].pregunta.cup_id, cuestionarios.codigo);
      return false;
    }

    let mensajes = await this.mensajesEspecialidad(sigla, canal, cesfam);
    if (mensajes.estado) {
      let total = mensajes.datos.length - 1;
      let actual = this.state.totalRepeticiones;
      confirmAlert({
        title: "",
        message: mensajes.datos[actual].mensaje,
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: () => {
              if (mensajes.datos[actual].mee_tipo === 2) {
                this.setState({ totalRepeticiones: 0 });
                return false;
              }
              if (total > actual) {
                this.setState(
                  {
                    totalRepeticiones: actual + 1,
                  },
                  () => {
                    this.buscarHorarios();
                  }
                );
              } else {
                this.setState({ totalRepeticiones: 0 });
                this.buscarHoras(this.state.cesfam);
              }
            },
          },
        ],
      });
    } else {
      this.buscarHoras(this.state.cesfam);
    }
  };

  buscarHoras = async (cesfam) => {
    let sigla = this.state.sigla;
    let canal = this.state.canal;

    axios
      .get(global.config.modulos.reservas + "horariosDisponibles/", {
        headers: authHeader(),
        params: {
          idUsuario: this.state.usuId,
          sigla: sigla,
          canal: canal,
          cesfam: cesfam,
          nHoras: 4,
          diffHoras: 3,
          quitar_reservas_nsp: this.state.quitar_reservas_nsp,
          quitar_prioridad: this.state.quitar_prioridad,
          quitar_usuario_bloqueado: this.state.quitar_usuario_bloqueado,
          quitar_hora_emparejada: this.state.quitar_hora_emparejada,
          deshabilitar_hora_en_gestion: true,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          res.data.horas.map((hor) => {
            hor["hor_atencion"] = hor["hor_atencion"].replace("T", " ");
          });
          this.setState({
            horarios: res.data.horas,
            ciclo: res.data.ciclo,
          });
          this.agregarHorariosProximos();
        } else {
          if (res.data.emparejada) {
            confirmAlert({
              title: "",
              message: "Este paciente ya posee una hora de " + res.data.nombre_especialidad + ", Segur@ que desea reservar esta hora?",
              buttons: [
                {
                  label: "Cancelar",
                  className: "btnCancelar",
                  onClick: () => {
                    this.setState({
                      horarios: [],
                    });
                  },
                },
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                  onClick: () => {
                    this.setState(
                      {
                        quitar_hora_emparejada: true,
                      },
                      () => {
                        this.buscarHorarios();
                      }
                    );
                  },
                },
              ],
            });
          } else {
            if (res.data.tipo == "horasOtroCentro") {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="agendarOtroCesfam">
                      <GridContainer className="agendar-container">
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                          <h2>{res.data.mensaje}</h2>
                          <br></br>
                          <h2>{res.data.mensaje_buscar}</h2>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}></GridItem>
                        {res.data.cesfams.map((row, index) => (
                          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: 10 }}>
                            <button
                              className="botonGeneral"
                              onClick={() => {
                                onClose();
                                this.buscarHoras(row.ces_id);
                              }}
                            >
                              {row.nombre_centro}
                            </button>
                          </GridItem>
                        ))}
                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
                          <Button
                            onClick={() => {
                              onClose();
                            }}
                            className="botonDialogCancel fondoRojo"
                          >
                            Cancelar
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  );
                },
              });
            } else {
              this.setState({
                horarios: [],
                quitar_hora_emparejada: null,
              });
              NotificationManager.error(res.data.mensaje);
            }
          }
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  agregarHorariosProximos = () => {
    let fecha_termino = moment().add(3, "hours").format("YYYY-MM-DD HH:mm:ss");

    axios
      .get(global.config.modulos.reservas + "horariosDisponibles/", {
        headers: authHeader(),
        params: {
          idUsuario: this.state.usuId,
          sigla: this.state.sigla,
          canal: this.state.canal,
          cesfam: this.state.cesfam,
          nHoras: 4,
          diffHoras: 0,
          quitar_reservas_nsp: this.state.quitar_reservas_nsp,
          quitar_prioridad: this.state.quitar_prioridad,
          quitar_usuario_bloqueado: this.state.quitar_usuario_bloqueado,
          quitar_hora_emparejada: this.state.quitar_hora_emparejada,
          fecha_termino: fecha_termino,
          quitar_verificacion_en_gestion: true,
          deshabilitar_hora_en_gestion: true,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          // append data to this.state.horarios
          const arregloHorario = [...this.state.horarios, ...res.data.horas];
          const nuevoArreglo = arregloHorario.sort((a, b) => {
            if (a.hor_atencion < b.hor_atencion) {
              return -1;
            }
            if (a.hor_atencion > b.hor_atencion) {
              return 1;
            }
            return 0;
          });

          this.setState({
            horarios: nuevoArreglo,
          });
        }
      });
  };

  valorEspPrimaria = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        datosEspSecundarias: [],
        espSecundaria: 0,
        horarios: [],
      },
      () => {
        this.traerContacto();
      }
    );
    this.state.datosEspPrimarias.map((esp) => {
      if (esp.esr_especialidad == e.target.value) {
        if (esp.esr_entrega_horas == 0) {
          this.fetchBuscarEspecialidades(this.state.usuId, this.state.canal, esp.esp_sigla);
          this.setState({
            sigla: "",
          });
        } else {
          this.state.sigla = esp.esp_sigla;
        }
      }
    });
  };

  valorEspSecundaria = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        horarios: [],
      },
      () => {
        this.traerContacto();
      }
    );
    this.state.datosEspSecundarias.map((esp) => {
      if (esp.esr_especialidad == e.target.value) {
        this.state.sigla = esp.esp_sigla;
        this.state.idEspecialidad = esp.esr_especialidad;
      }
    });
  };

  reservarHora = (horario, cesfam) => {
    if (this.validaciones()) {
      axios
        .post(
          global.config.modulos.reservas + "guardar_hora/",
          {
            horario: horario,
            usuario: this.state.usuId,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          if (res.data.estado) {
            confirmAlert({
              title: "",
              message: "La hora fue guardada por 3 min, segur@ que desea reservar esta hora?",
              buttons: [
                {
                  label: "Cancelar",
                  className: "btnCancelar",
                  onClick: () => null,
                },
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                  onClick: () => {
                    let telefono = this.state.telefono;
                    if (telefono == "otro") {
                      this.agregarTelefono(this.state.otroTelefono, this.state.usuId, this.state.uspId);
                      telefono = this.state.otroTelefono;
                    }

                    axios
                      .post(
                        global.config.modulos.agendamientos + "agendar_horario/",
                        {
                          horario: horario,
                          cesfam: cesfam,
                          usp_id: this.state.uspId,
                          canal: this.state.canal,
                          usu_id: this.state.usuId,
                          ciclo: this.state.ciclo,
                          rut_usuario: "",
                          grupo_familiar: this.state.reservador,
                          tipo_contacto: this.state.contacto,
                          telefono_contacto: telefono,
                        },
                        {
                          headers: authHeader(),
                        }
                      )
                      .then((res) => {
                        if (res.data.estado) {
                          NotificationManager.success(res.data.mensaje);
                          this.salir();
                        } else {
                          NotificationManager.error(res.data.mensaje);
                          this.salir();
                        }
                      })
                      .catch((err) => {
                        handleError(err);
                      });
                  },
                },
              ],
            });
          } else {
            NotificationManager.error(res.data.mensaje);
          }
        });
    }
  };

  validaciones() {
    const { teleconsulta, contacto, telefono, otroTelefono } = this.state;
    let formIsValid = true;

    if (teleconsulta && contacto == "3" && telefono == 0) {
      formIsValid = false;
      NotificationManager.error("Selecciona un teléfono.");
    } else if (teleconsulta && telefono == "otro" && !otroTelefono) {
      formIsValid = false;
      NotificationManager.error("Escribe el teléfono de contacto.");
    } else if (teleconsulta && telefono == "otro" && otroTelefono.length < 9) {
      formIsValid = false;
      NotificationManager.error("Verifica el largo del teléfono de contacto, deben ser 9 dígitos.");
    }

    return formIsValid;
  }

  traerContacto = () => {
    var especialidad = "";
    if (this.state.espPrimaria != 0 && this.state.espSecundaria == 0) {
      var e = document.getElementById("espPrimaria");
      especialidad = e.textContent;
    } else if (this.state.espPrimaria != 0 && this.state.espSecundaria != 0) {
      var e = document.getElementById("espSecundaria");
      especialidad = e.textContent;
    }

    if (especialidad.includes("Teleconsulta")) {
      this.setState({
        teleconsulta: true,
        contacto: 1,
        existeContactoTelefono: false,
        existeContactoTelefonoOtro: false,
        telefono: null,
        telefonos: [],
        otroTelefono: "",
      });
    } else {
      this.setState({
        teleconsulta: false,
        contacto: null,
        telefono: null,
        telefonos: [],
        otroTelefono: "",
      });
    }
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    if (name == "contacto") {
      if (value == 3) {
        this.setState({ existeContactoTelefono: true });
        this.traerTelefonos();
      } else {
        this.setState({
          existeContactoTelefono: false,
          telefono: null,
          telefonos: [],
          otroTelefono: "",
          existeContactoTelefonoOtro: false,
        });
      }
    }

    if (name == "telefono") {
      if (value == "otro") {
        this.setState({ existeContactoTelefonoOtro: true });
      } else {
        this.setState({ existeContactoTelefonoOtro: false });
      }
    }

    this.setState({ [name]: value });
  };

  traerTelefonos = () => {
    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        telefonos: res.data.datos,
        telefono: 0,
      });
    });
  };

  agregarTelefono = (telefono, usuId, uspId) => {
    axios
      .post(
        global.config.modulos.telefonos + "agregar_telefono/",
        {
          telefono: telefono,
          usu_id: usuId,
          tipo: 2,
          canal: 5,
          usp_id: uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  canalesTeleconsulta = async () => {
    axios
      .get(global.config.modulos.videollamada + "canales_teleconsulta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        this.setState({
          canalesTeleconsulta: data,
        });
      })
      .catch((err) => {});
  };

  render() {
    const { filtros } = this.state;
    return (
      <GridContainer className="agendar-container">
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer className="agendar-container">
            {this.state.herramientas.includes("H12") ? (
              <GridItem id="modalAgendarEspecialidades" xs={12} style={{ marginTop: 30, float: "left" }}>
                {filtros ? (
                  <Button onClick={() => this.cambiarFiltro(false)} style={{ color: "#fff" }} className=" fondoVerde">
                    Filtros Habilitados
                  </Button>
                ) : (
                  <Button onClick={() => this.cambiarFiltro(true)} style={{ color: "#fff", backgroundColor: "#d34a4a" }} className=" ">
                    Filtros Deshabilitados
                  </Button>
                )}
              </GridItem>
            ) : null}
            <GridItem id="modalAgendarEspecialidades" xs={5} sm={5} md={5} style={{ marginTop: 30 }}>
              <InputLabel id="label-habilitado">Especialidades</InputLabel>
              <Select style={{ with: 300 }} labelId="label-habilitado" id="espPrimaria" name="espPrimaria" value={this.state.espPrimaria} onChange={this.valorEspPrimaria} fullWidth>
                <MenuItem value={0}>Seleccione especialidad</MenuItem>
                {this.state.datosEspPrimarias.map((esp, index) => (
                  <MenuItem value={esp.esr_especialidad} key={index}>
                    {esp.esp_nombre}
                  </MenuItem>
                ))}
              </Select>
            </GridItem>
            {this.state.datosEspSecundarias.length > 0 ? (
              <GridItem id="modalAgendarAtenciones" xs={5} sm={5} md={5} style={{ marginTop: 30 }}>
                <InputLabel id="label-habilitado">Tipos de atención</InputLabel>
                <Select labelId="label-habilitado" id="espSecundaria" name="espSecundaria" value={this.state.espSecundaria} onChange={this.valorEspSecundaria} fullWidth>
                  <MenuItem value={0}>Seleccione tipo de atención</MenuItem>
                  {this.state.datosEspSecundarias.map((esp, index) => (
                    <MenuItem value={esp.esr_especialidad} key={index}>
                      {esp.esp_nombre}
                    </MenuItem>
                  ))}
                </Select>
              </GridItem>
            ) : null}
            <GridItem id="modalAgendarBuscar" xs={2} sm={2} md={2} style={{ marginTop: 40 }}>
              <Button onClick={this.buscarHorarios} className="botonDialog fondoNaranjo">
                Buscar
              </Button>
            </GridItem>
          </GridContainer>

          {this.state.teleconsulta ? (
            <>
              <br></br>
              <InputLabel>Contacto</InputLabel>
              <Select style={{ width: 200 }} labelId="label-habilitado" id="contacto" name="contacto" value={this.state.contacto} onChange={this.cambiarValores}>
                {this.state.canalesTeleconsulta.map((res, index) => {
                  return (
                    <MenuItem value={res.cta_id} key={index}>
                      {res.cta_canal}
                    </MenuItem>
                  );
                })}
              </Select>
              <br></br>
              <br></br>
              {this.state.existeContactoTelefono ? (
                <>
                  <InputLabel>Teléfono</InputLabel>
                  <Select style={{ width: 200 }} labelId="label-habilitado" id="telefono" name="telefono" value={this.state.telefono} onChange={this.cambiarValores}>
                    <MenuItem value={0}>Seleccione</MenuItem>

                    {this.state.telefonos.map((res, index) => {
                      return (
                        <MenuItem value={res.tel_telefono} key={index}>
                          {res.tel_telefono}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={"otro"}>Otro</MenuItem>
                  </Select>
                  <br></br>
                  <br></br>
                  {this.state.existeContactoTelefonoOtro ? (
                    <>
                      <InputLabel>Otro</InputLabel>
                      <TextField
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                        }}
                        id="standard-basic"
                        label=""
                        value={this.state.otroTelefono}
                        style={{ width: 200 }}
                        type="number"
                        name="otroTelefono"
                        onChange={this.cambiarValores}
                        onInput={(e) => {
                          e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}

          <GridItem xs={12} sm={12} md={12} id="modalAgendarTabla" style={{ minHeight: 200, marginTop: 40 }}>
            {this.state.horarios.length > 0 ? (
              <Table aria-label="simple table" className="bordeTabla">
                <TableHead>
                  <TableRow>
                    <TableCell className="cellInicio" align="center">
                      Hora
                    </TableCell>
                    <TableCell align="center">Especialidad</TableCell>
                    <TableCell align="center">Profesional</TableCell>
                    <TableCell align="center">Cesfam</TableCell>
                    <TableCell className="cellTermino" align="center">
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.horarios.map((hor, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{hor["hor_atencion"].replace("T", " ")}</TableCell>
                      <TableCell align="center">{hor["esp_nombre"]}</TableCell>
                      <TableCell align="center">{hor["pro_nombre"] + " " + hor["pro_apellido"]}</TableCell>
                      <TableCell align="center">{hor["ces_nombre"]}</TableCell>
                      <TableCell align="center">
                        <a onClick={() => this.reservarHora(hor["hor_id"], hor["hor_cesfam"])}>
                          <Tooltip title={this.state.familiar ? "Reservar esta hora para el familiar" : "Reservar esta hora para el paciente"} aria-label="Reservar esta hora para el paciente">
                            <img className="imagenUsuario" src={iconoAgendar} />
                          </Tooltip>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
            <Button onClick={this.salir} className="botonDialogCancel fondoRojo">
              Cancelar
            </Button>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}
