import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "./../../assets/css/graficos.css";

export default function GraficoApp(props) {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    root.locale = am5locales_es_ES;

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
      })
    );

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );
    cursor.lineY.set("visible", false);

    function arreglarData(data) {
      var datos = [];
      for (let i = 0; i < data.length; i++) {
        datos.push({
          date: new Date(data[i].fecha_registro).getTime(),
          fecha: data[i].fecha_registro,
          android: parseInt(data[i].android),
          ios: parseInt(data[i].ios),
          huawei: parseInt(data[i].huawei),
        });
      }
      return datos;
    }

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        groupData: true,
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    chart.get("colors").set("colors", [am5.color("#ae79ba"), am5.color("#73cdeb"), am5.color("#d15e75")]);

    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Huawei",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "huawei",
        valueXField: "date",
        valueYGrouped: "sum",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueX.formatDate()}:\n{name} : {valueY}",
        }),
      })
    );

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Ios",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "ios",
        valueXField: "date",
        valueYGrouped: "sum",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueX.formatDate()}:\n{name} : {valueY}",
        }),
      })
    );

    let series3 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Android",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "android",
        valueXField: "date",
        valueYGrouped: "sum",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueX.formatDate()}:\n{name} : {valueY}",
        }),
      })
    );

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    var data = arreglarData(props.data);
    series1.data.setAll(data);
    series2.data.setAll(data);
    series3.data.setAll(data);

    series1.appear(1000);
    series2.appear(1000);
    series3.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);
  return <div id="chartdiv" class="armchResponsiveBar"></div>;
}
