import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import MaterialTable from "material-table";
import { store } from "./../../store/index";
import iconoEditar from "../../assets/img/usuarios/lapiz.svg";
import ModificarComentario from ".//ModificarComentario";
import { NotificationManager } from "react-notifications";
import Grid from "@material-ui/core/Grid";
import icono_vista_8 from "../../assets/img/usuarios/icono_vista_8.svg";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class Comentarios extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      comid: "",
      comentario: "",
      comentarios: [],
      formErrors: {},

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      usuId: props.usuId,
      modalModificarComentario: false,
    };

    this.mostrarAlertaEliminarComentario = this.mostrarAlertaEliminarComentario.bind(this);
  }

  componentDidMount() {
    this.traerComentarios();
  }

  mostrarAlertaEliminarComentario(comid) {
    confirmAlert({
      title: "",
      message: "¿Esta seguro que desea eliminar este registro?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminarComentario(comid),
          className: "btnAceptar",
        },
      ],
    });
  }

  mostrarModalModificarComentario = (id, comentario) => {
    this.setState({
      modalModificarComentario: true,
      comid: id,
      comentario: comentario,
    });
  };

  ocultarModalModificarComentario = () => {
    this.setState({ modalModificarComentario: false });
    this.traerComentarios();
  };

  traerComentarios() {
    const apiUrl = global.config.modulos.comentarios + "comentarios_por_usuario/" + this.state.usuId;

    axios.get(apiUrl, { headers: authHeader(), params: { canal: 5, cesfam: this.state.cesfam } }).then((res) => {
      if (res.data.estado) {
        const datosComentarios = res.data.datos;
        datosComentarios.map((res) => {
          res["com_fecha_registro"] = res["com_fecha_registro"].replace("T", " ");
        });
        this.setState({
          comentarios: datosComentarios,
        });
      }
    });
  }

  eliminarComentario(comId) {
    var data = {
      com_estado: 2,
      usu_id: this.state.usuId,
      usp_id: this.state.uspId,
    };
    let apiUrl = global.config.modulos.comentarios + "comentario_por_id/" + comId;
    const options = {
      method: "DELETE",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.traerComentarios();
    });
  }

  render() {
    return (
      <div className="historialHoras" style={{ marginLeft: "-10px" }}>
        <Grid container spacing={2} className="fichaUsuario scroll-container">
          <Grid item xs={12}>
            <div>
              <img className="imagenDatoSeleccionado" src={icono_vista_8} style={{ marginTop: 65 }} />
              <div className="datosUsuario " style={{ border: "inherit", marginTop: "-110px" }}>
                <div className="divBordeTablaComentarios" style={{ width: "100%", float: "left" }}>
                  <MaterialTable
                    title=""
                    columns={[
                      { title: "Fecha Registro", field: "com_fecha_registro" },
                      { title: "Comentario", field: "com_comentario" },
                      { title: "Funcionario", field: "com_usuario_panel" },
                    ]}
                    actions={[
                      {
                        icon: () => <img src={iconoEditar} style={{ width: 40 }} />,
                        tooltip: "Editar el comentario",
                        onClick: (event, rowData) => this.mostrarModalModificarComentario(rowData.com_id, rowData.com_comentario),
                      },
                    ]}
                    data={this.state.comentarios}
                    options={{
                      actionsColumnIndex: -1,
                      search: true,
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No hay comentarios registrados",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        {this.state.modalModificarComentario ? <ModificarComentario ocultar={this.ocultarModalModificarComentario} comId={this.state.comid} comentario={this.state.comentario} usuId={this.state.usuId}></ModificarComentario> : null}
      </div>
    );
  }
}
