import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { connect } from "react-redux";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";

import AgregarCargaRni from "../../components/VacunacionInfluenza/AgregarCargaRni.js";

import signo from "./../../assets/img/signo.svg";

class ListaVacunacionInfluenza extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      usuario_reparto: 0,
      repartos: [],
      agregarReparto: false,
      agregarManual: false,
      detalleContacto: false,
      modificarPaciente: false,
      agregarRNI: false,
      siglasHerramienta: ["H20"],
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.fetchReparto();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Vacunación Influenza <span title=''><img src=" + signo + "></img></span>";
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  fetchReparto = async () => {
    const apiUrl = global.config.modulos.repartos + "pacientes_cargados_influenza/?cesfam=" + this.state.cesfam;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;

      this.setState({ repartos: data.datos });
    });
  };

  agregarRNI = (e) => {
    this.setState({ agregarRNI: true });
  };

  ocultarAgregarRNI = () => {
    this.setState({ agregarRNI: false });
    this.fetchReparto();
  };

  descargar_excel() {
    let apiUrl = global.config.modulos.repartos + "descargar_pacientes_cargados_influ/?cesfam=" + this.state.cesfam;

    axios((window.location.href = apiUrl), { headers: authHeader() }).then((res) => {});
  }

  cambiarVista = (vista) => {
    this.props.history.push(vista);
  };

  render() {
    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer style={{ "justify-content": "flex-end" }}>
            {this.state.herramientas.includes(this.state.siglasHerramienta[0]) ? (
              <a onClick={() => this.cambiarVista("/graficosVacunasInfluenza")} className={"btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }}>
                Metas de vacunas
              </a>
            ) : (
              <></>
            )}
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <MaterialTable
                title=""
                columns={[
                  { title: "RUT", field: "rut" },
                  { title: "Nombre", field: "nombre" },
                  { title: "Edad", field: "rvr_edad" },
                  { title: "N° de dosis", field: "rvr_dosis" },
                  {
                    title: "Fecha dosis administrada",
                    field: "rvr_fecha_vacunado",
                  },
                  {
                    title: "Responsable",
                    field: "rvr_responsable",
                  },
                  {
                    title: "Reacción",
                    field: "rvr_reaccion",
                  },
                  {
                    title: "Vacunado",
                    field: "rvr_estado_vacuna",
                  },
                  {
                    title: "Motivo",
                    field: "rvr_administrada",
                  },
                ]}
                data={this.state.repartos}
                actions={[
                  {
                    icon: "person_add",
                    tooltip: "Agregar RNI",
                    isFreeAction: true,
                    onClick: (event) => this.agregarRNI(),
                  },
                  {
                    icon: "download",
                    tooltip: "Descargar excel",
                    isFreeAction: true,
                    onClick: (event) => this.descargar_excel(),
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                }}
              />

              {this.state.agregarRNI ? <AgregarCargaRni ocultar={this.ocultarAgregarRNI}></AgregarCargaRni> : null}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaVacunacionInfluenza);
