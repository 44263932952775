import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "./../../../assets/css/graficos.css";

export default function GraficoArchivos(props) {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");
    //console.log(props);
    const { verTabla } = props;
    //console.log(verTabla);

    var responsive = am5themes_Responsive.newEmpty(root);

    responsive.addRule({
      name: "AxisRendererY",
      relevant: am5themes_Responsive.widthL,
      settings: {
        inside: true,
      },
    });

    root.setThemes([am5themes_Animated.new(root), responsive]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
      })
    );

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: props.realizadas,
      dataFields: {
        hora_atencion: "Hora de Atención",
        usu_nombre: "Nombre Paciente",
        usu_apellido: "Apellido Paciente",
        usu_rut: "Rut Paciente",
        especialidad: "Especialidad",
        intentos: "Intentos",
        intento_exitoso: "Intento Exitoso",
      },
      dataFieldsOrder: ["Hora de Atención", "Nombre Paciente", "Apellido Paciente", "Rut Paciente", "Especialidad", "Intentos", "Intento Exitoso"],
      pngOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      pdfOptions: {
        addURL: false,
      },
      jsonOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      title: "Intentos Exitosos",
      filePrefix: props.filename,
    });

    exporting.events.on("pdfdocready", function (event) {
      event.doc.content.unshift({
        text: props.filename,
        margin: [0, 30],
        style: {
          fontSize: 25,
          bold: true,
        },
      });
    });

    exporting.events.on("workbookready", function (event) {
      event.workbook.SheetNames.push("Intentos Fallidos");
      //console.log(props.fallidas)
      props.fallidas.map((obj) => {
        delete obj.vls_fecha_creacion_sala;
        delete obj.vls_usuario;
        delete obj.vls_profesional;
        delete obj.vls_agenda;
        delete obj.vls_id;
      });
      event.workbook.Sheets["Intentos Fallidos"] = event.xlsx.utils.json_to_sheet(props.fallidas, { header: ["hora_atencion", "usu_nombre", "usu_apellido", "usu_rut", "especialidad", "intentos", "ultimo_intento"] });

      event.workbook.Sheets["Intentos Fallidos"].A1.v = "Hora de Atencion";
      event.workbook.Sheets["Intentos Fallidos"].B1.v = "Nombre Paciente";
      event.workbook.Sheets["Intentos Fallidos"].C1.v = "Apellido Paciente";
      event.workbook.Sheets["Intentos Fallidos"].D1.v = "Rut Paciente";
      event.workbook.Sheets["Intentos Fallidos"].E1.v = "Especialidad";
      event.workbook.Sheets["Intentos Fallidos"].F1.v = "Intentos";
      event.workbook.Sheets["Intentos Fallidos"].G1.v = "Último Intento";

      //event.workbook.Sheets["Intentos Fallidos"] = event.xlsx.utils.sheet_add_json(props.fallidas, { origin: 'A2', skipHeader: true });
    });

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
      })
    );

    var modal = am5.Modal.new(root, {
      content: "No se encontraron datos para estos filtros",
    });

    series.slices.template.events.on("click", function (ev) {
      let slice = ev.target._dataItem.dataContext;
      //console.log(slice);
      verTabla(slice);
    });

    series.labels.template.set("text", "{value} llamadas {category}");

    series.get("colors").set("colors", [am5.color("#6fa9ef"), am5.color("#b0d6ff")]);

    series.states.create("hidden", {
      endAngle: -90,
    });

    //console.log(props.data)
    series.data.setAll(props.data);

    series.events.on("datavalidated", function (ev) {
      var series = ev.target;
      if (ev.target.data.length < 1) {
        modal.open();
      } else {
        /* console.log('tf');
          console.log(ev.target.data);
          console.log(ev.target.data.length); */
        modal.close();
      }
    });

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);
  return <div id="chartdiv" class="armchResponsivePie"></div>;
}
