import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioInscribirTratamiento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,
      beneficio: props.beneficio,
      fechaInicio: null,
      fechaParto: null,
      formErrors: {},
      profesionales: [],
      profesional: null,
      atencionExterna: null,
      open: true,
      nombreBeneficio: props.nombreBeneficio,
      especialidades: props.especialidades,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.traerProfesionales();
  }

  traerProfesionales = () => {
    let arrEspecialidades = this.state.especialidades.split(",");
    axios
      .get(global.config.modulos.profesionales + "por_especialidad/", {
        headers: authHeader(),
        params: {
          especialidad: parseInt(arrEspecialidades[0]),
        },
      })
      .then((res) => {
        this.setState({ profesionales: res.data.datos });
      });
  };

  inscribir = () => {
    if (this.state.profesional == null || this.state.profesional == "") {
      return NotificationManager.warning("Por favor seleccione un profesional");
    }
    let arrEspecialidades = this.state.especialidades.split(",");

    axios
      .post(
        global.config.modulos.beneficios + "inscribir/",
        {
          fechaInicio: null,
          fechaExtra: null,
          fechaTermino: null,
          usuario: this.state.usuId,
          beneficio: this.state.beneficio,
          usuarioPanel: this.state.uspId,
          profesional: this.state.profesional,
          atencionExterna: this.state.atencionExterna,
          especialidad: parseInt(arrEspecialidades[0]),
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Inscrito con éxito");
        } else {
          NotificationManager.error("Ha ocurrido un error al inscribir");
        }
        this.salir();
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img alt="" class="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Inscribir beneficio {this.state.nombreBeneficio}</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <label>Por favor ingrese la fecha de la última atención (opcional) y el profesional preferente</label>
                </GridItem>
                <GridItem xs={6} style={{ marginTop: 20 }}>
                  <InputLabel>Fecha de atención</InputLabel>
                  <input type="date" name="atencionExterna" value={this.state.atencionExterna} onChange={this.cambiarValores} placeholder="" className={"inputText fechaBeneficio"} />
                </GridItem>
                <GridItem xs={6} style={{ marginTop: 20 }}>
                  <InputLabel>Profesional preferente</InputLabel>
                  <select name="profesional" value={this.state.profesional} onChange={this.cambiarValores} className={"inputText fechaBeneficio"}>
                    <option value="">Seleccione</option>
                    {this.state.profesionales.map((profesional) => (
                      <option value={profesional.pro_id}>{profesional.nombreProfesional}</option>
                    ))}
                  </select>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                  <Button id="modalEliminarBtnAceptar" className="botonDialog fondoVerde" onClick={this.inscribir}>
                    Inscribir
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
