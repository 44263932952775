import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "../../store/index";
import chileanRut from "chilean-rut";
import moment from "moment";
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import TextField from "@material-ui/core/TextField";

export default class ModificarPaciente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombre: "",
      apellidoP: "",
      rut: "",
      calleDomicilio: "",
      numeroDomicilio: "",
      comunaDomicilio: "",
      detallesCasa: "",

      telefono1: "(+56) ",
      nDosisAntes: 0,
      fechaDosisAntes: "",
      hoy: moment().format("YYYY-MM-DD"),

      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      rus_id: props.usuario_reparto,

      open: true,
    };

    this.salir = this.salir.bind(this);
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  componentDidMount() {
    this.traerPaciente();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre", "apellidoP"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }

    this.setState({ [name]: value });
  };

  validaciones() {
    const { nombre, apellidoP, rut, calleDomicilio, numeroDomicilio, comunaDomicilio, telefono1, nDosisAntes } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!nombre) {
      formIsValid = false;
      formErrors["nombreErr"] = "Escribe el nombre.";
    }

    if (!apellidoP) {
      formIsValid = false;
      formErrors["apellidoErr"] = "Escribe el apellido.";
    }

    if (!rut) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut)) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut valido.";
    }

    if (!calleDomicilio) {
      formIsValid = false;
      formErrors["calleDomicilioErr"] = "Escribe el nombre de calle.";
    }

    if (!numeroDomicilio) {
      formIsValid = false;
      formErrors["numeroDomicilioErr"] = "Escribe el número del domicilio.";
    }

    if (!comunaDomicilio) {
      formIsValid = false;
      formErrors["comunaDomicilioErr"] = "Escribe la comuna del domicilio.";
    }

    if (telefono1.toString().length <= 6) {
      formIsValid = false;
      formErrors["telefono1Err"] = "Escribe el número correcto.";
    }

    if (telefono1.toString().length > 6 && telefono1.toString().length < 15) {
      formIsValid = false;
      formErrors["telefono1Err"] = "Escribe el número correcto.";
    }

    if (nDosisAntes == 0) {
      formIsValid = false;
      formErrors["nDosisAntesErr"] = "Escribe el número de dosis anterior.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        rus_id: this.state.rus_id,
        rut: this.state.rut,
        nombre: this.state.nombre,
        apellidop: this.state.apellidoP,
        calle: this.state.calleDomicilio,
        numero: this.state.numeroDomicilio,
        comuna: this.state.comunaDomicilio,
        block_depto_casa: this.state.detallesCasa,
        telefono: this.state.telefono1,
        dosisanterior: this.state.nDosisAntes,
        fechadosisanterior: this.state.fechaDosisAntes,
        cesfam: this.state.cesfam,
        uspId: this.state.uspId,
      };
      let apiUrl = global.config.modulos.repartos + "paciente/";
      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        var data = res.data;
        if (data.estado == false) {
          this.mostrarAlerta(data.mensaje);
        }
        this.salir();
      });
    }
  };

  validarTelefono = (e) => {
    let valorTelefono = "";

    if (e.target.name === "telefono1" && e.target.validity.valid) {
      var telefono = document.getElementById("telefono1");
      var nums_v = telefono.value.match(/\d+/g);
      if (nums_v != null) {
        if (telefono.value.toString().length >= 6) {
          valorTelefono = telefono.value;
        } else {
          valorTelefono = "(+56) ";
        }
      } else {
        valorTelefono = "(+56) ";
      }
      this.setState({ telefono1: valorTelefono });
    }
  };

  traerPaciente = () => {
    const apiUrl = global.config.modulos.repartos + "paciente/";

    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          rus_id: this.state.rus_id,
        },
      })
      .then((res) => {
        var data = res.data;

        this.setState({
          nombre: data.rus_nombre,
          apellidoP: data.rus_apellidos,
          rut: data.rus_rut,
          calleDomicilio: data.domicilio.rds_nombre_calle,
          numeroDomicilio: data.domicilio.rds_numero_domicilio,
          comunaDomicilio: data.domicilio.rds_comuna,
          detallesCasa: data.domicilio.rds_vivienda,
          telefono1: "(+56) " + data.rus_telefono,
          fechaDosisAntes: data.fecha_ultima_vacuna,
          nDosisAntes: data.ultima_vacuna,
        });
      });
  };

  render() {
    const { nombreErr, apellidoErr, rutErr, calleDomicilioErr, numeroDomicilioErr, comunaDomicilioErr, telefono1Err, nDosisAntesErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalAgregarProfesional">
        <DialogContent>
          <div spacing={3} className="modificarProfesional">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Modificar paciente</h2>
                </GridItem>
              </GridContainer>
            </GridItem>
            <Grid container spacing={3} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Nombre*</InputLabel>
                <input type="text" name="nombre" value={this.state.nombre} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                {nombreErr && <div style={{ color: "red", paddingBottom: 10 }}>{nombreErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Apellidos*</InputLabel>
                <input type="text" name="apellidoP" value={this.state.apellidoP} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                {apellidoErr && <div style={{ color: "red", paddingBottom: 10 }}>{apellidoErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Rut*</InputLabel>
                <input type="text" id="rut" name="rut" value={this.state.rut} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={"inputText"} maxLength={12} />
                {rutErr && <div style={{ color: "red", paddingBottom: 10 }}>{rutErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Calle del domicilio*</InputLabel>
                <input type="text" name="calleDomicilio" value={this.state.calleDomicilio} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                {calleDomicilioErr && <div style={{ color: "red", paddingBottom: 10 }}>{calleDomicilioErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Número del domicilio*</InputLabel>
                <input type="text" name="numeroDomicilio" value={this.state.numeroDomicilio} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                {numeroDomicilioErr && <div style={{ color: "red", paddingBottom: 10 }}>{numeroDomicilioErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Comuna del domicilio*</InputLabel>
                <input type="text" name="comunaDomicilio" value={this.state.comunaDomicilio} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                {comunaDomicilioErr && <div style={{ color: "red", paddingBottom: 10 }}>{comunaDomicilioErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>block/depto/casa</InputLabel>
                <input type="text" name="detallesCasa" value={this.state.detallesCasa} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Teléfono para contacto*</InputLabel>
                <input type="text" id="telefono1" name="telefono1" value={this.state.telefono1} onChange={this.validarTelefono} placeholder="" className={"inputText"} maxLength={15} pattern="^-?[0-9+() ]*\d*\.?\d*$" />
                {telefono1Err && <div style={{ color: "red", paddingBottom: 10 }}>{telefono1Err}</div>}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>N° de dosis anterior:*</InputLabel>
                <input type="number" id="nDosisAntes" name="nDosisAntes" value={this.state.nDosisAntes} onChange={this.cambiarValores} placeholder="" className={"inputText"} min="0" />
                {nDosisAntesErr && <div style={{ color: "red", paddingBottom: 10 }}>{nDosisAntesErr}</div>}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Fecha de la dosis anterior</InputLabel>
                <TextField
                  id="fechaDosisAntes"
                  name="fechaDosisAntes"
                  type="date"
                  value={this.state.fechaDosisAntes}
                  onChange={this.cambiarValores}
                  InputProps={{
                    inputProps: { min: "1900-01-01", max: this.state.hoy },
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ width: "100%", float: "left", marginTop: "25px" }}>
              <Button style={{ marginTop: 10 }} onClick={this.salir} color="primary" className="fondoRojo">
                Cancelar
              </Button>
              <Button style={{ marginTop: 10 }} onClick={this.enviarFormulario} color="primary" className="fondoVerde">
                Modificar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
