import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import { store } from "../../store/index";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import chileanRut from "chilean-rut";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";
import finalizar from "../../assets/img/gestion/finalizar.svg";
import finalizar_disabled from "../../assets/img/gestion/finalizar_disabled.svg";
import agendar from "../../assets/img/usuarios/agendar.svg";
import agendar_disabled from "../../assets/img/usuarios/agendar_disabled.svg";
import copiar from "../../assets/img/usuarios/copiar.svg";
import signo from "./../../assets/img/signo.svg";

import AgendarHora from "../../components/Agendar/ModalAgendarHora.js";

import ModalHoras from "./ModalHoras.js";

import moment from "moment";
import "moment/locale/es";

import "./../../assets/css/inicio.css";
import "./../../assets/css/horasres.css";

export default class VistaHoras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profesionales: [],
      horas: [],
      proId: "",
      busquedaProfesional: false,

      modalHoras: false,
      agendarHora: false,
      horarioAgendar: "",
      nombreprofesional: "",
      nombreEspecialidad: "",

      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
    };
  }

  componentDidMount() {
    this.traerProfesionales();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Traspaso a FCE <span title='Podrá visualizar toda la información relacionada a las horas Reservadas por Profesional'><img src=" + signo + "></img></span>";
  };

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        this.setState({
          profesionales: res.data.datos,
        });
      }
    });
  }

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  traerHoras() {
    this.setState({ horas: [] });
    const apiUrl = global.config.modulos.reservas + "horas_reservadas/?cesfam=" + this.state.cesfam + "&proId=" + this.state.proId;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        this.setState({
          horas: res.data.horas,
          total: res.data.total,
        });
      }
    });
  }

  agendarHora = (row) => {
    this.setState({
      agendarHora: true,
      horarioAgendar: row.hor_id,
      nombreEspecialidad: row.especialidad,
    });
  };

  ocultar_agendar = () => {
    this.setState({ agendarHora: false });
    this.traerHoras();
  };

  validaciones() {
    let formErrors = {};
    let formIsValid = true;

    var prof = document.getElementById("cmbProfesionales").value;

    if (prof == "") {
      formIsValid = false;
      formErrors["profErr"] = "Elije un profesional";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  buscarProfesional = () => {
    if (this.validaciones()) {
      axios.get(global.config.modulos.profesionales + "por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam, { headers: authHeader() }).then((res) => {
        this.setState({
          busquedaProfesional: true,
        });
      });
    }
    this.traerHoras();
  };

  renderEstado(est) {
    switch (est) {
      case "Libre":
        return <p className="free">Libre</p>;
      default:
        return <p className="reserved">Reservada</p>;
    }
  }

  renderReservar(est) {
    if (est.est_hora == "Libre") {
      if (moment(est.hor_atencion).format("YYYY-MM-DD HH:mm:ss") > moment().format("YYYY-MM-DD HH:mm:ss")) {
        return (
          <Tooltip title="Agendar Hora" aria-label="">
            <img className="mediumIcon" style={{ marginTop: 0 }} onClick={this.agendarHora.bind(this, est)} src={agendar} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Ficha Clinica" aria-label="">
            <img className="mediumIcon" style={{ marginTop: 0, cursor: "default" }} src={agendar_disabled} />
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip title="Ficha Clinica" aria-label="">
          <img className="mediumIcon" style={{ marginTop: 0, cursor: "default" }} src={agendar_disabled} />
        </Tooltip>
      );
    }
  }

  renderFicha(est) {
    if (est.est_hora != "Libre") {
      return (
        <Tooltip title="Ficha Clinica" aria-label="">
          <img className="mediumIcon" style={{ marginTop: 0 }} src={finalizar} onClick={this.modalHoras.bind(this, est)} />
        </Tooltip>
      );
    } else {
      if (moment(est.hor_atencion).format("YYYY-MM-DD HH:mm:ss") < moment().format("YYYY-MM-DD HH:mm:ss")) {
        return (
          <Tooltip title="Ficha Clinica" aria-label="">
            <img className="mediumIcon" style={{ marginTop: 0 }} src={finalizar} onClick={this.modalHoras.bind(this, est)} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Ficha Clinica" aria-label="">
            <img className="mediumIcon" style={{ marginTop: 0, cursor: "default" }} src={finalizar_disabled} />
          </Tooltip>
        );
      }
    }
  }

  modalHoras = (est) => {
    this.setState({ modalHoras: true, horarioAgendar: est.hor_id });
  };

  cerrarModalHoras = () => {
    this.setState({ modalHoras: false });
  };

  cerrarModalHorasActualizar = () => {
    this.setState({ modalHoras: false });
    this.traerHoras();
  };

  formatoRut(rut) {
    if (rut === "-") {
      return <>{rut}</>;
    } else {
      return (
        <>
          {chileanRut.format(rut, true)}
          <Tooltip title="Copiar RUT" aria-label="">
            <img className="smallIcon" style={{ marginTop: 0 }} onClick={() => this.copiarRut(rut)} src={copiar} />
          </Tooltip>
        </>
      );
    }
  }

  render() {
    const { horas } = this.state;
    const elegirProfesional = (proid, nombreprofesional) => {
      if (proid !== null) {
        this.setState({
          proId: proid,
          nombreprofesional: nombreprofesional,
        });
      } else {
        this.setState({ busquedaProfesional: false });
      }
    };
    return (
      <div className="middle-area">
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: 30 }}>
            <Autocomplete
              id="cmbProfesionales"
              onChange={function (event, res) {
                if (res === null) {
                  elegirProfesional(null, null);
                } else {
                  elegirProfesional(res.prc_profesional, res.nombreprofesional);
                }
              }}
              size="small"
              options={Object.values(this.state.profesionales)}
              getOptionLabel={(option) => option.nombreprofesional}
              filterOptions={createFilterOptions({
                // matchFrom: 'start',
                stringify: (option) => option.nombreprofesional + option.especialidades,
              })}
              style={{
                width: 390,
                height: 40,
                float: "left",
              }}
              renderInput={(params) => <TextField {...params} label="Buscar Profesional" variant="outlined" />}
            ></Autocomplete>
            <Button
              variant="contained"
              onClick={this.buscarProfesional}
              color="primary"
              style={{
                marginLeft: 20,
                boxShadow: "none",
              }}
              className="fondoNaranjo"
            >
              Buscar
            </Button>
          </GridItem>
        </GridContainer>
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} style={{ marginTop: 30, marginLeft: "0px", overflow: "scroll" }}>
              <div>
                <div style={{ marginBottom: 100 }}>
                  {this.state.busquedaProfesional ? (
                    <TableContainer>
                      <Table aria-label="simple table" className="bordeTabla">
                        <MaterialTable
                          title=""
                          columns={[
                            {
                              title: "Hora Atencion",
                              field: "hor_atencion",
                              render: (rowData) => <p>{moment(rowData.hor_atencion).format("DD-MM-YYYY HH:mm:ss")}</p>,
                            },
                            {
                              title: "RUT Paciente",
                              field: "paciente_rut",
                              render: (rowData) => <p>{this.formatoRut(rowData.paciente_rut)}</p>,
                            },
                            { title: "Nombre Paciente", field: "paciente_nom" },
                            {
                              title: "Sector",
                              field: "sector",
                            },
                            {
                              title: "Tipo de Atencion",
                              field: "especialidad",
                            },
                            {
                              title: "Rendimiento",
                              field: "hor_rendimiento",
                              render: (rowData) => <p>{rowData.hor_rendimiento} min</p>,
                            },
                            {
                              title: "Estado",
                              field: "est_hora",
                              searchable: true,
                              render: (rowData) => <p>{this.renderEstado(rowData.est_hora)}</p>,
                            },
                            {
                              title: "Agendar Hora",
                              render: (rowData) => <>{this.renderReservar(rowData, this)}</>,
                            },
                            {
                              title: "Ficha Clinica",
                              render: (rowData) => <>{this.renderFicha(rowData, this)}</>,
                            },
                          ]}
                          data={this.state.horas}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                            search: true,
                            toolbar: false,
                            actions: false,
                            exportAllData: true,
                            exportButton: true,
                          }}
                          localization={{
                            header: {
                              actions: "a",
                            },
                            toolbar: {
                              searchPlaceholder: "Buscar",
                              searchTooltip: "Buscar",
                              exportTitle: "Exportar",
                              exportAriaLabel: "Exportar",
                              exportCSVName: "Exportar como CSV",
                              exportPDFName: "Exportar como PDF",
                            },
                            body: {
                              emptyDataSourceMessage: "Este profesional no cuenta con registros para mostrar",
                            },
                            pagination: {
                              labelRowsSelect: "Filas",
                              labelDisplayedRows: "{from}-{to} de {count}",
                            },
                          }}
                        ></MaterialTable>
                      </Table>
                    </TableContainer>
                  ) : null}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>

        {this.state.modalHoras ? <ModalHoras ocultar={this.cerrarModalHoras} ocultarActualizado={this.cerrarModalHorasActualizar} horario={this.state.horarioAgendar}></ModalHoras> : null}

        {this.state.agendarHora ? <AgendarHora ocultar={this.ocultar_agendar} horario={this.state.horarioAgendar} nombreEspecialidad={this.state.nombreEspecialidad} nombreProfesional={this.state.nombreprofesional}></AgendarHora> : null}
      </div>
    );
  }
}
