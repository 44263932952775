import React, { useState, useEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import { loginUser } from "../../store/actions/actions";
import { handleError } from "../../components/Helpers/helpers.js";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import { fetchToken } from "./../../firebase";
import { confirmAlert } from "react-confirm-alert";

import "./../../assets/css/login.css";

const Login = ({ loginUser }) => {
  sessionStorage.setItem("isAuthUserVista", 4);

  const history = useHistory();
  const [ip, setIp] = useState("");

  const [isTokenFound, setTokenFound] = useState("");
  fetchToken(setTokenFound);

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    // Set the IP address to the constant `ip`
    setIp(JSON.stringify(data));
  };

  useEffect(() => {
    getIp();
  }, []);

  const validarLogin = ({ loginUser }) => {
    if (user === "" || pass === "") {
      NotificationManager.error("Debes llenar todos los campos");
      return false;
    }
    axios
      .post(global.config.modulos.login + `autenticar/`, {
        user: user,
        pass: pass,
        token: isTokenFound,
        ip: ip,
      })
      .then((res) => {
        const datos = res.data;

        sessionStorage.setItem("isAuthUserVista", 4);
        localStorage.setItem("token", datos.token);

        datos.token
          ? datos.bloqueado.estado
            ? confirmAlert({
                title: "",
                message: datos.bloqueado.mensaje,
                buttons: [
                  {
                    label: "Aceptar",
                    className: "btnAceptar",
                  },
                ],
              })
            : loginUser(datos.cesfam, datos.usp, datos.nombreusp, datos.herramientas, datos.cliente, datos.id_medico, datos.tipo)
          : NotificationManager.error(datos.mensaje);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const recuperarClave = () => {
    history.push("/recuperar");
  };

  return (
    <div className="login_medico">
      <div className="login-inicio">
        <div className="login-logo"></div>
        <div className="login-cuerpo">
          <TextField value={user} onChange={(e) => setUser(e.target.value)} id="login-usuario" name="login-usuario" label="Rut" fullWidth />

          <TextField value={pass} onChange={(e) => setPass(e.target.value)} id="login-contrasena" name="login-contrasena" label="Contraseña" type="password" fullWidth />

          <Button
            id="login-ingresar"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              validarLogin({ loginUser });
            }}
          >
            Ingresar
          </Button>

          <Grid style={{ textAlign: "center", marginTop: "20px" }}>
            <label
              onClick={() => {
                recuperarClave();
              }}
              className="olvidasteClave"
            >
              ¿Olvidaste tu contraseña?
            </label>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { loginUser })(Login);
