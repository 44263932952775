import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { store } from "store/index";
import { Grid, List, ListItem } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

import { confirmAlert } from "react-confirm-alert";

import Cargando from "components/Helpers/Cargando.js";

// CSS
import crearTotem from "assets/css/crearTotem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class CargarVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totem: store.getState().totem,
      arrNombre_video: [],
      arrVideosCargados: [],
    };
  }

  componentDidMount() {
    this.videos_cargados();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  cargarArchivo = (event, ubicacion) => {
    var arrVideo = [];
    const { totem } = this.state;

    for (let i = 0; i < event.length; i++) {
      var extension_sin_modificar = event[i].type;
      var extension = extension_sin_modificar.substring(extension_sin_modificar.lastIndexOf("/") + 1);
      var megabytes = event[i].size / 1048576;
      var archivo = document.getElementById("archivo");

      if (megabytes >= 100) {
        archivo.value = "";
        this.mostrarAlerta("Los videos no pueden ser mayores a 100MB");
      } else if (extension != "mp4") {
        this.mostrarAlerta("El archivo seleccionado no ha sido reconocido como mp4");
        archivo.value = "";
      } else {
        const data = new FormData();
        var arc = event[i];
        var nombreNuevo = totem + "_" + event[i]["name"];
        var blob = arc.slice(0, arc.size, "video/mp4");
        var newFile = new File([blob], nombreNuevo, { type: "video/mp4" });

        data.append("file", newFile);
        data.append("filename", ubicacion);
        this.subirArchivo(data);

        arrVideo.push(nombreNuevo);
      }
    }

    // SE SUBEN LAS RUTAS A LA DB
    const datos = {
      totem: totem,
      nombre_video: arrVideo,
    };

    axios.post(global.config.modulos.admin_totem + "administrar_videos/", datos, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        archivo.value = "";
        this.videos_cargados();
        this.mostrarAlerta("Tus videos han sido cargados con éxito");
      } else {
        this.mostrarAlerta(res.data.mensaje_error);
      }
    });
  };

  subirArchivo = async (data) => {
    this.setState({ cargando: true });
    try {
      await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_totem", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.setState({ cargando: false });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  videos_cargados = () => {
    axios
      .get(global.config.modulos.admin_totem + "administrar_videos/", {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        this.setState({ arrVideosCargados: data });
      });
  };

  render() {
    return (
      <GridContainer style={{ width: "100%", marginTop: "10px" }}>
        <GridItem xs={12} sm={12} md={12} style={{ display: "flex" }}>
          <Grid item xs={6} sm={6} md={6} style={{ marginRight: "5%" }}>
            <InputLabel className={crearTotem.textosTitulos} style={{ margin: "0px 0px 5px 5px" }}>
              Cargar Video
            </InputLabel>
            <input className={crearTotem.inputFile} type="file" id="archivo" accept=".mp4" name="archivo" multiple onChange={(e) => this.cargarArchivo(e.target.files, "publicidad/pantalla/videos/")} />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <p style={{ padding: "10px", border: "solid 3px #eaa028", borderRadius: "10px", fontWeight: "300", marginTop: "-90px" }}>
              <strong>Recuerda:</strong>
              <br />
              Los videos que subas se mostrarán directamente en el tótem, y deben cumplir con los siguientes requisitos:
              <br />
              <br />• El video debe estar en formato <strong>.mp4</strong>.<br />• El tamaño del video no debe exceder los <strong>100 MB</strong>.
              <br />• El video debe incluir <strong>subtítulos obligatorios</strong>.
              <br />
              <br />
              Si no se cumple alguno de estos requisitos, el video será deshabilitado automáticamente.
            </p>
          </Grid>
        </GridItem>

        {this.state.cargando ? <Cargando></Cargando> : <></>}
      </GridContainer>
    );
  }
}
