import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import chileanRut from "chilean-rut";
import { confirmAlert } from "react-confirm-alert";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "./../../assets/css/login.css";

export default class Recuperar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primeraParte: true,
      segundaParte: false,
      terceraParte: false,

      rutRe: "",
      codigoRe: "",
      uspId: "",
      claveNuevaRe: "",
      claveConfirmacionRe: "",
      ip: "",
    };

    this.getIp();
  }

  getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    // Set the IP address to the constant `ip`

    this.setState({
      ip: JSON.stringify(data),
    });
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  validaciones() {
    const { rutRe, codigoRe, claveNuevaRe, claveConfirmacionRe, primeraParte, segundaParte, terceraParte } = this.state;

    let formIsValid = true;

    if (!rutRe && primeraParte) {
      formIsValid = false;
      this.mostrarAlerta("Escribe el rut.");
    } else if (!chileanRut.validate(rutRe) && primeraParte) {
      formIsValid = false;
      this.mostrarAlerta("Escribe el rut valido.");
    }

    if (!codigoRe && segundaParte) {
      formIsValid = false;
      this.mostrarAlerta("Escribe el codigo.");
    }

    if (terceraParte) {
      if (claveNuevaRe.length < 7 && claveConfirmacionRe.length < 7) {
        formIsValid = false;
        this.mostrarAlerta("Las contraseña deben ser mayor a 6 caracteres");
      }

      if (claveNuevaRe != claveConfirmacionRe) {
        formIsValid = false;
        this.mostrarAlerta("Las contraseñas deben ser iguales");
      }
    }

    return formIsValid;
  }

  iniciarRecuperacion = () => {
    if (this.validaciones()) {
      this.validaExiste();
    }
  };

  validaExiste = () => {
    var data = {
      rut: this.state.rutRe,
      ip: this.state.ip,
    };
    let apiUrl = global.config.modulos.usuariospanel + "recuperar_clave/";
    const options = {
      method: "POST",
      data: data,
    };
    axios(apiUrl, options).then((res) => {
      var data = res.data;
      var estado = data.estado;
      if (estado) {
        this.mostrarAlerta(data.mensaje);
        this.setState({ primeraParte: false, segundaParte: true });
      } else {
        this.mostrarAlerta(data.mensaje);
      }
    });
  };

  verificarCodigo = () => {
    if (this.validaciones()) {
      var data = {
        rut: this.state.rutRe,
        ip: this.state.ip,
        codigo: this.state.codigoRe,
      };
      let apiUrl = global.config.modulos.usuariospanel + "verificar_codigo/";
      const options = {
        method: "POST",
        data: data,
      };
      axios(apiUrl, options)
        .then((res) => {
          var data = res.data;
          var estado = data.estado;
          if (estado) {
            this.setState({ segundaParte: false, terceraParte: true, uspId: data.usp_id });
          } else {
            this.mostrarAlerta(data.mensaje);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  cambiar_pass = () => {
    if (this.validaciones()) {
      axios
        .post(global.config.modulos.usuariospanel + "cambiar_pass/", {
          rut: this.state.rutRe,
          ip: this.state.ip,
          usp_id: this.state.uspId,
          pass_nueva: this.state.claveNuevaRe,
          pass_confirmacion: this.state.claveConfirmacionRe,
        })
        .then((res) => {
          var data = res.data;
          var estado = data.estado;
          if (estado) {
            confirmAlert({
              title: "Éxito",
              message: data.mensaje,
              buttons: [
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                  onClick: () => this.login(),
                },
              ],
            });
          } else {
            this.mostrarAlerta(data.mensaje);
          }
        });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  login = () => {
    if (sessionStorage.getItem("isAuthUserVista") == 1) {
      this.props.history.push("/");
    } else if (sessionStorage.getItem("isAuthUserVista") == 2) {
      this.props.history.push("/salud");
    } else if (sessionStorage.getItem("isAuthUserVista") == 3) {
      this.props.history.push("/departamento");
    } else {
      this.props.history.push("/");
    }
  };

  render() {
    var clase = "login";
    if (sessionStorage.getItem("isAuthUserVista") == 2) {
      clase = "login_salud";
    } else if (sessionStorage.getItem("isAuthUserVista") == 3) {
      clase = "login_departamento";
    }

    return (
      <div className={clase}>
        <div className="login-inicio">
          <div className="login-logo"></div>
          <div className="login-cuerpo">
            {this.state.primeraParte ? (
              <div>
                <TextField id="rutRe" name="rutRe" label="Rut" value={this.state.rutRe} onChange={this.cambiarValores} className="input-recuperar" fullWidth />

                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={this.login}
                    className="fondoRojo"
                    style={{
                      float: "right",
                      marginLeft: 10,
                      color: "#fff",
                    }}
                  >
                    Salir
                  </Button>
                  <Button onClick={this.iniciarRecuperacion} className="fondoVerde" style={{ float: "right", color: "#fff" }}>
                    Recuperar
                  </Button>
                </div>
              </div>
            ) : null}

            {this.state.segundaParte ? (
              <div>
                <TextField id="codigoRe" name="codigoRe" label="Codigo verificación" value={this.state.codigoRe} onChange={this.cambiarValores} className="input-recuperar" fullWidth />

                <Button id="login-ingresar" variant="contained" color="primary" disableElevation className="fondoVerde" onClick={this.verificarCodigo}>
                  Validar
                </Button>
              </div>
            ) : null}

            {this.state.terceraParte ? (
              <div>
                <TextField
                  id="claveNuevaRe"
                  name="claveNuevaRe"
                  label="Nueva contraseña"
                  type="password"
                  onChange={this.cambiarValores}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    marginTop: 20,
                  }}
                />

                <TextField
                  id="claveConfirmacionRe"
                  name="claveConfirmacionRe"
                  label="Repetir nueva contraseña"
                  type="password"
                  onChange={this.cambiarValores}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    marginTop: 20,
                  }}
                />

                <Button id="login-ingresar" variant="contained" color="primary" disableElevation onClick={this.cambiar_pass} className="fondoVerde">
                  Cambiar contraseña
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
