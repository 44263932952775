import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";
import axios from "axios";
import { store } from "../../store/index";

// core components
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import DualListBox from "react-dual-listbox";
import Grid from "@material-ui/core/Grid";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import "react-dual-listbox/lib/react-dual-listbox.css";
import MultiSelect from "react-multi-select-component";
import TextField from "@material-ui/core/TextField";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Calendar } from "react-multi-date-picker";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import TimePicker from "rc-time-picker";

// estilos

export default class IngresarBloqueoSectorHorario extends Component {
  constructor(props) {
    super(props);

    var fechaActual = moment().format("YYYY-MM-DD");
    this.state = {
      rec_id: props.configuracion,

      profesionales: [],
      profesionalesSeleccionados: [],
      horaInicio: moment(fechaActual + " 08:00"),
      horaTermino: moment(fechaActual + " 17:00"),
      fecha: "",
      fechaInicio: "",
      fechaTermino: "",
      todasHoras: false,
      limiteFecha: moment(),
      tipoHoras: 1,
      activarFechaTermino: false,
      tipoReunion: 5,

      motivosBloqueo: [],
      motivoBloqueo: "0",
      motivoBloqueoTxt: "",

      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      formErrors: {},
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.traerProfesionales();
    this.traerMotivosBloqueo();
    this.traerConfiguracion();
  }

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data["estado"]) {
        let proResult = [];
        for (var i = 0; i < res.data["datos"].length; i++) {
          let pro = {
            value: res.data["datos"][i]["prc_profesional"],
            label: res.data["datos"][i]["nombreprofesional"],
          };
          proResult.push(pro);
        }
        this.setState({
          profesionales: proResult,
        });
      }
    });
  }

  traerMotivosBloqueo() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_bloqueo_horario/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          motivosBloqueo: res.data,
        });
      }
    });
  }

  traerConfiguracion() {
    const apiUrl = global.config.modulos.agendamientos + "configuracion_bloqueo_por_id/" + this.state.rec_id;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var motivoBloqueo = res.data.rec_motivo_bloqueo;
      var motivoBloqueoTexto = res.data.rec_motivo_bloqueo_texto;

      var mostrarMotivoTxt = false;
      if (motivoBloqueo == "otro") {
        mostrarMotivoTxt = true;
      }

      var profesionalesSeleccionados = [];
      if (res.data.rec_profesionales != "") {
        profesionalesSeleccionados = res.data.rec_profesionales.split(",").map(Number);
      }

      var fechaActual = moment().format("YYYY-MM-DD");

      var horaInicio = res.data.rec_hora_inicio;
      var horaTermino = res.data.rec_hora_termino;

      this.setState({
        profesionalesSeleccionados: profesionalesSeleccionados,
        fechaInicio: res.data.rec_fecha_inicio,
        fechaTermino: res.data.rec_fecha_termino,
        horaInicio: moment(fechaActual + " " + horaInicio),
        horaTermino: moment(fechaActual + " " + horaTermino),
        activarFechaTermino: res.data.rec_fecha_termino_activa,
        tipoHoras: res.data.rec_tipo_fechas,

        motivoBloqueo: motivoBloqueo,
        motivoBloqueoTxt: motivoBloqueoTexto,
        mostrarMotivoTxt: mostrarMotivoTxt,
      });
    });
  }

  render() {
    const { profesionales, profesionalesSeleccionados, horaInicio, horaTermino, limiteFecha, fechaInicio, fechaTermino, tipoHoras, motivosBloqueo, motivoBloqueo, motivoBloqueoTxt } = this.state;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2>Ver Bloqueo por profesional</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}></GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="cargarHorario" style={{ marginTop: "2vw" }}>
                  <div>
                    <React.Fragment>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <InputLabel>Profesionales</InputLabel>
                          <br></br>
                          <DualListBox canFilter options={profesionales} selected={profesionalesSeleccionados} filterPlaceholder="Buscar..." disabled />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                              <InputLabel>Motivo de bloqueo</InputLabel>
                              <br></br>
                              <Select name="motivoBloqueo" className={"selectSimple"} value={motivoBloqueo} style={{ minWidth: 250 }} displayEmpty disabled>
                                <MenuItem value={"0"} key={0}>
                                  {"Selecciona"}
                                </MenuItem>

                                {motivosBloqueo &&
                                  motivosBloqueo.map((mb, index) => (
                                    <MenuItem value={mb.mb_descripcion} key={index}>
                                      {mb.mb_nombre}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              {this.state.mostrarMotivoTxt ? (
                                <TextareaAutosize
                                  name="motivoBloqueoTxt"
                                  aria-label="minimum height"
                                  className={"selectSimple "}
                                  style={{
                                    float: "left",
                                    minWidth: 250,
                                    marginTop: 20,
                                  }}
                                  rowsMin={3}
                                  placeholder="Escribe el motivo..."
                                  value={motivoBloqueoTxt}
                                  disabled
                                />
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <br></br>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid item xs={12} sm={12} md={12}>
                            <InputLabel></InputLabel>
                            <RadioGroup name="tipoHoras" id="tipoHoras" value={tipoHoras} disabled>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={3} md={2}>
                                  <FormControlLabel value={1} control={<Radio />} label="Horas libres" disabled />
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                  <FormControlLabel value={2} control={<Radio />} label="Horas libres y Reservadas" disabled />
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </Grid>
                          <br></br>

                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={3}>
                                <InputLabel>Fecha de inicio</InputLabel>
                                <br></br>
                                <TextField
                                  id="fechaInicio"
                                  name="fechaInicio"
                                  type="date"
                                  value={fechaInicio}
                                  inputProps={{
                                    min: limiteFecha.format("YYYY-MM-DD"),
                                  }}
                                  disabled
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={3}>
                                <InputLabel>Fecha de término</InputLabel>
                                <br></br>
                                <TextField
                                  id="fechaTermino"
                                  name="fechaTermino"
                                  type="date"
                                  value={fechaTermino}
                                  inputProps={{
                                    min: limiteFecha.format("YYYY-MM-DD"),
                                  }}
                                  disabled
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel control={<Checkbox name="activarFechaTermino" checked={this.state.activarFechaTermino} />} label="Permanente" disabled />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={2}>
                              <p>
                                <label
                                  style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                  }}
                                >
                                  Hora inicio
                                </label>
                              </p>
                              <br></br>
                              <TimePicker
                                minuteStep={5}
                                showSecond={false}
                                name="horaInicio"
                                id="horaInicio"
                                value={horaInicio}
                                style={{
                                  width: 100,
                                }}
                                disabled
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2}>
                              <p>
                                <label
                                  style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                  }}
                                >
                                  Hora Termino
                                </label>
                              </p>
                              <br></br>
                              <TimePicker
                                minuteStep={5}
                                showSecond={false}
                                name="horaTermino"
                                id="horaTermino"
                                value={horaTermino}
                                style={{
                                  width: 100,
                                }}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <br></br>
                      </Grid>
                    </React.Fragment>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
