import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";
import moment from "moment";
import axios from "axios";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { store } from "./../../store/index";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import { Grid, List, ListItem } from "@material-ui/core";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModificarUsuario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usu_id: props.usuario,
      nombre: "",
      nombreSocial: "",
      apellidoP: "",
      apellidoM: "",
      rut: "",
      rutOriginal: "",
      direccion: "",
      telefono1: "(+56) ",
      telefono2: "",
      correo: "",
      correoAnt: "",
      fechaNacimiento: "",
      sexo: "",
      condicion: "",
      situacion: "",
      discapacidad: "",
      nacionalidad: "",
      pasaporte: "",
      fechaParto: "",
      diasPrematuro: 0,
      semanasEmbarazo: 0,
      gestacionPrematuro: "",
      pesoPrematuro: 0,
      clasificacionPrematuro: "",

      comunaResidencia: "",
      cesfamUsuario: "",
      sector: "",
      estratificacion: 0,
      existe: 0,
      estadoUsuario: 0,
      motivoEliminado: 0,
      formErrors: {},
      comunasResidencia: [],
      cesfams: [],
      sectores: [],
      condiciones: [],
      condiciones: [],
      discapacidades: [],
      discapacidades: [],
      nacionalidades: [],

      condicionesSeleccionadas: [],
      discapacidadesSeleccionadas: [],

      condicionesSeleccionadasCui: [],
      discapacidadesSeleccionadasCui: [],

      puebloOriginario: "",
      entidad: "",
      entidades: [],

      asociacionCuidador: 0,
      rutCuidador: "",
      rutACuidar: "",

      asociacionApoderado: 0,
      rutApoderado: "",

      nro_ficha: 0,
      nro_carpeta: 0,

      mostrarDiscapacidadCui: false,
      mostrarCondicionCui: false,

      mostrarDiscapacidad: false,
      mostrarApoderado: false,
      mostrarEmbarazo: false,
      mostrarPrematuro: false,
      mostrarCuidador: false,
      mostrarCuidadorRut: false,

      hoy: moment().format("YYYY-MM-DD"),
      usp_id: store.getState().usp,
      cesfam: store.getState().cesfam,
      herramientas: store.getState().herramientas,
      cliente: props.cliente,
      canal: 5,
      usu_tipo: 0,
      open: true,
    };
  }

  componentDidMount() {
    this.traerCesfams(this.state.cliente);
    this.traerCondiciones();
    this.traerDiscapacidades();
    this.traerTodasComunas();
    this.traerNacionalidades();
    this.traerDatosUsuario();
    this.traerEntidades();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  revisaExisteRut = () => {
    var rut = document.getElementById("rutACuidar").value;

    this.setState({
      rutACuidar: rut,
    });

    let apiUrl = global.config.modulos.usuarios + "por_rut/";
    const options = {
      method: "POST",
      data: {
        rut: rut,
      },
      headers: authHeader(),
    };
    if (rut.length >= 8) {
      axios(apiUrl, options).then((res) => {
        var data = res.data;
        var estado = data.estado;
        if (estado) {
          this.setState({
            condicionesSeleccionadasCui: res.data["datos"]["ids_condiciones"],
            discapacidadesSeleccionadasCui: res.data["datos"]["ids_discapacidades"],
            mostrarCondicionCui: true,
          });

          if (res.data["datos"]["ids_discapacidades"].includes(5)) {
            this.setState({
              mostrarDiscapacidadCui: true,
            });
          } else {
            this.setState({
              mostrarDiscapacidadCui: false,
              discapacidadCui: 1,
            });
          }
        }
      });
    } else {
      this.setState({
        mostrarCondicionCui: false,
        mostrarDiscapacidadCui: false,
        condicionesSeleccionadasCui: [],
        discapacidadesSeleccionadasCui: [],
      });
    }
  };

  traerTodasComunas() {
    const apiUrl = global.config.modulos.cesfams + "comunas/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        this.setState({
          comunasResidencia: res.data["datos"],
        });
      });
  }

  traerCesfams(com) {
    const apiUrl = global.config.modulos.cesfams + "cesfams_usp/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          usp: this.state.usp_id,
          id: com,
        },
      })
      .then((res) => {
        this.setState({
          cesfams: res.data["datos"],
        });
      });
  }

  traerSectores(ces_id) {
    const apiUrl = global.config.modulos.sectores + "por_cesfam/" + ces_id;
    axios.get(apiUrl,{
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        sectores: res.data["datos"],
      });
    });
  }

  traerCondiciones() {
    const apiUrl = global.config.modulos.usuarios + "condiciones/";
    axios.get(apiUrl,{
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        condiciones: res.data["datos"],
      });
    });
  }

  traerDiscapacidades() {
    const apiUrl = global.config.modulos.usuarios + "discapacidades/";
    axios.get(apiUrl,{
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        discapacidades: res.data["datos"],
      });
    });
  }

  traerNacionalidades() {
    const apiUrl = global.config.modulos.usuarios + "nacionalidades/";
    axios.get(apiUrl,{
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        nacionalidades: res.data.datos,
      });
    });
  }

  traerEntidades() {
    const apiUrl = global.config.modulos.usuarios + "entidades/?usp_id=" + this.state.usp_id;
    axios.get(apiUrl,{
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        entidades: res.data.datos,
      });
    });
  }

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre", "apellidoP", "apellidoM"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }

    this.setState({ [name]: value });
  };

  valoresPrematuro = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      let peso = this.state.pesoPrematuro;
      let gestacion = this.state.gestacionPrematuro;

      if (peso > 0 && gestacion > 0) {
        let diasPrematuro = (40 - gestacion) * 7;
        this.setState({
          diasPrematuro: diasPrematuro,
        });

        let clasificacion = "";
        if (peso < 1500 || gestacion <= 31) {
          clasificacion = "Extremo";
        } else if (peso >= 1500 && gestacion >= 34 && gestacion <= 37) {
          clasificacion = "Tardío";
        } else if (peso >= 1500 && gestacion >= 32 && gestacion <= 33) {
          clasificacion = "Moderado";
        }

        this.setState({
          clasificacionPrematuro: clasificacion,
        });
      }
    });
  };

  validarTelefono = (e) => {
    let valorTelefono = "";

    if (e.target.name == "telefono1" && e.target.validity.valid) {
      var telefono = document.getElementById("telefono1");
      var nums_v = telefono.value.match(/\d+/g);
      if (nums_v != null) {
        if (telefono.value.toString().length >= 6) {
          valorTelefono = telefono.value;
        } else {
          valorTelefono = "(+56) ";
        }
      } else {
        valorTelefono = "(+56) ";
      }
      this.setState({ telefono1: valorTelefono });
    } else if (e.target.name == "telefono2" && e.target.validity.valid) {
      var telefono = document.getElementById("telefono2");
      var nums_v = telefono.value.match(/\d+/g);
      if (nums_v != null) {
        if (telefono.value.toString().length >= 1) {
          valorTelefono = telefono.value;
        } else {
          valorTelefono = "";
        }
      } else {
        valorTelefono = "";
      }
      this.setState({ telefono2: valorTelefono });
    }
  };

  validaciones() {
    const { nombre, gestacionPrematuro, semanasEmbarazo, pesoPrematuro, apellidoP, rut, direccion, telefono1, telefono2, correo, fechaNacimiento, sexo, comunaResidencia, cesfamUsuario, sector, situacion, condicionesSeleccionadas, discapacidadesSeleccionadas, fechaParto, diasPrematuro, condicion, discapacidad, asociacionApoderado, rutApoderado, usu_tipo, pasaporte, nacionalidad, asociacionCuidador, rutCuidador, mostrarRutACuidar, rutACuidar } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!nombre) {
      formIsValid = false;
      formErrors["nombreErr"] = "Escribe el nombre.";
    }

    if (!apellidoP) {
      formIsValid = false;
      formErrors["apellidoErr"] = "Escribe el apellido.";
    }

    if (!rut) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut)) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut valido.";
    }

    if (!direccion) {
      formIsValid = false;
      formErrors["direccionErr"] = "Escribe la dirección.";
    }

    if (telefono1.toString().length <= 6 && telefono2 == "") {
      formIsValid = false;
      confirmAlert({
        title: "",
        message: "Debes escribir al menos un télefono de contacto",
        buttons: [
          {
            label: "Aceptar",
            className: "btnAceptar",
          },
        ],
      });
    }

    if (telefono1.toString().length > 6 && telefono1.toString().length < 15) {
      formIsValid = false;
      formErrors["telefono1Err"] = "Escribe el número de celular correcto.";
    }

    if (telefono2 != "" && telefono2.toString().length < 9) {
      formIsValid = false;
      formErrors["telefono2Err"] = "Escribe el número de casa correcto.";
    }

    if (correo) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correo)) {
        formIsValid = false;
        formErrors["correoErr"] = "Verifica el formato del correo.";
      }
    }

    let fechaActual = moment().format("YYYY-MM-DD");
    if (fechaNacimiento > fechaActual) {
      formIsValid = false;
      formErrors["fechaNacimientoErr"] = "La fecha debe ser menor.";
    }

    if (fechaNacimiento < "1900-01-01") {
      formIsValid = false;
      formErrors["fechaNacimientoErr"] = "La fecha debe ser mayor.";
    }

    if (!fechaNacimiento) {
      formIsValid = false;
      formErrors["fechaNacimientoErr"] = "Escribe la Fecha de Nacimiento.";
    }

    if (sexo === "") {
      formIsValid = false;
      formErrors["sexoErr"] = "Selecciona el sexo del usuario.";
    }

    if (comunaResidencia === "") {
      formIsValid = false;
      formErrors["comunaResidenciaErr"] = "Selecciona la comuna de residencia del usuario.";
    }

    if (cesfamUsuario === "") {
      formIsValid = false;
      formErrors["cesfamErr"] = "Selecciona el cesfam del usuario.";
    }

    if (sector === "") {
      formIsValid = false;
      formErrors["sectorErr"] = "Selecciona el sector del usuario.";
    }

    if (situacion === "") {
      formIsValid = false;
      formErrors["situacionErr"] = "Selecciona la situación en el cesfam del usuario.";
    }

    if (sexo === "M" && condicionesSeleccionadas.includes(3)) {
      formIsValid = false;
      formErrors["condicionErr"] = "Un paciente masculino no puede tener la condición de embarazada.";
    }

    if (condicionesSeleccionadas.includes(5) && discapacidadesSeleccionadas.length == 0) {
      formIsValid = false;
      formErrors["discapacidadErr"] = "Selecciona la discapacidad del paciente.";
    }
    if (condicionesSeleccionadas.includes(5) && asociacionApoderado == "" && asociacionApoderado != "0") {
      formIsValid = false;
      formErrors["asociacionApoErr"] = "Selecciona si deseas asociar a un apoderado.";
    }

    if (condicionesSeleccionadas.includes(3) && semanasEmbarazo == 0) {
      formIsValid = false;
      formErrors["embarazadaErr"] = "Escriba las semanas de embarazo.";
    }
    if (condicionesSeleccionadas.includes(3) && semanasEmbarazo > 40) {
      formIsValid = false;
      formErrors["embarazadaErr"] = "Las semanas de embarazo no deben exceder las 40 semanas.";
    }
    if (condicionesSeleccionadas.includes(2) && (gestacionPrematuro == "" || gestacionPrematuro == 0 || gestacionPrematuro == null || gestacionPrematuro == undefined)) {
      formIsValid = false;
      formErrors["prematuroErr"] = "Escribe las semanas de gestación.";
    }
    if (condicionesSeleccionadas.includes(2) && (pesoPrematuro == "" || pesoPrematuro == 0 || pesoPrematuro == null || pesoPrematuro == undefined)) {
      formIsValid = false;
      formErrors["prematuroErr"] = "Escribe el peso al nacer.";
    }

    if (condicionesSeleccionadas.includes(2) && gestacionPrematuro >= 38) {
      formIsValid = false;
      formErrors["prematuroErr"] = "La gestación de prematuro no debe exceder las 37 semanas.";
    }

    if (asociacionApoderado == 1 && rutApoderado == "") {
      formIsValid = false;
      formErrors["rutApoErr"] = "Escribe el rut del apoderado.";
    } else if (asociacionApoderado == 1 && !chileanRut.validate(rutApoderado)) {
      formIsValid = false;
      formErrors["rutApoErr"] = "Escribe el rut valido.";
    }

    if (condicionesSeleccionadas.includes(5) && asociacionCuidador == "" && asociacionCuidador != "0") {
      formIsValid = false;
      formErrors["asociacionCuiErr"] = "Selecciona si deseas asociar a un cuidador.";
    }

    if (asociacionCuidador == 1) {
      if (rutCuidador == "") {
        formIsValid = false;
        formErrors["rutCuiErr"] = "Escribe el rut del cuidador.";
      } else if (!chileanRut.validate(rutCuidador)) {
        formIsValid = false;
        formErrors["rutCuiErr"] = "Escribe el rut valido.";
      } else if (chileanRut.format(rut, true) == chileanRut.format(rutCuidador, true)) {
        formIsValid = false;
        formErrors["rutCuiErr"] = "El rut del paciente no puede ser igual al del cuidador.";
      }
    }

    if (mostrarRutACuidar) {
      if (rutACuidar == "") {
        formIsValid = false;
        formErrors["rutACuiErr"] = "Escribe el rut del paciente a cuidar.";
      } else if (!chileanRut.validate(rutACuidar)) {
        formIsValid = false;
        formErrors["rutACuiErr"] = "Escribe el rut valido.";
      } else if (chileanRut.format(rut, true) == chileanRut.format(rutACuidar, true)) {
        formIsValid = false;
        formErrors["rutACuiErr"] = "El rut del paciente no puede ser igual al paciente que cuida.";
      }
    }

    if (usu_tipo == 2 && pasaporte == "") {
      formIsValid = false;
      formErrors["pasaporteErr"] = "Escribe el pasaporte o rut provisorio.";
    }

    if ((usu_tipo == 2 || this.state.cesfam == 76) && nacionalidad == "") {
      formIsValid = false;
      formErrors["nacionalidadErr"] = "Selecciona la nacionalidad.";
    }

    if (this.state.cesfam == 76) {
      if (this.state.entidad == "") {
        formIsValid = false;
        formErrors["entidadErr"] = "Selecciona la entidad.";
      }

      if (this.state.puebloOriginario == "" && this.state.puebloOriginario != "0") {
        formIsValid = false;
        formErrors["puebloOriginarioErr"] = "Selecciona si pertenece a un pueblo originario.";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        rut: this.state.rut,
        canal: this.state.canal,
        cesfam: this.state.cesfam,
      };
      let apiUrl = global.config.modulos.usuarios + "por_rut_verificar/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        var existe = res.data.estado;
        if (existe) {
          var rut = res.data.datos.usu_rut;

          if (rut != this.state.rutOriginal) {
            this.mostrarAlertaExiste("El rut ya existe.");
          } else {
            var telefono1 = "";
            var telefono2 = "";

            if (this.state.telefono1.toString().length == 15) {
              telefono1 = this.state.telefono1;
            }

            if (this.state.telefono2.toString().length == 9) {
              telefono2 = this.state.telefono2;
            }

            var nacionalidad = this.state.nacionalidad;
            if (nacionalidad == "") {
              nacionalidad = "Chilena";
            } else {
              nacionalidad = nacionalidad.uns_nacionalidad;
            }

            var nro_ficha = this.state.nro_ficha;
            if (nro_ficha == 0) {
              nro_ficha = null;
            }

            var nro_carpeta = this.state.nro_carpeta;
            if (nro_carpeta == 0) {
              nro_carpeta = null;
            }

            var pasaporte = this.state.pasaporte;

            var data = {
              usuario: this.state.usu_id,

              condiciones: this.state.condicionesSeleccionadas,
              discapacidades: this.state.discapacidadesSeleccionadas,

              condicionesCui: this.state.condicionesSeleccionadasCui,
              discapacidadesCui: this.state.discapacidadesSeleccionadasCui,

              fecha_parto: this.state.fechaParto,
              dias_prematuro: this.state.diasPrematuro,
              peso_prematuro: this.state.pesoPrematuro,
              clasificacion_prematuro: this.state.clasificacionPrematuro,
              gestacion_prematuro: this.state.gestacionPrematuro,
              usp_id: this.state.usp_id,
              canal: 5,
              datos: {
                usu_nombre: this.state.nombre,
                usu_apellido_p: this.state.apellidoP,
                usu_apellido_m: this.state.apellidoM,
                usu_rut: this.state.rut,
                usu_direccion: this.state.direccion,
                usu_telefono1: telefono1,
                usu_telefono2: telefono2,
                usu_fecha_nacimiento: this.state.fechaNacimiento,
                usu_nombre_social: this.state.nombreSocial,
                usu_correo_electronico: this.state.correo,
                usu_estado: 1,
                usu_sexo: this.state.sexo,
                usu_condicion: 1,
                usu_situacion: this.state.situacion,
                usu_discapacidad: 1,

                usu_apoderado: this.state.asociacionApoderado,
                usu_rut_apoderado: this.state.rutApoderado,

                usu_comuna_direccion: this.state.comunaResidencia.com_nombre,
                usu_cesfam: this.state.cesfamUsuario,
                usu_sector: this.state.sector,
                usu_nacionalidad: nacionalidad,
                usu_pasaporte: pasaporte,

                asociacionCuidador: this.state.asociacionCuidador,
                rutCuidador: this.state.rutCuidador,

                rutACuidar: this.state.rutACuidar,

                usu_ficha: nro_ficha,
                usu_carpeta: nro_carpeta,

                usu_estratificacion: this.state.estratificacion == 0 ? null : this.state.estratificacion,

                entidad: this.state.entidad,
                pueblo_originario: this.state.puebloOriginario,
              },
            };
            let apiUrl = global.config.modulos.usuarios + "actualizar_registro_usuario/";
            const options = {
              method: "PUT",
              data: data,
              headers: authHeader(),
            };
            axios(apiUrl, options).then((res) => {
              var data = res.data;

              var estado = data.estado;
              if (estado) {
                NotificationManager.success("Modificado con éxito");

                this.salir();
              } else {
                this.mostrarAlertaExiste(res.data.mensaje);
              }
            });
          }
        }
      });
    }
  };

  mostrarAlertaExiste(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  traerDatosUsuario() {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usu_id;
    axios(apiUrl,{
      headers: authHeader(),
    }).then((res) => {
      var telefono1 = res.data["datos"]["usu_telefono1"];
      if (telefono1) {
        telefono1 = telefono1.substring(2);
        telefono1 = "(+56) " + telefono1;
      } else {
        telefono1 = "(+56) ";
      }

      var telefono2 = res.data["datos"]["usu_telefono2"];
      if (telefono2) {
        telefono2 = telefono2;
      } else {
        telefono2 = "";
      }

      var estratificacion = res.data["datos"]["usu_estratificacion"];
      if (estratificacion == null) {
        estratificacion = 0;
      }

      var nombreSocial = res.data["datos"]["usu_nombre_social"];
      if (nombreSocial == null) {
        nombreSocial = "";
      }

      var nacionalidad = res.data["datos"]["usu_nacionalidad"];
      if (nacionalidad == null) {
        nacionalidad = "";
      } else {
        nacionalidad = { uns_id: 0, uns_nacionalidad: nacionalidad };
      }

      var comunaResidencia = res.data["datos"]["usu_comuna_direccion"];
      if (comunaResidencia == null) {
        comunaResidencia = "";
      } else {
        comunaResidencia = { com_id: 0, com_nombre: comunaResidencia, com_provincia_id: 0, com_ciudad: 0 };
      }

      var pasaporte = res.data["datos"]["usu_pasaporte"];
      if (pasaporte == null) {
        pasaporte = "";
      }

      var nro_ficha = res.data["datos"]["usu_ficha"];
      if (nro_ficha == null) {
        nro_ficha = 0;
      }

      var nro_carpeta = res.data["datos"]["usu_carpeta"];
      if (nro_carpeta == null) {
        nro_carpeta = 0;
      }

      let diasPrematuro = null;
      let pesoPrematuro = null;
      let clasificacionPrematuro = null;
      let gestacionPrematuro = null;
      if (res.data["datos"]["datos_prematuro"] != null) {
        diasPrematuro = res.data["datos"]["datos_prematuro"]["usp_dias"];
        pesoPrematuro = res.data["datos"]["datos_prematuro"]["usp_peso"];
        clasificacionPrematuro = res.data["datos"]["datos_prematuro"]["usp_clasificacion"];
        gestacionPrematuro = res.data["datos"]["datos_prematuro"]["usp_gestacion"];
      }

      this.setState({
        nombre: res.data["datos"]["usu_nombre"],
        apellidoP: res.data["datos"]["usu_apellido_p"],
        apellidoM: res.data["datos"]["usu_apellido_m"],
        rut: res.data["datos"]["usu_rut"],
        rutOriginal: res.data["datos"]["usu_rut"],
        direccion: res.data["datos"]["usu_direccion"],
        telefono1: telefono1,
        telefono2: telefono2,
        correo: res.data["datos"]["usu_correo_electronico"],
        fechaNacimiento: res.data["datos"]["usu_fecha_nacimiento"],
        sexo: res.data["datos"]["usu_sexo"],
        condicion: res.data["datos"]["usu_condicion"],
        situacion: res.data["datos"]["usu_situacion"],
        discapacidad: res.data["datos"]["usu_discapacidad"],

        asociacionApoderado: res.data["datos"]["usu_apoderado"],
        rutApoderado: res.data["datos"]["usu_rut_apoderado"],

        comunaResidencia: comunaResidencia,
        cesfamUsuario: res.data["datos"]["usu_cesfam"],
        sector: res.data["datos"]["usu_sector"],
        estratificacion: estratificacion,
        nombreSocial: nombreSocial,
        usu_tipo: res.data["datos"]["usu_tipo"],
        nacionalidad: nacionalidad,
        pasaporte: pasaporte,

        condicionesSeleccionadas: res.data["datos"]["ids_condiciones"],
        discapacidadesSeleccionadas: res.data["datos"]["ids_discapacidades"],

        fechaParto: res.data["datos"]["fecha_parto"],
        semanasEmbarazo: res.data["datos"]["fecha_parto"] ? this.calcularSemanasDesdeFecha(res.data["datos"]["fecha_parto"]) : 0,
        diasPrematuro: diasPrematuro,
        pesoPrematuro: pesoPrematuro,
        clasificacionPrematuro: clasificacionPrematuro,
        gestacionPrematuro: gestacionPrematuro,

        asociacionCuidador: res.data["datos"]["asociacionCuidador"],
        rutCuidador: res.data["datos"]["rutCuidador"],

        condicionesSeleccionadasCui: res.data["datos"]["ids_condiciones_cui"],
        discapacidadesSeleccionadasCui: res.data["datos"]["ids_discapacidades_cui"],

        rutACuidar: res.data["datos"]["rutACuidar"],

        nro_ficha: nro_ficha,
        nro_carpeta: nro_carpeta,

        entidad: res.data["datos"]["entidad"],
        puebloOriginario: res.data["datos"]["puebloOriginario"],

        open: true,
      });

      if (res.data["datos"]["ids_condiciones"].includes(5)) {
        this.setState({
          mostrarDiscapacidad: true,
        });
        if (res.data["datos"]["usu_apoderado"] == 1) {
          this.setState({
            mostrarApoderado: true,
          });
        } else {
          this.setState({
            mostrarApoderado: false,
            rutApoderado: "",
          });
        }

        if (res.data["datos"]["asociacionCuidador"] == 1) {
          this.setState({
            mostrarCuidadorRut: true,
          });
        } else {
          this.setState({
            mostrarCuidadorRut: false,
            rutCuidador: "",
          });
        }
      } else {
        this.setState({
          mostrarDiscapacidad: false,
          discapacidad: 1,
        });
      }

      if (res.data["datos"]["ids_condiciones"].includes(9)) {
        this.setState({
          mostrarRutACuidar: true,
        });
      } else {
        this.setState({
          mostrarRutACuidar: false,
        });
      }

      if (res.data["datos"]["ids_condiciones"].includes(3)) {
        this.setState({
          mostrarEmbarazo: true,
        });
      } else {
        this.setState({
          mostrarEmbarazo: false,
        });
      }

      if (res.data["datos"]["ids_condiciones"].includes(2)) {
        this.setState({
          mostrarPrematuro: true,
        });
      } else {
        this.setState({
          mostrarPrematuro: false,
        });
      }

      if (res.data["datos"]["usuarioACuidar"]) {
        this.setState({
          mostrarCondicionCui: true,
        });
      }

      if (res.data["datos"]["ids_condiciones_cui"].length > 0) {
        if (res.data["datos"]["ids_condiciones_cui"].includes(5)) {
          this.setState({
            mostrarDiscapacidadCui: true,
          });
        } else {
          this.setState({
            mostrarDiscapacidadCui: false,
            discapacidadCui: 1,
          });
        }
      }

      this.traerSectores(res.data["datos"]["usu_cesfam"]);
    });
  }

  guardarDiscapacidad = (event) => {
    let discapacidades = this.state.discapacidadesSeleccionadas;
    let valor = event.target.value;

    if (valor == null || valor == NaN) {
      return;
    }
    valor = parseInt(valor);

    if (discapacidades.includes(valor)) {
      discapacidades = this.removeItemOnce(discapacidades, valor);
    } else {
      discapacidades.push(valor);
    }

    this.setState(
      {
        discapacidadesSeleccionadas: discapacidades,
      },
      () => {
        console.log(this.state.discapacidadesSeleccionadas);
      }
    );
  };

  guardarCondicion = (event) => {
    let condiciones = this.state.condicionesSeleccionadas;
    let valor = event.target.value;

    if (valor == null || valor == NaN) {
      return;
    }
    valor = parseInt(valor);

    if (condiciones.includes(valor)) {
      condiciones = this.removeItemOnce(condiciones, valor);
    } else {
      condiciones.push(valor);
    }

    this.setState(
      {
        condicionesSeleccionadas: condiciones,
      },
      () => {
        if (condiciones.includes(9)) {
          this.setState({
            mostrarRutACuidar: true,
          });
        } else {
          this.setState({
            mostrarRutACuidar: false,
            rutACuidar: "",
          });
        }

        if (condiciones.includes(5)) {
          this.setState({
            mostrarDiscapacidad: true,
          });
        } else {
          this.setState({
            mostrarDiscapacidad: false,
            mostrarApoderado: false,
            discapacidadesSeleccionadas: [],

            discapacidad: 1,

            asociacionApoderado: 0,
            rutApoderado: "",

            asociacionCuidador: 0,
            rutCuidador: "",
          });
        }

        if (condiciones.includes(3)) {
          this.setState({
            mostrarEmbarazo: true,
          });
        } else {
          this.setState({
            mostrarEmbarazo: false,
            fechaParto: "",
          });
        }
        if (condiciones.includes(2)) {
          this.setState({
            mostrarPrematuro: true,
          });
        } else {
          this.setState({
            mostrarPrematuro: false,
            diasPrematuro: 0,
          });
        }
      }
    );
  };

  guardarCondicionCui = (event) => {
    let condiciones = this.state.condicionesSeleccionadasCui;
    let valor = event.target.value;

    if (valor == null || valor == NaN) {
      return;
    }
    valor = parseInt(valor);

    if (condiciones.includes(valor)) {
      condiciones = this.removeItemOnce(condiciones, valor);
    } else {
      condiciones.push(valor);
    }

    this.setState(
      {
        condicionesSeleccionadasCui: condiciones,
      },
      () => {
        if (condiciones.includes(5)) {
          this.setState({
            mostrarDiscapacidadCui: true,
          });
        } else {
          this.setState({
            mostrarDiscapacidadCui: false,
            discapacidadCui: 1,
            discapacidadesSeleccionadasCui: [],
          });
        }
      }
    );
  };

  guardarDiscapacidadCui = (event) => {
    let discapacidades = this.state.discapacidadesSeleccionadasCui;
    let valor = event.target.value;

    if (valor == null || valor == NaN) {
      return;
    }
    valor = parseInt(valor);

    if (discapacidades.includes(valor)) {
      discapacidades = this.removeItemOnce(discapacidades, valor);
    } else {
      discapacidades.push(valor);
    }

    this.setState(
      {
        discapacidadesSeleccionadasCui: discapacidades,
      },
      () => {
        console.log(this.state.discapacidadesSeleccionadasCui);
      }
    );
  };

  removeItemOnce = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  cambiarValorFechaParto = (e) => {
    let { name, value } = e.target;

    let fechaDeParto = this.agregarSemanasAFechaActual(value);
    console.log(fechaDeParto);
    this.setState({
      fechaParto: fechaDeParto,
      semanasEmbarazo: value,
    });
  };

  agregarSemanasAFechaActual = (semanas) => {
    let diff_semanas = parseInt(40 - semanas);
    console.log(diff_semanas);
    const fechaActual = moment();
    const fechaResultante = fechaActual.add(diff_semanas, "weeks");
    const fechaFormateada = fechaResultante.format("YYYY-MM-DD");
    return fechaFormateada;
  };

  calcularSemanasDesdeFecha = (fechaDada) => {
    const fechaDadaMoment = moment(fechaDada);
    let fechaActual = moment();
    fechaActual = fechaActual.subtract(1, "days");
    fechaActual = fechaActual.format("YYYY-MM-DD");
    let semanas = fechaDadaMoment.diff(fechaActual, "weeks");
    console.log(semanas);
    if (40 - semanas < 0) {
      semanas = 0;
    } else {
      semanas = 40 - semanas;
    }
    return semanas;
  };

  render() {
    const { nombreErr, apellidoErr, rutErr, direccionErr, telefono1Err, telefono2Err, correoErr, fechaNacimientoErr, sexoErr, comunaResidenciaErr, cesfamErr, sectorErr, situacionErr, condicionErr, discapacidadErr, embarazadaErr, prematuroErr, condicionesSeleccionadas, discapacidadesSeleccionadas, asociacionApoErr, rutApoErr, pasaporteErr, nacionalidadErr, asociacionCuiErr, rutCuiErr, rutACuiErr, entidadErr, puebloOriginarioErr } = this.state.formErrors;
    const { sectores, cesfams, comunasResidencia, condiciones, discapacidades, herramientas, nacionalidades } = this.state;

    const cambioCesfam = (event) => {
      this.setState({
        cesfamUsuario: event.target.value,
        sector: "",
      });

      this.traerSectores(event.target.value);
    };

    const cambioAsociacion = (event) => {
      this.setState({
        asociacionApoderado: event.target.value,
      });

      if (event.target.value == 1) {
        this.setState({
          mostrarApoderado: true,
        });
      } else {
        this.setState({
          mostrarApoderado: false,
        });
      }
    };

    const cambioAsociacionCui = (event) => {
      this.setState({
        asociacionCuidador: event.target.value,
      });

      if (event.target.value == 1) {
        this.setState({
          mostrarCuidadorRut: true,
        });
      } else {
        this.setState({
          mostrarCuidadorRut: false,
        });
      }
    };

    const cambiarNacionalidad = (nacionalidad) => {
      this.setState({
        nacionalidad: nacionalidad,
      });
    };

    const cambiarComuna = (comuna) => {
      this.setState({
        comunaResidencia: comuna,
      });
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <h2>Modificar</h2>

          <div style={{ marginTop: 20 }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <InputLabel>Nombre</InputLabel>
                    <input type="text" name="nombre" value={this.state.nombre} onChange={this.cambiarValores} placeholder="" className={nombreErr ? "inputText showError" : "inputText"} />
                    {nombreErr && <div style={{ color: "red", paddingBottom: 10 }}>{nombreErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Apellido Paterno</InputLabel>
                    <input type="text" name="apellidoP" value={this.state.apellidoP} onChange={this.cambiarValores} placeholder="" className={apellidoErr ? "inputText showError" : "inputText"} />
                    {apellidoErr && <div style={{ color: "red", paddingBottom: 10 }}>{apellidoErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Apellido Materno</InputLabel>
                    <input type="text" name="apellidoM" value={this.state.apellidoM} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Rut</InputLabel>
                    <input type="text" id="rut" name="rut" value={this.state.rut} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={rutErr ? "inputText showError" : "inputText"} maxLength={12} />
                    {rutErr && <div style={{ color: "red", paddingBottom: 10 }}>{rutErr}</div>}
                  </Grid>

                  {this.state.usu_tipo == 2 && (
                    <Grid item xs={6}>
                      <InputLabel>NIP o Pasaporte o Rut provisorio</InputLabel>
                      <input type="text" id="pasaporte" name="pasaporte" value={this.state.pasaporte} onChange={this.cambiarValores} placeholder="" className={pasaporteErr ? "inputText showError" : "inputText"} />
                      {pasaporteErr && <div style={{ color: "red", paddingBottom: 10 }}>{pasaporteErr}</div>}
                    </Grid>
                  )}

                  {(this.state.usu_tipo == 2 || this.state.cesfam == 76) && (
                    <Grid item xs={6}>
                      <InputLabel>Nacionalidad</InputLabel>
                      <Autocomplete
                        id="nacionalidad"
                        name="nacionalidad"
                        onChange={function (event, res) {
                          if (res != null) {
                            cambiarNacionalidad(res);
                          } else {
                            cambiarNacionalidad("");
                          }
                        }}
                        value={this.state.nacionalidad}
                        options={Object.values(this.state.nacionalidades)}
                        getOptionLabel={(option) => option.uns_nacionalidad}
                        filterOptions={createFilterOptions({
                          stringify: (option) => option.uns_nacionalidad,
                        })}
                        renderInput={(params) => <TextField {...params} />}
                      ></Autocomplete>

                      {nacionalidadErr && <div style={{ color: "red", paddingBottom: 10 }}>{nacionalidadErr}</div>}
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <InputLabel>Dirección</InputLabel>
                    <input type="text" name="direccion" value={this.state.direccion} onChange={this.cambiarValores} placeholder="" className={direccionErr ? "inputText showError" : "inputText"} />
                    {direccionErr && <div style={{ color: "red", paddingBottom: 10 }}>{direccionErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Comuna (residencia)*</InputLabel>
                    <Autocomplete
                      id="comunaResidencia"
                      name="comunaResidencia"
                      onChange={function (event, res) {
                        if (res != null) {
                          cambiarComuna(res);
                        } else {
                          cambiarComuna("");
                        }
                      }}
                      value={this.state.comunaResidencia}
                      options={Object.values(this.state.comunasResidencia)}
                      getOptionLabel={(option) => option.com_nombre}
                      filterOptions={createFilterOptions({
                        stringify: (option) => option.com_nombre,
                      })}
                      renderInput={(params) => <TextField {...params} />}
                    ></Autocomplete>

                    {comunaResidenciaErr && <div style={{ color: "red", paddingBottom: 10 }}>{comunaResidenciaErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Teléfono celular</InputLabel>
                    <input type="text" id="telefono1" name="telefono1" value={this.state.telefono1} onChange={this.validarTelefono} placeholder="" className={telefono1Err ? "inputText showError" : "inputText"} maxLength={15} pattern="^-?[0-9+() ]*\d*\.?\d*$" />
                    {telefono1Err && <div style={{ color: "red", paddingBottom: 10 }}>{telefono1Err}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Teléfono casa</InputLabel>
                    <input type="text" id="telefono2" name="telefono2" value={this.state.telefono2} onChange={this.validarTelefono} placeholder="" className={telefono2Err ? "inputText showError" : "inputText"} maxLength={9} pattern="^-?[0-9+() ]*\d*\.?\d*$" />
                    {telefono2Err && <div style={{ color: "red", paddingBottom: 10 }}>{telefono2Err}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Correo electrónico</InputLabel>
                    <input type="text" id="correo" name="correo" value={this.state.correo} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                    {correoErr && <div style={{ color: "red", paddingBottom: 10 }}>{correoErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Nombre social</InputLabel>
                    <input type="text" id="nombreSocial" name="nombreSocial" value={this.state.nombreSocial} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Fecha de Nacimiento</InputLabel>
                    <TextField
                      id="fechaNacimiento"
                      name="fechaNacimiento"
                      type="date"
                      value={this.state.fechaNacimiento}
                      onChange={this.cambiarValores}
                      InputProps={{
                        inputProps: { min: "1900-01-01", max: this.state.hoy },
                      }}
                      className={fechaNacimientoErr ? " showError" : ""}
                    />

                    {fechaNacimientoErr && <div style={{ color: "red", paddingBottom: 10 }}>{fechaNacimientoErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Sexo</InputLabel>
                    <Select id="sexo" name="sexo" value={this.state.sexo} onChange={this.cambiarValores} displayEmpty className={sexoErr ? " showError" : ""} style={{ width: "100%" }}>
                      <MenuItem value="" disabled>
                        <em>Seleccione sexo</em>
                      </MenuItem>

                      <MenuItem value="M" key={"1"}>
                        Masculino
                      </MenuItem>
                      <MenuItem value="F" key={"2"}>
                        Femenino
                      </MenuItem>
                      <MenuItem value="I" key={"3"}>
                        Intersex
                      </MenuItem>
                    </Select>
                    {sexoErr && <div style={{ color: "red", paddingBottom: 10 }}>{sexoErr}</div>}
                  </Grid>

                  {herramientas.includes("H3") && (
                    <Grid item xs={6}>
                      <InputLabel>Cesfam</InputLabel>
                      <Select id="cesfam" name="cesfam" value={this.state.cesfamUsuario} onChange={cambioCesfam} displayEmpty className={cesfamErr ? " showError" : ""} style={{ width: "100%" }}>
                        <MenuItem value="" disabled>
                          <em>Seleccione cesfam</em>
                        </MenuItem>

                        {cesfams &&
                          cesfams.map((ces, index) => (
                            <MenuItem value={ces.ces_id} key={index}>
                              {ces.ces_nombre}
                            </MenuItem>
                          ))}
                      </Select>
                      {cesfamErr && <div style={{ color: "red", paddingBottom: 10 }}>{cesfamErr}</div>}
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <InputLabel>Sector</InputLabel>
                    <Select id="sector" name="sector" value={this.state.sector} onChange={this.cambiarValores} displayEmpty className={sectorErr ? " showError" : ""} style={{ width: "100%" }}>
                      <MenuItem value="" disabled>
                        <em>Seleccione sector</em>
                      </MenuItem>
                      {sectores &&
                        sectores.map((sec, index) => (
                          <MenuItem value={sec.sec_id} key={index}>
                            {sec.sec_nombre}
                          </MenuItem>
                        ))}
                    </Select>
                    {sectorErr && <div style={{ color: "red", paddingBottom: 10 }}>{sectorErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Situación en el cesfam</InputLabel>
                    <Select id="situacion" name="situacion" value={this.state.situacion} onChange={this.cambiarValores} displayEmpty className={situacionErr ? " showError" : ""} style={{ width: "100%" }}>
                      <MenuItem value="" disabled>
                        <em>Seleccione situación</em>
                      </MenuItem>

                      <MenuItem value="0" key={"0"}>
                        Adscrito
                      </MenuItem>
                      <MenuItem value="1" key={"1"}>
                        Inscrito
                      </MenuItem>
                    </Select>
                    {situacionErr && <div style={{ color: "red", paddingBottom: 10 }}>{situacionErr}</div>}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Estratificación</InputLabel>
                    <Select id="estratificacion" name="estratificacion" value={this.state.estratificacion} onChange={this.cambiarValores} displayEmpty style={{ width: "100%" }}>
                      <MenuItem value={0}>Sin estratificación</MenuItem>
                      <MenuItem value={1}>G1</MenuItem>
                      <MenuItem value={2}>G2</MenuItem>
                      <MenuItem value={3}>G3</MenuItem>
                    </Select>
                  </Grid>

                  {this.state.herramientas.includes("H45") && (
                    <>
                      <Grid item xs={6}>
                        <InputLabel>N° de Ficha</InputLabel>
                        <input type="number" name="nro_ficha" value={this.state.nro_ficha} min={0} onChange={this.cambiarValores} className={"inputText"} />
                      </Grid>

                      <Grid item xs={6}>
                        <InputLabel>N° de la Carpeta</InputLabel>
                        <input type="number" name="nro_carpeta" value={this.state.nro_carpeta} min={0} onChange={this.cambiarValores} className={"inputText"} />
                      </Grid>
                    </>
                  )}

                  {this.state.cesfam == 76 && (
                    <>
                      <Grid item xs={6}>
                        <InputLabel>Entidad*</InputLabel>
                        <Select id="entidad" name="entidad" value={this.state.entidad} onChange={this.cambiarValores} displayEmpty style={{ width: "100%" }}>
                          <MenuItem value="" disabled>
                            <em>Seleccione entidad</em>
                          </MenuItem>

                          {this.state.entidades &&
                            this.state.entidades.map((ent, index) => (
                              <MenuItem value={ent.ues_id} key={index}>
                                {ent.ues_nombre}
                              </MenuItem>
                            ))}
                        </Select>
                        {entidadErr && <div style={{ color: "red", paddingBottom: 10 }}>{entidadErr}</div>}
                      </Grid>
                    </>
                  )}

                  {this.state.cesfam == 76 && (
                    <Grid item xs={6}>
                      <InputLabel>¿Pertenece a un Pueblo Originario?</InputLabel>
                      <Select id="puebloOriginario" name="puebloOriginario" value={this.state.puebloOriginario} onChange={this.cambiarValores} displayEmpty className={puebloOriginarioErr ? " showError" : ""} style={{ width: "100%" }}>
                        <MenuItem value="" disabled>
                          <em>Seleccione</em>
                        </MenuItem>

                        <MenuItem value="0" key={"0"}>
                          No
                        </MenuItem>
                        <MenuItem value="1" key={"1"}>
                          Sí
                        </MenuItem>
                      </Select>
                      {puebloOriginarioErr && <div style={{ color: "red", paddingBottom: 10 }}>{puebloOriginarioErr}</div>}
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel component="legend">Condiciones</FormLabel>
                      <FormGroup style={{ display: "inline" }}>
                        {condiciones &&
                          condiciones.map((con, index) => {
                            if (con.con_id == 1) {
                              //validacion temporal ya que no se puede quitar de momento la condicion "ninguna"
                              return false;
                            }
                            return <FormControlLabel key={index} control={<Checkbox value={con.con_id} onClick={(e) => this.guardarCondicion(e)} checked={this.state.condicionesSeleccionadas.includes(con.con_id) ? true : false} name={con.con_nombre} />} label={con.con_nombre} />;
                          })}
                      </FormGroup>
                    </FormControl>
                    {condicionErr && <div style={{ color: "red", paddingBottom: 10 }}>{condicionErr}</div>}
                  </Grid>

                  {this.state.mostrarDiscapacidad ? (
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel component="legend">Discapacidad</FormLabel>
                        <FormGroup style={{ display: "inline" }}>
                          {discapacidades &&
                            discapacidades.map((dis, index) => {
                              if (dis.dis_id == 1) {
                                //validacion temporal ya que no se puede quitar de momento la discapacidad "ninguna"
                                return false;
                              }
                              return <FormControlLabel key={index} control={<Checkbox value={dis.dis_id} onClick={(e) => this.guardarDiscapacidad(e)} checked={this.state.discapacidadesSeleccionadas.includes(dis.dis_id) ? true : false} name={dis.dis_nombre} />} label={dis.dis_nombre} />;
                            })}
                        </FormGroup>
                      </FormControl>
                      {discapacidadErr && <div style={{ color: "red", paddingBottom: 10 }}>{discapacidadErr}</div>}
                    </Grid>
                  ) : null}

                  {this.state.mostrarDiscapacidad ? (
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                      <Grid container spacing={3}>
                        {this.state.mostrarDiscapacidad ? (
                          <Grid item xs={6}>
                            <InputLabel>Asociar apoderado</InputLabel>
                            <Select id="asociacionApoderado" name="asociacionApoderado" value={this.state.asociacionApoderado} onChange={cambioAsociacion} displayEmpty className={asociacionApoErr ? " showError" : ""}>
                              <MenuItem value="" disabled>
                                <em>Seleccione</em>
                              </MenuItem>

                              <MenuItem value="0" key={"0"}>
                                No
                              </MenuItem>
                              <MenuItem value="1" key={"1"}>
                                Sí
                              </MenuItem>
                            </Select>
                            {asociacionApoErr && <div style={{ color: "red", paddingBottom: 10 }}>{asociacionApoErr}</div>}
                          </Grid>
                        ) : null}

                        {this.state.mostrarApoderado ? (
                          <Grid item xs={6}>
                            <InputLabel>Rut apoderado</InputLabel>
                            <input type="text" id="rutApoderado" name="rutApoderado" value={this.state.rutApoderado} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={rutApoErr ? "inputText showError" : "inputText"} maxLength={12} />
                            {rutApoErr && <div style={{ color: "red", paddingBottom: 10 }}>{rutApoErr}</div>}
                          </Grid>
                        ) : null}
                      </Grid>
                    </GridItem>
                  ) : null}

                  {this.state.mostrarDiscapacidad ? (
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                      <Grid container spacing={3}>
                        {this.state.mostrarDiscapacidad ? (
                          <Grid item xs={6}>
                            <InputLabel>¿Tiene cuidador?</InputLabel>
                            <Select id="asociacionCuidador" name="asociacionCuidador" value={this.state.asociacionCuidador} onChange={cambioAsociacionCui} displayEmpty className={asociacionCuiErr ? " showError" : ""}>
                              <MenuItem value="" disabled>
                                <em>Seleccione</em>
                              </MenuItem>

                              <MenuItem value="0" key={"0"}>
                                No
                              </MenuItem>
                              <MenuItem value="1" key={"1"}>
                                Sí
                              </MenuItem>
                            </Select>
                            {asociacionCuiErr && <div style={{ color: "red", paddingBottom: 10 }}>{asociacionCuiErr}</div>}
                          </Grid>
                        ) : null}

                        {this.state.mostrarCuidadorRut ? (
                          <Grid item xs={6}>
                            <InputLabel>Rut cuidador</InputLabel>
                            <input type="text" id="rutCuidador" name="rutCuidador" value={this.state.rutCuidador} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={rutCuiErr ? "inputText showError" : "inputText"} maxLength={12} />
                            {rutCuiErr && <div style={{ color: "red", paddingBottom: 10 }}>{rutCuiErr}</div>}
                          </Grid>
                        ) : null}
                      </Grid>
                    </GridItem>
                  ) : null}

                  {this.state.mostrarEmbarazo ? (
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel component="legend">Embarazada</FormLabel>
                        <label style={{ marginTop: 20 }}>Semanas de embarazo</label>
                        <input type="number" id="semanasEmbarazo" name="semanasEmbarazo" value={this.state.semanasEmbarazo} onChange={this.cambiarValorFechaParto} className={embarazadaErr ? "inputText showError" : "inputText"} />
                        {embarazadaErr && <div style={{ color: "red", paddingBottom: 10 }}>{embarazadaErr}</div>}
                      </FormControl>
                    </Grid>
                  ) : null}

                  {this.state.mostrarPrematuro ? (
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel component="legend">Prematuro</FormLabel>
                        <label style={{ marginTop: 20 }}>Semanas de gestación</label>
                        <input type="number" id="gestacionPrematuro" name="gestacionPrematuro" value={this.state.gestacionPrematuro} onChange={this.valoresPrematuro} className={prematuroErr ? "inputText showError" : "inputText"} />
                        <label style={{ marginTop: 20 }}>Peso al Nacer (grs)</label>
                        <input type="number" id="pesoPrematuro" name="pesoPrematuro" value={this.state.pesoPrematuro} onChange={this.valoresPrematuro} className={prematuroErr ? "inputText showError" : "inputText"} />
                        <label style={{ marginTop: 20 }}>Clasificación</label>
                        <input key={this.state.clasificacionPrematuro} type="text" readOnly id="clasificacionPrematuro" name="clasificacionPrematuro" value={this.state.clasificacionPrematuro} className={prematuroErr ? "inputText showError" : "inputText"} />
                        {prematuroErr && <div style={{ color: "red", paddingBottom: 10 }}>{prematuroErr}</div>}
                      </FormControl>
                    </Grid>
                  ) : null}

                  {this.state.mostrarRutACuidar ? (
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <InputLabel>Rut a cuidar</InputLabel>
                          <input type="text" id="rutACuidar" name="rutACuidar" value={this.state.rutACuidar} onChange={this.revisaExisteRut.bind(this)} placeholder="Ejemplo 12345678-9" className={rutACuiErr ? "inputText showError" : "inputText"} maxLength={12} />
                          {rutACuiErr && <div style={{ color: "red", paddingBottom: 10 }}>{rutACuiErr}</div>}
                        </Grid>

                        {this.state.mostrarCondicionCui ? (
                          <>
                            <Grid item xs={12}>
                              <InputLabel>¿cuál es su condición?</InputLabel>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl>
                                <FormLabel component="legend">Condiciones</FormLabel>
                                <FormGroup style={{ display: "inline" }}>
                                  {condiciones &&
                                    condiciones.map((con, index) => {
                                      if (con.con_id == 1) {
                                        //validacion temporal ya que no se puede quitar de momento la condicion "ninguna"
                                        return false;
                                      }
                                      if (con.con_id == 4 || con.con_id == 5 || con.con_id == 8) {
                                        return <FormControlLabel key={index} control={<Checkbox value={con.con_id} onClick={(e) => this.guardarCondicionCui(e)} checked={this.state.condicionesSeleccionadasCui.includes(con.con_id) ? true : false} name={con.con_nombre} />} label={con.con_nombre} />;
                                      } else {
                                        return false;
                                      }
                                    })}
                                </FormGroup>
                              </FormControl>
                              {condicionErr && <div style={{ color: "red", paddingBottom: 10 }}>{condicionErr}</div>}
                            </Grid>
                          </>
                        ) : null}

                        {this.state.mostrarDiscapacidadCui ? (
                          <Grid item xs={12}>
                            <FormControl>
                              <FormLabel component="legend">Discapacidad</FormLabel>
                              <FormGroup style={{ display: "inline" }}>
                                {discapacidades &&
                                  discapacidades.map((dis, index) => {
                                    if (dis.dis_id == 1) {
                                      //validacion temporal ya que no se puede quitar de momento la discapacidad "ninguna"
                                      return false;
                                    }
                                    return <FormControlLabel key={index} control={<Checkbox value={dis.dis_id} onClick={(e) => this.guardarDiscapacidadCui(e)} checked={this.state.discapacidadesSeleccionadasCui.includes(dis.dis_id) ? true : false} name={dis.dis_nombre} />} label={dis.dis_nombre} />;
                                  })}
                              </FormGroup>
                            </FormControl>
                            {discapacidadErr && <div style={{ color: "red", paddingBottom: 10 }}>{discapacidadErr}</div>}
                          </Grid>
                        ) : null}
                      </Grid>
                    </GridItem>
                  ) : null}
                </Grid>
              </GridItem>
            </GridContainer>

            <div style={{ width: "100%", float: "left", marginTop: "32px" }}>
              <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                Cancelar
              </Button>
              <Button className="fondoVerde" onClick={this.enviarFormulario} color="primary" diasSename>
                Modificar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
