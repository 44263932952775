import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import moment from "moment";

// estilos
//import "../../assets/css/agregar.css";

export default class Resolucion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      uspid: store.getState().usp,
      idRegistro: props.idRegistro,
      intentosContacto: [],
      solucion: props.solucion || "",
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerDetalle = () => {
    axios
      .get(global.config.modulos.reservas_externas + "detalle/", {
        headers: authHeader(),
        params: {
          id_reserva_externa: this.state.idRegistro,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ intentosContacto: res.data.datos });
        }
      });
  };

  guardarSolucion = () => {
    let datos = {
      gre_id: this.state.idRegistro,
      gre_solucion: this.state.solucion,
      gre_fecha_termino: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    if (this.state.solucion == "Anula") {
      datos["gre_ubicacion"] = 3;
    }
    if (this.state.solucion == "Confirma") {
      datos["gre_ubicacion"] = 2;
    }
    if (this.state.solucion == "Suspendido") {
      datos["gre_ubicacion"] = 7;
    }
    axios
      .put(global.config.modulos.reservas_externas + "actualizar/", datos.gre_fecha_termino, {
        headers: authHeader(),
      })
      .then((res) => {
        NotificationManager.success("Resolución guardada correctamente");
        this.salir();
      });
  };

  cambiarValor = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { solucion } = this.state;
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="recordatoriosExternosResolucion">
          <DialogContent>
            <h2>Resolución</h2>

            <select style={{ marginTop: 30, padding: 8, borderRadius: 5 }} value={solucion} name="solucion" onChange={(e) => this.cambiarValor(e)}>
              <option value={""}>Seleccione</option>
              <option value={"Confirma"}>Confirma</option>
              <option value={"Anula"}>Anula</option>
              <option value={"Suspendido"}>Suspendido</option>
              <option value={"No contesta"}>No contesta</option>
            </select>
          </DialogContent>
          <DialogActions>
            <Button className="botonDialog fondoVerde" onClick={this.guardarSolucion}>
              Aceptar
            </Button>
            <Button className="botonDialog fondoRojo" onClick={this.salir}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
