import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import MaterialTable from "material-table";
import "react-confirm-alert/src/react-confirm-alert.css";
import { store } from "./../../store/index";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import iconoEliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import iconoModificar from "../../assets/img/usuarios/icono_modificar.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AgregarEncuesta from "./AgregarEncuesta";
// estilos
//import "../../assets/css/agregar.css";

export default class ListadoEncuestas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      encuestas: [],
      formErrors: {},
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      usuId: props.usuario,
      familiar: props.familiar || false,
      age_id: null,

      modalElim: false,
      modalEdit: false,

      encuestAccion: {},
    };
    this.traerEncuestas = this.traerEncuestas.bind(this);
    this.eliminarEncuestaCancel = this.eliminarEncuestaCancel.bind(this);
    this.editarEncuestaCancel = this.editarEncuestaCancel.bind(this);
  }

  componentDidMount() {
    this.traerEncuestas();
  }

  traerEncuestas(opcion) {
    let apiUrl = global.config.modulos.cuestionarios + "lista_cuestionarios/" + "?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      /* console.log(res.data); */
      this.setState({ encuestas: res.data });
    });
  }

  editarEncuestaConfirm(rowData) {
    this.setState({ modalEdit: true, encuestAccion: rowData });
  }

  editarEncuestaCancel(rowData) {
    this.setState({ modalEdit: false }, () => {
      this.setState({ encuestAccion: {} });
      this.traerEncuestas();
    });
  }

  eliminarEncuestaConfirm(rowData) {
    this.setState({ modalElim: true, encuestAccion: rowData });
  }

  eliminarEncuestaCancel() {
    this.setState({ modalElim: false }, () => {
      this.setState({ encuestAccion: {} });
    });
  }

  eliminarEncuesta(rowData) {
    var data = {
      cuestionario: {
        cue_estado: 0,
      },
    };
    let apiUrl = global.config.modulos.cuestionarios + "por_id/" + this.state.encuestAccion.cue_id;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.traerEncuestas();
      this.eliminarEncuestaCancel();
      NotificationManager.info("Encuesta eliminada correctamente");
    });
  }

  render() {
    return (
      <>
        <>
          <div className="historialHoras">
            <Grid container spacing={2} className="fichaUsuario scroll-container">
              <Grid item xs={12}>
                <div>
                  <div className="datosUsuario " style={{ border: "", marginTop: "0", marginLeft: "0" }}>
                    <TableContainer style={{ width: "100%", marginTop: "" }}>
                      <Table aria-label="simple table" className="" style={{ textAlign: "center" }}>
                        <MaterialTable
                          title=""
                          columns={[
                            {
                              title: "Nombre Encuesta",
                              field: "cue_nombre",
                            },
                            {
                              title: "Fecha creación encuesta",
                              field: "cue_fecha_registro",
                              render: (rowData) => <p>{moment(rowData.cue_fecha_registro).format("DD-MM-YYYY")}</p>,
                            },
                            {
                              title: "Acciones",
                              render: (rowData) => (
                                <>
                                  <Tooltip title="Editar" aria-label="">
                                    <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.editarEncuestaConfirm.bind(this, rowData)} src={iconoModificar} />
                                  </Tooltip>
                                  <Tooltip title="Eliminar" aria-label="">
                                    <img className="mediumIcon" onClick={this.eliminarEncuestaConfirm.bind(this, rowData)} src={iconoEliminar} />
                                  </Tooltip>
                                </>
                              ),
                            },
                          ]}
                          data={this.state.encuestas}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: false,
                            search: true,
                            toolbar: false,
                            actions: false,
                            exportAllData: true,
                            exportButton: true,
                          }}
                          localization={{
                            header: {
                              actions: "a",
                            },
                            toolbar: {
                              searchPlaceholder: "Buscar",
                              searchTooltip: "Buscar",
                              exportTitle: "Exportar",
                              exportAriaLabel: "Exportar",
                              exportCSVName: "Exportar como CSV",
                              exportPDFName: "Exportar como PDF",
                            },
                            body: {
                              emptyDataSourceMessage: "No se encuentran encuestas para este cesfam",
                            },
                            pagination: {
                              labelRowsSelect: "Filas",
                              labelDisplayedRows: "{from}-{to} de {count}",
                            },
                          }}
                        ></MaterialTable>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
        <>
          <Dialog open={this.state.modalElim} onClose={this.eliminarEncuestaCancel.bind(this)} className="modificarUsuario">
            <DialogContent>
              <GridContainer className="">
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                      <h1>¿Seguro que desea eliminar la encuesta {this.state.encuestAccion.cue_nombre}?</h1>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                    <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8, color: "white" }} className="fondoRojoBeneficio" onClick={this.eliminarEncuesta.bind(this, this.state.difusAccion)}>
                      Eliminar
                    </Button>
                    <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.eliminarEncuestaCancel.bind(this)}>
                      Atrás
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </DialogContent>
          </Dialog>
        </>
        {this.state.modalEdit ? <AgregarEncuesta ocultar={this.editarEncuestaCancel} editar={true} cue_edit={this.state.encuestAccion} /> : null}
      </>
    );
  }
}
