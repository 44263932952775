import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default function GraficoArchivos(props) {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalHorizontal,
      })
    );

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: props.data,
      dataFields: {
        name: "Tipo ticket",
        value: "Cantidad ticket",
      },
      dataFieldsOrder: ["Tipo ticket", "Cantidad ticket"],
      pngOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      pdfOptions: {
        addURL: false,
      },
      jsonOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      title: "Tickets",
      filePrefix: props.filename,
    });

    exporting.events.on("pdfdocready", function (event) {
      event.doc.content.unshift({
        text: props.filename,
        margin: [0, 30],
        style: {
          fontSize: 25,
          bold: true,
        },
      });
    });

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "name",
        valueField: "value",
        categoryField: "name",
      })
    );

    series.get("colors").set("colors", [am5.color("#8787f4"), am5.color("#ef9943")]);

    series.slices.template.setAll({
      fillOpacity: 1,
      stroke: am5.color("#fff"),
      strokeWidth: 2,
    });

    series.labels.template.setAll({
      fontSize: 25,
      fill: am5.color("#000"),
      text: "{name} : {value}",
    });

    series.slices.template.set("toggleKey", "none");

    series.slices.template.set("tooltipText", "");

    series.data.setAll(props.data);

    series.appear(1000, 100);

    var modal = am5.Modal.new(root, {
      content: "No se encontraron datos para estos filtros",
    });

    if (props.data.length < 1) {
      modal.open();
    } else {
      modal.close();
    }

    return () => {
      root.dispose();
    };
  }, []);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
}
