import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { confirmAlert } from "react-confirm-alert"; // Import
import { store } from "./../../store/index";
import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { NotificationManager } from "react-notifications";
import PlantillasObservaciones from "./PlantillasObservaciones";
import ObservacionesHistorial from "./ObservacionesHistorial";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class AgregarObservacion extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      gestion: props.gegId,
      usuario: props.usuario,

      observacion: "",
      observaciones: [],
      estadoCaso: props.estadoCaso,
      demandaTomado: props.demandaTomado,

      open: true,
      modalPlantillasObservaciones: false,
      formErrors: {},
    };
  }

  componentDidMount = () => {
    this.traerObservaciones();
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  copiarTexto = (texto) => {
    this.setState({ observacion: texto });
  };

  mostrarModalPlantillasObservaciones = () => {
    this.setState({ modalPlantillasObservaciones: true });
  };

  ocultarModalPlantillasObservaciones = (mensaje = "") => {
    this.setState({ modalPlantillasObservaciones: false });
    this.copiarTexto(mensaje);
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerObservaciones = () => {
    axios
      .get(global.config.modulos.demanda + "traer_observaciones/", {
        headers: authHeader(),
        params: { usuario: this.state.usuario },
      })
      .then((res) => {
        this.setState({ observaciones: res.data.datos });
      });
  };

  validaciones() {
    const { observacion } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!observacion) {
      formIsValid = false;
      formErrors["obsErr"] = "Escribe la observación.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  agregarObservacion = () => {
    if (this.validaciones()) {
      axios
        .post(
          global.config.modulos.demanda + "agregar_observacion/",
          {
            usuario_panel: this.state.uspId,
            observacion: this.state.observacion,
            usuario: this.state.usuario,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          NotificationManager.success("Observacion agregada con éxito");
          this.salir();
        });
    }
  };

  guardarPlantilla = () => {
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ que desea guardar esta observación como plantilla?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => {
              axios
                .post(
                  global.config.modulos.demanda + "guardar_plantilla_comentario/",
                  {
                    usuario_panel: this.state.uspId,
                    comentario: this.state.observacion,
                  },
                  {
                    headers: authHeader(),
                  }
                )
                .then((res) => {
                  if (res.data.estado) {
                    NotificationManager.success("Plantilla guardada con éxito");
                  }
                });
            },
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  render() {
    const { obsErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agregarObservacion">
        <DialogContent>
          {this.state.estadoCaso == 1 ? (
            <React.Fragment>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12} className="">
                  <GridContainer className="agendar-container">
                    <GridItem xs={12}>
                      <h2>Agregar observación</h2>
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: 30 }}>
                      <TextField id="observacion" name="observacion" label="Observación" multiline rows={4} value={this.state.observacion} style={{ width: "100%" }} variant="outlined" onChange={this.cambiarValores} />
                      {obsErr && <div style={{ color: "red", paddingBottom: 10 }}>{obsErr}</div>}
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <Button
                        id={"modalModificarUsuarioBtnCancelar"}
                        onClick={this.salir}
                        style={{
                          marginLeft: 20,
                          color: "white",
                          float: "right",
                        }}
                        color="primary"
                        className="fondoRojo"
                      >
                        Cancelar
                      </Button>
                      <Button onClick={this.agregarObservacion} style={{ color: "white", float: "right" }} color="primary" className="fondoVerde">
                        Agregar
                      </Button>
                      <Button
                        style={{
                          marginLeft: 0,
                          float: "left",
                          color: "#fff",
                        }}
                        color="primary"
                        onClick={this.guardarPlantilla}
                        className="fondoVerde"
                      >
                        Guardar plantilla
                      </Button>
                      <Button
                        style={{
                          marginLeft: 20,
                          float: "left",
                          color: "#fff",
                        }}
                        color="primary"
                        onClick={this.mostrarModalPlantillasObservaciones}
                        className="fondoRojo"
                      >
                        Seleccionar plantilla
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </React.Fragment>
          ) : (
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="">
                <GridContainer className="agendar-container">
                  <GridItem xs={12}>
                    <h2>Observaciones</h2>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          )}

          {this.state.observaciones.length > 0 ? <ObservacionesHistorial observaciones={this.state.observaciones}></ObservacionesHistorial> : null}
        </DialogContent>

        {this.state.modalPlantillasObservaciones ? <PlantillasObservaciones ocultar={this.ocultarModalPlantillasObservaciones} tipo={this.state.tipo}></PlantillasObservaciones> : null}
      </Dialog>
    );
  }
}
