import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Cargando from "./../../components/Helpers/Cargando";
import PlantillasChat from "./PlantillasChat";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class MensajeGlobal extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      nombreUsp: store.getState().nombreusp,
      cesfam: store.getState().cesfam,
      open: true,
      requerimiento: null,
      sexo: null,
      edadMin: 0,
      edadMax: 0,
      casosIngresados: false,
      casosEnProceso: false,
      casosFinalizados: false,
      modalCargando: false,
      modalPlantillaChat: false,
      mensaje: "",
      tipo: props.tipo,
      plantillas: [],
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  cambiarValoresCheckBox = (nombre, valor) => {
    this.setState({
      [nombre]: !valor,
    });
  };

  mostrarModalCargando = () => {
    this.setState({ modalCargando: true });
  };

  ocultarModalCargando = () => {
    this.setState({ modalCargando: false });
  };

  mostrarModalPlantillaChat = () => {
    this.setState({ modalPlantillaChat: true });
  };

  ocultarModalPlantillaChat = (mensaje = "") => {
    this.setState({ modalPlantillaChat: false });
    this.copiarTexto(mensaje);
  };

  validarDatos = () => {
    if (!this.state.casosIngresados && !this.state.casosEnProceso && !this.state.casosFinalizados) {
      NotificationManager.warning("Por favor seleccione a que casos enviar el mensaje");
      return false;
    }
    /*
    if (
      typeof this.state.edadMin != "number" ||
      typeof this.state.edadMax != "number"
    ) {
      NotificationManager.warning("La edad solo debe contener números");
      return false;
    }
    */

    if (this.state.mensaje == "") {
      NotificationManager.warning("Por favor escriba un mensaje");
      return false;
    }
    return true;
  };

  copiarTexto = (texto) => {
    this.setState({ mensaje: texto });
  };

  enviarMensaje = () => {
    if (!this.validarDatos()) {
      return;
    }

    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea enviar este mensaje?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.crearChats(),
          className: "btnAceptar",
        },
      ],
    });
  };

  crearChats = () => {
    this.mostrarModalCargando();
    axios
      .post(
        global.config.modulos.chat + "chats_masivos_gestion/",
        {
          requerimiento: this.state.requerimiento,
          sexo: this.state.sexo,
          edadMin: this.state.edadMin,
          edadMax: this.state.edadMax,
          casosIngresados: this.state.casosIngresados,
          casosEnProceso: this.state.casosEnProceso,
          casosFinalizados: this.state.casosFinalizados,
          mensaje: this.state.mensaje,
          tipo: this.state.tipo,
          usp: this.state.uspId,
          nombreUsp: this.state.nombreUsp,
          cesfam: this.state.cesfam,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          this.ocultarModalCargando();
          NotificationManager.success("Mensajes enviados exitosamente");
          this.salir();
        } else {
          this.ocultarModalCargando();
          NotificationManager.error(res.data.mensaje);
        }
      });
  };

  guardarPlantilla = () => {
    if (this.state.mensaje == "") {
      return NotificationManager.warning("Primero debe escribir el mensaje");
    }
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea guardar este mensaje como plantilla?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            axios
              .post(
                global.config.modulos.gestion + "registrar_plantilla_chat/",
                {
                  usuario_panel: this.state.uspId,
                  mensaje: this.state.mensaje,
                  tipo_gestion: this.state.tipo,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                if (res.data.estado) {
                  NotificationManager.success("Plantilla guardada con éxito");
                }
              });
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="mensajeGlobal">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Mensaje general</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <FormControlLabel control={<Checkbox checked={this.state.casosIngresados} onChange={() => this.cambiarValoresCheckBox("casosIngresados", this.state.casosIngresados)} value="casosIngresados" name="casosIngresados" color="primary" />} label="Casos ingesados" />
                  <FormControlLabel control={<Checkbox checked={this.state.casosEnProceso} onChange={() => this.cambiarValoresCheckBox("casosEnProceso", this.state.casosEnProceso)} value="casosEnProceso" name="casosEnProceso" color="primary" />} label="Casos en proceso" />
                  <FormControlLabel control={<Checkbox checked={this.state.casosFinalizados} onChange={() => this.cambiarValoresCheckBox("casosFinalizados", this.state.casosFinalizados)} value="casosFinalizados" name="casosFinalizados" color="primary" />} label="Casos finalizados" />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <div style={{ float: "left" }}>
                    <p>Edad Min</p>
                    <input className="estiloSelect" type="number" name="edadMin" value={this.state.edadMin} onChange={this.cambiarValores} placeholder="" />
                  </div>
                  <div style={{ float: "left", marginLeft: 30 }}>
                    <p>Edad Max</p>
                    <input className="estiloSelect" type="number" name="edadMax" value={this.state.edadMax} onChange={this.cambiarValores} placeholder="" />
                  </div>
                  <div style={{ float: "left", marginLeft: 30 }}>
                    <FormControl variant="outlined">
                      <p id="demo-simple-select-outlined-label">Sexo</p>
                      <select className="estiloSelect" id="sexo" name="sexo" value={this.state.sexo} onChange={this.cambiarValores} label="Sexo">
                        <option value={""}>Todos</option>
                        <option value={"F"}>Femenino</option>
                        <option value={"M"}>Masculino</option>
                        <option value={"I"}>Intersex</option>
                      </select>
                    </FormControl>
                  </div>
                  <div style={{ float: "left", marginLeft: 30 }}>
                    {this.state.tipo ? (
                      <FormControl variant="outlined">
                        <p id="demo-simple-select-outlined-label">Requerimiento</p>
                        <select className="estiloSelect" id="requerimiento" name="requerimiento" value={this.state.requerimiento} onChange={this.cambiarValores} label="Requerimiento">
                          <option selected value={""}>
                            Todos
                          </option>
                          <option value={"Asistente social"}>Asistente social</option>
                          <option value={"Curaciones"}>Curaciones</option>
                          <option value={"Dental"}>Dental</option>
                          <option value={"Enfermero"}>Enfermero</option>
                          <option value={"Kinesiólogo"}>Kinesiólogo</option>
                          <option value={"Licencia médica"}>Licencia médica</option>
                          <option value={"Matrona"}>Matrona</option>
                          <option value={"Médico"}>Médico</option>
                          <option value={"Nutrición"}>Nutrición</option>
                          <option value={"Otro requerimiento"}>Otro requerimiento</option>
                          <option value={"Podología"}>Podología</option>
                          <option value={"Psicólogo"}>Psicólogo</option>
                          <option value={"Receta"}>Receta</option>
                          <option value={"Retiro de leche o alimento"}>Retiro de leche o alimento</option>
                          <option value={"Retiro de medicamentos"}>Retiro de medicamentos</option>
                          <option value={"Vacunación"}>Vacunación</option>
                        </select>
                      </FormControl>
                    ) : null}
                  </div>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <TextField id="mensaje" name="mensaje" label="Mensaje" multiline rows={4} defaultValue="" value={this.state.mensaje} style={{ width: "100%" }} variant="outlined" onChange={this.cambiarValores} />
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" onClick={this.enviarMensaje} className="fondoVerde">
                    Enviar
                  </Button>
                  <Button style={{ marginLeft: 0, float: "left", color: "#fff" }} color="primary" onClick={this.guardarPlantilla} className="fondoVerde">
                    Guardar mensaje
                  </Button>
                  <Button style={{ marginLeft: 20, float: "left", color: "#fff" }} color="primary" onClick={this.mostrarModalPlantillaChat} className="fondoRojo">
                    Seleccionar mensaje
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.modalCargando ? <Cargando color="#fff" type="spinningBubbles" /> : null}
        {this.state.modalPlantillaChat ? <PlantillasChat ocultar={this.ocultarModalPlantillaChat} tipo={this.state.tipo}></PlantillasChat> : null}
      </Dialog>
    );
  }
}
