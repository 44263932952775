import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";
import moment from "moment";
import axios from "axios";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";

import { Input } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import { store } from "./../../store/index";
import { DialogContentText } from "@material-ui/core";

import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ConfirmarEstadoEvaluacion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: store.getState().usp,
      cesfam: store.getState().cesfam,

      open: true,

      loi_id: props.loi_id,
      tipo_cita: props.tipo_cita,

      fecha_p: props.fecha_cita,
      fecha_inicio: moment(props.fecha_cita, "DD-MM-YYYY").format("YYYY-MM-DD"),

      estado_agenda: 0,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {}

  fecha_inicio = (e) => {
    const fecha_inicio = e.target.value;
    this.setState({ fecha_inicio: fecha_inicio });
  };

  estado_cita = (e) => {
    const estado_agenda = e.target.value;
    this.setState({
      estado_agenda: estado_agenda,
      mostrar_opciones: 1,
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  agregarTratamiento = () => {
    var datos = {};
    if (this.state.tipo_cita == "2") {
      if (this.state.fecha_inicio == null) {
        NotificationManager.error("Debe ingresar una fecha de inicio");
        return;
      }
      if (this.state.tratamiento == "0") {
        NotificationManager.error("Debe seleccionar un tratamiento");
        return;
      }

      datos["loi_fecha_evaluacion"] = this.state.fecha_inicio + " " + moment().format("HH:mm");
    } else {
      if (this.state.estado_agenda == null || this.state.estado_agenda == "0") {
        NotificationManager.error("Debe seleccionar el estado de la agenda");
        return;
      }
    }

    axios
      .patch(
        global.config.modulos.listaEspera + "cambiar_cita_evaluacion/",
        {
          loi_id: this.state.loi_id,
          usp_id: this.state.usp_id,
          estado_agenda: this.state.estado_agenda,
          datos: datos,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha confirmado el tratamiento al usuario");
          this.salir();
          this.setState({
            fecha_inicio: null,
            tratamiento: null,
          });
        } else {
          NotificationManager.error("No se ha podido agregar el tratamiento al usuario");
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "500px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.tipo_cita === 1 ? (
                  <div>
                    <h2>Confirme el estado final de la cita.</h2>
                    <br></br>
                    <FormControl style={{ width: "100%" }}>
                      <Select name="tratamiento" value={this.state.estado_agenda} onChange={this.estado_cita}>
                        <MenuItem value={0}>Seleccione estado</MenuItem>
                        <MenuItem value={"1"}>Asistida</MenuItem>
                        <MenuItem value={"2"}>Inasistente</MenuItem>
                        <MenuItem value={"3"}>Suspendida</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <div>
                    <h2>Agregar una nueva fecha de citación:</h2>
                    <br></br>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">fecha tratamiento</InputLabel>
                      <Input type="date" name="fecha inicio" value={this.state.fecha_inicio} onChange={this.fecha_inicio} />
                    </FormControl>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: 10, backgroundColor: "#00ac77", color: "#fff" }} onClick={() => this.agregarTratamiento()}>
                    Guardar
                  </Button>
                  <Button onClick={this.salir} style={{ color: "#fff" }} className="fondoNaranjo">
                    Cancelar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
