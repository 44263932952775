import React, { Component, useRef, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import { useReactToPrint } from "react-to-print";

import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import logo_comuna_1 from "assets/img/imprimir/1.png";
import logo_comuna_2 from "assets/img/imprimir/2.png";
import logo_comuna_6 from "assets/img/imprimir/6.png";
import logo_comuna_8 from "assets/img/imprimir/8.png";
import logo_comuna_10 from "assets/img/imprimir/10.png";
import logo_comuna_11 from "assets/img/imprimir/11.png";
import logo from "assets/img/imprimir/logo.png";
import apps from "assets/img/imprimir/apps.png";

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      examen: props.examen,
      horario: props.horario,
      cliente: store.getState().cliente,
      cesfam: store.getState().cesfam,
      data: [],
    };
  }

  componentDidMount() {
    this.traerHorario();
  }

  traerHorario = async () => {
    let apiUrl = global.config.modulos.aplicaciones + "confirmar_hora/?horario=" + this.state.horario;
    axios.get(apiUrl, { headers: authHeader() }).then(async (res) => {
      var data = res.data;
      var estado = data.estado;

      var tabla = [];
      var examen = [];
      if (estado) {
        var datos = data["mensaje"].split("<br>");
        var fecha = datos[2];
        var dia = datos[3];
        var profesional = datos[5];

        if (this.state.examen != null) {
          if (this.state.examen == 24) {
            examen.push(
              <div>
                <br></br>
                <br></br>
                <label>
                  <strong style={{ fontSize: "20px" }}> Preparación Ecografía Abdominal:</strong>
                  <br></br>
                  *El <strong style={{ fontSize: "20px" }}>día del examen debe presentarse con 8 hrs de AYUNO COMPLETO</strong> (no ingerir sólidos ni líquidos)<br></br>*<strong style={{ fontSize: "20px" }}>El día previo al examen:</strong> Debe realizar una <strong style={{ fontSize: "20px" }}>DIETA LIVIANA</strong> de alimentos.<br></br>
                  <strong style={{ fontSize: "20px" }}>Desayuno y Merienda:</strong> Té (solo o con limón), lo puede acompañar con galletitas de agua, mermelada, jamón de pavo.<br></br>
                  <strong style={{ fontSize: "20px" }}>Almuerzo y Cena:</strong> Caldos sin grasa, arroz o fideos blancos, Pollo hervido (sin piel), carne magra (sin grasa), Pescado hervido. Agua sin gas.<br></br>
                  <strong style={{ fontSize: "20px" }}>Alimentos prohibidos:</strong> Bebidas, café, aguas gasificadas, lácteos, quesos, frituras, huevos, paltas, no masticar chicle, ni fumar.<br></br>
                </label>
              </div>
            );
          }
          if (this.state.examen == 15 || this.state.examen == 16 || this.state.examen == 17) {
            examen.push(
              <div>
                <br></br>
                <br></br>
                <label>
                  <strong style={{ fontSize: "20px" }}>Preparación Mamografía:</strong>
                  <br></br>
                  *Traer mamografía anterior, informes más imágenes (placas o CD).
                  <br></br>
                  *No aplicarse desodorante, crema ni talco.<br></br>
                </label>
              </div>
            );
          }
          if (this.state.examen == 18) {
            examen.push(
              <div>
                <br></br>
                <br></br>
                <label>
                  <strong style={{ fontSize: "20px" }}>Preparación Ecografía Mamaria:</strong>
                  <br></br>
                  *Traer mamografía actualizada (de no más de 6 meses) informes más imágenes (placas o CD) y ecografía mamaria (de hasta 2 años).
                  <br></br>
                  *No aplicarse desodorante, crema ni talco.<br></br>
                </label>
              </div>
            );
          }
          if (this.state.examen == 19 || this.state.examen == 23) {
            examen.push(
              <div>
                <br></br>
                <br></br>
                <label>
                  <strong style={{ fontSize: "20px" }}>Ecografía Pélvica:</strong>
                  <br></br>
                  *El examen se realiza cuando la vejiga está totalmente distendida y el paciente siente muchos deseos de orinar.
                  <br></br>
                  *Tome 1 litro y medio de líquidos (Agua, Te, Café, etc.) debe ingerirlos 2 horas antes de la hora reservada y no orinar.<br></br>
                  *En personas con sonda urovesical, pinzarla 2 horas antes del examen.<br></br>
                </label>
              </div>
            );
          }
          if (this.state.examen == 21) {
            examen.push(
              <div>
                <br></br>
                <br></br>
                <label>
                  <strong style={{ fontSize: "20px" }}>Ecografía Testicular:</strong>
                  <br></br>
                  *Se debe presentar con la zona a examinar rasurada.
                  <br></br>
                </label>
              </div>
            );
          }

          tabla.push(
            <div style={{ textAlign: "center", padding: "10px" }}>
              <label>
                <br></br>
                Su hora ha quedado reservada para el día <strong style={{ fontSize: "20px" }}>{fecha}</strong> a las <strong style={{ fontSize: "20px" }}>{dia.replace("A las ", "")}</strong> con el <strong style={{ fontSize: "20px" }}>{profesional.replace("con El", "")}</strong>
                <br></br>
                <br></br>
                ¡¡¡Recuerde!!!
                <br></br>
                Llegar 15 minutos antes de tu hora al área de Imagenología del Cesfam Cristo Vive, ubicado en Av. Recoleta 4125, Recoleta.
                <br></br>
                <strong>*Debes llevar la orden de examen</strong>
                {examen}
                <br></br>
                <br></br>
                No pierdas tu atención, si no puedes asistir anula 2 días antes.
                <br></br>
                Si perteneces al Cesfam puedes descargar la aplicación HoraSalud:
                <br></br>
                <br></br>
                <img src={apps} style={{ width: "100%" }}></img>
                <br></br>O ingresa a<br></br> <strong style={{ fontSize: "20px" }}>horasalud.cl</strong>
              </label>
            </div>
          );
        } else {
          var numero = await this.traerNumero();
          var comuna = this.state.cliente;

          tabla.push(
            <div style={{ textAlign: "center", padding: "10px" }}>
              <label>
                {comuna == 1 ? <img src={logo_comuna_1} style={{ width: "40%" }}></img> : comuna == 2 ? <img src={logo_comuna_2} style={{ width: "40%" }}></img> : comuna == 6 ? <img src={logo_comuna_6} style={{ width: "40%" }}></img> : comuna == 8 ? <img src={logo_comuna_8} style={{ width: "40%" }}></img> : comuna == 10 ? <img src={logo_comuna_10} style={{ width: "40%" }}></img> : comuna == 11 ? <img src={logo_comuna_11} style={{ width: "40%" }}></img> : null}
                <br></br>
                <br></br>
                Su hora ha quedado reservada para el día <strong style={{ fontSize: "20px" }}>{fecha}</strong> a las <strong style={{ fontSize: "20px" }}>{dia.replace("A las ", "")}</strong> con el <strong style={{ fontSize: "20px" }}>{profesional.replace("con El", "")}</strong>
                <br></br>
                <br></br>
                ¡¡¡Recuerde!!!
                <br></br>
                Llegar 15 minutos antes a tu hora.
                <br></br>Y si no puede asistir, anule llamando al <strong style={{ fontSize: "20px" }}>{numero}</strong> o descarga la aplicación
                <br></br>
                <br></br>
                <img src={apps} style={{ width: "100%" }}></img>
                <br></br>O ingresa a<br></br> <strong style={{ fontSize: "20px" }}>horasalud.cl</strong>
                <br></br>
                <img src={logo} style={{ width: "100%" }}></img>
              </label>
            </div>
          );
        }
      }
      this.setState({
        data: tabla,
      });
    });
  };

  traerNumero = async () => {
    let apiUrl = global.config.modulos.clientes + "cliente_por_comuna/?comuna=" + this.state.cliente;
    var res = await axios.get(apiUrl, {
      headers: authHeader(),
    });
    return res.data.cli_numero_contrato;
  };

  render() {
    return this.state.data;
  }
}

class DatosPaciente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuId: props.usuId,
      horario: props.horario,
      cliente: store.getState().cliente,
      data: [],
    };
  }

  componentDidMount() {
    this.traerHorario();
  }

  traerUsuario = async () => {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;
    var res = await axios.get(apiUrl, {
      headers: authHeader(),
    });
    var data = res.data;
    var nombre = data["datos"]["usu_nombre"] + " " + data["datos"]["usu_apellido_p"];
    return nombre;
  };

  traerHorario = () => {
    let apiUrl = global.config.modulos.aplicaciones + "confirmar_hora/?horario=" + this.state.horario;
    axios.get(apiUrl, { headers: authHeader() }).then(async (res) => {
      var data = res.data;
      var estado = data.estado;

      var tabla = [];
      var nombre = await this.traerUsuario();
      if (estado) {
        var datos = data["mensaje"].split("<br>");
        var fecha = datos[2];
        var dia = datos[3];
        var profesional = datos[5];

        tabla.push(
          <div style={{ textWrap: "nowrap" }}>
            <label>
              La hora para {nombre} ha sido reservada para el día <strong>{fecha}</strong> a las <strong>{dia.replace("A las ", "")}</strong> <br></br> con el <strong>{profesional.replace("con El", "")}</strong>, ¿Desea imprimir un comprobante de la hora reservada <br></br> para el paciente?
            </label>
          </div>
        );
      }

      this.setState({
        data: tabla,
      });
    });
  };

  render() {
    return this.state.data;
  }
}

const ImprimirHora = (props) => {
  const salir = () => {
    props.ocultar();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      salir();
    },
  });

  return (
    <div
      className="custom-ui"
      style={{
        backgroundColor: "white",
        width: "100%",
        padding: "40px",
        borderRadius: "20px",
      }}
    >
      <h1
        style={{
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        ¡Atención!
      </h1>
      <br></br>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DatosPaciente horario={props.horario} usuId={props.usuId}></DatosPaciente>

          <br></br>

          <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={salir} style={{ marginLeft: 20 }}>
            No imprimir
          </Button>

          <Button className="botonDialog fondoVerde" onClick={handlePrint}>
            Imprimir hora
          </Button>

          <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} horario={props.horario} examen={props.examen} />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ImprimirHora;
