import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridContainer from "./../../components/grid/GridContainer.js";
import Titulo from "./../../components/titulo/Titulo.js";
import { Grid } from "@material-ui/core";
import "moment/locale/es";
import axios from "axios";
import MetasActivas from "./MetasActivas";
import Button from "@material-ui/core/Button";
import AgregarMeta from "./AgregarMeta.js";
import GraficosMetas from "./GraficosMetas.js";

import signo from "./../../assets/img/signo.svg";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/graficos.css";
import "./../../assets/css/usuarios.css";

export default class MenuMetas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarGraficoMetas: false,
      mostrarTablaMetasAct: true,
      mostrarTablaMetasInact: false,
      mostrarCrearMeta: false,
    };

    this.ocultarCrearMeta = this.ocultarCrearMeta.bind(this);
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Metas <span title=''><img src=" + signo + "></img></span>";
  };

  mostrarOpcion(opcion) {
    switch (opcion) {
      case 1:
        this.setAllFalse();
        this.setState({ mostrarGraficoMetas: true });
        break;
      case 2:
        this.setAllFalse();
        this.setState({ mostrarTablaMetasAct: true });
        break;
      case 3:
        this.setAllFalse();
        this.setState({ mostrarTablaMetasInact: true });
        break;
      default:
        this.setAllFalse();
        break;
    }
  }

  setAllFalse() {
    this.setState({ mostrarGraficoMetas: false, mostrarTablaMetasAct: false, mostrarTablaMetasInact: false });
  }

  mostrarCrearMeta() {
    this.setState({ mostrarCrearMeta: true });
  }

  ocultarCrearMeta() {
    this.setState({ mostrarCrearMeta: false, mostrarTablaMetasAct: false, mostrarTablaMetasInact: false }, () => {
      this.setState({ mostrarTablaMetasAct: true });
    });
  }

  render() {
    return (
      <div className="middle-area" style={{ width: "85%" }}>
        <br></br>
        <GridContainer>
          <Grid item xs={12} sm={12}>
            <a className={this.state.mostrarGraficoMetas ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 1)}>
              Monitoreo de metas
            </a>
            <a className={this.state.mostrarTablaMetasAct ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 2)}>
              Metas activas
            </a>
            <a className={this.state.mostrarTablaMetasInact ? "btnOpcion buttonSelected" : "btnOpcion fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarOpcion.bind(this, 3)}>
              Metas inactivas
            </a>
            {this.state.mostrarTablaMetasAct ? (
              <a className="btnOpcion botonesAlineados botonAgregarUsuario fondoNaranjo" style={{ cursor: "pointer", marginRight: 10 }} onClick={this.mostrarCrearMeta.bind(this)}>
                Agregar Meta
              </a>
            ) : null}
          </Grid>
        </GridContainer>

        {this.state.mostrarGraficoMetas ? <GraficosMetas /> : null}

        {this.state.mostrarTablaMetasAct ? (
          <div class="middle-content">
            <MetasActivas activas={true} />
          </div>
        ) : null}
        {this.state.mostrarTablaMetasInact ? (
          <div class="middle-content">
            <MetasActivas activas={false} />
          </div>
        ) : null}

        {this.state.mostrarCrearMeta ? <AgregarMeta ocultar={this.ocultarCrearMeta} editar={false}></AgregarMeta> : null}
      </div>
    );
  }
}
