import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import chileanRut from "chilean-rut";
import AlertaHTML from "./../../components/Gestion/AlertaHTMLOpciones";
import { NotificationManager } from "react-notifications";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class AgregarCasoOpciones extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      canal: 5,
      uspId: parseInt(store.getState().usp),
      cesfam: props.cesfam,
      open: true,
      gestion: props.gegId,
      tipo: props.tipo,
      rut: null,
      riesgo: "Alto",
      nuevoUsuario: false,
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      fechaNacimiento: null,
      direccion: "",
      telefono: "",
      sexo: "M",
      cesfamUsuario: null,
      sector: null,
      sectores: [],
      modalAlertaHTML: false,
      mensajeAlerta: [],
      validacionesOk: false,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  ocultarAlertaHTML = () => {
    this.setState({ modalAlertaHTML: false });
    this.salir();
  };

  agregarCasoAlertaHTML = () => {
    this.crearCaso();
    this.salir();
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre", "apellidoP", "apellidoM"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }

    this.setState({ [name]: value });
  };

  cambiarValorFecha = (e) => {
    let fecha = moment(e).format("YYYY-MM-DD");

    this.setState({ fechaNacimiento: fecha });
  };

  crearCaso = () => {
    axios
      .post(
        global.config.modulos.gestion + "crear/",
        {
          canal: 5,
          cesfam: this.state.cesfam,
          tipo: this.state.tipo,
          requerimiento: null,
          rut: this.state.rut,
          riesgo: this.state.riesgo,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          this.salir();
        } else {
          this.setState({ nuevoUsuario: true });
        }
      });
  };

  enviarFormulario = (crearDirecto) => {
    if (crearDirecto) {
      return this.crearCaso();
    }

    if (this.state.rut == null || this.state.rut == "") {
      return NotificationManager.error("El rut es inválido");
    }

    if (!chileanRut.validate(this.state.rut)) {
      return NotificationManager.error("El rut es inválido");
    }

    axios
      .get(global.config.modulos.gestion + "usuario/", {
        headers: authHeader(),
        params: {
          rut: this.state.rut,
          cesfam: this.state.cesfam,
          tipo: this.state.tipo,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          this.setState({
            mensajeAlerta: res.data.mensaje,
            modalAlertaHTML: true,
          });
          return;
        }
        if (res.data.cesfam) {
          confirmAlert({
            title: "",
            message: res.data.mensaje + ", desea continuar?",
            buttons: [
              {
                label: "Cancelar",
                className: "btnCancelar",
              },
              {
                label: "Aceptar",
                onClick: () => this.crearCaso(),
                className: "btnAceptar",
              },
            ],
          });
        } else {
          confirmAlert({
            title: "",
            message: res.data.mensaje + ", desea continuar?",
            buttons: [
              {
                label: "Cancelar",
                className: "btnCancelar",
              },
              {
                label: "Aceptar",
                onClick: () => this.setState({ nuevoUsuario: true }),
                className: "btnAceptar",
              },
            ],
          });
        }
      });
  };

  guardarUsuario = (e) => {
    if (this.validaciones()) {
      var data = {
        usu_nombre: this.state.nombre,
        usu_apellido_p: this.state.apellidoP,
        usu_apellido_m: this.state.apellidoM,
        usu_rut: this.state.rut,
        usu_direccion: this.state.direccion,
        usu_telefono1: "",
        usu_telefono2: "",
        usu_fecha_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
        usu_fecha_nacimiento: this.state.fechaNacimiento,
        usu_estado: 1,
        usu_sexo: this.state.sexo,
        usu_cesfam: this.state.cesfamUsuario,
        usu_rut_original: this.state.rut,
        usu_fecha_inscripcion: moment().format("YYYY-MM-DD HH:mm:ss"),
        usu_canal_inscripcion: this.state.canal,
        usu_condicion: 1,
        usu_discapacidad: 1,
        usu_sector: this.state.sector,
        canal: 5,
        usp_id: this.state.uspId,
      };

      let apiUrl = global.config.modulos.usuarios + "agregar/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        if (res.data.estado) {
          axios
            .post(
              global.config.modulos.telefonos + "agregar_telefono/",
              {
                telefono: this.state.telefono,
                usu_id: res.data.datos["usu_id"],
                tipo: 2,
                canal: this.state.canal,
                usp_id: this.state.uspId,
              },
              {
                headers: authHeader(),
              }
            )
            .then((res) => {
              this.enviarFormulario(true);
            });
        }
      });
    }
  };

  cambioCesfam = (event) => {
    this.setState({
      cesfamUsuario: event.target.value,
      sector: "",
    });

    this.traerSectores(event.target.value);
  };

  traerSectores(cesId) {
    const apiUrl = global.config.modulos.sectores + "por_cesfam/" + cesId;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        this.setState({
          sectores: res.data.datos,
        });
      });
  }

  handleError = (value, respuesta, tipo = "") => {
    if (!value) {
      return respuesta == 1 ? true : "Campo requerido";
    }

    if (tipo === "telefono") {
      if (value.length < 9) {
        return respuesta == 1 ? true : "El teléfono debe tener un largo de 9 dígitos";
      }
    }

    return false;
  };

  validaciones = () => {
    const { rut, riesgo, nombre, apellidoP, apellidoM, sexo, fechaNacimiento, direccion, telefono, cesfamUsuario, sector } = this.state;

    const camposVacios = [rut, riesgo, nombre, apellidoP, apellidoM, sexo, fechaNacimiento, direccion, telefono, cesfamUsuario, sector];

    if (camposVacios.includes("") || camposVacios.includes(null) || telefono.length < 9) {
      NotificationManager.warning("Hay campos con error");
      return false;
    }

    return true;
  };

  render() {
    const validar = this.validaciones;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario agregarCaso">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="">
                <GridContainer className="agendar-container">
                  <GridItem xs={12}>
                    <h2>Agregar Caso</h2>
                  </GridItem>
                  <GridItem xs={6} style={{ marginTop: 30 }}>
                    <TextField id="rut" style={{ width: "80%" }} name="rut" label="Rut" value={this.state.rut} onChange={this.cambiarValores} error={this.handleError(this.state.rut, 1)} helperText={this.handleError(this.state.rut, 2)} />
                  </GridItem>
                  {this.state.tipo == 1 || this.state.tipo == 2 ? (
                    <GridItem xs={6} style={{ marginTop: 30 }}>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Riesgo</InputLabel>
                        <Select style={{ marginTop: 8, width: "150%" }} labelId="demo-simple-select-outlined-label" id="riesgo" name="riesgo" value={this.state.riesgo} onChange={this.cambiarValores} label="Riesgo">
                          <MenuItem value={"Alto"}>Alto</MenuItem>
                          <MenuItem value={"Medio"}>Medio</MenuItem>
                          <MenuItem value={"Bajo"}>Bajo</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  ) : null}

                  {this.state.nuevoUsuario ? (
                    <React.Fragment>
                      <GridItem xs={6} style={{ marginTop: 30 }}>
                        <TextField style={{ width: "80%" }} id="nombre" name="nombre" label="Nombre" value={this.state.nombre} onChange={this.cambiarValores} error={this.handleError(this.state.nombre, 1)} helperText={this.handleError(this.state.nombre, 2)} />
                      </GridItem>

                      <GridItem xs={6} style={{ marginTop: 30 }}>
                        <TextField style={{ width: "80%" }} id="apellidoP" name="apellidoP" label="Apellido paterno" value={this.state.apellidoP} onChange={this.cambiarValores} error={this.handleError(this.state.apellidoP, 1)} helperText={this.handleError(this.state.apellidoP, 2)} />
                      </GridItem>

                      <GridItem xs={6} style={{ marginTop: 30 }}>
                        <TextField style={{ width: "80%" }} id="apellidoM" name="apellidoM" label="Apellido Materno" value={this.state.apellidoM} onChange={this.cambiarValores} error={this.handleError(this.state.apellidoM, 1)} helperText={this.handleError(this.state.apellidoM, 2)} />
                      </GridItem>
                      <GridItem xs={6} style={{ marginTop: 30, marginLeft: -15 }} className="inputSexo">
                        <GridItem xs={12}>
                          <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">Sexo</InputLabel>
                            <Select style={{ marginTop: 8, width: "150%" }} labelId="demo-simple-select-outlined-label" id="sexo" name="sexo" value={this.state.sexo} onChange={this.cambiarValores} label="Sexo">
                              <MenuItem value={"F"}>Femenino</MenuItem>
                              <MenuItem value={"M"}>Masculino</MenuItem>
                              <MenuItem value={"I"}>Intersex</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridItem>

                      <GridItem xs={6} style={{ marginTop: 25 }}>
                        <KeyboardDatePicker
                          disableToolbar
                          invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                          disableFuture="true"
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha de nacimiento"
                          inputValue={this.state.fechaNacimiento}
                          name="fechaNacimiento"
                          onChange={(e) => this.cambiarValorFecha(e)}
                          KeyboardButtonProps={{
                            "aria-label": "cambiar fecha",
                          }}
                          error={this.handleError(this.state.fechaNacimiento, 1)}
                          helperText={this.handleError(this.state.fechaNacimiento, 2)}
                        />
                      </GridItem>

                      <GridItem xs={6} style={{ marginTop: 30 }}>
                        <TextField style={{ width: "80%" }} id="direccion" name="direccion" label="Dirección" value={this.state.direccion} onChange={this.cambiarValores} error={this.handleError(this.state.direccion, 1)} helperText={this.handleError(this.state.direccion, 2)} />
                      </GridItem>

                      <GridItem xs={6} style={{ marginTop: 25 }}>
                        <TextField
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                          }}
                          id="standard-basic"
                          label="Teléfono"
                          value={this.state.telefono}
                          style={{ with: 300 }}
                          type="number"
                          name="telefono"
                          onChange={this.cambiarValores}
                          error={this.handleError(this.state.telefono, 1, "telefono")}
                          helperText={this.handleError(this.state.telefono, 2, "telefono")}
                          onInput={(e) => {
                            e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} style={{ marginTop: 30 }}>
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Cesfam</InputLabel>
                          <Select style={{ marginTop: 8, width: "150%" }} labelId="demo-simple-select-outlined-label" id="cesfamUsuario" placeholder="Select..." name="cesfamUsuario" value={this.state.cesfamUsuario} onChange={this.cambioCesfam} error={this.handleError(this.state.cesfamUsuario, 1)}>
                            <MenuItem value={30}>Sin Cesfam</MenuItem>
                            <MenuItem value={1}>Padre Hurtado</MenuItem>
                            <MenuItem value={2}>Santa Julia</MenuItem>
                            <MenuItem value={3}>Félix de Amesti</MenuItem>
                          </Select>
                          {this.handleError(this.state.cesfamUsuario, 1) && (
                            <div
                              style={{
                                color: "#f44336",
                                paddingTop: 5,
                                fontSize: 12,
                              }}
                            >
                              {this.handleError(this.state.cesfamUsuario, 2)}
                            </div>
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem xs={6} style={{ marginTop: 30 }}>
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Sector</InputLabel>
                          <Select
                            style={{
                              marginTop: 8,
                              width: "150%",
                            }}
                            labelId="demo-simple-select-outlined-label"
                            id="sector"
                            name="sector"
                            value={this.state.sector}
                            defaultValue=""
                            onChange={this.cambiarValores}
                            label="Sectores"
                            error={this.handleError(this.state.sector, 1)}
                          >
                            <MenuItem value="" disabled>
                              <em>Seleccione sector</em>
                            </MenuItem>
                            {this.state.sectores &&
                              this.state.sectores.map((sec, index) => (
                                <MenuItem value={sec.sec_id} key={index}>
                                  {sec.sec_nombre}
                                </MenuItem>
                              ))}
                          </Select>
                          {this.handleError(this.state.sector, 1) && (
                            <div
                              style={{
                                color: "#f44336",
                                paddingTop: 5,
                                fontSize: 12,
                              }}
                            >
                              {this.handleError(this.state.sector, 2)}
                            </div>
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} style={{ marginTop: 20 }}>
                        <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                          Cancelar
                        </Button>
                        <Button onClick={this.guardarUsuario} color="primary" className="fondoVerde">
                          Agregar
                        </Button>
                      </GridItem>
                    </React.Fragment>
                  ) : (
                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                        Cancelar
                      </Button>
                      <Button onClick={() => this.enviarFormulario(false)} color="primary" className="fondoVerde">
                        Agregar
                      </Button>
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
          {this.state.modalAlertaHTML ? <AlertaHTML ocultar={this.ocultarAlertaHTML} mensaje={this.state.mensajeAlerta} agregarCaso={this.agregarCasoAlertaHTML} /> : null}
        </Dialog>
      </MuiPickersUtilsProvider>
    );
  }
}
