// core components
import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "store/index";
import "moment/locale/es";
import { connect } from "react-redux";
import axios from "axios";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

//JS
import HistorialComunaDialog from "./Dialog/HistorialComunaDialog";
import CargandoSimple from "./CargandoSimple";
import VisualTicket from "./Ticket/VisualTicket";
import SolicitudRealizadasJS from "./Ticket/SolicitudesRealizadas";
import SolicitudRespuestasJS from "./Ticket/SolicitudesRespuestas";

//IMAGENES
import SolicitudEstado from "../../assets/img/solicitud_trabajo/iconos/estado_de_solicitud.svg";
import SolicitudRealizadas from "../../assets/img/solicitud_trabajo/iconos/solicitudes_realizadas.svg";
import SolicitudRespuestas from "../../assets/img/solicitud_trabajo/iconos/respuesta_de_solicitudes.svg";
import SolicitudRespuestasSiPunto from "../../assets/img/solicitud_trabajo/iconos/respuesta_de_solicitudes_sin_punto.svg";

//CSS
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDeAlerta: false,
      modal: false,
      hisoria_comuna_dialog: false,
      cargando: false,

      solicitudes: [],
      infoTicket: [],
      historial: [],
      historialComunalData: [],
      herramientas: store.getState().herramientas,

      pasoSolicitud: 1,
      cuenta: 0,
    };
  }

  componentDidMount() {
    this.titulo();
    this.cuentaSolicitudesTerreno();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Solicitud de trabajo<span title='Solicitud de trabajo'></span>";
  };

  cuentaSolicitudesTerreno = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "solicitudes_visitas_horarios/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
        },
      })
      .then((res) => {
        this.setState({
          cuenta: res.data.cuenta,
        });
      });
  };

  cerrar = () => {
    this.setState({
      modalDeAlerta: false,
    });
  };

  pasoSolicitud = (paso) => {
    this.setState({ pasoSolicitud: paso });
  };

  render() {
    const { herramientas, cuenta, pasoSolicitud, infoTicket, historial, hisoria_comuna_dialog, historialComunalData, cargando } = this.state;
    return (
      <>
        <div className="middle-area" style={{ height: "80%" }}>
          <div className="middle-content" style={{ marginTop: 0 }}>
            <GridContainer style={{ width: "90%" }}>
              <GridItem xs={12} sm={12} md={12} style={{ display: "flex", marginBottom: 35 }}>
                <div className={`${SolicitudTrabajo.botonesIconosPrincipal} ${pasoSolicitud == 1 && SolicitudTrabajo.botonesIconosPrincipalSelected}`} style={{ marginRight: 15 }} onClick={() => this.pasoSolicitud(1)}>
                  <img src={SolicitudEstado} />
                </div>
                <div className={`${SolicitudTrabajo.botonesIconosPrincipal} ${pasoSolicitud == 2 && SolicitudTrabajo.botonesIconosPrincipalSelected}`} style={{ marginRight: 15 }} onClick={() => this.pasoSolicitud(2)}>
                  <img src={SolicitudRealizadas} />
                </div>
                {herramientas.includes("H50") && (
                  <div className={`${SolicitudTrabajo.botonesIconosPrincipal} ${pasoSolicitud == 3 && SolicitudTrabajo.botonesIconosPrincipalSelected}`} style={{ marginRight: 15 }} onClick={() => this.pasoSolicitud(3)}>
                    <img src={cuenta > 0 ? SolicitudRespuestas : SolicitudRespuestasSiPunto} />
                    {cuenta > 0 && <span className={`${pasoSolicitud == 3 ? SolicitudTrabajo.tamanoCuentaRespuesta : SolicitudTrabajo.tamanoCuentaRespuesta2}`}>{cuenta}</span>}
                  </div>
                )}
              </GridItem>

              {pasoSolicitud == 1 && <VisualTicket info={infoTicket} historial={historial} />}
              {pasoSolicitud == 2 && <SolicitudRealizadasJS />}
              {pasoSolicitud == 3 && <SolicitudRespuestasJS cuentaSolicitudesTerreno={this.cuentaSolicitudesTerreno} />}
            </GridContainer>
          </div>
        </div>

        {/* MODALES */}
        {hisoria_comuna_dialog && <HistorialComunaDialog data={historialComunalData} cerrarModal={() => this.HistorialDialog(false)} />}

        {cargando && <CargandoSimple />}
      </>
    );
  }
}

export default Menu;
