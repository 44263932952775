import React from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "./../../components/titulo/Link.js";
import { FaQuestionCircle } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { store } from "./../../store/index";
import { connect } from "react-redux";

function Titulo(props) {
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        {props.links.map((link, index) => (
          <Link className="breadcumb" key={index} nombre={link[0]} url={link[1]} tipo={1} />
        ))}
        <Link nombre={props.linkActual} tipo={2} />
      </Breadcrumbs>

      <h1 className="content-titulo" style={store.getState().tipo == 2 ? { margin: "0" } : null}>
        {props.titulo}
        <Tooltip title={props.descripcion}>
          <span>
            <FaQuestionCircle className={"info-titulo"} />{" "}
          </span>
        </Tooltip>
      </h1>
    </div>
  );
}

export default Titulo;
