import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";

import { connect } from "react-redux";
import chileanRut from "chilean-rut";

// core components
import ver_mas from "assets/img/iconos correccion/ver mas.svg";
import telefono from "assets/img/usuarios/telefono-datos-o.svg";
import modi_icon from "assets/img/usuarios/icono_modificar.svg";

import Paginacion from "components/Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Buttons from "react-multi-date-picker/components/button";

import MenuItem from "@material-ui/core/MenuItem";
import TableHeader from "components/Table/TableHeader";
import UsuarioEnTratamiento from "components/ListaDeEspera/UsuarioEnTratamiento";
import UsuarioObservacionFija from "components/ListaDeEspera/UsuarioObservacionFija";
import UsuarioCrearObservacionLibre from "components/ListaDeEspera/UsuarioCrearObservacionLibre";
import VerObservacionesLibre from "components/ListaDeEspera/VerObservacionesLibres";
import VerTelefonos from "components/ListaDeEspera/VerTelefonos";
import NotificationManager from "react-notifications/lib/NotificationManager";

import VistaUsuarios from "components/GestionPrioritarios/VistaUsuarios";

import { Input } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";

import Ver_detalle_llamadas from "components/ListaDeEspera/Ver_detalle_llamadas";

import EditarRutListaOirs from "components/ListaDeEspera/EditarRutListaOirs";
import EditarFechaRegistro from "components/ListaDeEspera/EditarFechaRegistro";
import ModificarUsuarioTratamiento from "components/ListaDeEspera/ModificarUsuarioTratamiento";
import EditarDatos from "components/ListaDeEspera/EditarDatos";

class ListaDeEspera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,

      datos: [],

      loading: false,
      pagina: 0,
      total: 0,
      filas: 10,
      nombreTitulo: [
        { nombre: "Fecha inscripción", filtro: "loi_fecharegistro" },
        { nombre: "Rut", filtro: "loi_rut" },
        { nombre: "Nombres", filtro: "loi_nombre" },
        { nombre: "Edad", filtro: "loi_fecha_nacimiento" },
        { nombre: "Género", filtro: "loi_sexo" },
        { nombre: "Cesfam", filtro: "loi_cesfam" },
        { nombre: "Teléfonos", filtro: "nulo" },
        { nombre: "App móvil", filtro: "loi_app_movil" },
        { nombre: "Programa", filtro: "nulo" },
        { nombre: "Observación", filtro: "nulo" },
        { nombre: "Observación libre", filtro: "loi_observacion" },
      ],
      ordenarPrioridad: null,
      listadoOirs: [],
      mostrarModalTratamientos: false,
      mostrarModalObservacionFija: false,
      mostrarModalCrearObservacionLibre: false,
      mostrarModalObservacionLibre: false,
      mostrarModalTelefonos: false,

      loi_id: null,
      texto_filtro: props.busqueda,
      cesfams: [],
      genero: null,
      cesfam_b: null,
      edad: null,
      fecha2: null,
      fecha1: null,
      edad2: null,

      loi_rut: null,
      loi_fecha_registro: null,
      programa_cita: null,
      id_usuario: null,
      estado_gestion: 0,
      tipo_lista: 0,
      tratamientos: [],
      tratamiento: null,
      tratamiento_secundarios: [],
      trat_sec: null,

      mostrarDetalle: false,
      modalUsuario: false,
      mostrarModalEditarRut: false,
      mostrarModalEditarFechaRe: false,
      mostrarModalAlertaModificarTto: false,
    };
  }

  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });
  };

  componentDidMount() {
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
    this.obtenerCesfams();
    this.obtenerCesfamGestion();
    this.obtenerTratamientos();
    this.obtenerTratamientoSec();
  }

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.obtenerTratamientoOirs(this.state.pagina, filas, this.state.ordenarPrioridad);
  };

  mostrarModalUsuario = (idusuario) => {
    if (idusuario) {
      this.setState({
        modalUsuario: true,
        id_usuario: idusuario,
      });
    }
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  obtenerCesfamGestion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfam_gestion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            estado_gestion: 1,
            nombreTitulo: [
              { nombre: "Fecha inscripción", filtro: "loi_fecharegistro" },
              { nombre: "Rut", filtro: "loi_rut" },
              { nombre: "Nombres", filtro: "loi_nombre" },
              { nombre: "Edad", filtro: "loi_fecha_nacimiento" },
              { nombre: "Género", filtro: "loi_sexo" },
              { nombre: "Cesfam", filtro: "loi_cesfam" },
              { nombre: "Teléfonos", filtro: "nulo" },
              { nombre: "App móvil", filtro: "loi_app_movil" },
              { nombre: "Programa", filtro: "nulo" },
              { nombre: "Observación", filtro: "nulo" },
              { nombre: "Observación libre", filtro: "loi_observacion" },
              { nombre: "Detalle", filtro: "nulo" },
            ],
          });
        } else {
          this.setState({
            estado_gestion: 0,
          });
        }
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.obtenerTratamientoOirs(pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  handleFiltradorCampo = (ordenarPrioridad) => {
    this.setState({
      ordenarPrioridad: ordenarPrioridad,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, ordenarPrioridad);
  };

  LimpiarFiltros = () => {
    this.setState(
      {
        cesfam_b: null,
        genero: null,
        edad: "",
        edad2: "",
        fecha2: "",
        fecha1: "",
        texto_filtro: "",
        tratamiento: null,
        trat_sec: null,
      },
      () => {
        this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
      }
    );
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  setearNull = () => {
    this.setState(
      {
        cesfam_b: null,
        genero: null,
        edad: null,
        edad2: null,
        fecha2: null,
        fecha1: null,
        texto_filtro: null,
      },
      () => {
        this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
      }
    );
  };

  obtenerTratamientoSec = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamiento_secundarios: res.data.datos,
          });
        }
      });
  };

  obtenerTratamientoOirs = async (pagina, fila, filtrador) => {
    axios
      .get(global.config.modulos.listaEspera + "ver_oirs/", {
        headers: authHeader(),
        params: {
          pagina: pagina,
          ordenarPrioridad: filtrador,
          textofiltro: this.state.texto_filtro,
          genero: this.state.genero,
          cesfam_b: this.state.cesfam_b,
          edad: this.state.edad,
          edad2: this.state.edad2,
          FechaInicio: this.state.fecha1,
          FechaTermino: this.state.fecha2,
          cesfam_admin: this.state.cesfam,
          tratamiento: this.state.tratamiento,
          tratamiento_sec: this.state.trat_sec,
          tipo: 1,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            total: res.data.total,
            listadoOirs: res.data.datos,
            pagina: res.data.pagina,
            ordenarPrioridad: res.data.prioridad,
          });
        } else {
          this.setState({
            total: 0,
            listadoOirs: [],
            pagina: 0,
            ordenarPrioridad: null,
          });
        }
      });
  };

  abrirModalTratamientos = (loi_id, loi_tratamiento) => {
    this.setState({
      mostrarModalTratamientos: true,
      loi_id: loi_id,
      programa_cita: loi_tratamiento,
    });
  };

  cerrarModalTratamientos = () => {
    this.setState({
      mostrarModalTratamientos: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalObservacionFija = (loi_id) => {
    this.setState({
      mostrarModalObservacionFija: true,
      loi_id: loi_id,
    });
  };

  tratamiento_sec = (e) => {
    const trat_sec = e.target.value;
    this.setState({ trat_sec: trat_sec });
  };

  cerrarModalObservacionFija = () => {
    this.setState({
      mostrarModalObservacionFija: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModaldetalle = (loi_id) => {
    this.setState({
      mostrarDetalle: true,
      loi_id: loi_id,
    });
  };

  cerrarModaldetalle = () => {
    this.setState({
      mostrarDetalle: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalCrearObservacionLibre = (loi_id) => {
    this.setState({
      mostrarModalCrearObservacionLibre: true,
      loi_id: loi_id,
    });
  };

  cerrarModalCrearObservacionLibre = () => {
    this.setState({
      mostrarModalCrearObservacionLibre: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalObservacionesLibre = (loi_id) => {
    this.setState({
      mostrarModalObservacionLibre: true,
      loi_id: loi_id,
    });
  };

  cerrarModalObservacionLibre = () => {
    this.setState({
      mostrarModalObservacionLibre: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  aplicarFiltros = () => {
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  filtroTexto = (e) => {
    const texto_filtro = e.target.value;
    this.setState({ texto_filtro: texto_filtro });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  obtenerCesfams = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_cesfams/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            cesfams: res.data.datos,
          });
        }
      });
  };

  abrirModalTelefonos = (loi_id) => {
    this.setState({
      mostrarModalTelefonos: true,
      loi_id: loi_id,
    });
  };

  cerrarModalTelefonos = () => {
    this.setState({
      mostrarModalTelefonos: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalEditarRut = (loi_id, loi_rut) => {
    this.setState({
      mostrarModalEditarRut: true,
      loi_id: loi_id,
      loi_rut: loi_rut,
    });
  };

  cerrarModalEditarRut = () => {
    this.setState({
      mostrarModalEditarRut: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalEditarFecha = (loi_id, loi_fecha_registro) => {
    this.setState({
      mostrarModalEditarFechaRe: true,
      loi_id: loi_id,
      loi_fecha_registro: loi_fecha_registro,
    });
  };

  cerrarModalEditarFecha = () => {
    this.setState({
      mostrarModalEditarFechaRe: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  AlertaModificarTto = (loi_id, loi_tratamiento) => {
    this.setState({
      mostrarModalAlertaModificarTto: true,
      loi_id: loi_id,
      programa_cita: loi_tratamiento,
    });
  };

  CerrarAlertaModificarTto = () => {
    this.setState({
      mostrarModalAlertaModificarTto: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  descargarExcel = async () => {
    this.setState({ loading: true });
    NotificationManager.warning("Se inicio proceso de descarga de excel esto puede tardar unos segundos espere por favor", "Descargando....", 3000);
    await axios.get((window.location.href = global.config.modulos.listaEspera + "Excel_lista_espera/?FechaInicio=" + this.state.fecha1 + "&FechaTermino=" + this.state.fecha2 + "&genero=" + this.state.genero + "&cesfam=" + this.state.cesfam_b + "&edad=" + this.state.edad + "&textofiltro=" + this.state.texto_filtro + "&cesfam_admin=" + this.state.cesfam), { headers: authHeader() }).then((res) => {
      this.setState({ loading: false });
    });
  };

  mostrarModalEditar = (loi_id, tipo, dato) => {
    this.setState({
      mostrarModalEditar: true,
      loi_id: loi_id,
      tipoEditar: tipo,
      datoEditar: dato,
    });
  };

  cerrarModalEditar = () => {
    this.setState({
      mostrarModalEditar: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  render() {
    return (
      <div className="middle-content">
        <div style={{ display: "flex" }}>
          <FormControl style={{ width: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Nombre o Rut</InputLabel>
            <Input type="text" name="texto_filtro" value={this.state.texto_filtro} onChange={this.filtroTexto} />
          </FormControl>

          <FormControl style={{ width: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Genero</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" name="genero" value={this.state.genero} onChange={this.cambiarValores}>
              <MenuItem value={null}>--Seleccione Sexo--</MenuItem>
              <MenuItem value={"m"}>Masculino</MenuItem>
              <MenuItem value={"f"}>Femenino</MenuItem>
              <MenuItem value={"i"}>InterSex</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ width: "7%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Edad</InputLabel>
            <Input type="number" labelId="demo-simple-select-label" id="demo-simple-select" name="edad" value={this.state.edad} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ width: "7%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Edad 2</InputLabel>
            <Input type="number" labelId="demo-simple-select-label" id="demo-simple-select" name="edad2" value={this.state.edad2} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ width: "8%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Cesfam</InputLabel>
            <Select name="cesfam_b" value={this.state.cesfam_b} onChange={this.cambiarValores}>
              <MenuItem value={null}>Selecciona Cesfams</MenuItem>
              {this.state.cesfams.map((tratamiento, i) => {
                return (
                  <MenuItem key={i} value={tratamiento.ces_id}>
                    {tratamiento.ces_nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Buttons onClick={this.aplicarFiltros} className={"btnOpcion fondoNaranjo"}>
            Filtro
          </Buttons>

          <Buttons onClick={this.LimpiarFiltros} className={"btnOpcion fondoNaranjo"}>
            Limpiar Filtro
          </Buttons>
        </div>

        <div
          style={{
            margin: "1% 0px",
          }}
        >
          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <Input type="date" labelId="demo-simple-select-label" id="demo-simple-select" name="fecha1" value={this.state.fecha1} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <Input type="date" labelId="demo-simple-select-label" id="demo-simple-select" name="fecha2" value={this.state.fecha2} onChange={this.cambiarValores}></Input>
          </FormControl>
        </div>

        <TableContainer style={{ marginBottom: "120px" }}>
          <Table className="tablaGeneralGestion bordeTabla" aria-label="simple table">
            <TableHeader nombreTitulo={this.state.nombreTitulo} datocampo={this.handleFiltradorCampo} />

            <TableBody>
              {this.state.listadoOirs.map((row, index) => (
                <TableRow key={index}>
                  {row.loi_fecha_invalida === 1 ? (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h4>{["Código: ", row.loi_fila_b1]}</h4>}>
                          <p style={{ fontSize: "14px" }}>{moment(row.loi_fecharegistro).format("DD-MM-YYYY")}</p>
                        </Tooltip>
                      </React.Fragment>
                      <React.Fragment>
                        <Tooltip title={<h4>Modificar fecha registro</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalEditarFecha(row.loi_id, row.loi_fecha_texto_invalida)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h4>{["Código: ", row.loi_fila_b1]}</h4>}>
                          <p style={{ fontSize: "14px" }}>{moment(row.loi_fecharegistro).format("DD-MM-YYYY")}</p>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  )}

                  {row.loi_rut_valido === 1 ? (
                    <TableCell align="center">
                      <p style={{ fontSize: "14px" }}>{row.loi_rut}</p>{" "}
                      <React.Fragment>
                        <Tooltip title={<h4>Modificar Rut</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalEditarRut(row.loi_id, row.loi_rut)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : (
                    <TableCell>
                      {
                        <a style={{ cursor: "pointer" }} onClick={() => this.mostrarModalUsuario(row.loi_usuario)}>
                          {chileanRut.format(row.loi_rut, true)}
                        </a>
                      }
                    </TableCell>
                  )}

                  <TableCell align="center">
                    <Tooltip title={<h4>{["Fecha de Nacimiento: ", row.nacimiento]}</h4>}>
                      <p style={{ fontSize: "14px" }}>{row.loi_nombre} </p>
                    </Tooltip>
                  </TableCell>

                  <TableCell align="center">{row.edad}</TableCell>
                  <TableCell align="center">{row.sexo}</TableCell>
                  <TableCell align="center">{row.cesfam_nombre}</TableCell>
                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip title={<h4>Ver télefonos del paciente</h4>}>
                        <div>
                          <img class="mediumIcon" src={telefono} onClick={() => this.abrirModalTelefonos(row.loi_id)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>
                  <TableCell align="center">{row.estado_app}</TableCell>

                  {row.nombre_tratamiento ? (
                    <TableCell align="center">
                      {row.nombre_tratamiento}
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "15px",
                          marginTop: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <React.Fragment>
                          <Tooltip title={<h4>Ingresar paciente a tratamiento</h4>}>
                            <div>
                              <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalTratamientos(row.loi_id, row.loi_tratamiento)}></img>
                            </div>
                          </Tooltip>
                        </React.Fragment>
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      {row.nombre_tratamiento}
                      <React.Fragment>
                        <Tooltip title={<h4>Ingresar paciente a tratamiento</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalTratamientos(row.loi_id, null)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  )}

                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip title={<h4>Ingresar Observación</h4>}>
                        <div>
                          <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalObservacionFija(row.loi_id)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>
                  <TableCell align="justify">
                    {row.observacion_usuario}
                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <React.Fragment>
                        <Tooltip title={<h4>ver observacion libre</h4>}>
                          <div style={{ marginRight: "10px" }}>
                            <img class="mediumIcon" src={ver_mas} onClick={() => this.abrirModalObservacionesLibre(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                      <React.Fragment>
                        <Tooltip title={<h4>Ingresar observacion libre</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalCrearObservacionLibre(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </div>
                  </TableCell>
                  {this.state.estado_gestion === 1 ? (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h4>Ver detalle</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModaldetalle(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
        </TableContainer>

        {this.state.mostrarModalTratamientos ? <UsuarioEnTratamiento ocultar={this.cerrarModalTratamientos} id_usuario={this.state.loi_id} programa_cita={this.state.programa_cita} tipo_lista={this.state.tipo_lista}></UsuarioEnTratamiento> : null}
        {this.state.mostrarModalObservacionFija ? <UsuarioObservacionFija ocultar={this.cerrarModalObservacionFija} id_usuario={this.state.loi_id}></UsuarioObservacionFija> : null}
        {this.state.mostrarModalCrearObservacionLibre ? <UsuarioCrearObservacionLibre ocultar={this.cerrarModalCrearObservacionLibre} id_usuario={this.state.loi_id}></UsuarioCrearObservacionLibre> : null}
        {this.state.mostrarModalObservacionLibre ? <VerObservacionesLibre ocultar={this.cerrarModalObservacionLibre} id_usuario={this.state.loi_id}></VerObservacionesLibre> : null}
        {this.state.mostrarModalTelefonos ? <VerTelefonos ocultar={this.cerrarModalTelefonos} id_usuario={this.state.loi_id}></VerTelefonos> : null}
        {this.state.mostrarModalEditarRut ? <EditarRutListaOirs ocultar={this.cerrarModalEditarRut} id_usuario={this.state.loi_id} rut={this.state.loi_rut}></EditarRutListaOirs> : null}
        {this.state.mostrarModalEditarFechaRe ? <EditarFechaRegistro ocultar={this.cerrarModalEditarFecha} id_usuario={this.state.loi_id} fecha_inv={this.state.loi_fecha_registro}></EditarFechaRegistro> : null}
        {this.state.mostrarModalAlertaModificarTto ? <ModificarUsuarioTratamiento ocultar={this.CerrarAlertaModificarTto} id_usuario={this.state.loi_id} programa_cita={this.state.programa_cita}></ModificarUsuarioTratamiento> : null}
        {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.id_usuario}></VistaUsuarios> : null}
        {this.state.mostrarDetalle ? <Ver_detalle_llamadas ocultar={this.cerrarModaldetalle} id_oirs={this.state.loi_id}></Ver_detalle_llamadas> : null}
        {this.state.mostrarModalEditar ? <EditarDatos ocultar={this.cerrarModalEditar} registro={this.state.loi_id} tipo={this.state.tipoEditar} dato={this.state.datoEditar}></EditarDatos> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaDeEspera);
