import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import moment from "moment";
import "moment/locale/es";

import { store } from "../../../store/index";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Grid, List, ListItem } from "@material-ui/core";

// core components
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import MaterialTable from "material-table";

import AgregarCarga from "./AgregarCarga.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import icono_eliminar from "assets/img/usuarios/icono_eliminar.svg";

import chileanRut from "chilean-rut";

//CSS
import crearTotem from "assets/css/crearTotem.module.css";

class ListaUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usu_id: 0,
      cesfam: store.getState().cesfam,
      cargarUsuarios: false,
      agregarUsuario: false,
      agregarDatos: false,
      modificarUsuario: false,
    };
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  componentDidMount() {
    this.fetchUsuarios();
  }

  fetchUsuarios = async () => {
    const apiUrl = global.config.modulos.admin_totem + "usuarios/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ usuarios: res.data.usuarios });
    });
  };

  cargarUsuarios = (e) => {
    this.setState({ cargarUsuarios: true });
  };

  ocultarCargar = () => {
    this.setState({ cargarUsuarios: false });
    this.fetchUsuarios();
  };

  alertaEliminarUsuario = (usu_id) => {
    confirmAlert({
      title: "",
      message: "Deseas eliminar este paciente",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminar_usuario(usu_id),
          className: "btnAceptar",
        },
      ],
    });
  };

  eliminar_usuario = (usu_id) => {
    const apiUrl = global.config.modulos.admin_totem + "usuario_por_id/?usu_id=" + usu_id;
    axios.delete(apiUrl, { headers: authHeader() }).then((res) => {
      this.fetchUsuarios();
    });
  };

  modificarUsuario = (usu_id) => {
    this.setState(
      {
        modificarUsuario: true,
        usu_id: usu_id,
      },
      () => {
        this.datos_usuario(usu_id);
      }
    );
  };

  datos_usuario = (usu_id) => {
    const apiUrl = global.config.modulos.admin_totem + "usuario_por_id/?usu_id=" + usu_id;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      document.getElementById("nombre").value = res.data.usu_nombre;
      document.getElementById("apellido").value = res.data.usu_apellido_p;
      document.getElementById("selectSexo").value = res.data.usu_sexo;
      document.getElementById("fechaNacimiento").value = res.data.usu_fecha_nacimiento;
      document.getElementById("selectDisc").value = res.data.usu_discapacidad;
    });
  };

  validacionFuncionarios = () => {
    var nombre = document.getElementById("nombre").value;
    var apellido = document.getElementById("apellido").value;
    var fechaNacimiento = document.getElementById("fechaNacimiento").value;

    if (!nombre) {
      this.mostrarAlerta("Escribe el nombre.");
      return false;
    } else if (!apellido) {
      this.mostrarAlerta("Escribe el apellido.");
      return false;
    } else if (!fechaNacimiento) {
      this.mostrarAlerta("Ingresa la fecha de nacimiento.");
      return false;
    }

    return true;
  };

  modificarUsuarioD = () => {
    if (this.validacionFuncionarios()) {
      var nombre = document.getElementById("nombre").value;
      var apellido = document.getElementById("apellido").value;
      var selectSexo = document.getElementById("selectSexo").value;
      var fechaNacimiento = document.getElementById("fechaNacimiento").value;
      var selectDisc = document.getElementById("selectDisc").value;

      var data = {
        usu_id: this.state.usu_id,
        nombre: nombre,
        apellido_p: apellido,
        sexo: selectSexo,
        fechanacimiento: fechaNacimiento,
        discapacidad: selectDisc,
      };
      axios.patch(global.config.modulos.admin_totem + "usuario_por_id/", data, { headers: authHeader() }).then((res) => {
        this.salirModificar();
        this.fetchUsuarios();
        this.mostrarAlerta("Modificado con éxito");
      });
    }
  };

  salirModificar = () => {
    this.setState({
      modificarUsuario: false,
      usu_id: 0,
    });
  };

  agregarUsuario = (e) => {
    this.setState({ agregarUsuario: true, agregarDatos: false, usu_id: 0 });
  };

  ocultarAgregar = () => {
    this.setState({ agregarUsuario: false, usu_id: 0 });
    this.fetchUsuarios();
  };

  validaciones() {
    var rut = document.getElementById("rut").value;

    if (!rut) {
      this.mostrarAlerta("Escribe el rut.");
      return false;
    } else if (!chileanRut.validate(rut)) {
      this.mostrarAlerta("Escribe el rut válido.");
      return false;
    }

    return true;
  }

  buscar_usuario = () => {
    if (this.validaciones()) {
      var rut = document.getElementById("rut").value;

      var data = {
        usu_rut: rut,
      };

      axios.post(global.config.modulos.admin_totem + "usuario_por_rut/", data, { headers: authHeader() }).then((res) => {
        var data = res.data;
        var estado = data.estado;

        if (estado) {
          this.setState({ agregarDatos: true, usu_id: res.data.usu_id });

          document.getElementById("btnBuscar").disabled = true;
          document.getElementById("rut").disabled = true;

          document.getElementById("nombre").value = res.data.usu_nombre;
          document.getElementById("apellido").value = res.data.usu_apellido_p;
          document.getElementById("selectSexo").value = res.data.usu_sexo;
          document.getElementById("fechaNacimiento").value = res.data.usu_fecha_nacimiento;
          document.getElementById("selectDisc").value = res.data.usu_discapacidad;
        } else {
          document.getElementById("btnBuscar").disabled = true;
          document.getElementById("rut").disabled = true;
          this.setState({ agregarDatos: true, usu_id: 0 });
        }
      });
    }
  };

  agregarUsuarioD = () => {
    if (this.validacionFuncionarios()) {
      if (this.state.usu_id != 0) {
        var nombre = document.getElementById("nombre").value;
        var apellido = document.getElementById("apellido").value;
        var selectSexo = document.getElementById("selectSexo").value;
        var fechaNacimiento = document.getElementById("fechaNacimiento").value;
        var selectDisc = document.getElementById("selectDisc").value;

        var data = {
          usu_id: this.state.usu_id,
          nombre: nombre,
          apellido_p: apellido,
          sexo: selectSexo,
          fechanacimiento: fechaNacimiento,
          discapacidad: selectDisc,
        };

        axios.patch(global.config.modulos.admin_totem + "usuario_por_id/", data, { headers: authHeader() }).then((res) => {
          this.ocultarAgregar();
          this.fetchUsuarios();
          this.mostrarAlerta("Agregado con éxito");
        });
      } else {
        var rut = document.getElementById("rut").value;
        var nombre = document.getElementById("nombre").value;
        var apellido = document.getElementById("apellido").value;
        var selectSexo = document.getElementById("selectSexo").value;
        var fechaNacimiento = document.getElementById("fechaNacimiento").value;
        var selectDisc = document.getElementById("selectDisc").value;

        var data = {
          rut: rut,
          nombre: nombre,
          apellido_p: apellido,
          sexo: selectSexo,
          fechanacimiento: fechaNacimiento,
          discapacidad: selectDisc,
          cesfam: this.state.cesfam,
        };

        axios.post(global.config.modulos.admin_totem + "agregar_usuario/", data, { headers: authHeader() }).then((res) => {
          this.ocultarAgregar();
          this.fetchUsuarios();
          this.mostrarAlerta("Agregado con éxito");
        });
      }
    }
  };

  render() {
    return (
      <div>
        <h1 className="content-titulo" style={{ color: "#666666", marginLeft: "1%", fontSize: "1.8rem" }}>
          Pacientes
        </h1>
        <GridContainer style={{ width: "100%", marginTop: "10px", "justify-content": "flex-end" }}>
          <a
            className={crearTotem.btnAgregarAcceso}
            style={{ width: "12%" }}
            onClick={(event) => {
              this.agregarUsuario();
            }}
          >
            {"Agregar paciente unitario"}
          </a>
          <a className={crearTotem.btnAgregarAcceso} style={{ width: "12%" }} onClick={(event) => this.cargarUsuarios()}>
            {"Agregar pacientes masivo"}
          </a>
          <GridItem xs={12} sm={12} md={12}>
            <div className={crearTotem.divBordeTablaAccesos} style={{ fontSize: "1rem !important" }}>
              <MaterialTable
                title=""
                columns={[
                  { title: "RUT", field: "usu_rut" },
                  { title: "Nombre", field: "nombre_usuario" },
                  { title: "Sexo", field: "usu_sexo" },
                  {
                    title: "Discapacidad",
                    field: "usu_discapacidad",
                    render: (rowData) => <React.Fragment>{rowData.usu_discapacidad == 1 ? "SÍ" : "NO"}</React.Fragment>,
                  },
                  {
                    title: "Fecha de nacimiento",
                    field: "usu_fecha_nacimiento",
                  },
                  {
                    title: "Acciones",
                    render: (rowData) => (
                      <React.Fragment>
                        <div>
                          <img className="botonesTablas" style={{ marginRight: "20px", width: "30px" }} src={icono_modificar} onClick={this.modificarUsuario.bind(this, rowData.usu_id)} />
                          <img className="botonesTablas" style={{ width: "30px" }} src={icono_eliminar} onClick={this.alertaEliminarUsuario.bind(this, rowData.usu_id)} />
                        </div>
                      </React.Fragment>
                    ),
                  },
                ]}
                data={this.state.usuarios}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                }}
              />
              {this.state.cargarUsuarios ? <AgregarCarga ocultar={this.ocultarCargar}></AgregarCarga> : null}
            </div>
          </GridItem>

          {this.state.modificarUsuario ? (
            <div>
              <Dialog open={this.state.modificarUsuario} onClose={this.salirModificar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
                <DialogContent>
                  <h1 style={{ color: "#666666" }}>Modificar paciente</h1>
                  <br></br>
                  <br></br>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={crearTotem.textosTitulos}>Nombre</InputLabel>
                        <input type="text" id={"nombre"} className={crearTotem.input} style={{ width: "100%" }} />
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={crearTotem.textosTitulos}>Apellido</InputLabel>
                        <input type="text" id={"apellido"} className={crearTotem.input} style={{ width: "100%" }} />
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={crearTotem.textosTitulos}>Sexo</InputLabel>
                        <NativeSelect className={crearTotem.selectores} disableUnderline={true} id={"selectSexo"} style={{ width: "100%" }}>
                          <option value="F">F</option>
                          <option value="M">M</option>
                          <option value="I">I</option>
                        </NativeSelect>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={crearTotem.textosTitulos}>Fecha de Nacimiento</InputLabel>
                        <input type="date" id={"fechaNacimiento"} className={crearTotem.input} style={{ width: "100%" }} />
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={crearTotem.textosTitulos}>Discapacidad</InputLabel>
                        <NativeSelect disableUnderline={true} className={crearTotem.selectores} id={"selectDisc"} style={{ width: "100%" }}>
                          <option value="0">No</option>
                          <option value="1">Sí</option>
                        </NativeSelect>
                      </Grid>
                    </Grid>
                  </Grid>

                  <br></br>
                  <br></br>

                  <a
                    className={"btnAcciones btnAgregarAcceso"}
                    onClick={() => {
                      this.modificarUsuarioD();
                    }}
                  >
                    {"Modificar"}
                  </a>
                </DialogContent>
              </Dialog>
            </div>
          ) : null}

          {this.state.agregarUsuario ? (
            <div>
              <Dialog open={this.state.agregarUsuario} onClose={this.ocultarAgregar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
                <DialogContent>
                  <h1 style={{ color: "#666666" }}>Agregar paciente</h1>
                  <br></br>
                  <br></br>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={6} sm={6}>
                            <InputLabel className={crearTotem.textosTitulos}>RUT</InputLabel>
                            <input type="text" id={"rut"} className={crearTotem.input} style={{ width: "100%" }} />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "flex-start",
                            }}
                          >
                            <button
                              id={"btnBuscar"}
                              className={crearTotem.btnAgregarAcceso}
                              style={{ width: "4vw" }}
                              onClick={() => {
                                this.buscar_usuario();
                              }}
                            >
                              {"Buscar"}
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>

                      {this.state.agregarDatos ? (
                        <>
                          <Grid item xs={4} sm={3}>
                            <InputLabel className={crearTotem.textosTitulos}>Nombre</InputLabel>
                            <input type="text" id={"nombre"} className={crearTotem.input} style={{ width: "100%" }} />
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <InputLabel className={crearTotem.textosTitulos}>Apellido</InputLabel>
                            <input type="text" id={"apellido"} className={crearTotem.input} style={{ width: "100%" }} />
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <InputLabel className={crearTotem.textosTitulos}>Sexo</InputLabel>
                            <NativeSelect className={crearTotem.selectores} disableUnderline={true} id={"selectSexo"} style={{ width: "100%" }}>
                              <option value="F">F</option>
                              <option value="M">M</option>
                              <option value="I">I</option>
                            </NativeSelect>
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <InputLabel className={crearTotem.textosTitulos}>Fecha de Nacimiento</InputLabel>
                            <input type="date" id={"fechaNacimiento"} className={crearTotem.input} style={{ width: "100%" }} />
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <InputLabel className={crearTotem.textosTitulos}>Discapacidad</InputLabel>
                            <NativeSelect className={crearTotem.selectores} disableUnderline={true} id={"selectDisc"} style={{ width: "100%" }}>
                              <option value="0">No</option>
                              <option value="1">Sí</option>
                            </NativeSelect>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>

                  <br></br>
                  <br></br>
                  {this.state.agregarDatos ? (
                    <a
                      className={crearTotem.btnAgregarAcceso}
                      style={{ width: "6vw" }}
                      onClick={() => {
                        this.agregarUsuarioD();
                      }}
                    >
                      {"Modificar"}
                    </a>
                  ) : null}
                </DialogContent>
              </Dialog>
            </div>
          ) : null}
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaUsuarios);
