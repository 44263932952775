import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem } from "@material-ui/core";
import { store } from "store/index";
import { confirmAlert } from "react-confirm-alert";
import { socketTotem } from "../../../service/socket";

import InputLabel from "@material-ui/core/InputLabel";

import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";
import MaterialTable from "material-table";

import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slider from "@material-ui/core/Slider";

import Cargando from "components/Helpers/Cargando.js";

// CSS
import crearTotem from "assets/css/crearTotem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class ConfigTv extends Component {
  constructor(props) {
    super(props);
    this.state = { totem: store.getState().totem, tam_banner: "" };
  }

  componentDidMount() {
    this.configurarTv();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  configurarTv = () => {
    this.publicidad_videos();
    this.configuracion_tv();
  };

  configuracion_tv = () => {
    const apiUrl = global.config.modulos.admin_totem + "configuraciones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
          tipo: 1,
        },
      })
      .then((res) => {
        var data = res.data;

        document.getElementById("textoBanner").value = data["cop_mensaje_banner"];
        document.getElementById("velocidadBanner").value = data["cop_velocidad_banner"];

        var volumenVideo = document.getElementById("volumenVideo");
        volumenVideo.getElementsByTagName("input")[0].value = data["cop_volumen"];

        this.setState({ tam_banner: data["cop_tamano_banner"] });
      });
  };

  publicidad_videos = () => {
    const apiUrl = global.config.modulos.admin_totem + "publicidad/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
          tipo: 5,
        },
      })
      .then((res) => {
        var data = res.data;
        this.setState({
          videos: data.publicidad,
        });
      });
  };

  modificar_publicidad = (idPublicidad, estado, tipo) => {
    if (estado == 1) {
      estado = 0;
    } else {
      estado = 1;
    }

    var data = { idPublicidad: idPublicidad, tipo: tipo };
    data["vsp_estado"] = estado;

    axios.patch(global.config.modulos.admin_totem + "publicidad_por_id/", data, { headers: authHeader() }).then((res) => {
      this.publicidad_videos();
    });
  };

  cargarArchivo = async (event, ubicacion) => {
    this.setState({ cargando: true });

    var totem = this.state.totem;
    const data = new FormData();
    data.append("file", event[0]);
    data.append("filename", ubicacion);

    var res = await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_totem", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    var dataRes = res.data;
    const apiUrl = global.config.modulos.admin_totem + "publicidad/";
    await axios.post(apiUrl, { totem: totem, nombre: dataRes.nombre, tipo: 4 }, { headers: authHeader() });
    confirmAlert({
      title: "Guardado con éxito",
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
    document.getElementById("archivo").value = "";
    this.publicidad_videos();

    this.setState({ cargando: false });
  };

  modificar_configuracion = (tipo) => {
    var data = { totem: this.state.totem, tipo: tipo };

    var textoBanner = document.getElementById("textoBanner").value;
    var velocidadBanner = document.getElementById("velocidadBanner").value;
    var volumenVideo = document.getElementById("volumenVideo");
    volumenVideo = volumenVideo.getElementsByTagName("input")[0].value;

    data["cop_mensaje_banner"] = textoBanner;
    data["cop_velocidad_banner"] = velocidadBanner;
    data["cop_volumen"] = volumenVideo;

    axios.patch(global.config.modulos.admin_totem + "configuraciones_por_id/", data, { headers: authHeader() }).then((res) => {
      socketTotem.emit("reiniciar_pantalla", {
        totem: 1,
      });
      this.mostrarAlerta("Guardado con éxito");
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    console.log(value);
    this.setState({ [name]: value });
  };

  render() {
    return (
      <GridContainer style={{ width: "100%", marginTop: "10px" }}>
        <GridItem xs={9} sm={9} md={9}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4} style={{ paddingLeft: "20px" }}>
                <InputLabel className={crearTotem.textosTitulos}>Volumen video</InputLabel>
                <Slider
                  id="volumenVideo"
                  min={0}
                  step={0.1}
                  max={1}
                  valueLabelDisplay="auto"
                  defaultValue={"0"}
                  style={{
                    marginBottom: "10px",
                    width: "100%",
                    color: "#eaa028",
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <InputLabel className={crearTotem.textosTitulos}>Velocidad banner</InputLabel>
                <input type="number" id="velocidadBanner" className={crearTotem.input} defaultValue={"100"} min="1" max="500" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <InputLabel className={crearTotem.textosTitulos}>Texto banner</InputLabel>
                    <textArea type="text" id="textoBanner" className={crearTotem.inputTextArea} />
                  </Grid>
                </Grid>
                <a
                  className={crearTotem.btnAgregarAcceso}
                  style={{
                    width: "6vw",
                    height: "40px",
                    marginTop: "5%",
                  }}
                  onClick={() => {
                    this.modificar_configuracion(1);
                  }}
                >
                  Guardar
                </a>
              </Grid>
            </Grid>

            <h6
              style={{
                fontSize: "1.5rem",
                fontWeight: "700",
                color: "#666666",
              }}
            >
              Videos que se visualizaran en pantalla
            </h6>
            <Grid item xs={12} sm={12}>
              <div className={crearTotem.divBordeTablaAccesos}>
                <MaterialTable
                  title=""
                  columns={[
                    {
                      title: "Nombre del archivo",
                      render: (rowData) => (
                        <React.Fragment>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <video src={rowData.vsp_ruta} style={{ display: "flex", transform: "none" }} muted controls></video>
                          </p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: "Seleccionar",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>
                            <Checkbox className={crearTotem.checkbox} checked={rowData.vsp_estado == "1"} onClick={() => this.modificar_publicidad(rowData.vsp_id, rowData.vsp_estado, 4)} />
                          </p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                  data={this.state.videos}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  localization={{
                    header: {
                      actions: "Acciones",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "filas",
                      firstTooltip: "primera",
                      previousTooltip: "anterior",
                      nextTooltip: "siguiente",
                      lastTooltip: "última",
                    },
                    toolbar: {
                      nRowsSelected: "{0} fila(s) seleccionadas",
                      searchTooltip: "Buscar",
                      searchPlaceholder: "Buscar",
                    },
                    body: {
                      emptyDataSourceMessage: "No tiene historial de atenciones",
                      filterRow: {
                        filterTooltip: "Buscar",
                      },
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </GridItem>

        {this.state.cargando ? <Cargando></Cargando> : <></>}
      </GridContainer>
    );
  }
}
