import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "../../../src/assets/css/graficosDM.css";
import ModalGraficoOferta from "../../components/Reportes/ModalGraficoOferta.js";
export default function CargaGraficoOferta(props) {
  useLayoutEffect(() => {
    const fecha = props.data[0]["Fecha"];

    const fechaDividida = fecha.split("-");
    const dd = fechaDividida[2];
    const mm = fechaDividida[1];
    const yyyy = fechaDividida[0];
    const fechaN = `${dd}-${mm}-${yyyy}`;

    console.log(fechaN);

    const dia_set = new Set();
    const diasemana = props.data[0]["dia_semana"];

    dia_set.add(props.data[0]);
    dia_set.add(props.data[1]);
    dia_set.add(props.data[2]);
    dia_set.add(props.data[3]);
    dia_set.add(props.data[4]);

    const dia = [...dia_set];

    let root = am5.Root.new("charLunes");

    var responsive = am5themes_Responsive.newEmpty(root);

    responsive.addRule({
      name: "AxisRendererY",
      relevant: am5themes_Responsive.widthXXS,

      settings: {
        inside: true,
      },
    });

    root.setThemes([am5themes_Animated.new(root), responsive]);

    root.locale = am5locales_es_ES;
    root._logo.dispose();

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        pinchZoomX: false,
      })
    );

    chart.children.unshift(
      am5.Label.new(root, {
        text: `Oferta Día ${fechaN}`,
        fontSize: 22,
        x: am5.percent(38),
        y: am5.percent(-5.5),
        paddingBottom: 0,
        fontWeight: 500,
      })
    );

    chart.get("colors").set("colors", [am5.color("#CAA2F4"), am5.color("#E8C42A"), am5.color("#71D3A4"), am5.color("#23BAE8"), am5.color("#F45175")]);

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "Value",
        sequencedInterpolation: true,
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
          autoTextColor: false,
          fill: am5.color(0xff5566),
        }),
      })
    );

    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    var data = dia;

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: dia,
      title: ``,
      pdfOptions: {
        imageFormat: "jpg",
        includeData: true,
        addColumnNames: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      xlsxOptions: {
        imageFormat: "jpg",
        includeData: true,
        addColumnNames: true,
      },
      pngOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      filePrefix: `Oferta Día ${diasemana} ${props.data[0]["Fecha"]}`,
      dataFields: {
        category: `Categoria`,
        Value: `Total`,
        Fecha: `Fecha`,
      },
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);
  return (
    <>
      <div id={"charLunes"} style={{ width: "100%", height: "90%" }}></div>
    </>
  );
}
