import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import AgregarTelefono from "../Usuarios/AgregarTelefono";
import AgregarTelefonoPercapitado from "./AgregarTelefonoPercapitado";
import "../../assets/css/usuarios.css";
import MaterialTable from "material-table";

export default class VerTelefonos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //usp_id: store.getState().usp,
      cesfamus: store.getState().cesfam,
      //herramientas: store.getState().herramientas,
      //cliente: store.getState().cliente,
      open: true,
      //canal: 5,
      //rutExtranjero: false,
      //agregarndoUsuario: false,
      //ordenarPrioridad: null,
      pagina: 0,
      filas: 10,
      total: 0,
      id_usuario: props.id_usuario,
      telefonos: [],
      fecha_inicio: null,
      tratamiento: null,
      usu_id: null,
      modalAgregarTelefono: false,
      percapitado_id: null,
      estadoHorasalud: false,
      modalAgregarTelefonoPercapitado: false,
    };
    //console.log(this.state.id_usuario);
  }
  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_telefonos/", {
        headers: authHeader(),
        params: { idusuario: this.state.id_usuario },
      })
      .then((res) => {
        if (res.data.estado) {
          if (res.data.enHoraSalud) {
            this.setState({
              telefonos: res.data.datos,
              usu_id: res.data.datos[0]["tel_usuario_id"],
              estadoHorasalud: true,
            });
          } else {
            this.setState({
              telefonos: res.data.datos,
              usu_id: res.data.datos[0]["id_percapitado"],
              estadoHorasalud: false,
            });
          }
        }
      });
  };
  componentDidMount() {
    this.obtenerTratamientos();
  }

  fecha_inicio = (e) => {
    const fecha_inicio = e.target.value;
    this.setState({ fecha_inicio: fecha_inicio });
    //console.log(this.state.fecha_inicio);
  };
  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });
    //console.log(this.state.tratamiento);
  };

  abrirModalAgregarTelefono = (usuario_id) => {
    if (this.state.estadoHorasalud) {
      this.setState({
        modalAgregarTelefono: true,
        usu_id: usuario_id,
      });
      console.log(this.state.usu_id);
    } else {
      this.setState({
        modalAgregarTelefonoPercapitado: true,
        percapitado_id: usuario_id,
      });
    }
  };
  cerrarModalAgregarTelefono = () => {
    this.setState({
      modalAgregarTelefono: false,
      modalAgregarTelefonoPercapitado: false,
    });
    this.obtenerTratamientos();
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent style={{ width: "600px" }}>
            <h2>Teléfonos</h2>
            <GridContainer style={{ width: "100%" }}>
              <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
                <MaterialTable
                  style={{ width: "100%", marginBottom: "5px", fontSize: "14px" }}
                  title=""
                  columns={[{ title: "Teléfonos", field: "loi_telefono1" }]}
                  data={this.state.telefonos}
                  actions={[
                    {
                      icon: "add",
                      tooltip: "Agregar teléfono",
                      isFreeAction: true,
                      onClick: (event, rowData) => this.abrirModalAgregarTelefono(this.state.usu_id),
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Usuario aun no tiene telefonos ingresados",
                    },
                  }}
                />
              </GridItem>
            </GridContainer>

            <div style={{ display: "flex", marginBottom: "1%", marginLeft: "90%" }}>
              <Buttons
                onClick={this.salir}
                className={"btnOpcion fondoNaranjo"}
                style={{
                  cursor: "pointer",
                  marginLeft: "2%",
                  marginTop: "2%",
                  fontSize: "16px",
                  float: "left",
                  border: "0",
                  textAlign: "center",
                  height: "40px",
                  width: "150px",
                }}
              >
                Cancelar
              </Buttons>
            </div>
          </DialogContent>
        </Dialog>
        {this.state.modalAgregarTelefono ? <AgregarTelefono usuId={this.state.usu_id} ocultar={this.cerrarModalAgregarTelefono} /> : null}
        {this.state.modalAgregarTelefonoPercapitado ? <AgregarTelefonoPercapitado percapitadoId={this.state.percapitado_id} id_listaOirs={this.state.id_usuario} ocultar={this.cerrarModalAgregarTelefono} /> : null}
      </div>
    );
  }
}
