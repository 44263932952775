import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class Historial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      historial: [],
      gestion: props.gdo_id,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.traerHistorial();
  }

  traerHistorial = () => {
    axios
      .get(global.config.modulos.gestion + "historial_gestion/", {
        headers: authHeader(),
        params: { gestion: this.state.gestion },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ historial: res.data.datos });
        } else {
          this.setState({ historial: [] });
        }
      });
  };

  render() {
    const { historial } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agregarObservacion">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12}>
              <h2>Historial</h2>
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 30 }}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                  <TableHead stickyHeader>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha
                      </TableCell>
                      <TableCell align="center">Funcionario</TableCell>
                      <TableCell align="center">Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historial &&
                      historial.map((res, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{res.gdh_fecha_registro.replace("T", " ")}</TableCell>
                          <TableCell align="center">{res.usuario_panel}</TableCell>
                          <TableCell align="center">{res.gdh_descripcion}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
