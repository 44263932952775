import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import axios from "axios";
import moment from "moment";

// core components

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import MaterialTable from "material-table";

export default class VerObservacionesLibre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cesfam: store.getState().cesfam,
      open: true,
      gdo_id: props.gdo_id,
      observaciones: [],
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.obtenerTratamientos();
  }

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.gestion + "observaciones_gestion_despacho/", {
        headers: authHeader(),
        params: { gdo_gestion: this.state.gdo_id },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            observacionesLibres: res.data.datos,
          });
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent style={{ width: "1000px", maxWidth: "100%", height: "100%", maxHeight: "100%" }}>
            <h2>Observaciónes</h2>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ marginTop: "0px" }}>
                <MaterialTable
                  style={{ width: "100%", height: "25%", marginBottom: "5px", fontSize: "14px" }}
                  title=""
                  columns={[
                    {
                      title: "Fecha ingreso observación",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>{moment(rowData["gdo_fecha_registro"]).format("DD-MM-YYYY HH:mm")} hrs</p>
                        </React.Fragment>
                      ),
                    },
                    { title: "Funcionario ", field: "usuario_panel" },
                    {
                      title: "Observación",
                      render: (rowData) => <React.Fragment>{rowData["gdo_observacion"] == 26 ? <p style={{ fontSize: "14px" }}>{rowData["gdo_observacion_detalle"]}</p> : <p style={{ fontSize: "14px" }}>{rowData["observacion"]}</p>}</React.Fragment>,
                    },
                  ]}
                  data={this.state.observacionesLibres}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Paciente aun no tiene observaciones",
                    },
                  }}
                />
              </GridItem>
            </GridContainer>

            <div style={{ display: "flex", marginBottom: "3%", marginLeft: "90%" }}>
              <Buttons onClick={this.salir} className={"btnOpcion fondoNaranjo"}>
                Cancelar
              </Buttons>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
