import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import "react-datepicker/dist/react-datepicker.css";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/gestion.css";

export default class AgendarHora extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      profesionales: [],
      horas: [],
      especialidad: "",
      profesional: "",
      hora: "",
      contacto: 1,
      telefonos: [],
      telefono: "",
      otroTelefono: "",
      usuCesfam: null,
      cesfamReserva: null,
      uspId: parseInt(store.getState().usp),
      nombre_cesfam: "",
      tipo_cesfam: "",
      open: true,
      canal: 5,
      usuId: props.usuId,
      gegId: props.gegId,
      existeProfesional: false,
      existeHorario: false,
      existeContactoTelefono: false,
      existeContactoTelefonoOtro: false,
      canalesTeleconsulta: [],
    };
  }

  componentDidMount() {
    this.traerUsuario();
    this.traerTelefonos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name == "especialidad") {
      this.traerProfesionales(value);
    }

    if (name == "profesional") {
      this.traerHorarios(value);
    }

    if (name == "hora") {
      this.guardarHora(value);
    }

    if (name == "contacto") {
      if (value == 3) {
        this.setState({ existeContactoTelefono: true });
      } else {
        this.setState({ existeContactoTelefono: false });
      }
    }

    if (name == "telefono") {
      if (value == "otro") {
        this.setState({ existeContactoTelefonoOtro: true });
      } else {
        this.setState({ existeContactoTelefonoOtro: false });
      }
    }
  };

  traerProfesionales = (especialidad) => {
    let cesfams = [this.state.usuCesfam];

    //TODO: se debe habilitar que psicologia busque profesionales en todos los cesfams de la comuna
    //if (especialidad == "P_PSI" || especialidad == "TEP") {
    //
    //}

    axios
      .get(global.config.modulos.profesionales + "profesionales_por_sigla_especialidad_con_horas/", {
        headers: authHeader(),
        params: { sigla: especialidad, cesfam: cesfams },
      })
      .then((res) => {
        this.setState({
          existeProfesional: false,
          existeHorario: false,
          existeContactoTelefono: false,
          existeContactoTelefonoOtro: false,
          profesionales: [],
          horas: [],
          profesional: "",
          hora: "",
          telefono: "",
          contacto: 1,
        });
        if (res.data.datos.length > 0) {
          this.setState({
            profesionales: res.data.datos,
            existeProfesional: true,
          });
        } else {
          NotificationManager.info("No hay profesionales con esta atención que cuenten con horarios disponibles");
        }
      });
  };

  traerHorarios = (profesional) => {
    let cesfam = null;
    let nombre_cesfam = null;
    let tipo_cesfam = null;
    this.state.profesionales.forEach((pro) => {
      if (pro.pro_id == profesional) {
        cesfam = pro.esp_cesfam;
        nombre_cesfam = pro.cesfam;
        tipo_cesfam = pro.tipo_cesfam;
        this.setState({
          cesfamReserva: cesfam,
          nombre_cesfam: nombre_cesfam,
          tipo_cesfam: tipo_cesfam,
        });
      }
    });

    let sigla = this.state.especialidad;
    let nHoras = 30;
    let canal = this.state.canal;
    let usuId = this.state.usuId;
    axios
      .get(global.config.modulos.reservas + "horariosDisponibles/", {
        headers: authHeader(),
        params: {
          sigla: sigla,
          cesfam: cesfam,
          idProfesional: profesional,
          nHoras: nHoras,
          canal: canal,
          idUsuario: usuId,
          diffHoras: 1,
          quitar_verificacion_en_gestion: true,
          deshabilitar_hora_en_gestion: true,
        },
      })
      .then((res) => {
        this.setState({
          existeHorario: false,
          existeContactoTelefono: false,
          existeContactoTelefonoOtro: false,
          horas: [],
          hora: "",
          telefono: "",
          contacto: 1,
        });
        if (res.data.estado) {
          this.setState({ horas: res.data.horas, existeHorario: true });
        } else {
          confirmAlert({
            title: "",
            message: res.data.mensaje,
            buttons: [
              {
                label: "Aceptar",
                className: "btnCancelar",
              },
            ],
          });
          NotificationManager.info(res.data.mensaje);
        }
      });
  };

  traerUsuario() {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState(
        {
          usuCesfam: res.data.datos.usu_cesfam,
        },
        () => {
          this.canalesTeleconsulta();
        }
      );
    });
  }

  guardarHora = (horario) => {
    axios
      .post(
        global.config.modulos.reservas + "guardar_hora/",
        {
          horario: horario,
          usuario: this.state.usuId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.error(res.data.mensaje);
          confirmAlert({
            title: "",
            message: res.data.mensaje,
            buttons: [
              {
                label: "Aceptar",
                className: "btnAceptar",
                onClick: () => {
                  this.traerHorarios(this.state.profesional);
                },
              },
            ],
          });
        }
      });
  };

  reservarHora = () => {
    if (!this.validaciones()) {
      return;
    }

    confirmAlert({
      title: "",
      message: "Segur@ que desea reservar esta hora en " + this.state.tipo_cesfam + " " + this.state.nombre_cesfam + "?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
          onClick: () => null,
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            let telefono = this.state.telefono;
            if (telefono == "otro") {
              this.agregarTelefono(this.state.otroTelefono, this.state.usuId, this.state.uspId);
              telefono = this.state.otroTelefono;
            }

            axios
              .post(
                global.config.modulos.agendamientos + "agendar_horario/",
                {
                  horario: this.state.hora,
                  cesfam: this.state.cesfamReserva,
                  usp_id: this.state.uspId,
                  canal: this.state.canal,
                  usu_id: this.state.usuId,
                  tipo_contacto: this.state.contacto,
                  telefono_contacto: telefono,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                axios
                  .patch(
                    global.config.modulos.gestion,
                    {
                      geg_id: this.state.gegId,
                      geg_agenda: res.data.agenda,
                      geg_especialidad: this.state.especialidad,
                      geg_tipo_contacto: this.state.contacto,
                      geg_telefono_contacto: telefono,
                    },
                    {
                      headers: authHeader(),
                    }
                  )
                  .then((res2) => {
                    if (res.data.estado) {
                      NotificationManager.success(res.data.mensaje + " en " + this.state.tipo_cesfam + " " + this.state.nombre_cesfam);
                      this.salir();
                    } else {
                      NotificationManager.error(res.data.mensaje);
                      this.salir();
                    }

                    this.generarHistorial("Se reserva hora", this.state.gegId, this.state.uspId);
                  });
              });
          },
        },
      ],
    });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  validaciones = () => {
    const { especialidad, contacto, profesional, hora, telefono, otroTelefono } = this.state;

    if (!especialidad || !profesional || !hora) {
      NotificationManager.warning("Por favor completa todos los campos");
      return false;
    } else if (contacto == 3 && !telefono) {
      NotificationManager.warning("Por favor completa todos los campos");
      return false;
    } else if (telefono == "otro" && !otroTelefono) {
      NotificationManager.warning("Por favor completa todos los campos");
      return false;
    }

    return true;
  };

  traerTelefonos = () => {
    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        telefonos: res.data.datos,
      });
    });
  };

  agregarTelefono = (telefono, usuId, uspId) => {
    axios
      .post(
        global.config.modulos.telefonos + "agregar_telefono/",
        {
          telefono: telefono,
          usu_id: usuId,
          tipo: 2,
          canal: 5,
          usp_id: uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  canalesTeleconsulta = async () => {
    axios
      .get(global.config.modulos.videollamada + "canales_teleconsulta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.usuCesfam,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        this.setState({
          canalesTeleconsulta: data,
        });
      })
      .catch((err) => {});
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Reservar hora</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <InputLabel>Especialidad</InputLabel>
                  <select className={"camposAgendaGestion"} id="especialidad" name="especialidad" value={this.state.especialidad} onChange={this.cambiarValores}>
                    <option disabled value="">
                      Seleccione
                    </option>
                    <option value="P_ASO">Asistente Social presencial</option>
                    <option value="TES">Asistente Social teleconsulta</option>
                    <option value="P_DEN">Dental presencial</option>
                    <option value="TEO">Dental teleconsulta</option>
                    <option value="P_ENF">Enfermero presencial</option>
                    <option value="TEE">Enfermero teleconsulta</option>
                    <option value="P_KIN">Kinesiólogo presencial</option>
                    <option value="TEK">Kinesiólogo teleconsulta</option>
                    <option value="P_MAT">Matrona presencial</option>
                    <option value="TMA">Matrona teleconsulta</option>
                    <option value="P_MED">Médico presencial</option>
                    <option value="TEM">Médico teleconsulta</option>
                    <option value="P_NUT">Nutrición presencial</option>
                    <option value="TEN">Nutrición teleconsulta</option>
                    <option value="P_PSI">Psicólogo presencial</option>
                    <option value="TEP">Psicólogo teleconsulta</option>
                  </select>
                </GridItem>
                {this.state.existeProfesional ? (
                  <React.Fragment>
                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <InputLabel>Profesional</InputLabel>
                      <select className={"camposAgendaGestion"} id="profesional" name="profesional" value={this.state.profesional} onChange={this.cambiarValores}>
                        <option disabled value="">
                          Seleccione
                        </option>
                        {this.state.profesionales.map((res, index) => (
                          <option key={index} value={res.pro_id}>
                            {res.pro_nombre + " " + res.pro_apellido_p}
                          </option>
                        ))}
                      </select>
                    </GridItem>
                  </React.Fragment>
                ) : null}
                {this.state.existeHorario ? (
                  <React.Fragment>
                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <InputLabel>Horarios</InputLabel>
                      <select className={"camposAgendaGestion"} id="hora" name="hora" value={this.state.hora} onChange={this.cambiarValores}>
                        <option disabled value="">
                          Seleccione
                        </option>
                        {this.state.horas.map((res, index) => (
                          <option key={index} value={res.hor_id}>
                            {res.hor_atencion.replace("T", " ")}
                          </option>
                        ))}
                      </select>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <InputLabel>Contacto</InputLabel>
                      <select id="contacto" name="contacto" value={this.state.contacto} onChange={this.cambiarValores} className={"camposAgendaGestion"}>
                        {this.state.canalesTeleconsulta.map((res, index) => {
                          return (
                            <option value={res.cta_id} key={index}>
                              {res.cta_canal}
                            </option>
                          );
                        })}
                      </select>
                    </GridItem>
                    {this.state.existeContactoTelefono ? (
                      <React.Fragment>
                        <GridItem xs={12} style={{ marginTop: 20 }}>
                          <InputLabel>Teléfono</InputLabel>
                          <select id="telefono" name="telefono" value={this.state.telefono} onChange={this.cambiarValores} className={"camposAgendaGestion"}>
                            <option selected disabled value="">
                              Seleccione
                            </option>
                            {this.state.telefonos.map((res, index) => {
                              return (
                                <option key={index} value={res.tel_telefono}>
                                  {res.tel_telefono}
                                </option>
                              );
                            })}
                            <option value="otro">Otro</option>
                          </select>
                        </GridItem>
                        {this.state.existeContactoTelefonoOtro ? (
                          <GridItem xs={12} style={{ marginTop: 20 }}>
                            <InputLabel>Otro</InputLabel>
                            <TextField
                              InputProps={{
                                startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                              }}
                              id="standard-basic"
                              label=""
                              value={this.state.otroTelefono}
                              style={{ with: 300 }}
                              type="number"
                              name="otroTelefono"
                              onChange={this.cambiarValores}
                              onInput={(e) => {
                                e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                              }}
                            />
                          </GridItem>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  {this.state.existeHorario ? (
                    <Button onClick={this.reservarHora} color="primary" className="fondoVerde">
                      Reservar
                    </Button>
                  ) : null}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
