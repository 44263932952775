import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GridItem from "../../components/grid/GridItem.js";
import { store } from "../../store/index";
import GridContainer from "../../components/grid/GridContainer.js";
import { Button, Dialog, DialogContent, GridListTile, GridList } from "@material-ui/core/";
import Titulo from "../../components/titulo/Titulo.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaterialTable from "material-table";
import reportes from "./../../assets/css/reportes.css";
import axios from "axios";
import chileanRut from "chilean-rut";

export default class ModalNSPDetalles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      cesfam: store.getState().cesfam,
      datos_detalle: [],
      especialidad_id: props.especialidad_id,
      usuario_id: props.usuario_id,
      fecha_inicio: props.fecha_inicio,
      fecha_fin: props.fecha_termino,
      listado: [],
    };
    console.log(this.state.fecha_inicio);
  }

  componentDidMount() {
    this.detallensp();
  }

  detallensp = () => {
    axios
      .get(global.config.modulos.reportes + "listado_NSP/detalleespecialidadNsp/", {
        headers: authHeader(),
        params: {
          usuario_id: this.state.usuario_id,
          especialidad_id: this.state.especialidad_id,
          startDate: this.state.fecha_inicio,
          endDate: this.state.fecha_fin,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datos_detalle: res.data.datos,
          });
        }
      });
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
          <div style={{ width: "90%", marginLeft: "5%", marginTop: "10px" }}>
            <GridContainer id="tablaBloqueos" style={{ width: "100%" }}>
              <GridItem xs={12} style={{ marginTop: "10px", marginBottom: "-20px" }}>
                <div>
                  <h2>Historial de NSP Consulta Médica</h2>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  id="tablaBloqueos"
                  style={{
                    width: "100%",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                  title=""
                  columns={[
                    {
                      title: "Rut",
                      render: (rowData) => (
                        <React.Fragment>
                          <div>{chileanRut.format(rowData.usu_rut, true)}</div>
                        </React.Fragment>
                      ),
                    },
                    { title: "Nombre", field: "nombre_usuario" },
                    { title: "Fecha agendamiento", field: "fecha_reserva" },
                    { title: "Hora perdida", field: "hora_perdida" },
                    { title: "Especialidad", field: "especialidad" },
                  ]}
                  data={this.state.datos_detalle}
                  options={{
                    sorting: true,
                    search: false,
                  }}
                  localization={{
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "filas",
                      firstTooltip: "primera",
                      previousTooltip: "anterior",
                      nextTooltip: "siguiente",
                      lastTooltip: "última",
                    },
                  }}
                ></MaterialTable>
              </GridItem>
            </GridContainer>
          </div>
          <GridItem xs={12} style={{ marginTop: 30, marginBottom: 30 }}>
            <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
              Cerrar
            </Button>
          </GridItem>
        </Dialog>
      </div>
    );
  }
}
