import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import BeneficioDentalEmbarazada from "../Beneficios/BeneficioDentalEmbarazada";
import BeneficioEmbarazada from "../Beneficios/BeneficioEmbarazada.js";
import BeneficioEmbarazadaPreferente from "../Beneficios/BeneficioEmbarazadaPreferente.js";
import BeneficioEnfermera from "../Beneficios/BeneficioEnfermera.js";
import BeneficioTratamientoDental from "../Beneficios/BeneficioTratamientoDental.js";
import BeneficioKinesiologo from "../Beneficios/BeneficioKinesiologo.js";
import BeneficioRespiratorio from "../Beneficios/BeneficioRespiratorio.js";
import BeneficioRiesgoDental from "../Beneficios/BeneficioRiesgoDental.js";
import BeneficioRespiratorioAntofagasta from "../Beneficios/BeneficioRespiratorioAntofagasta.js";
import BeneficioPsicologo from "../Beneficios/BeneficioPsicologo.js";
import BeneficioCronico from "components/Beneficios/BeneficioCronico.js";
import BeneficioPodologo from "components/Beneficios/BeneficioPodologo.js";
import BeneficioControlSano from "components/Beneficios/BeneficioControlSano.js";
import BeneficioSename from "components/Beneficios/BeneficioSename.js";
import BeneficioSalaEra from "components/Beneficios/BeneficioSalaEra.js";
import Beneficiolamados from "components/Beneficios/BeneficioLlamados.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import BeneficioIngresoCronico from "components/Beneficios/BeneficioIngresoCronico.js";
import BeneficioSesiones from "components/Beneficios/BeneficioSesiones.js";

// estilos
//import "../../assets/css/agregar.css";

export default class Beneficios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      formErrors: {},
      modalBeneficioInscribir: false,
      modalBeneficioDesinscribir: false,
      beneficio: null,
      open: true,
    };
  }

  componentDidMount() {
    this.traeBeneficios();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traeBeneficios = () => {
    this.setState({ beneficios: [] });
    axios
      .get(global.config.modulos.beneficios, {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam, usuario: this.state.usuId },
      })
      .then((res) => {
        if (res.data.estado) {
          console.log(res.data.datos);
          this.setState({ beneficios: res.data.datos });
        }
      });
  };

  render() {
    const { open, beneficios, usuId } = this.state;
    return (
      <Dialog open={open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Beneficios</h2>
                </GridItem>
                {beneficios.length > 0 ? (
                  <React.Fragment>
                    {beneficios.map((res, index) => (
                      <React.Fragment key={index}>
                        {res.ben_tipo == 1 ? <BeneficioDentalEmbarazada beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 3 ? <BeneficioPsicologo beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 4 ? <BeneficioTratamientoDental beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 5 ? <BeneficioKinesiologo beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 6 ? <BeneficioRiesgoDental beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 7 ? <BeneficioRespiratorio beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 8 ? <BeneficioIngresoCronico beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 9 ? <BeneficioCronico beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 10 ? <BeneficioPodologo beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 11 ? <BeneficioControlSano beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 12 ? <BeneficioEmbarazada beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 13 ? <BeneficioEnfermera beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 14 ? <BeneficioSename beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 15 ? <BeneficioRespiratorioAntofagasta beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} ciclo={res.ben_inscripcion_ciclo} /> : null}
                        {res.ben_tipo == 16 ? <BeneficioSalaEra beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} cesfamAsociado={res.usuario.usb_cesfam_redireccion} /> : null}
                        {res.ben_tipo == 17 ? <Beneficiolamados beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 18 ? <BeneficioSesiones beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                        {res.ben_tipo == 19 ? <BeneficioEmbarazadaPreferente beneficio={res} usuId={usuId} traeBeneficios={this.traeBeneficios} /> : null}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ) : (
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    Cesfam sin beneficios activos
                  </GridItem>
                )}

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
