import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { connect } from "react-redux";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

import axios from "axios";
import chileanRut from "chilean-rut";

import moment from "moment";
import "moment/locale/es";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import { Grid, List, ListItem } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert"; // Import
import { store } from "../../store/index";
import { socketNotificacion } from "../../service/socket";

import Tooltip from "@material-ui/core/Tooltip";

import icon_anunciar_llegada from "assets/img/imagenologia/anunciar_llegada.svg";
import icon_iniciar_examen from "assets/img/imagenologia/iniciar_examen.svg";
import icon_finalizar_examen from "assets/img/imagenologia/finalizar_examen.svg";
import icon_no_realizado from "assets/img/imagenologia/no_realizado.svg";

import icon_anunciar_llegada_l from "assets/img/imagenologia/lunes/anunciar_llegada.svg";
import icon_iniciar_examen_l from "assets/img/imagenologia/lunes/iniciar_examen.svg";
import icon_finalizar_examen_l from "assets/img/imagenologia/lunes/finalizar_examen.svg";

import icon_anunciar_llegada_m from "assets/img/imagenologia/martes/anunciar_llegada.svg";
import icon_iniciar_examen_m from "assets/img/imagenologia/martes/iniciar_examen.svg";
import icon_finalizar_examen_m from "assets/img/imagenologia/martes/finalizar_examen.svg";

import icon_anunciar_llegada_mi from "assets/img/imagenologia/miercoles/anunciar_llegada.svg";
import icon_iniciar_examen_mi from "assets/img/imagenologia/miercoles/iniciar_examen.svg";
import icon_finalizar_examen_mi from "assets/img/imagenologia/miercoles/finalizar_examen.svg";

import icon_anunciar_llegada_j from "assets/img/imagenologia/jueves/anunciar_llegada.svg";
import icon_iniciar_examen_j from "assets/img/imagenologia/jueves/iniciar_examen.svg";
import icon_finalizar_examen_j from "assets/img/imagenologia/jueves/finalizar_examen.svg";

import icon_anunciar_llegada_v from "assets/img/imagenologia/viernes/anunciar_llegada.svg";
import icon_iniciar_examen_v from "assets/img/imagenologia/viernes/iniciar_examen.svg";
import icon_finalizar_examen_v from "assets/img/imagenologia/viernes/finalizar_examen.svg";

import icon_anunciar_llegada_s from "assets/img/imagenologia/sabado/anunciar_llegada.svg";
import icon_iniciar_examen_s from "assets/img/imagenologia/sabado/iniciar_examen.svg";
import icon_finalizar_examen_s from "assets/img/imagenologia/sabado/finalizar_examen.svg";

import tipo_examenes from "assets/img/imagenologia/tipo_examenes.svg";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ExamenNoRealizado from "./ExamenNoRealizado";

import signo from "assets/img/signo.svg";

import "assets/css/videollamada.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import imagenologia from "assets/css/imagenologia.module.css";

class Pacientes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fechaPacientes: [],
      fechaReservas: new Date(),

      proId: store.getState().id_medico,
      uspId: store.getState().usp,

      fechaSeleccionada: "",
      semanaFiltro: moment().format("GGGG-[W]WW"),
      color: "#1ac6c1",
      posicion: 0,

      imaId: 0,
      pacientes: [],
    };
  }

  componentDidMount() {
    this.titulo();

    var mes = moment().format("YYYY-MM");
    this.traerFechasAgendas(mes);
    this.traerSemanas(this.state.semanaFiltro);

    socketNotificacion.on("confirmar_llegada", (data) => {
      this.traerSemanas(this.state.semanaFiltro);
    });
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Pacientes del día <span title=''><img src=" + signo + "></img></span>";
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cambiarValoresFecha = (e) => {
    this.setState({ fechaReservas: e });

    this.traerDatos(e);
  };

  traerSemanas = (sem) => {
    var semana = sem;

    if (semana == "") {
      return;
    }

    var arrSemana = sem.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];
    var inicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var colores = ["#1ac6c1", "#ea1877", "#7b37db", "#d625b4", "#1289db", "#24bde0"];

    this.traerDatos(inicioSemana, colores[0], 0);
  };

  traerDatos = async (fecha, color, posicion) => {
    this.setState({
      fechaSeleccionada: fecha,
      color: color,
      posicion: posicion,
    });
    fecha = moment(fecha).format("YYYY-MM-DD");

    var arrSemana = this.state.semanaFiltro.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];
    var inicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var botonesTop = [];

    var colores = ["#1ac6c1", "#ea1877", "#7b37db", "#d625b4", "#1289db", "#24bde0"];
    var inicioSemana = moment(inicioSemana);
    for (var i = 0; i <= 5; i++) {
      if (i == posicion) {
        botonesTop.push(
          <button id={"btn" + i} className={imagenologia.botonL} style={{ background: colores[i], width: "11vw" }} onClick={this.traerDatos.bind(this, inicioSemana, colores[i], i)}>
            {inicioSemana.format("dddd") + " " + inicioSemana.format("DD")}
          </button>
        );
      } else {
        botonesTop.push(
          <button id={"btn" + i} className={imagenologia.botonL} style={{ background: colores[i] }} onClick={this.traerDatos.bind(this, inicioSemana, colores[i], i)}>
            {inicioSemana.format("ddd") + " " + inicioSemana.format("DD")}
          </button>
        );
      }

      inicioSemana = moment(inicioSemana).add(1, "day");
    }

    this.setState({
      botonesTop: botonesTop,
    });

    var datos = {
      fecha: fecha,
      uspId: this.state.uspId,
    };

    var res = await axios.get(global.config.modulos.agendamientos + "imagenologia_por_fecha/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    var agenda = data.agenda;
    var habilitarAcciones = data.habilitarAcciones;

    var tableBody = [];
    if (agenda.length > 0) {
      for (var i = 0; i < agenda.length; i++) {
        var anunciar_llegada = icon_anunciar_llegada;
        var iniciar_examen = icon_iniciar_examen;
        var finalizar_examen = icon_finalizar_examen;

        if (agenda[i]["ima_llegada"] != null) {
          if (posicion == 0) {
            anunciar_llegada = icon_anunciar_llegada_l;
          } else if (posicion == 1) {
            anunciar_llegada = icon_anunciar_llegada_m;
          } else if (posicion == 2) {
            anunciar_llegada = icon_anunciar_llegada_mi;
          } else if (posicion == 3) {
            anunciar_llegada = icon_anunciar_llegada_j;
          } else if (posicion == 4) {
            anunciar_llegada = icon_anunciar_llegada_v;
          } else if (posicion == 5) {
            anunciar_llegada = icon_anunciar_llegada_s;
          }
        }

        if (agenda[i]["ima_estado"] != 0) {
          if (posicion == 0) {
            iniciar_examen = icon_iniciar_examen_l;
          } else if (posicion == 1) {
            iniciar_examen = icon_iniciar_examen_m;
          } else if (posicion == 2) {
            iniciar_examen = icon_iniciar_examen_mi;
          } else if (posicion == 3) {
            iniciar_examen = icon_iniciar_examen_j;
          } else if (posicion == 4) {
            iniciar_examen = icon_iniciar_examen_v;
          } else if (posicion == 5) {
            iniciar_examen = icon_iniciar_examen_s;
          }
        }

        if (agenda[i]["ima_estado"] == 3 || agenda[i]["ima_estado"] == 2) {
          if (posicion == 0) {
            finalizar_examen = icon_finalizar_examen_l;
          } else if (posicion == 1) {
            finalizar_examen = icon_finalizar_examen_m;
          } else if (posicion == 2) {
            finalizar_examen = icon_finalizar_examen_mi;
          } else if (posicion == 3) {
            finalizar_examen = icon_finalizar_examen_j;
          } else if (posicion == 4) {
            finalizar_examen = icon_finalizar_examen_v;
          } else if (posicion == 5) {
            finalizar_examen = icon_finalizar_examen_s;
          }
        }

        tableBody.push(
          <React.Fragment>
            <TableRow key={i} style={{ background: agenda[i]["estado_color"] }}>
              <TableCell align="center">{agenda[i]["horario"]}</TableCell>
              <TableCell align="center">{chileanRut.format(agenda[i]["usu_rut"], true)}</TableCell>
              <TableCell align="center">{agenda[i]["nombre_usuario"]}</TableCell>
              <TableCell align="center">
                <p>{agenda[i]["usu_nombre_social"] != null ? agenda[i]["usu_nombre_social"] : "--"}</p>
              </TableCell>
              <Tooltip title={"Edad: " + agenda[i]["edad"]} aria-label={""}>
                <TableCell align="center">{agenda[i]["usu_fecha_nacimiento"] != null ? agenda[i]["usu_fecha_nacimiento"] : "--"}</TableCell>
              </Tooltip>
              <TableCell align="center">{agenda[i]["cesfam"]}</TableCell>
              <Tooltip title={agenda[i]["no_realizado"] != "" ? "Motivo no realizado: " + agenda[i]["no_realizado"] : ""} aria-label={""}>
                <TableCell align="center">{agenda[i]["examen"]}</TableCell>
              </Tooltip>
              {habilitarAcciones && (
                <TableCell align="center">
                  <div className={imagenologia.contenedorImgImagenologia}>
                    <img title="Anuncio de llegada" className={imagenologia.botonesAcciones} src={anunciar_llegada} style={{ cursor: "default" }} />
                    <img title={agenda[i]["ima_estado"] == 0 ? "Iniciar examen" : ""} className={imagenologia.botonesAcciones} src={iniciar_examen} style={agenda[i]["ima_estado"] == 0 ? { cursor: "pointer" } : { cursor: "default" }} onClick={agenda[i]["ima_estado"] == 0 ? this.confirmarIniciar.bind(this, agenda[i]["ima_id"]) : null} />
                    <img title={agenda[i]["ima_estado"] == 1 ? "Finalizar examen" : agenda[i]["estado_agenda"]} className={imagenologia.botonesAcciones} src={finalizar_examen} style={agenda[i]["ima_estado"] == 1 ? { cursor: "pointer" } : { cursor: "default" }} onClick={agenda[i]["ima_estado"] == 1 ? this.confirmarFinalizar.bind(this, agenda[i]["ima_id"]) : null} />
                  </div>
                </TableCell>
              )}
            </TableRow>
          </React.Fragment>
        );
      }
    } else {
      tableBody.push(
        <TableRow>
          <TableCell align="center" style={{ height: 250 }} colSpan={8}>
            No tiene horas
          </TableCell>
        </TableRow>
      );
    }

    var border = "3px solid " + color;
    var tabla = [];
    tabla.push(
      <TableContainer style={{ maxHeight: "46vh", border: border, borderRadius: "15px" }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Hora
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                RUT
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Nombre
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Nombre Social
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Fecha de Nacimiento
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Cesfam
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Tipo de atención
              </TableCell>
              {habilitarAcciones && (
                <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                  Acciones
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
    );

    this.setState({
      tabla: tabla,
    });
  };

  confirmarIniciar = (imaId) => {
    confirmAlert({
      title: "¿Deseas iniciar el examen?",
      message: "",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: this.cambiarEstadoAgenda.bind(this, imaId, 1),
        },
      ],
    });
  };

  confirmarFinalizar = (imaId) => {
    confirmAlert({
      title: "¿El examen fue realizado con éxito?",
      message: "",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
          onClick: this.mostrarModalNoRealizado.bind(this, imaId),
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: this.cambiarEstadoAgenda.bind(this, imaId, 3),
        },
      ],
    });
  };

  cambiarEstadoAgenda = async (imaId, accion) => {
    let apiUrl = global.config.modulos.agendamientos + "cambio_estado_imagenologia/";
    const options = {
      method: "POST",
      data: {
        imaId: imaId,
        accion: accion,
        uspId: this.state.uspId,
      },
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.traerDatos(this.state.fechaSeleccionada, this.state.color, this.state.posicion);
    });
  };

  cerrarModalReserva = () => {
    this.setState({ modalReserva: false });
  };

  handleMonthChange = (day) => {
    var mes = moment(day).format("YYYY-MM");
    this.traerFechasAgendas(mes);
  };

  traerFechasAgendas = async (fecha) => {
    var datos = {
      fecha: fecha,
      uspId: this.state.uspId,
    };

    var res = await axios.get(global.config.modulos.agendamientos + "imagenologia_fechas_por_mes/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    this.setState({
      fechaPacientes: data.fechas,
    });
  };

  mostrarModalNoRealizado = (imaId) => {
    this.setState({
      examenNoRealizado: true,
      imaId: imaId,
    });
  };

  ocultarModalNoRealizado = () => {
    this.setState({
      examenNoRealizado: false,
    });
    this.traerDatos(this.state.fechaSeleccionada, this.state.color, this.state.posicion);
  };

  render() {
    const cambiarSemana = (event) => {
      var semanaFiltro = event.target.value;
      if (semanaFiltro != "") {
        this.setState(
          {
            semanaFiltro: semanaFiltro,
            tabla: "",
          },
          () => {
            this.traerSemanas(semanaFiltro);
          }
        );
      }
    };

    return (
      <>
        <article className="middle-area">
          <GridContainer>
            <GridItem xs={6}>
              <InputLabel>Semana del horario</InputLabel>
              <TextField id="semanaFiltro" name="semanaFiltro" type="week" value={this.state.semanaFiltro} onChange={cambiarSemana} />
            </GridItem>

            <GridItem xs={6} style={{ textAlign: "end" }}>
              <img src={tipo_examenes} style={{ width: "29vw" }} />
            </GridItem>
          </GridContainer>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              {this.state.botonesTop}
            </div>

            {this.state.tabla}
          </div>
        </article>
        {this.state.examenNoRealizado ? <ExamenNoRealizado ocultar={this.ocultarModalNoRealizado} imaId={this.state.imaId}></ExamenNoRealizado> : null}
      </>
    );
  }
}
export default connect()(Pacientes);
