import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import chileanRut from "chilean-rut";
import GridItem from "../../../components/grid/GridItem.js";
import GridContainer from "../../../components/grid/GridContainer.js";
import MaterialTable from "material-table";

import "./../../../assets/css/inicio.css";
import "./../../../assets/css/horasres.css";

export default class TablaIntentosRealizados extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  formatoRut(rut) {
    if (rut === "-") {
      return <>{rut}</>;
    } else {
      return <>{chileanRut.format(rut, true)}</>;
    }
  }

  render() {
    //console.log(this.props)
    return (
      <GridContainer>
        <GridItem xs={12} style={{ marginBottom: 50, marginLeft: "0px", overflow: "scroll" }}>
          <TableContainer className="scroll-container">
            <Table aria-label="simple table" className="bordeTabla">
              <MaterialTable
                title="Llamadas realizadas"
                columns={[
                  {
                    title: "Fecha de Atencion",
                    field: "hora_atencion",
                    render: (rowData) => <p>{rowData.vls_fecha_creacion_sala.replace("T", " ")}</p>,
                  },
                  {
                    title: "RUT paciente",
                    field: "usu_rut",
                    render: (rowData) => <p>{this.formatoRut(rowData.usu_rut)}</p>,
                  },
                  {
                    title: "Nombre",
                    field: "usu_nombre",
                    render: (rowData) => (
                      <p>
                        {rowData.usu_nombre} {rowData.usu_apellido}
                      </p>
                    ),
                  },
                  {
                    title: "Tipo de Atencion",
                    field: "especialidad",
                  },
                  {
                    title: "N° de intentos",
                    field: "intentos",
                  },
                  {
                    title: "Fecha y hora llamada exitosa",
                    field: "intento_exitoso",
                    render: (rowData) => <p>{rowData.vls_fecha_creacion_sala.replace("T", " ")}</p>,
                  },
                ]}
                data={this.props.realizadas}
                options={{
                  actionsColumnIndex: -1,
                  sorting: true,
                  search: true,
                  toolbar: true,
                  actions: false,
                  exportAllData: false,
                  exportButton: false,
                }}
                localization={{
                  header: {
                    actions: "a",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                    exportTitle: "Exportar",
                    exportAriaLabel: "Exportar",
                    exportCSVName: "Exportar como CSV",
                    exportPDFName: "Exportar como PDF",
                  },
                  body: {
                    emptyDataSourceMessage: "Este profesional no cuenta con registros para mostrar",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: "{from}-{to} de {count}",
                  },
                }}
              ></MaterialTable>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>
    );
  }
}
