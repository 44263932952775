import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import axios from "axios";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import { Input } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class EditarFechaRegistro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usu_id: props.usu_id,
      direccion: props.direccion,

      cesfam: store.getState().cesfam,
      usp_id: store.getState().usp,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {}

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  modificar = () => {
    var direccion = this.state.direccion;
    if (direccion == "") {
      NotificationManager.error("Debe ingresar la dirección", "Atención", 3000);
      return;
    }

    var data = { usu_direccion: direccion };
    axios
      .patch(global.config.modulos.usuarios + "por_id/" + this.state.usu_id, data, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Modificado con éxito ", "Atención", 3000);
          this.salir();
        } else {
          NotificationManager.error(res.data.mensaje, "Atención", 3000);
          return;
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalAgregarProfesional">
          <DialogContent
            style={{
              width: "500px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div>{<h2>Modificar dirección</h2>}</div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl style={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Dirección</InputLabel>
                  <Input type="text" name="direccion" value={this.state.direccion} onChange={this.cambiarValores} />
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: 10, backgroundColor: "#00ac77", color: "#fff" }} onClick={() => this.modificar()}>
                    Modificar
                  </Button>

                  <Button onClick={() => this.salir()} style={{ color: "#fff" }} className="fondoNaranjo">
                    Cancelar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
