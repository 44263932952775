import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default class PrincipalGraf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      withPG: props.withPG,
      heightPG: props.heightPG,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      fecha_actual: props.fecha_actual,
      hora_inicio: props.hora_inicio,
      hora_termino: props.hora_termino,
      displayE: props.displayE,
      op: props.op,
      trans: props.trans,
      root: "",
      chart: "",
      dato: "",
      display: "center",
    };
  }

  PrincipalGraf = () => {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "horas_iniciales/", {
        headers: authHeader(),
        params: {
          fecha_act: this.state.fecha_actual,
          fecha_ini: this.state.fecha_inicio,
          fecha_ter: this.state.fecha_termino,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ datos: res.data.datos }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv4");

          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              layout: root.verticalLayout,
            })
          );

          let series = chart.series.push(
            am5percent.PieSeries.new(root, {
              valueField: "value",
              categoryField: "category",
              alignLabels: false,
            })
          );
          series.labels.template.setAll({
            textType: "circular",
            radius: 4,
          });

          let thise = this;
          let props = this.props;

          series.slices.template.events.on("click", function (ev) {
            if (ev.target._dataItem.dataContext.category === "horas_iniciales") {
              thise.setState({ displayE: "initial" }, () => {
                props.mostrarTablaBloqueadas();
              });
            }
            if (ev.target._dataItem.dataContext.category === "Demanda") {
              thise.setState({ displayE: "initial" }, () => {
                props.mostrarTablaDemanda();
              });
            }
          });

          series.labels.template.set("text", "{category}: [bold]{value} Horas");

          series.data.setAll(this.state.datos[0]);

          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.percent(50),
              x: am5.percent(50),
              marginTop: 15,
              marginBottom: 15,
            })
          );

          legend.data.setAll(series.dataItems);

          series.appear(1000, 100);
        });
      });
  };

  componentDidMount() {
    this.PrincipalGraf();
  }

  render() {
    return (
      <div
        id="chartdiv4"
        style={{
          width: this.state.withPG,
          height: this.state.heightPG,
          zIndex: 1,
          position: "fixed",
          display: this.state.display,
          marginTop: "20px",
          textAlign: "center",
          opacity: this.state.op,
          transition: this.state.trans,
        }}
      >
        <h1 style={{ textAlign: "center" }}>Demanda Nivel Pais</h1>
      </div>
    );
  }
}
