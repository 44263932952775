import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import moment from "moment";
import "moment/locale/es";

import { store } from "./../../../../store/index";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Grid, List, ListItem } from "@material-ui/core";

// core components
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialTable from "material-table";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import chileanRut from "chilean-rut";

class DatosTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      open: true,
    };

    this.salir = () => {
      this.setState({
        open: false,
      });
      props.ocultar();
    };
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  componentDidMount() {}

  validaciones() {
    var rut = document.getElementById("rut").value;

    if (!rut) {
      this.mostrarAlerta("Escribe el rut.");
      return false;
    } else if (!chileanRut.validate(rut)) {
      this.mostrarAlerta("Escribe el rut válido.");
      return false;
    }

    return true;
  }

  buscar_usuario = () => {
    this.setState({ datos: false, usu_id: 0 });

    if (this.validaciones()) {
      var rut = document.getElementById("rut").value;

      var data = {
        usu_rut: rut,
      };

      axios.post(global.config.modulos.totem + "tickets_por_rut/", data, { headers: authHeader() }).then((res) => {
        var data = res.data;
        var estado = data.estado;
        if (estado) {
          this.setState({ tickets: data.tickets });
        } else {
          this.mostrarAlerta("Paciente no tiene registros de tickets.");
        }

        this.setState({ datos: true });
      });
    }
  };

  tickets_por_usuario = (usu_id) => {
    axios.get(global.config.modulos.totem + "tickets_por_usuario/?usuario=" + usu_id, { headers: authHeader() }).then((res) => {
      var data = res.data;
      var estado = data.estado;
      if (estado) {
        this.setState({ tickets: data.tickets });
      } else {
        this.mostrarAlerta("Paciente no tiene registros de tickets.");
      }
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
        <DialogContent>
          <h1>Tickets por paciente</h1>
          <br></br>
          <br></br>

          <Grid item xs={12} sm={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={4}>
                  <Grid item xs={4} sm={6}>
                    <InputLabel className={"textosTitulos"}>RUT</InputLabel>
                    <input type="text" id={"rut"} className={"inputText"} style={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={4} sm={1}>
                    <button
                      id={"btnBuscar"}
                      className={"btnAcciones"}
                      onClick={() => {
                        this.buscar_usuario();
                      }}
                    >
                      {"Buscar"}
                    </button>
                  </Grid>
                </Grid>
              </Grid>

              {this.state.datos ? (
                <>
                  {/*  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={"textosTitulos"}>Nombre</InputLabel>
                        <input type="text" id={"nombre"} className={"inputText"} style={{ width: "100%" }} disabled />
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={"textosTitulos"}>Apellido</InputLabel>
                        <input type="text" id={"apellido"} className={"inputText"} style={{ width: "100%" }} disabled />
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={"textosTitulos"}>Sexo</InputLabel>
                        <NativeSelect className={"selectT"} id={"selectSexo"} style={{ width: "100%" }} disabled>
                          <option value="F">F</option>
                          <option value="M">M</option>
                          <option value="I">I</option>
                        </NativeSelect>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={"textosTitulos"}>Fecha de Nacimiento</InputLabel>
                        <input type="date" id={"fechaNacimiento"} className={"inputText"} style={{ width: "100%" }} disabled />
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <InputLabel className={"textosTitulos"}>Discapacidad</InputLabel>
                        <NativeSelect className={"selectT"} id={"selectDisc"} style={{ width: "100%" }} disabled>
                          <option value="0">No</option>
                          <option value="1">Sí</option>
                        </NativeSelect>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  <Grid item xs={12} sm={12}>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: "Día de obtención del ticket",
                          field: "dia_tick",
                        },
                        {
                          title: "Hora de obtención del ticket",
                          field: "hora_tick",
                        },
                        { title: "Módulo", field: "tit_nombre_opcion" },
                        { title: "Número de atención", field: "tit_codigo" },
                        {
                          title: "Atendido",
                          field: "tit_atendido",
                          render: (rowData) => <React.Fragment>{rowData.tit_atendido == 1 ? "SÍ" : "NO"}</React.Fragment>,
                        },
                        {
                          title: "Hora atendido",
                          field: "hora_tick_ate",
                          render: (rowData) => <React.Fragment>{rowData.hora_tick_ate ? rowData.hora_tick_ate + " hrs." : "--"}</React.Fragment>,
                        },
                        {
                          title: "Tiempo de espera",
                          field: "espera_ti",
                          render: (rowData) => <React.Fragment>{rowData.espera_ti ? rowData.espera_ti + " hrs." : "--"}</React.Fragment>,
                        },
                        {
                          title: "Duración de atención",
                          field: "tie_atencion",
                        },
                      ]}
                      data={this.state.tickets}
                      options={{
                        actionsColumnIndex: -1,
                        search: true,
                      }}
                      localization={{
                        header: {
                          actions: "Acciones",
                        },
                        pagination: {
                          labelDisplayedRows: "{from}-{to} de {count}",
                          labelRowsSelect: "filas",
                          firstTooltip: "primera",
                          previousTooltip: "anterior",
                          nextTooltip: "siguiente",
                          lastTooltip: "última",
                        },
                        toolbar: {
                          nRowsSelected: "{0} fila(s) seleccionadas",
                          searchTooltip: "Buscar",
                          searchPlaceholder: "Buscar",
                        },
                        body: {
                          emptyDataSourceMessage: "No tiene historial de atenciones",
                          filterRow: {
                            filterTooltip: "Buscar",
                          },
                        },
                      }}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(DatosTicket);
