import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import { store } from "store/index";

import chileanRut from "chilean-rut";
import axios from "axios";

import { Grid, List, ListItem } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";
import MaterialTable from "material-table";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import modificar from "assets/img/admin/modificar.svg";
import ver_mas from "assets/img/admin/ver_mas.svg";

import { confirmAlert } from "react-confirm-alert";

// CSS
import crearTotem from "assets/css/crearTotem.module.css";
import FuncionarioCSS from "assets/css/funcionario.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Funcionarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcionesCreadas: [],
      nombreFuncionarioOpc: "",
      ingresarRut: false,

      funcionarioOpc: [{ funcionario: 1 }],

      cesfam: store.getState().cesfam,
      totem: store.getState().totem,
    };
  }

  componentDidMount() {
    this.funcionarios();
    this.opciones_creadas();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  funcionarios = () => {
    const apiUrl = global.config.modulos.admin_totem + "funcionarios/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;
      this.setState({
        funcionarios: data.funcionarios,
      });
    });
  };

  opciones_habilitadas = (opciones, nombre_funcionario) => {
    if (opciones != null) {
      axios
        .post(
          global.config.modulos.admin_totem + "opciones_funcionario/",
          {
            opciones: opciones,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          var data = res.data;
          this.setState({
            abrirOpcionesHab: true,
            opcionesFuncionario: data.opciones,
            nombreFuncionarioOpc: nombre_funcionario,
          });
        });
    } else {
      this.setState({
        abrirOpcionesHab: true,
        opcionesFuncionario: [{ opc_nombre: "Sin opciones habilitadas" }],
        nombreFuncionarioOpc: nombre_funcionario,
      });
    }
  };

  abrirCrearAcceso = () => {
    this.setState({
      abrirCrearAcceso: true,
      opcionesActivasFuncionario: [],
    });
  };

  modificar_acceso = (funcionario) => {
    this.setState({
      funcionarioActivo: funcionario,
      abrirModificarAcceso: true,
      opcionesActivasFuncionario: [],
    });
    this.datosFuncionario(funcionario);
  };

  datosFuncionario = (funcionario) => {
    const apiUrl = global.config.modulos.admin_totem + "funcionario_por_id/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          funcionario: funcionario,
        },
      })
      .then((res) => {
        var data = res.data;
        document.getElementById("nombreMod").value = data["usp_nombre"];
        document.getElementById("apellidoMod").value = data["usp_apellido_p"];
        document.getElementById("rutMod").value = data["usp_rut"];
        document.getElementById("moduMod").value = data["usp_modulo"];

        var opciones = data["usp_opciones"].slice(0, -1);
        var arrOpciones = opciones.split(";");
        if (data["usp_opciones"] == "") {
          arrOpciones = [];
        }

        this.setState({ opcionesActivasFuncionario: arrOpciones });
      });
  };

  validacionFuncionarios = (tipo) => {
    if (tipo == 1) {
      var nombre = document.getElementById("nombreAgr").value;
      var apellido = document.getElementById("apellidoAgr").value;
      var rut = document.getElementById("rutAgr").value;
      var pass = document.getElementById("passAgr").value;
    } else {
      var nombre = document.getElementById("nombreMod").value;
      var apellido = document.getElementById("apellidoMod").value;
      var rut = document.getElementById("rutMod").value;
      var pass = document.getElementById("passMod").value;
    }

    var opciones = this.state.opcionesActivasFuncionario;

    if (!nombre) {
      this.mostrarAlerta("Ingresar el nombre.");
      return false;
    } else if (!apellido) {
      this.mostrarAlerta("Ingresar el apellido.");
      return false;
    } else if (!rut) {
      this.mostrarAlerta("Ingresar el rut.");
      return false;
    } else if (!chileanRut.validate(rut)) {
      this.mostrarAlerta("Ingresar el rut valido.");
      return false;
    } else if (!pass && tipo == 1) {
      this.mostrarAlerta("Ingresar la contraseña.");
      return false;
    } else if (opciones.length == 0) {
      this.mostrarAlerta("Ingresa una o más opciones.");
      return false;
    }

    return true;
  };

  agregarFuncionario = () => {
    if (this.validacionFuncionarios(1)) {
      var nombre = document.getElementById("nombreAgr").value;
      var apellido = document.getElementById("apellidoAgr").value;
      var rut = document.getElementById("rutAgr").value;
      var pass = document.getElementById("passAgr").value;
      var mod = document.getElementById("modAgr").value;

      var opcActivas = "";
      var opciones = this.state.opcionesActivasFuncionario;

      for (var i = 0; i < opciones.length; i++) {
        opcActivas += opciones[i] + ";";
      }

      var data = {
        usp_rut: rut,
        usp_nombre: nombre,
        usp_apellido_p: apellido,
        pass: pass,
        usp_opciones: opcActivas,
        usp_estado: 1,
        usp_cesfam: this.state.cesfam,
        usp_modulo: mod,
      };

      axios.post(global.config.modulos.admin_totem + "crear_funcionario/", data, { headers: authHeader() }).then((res) => {
        var data = res.data;
        var estado = data.estado;
        if (estado) {
          this.salir();
          this.funcionarios();
        }
        this.mostrarAlerta(data.mensaje);
      });
    }
  };

  modificarFuncionario = () => {
    if (this.validacionFuncionarios(2)) {
      var nombre = document.getElementById("nombreMod").value;
      var apellido = document.getElementById("apellidoMod").value;
      var rut = document.getElementById("rutMod").value;
      var pass = document.getElementById("passMod").value;
      var mod = document.getElementById("moduMod").value;

      var opcActivas = "";
      var opciones = this.state.opcionesActivasFuncionario;

      for (var i = 0; i < opciones.length; i++) {
        opcActivas += opciones[i] + ";";
      }

      var data = {
        funcionario: this.state.funcionarioActivo,
        usp_rut: rut,
        usp_nombre: nombre,
        usp_apellido_p: apellido,
        pass: pass,
        usp_opciones: opcActivas,
        usp_modulo: mod,
      };

      axios.patch(global.config.modulos.admin_totem + "funcionario_por_id/", data, { headers: authHeader() }).then((res) => {
        this.salirModificarAcceso();
        this.funcionarios();
        this.mostrarAlerta("Modificado con éxito");
      });
    }
  };

  opciones_creadas = () => {
    const apiUrl = global.config.modulos.admin_totem + "opciones_creadas/?totem=" + this.state.totem;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;
      this.setState({
        opcionesCreadas: data.opciones,
      });
    });
  };

  salir = () => {
    this.setState({
      abrirCrearAcceso: false,
      ingresarRut: false,
    });
  };

  salirModificarAcceso = () => {
    this.setState({
      abrirModificarAcceso: false,
    });
  };

  salirOpcionesHabilitadas = () => {
    this.setState({
      abrirOpcionesHab: false,
    });
  };

  configurarOpcionesPanel = () => {
    this.setState({
      confTotem: false,
      confTotemApa: false,
      confTotemOpc: false,
      confTotemHor: false,
      confTicket: false,
      confTv: false,
      confPeri: false,
      confOpcionesPanel: true,
    });
  };

  render() {
    const elegirOpc = (event) => {
      const { name, value } = event.target;

      var opciones = this.state.opcionesActivasFuncionario;

      var check = event.target.checked;
      if (check == true) {
        opciones.push(value);
      } else {
        const index = opciones.indexOf(value);
        if (index > -1) {
          opciones.splice(index, 1);
        }
      }

      this.setState({
        opcionesActivasFuncionario: opciones,
        [name]: check,
      });
    };

    return (
      <GridContainer
        style={{
          width: "100%",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ color: "#666666", marginLeft: "1%", fontSize: "1.8rem" }}>Funcionarios con acceso tótem </h2>
        <a
          className={crearTotem.btnAgregarAcceso}
          style={{ width: "12%" }}
          onClick={() => {
            this.abrirCrearAcceso();
          }}
        >
          Agregar acceso
        </a>
        <GridItem xs={12} sm={12} md={12}>
          <div className={crearTotem.divBordeTablaAccesos}>
            <MaterialTable
              title=""
              columns={[
                { title: "RUT", field: "usp_rut" },
                { title: "Nombre", field: "nombre_funcionario" },
                {
                  title: "Opciones habilitadas",
                  render: (rowData) =>
                    rowData.usp_opciones == null ? (
                      <p>No</p>
                    ) : (
                      <React.Fragment>
                        <img className="botonesTablas" src={ver_mas} style={{ width: "40px" }} onClick={() => this.opciones_habilitadas(rowData.usp_opciones, rowData.nombre_funcionario)} />
                      </React.Fragment>
                    ),
                },
                {
                  title: "Acciones",
                  render: (rowData) => (
                    <React.Fragment>
                      <img style={{ width: "40px" }} className="botonesTablas" src={modificar} onClick={() => this.modificar_acceso(rowData.usp_id)} />
                    </React.Fragment>
                  ),
                },
              ]}
              data={this.state.funcionarios}
              options={{
                actionsColumnIndex: -1,
                search: true,
              }}
              localization={{
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "filas",
                  firstTooltip: "primera",
                  previousTooltip: "anterior",
                  nextTooltip: "siguiente",
                  lastTooltip: "última",
                },
                toolbar: {
                  nRowsSelected: "{0} fila(s) seleccionadas",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No tiene funcionarios creados",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </div>
        </GridItem>

        {this.state.ingresarRut && (
          <>
            <Dialog open={this.state.ingresarRut} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogContent>
                <GridItem xs={12} sm={12} md={12}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      padding: "0px",
                      marginBottom: "40px",
                    }}
                  >
                    <h1 style={{ color: "#818181" }}>Ingresar Rut</h1>
                    <br />
                    <br />
                    <input type="text" name="rut" style={{ textAlign: "center" }} maxLength={12} className={FuncionarioCSS.inputText} value={this.state.rut} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" />
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button onClick={this.buscarRut} style={{ width: "150px", margin: "0" }} className={FuncionarioCSS.boton}>
                      Buscar
                    </button>
                  </div>
                </GridItem>
              </DialogContent>
            </Dialog>
          </>
        )}

        {this.state.abrirCrearAcceso ? (
          <div>
            <Dialog open={this.state.abrirCrearAcceso} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent>
                <h1>Agregar acceso</h1>
                <br></br>
                <br></br>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Nombre</InputLabel>
                      <input type="text" id={"nombreAgr"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Apellido</InputLabel>
                      <input type="text" id={"apellidoAgr"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>RUT</InputLabel>
                      <input type="text" id={"rutAgr"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Contraseña</InputLabel>
                      <input type="text" id={"passAgr"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Módulo</InputLabel>
                      <input type="text" id={"modAgr"} className={crearTotem.input} />
                    </Grid>
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <Grid item xs={12} sm={12}>
                  <InputLabel className={crearTotem.textosTitulos}>Opciones habilitadas</InputLabel>

                  <Grid container spacing={3}>
                    {this.state.opcionesCreadas.map((opc, index) => (
                      <Grid item xs={4} sm={3}>
                        <FormControlLabel name={opc.opc_nombre} label={opc.opc_nombre} value={opc.opc_id} control={<Checkbox className={crearTotem.checkbox} />} onChange={elegirOpc} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                <a
                  className={"btnAcciones btnAgregarAcceso"}
                  onClick={() => {
                    this.agregarFuncionario();
                  }}
                >
                  Agregar
                </a>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}

        {this.state.abrirModificarAcceso ? (
          <div>
            <Dialog open={this.state.abrirModificarAcceso} onClose={this.salirModificarAcceso} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent>
                <h1>Modificar acceso</h1>
                <br></br>
                <br></br>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={3}>
                      <InputLabel className={crearTotem.textosTitulos}>Nombre</InputLabel>
                      <input type="text" id={"nombreMod"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <InputLabel className={crearTotem.textosTitulos}>Apellido</InputLabel>
                      <input type="text" id={"apellidoMod"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <InputLabel className={crearTotem.textosTitulos}>RUT</InputLabel>
                      <input type="text" id={"rutMod"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <InputLabel className={crearTotem.textosTitulos}>Contraseña</InputLabel>
                      <input type="text" id={"passMod"} className={crearTotem.input} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <InputLabel className={crearTotem.textosTitulos}>Módulo</InputLabel>
                      <input type="text" id={"moduMod"} className={crearTotem.input} />
                    </Grid>
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <Grid item xs={12} sm={12}>
                  <InputLabel className={crearTotem.textosTitulos}>Opciones habilitadas</InputLabel>

                  <Grid container spacing={3}>
                    {
                      (console.log(this.state.opcionesActivasFuncionario),
                      this.state.opcionesCreadas.map((opc, index) => (
                        <Grid item xs={4} sm={3}>
                          <FormControlLabel name={opc.opc_nombre} label={opc.opc_nombre} value={opc.opc_id} control={<Checkbox className={crearTotem.checkbox} />} checked={this.state.opcionesActivasFuncionario.includes(opc.opc_id.toString())} onChange={elegirOpc} />
                        </Grid>
                      )))
                    }
                  </Grid>
                </Grid>

                <a
                  className={"btnAcciones btnAgregarAcceso"}
                  onClick={() => {
                    this.modificarFuncionario();
                  }}
                >
                  {"Modificar"}
                </a>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}

        {this.state.abrirOpcionesHab ? (
          <div>
            <Dialog open={this.state.abrirOpcionesHab} onClose={this.salirOpcionesHabilitadas} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="opcionesHabilitadas">
              <DialogContent>
                <h1>Opciones habilitadas</h1>
                <div className="divBordeTablaAccesos" style={{ width: "100%" }}>
                  <MaterialTable
                    title=""
                    columns={[
                      {
                        title: "Nombre funcionario",
                        render: (rowData) => (
                          <React.Fragment>
                            <p>{this.state.nombreFuncionarioOpc}</p>
                          </React.Fragment>
                        ),
                      },
                      {
                        title: "Opciones habilitadas",
                        render: (rowData) => (
                          <React.Fragment>
                            {this.state.opcionesFuncionario.map((opc, index) => (
                              <p>{opc.opc_nombre}</p>
                            ))}
                          </React.Fragment>
                        ),
                      },
                    ]}
                    data={this.state.funcionarioOpc}
                    options={{
                      actionsColumnIndex: -1,
                      search: false,
                      paging: false,
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No tiene opciones habilitadas",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                </div>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}
      </GridContainer>
    );
  }
}
