import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "./../../components/grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import icono_ver_mas from "../../assets/img/usuarios/icono_ver_mas.svg";

import Tooltip from "@material-ui/core/Tooltip";

import { store } from "./../../store/index";

import MaterialTable from "material-table";

import DetalleSolicitudHoras from "./DetalleSolicitudHoras";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class SolicitudHoras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cesfam: store.getState().cesfam,
      uspid: store.getState().usp,
      usuario: props.usuario,

      especialidad: 0,
      esp_nombre: "",
      solicitudes: [],
      open: true,
    };
  }

  componentDidMount() {
    this.traerHoras();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerHoras = () => {
    axios
      .get(global.config.modulos.agendamientos + "solicitudes/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuario,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ solicitudes: res.data.datos });
        }
      });
  };

  mostrarModalDetalleSolicitud = (especialidad, esp_nombre) => {
    this.setState({
      modalDetalleSolicitud: true,
      especialidad: especialidad,
      esp_nombre: esp_nombre,
    });
  };

  ocultarModalDetalleSolicitud = () => {
    this.setState({
      modalDetalleSolicitud: false,
    });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="ultimaSolicitud">
          <DialogContent>
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <h2 style={{ fontSize: "2.5vw", color: "#313030", position: "absolute" }}>Solicitud de hora</h2>

              <MaterialTable
                title=""
                columns={[
                  { title: "Centro de Salud", field: "cesfam" },
                  { title: "Tipo de atención", field: "esp_nombre" },
                  { title: "Número de intentos", field: "total_intentos" },
                  { title: "Número de reservas", field: "total_agendas" },
                  {
                    title: "Fecha de última reserva",
                    field: "fecha_ultima_reserva",
                    render: (rowData) => (
                      <React.Fragment>
                        {rowData.fecha_ultima_reserva == null ? (
                          <p>No hay registro</p>
                        ) : (
                          <Tooltip title={rowData.estado_ultima_reserva} aria-label={rowData.estado_ultima_reserva}>
                            <p style={{ backgroundColor: rowData.color_estado, color: "#fff", borderRadius: "5px" }}>{rowData.fecha_ultima_reserva}</p>
                          </Tooltip>
                        )}
                      </React.Fragment>
                    ),
                  },
                  {
                    title: "Detalle",
                    field: "total_agendas",
                    render: (rowData) => (
                      <Tooltip title="Ver Detalle" aria-label="Ver Detalle">
                        <img
                          style={{
                            width: 30,
                            float: "inherit",
                          }}
                          className="imagenBotonAccion "
                          src={icono_ver_mas}
                          onClick={this.mostrarModalDetalleSolicitud.bind(this, rowData["esp_id"], rowData["esp_nombre"])}
                        />
                      </Tooltip>
                    ),
                  },
                ]}
                actions={[]}
                data={this.state.solicitudes}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  sorting: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "Sin solicitudes en los últimos 30 días",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </GridItem>
          </DialogContent>
          <DialogActions>
            <Button className="botonDialog fondoRojo" onClick={this.salir}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.modalDetalleSolicitud ? <DetalleSolicitudHoras ocultar={this.ocultarModalDetalleSolicitud} esp_nombre={this.state.esp_nombre} especialidad={this.state.especialidad} usuario={this.state.usuario}></DetalleSolicitudHoras> : null}
      </div>
    );
  }
}
