import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import { store } from "../../store/index";
import { connect } from "react-redux";

import { withStyles, makeStyles } from "@material-ui/core/styles";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import MaterialTable from "material-table";

import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

import { FaTrash } from "react-icons/fa";
import { showLoader, hideLoader } from "./../../store/actions/actions";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";

// estilos

class DuplicarHorario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      horId: props.horario,
      horariosDuplicados: [],

      cantidad: 0,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirActualizado = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarActualizado();
  };

  componentDidMount() {
    this.horasDuplicadas();
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  horariosParaCargar = () => {
    this.enviarFormulario();
  };

  horasDuplicadas = () => {
    var apiUrl = global.config.modulos.agendamientos + "horarios_duplicados/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          horId: this.state.horId,
        },
      })
      .then((res) => {
        var data = res.data;

        this.setState({ horariosDuplicados: data.horarios });
      });
  };

  enviarFormulario = (e) => {
    if (this.state.cantidad <= 0) {
      NotificationManager.warning("La cantidad no puede ser 0 o menor que 0");
    } else {
      this.props.dispatch(showLoader());

      var data = {
        uspId: this.state.uspId,
        horId: this.state.horId,
        nDuplicar: this.state.cantidad,
      };

      let apiUrl = global.config.modulos.agendamientos + "duplicar_horario/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options)
        .then((res) => {
          this.props.dispatch(hideLoader());
          setTimeout(() => {
            if (res.data.estado) {
              this.setState({ cantidad: 0 });

              this.horasDuplicadas();
              this.mostrarAlerta(res.data.mensaje);
            } else {
              this.mostrarAlerta(res.data.mensaje);
            }
          }, 500);
        })
        .catch((err) => {
          this.props.dispatch(hideLoader());
        });
    }
  };

  eliminarHorarioConfirmar(horId) {
    confirmAlert({
      title: "",
      message: "¿Seguro(a) que deseas eliminar el horario?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminarHorario(horId),
          className: "btnAceptar",
        },
      ],
    });
  }

  eliminarHorario(horId) {
    var arregloHorEl = [];
    arregloHorEl.push(horId);

    var data = {
      horarios: arregloHorEl,
      usp_id: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "eliminar_horarios/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (res.data.mensaje == "eliminadoExito") {
        var apiUrl = global.config.modulos.agendamientos + "eliminar_duplicado/?horId=" + horId;
        axios
          .get(apiUrl, {
            headers: authHeader(),
          })
          .then((res) => {
            this.salirActualizado();
            NotificationManager.success("Se han eliminado existosamente la horas");
          });
      } else if (res.data.mensaje == "horarioAgendado") {
        NotificationManager.warning("No se pueden eliminar horas reservadas");
      }
    });
  }

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <div className="cargarHorario">
            <GridContainer>
              <GridItem xs={12}>
                <h2>Duplicar horario</h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel>Veces a duplicar</InputLabel>
                    <input type="number" name="cantidad" value={this.state.cantidad} onChange={this.cambiarValores} placeholder="" className={"inputTextAgendar"} min="0" />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12} style={{ float: "right" }}>
                      <Grid item xs={12} sm={8} md={8} style={{ float: "left", marginTop: 20 }} className="modalHorarioCopiar">
                        <Button onClick={this.enviarFormulario} className="fondoVerde">
                          Duplicar
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{
                          float: "left",
                          marginTop: 20,
                          marginLeft: 20,
                        }}
                        className="modalHorarioCopiar"
                      >
                        <Button onClick={this.salir} className="fondoRojo">
                          Cancelar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                {this.state.horariosDuplicados && (
                  <MaterialTable
                    title=""
                    columns={[
                      { title: "Rut", field: "usu_rut" },
                      { title: "Nombre", field: "usu_nombre" },
                      { title: "Tipo de atención", field: "especialidad" },
                      { title: "Horario", field: "hora" },
                      { title: "Estado", field: "estado_hora" },
                      {
                        title: "Acción",
                        render: (rowData) => (
                          <React.Fragment>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <img class="imagenBotonReservarHorario" src={icono_eliminar} aria-label="Eliminar" title="Eliminar" onClick={this.eliminarHorarioConfirmar.bind(this, rowData.hor_id)}></img>
                            </div>
                          </React.Fragment>
                        ),
                      },
                    ]}
                    data={this.state.horariosDuplicados}
                    options={{
                      actionsColumnIndex: -1,
                      search: true,
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No tiene historial de atenciones",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                )}
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(DuplicarHorario);
