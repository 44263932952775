import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import stilos from "../../../assets/css/graficosDM.module.css";

export default class GrafResEsp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayInicial: props.displayInicial,
      displayBloqueadas: props.displayBloqueadas,
      displayAnuladas: props.displayAnuladas,
      displayFinal: props.displayFinal,
      color: props.colores,
      colores: "",
    };
  }

  cambioColor() {
    if (this.state.color === "#FF9327") {
      this.setState({
        colores: stilos.color1,
      });
    } else if (this.state.color === "#EF4597") {
      this.setState({
        colores: stilos.color2,
      });
    } else if (this.state.color === "#30b59b") {
      this.setState({
        colores: stilos.color3,
      });
    } else if (this.state.color === "#225093") {
      this.setState({
        colores: stilos.color4,
      });
    } else if (this.state.color === "#23BAE8") {
      this.setState({
        colores: stilos.color5,
      });
    } else if (this.state.color === "#8156C1") {
      this.setState({
        colores: stilos.color6,
      });
    } else if (this.state.color === "#63C189") {
      this.setState({
        colores: stilos.color7,
      });
    } else if (this.state.color === "#F45175") {
      this.setState({
        colores: stilos.color8,
      });
    } else if (this.state.color === "#E8C42A") {
      this.setState({
        colores: stilos.color9,
      });
    }
  }

  componentDidMount() {
    this.cambioColor();
  }

  render() {
    return (
      <div style={{ marginLeft: "24%" }}>
        <button className={this.state.colores} onClick={() => this.props.clickButton(1)}>
          Reservas Iniciales
        </button>
        <button className={this.state.colores} onClick={() => this.props.clickButton(2)}>
          Reservas Bloqueadas
        </button>
        <button className={this.state.colores} onClick={() => this.props.clickButton(3)}>
          Reservas Anuladas
        </button>
        <button className={this.state.colores} onClick={() => this.props.clickButton(4)}>
          Reservas Finales
        </button>
      </div>
    );
  }
}
