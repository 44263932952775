import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import { store } from "../../store/index";
import { connect } from "react-redux";

import chileanRut from "chilean-rut";

import moment from "moment";
import "moment/locale/es";

// core components
import cargando from "assets/img/cargando_salusin.gif";
import descargar from "assets/img/lista_espera/descargar.svg";
import inscribir from "assets/img/iconos correccion/inscribir.svg";
import ver_mas from "assets/img/iconos correccion/ver mas.svg";
import modi_icon from "assets/img/usuarios/icono_modificar.svg";

import Paginacion from "components/Table/Pagination.js";
import TableHeader from "components/Table/TableHeader";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Buttons from "react-multi-date-picker/components/button";

import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { Input } from "@material-ui/core";

import ConfirmarUsuarioTratamiento from "components/CamaraHiperbarica/ConfirmarUsuarioTratamiento";

import UsuarioCrearObservacionLibre from "components/CamaraHiperbarica/UsuarioCrearObservacionLibre";
import VerObservacionesLibre from "components/CamaraHiperbarica/VerObservacionesLibres";
import UsuarioObservacionFija from "components/CamaraHiperbarica/UsuarioObservacionFija";

import UsuarioPrioridad from "components/CamaraHiperbarica/UsuarioPrioridad";
import ConfirmarEstadoEvaluacion from "components/CamaraHiperbarica/ConfirmarEstadoEvaluacion";

import UsuarioEnAlta from "components/CamaraHiperbarica/UsuarioEnAlta";

import EditarDatos from "components/CamaraHiperbarica/EditarDatos";

import VistaUsuarios from "components/GestionPrioritarios/VistaUsuarios";

import NotificationManager from "react-notifications/lib/NotificationManager";

class ListaCitadosTratamiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,

      datos: [],
      loading: false,

      pagina: 0,
      total: 0,
      filas: 10,
      nombreTitulo: [
        { nombre: "Fecha de derivación", filtro: "loi_fecharegistro" },
        { nombre: "Rut", filtro: "loi_rut" },
        { nombre: "Nombres", filtro: "loi_nombre" },
        { nombre: "Fecha de diagnóstico", filtro: "nulo" },
        { nombre: "Diagnóstico", filtro: "loi_tratamiento" },
        {
          nombre: "prioridad",
          filtro: "loi_prioridad",
        },
        {
          nombre: "Fecha de evaluación",
          filtro: "loi_fecha_tratamiento",
        },
        {
          nombre: "Resultado exploración de oídos",
          filtro: "loi_tratamiento_secundario",
        },
        {
          nombre: "Citación a tratamiento",
          filtro: "nulo",
        },
        { nombre: "Cierre de caso", filtro: "nulo" },
        { nombre: "Observación libre", filtro: "loi_observacion" },
      ],
      ordenarPrioridad: null,
      listadoOirs: [],
      texto_filtro: props.busqueda,

      cesfams: [],
      genero: null,
      cesfam_b: null,
      edad: null,
      edad2: null,

      fecha2: null,
      fecha1: null,

      tratamientos: [],
      tratamiento: null,
      fecha_cita: null,
      programa_cita: null,

      loi_rut: null,
      loi_fecha_registro: null,
      id_usuario: null,
      modalUsuario: false,
      estado_gestion: 0,
      mostrarDetalle: false,
      tipo_lista: 0,
      loi_prioridad: null,

      mostrarPrioridad: false,
      mostrarAlertaConfirmarEv: false,
      mostrarModalObservacionFija: false,
      mostrarAlertaIngresoTTo: false,
      mostrarModalEditarRut: false,
      mostrarModalEditarFechaRe: false,

      trat_sec: null,
      tratamiento_secundarios: [],
    };
  }

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.obtenerTratamientoOirs(this.state.pagina, filas, this.state.ordenarPrioridad);
  };

  abrirModalObservacionFija = (loi_id) => {
    this.setState({
      mostrarModalObservacionFija: true,
      loi_id: loi_id,
    });
  };

  cerrarModalObservacionFija = () => {
    this.setState({
      mostrarModalObservacionFija: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalPrioridad = (loi_id, loi_prioridad) => {
    this.setState({
      mostrarPrioridad: true,
      loi_id: loi_id,
      loi_prioridad: loi_prioridad,
    });
  };

  cerrarModalPrioridad = () => {
    this.setState({
      mostrarPrioridad: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.obtenerTratamientoOirs(pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  handleFiltradorCampo = (ordenarPrioridad) => {
    this.setState({
      ordenarPrioridad: ordenarPrioridad,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, ordenarPrioridad);
  };

  componentDidMount() {
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
    this.obtenerCesfams();
    this.obtenerTratamientos();
    this.obtenerCesfams_evaluacion();
    this.obtenerTratamientoSec();
  }

  obtenerCesfams_evaluacion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfams_evaluacion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            cesfam: res.data.datos["tc_cesfam_transformado"],
            tipo_lista: res.data.datos["tc_tipo_lista"],
          });
        }
      });
  };

  obtenerTratamientoSec = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamiento_secundarios: res.data.datos,
          });
        }
      });
  };

  obtenerTratamientoOirs = async (pagina, fila, filtrador) => {
    axios
      .get(global.config.modulos.listaEspera + "ver_oirs/", {
        headers: authHeader(),
        params: {
          pagina: pagina,
          ordenarPrioridad: filtrador,
          textofiltro: this.state.texto_filtro,
          genero: this.state.genero,
          cesfam_b: this.state.cesfam_b,
          edad: this.state.edad,
          edad2: this.state.edad2,
          FechaInicio: this.state.fecha1,
          FechaTermino: this.state.fecha2,
          cesfam_admin: this.state.cesfam,
          tratamiento: this.state.tratamiento,
          tratamiento_sec: this.state.trat_sec,
          tipo: 2,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            total: res.data.total,
            listadoOirs: res.data.datos,
            pagina: res.data.pagina,
            ordenarPrioridad: res.data.prioridad,
          });
        } else {
          this.setState({
            total: 0,
            listadoOirs: [],
            pagina: 0,
            ordenarPrioridad: null,
          });
        }
      });
  };

  abrirModalObservacionesLibre = (loi_id) => {
    this.setState({
      mostrarModalObservacionLibre: true,
      loi_id: loi_id,
    });
  };

  cerrarModalObservacionLibre = () => {
    this.setState({
      mostrarModalObservacionLibre: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  tratamiento_sec = (e) => {
    const trat_sec = e.target.value;
    this.setState({ trat_sec: trat_sec });
  };

  abrirModalCrearObservacionLibre = (loi_id) => {
    this.setState({
      mostrarModalCrearObservacionLibre: true,
      loi_id: loi_id,
    });
  };

  cerrarModalCrearObservacionLibre = () => {
    this.setState({
      mostrarModalCrearObservacionLibre: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  mostrarModalUsuario = (idusuario) => {
    if (idusuario) {
      this.setState({
        modalUsuario: true,
        id_usuario: idusuario,
      });
    }
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  abrirModalUsuarioEnAlta = (loi_id) => {
    this.setState({
      mostrarModalUsuarioEnAlta: true,
      loi_id: loi_id,
    });
  };

  cerrarModalUsuarioEnAlta = () => {
    this.setState({
      mostrarModalUsuarioEnAlta: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  aplicarFiltros = () => {
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  filtroTexto = (e) => {
    const texto_filtro = e.target.value;
    this.setState({ texto_filtro: texto_filtro });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });
  };

  abrirAlertaIngresoTTo = (loi_id, fecha_cita, programa_cita, tipo) => {
    this.setState({
      mostrarAlertaIngresoTTo: true,
      loi_id: loi_id,
      fecha_cita: fecha_cita,
      programa_cita: programa_cita,
      tipo_cita: tipo,
    });
  };

  cerrarAlertaIngresoTTo = () => {
    this.setState({
      mostrarAlertaIngresoTTo: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirAlertaConfirmarEv = (loi_id, fecha_cita, programa_cita, tipo) => {
    this.setState({
      mostrarAlertaConfirmarEv: true,
      loi_id: loi_id,
      fecha_cita: fecha_cita,
      programa_cita: programa_cita,
      tipo_cita: tipo,
    });
  };

  cerrarAlertaConfirmarEv = () => {
    this.setState({
      mostrarAlertaConfirmarEv: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  obtenerCesfams = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_cesfams/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            cesfams: res.data.datos,
          });
        }
      });
  };

  abrirModalTelefonos = (loi_id) => {
    this.setState({
      mostrarModalTelefonos: true,
      loi_id: loi_id,
    });
  };

  cerrarModalTelefonos = () => {
    this.setState({
      mostrarModalTelefonos: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  descargarExcel = async () => {
    this.setState({ loading: true });
    NotificationManager.warning("Se inicio proceso de descarga de excel esto puede tardar unos segundos espere porfavor", "Descargando....", 3000);
    await axios.get((window.location.href = global.config.modulos.listaEspera + "Excel_lista_citados_tratamiento/?FechaInicio=" + this.state.fecha1 + "&FechaTermino=" + this.state.fecha2 + "&genero=" + this.state.genero + "&cesfam=" + this.state.cesfam_b + "&edad=" + this.state.edad + "&textofiltro=" + this.state.texto_filtro + "&tratamiento=" + this.state.tratamiento + "&cesfam_admin=" + this.state.cesfam), { headers: authHeader() }).then((res) => {
      this.setState({ loading: false });
    });
  };

  LimpiarFiltros = () => {
    this.setState(
      {
        cesfam_b: null,
        genero: null,
        edad: "",
        edad2: "",
        fecha2: "",
        fecha1: "",
        texto_filtro: "",
        tratamiento: null,
      },
      () => {
        this.setearNull();
      }
    );
  };

  setearNull = () => {
    this.setState(
      {
        cesfam_b: null,
        genero: null,
        edad: null,
        edad2: null,
        fecha2: null,
        fecha1: null,
        texto_filtro: null,
      },
      () => {
        this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
      }
    );
  };

  mostrarModalEditar = (loi_id, tipo, dato) => {
    this.setState({
      mostrarModalEditar: true,
      loi_id: loi_id,
      tipoEditar: tipo,
      datoEditar: dato,
    });
  };

  cerrarModalEditar = () => {
    this.setState({
      mostrarModalEditar: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  render() {
    var today = moment().format("DD-MM-YYYY");

    return (
      <div className="middle-content">
        <div style={{ display: "flex" }}>
          <FormControl style={{ width: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Nombre o Rut</InputLabel>
            <Input type="text" name="texto_filtro" value={this.state.texto_filtro} onChange={this.filtroTexto} />
          </FormControl>

          <FormControl style={{ width: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Genero</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" name="genero" value={this.state.genero} onChange={this.cambiarValores}>
              <MenuItem value={null}>--Seleccione Sexo--</MenuItem>
              <MenuItem value={"m"}>Masculino</MenuItem>
              <MenuItem value={"f"}>Femenino</MenuItem>
              <MenuItem value={"i"}>InterSex</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ width: "7%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Edad</InputLabel>
            <Input type="number" labelId="demo-simple-select-label" id="demo-simple-select" name="edad" value={this.state.edad} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ width: "7%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Edad 2</InputLabel>
            <Input type="number" labelId="demo-simple-select-label" id="demo-simple-select" name="edad2" value={this.state.edad2} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ width: "8%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Cesfam</InputLabel>
            <Select name="cesfam_b" value={this.state.cesfam_b} onChange={this.cambiarValores}>
              <MenuItem value={null}>Selecciona Cesfams</MenuItem>
              {this.state.cesfams.map((cesfam, i) => {
                return (
                  <MenuItem key={i} value={cesfam.ces_id}>
                    {cesfam.ces_nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ minWidth: "12%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Diagnóstico</InputLabel>
            <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
              <MenuItem value={null}>Seleccione Tratamiento</MenuItem>
              {this.state.tratamientos.map((tratamiento, i) => {
                return (
                  <MenuItem key={i} value={tratamiento.lt_id}>
                    {tratamiento.lt_nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Resultado examen</InputLabel>
            <Select name="tratamiento" value={this.state.trat_sec} onChange={this.tratamiento_sec}>
              <MenuItem value={null}>Seleccione resultado de examen</MenuItem>
              {this.state.tratamiento_secundarios.map((tt_sec, i) => {
                return (
                  <MenuItem key={i} value={tt_sec.lre_id}>
                    {tt_sec.lre_nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Buttons onClick={this.aplicarFiltros} className={"btnOpcion fondoNaranjo"}>
            Filtro
          </Buttons>
          <Buttons onClick={this.LimpiarFiltros} className={"btnOpcion fondoNaranjo"}>
            Limpiar Filtro
          </Buttons>

          <Tooltip title={<h4>Descargar Excel lista de otros casos</h4>} aria-label="Lista de ingresos">
            <img className="imagenBotonAccionMenu" src={descargar} onClick={() => this.descargarExcel()} />
          </Tooltip>
          {this.state.loading ? <img style={{ width: "80px", height: "50px" }} src={cargando} alt="my-gif" /> : null}
        </div>

        <div
          style={{
            margin: "1% 0px",
          }}
        >
          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <Input type="date" labelId="demo-simple-select-label" id="demo-simple-select" name="fecha1" value={this.state.fecha1} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <Input type="date" labelId="demo-simple-select-label" id="demo-simple-select" name="fecha2" value={this.state.fecha2} onChange={this.cambiarValores}></Input>
          </FormControl>
        </div>

        <TableContainer style={{ marginBottom: "120px" }}>
          <Table className="tablaGeneralGestion bordeTabla" aria-label="simple table">
            <TableHeader nombreTitulo={this.state.nombreTitulo} datocampo={this.handleFiltradorCampo} />
            <TableBody>
              {this.state.listadoOirs.map((row, index) => (
                <TableRow key={index}>
                  {row.loi_fecha_invalida === 1 ? (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h2>{["Código: ", row.loi_fila_b1]}</h2>}>
                          <p style={{ fontSize: "14px" }}>{moment(row.loi_fecharegistro).format("DD-MM-YYYY")}</p>
                        </Tooltip>
                      </React.Fragment>

                      <React.Fragment>
                        <Tooltip title={<h2>Modificar fecha registro</h2>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.mostrarModalEditar(row.loi_id, 4, row.loi_fecha_texto_invalida)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h2>{["Código: ", row.loi_fila_b1]}</h2>}>
                          <p style={{ fontSize: "14px" }}>{moment(row.loi_fecharegistro).format("DD-MM-YYYY")}</p>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  )}

                  {row.loi_rut_valido === 1 ? (
                    <TableCell align="center">
                      <p style={{ fontSize: "14px" }}>{row.loi_rut}</p>{" "}
                      <React.Fragment>
                        <Tooltip title={<h2>Modificar Rut</h2>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.mostrarModalEditar(row.loi_id, 5, row.loi_rut)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : (
                    <TableCell>
                      {
                        <a style={{ cursor: "pointer" }} onClick={() => this.mostrarModalUsuario(row.loi_usuario)}>
                          {chileanRut.format(row.loi_rut, true)}
                        </a>
                      }
                    </TableCell>
                  )}

                  <TableCell align="center">
                    <Tooltip
                      title={
                        <div>
                          <h2>{["Fecha de Nacimiento: ", row.nacimiento]}</h2>
                          <h2>{["Edad: ", row.edad]} </h2>
                          <h2>{["Género: ", row.sexo]} </h2>
                          <h2>{["Cesfam: ", row.cesfam_nombre]}</h2>
                        </div>
                      }
                    >
                      <p style={{ fontSize: "14px" }}>{row.loi_nombre} </p>
                    </Tooltip>
                  </TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip
                        title={[
                          <div>
                            <h2>{["Fecha de cambio: ", row.fecha_modificado]}</h2>
                          </div>,
                          <div>
                            <h2>{["Funcionario/a: ", row.nombre_profesional]}</h2>
                          </div>,
                        ]}
                        style={{ fontSize: "20px" }}
                      >
                        <p style={{ fontSize: "14px" }}> {row.fecha_diagnostico} </p>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>

                  <TableCell align="center">
                    <Tooltip title={<h2>{["Escala de evaluacion: ", row.nombre_escala]}</h2>}>
                      <p style={{ fontSize: "14px" }}>{row.loi_tratamiento == "63" && row.loi_tratamiento_libre != null ? row.loi_tratamiento_libre : row.nombre_tratamiento}</p>
                    </Tooltip>
                  </TableCell>

                  <TableCell align="center">
                    {row.prioridad}
                    <React.Fragment>
                      <Tooltip title={<h2>Ingresar Prioridad</h2>}>
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "10px",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalPrioridad(row.loi_id, row.loi_prioridad)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip
                        title={[
                          <div>
                            <div>
                              <h2>{["Nsp: ", row.loi_nsp_ev]}</h2>
                            </div>
                            <div>
                              <h2>{["Suspenciones: ", row.loi_bloqueo_ev]}</h2>
                            </div>
                            <div>
                              <h2>{["Sesiones: ", row.loi_sesiones_ev]}</h2>
                            </div>
                          </div>,
                        ]}
                        style={{ fontSize: "20px" }}
                      >
                        <p style={{ fontSize: "14px" }}> {row.fecha_evaluacion} </p>
                      </Tooltip>
                    </React.Fragment>

                    <div
                      style={{
                        display: "flex",
                        marginLeft: "20px",
                        marginTop: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <React.Fragment>
                        <Tooltip title="Confirmar el estado final de la cita">
                          <div style={{ marginRight: "10px", marginTop: "2px" }}>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirAlertaConfirmarEv(row.loi_id, row.fecha_evaluacion, row.loi_tratamiento, 1)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>

                      <React.Fragment>
                        <Tooltip title="Ingresar una nueva fecha de citación">
                          <div>
                            <img class="mediumIcon" style={{ width: "41px" }} src={inscribir} onClick={() => this.abrirAlertaConfirmarEv(row.loi_id, row.fecha_evaluacion, row.loi_tratamiento, 2)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </div>
                  </TableCell>

                  <TableCell align="center"> {row.nombre_examen}</TableCell>

                  <TableCell align="center">
                    {row.loi_fecha_tratamiento}

                    <div
                      style={{
                        display: "flex",
                        marginLeft: "20px",
                        marginTop: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <React.Fragment>
                        <Tooltip title="Ingresar paciente a tratamiento">
                          <div style={{ marginRight: "10px" }}>
                            <img class="mediumIcon" src={inscribir} onClick={() => this.abrirAlertaIngresoTTo(row.loi_id, today, row.loi_tratamiento, 3)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </div>
                  </TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip title={<h2>Ingresar Observación</h2>}>
                        <div>
                          <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalObservacionFija(row.loi_id)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>

                  <TableCell align="center">
                    {row.observacion_usuario}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "20px",
                        marginTop: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <React.Fragment>
                        <Tooltip title={<h2>Ver observación libre</h2>}>
                          <div style={{ marginRight: "10px" }}>
                            <img class="mediumIcon" src={ver_mas} aria-label="Observaciones libres" onClick={() => this.abrirModalObservacionesLibre(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>

                      <React.Fragment>
                        <Tooltip title={<h2>Ingresar observación libre</h2>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalCrearObservacionLibre(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
        </TableContainer>

        {this.state.mostrarModalCrearObservacionLibre ? <UsuarioCrearObservacionLibre ocultar={this.cerrarModalCrearObservacionLibre} id_usuario={this.state.loi_id}></UsuarioCrearObservacionLibre> : null}
        {this.state.mostrarModalObservacionLibre ? <VerObservacionesLibre ocultar={this.cerrarModalObservacionLibre} id_usuario={this.state.loi_id}></VerObservacionesLibre> : null}
        {this.state.mostrarModalObservacionFija ? <UsuarioObservacionFija ocultar={this.cerrarModalObservacionFija} id_usuario={this.state.loi_id}></UsuarioObservacionFija> : null}

        {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.id_usuario}></VistaUsuarios> : null}

        {this.state.mostrarPrioridad ? <UsuarioPrioridad ocultar={this.cerrarModalPrioridad} id_usuario={this.state.loi_id} id_prioridad={this.state.loi_prioridad}></UsuarioPrioridad> : null}
        {this.state.mostrarModalUsuarioEnAlta ? <UsuarioEnAlta ocultar={this.cerrarModalUsuarioEnAlta} id_usuario={this.state.loi_id}></UsuarioEnAlta> : null}

        {this.state.mostrarAlertaConfirmarEv ? <ConfirmarEstadoEvaluacion ocultar={this.cerrarAlertaConfirmarEv} loi_id={this.state.loi_id} fecha_cita={this.state.fecha_cita} programa_cita={this.state.programa_cita} tipo_cita={this.state.tipo_cita}></ConfirmarEstadoEvaluacion> : null}
        {this.state.mostrarAlertaIngresoTTo ? <ConfirmarUsuarioTratamiento ocultar={this.cerrarAlertaIngresoTTo} loi_id={this.state.loi_id} fecha_cita={this.state.fecha_cita} programa_cita={this.state.programa_cita} tipo_cita={this.state.tipo_cita}></ConfirmarUsuarioTratamiento> : null}
        {this.state.mostrarModalEditar ? <EditarDatos ocultar={this.cerrarModalEditar} registro={this.state.loi_id} tipo={this.state.tipoEditar} dato={this.state.datoEditar} escala_ev={this.state.escala_ev} patologia={this.state.patologia}></EditarDatos> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaCitadosTratamiento);
