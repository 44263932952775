import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class EliminarUsuario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      uspId: store.getState().usp,
      usuId: props.usuario,
      motivosEliminacion: [],
      formErrors: {},
      open: true,
    };

    this.traerMotivosEliminacion();
  }

  eliminar = () => {
    this.setState({
      open: false,
    });
    this.props.eliminar();
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAlertaEliminado() {
    NotificationManager.success("Paciente eliminado con éxito");
  }

  validaciones() {
    const { motivo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (motivo == "" || motivo == 0) {
      formIsValid = false;
      formErrors["motivoErr"] = "Selecciona el motivo.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    let usuId = this.state.usuId;

    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "Segur@ que desea eliminar este paciente?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
            onClick: () => null,
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: () => {
              var data = {
                usu_estado: 2,
                usu_motivo_eliminacion: this.state.motivo,
                usp_id: this.state.uspId,
              };
              let apiUrl = global.config.modulos.usuarios + "por_id/" + usuId;
              const options = {
                method: "DELETE",
                data: data,
                headers: authHeader(),
              };
              axios(apiUrl, options).then((res) => {
                this.eliminar();
                this.mostrarAlertaEliminado();
              });
            },
          },
        ],
      });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerMotivosEliminacion() {
    const apiUrl = global.config.modulos.usuarios + "motivos_eliminacion_usuario/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        this.setState({
          motivosEliminacion: res.data.motivos,
        });
      });
  }

  render() {
    const { motivoErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="eliminarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Eliminar</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Select id="motivo" name="motivo" value={this.state.motivo} onChange={this.cambiarValores} displayEmpty>
                    <MenuItem value="" disabled>
                      <em>¿Porqué esta eliminando al paciente?</em>
                    </MenuItem>

                    {this.state.motivosEliminacion &&
                      this.state.motivosEliminacion.map((mb, index) => (
                        <MenuItem value={mb.meu_id} key={index}>
                          {mb.meu_motivo}
                        </MenuItem>
                      ))}
                  </Select>
                  {motivoErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoErr}</div>}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cancelar
                  </Button>
                  <Button className="botonDialog fondoVerde" onClick={this.enviarFormulario}>
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
