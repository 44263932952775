import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import BeneficioDesinscribir from "./BeneficioDesinscribirRiesgoDental";
import { store } from "./../../store/index";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import { param } from "express/lib/request.js";
import MostrarAudios from "./MostrarAudios";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioRiesgoDental extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      datosBeneficio: props.beneficio,
      formErrors: {},
      modalBeneficioInscribir: false,
      modalBeneficioDesinscribir: false,
      beneficio: null,
      nombreBeneficio: null,
      open: true,
      herramientas: store.getState().herramientas,
      especialidades: "",
      llamados: [],
      modalAudios: false,
    };
  }

  traeBeneficios = () => {
    this.props.traeBeneficios();
  };

  verLLamados = () => {
    axios
      .get(global.config.modulos.beneficios + "obtener_registros_usuario/", {
        headers: authHeader(),
        params: { usuario: this.state.usuId },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ llamados: res.data.datos }, () => {
            this.mostrarAudios();
          });
        } else {
          NotificationManager.error("No se encontraron registros");
        }
      });
  };

  mostrarAudios = () => {
    this.setState({ modalAudios: true });
  };

  ocultarAudios = () => {
    this.setState({ modalAudios: false });
  };

  mostrarBeneficioInscribir = (beneficio, nombre, especialidad) => {
    this.setState({
      modalBeneficioInscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
      especialidades: especialidad,
    });
  };

  ocultarBeneficioInscribir = () => {
    this.setState({ modalBeneficioInscribir: false });
    this.asignarDatosProfesional();
    this.traeBeneficios();
  };

  mostrarBeneficioDesinscribir = (beneficio, nombre) => {
    this.setState({
      modalBeneficioDesinscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
    });
  };

  ocultarBeneficioDesinscribir = () => {
    this.setState({ modalBeneficioDesinscribir: false });
    this.traeBeneficios();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  inscribir = () => {
    axios
      .post(
        global.config.modulos.beneficios + "inscribir/",
        {
          fechaInicio: null,
          fechaExtra: null,
          fechaTermino: null,
          usuario: this.state.usuId,
          beneficio: this.state.datosBeneficio.ben_id,
          usuarioPanel: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Inscrito con éxito");
        } else {
          NotificationManager.error("Ha ocurrido un error al inscribir");
        }
        this.traeBeneficios();
      });
  };

  render() {
    const beneficio = this.state.datosBeneficio;

    return (
      <React.Fragment>
        {beneficio.estadoUsuario ? (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Activo
                </p>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p className="parrafoBeneficio">
                    <label className="labelBeneficio">Fecha de ingreso: </label> {beneficio.usuario.usb_fecha_inicio}
                  </p>

                  {this.state.herramientas.includes("H76") ? (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Tooltip title="Ver los registros">
                        <img src={ver_mas} onClick={() => this.verLLamados()} className="mediumIcon" alt="ver_mas" />
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioDesinscribir(beneficio.ben_id, beneficio.ben_nombre)} className="botonDesinscribirBeneficio">
                      Desinscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        ) : (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Inactivo
                </p>

                <br />
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.inscribir()} className="botonInscribirBeneficio">
                      Inscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        )}

        {this.state.modalBeneficioDesinscribir ? <BeneficioDesinscribir ocultar={this.ocultarBeneficioDesinscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} idPrioridad={this.state.idPrioridad} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioDesinscribir> : null}

        {this.state.modalAudios ? <MostrarAudios audios={this.state.llamados} ocultar={this.ocultarAudios}></MostrarAudios> : null}
      </React.Fragment>
    );
  }
}
