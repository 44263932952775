import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { NotificationManager } from "react-notifications";

// core components
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { confirmAlert } from "react-confirm-alert"; // Import

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModalCambioBloqueo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: store.getState().usp,
      cesfam: store.getState().cesfam,

      horarios: props.horario,

      motivoBloqueo: "0",
      motivoBloqueoTxt: "",

      open: true,
      formErrors: [],
    };

    this.traerMotivosBloqueo();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirExito = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarExito();
  };

  traerMotivosBloqueo() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_bloqueo_horario/?cesfam=" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var motivosBloqueo = res.data;
        this.setState({
          motivosBloqueo: motivosBloqueo,
        });
      });
  }

  validaciones() {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    let formIsValid = true;

    if (motivoBloqueo == "0") {
      NotificationManager.warning("Debe Seleccionar un motivo");
      formIsValid = false;
    } else if (motivoBloqueo == "otro" && motivoBloqueoTxt == "") {
      NotificationManager.warning("Debe escribir el motivo");
      formIsValid = false;
    }

    return formIsValid;
  }

  confirmarCambiarBloqueo = () => {
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ de cambiar el motivo del bloqueo de los horarios?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => this.cambiarBloqueoHorarios(),
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  cambiarBloqueoHorarios = () => {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    var motivoBloqueoHora = "";
    if (motivoBloqueo == "Activa") {
      motivoBloqueoHora = null;
    } else if (motivoBloqueo == "otro") {
      motivoBloqueoHora = motivoBloqueoTxt;
    } else {
      motivoBloqueoHora = motivoBloqueo;
    }

    var data = {
      horarios: this.state.horarios,
      motivoBloqueo: motivoBloqueoHora,
      canal: 5,
      uspId: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "cambiar_motivo_horarios_bloqueados/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;
      this.salirExito();
      NotificationManager.success(data.mensaje);
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const cambioMotivoBloqueo = (event) => {
      if (event.target.value == "otro") {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: true,
        });
      } else {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: false,
        });
      }
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Cambiar motivo de bloqueo anterior</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <InputLabel style={{ color: "black" }}>Motivo de bloqueo</InputLabel>
                  <br></br>
                  <Select name="motivoBloqueo" className={"selectSimple"} value={this.state.motivoBloqueo} onChange={cambioMotivoBloqueo} style={{ minWidth: 250 }} displayEmpty>
                    <MenuItem value={"0"} key={0}>
                      {"Selecciona"}
                    </MenuItem>

                    {this.state.motivosBloqueo &&
                      this.state.motivosBloqueo.map((mb, index) => (
                        <MenuItem value={mb.mb_descripcion} key={index}>
                          {mb.mb_nombre}
                        </MenuItem>
                      ))}
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className="selectMotivoBloqueo">
                  {this.state.mostrarMotivoTxt ? <TextareaAutosize name="motivoBloqueoTxt" aria-label="minimum height" className={"selectSimple "} style={{ float: "left", minWidth: 250, marginTop: 20 }} rowsMin={3} placeholder="Escribe el motivo..." value={this.state.motivoBloqueoTxt} onChange={this.cambiarValores} /> : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button variant="contained" className="btnCargaHorario fondoRojo" style={{ backgroundColor: "#ea2828" }} onClick={this.salir}>
                    Cancelar
                  </Button>
                  <Button variant="contained" className="btnCargaHorario fondoVerde" onClick={this.confirmarCambiarBloqueo}>
                    Reemplazar motivos
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
