import "moment/locale/es";
import React, { useState, useEffect, Component, useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Titulo from "../../components/titulo/Titulo.js";
import FiltrosOfertaDemanda from "./FiltrosOfertaDemanda";
import ModalGraficoOferta from "../../components/Reportes/ModalGraficoOferta.js";
import axios from "axios";
import { store } from "../../store/index";
import CargaGraficoOferta from "./CargaGraficoOferta";
import ModalGraficoDemanda from "../../components/Reportes/ModalGraficoDemanda.js";
import GraficoConsolidado from "./CargaGraficoConsolidado.js";
import CargaGraficoDemanda from "./CargaGraficoDemanda";
import { Input } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import Button from "react-multi-date-picker/components/button";
//import "./../../../assets/css/graficos.css";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import StiloBtn from "../../assets/css/ofertademanda.module.css";

import signo from "./../../assets/img/signo.svg";

class VistaOfertaYDemanda extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      familia: null,
      semana: null,
      showModalOferta: false,
      especialidades: [],
      selectedEsp: null,
      tipoAtenciones: [],
      tipoAtencion: null,
      selectedSem: null,
      cesfam: store.getState().cesfam,
      ofertasProgramadas: [],
      ofertasEntregadas: [],
      ofertasDisponible: [],
      datosConsolidado: [],
      datosGrafico: [],
      dia: null,
      titulo: null,
      tipo: 0,
      hide_entregadas: false,
      hide_disponibles: false,
      hide_programadas: false,
      botones: false,
      abrirGrafico: false,
      abrirGraficoC: false,
      displayC: "none",
      ofertasDemandas: [],
      datosGrafico: [],
      showModalOferta: false,
      dia: null,
      titulo: null,
      tipo: 0,
      mostrar_filtro: false,
      fecha: 1,

      hide_entregadas: false,
      hide_disponibles: false,
      hide_programadas: false,
      showModalDemanda: false,
      showModalOferta: false,

      open: true,
    };
  }

  componentDidMount() {
    this.especialidades();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Oferta - Demanda <span title='Podrá visualizar toda la información relacionada a la oferta y demanda'><img src=" + signo + "></img></span>";
  };

  selectorEspecialidad = (e) => {
    const selectedEsp = e.target.value;
    this.setState({ selectedEsp: selectedEsp });
  };

  especialidades = () => {
    axios
      .get(global.config.modulos.reportes + "listado_especialidades/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            especialidades: res.data.datos,
          });
        }
      });
  };

  ofertasProgramadas = async () => {
    if (this.state.familia == null) {
      NotificationManager.error("Seleccione una especialidad", "Atención", 3000);
      return;
    }
    await axios
      .get(global.config.modulos.grafico_demanda + "oferta_por_semana/", {
        headers: authHeader(),
        params: {
          semana: this.state.semana,
          especialidad: this.state.tipoAtencion,
          cesfam: this.state.cesfam,
          fecha: this.state.fecha,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ ofertasProgramadas: res.data.datos });
        } else {
          this.setState({ ofertasProgramadas: [] });
        }
      });

    await axios
      .get(global.config.modulos.grafico_demanda + "demanda_por_semana/", {
        headers: authHeader(),
        params: {
          semana: this.state.semana,
          especialidad: this.state.tipoAtencion,
          cesfam: this.state.cesfam,
          fecha: this.state.fecha,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ ofertasDemandas: res.data.datos });
        } else {
          this.setState({ ofertasDemandas: [] });
        }
      });
    this.setState({ abrirGrafico: true });
  };

  ofertasConsolidado = async () => {
    if (this.state.familia == null) {
      NotificationManager.error("Seleccione una especialidad", "Atención", 3000);
      return;
    }
    await axios
      .get(global.config.modulos.grafico_demanda + "grafico_Consolidado/", {
        headers: authHeader(),
        params: {
          semana: this.state.semana,
          especialidad: this.state.tipoAtencion,
          cesfam: this.state.cesfam,
          fecha: this.state.fecha,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ datosConsolidado: res.data.datos });
        } else {
          this.setState({ datosConsolidado: [] });
        }
      });
    this.setState({ abrirGraficoC: true });
  };

  ocultarModalOferta = () => {
    this.setState({ showModalOferta: false });
  };

  ocultarModalDemanda = () => {
    this.setState({ showModalDemanda: false });
  };

  cambiarFiltro = (i) => {
    this.setState(
      {
        abrirGrafico: false,
        fecha: i,
        abrirGraficoC: false,
        displayC: "none",
        botones: true,
      },
      () => {
        this.ofertasProgramadas();
      }
    );
  };

  cambiarConsolidado = (i) => {
    this.setState(
      {
        fecha: i,
        abrirGraficoC: false,
        abrirGrafico: false,
        displayC: "block",
        botones: true,
      },
      () => {
        this.ofertasConsolidado();
      }
    );
  };

  traerTipoAtencion = (cesfam, familia) => {
    console.log("Llamando a traerTipoAtencion con familia:", this.state.familia);
    axios
      .get(global.config.modulos.grafico_demanda + "especialidad_por_familia/", {
        headers: authHeader(),
        params: {
          cesfam: cesfam,
          familia: familia,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          console.log("error");
        } else {
          this.setState({
            mostrar_filtro: true,
            tipoAtenciones: res.data.datos,
          });
        }
      })
      .catch((error) => {
        console.error("Error en la llamada a la API:", error);
      });
  };

  onChangeEsp = (event) => {
    this.setState({ familia: event.target.value, mostrar_filtro: false }, () => {
      this.traerTipoAtencion(this.state.cesfam, this.state.familia);
    });
  };

  onChangeTipo = (event) => {
    this.setState({ tipoAtencion: event.target.value });
  };

  onChangeSem = (event) => {
    this.setState({ semana: event.target.value });
  };

  handleClick = () => {
    this.cambiarFiltro(1);
  };

  hsEntregadas = (dia, familia) => {
    this.setState({
      showModalOferta: true,
      dia: dia,
      titulo: familia,
      tipo: "entregadas",
    });
  };

  hsProgramadas = (dia, familia) => {
    this.setState({
      showModalOferta: true,
      dia: dia,
      titulo: familia,
      tipo: "programadas",
    });
  };

  hsDisponible = (dia, familia) => {
    this.setState({
      showModalOferta: true,
      dia: dia,
      titulo: familia,
      tipo: "disponibles",
    });
  };

  hsDemanda = (dia, familia) => {
    this.setState({ showModalDemanda: true, dia: dia, titulo: familia });
  };

  render() {
    const especialidad = this.state.ofertasProgramadas.map((esp) => esp.especialidad);
    //extract only one value of the array especialidad
    const especialidad_unique = [...new Set(especialidad)];
    {
      console.log("especialidad_unique", especialidad_unique);
    }
    const fecha_completa = moment().format("YYYY") + "-W" + moment().week();
    console.log("fecha_completa", fecha_completa);
    return (
      <div className="middle-area" style={{ height: "85%" }}>
        <div className="middle-content">
          <div className="filtros-oferta-demanda" style={{ marginTop: "10px" }}>
            <FormControl style={{ width: "20%", marginLeft: "1%" }}>
              <InputLabel id="demanda-label">Especialidad</InputLabel>
              <Select labelId="demanda-label" id="demanda-select" value={this.state.familia} onChange={this.onChangeEsp}>
                <MenuItem value={null}>Seleccione una especialidad</MenuItem>
                {this.state.especialidades.map((esp, i) => {
                  return (
                    <MenuItem key={i} value={esp.id}>
                      {esp.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {this.state.mostrar_filtro ? (
              <FormControl style={{ width: "20%", marginLeft: "1%" }}>
                <InputLabel id="demanda-label">Tipo de atencion</InputLabel>
                <Select labelId="demanda-label" id="demanda-select" value={this.state.tipoAtencion} onChange={this.onChangeTipo}>
                  <MenuItem value={null}>Seleccione un tipo de atencion</MenuItem>
                  {this.state.tipoAtenciones.map((esp, i) => {
                    return (
                      <MenuItem key={i} value={esp.esp_id}>
                        {esp.esp_especialidad}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}

            <FormControl style={{ width: "20%", marginLeft: "2%", marginTop: "16px" }}>
              <Input
                type="week"
                name="semana"
                value={this.state.selectedSem}
                //inputProps={{ max: fecha_completa }}
                //max={fecha_completa}
                onChange={this.onChangeSem}
              ></Input>
            </FormControl>
            <div style={{ marginLeft: "2%", marginTop: "-14px" }}>
              <Button className="boton-filtrar" onClick={this.handleClick}>
                Filtrar
              </Button>
            </div>
          </div>
          {this.state.botones ? (
            <div
              style={{
                marginTop: "1%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className={` ${this.state.fecha == 0 ? StiloBtn.btnOFActive : StiloBtn.btnOF}`}
                onClick={() => {
                  this.cambiarConsolidado(0);
                }}
              >
                Consolidado
              </Button>
              <Button
                className={` ${this.state.fecha == 1 ? StiloBtn.btnOFActive : StiloBtn.btnOF} `}
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  this.cambiarFiltro(1);
                }}
              >
                Lunes
              </Button>
              <Button
                className={` ${this.state.fecha == 2 ? StiloBtn.btnOFActive : StiloBtn.btnOF} `}
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  this.cambiarFiltro(2);
                }}
              >
                Martes
              </Button>
              <Button
                className={` ${this.state.fecha == 3 ? StiloBtn.btnOFActive : StiloBtn.btnOF} `}
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  this.cambiarFiltro(3);
                }}
              >
                Miercoles
              </Button>
              <Button
                className={` ${this.state.fecha == 4 ? StiloBtn.btnOFActive : StiloBtn.btnOF} `}
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  this.cambiarFiltro(4);
                }}
              >
                Jueves
              </Button>
              <Button
                className={` ${this.state.fecha == 5 ? StiloBtn.btnOFActive : StiloBtn.btnOF} `}
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  this.cambiarFiltro(5);
                }}
              >
                Viernes
              </Button>
              <Button
                className={` ${this.state.fecha == 6 ? StiloBtn.btnOFActive : StiloBtn.btnOF} `}
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  this.cambiarFiltro(6);
                }}
              >
                Sabado
              </Button>
              <Button
                className={` ${this.state.fecha == 7 ? StiloBtn.btnOFActive : StiloBtn.btnOF} `}
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  this.cambiarFiltro(7);
                }}
              >
                Domingo
              </Button>
            </div>
          ) : null}

          <div className="oferta-demanda" style={{ marginTop: "20px" }}>
            <div className="oferta" style={{ width: "49%", marginRight: "5px" }}>
              {this.state.abrirGrafico ? <h4 style={{ marginBottom: "40px" }}>Oferta </h4> : null}
              {this.state.abrirGrafico ? (
                <React.Fragment>
                  {especialidad_unique.length > 0 ? <h4>{especialidad_unique}</h4> : <h4 style={{ marginBottom: "20px" }}>Ofertas</h4>}
                  <CargaGraficoOferta key={"chartdiv"} data={this.state.ofertasProgramadas} hsProgramadas={this.hsProgramadas} hsDisponible={this.hsDisponible} hsEntregadas={this.hsEntregadas} />
                </React.Fragment>
              ) : null}
            </div>

            <div className="demanda" style={{ width: "49%" }}>
              {this.state.abrirGrafico ? <h4 style={{ marginBottom: "20px", marginLeft: "30px" }}>Demanda por rut </h4> : null}
              {this.state.abrirGrafico ? <CargaGraficoDemanda key={"graficoDemanda"} data={this.state.ofertasDemandas} hsDemanda={this.hsDemanda} /> : null}
            </div>

            <div className="Consolidado" style={{ width: "98%", display: this.state.displayC }}>
              {this.state.abrirGraficoC ? <h4 style={{ marginBottom: "20px", marginLeft: "30px" }}>Consolidado </h4> : null}
              {this.state.abrirGraficoC ? <GraficoConsolidado key={"consolidado"} data={this.state.datosConsolidado} /> : null}
            </div>

            {this.state.showModalOferta ? <ModalGraficoOferta ocultar={this.ocultarModalOferta} titulo={this.state.titulo} dia_semana={this.state.dia} tipo={this.state.tipo} semana={this.state.semana} nombre={especialidad_unique}></ModalGraficoOferta> : null}
            {this.state.showModalDemanda ? <ModalGraficoDemanda ocultar={this.ocultarModalDemanda} titulo={this.state.familia} dia_semana={this.state.dia} tipo={this.state.tipo} semana={this.state.semana} nombre={especialidad_unique}></ModalGraficoDemanda> : null}
          </div>
        </div>
      </div>
    );
  }
}
export default VistaOfertaYDemanda;
