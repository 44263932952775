import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { debounce } from "lodash";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import NotificationManager from "react-notifications/lib/NotificationManager";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import thisStringValue from "es-abstract/2015/thisStringValue";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default class AgregarDifusion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      cesfams: [],
      repeticionOpciones: [
        { value: "0", label: "Día", dias: "1" },
        { value: "1", label: "Semana", dias: "7" },
        { value: "2", label: "Mes", dias: "30" },
      ],
      repeticionSelect: null,
      sexoOpciones: [
        { value: "0", label: "Masculino" },
        { value: "1", label: "Femenino" },
        { value: "2", label: "Ambos" },
      ],
      sexoSelect: null,
      condicionOpciones: [],
      condicionSelect: null,
      canalesOpciones: [{ value: "", label: "" }],
      canalSelect: [],
      configuracionAppOpciones: [
        { value: "1", label: "Texto" },
        { value: "2", label: "Imagen" },
        { value: "3", label: "Video" },
        { value: "4", label: "Link" },
        { value: "5", label: "Gif" },
      ],
      configuracionAppSelect: null,
      encuestaOpciones: [],
      encuestaSelect: null,
      especialidadOpciones: [],
      especialidadSelect: null,

      nombre: "",
      codigo: "",
      inscritos: true,
      univComuna: false,
      univCesfam: false,
      cesfamSelect: null,
      comuna: null,

      repetir: "",
      inicio: null,
      fin: null,
      edadMin: "",
      edadMax: "",
      mensajeTxt: "",
      linkTxt: "",
      mensajeTxtEsp: "",
      archivo: 0,
      archivoOld: 0,

      difusionDetalle: false,
      open: true,

      encuesta: false,
      informacion: false,
      reserva: false,
      inscripcion: false,

      texto: false,
      link: false,
      imagen: false,
      video: false,
      gif: false,

      errorValid: false,
      idnew: null,
    };
  }

  componentDidMount() {
    let open = !this.props.editar && !this.props.clon;
    this.setState({ open: open }, () => {
      this.obtenerListaCesfams();
      this.obtenerListaCondiciones();
      this.obtenerListaEspecialidades();
      this.obtenerListaCanales();
      this.obtenerListaEncuestas();
      this.cargarDifusEditar();
    });
  }

  salir = () => {
    //console.log(this.state.idnew);
    this.setState({
      open: false,
      difusionDetalle: false,
    });
    this.props.ocultar(this.state.idnew);
  };

  obtenerListaCesfams = () => {
    let apiUrl = global.config.modulos.difusion + "lista_cesfams_difusion/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let val = 0;
      this.setState({ comuna: res.data.comuna });
      res.data.cesfams.map((item) => {
        this.state.cesfams.push({
          label: item.ces_nombre,
          val: val,
          id: item.ces_id,
        });
        val++;
      });
    });
  };

  obtenerListaCondiciones = () => {
    let apiUrl = global.config.modulos.difusion + "lista_condiciones_difusion/";
    if (this.state.sexoSelect && this.state.sexoSelect.label === "Femenino") {
      apiUrl = apiUrl + "?mujer=1";
    }
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let cond = [];
      let val = 0;
      res.data.condiciones.map((item) => {
        cond.push({
          label: item.con_nombre,
          val: val,
          id: item.con_id,
        });
        this.setState({ condicionOpciones: cond });
        val++;
      });
    });
  };

  obtenerListaEspecialidades = () => {
    let apiUrl = global.config.modulos.difusion + "lista_especialidades_por_cesfam/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let esp = [];
      let val = 0;
      res.data.especialidades.map((item) => {
        esp.push({
          label: item.esp_nombre,
          val: val,
          id: item.esp_id,
        });
        this.setState({ especialidadOpciones: esp });
        val++;
      });
    });
  };

  obtenerListaEncuestas = () => {
    let apiUrl = global.config.modulos.cuestionarios + "lista_cuestionarios/" + "?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let enc = [];
      let val = 0;
      res.data.map((encuesta) => {
        enc.push({ label: encuesta.cue_nombre, val: val, id: encuesta.cue_id });
      });
      this.setState({ encuestaOpciones: enc });
      val++;
    });
  };

  obtenerListaCanales = () => {
    let apiUrl = global.config.modulos.difusion + "lista_canales_difusion/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let esp = [];
      let val = 0;
      res.data.canales.map((item) => {
        esp.push({
          label: item.can_nombre,
          val: val,
          id: item.can_id,
        });
        this.setState({ canalesOpciones: esp });
        val++;
      });
      /* console.log("traje los canales"); */
    });
  };

  cargarDifusEditar = () => {
    if (this.props.editar || this.props.clon) {
      const dic_id = this.props.dif_edit.dic_id;
      let apiUrl = global.config.modulos.difusion + "por_id/" + dic_id;
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        /* console.log(res.data); */
        let canalSelect = [];
        let canales = res.data.dic_canales.split(",");
        canales = canales.filter((item) => item !== "");

        canales.map((can) => {
          let canal = this.state.canalesOpciones.filter((item) => item.id === parseInt(can));
          canalSelect.push(canal[0]);
        });
        let repeticionSelect = this.state.repeticionOpciones.filter((item) => item.dias === res.data.dic_repeticion_dias.toString());
        let sexoSelect = this.state.sexoOpciones.filter((item) => item.value === res.data.dic_sexo.toString());
        let condicionSelect = this.state.condicionOpciones.filter((item) => item.id === res.data.dic_condicion);
        let configuracionAppSelect = this.state.configuracionAppOpciones.filter((item) => item.value === res.data.dic_config_app.toString());
        let encuestaSelect = this.state.encuestaOpciones.filter((item) => item.id === res.data.dic_encuesta);
        let especialidadSelect = this.state.especialidadOpciones.filter((item) => item.id === res.data.dic_especialidad);
        let cesfamSelect = this.state.cesfams.filter((item) => item.id === res.data.dic_cesfam.toString());
        this.setState(
          {
            nombre: res.data.dic_nombre,
            codigo: res.data.dic_codigo,
            inscritos: res.data.dic_inscritos === 1 ? true : false,
            univComuna: res.data.dic_carga_usuario === 1 ? true : false,
            univCesfam: res.data.dic_carga_usuario === 2 ? true : false,
            cesfamSelect: cesfamSelect[0],
            comuna: res.data.dic_comuna,

            informacion: res.data.dic_tipo === 1 ? true : false,
            encuesta: res.data.dic_tipo === 2 ? true : false,
            reserva: res.data.dic_tipo === 3 ? true : false,
            inscripcion: res.data.dic_tipo === 4 ? true : false,

            repetir: res.data.dic_repeticion,
            repeticionSelect: repeticionSelect[0],
            inicio: res.data.dic_fecha_inicio,
            fin: res.data.dic_fecha_termino,
            sexoSelect: sexoSelect[0],
            edadMin: res.data.dic_edad_min,
            edadMax: res.data.dic_edad_max,
            condicionSelect: condicionSelect[0],

            canalSelect: canalSelect,
            configuracionAppSelect: configuracionAppSelect[0],
            mensajeTxt: res.data.dic_mensaje,
            mensajeTxtEsp: res.data.dic_mensaje_especialidad,
            encuestaSelect: encuestaSelect[0],
            especialidadSelect: especialidadSelect[0],
            texto: res.data.dic_config_app === 1 ? true : false,
            imagen: res.data.dic_config_app === 2 ? true : false,
            video: res.data.dic_config_app === 3 ? true : false,
            link: res.data.dic_config_app === 4 ? true : false,
            gif: res.data.dic_config_app === 5 ? true : false,
            archivo: res.data.dic_url,
            archivoOld: res.data.dic_url,
          },
          () => {
            /* console.log(this.state); */
            this.abrirSegundoModal();
          }
        );
      });
    }
  };

  abrirSegundoModal = () => {
    if (this.validaciones("1") || this.props.editar || this.props.clon) {
      /* console.log('alooo'); */
      this.setState({
        open: false,
        difusionDetalle: true,
      });
    }
  };

  volverPrimerModal = () => {
    this.setState({
      open: true,
      difusionDetalle: false,
    });
  };

  obtenerCargaUsuarios() {
    if (this.state.univComuna && !this.state.univCesfam) {
      return "1";
    } else if (!this.state.univComuna && this.state.univCesfam) {
      return "2";
    } else if (!this.state.univComuna && !this.state.univCesfam) {
      return "3";
    }
  }

  obtenerTipoDifusion() {
    if (this.state.informacion && !this.state.encuesta && !this.state.reserva && !this.state.inscripcion) {
      return "1";
    } else if (!this.state.informacion && this.state.encuesta && !this.state.reserva && !this.state.inscripcion) {
      return "2";
    } else if (!this.state.informacion && !this.state.encuesta && this.state.reserva && !this.state.inscripcion) {
      return "3";
    } else if (!this.state.informacion && !this.state.encuesta && !this.state.reserva && this.state.inscripcion) {
      return "4";
    }
  }

  cambioInscritos = (e) => {
    if (e.target.ariaLabel === "Inscritos") {
      this.setState({
        inscritos: true,
        inscripcion: false,
      });
    } else if (e.target.ariaLabel === "No Inscritos") {
      this.setState({
        inscritos: false,
        reserva: false,
      });
    }
  };

  cambiarCesfam = (event, value) => {
    this.setState({ cesfamSelect: value });
  };

  cambioCanales = (event, value) => {
    this.setState({ canalSelect: value });
  };

  cambioFechaInicio = (e) => {
    this.setState({ inicio: e });
  };

  cambioFechaFin = (e) => {
    this.setState({ fin: e });
  };

  cambioConfigApp = (event, value) => {
    /* console.log(value.value); */
    switch (value.value) {
      case "1":
        this.setState({
          configuracionAppSelect: value,
          texto: true,
          link: false,
          imagen: false,
          video: false,
          gif: false,
        });
        break;
      case "2":
        this.setState({
          configuracionAppSelect: value,
          imagen: true,
          texto: false,
          link: false,
          video: false,
          gif: false,
        });
        break;
      case "3":
        this.setState({
          configuracionAppSelect: value,
          video: true,
          texto: false,
          link: false,
          imagen: false,
          gif: false,
        });
        break;
      case "4":
        this.setState({
          configuracionAppSelect: value,
          link: true,
          texto: false,
          imagen: false,
          video: false,
          gif: false,
        });
        break;
      case "5":
        this.setState({
          configuracionAppSelect: value,
          gif: true,
          texto: false,
          link: false,
          imagen: false,
          video: false,
        });
        break;
    }
  };

  cambioCarga = (e) => {
    if (e.target.ariaLabel === "Comuna") {
      this.setState({
        univComuna: !this.state.univComuna,
        univCesfam: false,
      });
    } else if (e.target.ariaLabel === "Cesfam") {
      this.setState({
        univComuna: false,
        univCesfam: !this.state.univCesfam,
      });
    }
  };

  cambioRepeticion = (event, value) => {
    this.setState({ repeticionSelect: value });
  };

  cambioCondicion = (event, value) => {
    this.setState({ condicionSelect: value });
  };

  cambioSexo = (event, value) => {
    this.setState({ sexoSelect: value, condicionSelect: null }, () => {
      this.obtenerListaCondiciones();
    });
  };

  cambioEncuesta = (event, value) => {
    this.setState({ encuestaSelect: value });
  };

  cambioEspecialidad = (event, value) => {
    this.setState({ especialidadSelect: value });
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }
    const soloNumeros = ["repetir", "edadMin", "edadMax"];
    if (soloNumeros.includes(name)) {
      value = value.replace(/[^0-9.]/g, "");
    }

    this.setState({ [name]: value });
  };

  cambioTipo = (e) => {
    if (e.target.ariaLabel === "Información") {
      this.setState({
        encuesta: false,
        informacion: true,
        reserva: false,
        inscripcion: false,
      });
    } else if (e.target.ariaLabel === "Encuestas") {
      this.setState({
        encuesta: true,
        informacion: false,
        reserva: false,
        inscripcion: false,
      });
    } else if (e.target.ariaLabel === "Reserva") {
      this.setState({
        encuesta: false,
        informacion: false,
        reserva: true,
        inscripcion: false,
      });
    } else if (e.target.ariaLabel === "Inscripción") {
      this.setState({
        encuesta: false,
        informacion: false,
        reserva: false,
        inscripcion: true,
      });
    }
  };

  obtenerCanales() {
    let listaCanales = ",";
    this.state.canalSelect.map((canal) => {
      listaCanales = listaCanales + canal.id + ",";
    });
    return listaCanales;
  }

  validaciones(modal) {
    let formErrors = {};
    let validado = true;

    const { univCesfam, univComuna } = this.state;

    switch (modal) {
      case "1":
        const { nombre, codigo, cesfamSelect } = this.state;

        if (nombre === "") {
          formErrors["nombreErr"] = "Debe introducir el nombre de la difusion";
          validado = false;
        }

        if (codigo === "") {
          formErrors["codigoErr"] = "Debe introducir el código de la difusion";
          validado = false;
        }

        if (univCesfam && !cesfamSelect) {
          formErrors["cesfamErr"] = "Debe seleccionar un cesfam";
          validado = false;
        }
        this.setState({ errorValid: formErrors });
        break;

      case "2":
        const { encuesta, informacion, reserva, inscripcion, inscritos, repetir, repeticionSelect, inicio, fin, sexoSelect, edadMin, edadMax, condicionSelect, canalSelect, configuracionAppSelect, encuestaSelect, especialidadSelect, texto, link, imagen, video, gif, mensajeTxt, mensajeTxtEsp, linkTxt, archivo } = this.state;

        if (inscritos && !encuesta && !informacion && !reserva) {
          formErrors["tipoErr"] = "Debe seleccionar un tipo de difusión";
          validado = false;
        }

        if (!inscritos && !encuesta && !informacion && !inscripcion) {
          formErrors["tipoErr"] = "Debe seleccionar un tipo de difusión";
          validado = false;
        }

        if (repetir === "") {
          formErrors["repetirErr"] = "Debe ingresar las veces que la difusion se repite";
          validado = false;
        }

        if (repeticionSelect === null) {
          formErrors["repeticionErr"] = "Debe seleccionar la frecuencia de repeticion";
          validado = false;
        }

        if (inicio == null) {
          formErrors["inicioErr"] = "Debe seleccionar la fecha de inicio";
          validado = false;
        }

        if (fin == null) {
          formErrors["finErr"] = "Debe seleccionar la fecha de fin";
          validado = false;
        }

        if (inicio > fin) {
          formErrors["fechaErr"] = "La fecha de inicio debe ser anterior a la fecha de fin";
          validado = false;
        }

        if (!univCesfam && !univComuna && sexoSelect == null) {
          formErrors["sexoErr"] = "Debe seleccionar el sexo";
          validado = false;
        }

        if (!univCesfam && !univComuna && edadMin == "") {
          formErrors["edadMinErr"] = "Debe ingresar la edad mínima";
          validado = false;
        }

        if (!univCesfam && !univComuna && edadMax == "") {
          formErrors["edadMaxErr"] = "Debe ingresar la edad máxima";
          validado = false;
        }

        if (!univCesfam && !univComuna && edadMin > edadMax) {
          formErrors["edadErr"] = "La edad mínima debe ser menor a la edad máxima";
          validado = false;
        }

        if (!univCesfam && !univComuna && condicionSelect == null) {
          formErrors["condicionErr"] = "Debe seleccionar la condición";
          validado = false;
        }

        if (canalSelect.length < 1) {
          formErrors["canalErr"] = "Debe seleccionar al menos un canal";
          validado = false;
        }

        if (informacion && configuracionAppSelect == null) {
          formErrors["configuracionErr"] = "Debe seleccionar la configuración de la aplicación";
          validado = false;
        }

        if (encuesta && encuestaSelect == null && encuesta) {
          formErrors["encuestaErr"] = "Debe seleccionar la encuesta";
          validado = false;
        }

        if ((inscripcion || reserva) && especialidadSelect == null) {
          formErrors["especialidadErr"] = "Debe seleccionar la especialidad";
          validado = false;
        }

        if ((inscripcion || reserva || texto) && mensajeTxt === "") {
          formErrors["mensajeTxtErr"] = "Debe ingresar el mensaje";
          validado = false;
        }

        if ((inscripcion || reserva) && mensajeTxtEsp == "") {
          formErrors["mensajeTxtEspErr"] = "Debe ingresar el mensaje de bienvenida";
          validado = false;
        }

        if (link && linkTxt === "") {
          formErrors["linkTxtErr"] = "Debe ingresar el link";
          validado = false;
        }

        if ((gif || imagen || video) && archivo === null) {
          formErrors["archivoErr"] = "Debe seleccionar un archivo";
          validado = false;
        }

        this.setState({ errorValid: formErrors });
        break;
    }
    return validado;
  }

  subirArchivo() {
    const { archivo, uspId } = this.state;
    const data = new FormData();
    let filename = uspId + "_" + new Date().getTime() + "_" + archivo.name;
    data.append("file", archivo);
    data.append("tipo", 3);
    data.append("sala", "");
    data.append("filename", filename);
    data.append("usuario", uspId);
    data.append("carpeta", "archivosDifusion");

    axios
      .post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_difusion", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        /* console.log(res); */
      })
      .catch(function (err) {
        /* console.log(err); */
      });
    return filename;
  }

  enviarDifusion = (e) => {
    /* console.log(this.state);
    console.log(this.props.clon) */
    let archivo = "0";
    archivo = this.props.clon ? this.state.archivoOld : "0";
    archivo = this.props.clon && this.state.archivo !== archivo ? this.subirArchivo() : archivo;
    archivo = !this.props.clon && (this.state.imagen || this.state.video || this.state.gif) ? this.subirArchivo() : archivo;
    if (this.validaciones("2")) {
      var data = {
        dic_nombre: this.state.nombre,
        dic_tipo: this.obtenerTipoDifusion(),
        dic_repeticion: this.state.repetir,
        dic_repeticion_dias: this.state.repeticionSelect.dias,
        dic_sector: "0",
        dic_edad_min: !this.state.univCesfam && !this.state.univComuna ? this.state.edadMin : "0",
        dic_edad_max: !this.state.univCesfam && !this.state.univComuna ? this.state.edadMax : "0",
        dic_sexo: !this.state.univCesfam && !this.state.univComuna ? this.state.sexoSelect.value : "0",
        dic_condicion: !this.state.univCesfam && !this.state.univComuna ? this.state.condicionSelect.id : "0",
        dic_inscritos: +this.state.inscritos,
        dic_config_app: this.state.informacion ? this.state.configuracionAppSelect.value : "0",
        dic_canales: this.obtenerCanales(),
        dic_fecha_inicio: moment(this.state.inicio).format(),
        dic_fecha_termino: moment(this.state.fin).format(),
        dic_cesfam: this.state.cesfamSelect ? this.state.cesfamSelect.id : this.state.cesfam,
        dic_especialidad: this.state.reserva || this.state.inscripcion ? this.state.especialidadSelect.id : "0",
        dic_encuesta: this.state.encuesta ? this.state.encuestaSelect.id : "0",
        dic_estado: "1",
        dic_comuna: this.state.univComuna ? this.state.comuna : "0",
        dic_codigo: this.state.codigo,
        dic_hora_no_contesta: "0",
        dic_pausada: "0",
        dic_carga_usuario: this.obtenerCargaUsuarios(),
        dic_crea_configuracion: "1",
        dic_url: archivo,
        dic_link_redireccionar: this.state.link ? this.state.linkTxt : "0",
        dic_mensaje: this.state.texto || this.state.reserva || this.state.inscripcion ? this.state.mensajeTxt : "",
        dic_mensaje_especialidad: this.state.reserva || this.state.inscripcion ? this.state.mensajeTxtEsp : "Mensaje de prueba",
        dic_intentos: "2",
      };
      let apiUrl = global.config.modulos.difusion + "agregar_configuracion/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        let accion = this.props.clon ? "clonada" : "creada";
        NotificationManager.info("Difusión " + accion + " correctamente");
        this.setState({ idnew: res.data.dic_id });
        this.salir();
      });
    }
  };

  actualizarDifusion = (e) => {
    e.preventDefault();
    if (this.validaciones("2")) {
      let archivo = this.state.archivo != this.state.archivoOld ? this.subirArchivo() : this.props.dif_edit.dic_url;
      var data = {
        dic_nombre: this.state.nombre,
        dic_tipo: this.obtenerTipoDifusion(),
        dic_repeticion: this.state.repetir,
        dic_repeticion_dias: this.state.repeticionSelect.dias,
        dic_sector: "0",
        dic_edad_min: !this.state.univCesfam && !this.state.univComuna ? this.state.edadMin : "0",
        dic_edad_max: !this.state.univCesfam && !this.state.univComuna ? this.state.edadMax : "0",
        dic_sexo: !this.state.univCesfam && !this.state.univComuna ? this.state.sexoSelect.value : "0",
        dic_condicion: !this.state.univCesfam && !this.state.univComuna ? this.state.condicionSelect.id : "0",
        dic_inscritos: +this.state.inscritos,
        dic_config_app: this.state.informacion ? this.state.configuracionAppSelect.value : "0",
        dic_canales: this.obtenerCanales(),
        dic_fecha_inicio: moment(this.state.inicio).format(),
        dic_fecha_termino: moment(this.state.fin).format(),
        dic_cesfam: this.state.cesfamSelect ? this.state.cesfamSelect.id : this.state.cesfam,
        dic_especialidad: this.state.reserva || this.state.inscripcion ? this.state.especialidadSelect.id : "0",
        dic_encuesta: this.state.encuesta ? this.state.encuestaSelect.id : "0",
        dic_estado: "1",
        dic_comuna: this.state.univComuna ? this.state.comuna : "0",
        dic_codigo: this.state.codigo,
        dic_hora_no_contesta: "0",
        dic_carga_usuario: this.obtenerCargaUsuarios(),
        dic_crea_configuracion: "1",
        dic_url: this.state.imagen || this.state.video || this.state.gif ? archivo : "0",
        dic_link_redireccionar: this.state.link ? this.state.linkTxt : "0",
        dic_mensaje: this.state.texto || this.state.reserva || this.state.inscripcion ? this.state.mensajeTxt : "",
        dic_mensaje_especialidad: this.state.reserva || this.state.inscripcion ? this.state.mensajeTxtEsp : "Mensaje de prueba",
        dic_intentos: "2",
      };
      let apiUrl = global.config.modulos.difusion + "por_id/" + this.props.dif_edit.dic_id;
      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        NotificationManager.info("Difusión actualizada correctamente");
        this.salir();
      });
    }
  };

  render() {
    const { nombreErr, codigoErr, cesfamErr, tipoErr, repetirErr, repeticionErr, inicioErr, finErr, fechaErr, sexoErr, edadMinErr, edadMaxErr, edadErr, condicionErr, canalErr, configuracionErr, encuestaErr, especialidadErr, archivoErr, mensajeTxtErr, mensajeTxtEspErr, linkTxtErr } = this.state.errorValid;
    const cargarArchivo = (e) => {
      this.setState({
        archivo: e,
      });
    };

    return (
      <>
        <Dialog open={this.state.open} onClose={this.salir} className="modificarUsuario">
          <DialogContent>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={10} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>Agregar difusión</h1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={3} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Nombre Difusión</InputLabel>
                  <input type="text" name="nombre" value={this.state.nombre} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {nombreErr ? <div style={{ color: "red", paddingBottom: 10 }}>{nombreErr}</div> : null}
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 20 }}>
                  <InputLabel>Código Campaña</InputLabel>
                  <input type="text" name="codigo" value={this.state.codigo} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {codigoErr ? <div style={{ color: "red", paddingBottom: 10 }}>{codigoErr}</div> : null}
                </GridItem>
                <GridItem xs={5} style={{ marginTop: 20 }}>
                  <InputLabel>Difusión para usuarios</InputLabel>
                  <FormControlLabel label="Inscritos" control={<Checkbox checked={this.state.inscritos} color="primary" onChange={this.cambioInscritos} inputProps={{ "aria-label": "Inscritos" }} />} />
                  <FormControlLabel label="No Inscritos" control={<Checkbox checked={!this.state.inscritos} color="primary" onChange={this.cambioInscritos} inputProps={{ "aria-label": "No Inscritos" }} />} />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={5} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Universal</InputLabel>
                  <FormControlLabel label="Comuna" control={<Checkbox checked={this.state.univComuna} color="primary" onChange={this.cambioCarga} inputProps={{ "aria-label": "Comuna" }} />} />
                  <FormControlLabel label="Centro de Salud" control={<Checkbox checked={this.state.univCesfam} color="primary" onChange={this.cambioCarga} inputProps={{ "aria-label": "Cesfam" }} />} />
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 20 }}>
                  {this.state.univCesfam ? <Autocomplete name="cesfams" disablePortal disableClearable id="combo-box-demo1" options={this.state.cesfams} getOptionLabel={(option) => option.label} onChange={this.cambiarCesfam.bind(this)} value={this.state.cesfamSelect} renderInput={(params) => <TextField {...params} label="Seleccionar Centro de Salud" />}></Autocomplete> : null}
                  {this.state.univCesfam && cesfamErr ? <div style={{ color: "red", paddingBottom: 10 }}>{cesfamErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 20, marginLeft: 10 }}>
                  <p>Recuerde: Su difusión no puede sobrepasar el total de 9000 usuarios.</p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem
                  xs={12}
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    style={{
                      marginRight: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 8,
                    }}
                    className="fondoVerde"
                    onClick={this.abrirSegundoModal}
                    /* onClick={this.enviarDifusion} */
                  >
                    Confirmar
                  </Button>
                  <Button
                    style={{
                      marginLeft: 5,
                      marginRight: 20,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 8,
                    }}
                    color="primary"
                    className="botonGeneral"
                    onClick={this.salir}
                  >
                    Cancelar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.difusionDetalle} onClose={this.salir} className="modificarUsuario">
          <DialogContent>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={10} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>{this.props.clon ? <>Clonar</> : <>Editar</>} difusión</h1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Tipo de difusión</InputLabel>
                  <FormControlLabel label="Encuestas" control={<Checkbox checked={this.state.encuesta} color="primary" onChange={this.cambioTipo} inputProps={{ "aria-label": "Encuestas" }} />} />
                  <FormControlLabel label="Información" control={<Checkbox checked={this.state.informacion} color="primary" onChange={this.cambioTipo} inputProps={{ "aria-label": "Información" }} />} />
                  {this.state.inscritos ? <FormControlLabel label="Reserva" control={<Checkbox checked={this.state.reserva} color="primary" onChange={this.cambioTipo} inputProps={{ "aria-label": "Reserva" }} />} /> : <FormControlLabel label="Inscripciones" control={<Checkbox checked={this.state.inscripcion} color="primary" onChange={this.cambioTipo} inputProps={{ "aria-label": "Inscripción" }} />} />}
                  {tipoErr ? <div style={{ color: "red", paddingBottom: 10 }}>{tipoErr}</div> : null}
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 10, marginLeft: 10 }}>
                  <InputLabel>
                    <b>Difundir</b>
                  </InputLabel>
                  <InputLabel style={{ marginTop: 10 }}>Repetir</InputLabel>
                  <input type="text" name="repetir" maxlength="1" value={this.state.repetir} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {repetirErr ? <div style={{ color: "red", paddingBottom: 10 }}>{repetirErr}</div> : null}
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 5 }}>
                  <InputLabel>&nbsp;</InputLabel>
                  <InputLabel>Por</InputLabel>
                  <Autocomplete name="repeticionSelect" disablePortal disableClearable id="combo-box-tiempos" options={this.state.repeticionOpciones} getOptionLabel={(option) => option.label} onChange={this.cambioRepeticion.bind(this)} value={this.state.repeticionSelect} renderInput={(params) => <TextField {...params} label="Seleccionar Frecuencia" />}></Autocomplete>
                  {repeticionErr ? <div style={{ color: "red", paddingBottom: 10 }}>{repeticionErr}</div> : null}
                </GridItem>
                <GridItem xs={2} style={{ marginTop: 10 }}>
                  <InputLabel>
                    <b>Duración Difusión</b>
                  </InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      style={{ marginTop: 10 }}
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      //disablePast="true"
                      views={["year", "month", "date"]}
                      value={this.state.inicio}
                      initialFocusedDate={this.state.inicio}
                      format="yyyy-MM-dd"
                      id="date-picker-inline"
                      label="Inicio"
                      name="inicio"
                      onChange={(e) => this.cambioFechaInicio(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {inicioErr ? <div style={{ color: "red", paddingBottom: 10 }}>{inicioErr}</div> : null}
                  {fechaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{fechaErr}</div> : null}
                </GridItem>
                <GridItem xs={2} style={{ marginTop: 10 }}>
                  <InputLabel>&nbsp;</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      style={{ marginTop: 10 }}
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      disablePast="true"
                      views={["year", "month", "date"]}
                      value={this.state.fin}
                      initialFocusedDate={this.state.fin}
                      format="yyyy-MM-dd"
                      id="date-picker-inline"
                      label="Término"
                      name="termino"
                      onChange={(e) => this.cambioFechaFin(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {finErr ? <div style={{ color: "red", paddingBottom: 10 }}>{finErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                {!this.state.univCesfam && !this.state.univComuna ? (
                  <>
                    <GridItem xs={3} style={{ marginTop: 20, marginLeft: 10 }}>
                      <Autocomplete name="sexo" disablePortal disableClearable id="combo-box-sexo" options={this.state.sexoOpciones} getOptionLabel={(option) => option.label} onChange={this.cambioSexo.bind(this)} value={this.state.sexoSelect} renderInput={(params) => <TextField {...params} label="Sexo" />}></Autocomplete>
                      {sexoErr ? <div style={{ color: "red", paddingBottom: 10 }}>{sexoErr}</div> : null}
                    </GridItem>
                    <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                      <InputLabel>Edad</InputLabel>
                      <input type="text" name="edadMin" maxLength={2} value={this.state.edadMin} onChange={this.cambiarValores} placeholder="" className="inputText" />
                      {edadMinErr ? <div style={{ color: "red", paddingBottom: 10 }}>{edadMinErr}</div> : null}
                      {edadErr ? <div style={{ color: "red", paddingBottom: 10 }}>{edadErr}</div> : null}
                    </GridItem>
                    <GridItem xs={1} style={{ marginTop: 35 }}>
                      <input type="text" name="edadMax" maxLength={2} value={this.state.edadMax} onChange={this.cambiarValores} placeholder="" className="inputText" />
                      {edadMaxErr ? <div style={{ color: "red", paddingBottom: 10 }}>{edadMaxErr}</div> : null}
                    </GridItem>
                    <GridItem xs={3} style={{ marginTop: 20, marginLeft: 95 }}>
                      <Autocomplete name="condicion" disableClearable id="combo-box-condicion" options={this.state.condicionOpciones} getOptionLabel={(option) => option.label} onChange={this.cambioCondicion.bind(this)} value={this.state.condicionSelect} renderInput={(params) => <TextField {...params} label="Condicion" />}></Autocomplete>
                      {condicionErr ? <div style={{ color: "red", paddingBottom: 10 }}>{condicionErr}</div> : null}
                    </GridItem>
                  </>
                ) : null}
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={3} style={{ marginTop: 30, marginLeft: 10 }}>
                  <Autocomplete
                    name="canales"
                    disableClearable
                    multiple
                    id="combo-box-canales"
                    options={this.state.canalesOpciones}
                    getOptionLabel={(option) => option.label}
                    onChange={this.cambioCanales.bind(this)}
                    limitTags={1}
                    defaultValue={this.state.canalSelect}
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.label}
                      </React.Fragment>
                    )}
                    renderInput={(params) => <TextField {...params} label="Canales" placeholder="Seleccione canales" />}
                  ></Autocomplete>
                  {canalErr ? <div style={{ color: "red", paddingBottom: 10 }}>{canalErr}</div> : null}
                </GridItem>
                {this.state.informacion ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <Autocomplete name="config-app" disableClearable id="combo-box-config" options={this.state.configuracionAppOpciones} getOptionLabel={(option) => option.label} onChange={this.cambioConfigApp.bind(this)} value={this.state.configuracionAppSelect} renderInput={(params) => <TextField {...params} label="Configuración app" />}></Autocomplete>
                    {configuracionErr ? <div style={{ color: "red", paddingBottom: 10 }}>{configuracionErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.encuesta ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <Autocomplete name="encuesta" disableClearable id="combo-box-encuesta" options={this.state.encuestaOpciones} getOptionLabel={(option) => option.label} onChange={this.cambioEncuesta.bind(this)} value={this.state.encuestaSelect} renderInput={(params) => <TextField {...params} label="Encuesta" />}></Autocomplete>
                    {encuestaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{encuestaErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.reserva || this.state.inscripcion ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <Autocomplete name="especialidad" disableClearable id="combo-box-especialidad" options={this.state.especialidadOpciones} getOptionLabel={(option) => option.label} onChange={this.cambioEspecialidad.bind(this)} value={this.state.especialidadSelect} renderInput={(params) => <TextField {...params} label="Especialidad" />}></Autocomplete>
                    {especialidadErr ? <div style={{ color: "red", paddingBottom: 10 }}>{especialidadErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.texto || this.state.reserva || this.state.inscripcion ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <InputLabel>Mensaje Bienvenida</InputLabel>
                    <input type="text" name="mensajeTxt" value={this.state.mensajeTxt} onChange={this.cambiarValores} placeholder="" className="inputText" />
                    {mensajeTxtErr ? <div style={{ color: "red", paddingBottom: 10 }}>{mensajeTxtErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.imagen ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <InputLabel>Cargar Imagen</InputLabel>
                    <input type="file" accept="image/png, image/jpeg" name="imagenArchivo" onChange={(e) => cargarArchivo(e.target.files[0])} />
                    {archivoErr ? <div style={{ color: "red", paddingBottom: 10 }}>{archivoErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.video ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <InputLabel>Cargar Video</InputLabel>
                    <input type="file" accept="video/*" name="videoArchivo" onChange={(e) => cargarArchivo(e.target.files)} />
                    {archivoErr ? <div style={{ color: "red", paddingBottom: 10 }}>{archivoErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.gif ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <InputLabel>Cargar Gif</InputLabel>
                    <input type="file" accept="image/gif" name="gifArchivo" onChange={(e) => cargarArchivo(e.target.files)} />
                    {archivoErr ? <div style={{ color: "red", paddingBottom: 10 }}>{archivoErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.link ? (
                  <GridItem xs={3} style={{ marginTop: 30 }}>
                    <InputLabel>Ingresar Link</InputLabel>
                    <input type="text" name="linkTxt" value={this.state.linkTxt} onChange={this.cambiarValores} placeholder="" className="inputText" />
                    {linkTxtErr ? <div style={{ color: "red", paddingBottom: 10 }}>{linkTxtErr}</div> : null}
                  </GridItem>
                ) : null}
                {this.state.reserva || this.state.inscripcion ? (
                  <GridItem xs={3} style={{ marginTop: 30, marginLeft: 10 }}>
                    <InputLabel>Mensaje Especialidad</InputLabel>
                    <input type="text" name="mensajeTxtEsp" value={this.state.mensajeTxtEsp} onChange={this.cambiarValores} placeholder="" className="inputText" />
                    {mensajeTxtEspErr ? <div style={{ color: "red", paddingBottom: 10 }}>{mensajeTxtEspErr}</div> : null}
                  </GridItem>
                ) : null}
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem
                  xs={12}
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    style={{
                      marginRight: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 8,
                    }}
                    className="fondoVerde"
                    /* onClick={this.abrirSegundoModal} */
                    onClick={!this.props.editar ? this.enviarDifusion : this.actualizarDifusion}
                    /* onClick={this.subirArchivo.bind(this)} */
                  >
                    Confirmar
                  </Button>
                  <Button
                    style={{
                      marginLeft: 5,
                      marginRight: 20,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 8,
                    }}
                    color="primary"
                    className="botonGeneral"
                    onClick={!this.props.editar && !this.props.clon ? this.volverPrimerModal : this.salir}
                  >
                    {!this.props.editar && !this.props.clon ? <>Atrás</> : <>Salir</>}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
