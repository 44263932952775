import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class DetalleCanal extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      detalle: props.detalle,
      registro: props.registro,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="detalleAudio">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                <img className="marca-agua"></img>
                <GridContainer className="agendar-container">
                  <GridItem xs={12}>
                    <h2>Detalle</h2>
                    <p style={{ marginTop: 30 }}>
                      <label>Fecha de registro: {this.state.registro.substring(0, 10)}</label>
                    </p>
                    <div style={{ marginTop: 30 }} className="comentariosUsuario" dangerouslySetInnerHTML={{ __html: this.state.detalle }}></div>
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button onClick={this.salir} className="botonSimple floatRigth fondoRojo">
                      Cerrar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
